import { assign, createMachine, StateFrom } from 'xstate';
import { IPoint } from '@/utils/gis/types';
import { useEditorServices } from '@/features/editor/providers/editor-services-provider';
import { useSelector } from '@xstate/react';
import { R } from '@/lib/remeda';
import { snapAngle } from '@/utils/gis/snap-angle';
import { drawCustomLength } from '@/utils/gis/custom-lenght';
import { distanceVincenty } from '@/utils/gis/distance-vincenty';

interface IDrawPolygonMachineContext {
  path: IPoint[] | undefined;
  cursorPoint: IPoint | undefined;
}

type IDrawPolygonMachineEvent =
  | {
      type: 'CLICK';
      point: IPoint;
      isSnappingAngle: boolean;
      isDrawBox: boolean;
    }
  | {
      type: 'MOUSE_MOVE';
      point: IPoint;
      isSnappingAngle: boolean;
      isDrawBox: boolean;
    }
  | { type: 'DOUBLE_CLICK' }
  | { type: 'CANCEL' }
  | { type: 'RESET' };

type IDrawPolygonMachineTypestate =
  | {
      value: 'idle';
      context: {
        path: undefined;
        cursorPoint: undefined;
      };
    }
  | {
      value: 'drawing';
      context: {
        path: IPoint[];
        cursorPoint: IPoint;
      };
    }
  | {
      value: 'done';
      context: {
        path: IPoint[];
        cursorPoint: undefined;
      };
    };

let pointFirstSide: IPoint | undefined = undefined;

function drawBox(path: IPoint[], point: IPoint): IPoint {
  const first = R.first(path)!;
  const last = R.last(path)!;
  const beforeLast = path.length > 2 ? path[path.length - 2]! : first;
  let lengthSide: number = 0;

  if (path.length === 1) {
    pointFirstSide = point;
  }

  if (path.length === 2) {
    return drawCustomLength(beforeLast, last, point, 0, 90);
  } else {
    if (path.length === 3) {
      lengthSide = distanceVincenty(pointFirstSide!, first);
      return drawCustomLength(beforeLast, last, point, lengthSide, 90);
    } else {
      return drawCustomLength(beforeLast, last, point, 0, 0);
    }
  }
}

function getSnappedPoint(path: IPoint[], point: IPoint): IPoint {
  const first = R.first(path)!;
  const last = R.last(path)!;
  const beforeLast = path.length > 2 ? path[path.length - 2]! : first;

  return snapAngle(beforeLast, last, point);
}

function isValidPath(path: IPoint[]): boolean {
  return path.length > 2;
}

const drawPolygonMachine = createMachine<
  IDrawPolygonMachineContext,
  IDrawPolygonMachineEvent,
  IDrawPolygonMachineTypestate
>({
  predictableActionArguments: true,

  initial: 'idle',

  context: {
    path: undefined,
    cursorPoint: undefined
  },

  states: {
    idle: {
      entry: [assign({ path: undefined, cursorPoint: undefined })],
      on: {
        CLICK: {
          target: 'drawing',
          actions: [
            assign({
              path: (context, event) => [event.point]
            })
          ]
        }
      }
    },

    drawing: {
      on: {
        CLICK: {
          actions: [
            assign({
              path: (context, event) => {
                const point = event.isSnappingAngle
                  ? getSnappedPoint(context.path!, event.point)
                  : event.isDrawBox
                  ? drawBox(context.path!, event.point)
                  : event.point;
                return context.path ? [...context.path, point] : [point];
              }
            })
          ]
        },

        MOUSE_MOVE: {
          actions: [
            assign({
              cursorPoint: (context, event) =>
                event.isSnappingAngle
                  ? getSnappedPoint(context.path!, event.point)
                  : event.isDrawBox
                  ? drawBox(context.path!, event.point)
                  : event.point
            })
          ]
        },

        DOUBLE_CLICK: [
          { target: 'done', cond: (context) => isValidPath(context.path!) },
          {
            target: 'idle'
          }
        ],
        CANCEL: [{ target: 'idle' }]
      }
    },

    done: {
      entry: [assign({ cursorPoint: undefined })],
      on: {
        RESET: {
          target: 'idle'
        }
      }
    }
  }
});

type DrawPolygonServiceState = StateFrom<typeof drawPolygonMachine>;

function useDrawPolygonService() {
  const { drawPolygonService } = useEditorServices();
  return { drawPolygonService };
}

function selectPath(state: DrawPolygonServiceState) {
  return state.context.path;
}

function useSelectPath() {
  const { drawPolygonService } = useEditorServices();
  return useSelector(drawPolygonService, selectPath);
}

function selectCursorPoint(state: DrawPolygonServiceState) {
  return state.context.cursorPoint;
}

function useSelectCursorPoint() {
  const { drawPolygonService } = useEditorServices();
  return useSelector(drawPolygonService, selectCursorPoint);
}

export { drawPolygonMachine, useDrawPolygonService, useSelectPath, useSelectCursorPoint };
export type { DrawPolygonServiceState };
