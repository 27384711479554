import { IPoint } from '@/utils/gis/types';
import { ISide } from '@/utils/drawing/types';
import { selectPolygonSides } from '@/features/google-map/utils/select-polygon-sides';

/*
 * Builds an array of sides from a polyline path.
 *
 * Since this is a polyline, it won't complete the path
 * by adding the (first, last) point as the last one.
 */
export function selectPolylineSides(path: IPoint[]): ISide[] {
  const sides = selectPolygonSides(path);
  sides.pop();
  return sides;
}
