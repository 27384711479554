import { Typography } from '@/components/typography';
import React from 'react';

interface IInverterDetailsProps {
  label: string;
  value: React.ReactNode;
}

export function InverterDetails({ label, value }: IInverterDetailsProps): JSX.Element {
  return (
    <Typography>
      <span sx={{ color: 'menuText' }}>{label} </span>
      {value}
    </Typography>
  );
}
