import { api } from '@/utils/api';
import { R } from '@/lib/remeda';
import { InferMutationOptions } from '@/utils/types';
import { useMutation } from '@tanstack/react-query';

interface ISurfaceDeleteApiRequest {
  guid: string;
  calculationId: number;
}

async function surfaceDeleteApi(request: ISurfaceDeleteApiRequest) {
  return api.post('/deleteSurface', {
    ...R.omit(request, ['calculationId']),
    calculationID: request.calculationId
  });
}

export function useSurfaceDelete(options?: InferMutationOptions<typeof surfaceDeleteApi>) {
  return useMutation(surfaceDeleteApi, options);
}
