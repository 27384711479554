import * as R from 'remeda';
import { IPoint } from '@/utils/gis/types';
import { ISide } from '@/utils/drawing/types';

/*
 * Constructs sides given a polygon with unique path points.
 *
 * Since it constructs sides for a _polygon_, it will complete
 * the polygon by adding the (last, first) point pair.
 */
export function selectPolygonSides(path: IPoint[]): ISide[] {
  if (path.length <= 1) {
    return [];
  }

  const sides: ISide[] = [];
  for (let i = 1; i < path.length; i++) {
    const start = path[i - 1]!;
    const end = path[i]!;
    sides.push({
      start,
      end
    });
  }
  const first = R.first(path);
  const last = R.last(path);

  if (first === undefined || last === undefined) {
    throw new Error(`Path must have at least two points to create a surface`);
  }

  sides.push({
    start: first,
    end: last
  });
  return sides;
}
