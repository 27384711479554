import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { ESDEC_PREFERRED_PRODUCT_TYPE } from '../esdec-types';
import { FormattedMessage, useIntl } from 'react-intl';

export const EsdecPreferredProductInput = observer(function EsdecPreferredProductInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const intl = useIntl();
  const surface = store.surfaces.surfaceGetOne(surfaceId);
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec || !surface || surface.placement === 'ground') {
    return <React.Fragment />;
  }

  const preferredProductOptions = ESDEC_PREFERRED_PRODUCT_TYPE[surface.placement].map((preferredProduct) => ({
    label: preferredProduct,
    value: preferredProduct
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Product',
        id: 'x/ZVlU'
      })}
      placeholder={<FormattedMessage defaultMessage="Select Product" id="5JlF3Y" />}
      name="preferredProduct"
      options={preferredProductOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updatePreferredProduct(value.value);
        }
      }}
      value={preferredProductOptions.find(
        (preferredProductOption) => preferredProductOption.value === surfaceEsdec.preferredProduct
      )}
    />
  );
});
