import { UnwrapArray } from '@/utils/types';
import { IEditorProjectInformation } from '@/features/project-information/utils/project-information';
import { neverCase } from '@/utils/never-case';
import { ProjectInformationApi } from '@/features/project-information';

export function matchDeductionToClient(
  deduction: UnwrapArray<ProjectInformationApi.ICalculationDefaultsApiResponse['deductions']>,
  clientType: Required<IEditorProjectInformation>['clientType']
): boolean {
  switch (clientType) {
    case 'privatePerson':
      return deduction.includeVat;
    case 'company':
      return deduction.excludeVat;
    default:
      neverCase(clientType);
  }
}
