import { makeAutoObservable } from 'mobx';
import { ISurface } from '@/features/editor/utils/surface/surface';
import { IPoint } from '@/utils/gis/types';
import { R } from '@/lib/remeda';
import { Gis } from '@/features/editor/utils/gis';
import { nanoid } from 'nanoid';
import {
  MOUNTING_SYSTEM_DEFAULT_MARGIN_METERS,
  MOUNTING_SYSTEM_DEFAULT_SETBACK_METERS
} from '@/features/editor/utils/mounting-system';
import { MetadataStore } from '@/features/editor/stores/mobx/metadata-store';
import {
  addOptimizerToPath,
  panelsCacheUpdate,
  polygonPointUpdate,
  PolygonPointUpdatePayload,
  removeOptimizerFromPath,
  toggleOptimizersOnPath
} from '@/features/editor/utils/machine-actions';
import { surfaceRidgeSide } from '@/features/google-map/utils/surface-ridge-side';
import { selectPolylineSides } from '@/features/google-map/utils/select-polyline-sides';
import { formatPrecision } from '@/utils/format-precision';
import { oppositeAzimuth } from '@/utils/gis/opposite-azimuth';
import { groupByMap } from '@/utils/group-by-map';
import { isNil } from 'remeda';
import {
  GeneratedPanel,
  getManualPanels,
  IPanelType,
  panelPolygon,
  panelPolygonDimensions
} from '@/features/editor/utils/panel/panel';
import booleanIntersects from '@turf/boolean-intersects';
import { IOptimizerPayload } from '@/features/editor/utils/optimizers/optimizer';
import { IEditorContext, IEditorSurfacesPanelsCache } from '@/features/editor/utils/editor-context';
import { IPanelsCacheUpdatePayload } from '@/features/editor/utils/actions/use-editor-actions';
import center from '@turf/center';
import * as turf from '@turf/helpers';
import { StateStore } from '@/features/editor/stores/mobx/state-store';
import { InvertersStore } from '@/features/editor/stores/mobx/inverters-store';
import { SurfacesEsdecStore } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { RectanglePoint } from '@/utils/turf/rectangle-polygon';
import { getSideLengths } from '@/features/editor/utils/get-side-lengths';

function getNextSurfaceIndex(surfaces: ISurface[]): number {
  const surfaceIndexes = surfaces
    .map((surface) => getSurfaceIndexFromDescription(surface.description))
    .filter(R.isDefined);
  const maxSurfaceIndex = R.arrayMax(surfaceIndexes);
  return maxSurfaceIndex + 1;

  function getSurfaceIndexFromDescription(description: string): number | undefined {
    const [, stringIndex] = description.split(' ');
    if (R.isNil(stringIndex)) {
      return undefined;
    }
    const result = parseInt(stringIndex);
    if (Number.isNaN(result)) {
      return undefined;
    }
    return result;
  }
}

class SurfacesStore {
  public surfaces: ISurface[] = [];

  public selectedEsdecSurfaceId: string | undefined;

  public selectedSurfaceId: string | undefined;

  public selectedOverviewSurfaceIds: string[] = [];

  public hoveredSurfaceIds: string[] = [];

  public surfacesPanelsCache: Record<string, IEditorSurfacesPanelsCache> = {};

  public copiedPanelsTopLeftPoints: IPoint[] = [];

  private _metadataStore: MetadataStore;
  private _stateStore: StateStore;
  private _invertersStore: InvertersStore;
  private _surfacesEsdecStore: SurfacesEsdecStore;
  public inputValue?: number;
  public inputSideValue?: number;
  public constructor({
    initial,
    metadataStore,
    stateStore,
    invertersStore,
    surfacesEsdecStore
  }: {
    initial?: Pick<IEditorContext, 'surfaces' | 'selectedSurfaceId' | 'surfacesPanelsCache'>;
    invertersStore: InvertersStore;
    metadataStore: MetadataStore;
    stateStore: StateStore;
    surfacesEsdecStore: SurfacesEsdecStore;
  }) {
    makeAutoObservable(this);

    this._metadataStore = metadataStore;
    this._stateStore = stateStore;
    this._invertersStore = invertersStore;
    this._surfacesEsdecStore = surfacesEsdecStore;

    if (initial) {
      this.surfaces = initial.surfaces;
      this.selectedSurfaceId = initial.selectedSurfaceId;
      this.surfacesPanelsCache = initial.surfacesPanelsCache;
    }
  }

  public surfaceCreate = (path: IPoint[]) => {
    const defaultPanelType = this._metadataStore.panelTypes[0];
    if (R.isNil(defaultPanelType)) {
      return;
    }

    const geojsonPath = Gis.geojsonPolygonPath(path);
    if (!Gis.isValidGeoJsonPath(geojsonPath)) {
      return;
    }

    const surface: ISurface = {
      pvgisLoss: this._metadataStore.defaultPvgisLoss,
      id: nanoid(),
      panelTypeId: defaultPanelType.productId,
      panelAttributes: {
        width: 0,
        length: 0,
        thickness: 0,
        weight: 0
      },
      rotationAngle: 0,
      panelsManual: [],
      description: `Surface ${getNextSurfaceIndex(this.surfaces)}`,
      placement: 'angled' as const,
      tiltAngle: 0,
      panelTiltAngle: 0,
      path: geojsonPath,
      orientation: 'portrait' as const,
      heightToEavesMeters: 0,
      setbackMeters: MOUNTING_SYSTEM_DEFAULT_SETBACK_METERS,
      deletedPanelTopLefts: [],
      rowMarginMeters: MOUNTING_SYSTEM_DEFAULT_MARGIN_METERS,
      columnMarginMeters: MOUNTING_SYSTEM_DEFAULT_MARGIN_METERS,
      optimizerPoints: [],
      isStartingCostEnabled: true,
      racking: {
        horizontalCount: 1,
        verticalCount: 1,
        verticalSpaceMeters: 1,
        horizontalSpaceMeters: 1
      },
      esdecAttributes: {
        summary_results: {
          windPressure: null,
          regularSnowLoad: null,
          panelCount: null,
          totalPower: null,
          averageRoofLoad: null,
          errors: null
        },
        order_list: { totalprice: 0, pricepermodule: 0, priceperwattpower: 0 },
        roof: {
          windzone: null,
          snowzone: null,
          topography: null,
          terrainCategory: null,
          material: null,
          side: null,
          snowFencesPresent: null,
          steelType: null,
          purlinDistance: null,
          roofStructure: null,
          rafterDistance: null,
          seamType: null,
          flangeDistance: null,
          hangerBolt: null,
          product: null,
          railSystem: null
        }
      }
    };

    this.surfaces.push(surface);
    this.selectedSurfaceId = surface.id;

    this._stateStore.setState('dragging');
    this._surfacesEsdecStore.create({ surfaceId: surface.id });
  };

  public surfaceSelect = (surfaceId: string) => {
    this.selectedSurfaceId = surfaceId;

    const thisSurfaceInverter = this._invertersStore.inverters.find((inverter) =>
      inverter.surfaceIds.includes(surfaceId)
    );

    if (thisSurfaceInverter) {
      this._invertersStore.inverterSelect(thisSurfaceInverter.id);
    }
  };

  public surfacePointUpdate = (payload: PolygonPointUpdatePayload) => {
    polygonPointUpdate(this.surfaces, payload);
  };

  public surfaceHover = ({ surfaceId, mode }: { mode: 'mouseenter' | 'mouseleave'; surfaceId: string }) => {
    if (mode === 'mouseenter' && !this.hoveredSurfaceIds.includes(surfaceId)) {
      this.hoveredSurfaceIds.push(surfaceId);
    } else {
      this.hoveredSurfaceIds.splice(this.hoveredSurfaceIds.indexOf(surfaceId), 1);
    }
  };

  public surfaceResetAutomation = (data: { surfaceId: string }) => {
    const surface = this.surfaces.find((surface) => surface.id === data.surfaceId);
    if (surface) {
      surface.panelsManual = [];
      surface.deletedPanelTopLefts = [];
    }
  };

  public updateSelectedOverviewSurfaceIds(selectedOverviewSurfaceIds: string[]) {
    this.selectedOverviewSurfaceIds = selectedOverviewSurfaceIds;
  }

  public surfaceDelete = ({ surfaceId }: { surfaceId: string }) => {
    const index = this.surfaces.findIndex((surface) => surface.id === surfaceId);
    this.surfaces.splice(index, 1);

    if (this.selectedSurfaceId === surfaceId) {
      this.selectedSurfaceId = R.last(this.surfaces)?.id;
    }
  };

  public surfaceUpdate = (surfaceId: string, fn: (surface: ISurface) => void) => {
    const old = this.surfaces.find((surface) => surface.id === surfaceId);
    if (old) {
      fn(old);
    }
  };

  public setSurfaceDirectionOppositeOfPoint = ({
    surfaceId,
    point
  }: {
    surfaceId: string;
    point: IPoint;
  }) => {
    const surface = this.surfaces.find((surface) => surface.id === surfaceId);
    if (surface === undefined) {
      return;
    }
    const ridgeLine = surfaceRidgeSide({
      ridge: point,
      sides: selectPolylineSides(surface.path),
      id: surface.id
    });

    if (ridgeLine) {
      this.updateSurfaceRotationAngle({
        angle: formatPrecision(oppositeAzimuth(ridgeLine, surface), 2),
        surfaceId
      });
    }
  };

  public updateSurfaceSides = ({ sides, surfaceId }: { sides: object; surfaceId: string | undefined }) => {
    const surface = this.surfaces.find((surface) => surface.id === surfaceId);
    if (surface) {
      console.log(getSideLengths(surface.path));
    }
  };

  public updateSurfaceRotationAngle = ({ surfaceId, angle }: { surfaceId: string; angle: number }) => {
    const surface = this.surfaces.find((surface) => surface.id === surfaceId);
    if (surface) {
      surface.rotationAngle = angle >= 0 ? angle % 361 : 360 + (angle % 361);
      surface.panelsManual.forEach((panel) => (panel.rotationAngle = surface.rotationAngle));
    }
  };

  public updateSurfaceRidge = ({ surfaceId, ridge }: { surfaceId: string; ridge: IPoint }) => {
    this.surfaceUpdate(surfaceId, (surface) => {
      surface.ridge = ridge;
    });

    this.setSurfaceDirectionOppositeOfPoint({ surfaceId, point: ridge });
  };

  public surfaceGetOne = (surfaceId: string): ISurface | undefined => {
    return this.surfaces.find((surface) => surface.id === surfaceId);
  };

  public panelsAutomaticToManual = (data: {
    surfaceId: string;
    panels: {
      topLeft: IPoint;
      originalPoint: IPoint;
    }[];
  }) => {
    const surface = this.surfaces.find((surface) => surface.id === data.surfaceId);
    if (surface === undefined) {
      return;
    }

    surface.panelsManual.push(
      ...data.panels.map((panel) => ({
        id: nanoid(),
        rotationAngle: surface.rotationAngle,
        topLeft: panel.topLeft,
        originalPoint: panel.originalPoint
      }))
    );
  };

  public panelsManualUpdate = (data: {
    surfaceId: string;
    panels: {
      panelId: string;
      topLeft: IPoint;
    }[];
  }) => {
    const surface = this.surfaces.find((surface) => data.surfaceId === surface.id);
    if (surface === undefined) {
      return;
    }
    const panels = groupByMap(data.panels, (panel) => panel.panelId);
    surface.panelsManual = surface.panelsManual.map((panel) => {
      return {
        ...panel,
        topLeft: panels.get(panel.id)?.topLeft ?? panel.topLeft
      };
    });
  };

  public removePanelsFromSurface = ({
    surfaceId,
    panelTopLefts
  }: {
    surfaceId: string;
    panelTopLefts: IPoint[];
  }) => {
    const surface = this.surfaces.find((s) => s.id === surfaceId);
    const panelType = this.getSurfacePanelType(surfaceId);

    if (isNil(surface) || isNil(panelType)) {
      return;
    }

    surface.deletedPanelTopLefts = [...surface.deletedPanelTopLefts, ...panelTopLefts];
    const deletedPanels = panelTopLefts.map((topLeft) =>
      panelPolygon({
        rotationAngle: surface.rotationAngle,
        tiltAngle: surface.tiltAngle,
        heightMeters: panelType.heightMeters,
        widthMeters: panelType.widthMeters,
        orientation: surface.orientation,
        topLeft
      })
    );
    surface.panelsManual = surface.panelsManual.filter((panel) => {
      for (const deletedPanel of deletedPanels) {
        if (
          booleanIntersects(
            panelPolygon({
              topLeft: panel.topLeft,
              rotationAngle: surface.rotationAngle,
              tiltAngle: surface.tiltAngle,
              heightMeters: panelType.heightMeters,
              widthMeters: panelType.widthMeters,
              orientation: surface.orientation
            }),
            deletedPanel
          )
        ) {
          return false;
        }
      }
      return true;
    });
  };

  public getSurfacePanelType = (surfaceId: string): IPanelType | undefined => {
    const surface = this.surfaces.find((s) => s.id === surfaceId);
    if (R.isNil(surface)) {
      return undefined;
    }
    return this._metadataStore.panelTypes.find((p) => p.productId === surface.panelTypeId);
  };

  public optimizersToggle = (payload: IOptimizerPayload[]) => {
    toggleOptimizersOnPath(
      {
        surfaces: this.surfaces
      },
      payload
    );
  };

  public optimizersAdd = (payload: IOptimizerPayload[]) => {
    addOptimizerToPath(
      {
        surfaces: this.surfaces
      },
      payload
    );
  };

  public optimizersRemove = (payload: IOptimizerPayload[]) => {
    removeOptimizerFromPath(
      {
        surfaces: this.surfaces
      },
      payload
    );
  };

  public updatePanelsCache = (payload: IPanelsCacheUpdatePayload) => {
    panelsCacheUpdate(
      {
        surfacesPanelsCache: this.surfacesPanelsCache
      },
      payload
    );
  };

  public surfacesAttachImage = (payload: { surfaceId: string; imageSrc?: string }[]) => {
    for (const { surfaceId, imageSrc } of payload) {
      const surface = this.surfaces.find((surface) => surface.id === surfaceId);
      if (surface) {
        surface.mapImageSrc = imageSrc;
      }
    }
  };

  public surfacePanelManualCreate = ({ point }: { point: IPoint }) => {
    const selectedSurface = this.surfaces.find((surface) => surface.id === this.selectedSurfaceId);
    if (selectedSurface === undefined) {
      return;
    }

    const isOverlappingExistingManualPanels = (selectedSurface: ISurface, point: IPoint): boolean => {
      const panelType = this.getSurfacePanelType(selectedSurface.id);
      if (R.isNil(panelType)) {
        return false;
      }

      const commonOptions = {
        tiltAngle: selectedSurface.tiltAngle,
        heightMeters: panelType.heightMeters,
        widthMeters: panelType.widthMeters,
        rotationAngle: selectedSurface.rotationAngle,
        orientation: selectedSurface.orientation
      } as const;

      const eventPanel = panelPolygon({
        ...commonOptions,
        topLeft: point
      });

      return getManualPanels(
        selectedSurface.panelsManual,
        panelPolygonDimensions(commonOptions),
        center(turf.polygon([Gis.geojsonPolygonPath(selectedSurface.path)]))
      ).some(({ panel }) => booleanIntersects(eventPanel, panel));
    };

    if (isOverlappingExistingManualPanels(selectedSurface, point)) {
      return;
    }

    selectedSurface.panelsManual.push({
      id: nanoid(),
      rotationAngle: selectedSurface.rotationAngle,
      topLeft: point,
      originalPoint: undefined
    });
  };

  public surfacePanelsPaste = ({
    surfaceId,
    pasteOrigin,
    topLefts,
    onPasteDone
  }: {
    surfaceId: string;
    pasteOrigin: IPoint;
    topLefts: IPoint[];
    onPasteDone?: (pastedIds: string[]) => void;
  }) => {
    const surface = this.surfaceGetOne(surfaceId);
    if (surface === undefined) {
      return;
    }

    const topLeftMost = Gis.findTopLeftMostPoint(topLefts);
    if (topLeftMost === undefined) {
      return;
    }

    const rhumbBearing = Gis.rhumbBearing(topLeftMost, pasteOrigin);
    const distance = Gis.distance(topLeftMost, pasteOrigin);
    const panelTopLeftManual = topLefts.map((point) => Gis.transformTranslate(point, distance, rhumbBearing));

    const panelsToCreate = panelTopLeftManual.map((topLeft) => ({
      id: nanoid(),
      rotationAngle: surface.rotationAngle,
      topLeft: topLeft,
      originalPoint: undefined
    }));

    surface.panelsManual.push(...panelsToCreate);
    this.copiedPanelsTopLeftPoints = [];

    onPasteDone?.(panelsToCreate.map((panel) => panel.id));
  };

  public surfacePanelsCopy = ({ panels }: { panels: GeneratedPanel[] }) => {
    this.copiedPanelsTopLeftPoints = panels.map(({ panel }) =>
      RectanglePoint.topLeft(Gis.polygonFeatureToPath(panel))
    );
  };
}

export { SurfacesStore };
