import { api } from '@/utils/api';
import { z } from 'zod';
import { InferQueryOptions, UnwrapPromise } from '@/utils/types';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { EditorApiKeys } from '@/features/editor/api/keys';
import { LoaderFunctionArgs } from 'react-router-dom';
import { R } from '@/lib/remeda';

const monthlyTuple = z.tuple([
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number()
]);

const labelValueString = z.object({
  label: z.string(),
  value: z.string()
});

const labelValueNumber = z.object({
  label: z.string(),
  value: z.number()
});

const parser = z.object({
  charts: z.object({
    monthly: z.object({
      monthlyConsumption: monthlyTuple,
      monthlySales: monthlyTuple,
      productionMonths: monthlyTuple
    }),
    yearly: z.object({
      finalCost: z.string(),
      totalPerYear: z.array(z.string()),
      yearEarning: z.number(),
      years: z.number()
    })
  }),

  congratsTextVars: z.object({
    returnInvestment: z.string(),
    totEarning: z.string(),
    warrantyTimeEmission: z.string(),
    warrantyTimeProduction: z.string(),
    yearEarning: z.number()
  }),

  costs: z.object({
    inverterWarranty: z.number(),
    optimizerWarranty: z.number(),
    panelMaterialWarranty: z.number(),
    panelWarranty: z.number(),
    table: z.object({
      offerAdjustment: labelValueNumber.extend({
        isActive: z.boolean()
      }),
      panelCost: labelValueNumber,
      inverterCost: labelValueNumber,
      optimizerCost: labelValueNumber,
      accessoriesCost: labelValueNumber,
      mountingMaterialCost: labelValueNumber,
      electricalMaterialCost: labelValueNumber,
      mountingCost: labelValueNumber,
      electricalInstallationCost: labelValueNumber,
      additionsCost: labelValueNumber,
      shippingAndWasteCost: labelValueNumber,
      designCost: labelValueNumber,
      totalSolarCost: labelValueNumber.extend({
        possibleDeduction: z.number()
      }),
      batteriesCost: labelValueNumber,
      wallboxCost: labelValueNumber,
      totalAccessoriesCost: labelValueNumber.extend({
        possibleDeduction: z.number()
      }),
      deduction: labelValueNumber,
      finalCost: labelValueNumber,
      travelCost: labelValueNumber,
      shippingCost: labelValueNumber,
      wasteCost: labelValueNumber
    }),
    vatInfo: z.string(),
    isVatIncluded: z.boolean()
  }),

  productionPerSurface: z.record(
    z.object({
      id: z.number(),
      panel_group_id: z.number(),
      inverter_group_id: z.number().nullable(),
      loss: z.number(),
      startCost: z.number(),
      numberOfPanels: labelValueNumber,
      installedPower: labelValueString,
      productionPerYear: labelValueString,
      geometry: z.object({
        id: z.number().nullable(),
        roof_id: z.number().nullable(),
        type_id: z.number().nullable(),
        aspectAngel: z.number(),
        roofAngel: z.number(),
        totalHeight: z.number(),
        houseLength: z.number(),
        houseWidth: z.number(),
        wallHeight: z.number(),
        roofWidth: z.number(),
        roofType: z.string().nullable(),
        roofModel: z.string()
      }),
      productionPerPanel: labelValueString.optional()
    })
  ),

  sizeAndProduction: z.record(
    z.object({
      fuseNeeded: labelValueString,
      panelsCount: labelValueString,
      effectPerPanel: labelValueString,
      installedEffect: labelValueNumber,
      yearlyProduction: labelValueString,
      yearlyProductionValue: labelValueNumber,
      warrantyTimeProduction: labelValueString,
      warrantyTimeProductionValue: labelValueNumber,
      warrantyEffect: z.string(),
      warrantyTime: z.number()
    })
  ),

  sizeAndProductionPerSurface: z.record(
    z.object({
      panelsCount: z.number(),
      effectPerPanel: labelValueNumber,
      installedEffect: labelValueNumber,
      yearlyProduction: labelValueNumber,
      yearlyPanelProduction: labelValueNumber
    })
  ),

  returnOverTime: z.array(
    z.object({
      production: z.string(),
      valuePerYear: z.string(),
      interest: z.string(),
      amort: z.string(),
      liquidity: z.string(),
      liquidityAcc: z.string()
    })
  ),

  customerOrganizationInfo: z.object({
    customerInfo: z.object({
      address: z.string(),
      company: z.string(),
      email: z.string(),
      name: z.string(),
      phone: z.string(),
      referenceNumber: z.number(),
      customerID: z.number()
    }),
    organizationInfo: z.object({
      address: z.string().nullable(),
      logo: z.string().nullable(),
      name: z.string().nullable(),
      phone: z.string().nullable(),
      sellerMail: z.string().nullable(),
      sellerName: z.string().nullable(),
      sellerPhone: z.string().nullable(),
      website: z.string().nullable()
    })
  })
});

export interface IGetEditorResultsApiRequest {
  calculationId: number;
}

export type IGetEditorResultsApiResponse = UnwrapPromise<ReturnType<typeof getEditorResultsApi>>;

export async function getEditorResultsApi(
  { calculationId }: IGetEditorResultsApiRequest,
  signal?: AbortSignal
) {
  const response = await api.get(`/results?calculationID=${calculationId}`, { signal });
  const data = parser.parse(response.data);

  const costs = data.costs;
  const table = costs.table;

  return {
    ...data,
    costs: {
      ...costs,
      table: {
        ...table,
        offerAdjustment: {
          ...table.offerAdjustment,
          value: table.offerAdjustment.isActive ? table.offerAdjustment.value : 0
        },
        totalCalculationCost: table.totalAccessoriesCost.value + table.totalSolarCost.value
      }
    }
  };
}

export async function getEditorResultsLoader(args: LoaderFunctionArgs, queryClient: QueryClient) {
  const url = new URL(args.request.url);
  const calculationId = url.searchParams.get('calculationId')
    ? parseInt(url.searchParams.get('calculationId')!)
    : undefined;
  if (R.isNil(calculationId)) {
    throw new Error('calculationId is required');
  }

  return (
    queryClient.getQueryData<IGetEditorResultsApiResponse>(EditorApiKeys.editorResults({ calculationId })) ??
    (await getEditorResultsApi({ calculationId }))
  );
}

export function useEditorResults(
  { calculationId }: IGetEditorResultsApiRequest,
  options?: InferQueryOptions<typeof getEditorResultsApi>
) {
  return useQuery(
    EditorApiKeys.editorResults({ calculationId }),
    ({ signal }) => getEditorResultsApi({ calculationId }, signal),
    options
  );
}
