import { IPoint } from '@/utils/gis/types';

// O(n) hash of a polygon's path
export function hashPolygonPath(path: IPoint[]): string {
  let result = `${path}`;
  for (const point of path) {
    result += hashPoint(point);
  }
  return result;
}

export function hashPoint(point: IPoint, precision: number = 6): string {
  return `${point[1].toFixed(precision)},${point[0].toFixed(precision)}`;
}
