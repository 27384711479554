import { useSelector } from '@xstate/react';
import React from 'react';
import { ScreenshotArea } from '@/components/screenshot/screenshot';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { GoogleMapCoords } from '@/features/google-map/utils/google-map-coords';
import { GoogleMapConversion } from '@/features/google-map/utils/google-map-conversion';
import { Gis } from '@/features/editor/utils/gis';
import { IVectorBounds } from '@/utils/vec';
import { ISurface, surfaceImageName } from '@/features/editor/utils/surface/surface';
import { isNil } from 'remeda';
import { EditorServices } from '@/features/editor';
import { FileUpload } from '@/features';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { useModal } from '@/features/modal/stores/use-modal-store';
import { ButtonIcon } from '@/components/button/button';
import { MdClose } from 'react-icons/md';
import { ModalDialogTitle } from '@/components/modal-dialog';
import { Typography } from '@/components/typography';
import { FormattedMessage } from 'react-intl';
import { Box } from '@/components/box';
import { FixedLoadingIndicator } from '@/components/loading-indicator';

function getSurfacesWithinSnapBounds(
  bounds: IVectorBounds,
  surfaces: ISurface[],
  map: google.maps.Map
): ISurface[] {
  const boundsBbox = Gis.bbox({
    topLeft: GoogleMapConversion.latLngToPoint(
      GoogleMapCoords.vectorToLatLng(new google.maps.Point(bounds.topLeft.x, bounds.topLeft.y), map)!
    ),
    bottomRight: GoogleMapConversion.latLngToPoint(
      GoogleMapCoords.vectorToLatLng(new google.maps.Point(bounds.bottomRight.x, bounds.bottomRight.y), map)!
    )
  });

  return surfaces.filter((surface) => Gis.booleanWithin(surface.path, boundsBbox));
}

function useScreenshotDoneSubscriber() {
  const store = useEditorStore();
  const modal = useModal();
  const { screenshotService } = EditorServices.Screenshot.useScreenshotService();
  const { map } = useGoogleMapContext();
  const { mutate: uploadFilesMutate } = FileUpload.useUploadFiles({
    onSuccess: (data) => {
      if (data) {
        const surfaceNames = Object.keys(data);
        const surfacesPayload = store.surfaces.surfaces
          .filter((surface) => surfaceNames.includes(surfaceImageName(surface)))
          .map((surface) => ({
            surfaceId: surface.id,
            imageSrc: data[surfaceImageName(surface)]
          }));

        store.surfaces.surfacesAttachImage(surfacesPayload);

        modal.openModal({
          children: (
            <Box sx={{ variant: 'cards.modal', p: 2 }}>
              <Box sx={{ px: 2, py: 2 }}>
                <ButtonIcon onClick={modal.closeModal}>
                  <MdClose size={20} />
                </ButtonIcon>
              </Box>

              <ModalDialogTitle
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: 'white',
                  width: 'auto',
                  maxWidth: 'auto',
                  height: 'auto'
                }}
              >
                <Typography sx={{ p: 4 }}>
                  <FormattedMessage
                    defaultMessage="Screenshot successfully taken. Image has been stored and attached to appropriate surface. Image can be previewed on the calculations screen in the surface card."
                    id="JmyZgE"
                    description="Label for snipping tool when image not created"
                  />
                </Typography>
              </ModalDialogTitle>
            </Box>
          )
        });
      } else {
        return modal.openModal({
          children: (
            <Box sx={{ variant: 'cards.modal', p: 2 }}>
              <Box sx={{ px: 2, py: 2 }}>
                <ButtonIcon onClick={modal.closeModal}>
                  <MdClose size={20} />
                </ButtonIcon>
              </Box>

              <ModalDialogTitle
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: 'white',
                  width: 'auto',
                  maxWidth: 'auto',
                  height: 'auto'
                }}
              >
                <Typography sx={{ p: 4 }}>
                  <FormattedMessage
                    defaultMessage="Problem occurred while taking screenshot, image was not successfully uploaded or found, please try again by selecting larger area."
                    id="TiQu7S"
                    description="Label for snipping tool when image not created"
                  />
                </Typography>
              </ModalDialogTitle>
            </Box>
          )
        });
      }
    }
  });

  React.useEffect(() => {
    const listener = screenshotService.subscribe((state) => {
      if (state.matches('idle')) {
        const { bounds, image } = state.context;
        if (isNil(bounds) || isNil(image)) {
          return;
        }

        const surfacesWithinBounds = getSurfacesWithinSnapBounds(bounds, store.surfaces.surfaces, map);

        screenshotService.send({
          type: 'RESET'
        });

        uploadFilesMutate(
          surfacesWithinBounds.map((surface) => ({
            object: image,
            name: surfaceImageName(surface)
          }))
        );
      }
    });

    return () => {
      listener.unsubscribe();
    };
  }, [map, screenshotService, store, uploadFilesMutate]);
}

export const MapScreenshot = observer(function MapScreenshot() {
  const { screenshotService } = EditorServices.Screenshot.useScreenshotService();
  const state = useSelector(screenshotService, (state) => state);

  const onBoundsChange = React.useCallback(
    (bounds: IVectorBounds) => {
      screenshotService.send({
        type: 'SNIPPING_DONE',
        payload: {
          bounds
        }
      });
    },
    [screenshotService]
  );

  useScreenshotDoneSubscriber();

  if (state.matches('idle') || state.matches('authorizing')) {
    return <React.Fragment />;
  }

  if (state.matches('snipping')) {
    return <ScreenshotArea onBoundsChange={onBoundsChange} />;
  }

  return <FixedLoadingIndicator />;
});
