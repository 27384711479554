import { EditorApi } from '@/features/editor';
import { useEditorCalculationId } from '@/features/editor/utils/use-editor-calculation-id';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { IGetAllProductsApiResponse } from '@/features/editor/api';
import { UnwrapArray } from '@/utils/types';
import { filterOptimizersByInverterFamily } from '@/features/editor/utils/optimizers/filter-optimizers-by-inverter-family';
import React from 'react';

type OptimizerProduct = UnwrapArray<IGetAllProductsApiResponse['optimizerTypes']>;
type InverterProduct = UnwrapArray<IGetAllProductsApiResponse['inverterTypes']>;

type InverterProductId = string;
type IInverterOptimizersReturn = Record<InverterProductId, OptimizerProduct[]>;

function getOptimizersForInverters(
  inverters: InverterProduct[],
  optimizers: OptimizerProduct[]
): IInverterOptimizersReturn {
  const result: IInverterOptimizersReturn = {};

  for (const inverter of inverters) {
    result[inverter.productId] = filterOptimizersByInverterFamily(inverter, optimizers);
  }

  return result;
}

export function useInverterOptimizers(): {
  inverterOptimizers: IInverterOptimizersReturn;
} {
  const { calculationId } = useEditorCalculationId();
  const allProducts = EditorApi.useGetAllProducts(calculationId);

  return {
    inverterOptimizers: React.useMemo(() => {
      if (isQueryLoading(allProducts.isLoading, allProducts.data)) {
        return {};
      }
      return getOptimizersForInverters(allProducts.data.inverterTypes, allProducts.data.optimizerTypes);
    }, [allProducts.data, allProducts.isLoading])
  };
}
