import { MapCircle } from '@/features/google-map/components/shapes/map-circle';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import React from 'react';
import { IMapPolygonOptions, MapPolygon } from '@/features/google-map/components/shapes/map-polygon';
import { IPoint } from '@/utils/gis/types';
import { Gis } from '@/features/editor/utils/gis';
import { R } from '@/lib/remeda';
import { getPointFromMouseEvent } from '@/features/google-map/utils/get-point-from-mouse-event';
import rhumbDestination from '@turf/rhumb-destination';
import rhumbBearing from '@turf/rhumb-bearing';
import { snapAngle } from '@/utils/gis/snap-angle';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { MapPolyline } from '@/features/google-map/components/shapes/map-polyline';
import { MapLengthLabel } from '@/features/google-map/components/shapes/map-length-label';
import { distanceVincenty } from '@/utils/gis/distance-vincenty';
import { formatLengthMarker } from '@/features/editor/utils/format-length-marker';
import { useContextMenuStore } from '@/features/editor/stores/use-context-menu-store';
import { getPositionFromMouseEvent } from '@/features/google-map/utils/get-position-from-mouse-event';
import { MdDelete } from 'react-icons/md';
import { ISide } from '@/utils/drawing/types';

interface IHandle {
  point: IPoint;
  opacity: number;
  index: number;
}

/**
 * Generates an array of "handles" for a polygon, where each
 * handle corresponds to a point on the polygon's path.
 *
 * The handles are generated by looping through the points in the polygon's path,
 * adding handles at each point and at the midpoint between each pair of points.
 * */
function getPolygonHandlePoints(path: IPoint[]): IHandle[] {
  const result: IHandle[] = [];
  for (let i = 0; i < path.length; i++) {
    const nextIndex = i + 1;

    const point = path[i]!;
    const nextPoint = path[nextIndex];

    result.push({
      point,
      opacity: 1,
      index: i
    });

    if (nextPoint) {
      result.push({
        point: Gis.midpoint(point, nextPoint),
        opacity: 0.5,
        index: i + 0.5
      });
    }
  }
  result.push({
    point: Gis.midpoint(R.last(path)!, R.first(path)!),
    opacity: 0.5,
    index: path.length - 1 + 0.5
  });

  return result;
}

/**
 * Get an element from an array.
 *
 * If the index is larger than the max, wrap around to the beginning.
 * If the index is smaller than the min, wrap around to the end.
 * */
function getAtWrapped<T>(arr: T[], index: number): T | undefined {
  if (index < 0) {
    return arr[arr.length + index];
  }
  if (index >= arr.length) {
    return arr[index - arr.length];
  }
  return arr[index];
}

function getOffsetPoint(from: IPoint, to: IPoint, distanceMeters: number = 0.5): IPoint {
  return rhumbDestination(from, distanceMeters, rhumbBearing(from, to), {
    units: 'meters'
  }).geometry.coordinates as IPoint;
}

// Describes the different distances (in meters) between the length marker and the handle
function zoomToLabelDistanceFromHandle(zoom: number) {
  if (zoom >= 26) {
    return 0.2;
  }
  if (zoom >= 23) {
    return 0.5;
  }
  if (zoom >= 21) {
    return 1;
  }
}

/**
 * When a handle index has a decimal value (1.5), it means the handle is between (1, 2).
 * This implies that the handle is used to create a new point in the polygon, rather
 * than updating an existing point (such as if the index was 1, for example).
 * */
function getUpdateModeFromHandleIndex(index: number): 'insert' | 'update' {
  return index % 1 === 0 ? 'update' : 'insert';
}

/**
 * The cursor triangle appears when the user is dragging a point.
 * It is made up of a middle point (the cursor point) and the ones before and after it.
 * */
function getCursorTrianglePoints(
  event: google.maps.MapMouseEvent,
  handleIndex: number,
  path: IPoint[]
): {
  middlePoint: IPoint;
  prevPoint: IPoint;
  nextPoint: IPoint;
} {
  const mode = getUpdateModeFromHandleIndex(handleIndex);
  // Insert mode has a decimal index, so we round down for the "previous"
  const prevIndex = mode === 'insert' ? Math.floor(handleIndex) : handleIndex - 1;
  // Insert mode has a decimal index, so we round up for the "next"
  const nextIndex = mode === 'insert' ? Math.ceil(handleIndex) : handleIndex + 1;

  const prevPoint = getAtWrapped(path, prevIndex)!;
  const nextPoint = getAtWrapped(path, nextIndex)!;
  const eventPoint = getPointFromMouseEvent(event)!;

  if ((event.domEvent as MouseEvent).shiftKey) {
    return {
      middlePoint: snapAngle(prevPoint, nextPoint, eventPoint),
      prevPoint,
      nextPoint
    };
  }

  return {
    middlePoint: eventPoint,
    prevPoint,
    nextPoint
  };
}

// The "insert handle" has an index which isn't a whole number (1.5, for example)
function isInsertHandleIndex(index: number): boolean {
  return index % 1 !== 0;
}

function HandleSideLengthMarker({
  side,
  mapZoom,
  calculateSideLength
}: {
  calculateSideLength?: (side: ISide) => number;
  side: ISide;
  mapZoom: number;
}) {
  return (
    <MapLengthLabel
      label={formatLengthMarker(
        calculateSideLength ? calculateSideLength(side) : distanceVincenty(side.start, side.end)
      )}
      point={
        mapZoom >= 22
          ? getOffsetPoint(side.start, side.end, zoomToLabelDistanceFromHandle(mapZoom))
          : Gis.midpoint(side.start, side.end)
      }
    />
  );
}

interface IMapEditablePolygonProps extends IMapPolygonOptions {
  id: string;
  isEditable?: boolean;
  calculateSideLength?: (side: ISide) => number;
  onPointUpdate: (data: {
    polygonId: string;
    point: IPoint;
    indexAt: number;
    mode: 'update' | 'insert';
  }) => void;
  onPointDelete: (data: { polygonId: string; indexAt: number }) => void;
}

export function MapEditablePolygon({
  isEditable = true,
  children,
  onPointUpdate,
  onPointDelete,
  calculateSideLength,
  ...props
}: IMapEditablePolygonProps) {
  const { map } = useGoogleMapContext();
  const { openContextMenu } = useContextMenuStore();

  const [dragPoints, setDragPoints] = React.useState<{
    middlePoint: IPoint;
    prevPoint: IPoint;
    nextPoint: IPoint;
  }>();

  const mapZoom = map.getZoom() ?? 0;
  const normalizedPath = Gis.geojsonPolygonPathNormalize(props.path);

  if (!isEditable) {
    return (
      <MapPolygon {...props} editable={false}>
        {children}
      </MapPolygon>
    );
  }

  return (
    <MapPolygon {...props} editable={false}>
      {getPolygonHandlePoints(normalizedPath).map(({ point, opacity, index }) => (
        <MapCircle
          onRightClick={(event) => {
            /**
             *  Checks if a handle can be removed.
             *  If the polygon has three handles then we can't remove one because
             *  in order to be a polygon it must have at least three handles
             * */
            if (props.path.length > 4 && !isInsertHandleIndex(index)) {
              openContextMenu({
                position: getPositionFromMouseEvent(event),
                items: [
                  {
                    key: 'remove-handles',
                    onAction: () => {
                      onPointDelete({
                        polygonId: props.id,
                        indexAt: index
                      });
                    },
                    children: [<MdDelete key="icon" />, <span key="text">Delete</span>]
                  }
                ]
              });
            }
          }}
          onDragEnd={(event) => {
            const mode = getUpdateModeFromHandleIndex(index);
            const { middlePoint } = getCursorTrianglePoints(event, index, normalizedPath);

            onPointUpdate({
              polygonId: props.id,
              point: middlePoint,
              indexAt: mode === 'insert' ? Math.ceil(index) : index,
              mode
            });

            setDragPoints(undefined);
          }}
          onDrag={(event) => {
            setDragPoints(getCursorTrianglePoints(event, index, normalizedPath));
          }}
          fillColor="white"
          clickable
          draggable
          zIndex={GOOGLE_MAP_Z_INDICES.EDIT_HANDLE}
          size={4}
          key={index}
          point={point}
          opacity={opacity}
        />
      ))}

      {dragPoints && (
        <React.Fragment>
          <MapPolyline
            path={[dragPoints.middlePoint, dragPoints.prevPoint]}
            strokeColor={props.strokeColor}
          />

          <MapPolyline
            path={[dragPoints.middlePoint, dragPoints.nextPoint]}
            strokeColor={props.strokeColor}
          />

          <HandleSideLengthMarker
            calculateSideLength={calculateSideLength}
            side={{
              start: dragPoints.middlePoint,
              end: dragPoints.prevPoint
            }}
            mapZoom={mapZoom}
          />

          <HandleSideLengthMarker
            calculateSideLength={calculateSideLength}
            side={{
              start: dragPoints.middlePoint,
              end: dragPoints.nextPoint
            }}
            mapZoom={mapZoom}
          />
        </React.Fragment>
      )}

      {children}
    </MapPolygon>
  );
}
