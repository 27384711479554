import { EditorApi } from '@/features/editor';
import React from 'react';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { R } from '@/lib/remeda';
import { ProjectInformationApi } from '@/features/project-information';
import { autorun, runInAction, toJS } from 'mobx';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { matchDeductionToClient } from '@/features/project-information/utils/match-deduction-to-client';
import { setSuitableOptimizersForInverters } from '@/features/editor/utils/machine-actions';

// Sets up a subscriber that will update whenever the state changes.
// This can be used to maintain some rules about the state.
export function useEditorRulesSubscriber({ calculationId }: { calculationId: number }) {
  useFinancialSupportRule({ calculationId });
  useValidOptimizersRule({ calculationId });
  useRemoveDeadSurfacesRule();
}

function useValidOptimizersRule({ calculationId }: { calculationId: number }) {
  const allProducts = EditorApi.useGetAllProducts(calculationId);
  const store = useEditorStore();

  React.useEffect(() => {
    if (isQueryLoading(allProducts.isLoading, allProducts.data)) {
      return;
    }

    const products = allProducts.data;

    const disposer = autorun(() => {
      /**
       * Due to how MobX tracks access, we need to access the observables
       * in a way that MobX can track them. This is why we use toJS() here.
       * */
      toJS(store.surfaces.surfaces);
      toJS(store.inverters.inverters);
      toJS(store.metadata.panelTypes);

      runInAction(() =>
        setSuitableOptimizersForInverters(
          {
            surfaces: store.surfaces.surfaces,
            inverters: store.inverters.inverters,
            panelTypes: store.metadata.panelTypes
          },
          {
            inverterProducts: products.inverterTypes,
            optimizerProducts: products.optimizerTypes
          }
        )
      );
    });

    return () => {
      disposer();
    };
  }, [allProducts.data, allProducts.isLoading, store]);
}

function useRemoveDeadSurfacesRule() {
  const store = useEditorStore();

  React.useEffect(() => {
    const disposer = autorun(() => {
      const surfaces = store.surfaces.surfaces;
      const surfaceIds = surfaces.map((surface) => surface.id);

      store.inverters.inverterRemoveDeadSurfaces({
        surfaceIds
      });
    });

    return () => {
      disposer();
    };
  }, [store]);
}

/**
 * Since the deduction types for a calculation are dependent on the client type,
 * a situation can happen where the client type is changed and the selected deduction
 * is no longer valid. This function ensures that the selected deduction is always valid.
 * */
export function useFinancialSupportRule(data?: { calculationId: number }) {
  const calculationDefaults = ProjectInformationApi.useCalculationDefaults(data);
  const store = useEditorStore();

  React.useEffect(() => {
    const disposer = autorun(() => {
      const deductions = calculationDefaults.data?.deductions ?? [];
      const { clientType, financialSupport } = store.projectInformation;

      const filteredDeductions = deductions.filter((deduction) =>
        matchDeductionToClient(deduction, clientType ?? 'privatePerson')
      );
      const existingDeduction = filteredDeductions.find((deduction) => deduction.id === financialSupport);

      if (!existingDeduction) {
        store.projectInformation.update((data) => {
          data.financialSupport = R.first(filteredDeductions)?.id;
        });
      }
    });

    return () => {
      disposer();
    };
  }, [calculationDefaults.data?.deductions, store.projectInformation]);
}
