import * as turf from '@turf/helpers';
import { IPoint } from '@/utils/gis/types';
import { degreesToRadians, Feature, Point, Polygon } from '@turf/helpers';
import { IRectanglePolygonOptions, RectanglePoint, rectanglePolygon } from '@/utils/turf/rectangle-polygon';
import { ISurface } from '@/features/editor/utils/surface/surface';
import transformTranslate from '@turf/transform-translate';
import rhumbDistance from '@turf/rhumb-distance';
import { turfPolygonPath } from '@/utils/turf/turf-polygon-path';
import rhumbBearing from '@turf/rhumb-bearing';
import booleanIntersects from '@turf/boolean-intersects';
import { z } from 'zod';
import { pointParser } from '@/utils/gis/point';

export const panelTypeParser = z.object({
  productId: z.string(),
  widthMeters: z.number(),
  heightMeters: z.number(),
  name: z.string(),
  power: z.number(),
  price: z.number()
});

export type IPanelType = z.infer<typeof panelTypeParser>;

/*
 * A manually placed panel
 *
 * As an example, a panel can be manually placed using the manual placement tool from the toolbar,
 * or by dragging and dropping an existing panel to a new location.
 * */
export const panelManualParser = z.object({
  // The internal ID of the panel
  id: z.string(),
  // The top-left point of the panel, used to draw the panel
  topLeft: pointParser,
  /*
   * The original top-left point of the panel.
   *
   * This will only exist if a generated panel is dragged and dropped into
   * a new location. Then, this will be the top-left point of the original.
   * */
  originalPoint: pointParser.optional(),
  // The angle of the panel, in degrees
  rotationAngle: z.number()
});

export type IPanelManual = z.infer<typeof panelManualParser>;

// Computes the new length of a side as seen from a 2D perspective, when the panel is tilted upward in 3D
function getTiltedSideLength(length: number, tilt: number): number {
  const adjacent = length;
  const hypotenuse = adjacent / Math.cos(degreesToRadians(tilt));
  return adjacent - adjacent * (adjacent / hypotenuse);
}

interface IPanelPolygonDimensionsOptions
  extends Pick<IPanelType, 'widthMeters' | 'heightMeters'>,
    Pick<ISurface, 'orientation'> {
  tiltAngle: number;
}

interface IPanelPolygonDimensionsReturn {
  widthMeters: number;
  heightMeters: number;
}

export function panelPolygonDimensions({
  widthMeters,
  heightMeters,
  tiltAngle,
  orientation
}: IPanelPolygonDimensionsOptions): IPanelPolygonDimensionsReturn {
  const panelHeightPortrait = heightMeters - getTiltedSideLength(heightMeters, tiltAngle);
  const panelHeightLandscape = widthMeters - getTiltedSideLength(widthMeters, tiltAngle);
  const panelWidthPortrait = widthMeters;
  const panelWidthLandscape = heightMeters;

  return {
    heightMeters: orientation === 'portrait' ? panelHeightPortrait : panelHeightLandscape,
    widthMeters: orientation === 'portrait' ? panelWidthPortrait : panelWidthLandscape
  };
}

interface IPanelPolygonOptions
  extends IPanelPolygonDimensionsOptions,
    Pick<IPanelManual, 'topLeft'>,
    IRectanglePolygonOptions {}
export function panelPolygon(options: IPanelPolygonOptions): Feature<Polygon> {
  const { widthMeters, heightMeters } = panelPolygonDimensions(options);
  return rectanglePolygon(turf.point(options.topLeft), widthMeters, heightMeters, {
    rotationAngle: options.rotationAngle,
    rotationPoint: options.rotationPoint
  });
}

// Moves a panel which was misplaced by a rotation back to its original position, while retaining the rotation
function moveToCursorSpawnPoint(rotatedPanel: Feature<Polygon>, cursorPoint: IPoint): Feature<Polygon> {
  return transformTranslate(
    rotatedPanel,
    rhumbDistance(RectanglePoint.topLeft(turfPolygonPath(rotatedPanel)), [...cursorPoint]),
    rhumbBearing(RectanglePoint.topLeft(turfPolygonPath(rotatedPanel)), [...cursorPoint])
  );
}

export interface IGeneratedPanelManual extends Pick<IPanelManual, 'originalPoint' | 'id'> {
  type: 'manual';
  panel: Feature<Polygon>;
}

export interface IGeneratedPanelAutomatic {
  type: 'automatic';
  id: string;
  panel: Feature<Polygon>;
}

// A panel which is shown on the screen as a polygon
export type GeneratedPanel = IGeneratedPanelManual | IGeneratedPanelAutomatic;

/*
 * Constructs an array of polygons for manually placed panels.
 *
 * Will ensure that if there are overlapping panels, only one will "win".
 * */
export function getManualPanels(
  panelsManual: IPanelManual[],
  panelDimensions: Pick<IPanelPolygonDimensionsOptions, 'widthMeters' | 'heightMeters'>,
  surfaceCenter: Feature<Point>
): (Pick<IPanelManual, 'originalPoint'> & {
  panel: Feature<Polygon>;
  type: 'manual';
  id: string;
})[] {
  const manualPanels = panelsManual.map((panel, index) => {
    return {
      panel: moveToCursorSpawnPoint(
        rectanglePolygon(
          turf.point(panel.topLeft),
          panelDimensions.widthMeters,
          panelDimensions.heightMeters,
          {
            rotationPoint: surfaceCenter.geometry.coordinates as IPoint,
            rotationAngle: panel.rotationAngle
          }
        ),
        panel.topLeft
      ),
      type: 'manual' as const,
      id: panel.id,
      originalPoint: panel.originalPoint,
      index
    };
  });
  return manualPanels.filter((panel) => {
    const overlapping = manualPanels.filter(
      (otherPanel) => otherPanel.index !== panel.index && booleanIntersects(otherPanel.panel, panel.panel)
    );
    if (overlapping.length === 0) {
      return true;
    }
    return overlapping.every((otherPanel) => otherPanel.index < panel.index);
  });
}
