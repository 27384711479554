import { IContextMenuPosition } from '@/components/context-menu';
import produce from 'immer';
import React from 'react';
import create from 'zustand';

interface IContextMenuItem {
  children: React.ReactNode[];
  textValue?: string;
  key: string;
  onAction: () => void;
}

interface IOpenOptions {
  position: IContextMenuPosition;
  items: IContextMenuItem[];
}

interface IContextMenuStore {
  position?: IContextMenuPosition;
  close: () => void;
  items: IContextMenuItem[];
  openContextMenu: (options: IOpenOptions) => void;
}

const useContextMenuStore = create<IContextMenuStore>((set) => ({
  items: [],
  close() {
    return set(
      produce((state: IContextMenuStore) => {
        state.position = undefined;
        state.items = [];
      })
    );
  },
  openContextMenu({ items, position }: IOpenOptions) {
    return set((state: IContextMenuStore) => ({ ...state, items, position }));
  }
}));

export { useContextMenuStore };
