import { IPoint } from '@/utils/gis/types';
import { R } from '@/lib/remeda';
import { z } from 'zod';

/**
 * Creates a point from a string.
 * Parses into a `[lng, lat]` format.
 * */
export function pointParseLngLat(coordinates: string): IPoint | undefined {
  const [lng, lat] = coordinates
    .split(',')
    .map((part) => part.trim())
    .map(parseFloat);

  if (R.isNil(lat) || R.isNil(lng) || Number.isNaN(lat) || Number.isNaN(lng)) {
    return undefined;
  }

  return [lng, lat];
}

export const pointParser = z.tuple([z.number(), z.number()]);

export function pointSerialize(point: IPoint): string {
  return point.join(', ');
}
