import { IOverlayImageWithBounds } from '@/features/google-map/utils/map-types/overlay-image-map';
import { makeAutoObservable } from 'mobx';
import { IEditorContext } from '@/features/editor/utils/editor-context';
import * as R from 'remeda';

export class OverlayImagesStore {
  public overlayImages: IOverlayImageWithBounds[] = [];

  public constructor({ initial }: { initial?: IEditorContext['overlayImages'] }) {
    makeAutoObservable(this);

    if (initial) {
      this.overlayImages = initial;
    }
  }

  public overlayImagesAdd = (images: IOverlayImageWithBounds[]) => {
    this.overlayImages.push(...images);
  };

  public overlayImagesRemove = (images: IOverlayImageWithBounds[]) => {
    const deletedSrc = new Set(images.map((image) => image.src));
    this.overlayImages = R.reject(this.overlayImages, (image) => deletedSrc.has(image.src));
  };
}
