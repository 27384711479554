import { Shape } from '@/features/google-map/utils/shapes/shape';
import { IBounds } from '@/utils/gis/types';
import * as R from 'remeda';

interface IRectangleOptions extends google.maps.RectangleOptions {}

export interface IRectangleShape {
  id: string;
  bounds: IBounds;
  options?: IRectangleOptions;
}

export class Rectangle extends Shape implements IRectangleShape {
  private readonly _bounds: IBounds;

  public constructor(id: string, bounds: IBounds, options?: IRectangleOptions) {
    super(id, R.merge({ strokeWeight: 1, bounds }, options));
    this._bounds = bounds;
  }

  public get bounds(): IBounds {
    return this._bounds;
  }

  public get options(): IRectangleOptions | undefined {
    return this._options;
  }

  public build(): google.maps.Rectangle {
    return new google.maps.Rectangle(this._options);
  }

  public update(shape: Rectangle): Rectangle {
    const result = new Rectangle(this._id, shape._bounds, R.merge(this._options, shape.options));
    result.eventListenersToAttach = shape.eventListenersToAttach;
    return result;
  }
}

export type { IRectangleOptions };
