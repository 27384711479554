// Copied from [react-hooks-worker](https://github.com/dai-shi/react-hooks-worker)

import React, { useEffect, useMemo, useRef, useState } from 'react';

interface State<Result> {
  result?: Result;
  error?: 'error' | 'messageerror';
}

const initialState = {};

/**
 * use worker
 *
 * The createWorker function should be stable to keep the worker running.
 * If it's referentially changed, it will create a new worker and terminate the old one.
 *
 * @example
 *
 * import { useWorker } from 'react-hooks-worker';
 *
 * const createWorker = () => new Worker(new URL('./slow_fib.worker', import.meta.url));
 *
 * const CalcFib = ({ count }) => {
 *   const { result, error } = useWorker(createWorker, count);
 *   if (error) return <div>Error: {error}</div>;
 *   return <div>Result: {result}</div>;
 * };
 */
export function useWorker<Input, Result>(
  createWorker: () => Worker,
  input: Input,
  getOptions?: () => WindowPostMessageOptions
) {
  const [state, setState] = useState<State<Result>>(initialState);
  const [status, setStatus] = React.useState<'loading' | 'error' | 'finished'>('loading');

  const worker = useMemo(createWorker, [createWorker]);
  const lastWorker = useRef<Worker>(worker);

  useEffect(() => {
    lastWorker.current = worker;
    let setStateSafe = (nextState: State<Result>) => setState(nextState);
    let setStatusSafe = (nextStatus: 'loading' | 'error' | 'finished') => setStatus(nextStatus);
    worker.onmessage = (e) => {
      setStatusSafe('finished');
      setStateSafe({ result: e.data });
    };
    worker.onerror = () => {
      setStatusSafe('error');
      setStateSafe({ error: 'error' });
    };
    worker.onmessageerror = () => {
      setStatusSafe('error');
      setStateSafe({ error: 'messageerror' });
    };
    const cleanup = () => {
      // eslint-disable-next-line react/function-component-definition
      setStateSafe = () => null; // we should not setState after cleanup.
      setStatusSafe = () => null;
      worker.terminate();
      setState(initialState);
      setStatus('finished');
    };
    return cleanup;
  }, [worker]);

  useEffect(() => {
    setStatus('loading');
    lastWorker.current.postMessage(input, getOptions?.());
  }, [input, getOptions]);
  return { state, status };
}
