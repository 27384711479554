import { makeAutoObservable } from 'mobx';
import { IObstacle } from '@/features/editor/utils/obstacle';
import { IPoint } from '@/utils/gis/types';
import { nanoid } from 'nanoid';
import { Gis } from '@/features/editor/utils/gis';
import { polygonPointUpdate, PolygonPointUpdatePayload } from '@/features/editor/utils/machine-actions';
import { IEditorContext } from '@/features/editor/utils/editor-context';

export class ObstaclesStore {
  public obstacles: IObstacle[] = [];

  public hoveredObstacleIds: Set<string> = new Set();

  public draggingObstacleIds: Set<string> = new Set();

  public copiedObstacle: IPoint[] = [];

  public constructor({ initial }: { initial?: Pick<IEditorContext, 'obstacles'> }) {
    makeAutoObservable(this);

    if (initial) {
      this.obstacles = initial.obstacles;
    }
  }

  public obstacleCreate = (path: IPoint[]) => {
    this.obstacles.push({
      id: nanoid(),
      path: Gis.geojsonPolygonPath(path)
    });
  };

  public obstacleUpdate = (id: string, fn: (obstacle: IObstacle) => void) => {
    const obstacle = this.obstacles.find((obstacle) => obstacle.id === id);
    if (obstacle) {
      fn(obstacle);
    }
  };

  public obstacleDelete = (id: string) => {
    this.obstacles = this.obstacles.filter((obstacle) => obstacle.id !== id);
  };

  public obstaclePointUpdate = (payload: PolygonPointUpdatePayload) => {
    polygonPointUpdate(this.obstacles, payload);
  };

  public obstaclesHover = (...ids: string[]) => {
    ids.forEach((id) => this.hoveredObstacleIds.add(id));
  };

  public obstaclesUnhover = (...ids: string[]) => {
    ids.forEach((id) => this.hoveredObstacleIds.delete(id));
  };

  public obstaclesDragStart = (...ids: string[]) => {
    ids.forEach((id) => this.draggingObstacleIds.add(id));
  };

  public obstaclesDragEnd = (...ids: string[]) => {
    ids.forEach((id) => this.draggingObstacleIds.delete(id));
  };

  public obstaclePaste = ({ pasteOrigin, copiedPoints }: { pasteOrigin: IPoint; copiedPoints: IPoint[] }) => {
    const topLeftMost = Gis.findTopLeftMost(copiedPoints);
    if (topLeftMost === undefined) {
      return;
    }

    const rhumbBearing = Gis.rhumbBearing(topLeftMost, pasteOrigin);
    const distance = Gis.distance(topLeftMost, pasteOrigin);
    const obstacleTopLeftManual = copiedPoints.map((point) =>
      Gis.transformTranslate(point, distance, rhumbBearing)
    );

    this.obstacles.push({
      id: nanoid(),
      path: Gis.geojsonPolygonPath(obstacleTopLeftManual)
    });
  };

  public obstacleCopy = (obstacle: IObstacle) => {
    this.copiedObstacle = obstacle.path;
  };
}
