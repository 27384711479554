import { View } from '@react-pdf/renderer';
import React from 'react';
import { EmptyPropsWithChildren } from '@/utils/types';
import { themeColors } from '@/lib/theme-ui/colors';
import { ComponentProps } from 'react';

interface IEditorResultsCardProps {
  title: React.ReactNode;
  children: React.ReactNode;
}
type ViewProps = ComponentProps<typeof View>;

interface IEditorResultsCardContentProps {
  style?: ViewProps['style'];
  children: React.ReactNode;
}
export function EditorResultsCardPrintable({ children, title }: IEditorResultsCardProps): JSX.Element {
  return (
    <EditorResultsCardPrintable.Wrapper>
      <EditorResultsCardPrintable.Title>{title}</EditorResultsCardPrintable.Title>
      <EditorResultsCardPrintable.Content>{children}</EditorResultsCardPrintable.Content>
    </EditorResultsCardPrintable.Wrapper>
  );
}

EditorResultsCardPrintable.Wrapper = function EditorResultsCardPrintableWrapper({
  children,
  style,
  ...props
}: ViewProps): JSX.Element {
  return (
    <View
      style={{
        borderRadius: 5,
        overflow: 'hidden',
        border: '1',
        borderColor: themeColors.inputBorder,
        backgroundColor: 'white',
        fontFamily: 'Inter',
        fontSize: 8,
        ...style
      }}
      {...props}
    >
      {children}
    </View>
  );
};

EditorResultsCardPrintable.Title = function EditorResultsCardPrintableTitle({
  children
}: EmptyPropsWithChildren) {
  return (
    <View
      style={{
        backgroundColor: themeColors.menuHover,
        color: '#303540',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderBottom: 1,
        borderColor: themeColors.inputBorder,
        fontSize: 9,
        textTransform: 'capitalize'
      }}
    >
      {children}
    </View>
  );
};

EditorResultsCardPrintable.Content = function EditorResultsCardPrintableContent({
  children,
  style
}: IEditorResultsCardContentProps) {
  return <View style={{ padding: 22, ...style }}>{children}</View>;
};
