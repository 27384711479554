/** @jsxImportSource theme-ui */
import { Label } from '@/components/label/label';
import { useMenuItem } from '@react-aria/menu';
import { TreeState } from '@react-stately/tree';
import { Node } from '@react-types/shared';
import React from 'react';

interface IMenuItemProps<T extends object> {
  item: Node<T>;
  state: TreeState<T>;
  onAction?: (item: React.Key) => void;
  onClose?: () => void;
}

export function MenuItem<T extends object>({
  item,
  state,
  onAction,
  onClose
}: IMenuItemProps<T>): JSX.Element {
  const isDisabled = state.disabledKeys.has(item.key);

  // Get props for the menu item element
  const ref = React.useRef<HTMLLIElement>(null);
  const { menuItemProps, labelProps } = useMenuItem(
    {
      key: item.key,
      isDisabled,
      onAction,
      onClose
    },
    state,
    ref
  );

  const [icon, title] = item.rendered as [React.ReactNode, React.ReactNode];

  return (
    <li
      {...menuItemProps}
      ref={ref}
      sx={{
        variant: 'text.menuItem',
        color: isDisabled ? 'disabled' : 'inherit',
        cursor: isDisabled ? 'default' : 'pointer'
      }}
    >
      <div
        sx={{
          mr: 3,
          display: 'inline-flex'
        }}
      >
        {icon}
      </div>
      <Label sx={{ cursor: 'inherit' }} {...labelProps}>
        {title}
      </Label>
    </li>
  );
}
