import { makeAutoObservable } from 'mobx';
import { IPanelType } from '@/features/editor/utils/panel/panel';

export interface IMetadataStore {
  panelTypes: IPanelType[];
  defaultPvgisLoss: number;
}

export class MetadataStore {
  public panelTypes: IPanelType[] = [];

  public defaultPvgisLoss: number = 14;

  public constructor(initial?: IMetadataStore) {
    makeAutoObservable(this);

    if (initial) {
      this.panelTypes = initial.panelTypes;
      this.defaultPvgisLoss = initial.defaultPvgisLoss;
    }
  }

  public update = (fn: (data: IMetadataStore) => void) => {
    fn(this);
  };
}
