import { IPoint } from '@/utils/gis/types';
import { positionToPoint } from '@/utils/turf/position-to-point';
import rhumbDestination from '@turf/rhumb-destination';
import { distanceVincenty } from '@/utils/gis/distance-vincenty';
import rhumbBearing from '@turf/rhumb-bearing';
import { bearingToAzimuth } from '@turf/helpers';
/**
 * Given length of initial 2 side and rectangle gets snapped under 90 degrees angle.
 * */
export function drawCustomLength(
  start: IPoint,
  middle: IPoint,
  end: IPoint,
  lengthSide: number,
  angleVal: number
): IPoint {
  return positionToPoint(
    rhumbDestination(
      middle,
      lengthSide ? lengthSide : Number(distanceVincenty(end, middle).toFixed(2)),
      angleVal > 0
        ? rhumbBearing(middle, start) + snap(getGeometricAngle(start, middle, end), angleVal)
        : rhumbBearing(middle, start) + getGeometricAngle(start, middle, end),
      {
        units: 'meters'
      }
    ).geometry.coordinates
  );
}

/**
 * Given the start, end, and middle point of a geographic triangle,
 * returns the geometric angle at the middle point.
 *
 * This is necessary because we need the actual angle of the triangle,
 * rather than the bearing between its sides.
 *
 * https://www.engineeringenotes.com/surveying/compass-surveying/calculation-of-angles-from-bearings-compass-surveying-surveying/13816
 * */
function getGeometricAngle(start: IPoint, middle: IPoint, end: IPoint): number {
  const midStart = bearingToAzimuth(rhumbBearing(middle, start));
  const midEnd = bearingToAzimuth(rhumbBearing(middle, end));

  return -(midStart - midEnd);
}

function snap(num: number, to: number): number {
  return Math.round(num / to) * to;
}
