import React from 'react';
import { NumberField } from '@/components/number-field/number-field';
import { MdRotate90DegreesCcw } from 'react-icons/md';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface ISurfaceOrientationInputProps {
  surfaceId: string;
}

export const SurfaceOrientationInput = observer(function SurfaceOrientationInput({
  surfaceId
}: ISurfaceOrientationInputProps): JSX.Element {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (surface === undefined) {
    return <React.Fragment />;
  }

  return (
    <NumberField
      icon={<MdRotate90DegreesCcw />}
      label="Panel orientation"
      formatOptions={{
        style: 'unit',
        unit: 'degree'
      }}
      value={surface.rotationAngle}
      onChange={(value) => {
        if (Number.isNaN(value)) {
          return;
        }
        store.surfaces.surfaceUpdate(surfaceId, (surface) => {
          surface.rotationAngle = value;
        });
      }}
    />
  );
});
