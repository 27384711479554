/** @jsxImportSource theme-ui */
import { LinkProps as LinkPropsThemeUi, Link as ThemeUiLink } from 'theme-ui';
import { Link as ReactPdfLink } from '@react-pdf/renderer';
import { ComponentProps } from 'react';

interface ILinkProps extends LinkPropsThemeUi {}

export function Link(props: ILinkProps): JSX.Element {
  return <ThemeUiLink sx={{ color: 'inherit', textDecoration: 'none' }} {...props} />;
}

export function LinkPdf(props: ComponentProps<typeof ReactPdfLink>): JSX.Element {
  return <ReactPdfLink style={{ color: '#303540', textDecoration: 'none' }} {...props} />;
}
