import * as ReactRouter from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { EditorMapRoute } from '@/features/editor/routes/editor-map-route';
import { EditorResultsRoute } from '@/features/editor-results/routes/editor-results-route';
import React from 'react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { getEditorResultsLoader } from '@/features/editor-results/api/get-editor-results';
import { EditorRoute } from '@/features/editor/routes/editor-route';
import ErrorPage from '@/components/error-page';
import { EsdecRoute } from '@/features/esdec/esdec-route';
import { DemoPage } from '@/components/demo-page';

const router = (queryClient: QueryClient): ReturnType<typeof createBrowserRouter> =>
  createBrowserRouter([
    {
      path: '',
      element: <EditorRoute />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: <EditorMapRoute />
        },
        {
          path: ':tab',
          element: <EditorMapRoute />
        },
        {
          path: 'results',
          element: <EditorResultsRoute />,
          loader: (args) => getEditorResultsLoader(args, queryClient)
        },
        {
          path: 'esdec',
          element: <EsdecRoute />
        },
        {
          path: 'demo',
          element: <DemoPage />
        }
      ]
    }
  ]);

export function ReactRouterProvider(): JSX.Element {
  const queryClient = useQueryClient();

  return <ReactRouter.RouterProvider router={router(queryClient)} />;
}
