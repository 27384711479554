/** @jsxImportSource theme-ui */
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { Typography } from '@/components/typography';
import { FormattedMessage } from 'react-intl';
import { ButtonSecondary } from '@/components/button/button';
import { Box } from '@/components/box';

export const OverlayImageInProgressControls = observer(function OverlayImageInProgressControls() {
  const store = useEditorStore();

  const { overlayImage } = store;
  const image = overlayImage.image;

  if (!image) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        flex: 1,
        p: 6
      }}
    >
      <Typography variant="headline3">
        <FormattedMessage defaultMessage="Overlay Image" id="7D9kQp" />
      </Typography>

      <label>
        <Typography sx={{ mb: 1 }}>
          <FormattedMessage defaultMessage="Opacity" id="PHutSR" /> ({image.opacity})
        </Typography>

        <input
          sx={{
            width: '100%'
          }}
          min={0.01}
          max={1}
          step={0.01}
          type="range"
          value={image.opacity}
          onChange={(event) => {
            overlayImage.updateImage((image) => {
              image.opacity = parseFloat(event.target.value);
            });
          }}
        />
      </label>

      <ButtonSecondary size="small" onClick={overlayImage.cancel}>
        <FormattedMessage defaultMessage="Clear image" id="uCeH05" />
      </ButtonSecondary>
    </Box>
  );
});
