const featureFlags = {
  screenshotTool: true,
  esdec: true
} as const;

function isFeatureFlagActive(featureFlag: keyof typeof featureFlags): boolean {
  return featureFlags[featureFlag];
}

export { isFeatureFlagActive };
