import { api } from '@/utils/api';
import { InferQueryOptions, UnwrapPromise } from '@/utils/types';
import { useQuery } from '@tanstack/react-query';
import { EditorApiKeys } from '@/features/editor/api/keys';
import { z } from 'zod';
import { R } from '@/lib/remeda';

const responseParser = z.union([z.record(z.string().nullable()), z.array(z.any())]);

export async function getHelpTextsApi(): Promise<Record<string, string>> {
  const response = await api.get('/helpTexts');
  const data = responseParser.parse(response.data);

  // The api returns empty array when there are no help texts
  // We want the response to always be a record, in this case an empty record
  if (Array.isArray(data)) {
    return {} as Record<string, string>;
  }

  return R.pipe(R.toPairs(data), R.filter(isValidRecordEntry), (i) => R.fromPairs(i));

  function isValidRecordEntry(entry: [string, string | null]): entry is [string, string] {
    return R.isDefined(entry[1]);
  }
}

export type IGetHelpTextsApiResponse = UnwrapPromise<typeof getHelpTextsApi>;

/**
 * Get all the help texts from the backend. Returns an object where the key is the name/category
 * of the help text and the value is the help text itself.
 * */
export function useGetHelpTexts(options?: InferQueryOptions<typeof getHelpTextsApi>) {
  return useQuery(EditorApiKeys.helpTexts, getHelpTextsApi, options);
}
