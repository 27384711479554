import { geojsonPolygonUpdate, GeoJsonPolygonUpdateMode } from '@/utils/gis/geojson-polygon-update';
import { IPoint } from '@/utils/gis/types';

export type PolygonPointUpdatePayload =
  | {
      mode: Extract<GeoJsonPolygonUpdateMode, 'update' | 'insert'>;
      point: IPoint;
      polygonId: string;
      indexAt: number;
    }
  | {
      mode: Extract<GeoJsonPolygonUpdateMode, 'remove'>;
      polygonId: string;
      indexAt: number;
    };

export function polygonPointUpdate(
  polygons: { path: IPoint[]; id: string }[],
  data: PolygonPointUpdatePayload
) {
  const polygon = polygons.find((polygon) => polygon.id === data.polygonId);
  if (polygon === undefined) {
    return;
  }

  switch (data.mode) {
    case 'insert': {
      geojsonPolygonUpdate({
        mode: 'insert',
        point: data.point,
        path: polygon.path,
        indexAt: data.indexAt
      });
      break;
    }
    case 'update': {
      geojsonPolygonUpdate({
        mode: 'update',
        point: data.point,
        path: polygon.path,
        indexAt: data.indexAt
      });
      break;
    }
    case 'remove': {
      geojsonPolygonUpdate({
        mode: 'remove',
        path: polygon.path,
        indexAt: data.indexAt
      });
      break;
    }
  }
}
