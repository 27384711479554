/** @jsxImportSource theme-ui */

import { useLocale } from '@react-aria/i18n';
import { useNumberFieldState } from '@react-stately/numberfield';
import { NumberFieldProps } from '@react-types/numberfield';
import React from 'react';
import { useNumberField } from '@react-aria/numberfield';
import { useButton } from '@react-aria/button';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { ButtonProps, CSSProperties, ThemeUIStyleObject } from 'theme-ui';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { useFocusRing } from '@react-aria/focus';
import { darken, lighten } from '@theme-ui/color';
import { Label } from '@/components/label/label';
import { Typography } from '@/components/typography';
import { HoverCard } from '@/components/hover-card/hover-card';

function interactiveStyles({
  isHovered,
  isFocused
}: {
  isFocused: boolean;
  isHovered: boolean;
}): ThemeUIStyleObject {
  if (isFocused) {
    return {
      borderColor: 'primary'
    };
  }

  if (isHovered) {
    return {
      borderColor: darken('inputBorder', 0.1)
    };
  }

  return {
    borderColor: 'inputBorder'
  };
}

interface IStepButtonProps extends ButtonProps {
  direction: 'up' | 'down';
  isHovered: boolean;
  isFocused: boolean;
}
const StepButton = React.forwardRef<HTMLButtonElement, IStepButtonProps>(
  ({ direction, isFocused, isHovered, ...props }, ref) => {
    function directionStyles(): CSSProperties {
      return direction === 'up'
        ? {
            borderTopRightRadius: 1,
            borderBottom: 'none',
            borderLeft: 'none'
          }
        : {
            borderBottomRightRadius: 1,
            borderLeft: 'none'
          };
    }

    return (
      <button
        ref={ref}
        {...props}
        sx={{
          background: 'transparent',
          padding: 0,
          px: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid',
          borderColor: 'inputBorder',
          backgroundColor: 'collapsibleBackground',
          color: 'inherit',

          transition: 'background-color 0.1s ease-in-out, border-color 0.1s ease-in-out',

          '&:hover': {
            backgroundColor: 'white'
          },

          ...directionStyles(),
          ...interactiveStyles({ isHovered, isFocused })
        }}
      >
        {props.children}
      </button>
    );
  }
);

export interface INumberFieldProps extends NumberFieldProps {
  icon?: React.ReactNode;
  className?: string;
  showStepper?: boolean;
  'aria-label'?: string;
  adornment?: React.ReactNode;

  // Provide a detail description for the field, which shows
  // up in a hover card when hovering the label
  fieldDetail?: React.ReactNode;
}

export function NumberField({ icon, showStepper, fieldDetail, ...props }: INumberFieldProps): JSX.Element {
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });

  const $input = React.useRef<HTMLInputElement>(null);
  const $increment = React.useRef<HTMLButtonElement>(null);
  const $decrement = React.useRef<HTMLButtonElement>(null);

  const { labelProps, groupProps, inputProps, incrementButtonProps, decrementButtonProps, descriptionProps } =
    useNumberField(props, state, $input);

  const { buttonProps: incrementProps } = useButton(incrementButtonProps, $increment);
  const { buttonProps: decrementProps } = useButton(decrementButtonProps, $decrement);

  const { isHovered, hoverProps } = useHover({});
  const { focusProps, isFocused } = useFocusRing();

  function getLabel() {
    if (props.label === undefined) {
      return null;
    }

    const label = (
      <Label sx={{ mb: 1, width: 'fit-content' }} {...labelProps}>
        {props.label}
      </Label>
    );

    if (fieldDetail === undefined) {
      return label;
    }

    return <HoverCard content={fieldDetail}>{label}</HoverCard>;
  }

  return (
    <div sx={{ color: 'text', isolation: 'isolate' }}>
      {getLabel()}

      <div
        {...groupProps}
        sx={{
          display: 'flex',
          color: 'inputText',
          position: 'relative'
        }}
      >
        {icon !== undefined && (
          <div
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              borderColor: 'inputBorder',
              borderRight: 'none',
              borderTopLeftRadius: 1,
              borderBottomLeftRadius: 1,
              px: 2,
              transition: 'border-color 0.1s ease-in-out',
              ...interactiveStyles({ isHovered, isFocused }),
              color: 'inherit'
            }}
          >
            {icon}
          </div>
        )}

        <input
          {...mergeProps(inputProps, focusProps, hoverProps)}
          ref={$input}
          sx={{
            width: '100%',
            margin: '0px',
            flexGrow: 1,
            border: '1px solid',
            color: 'inherit',
            outline: 'none',
            px: 3,
            py: showStepper ? 1 : 2,
            fontFamily: 'body',
            '&:disabled': {
              color: 'inherit',
              backgroundColor: 'rgba(239, 239, 239, 0.6)'
            },
            transition: 'border-color 0.1s ease-in-out',

            '&::placeholder': {
              color: lighten('inputText', 0.4)
            },

            ...(icon === undefined && {
              borderTopLeftRadius: 1,
              borderBottomLeftRadius: 1
            }),
            ...(!showStepper && {
              borderTopRightRadius: 1,
              borderBottomRightRadius: 1
            }),
            ...(props.adornment && {
              pr: 11
            }),
            ...interactiveStyles({ isHovered, isFocused })
          }}
        />

        {showStepper ? (
          <div>
            <StepButton
              {...incrementProps}
              sx={{ margin: '0px' }}
              ref={$increment}
              direction="up"
              isFocused={isFocused}
              isHovered={isHovered}
            >
              <MdExpandLess size={16} />
            </StepButton>
            <StepButton
              {...decrementProps}
              sx={{ margin: '0px' }}
              ref={$decrement}
              direction="down"
              isFocused={isFocused}
              isHovered={isHovered}
            >
              <MdExpandMore size={16} />
            </StepButton>
          </div>
        ) : null}

        {props.adornment !== undefined ? (
          <Typography
            variant="body2"
            sx={{
              ml: 4,
              position: 'absolute',
              right: 3,
              top: '50%',
              transform: 'translateY(-50%)',
              pointerEvents: 'none',
              color: lighten('inputText', 0.4)
            }}
          >
            {props.adornment}
          </Typography>
        ) : null}
      </div>

      {props.description && (
        <Label sx={{ mt: 1 }} {...descriptionProps}>
          {props.description}
        </Label>
      )}
    </div>
  );
}
