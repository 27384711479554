/** @jsxImportSource theme-ui */
import { Box } from 'theme-ui';
import { EsdecMountingSystemTypeCard } from './esdec-mounting-system-type-card';
import flatFixFusion from '@/assets/images/esdec-flatfix-fusion.png';
import flatFixWavePlus from '@/assets/images/esdec-flatfix-wave-plus.png';
import clickFitEvo from '@/assets/images/esdec-clickfit-evo.png';
import clickFitBasic from '@/assets/images/esdec-clickfit-basic.png';
import { FormattedMessage } from 'react-intl';
import { ButtonPrimary } from '@/components/button/button';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import React from 'react';

export const EsdecMountingSystemType = observer(function EsdecMountingSystemType({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const { surfacesEsdec } = store;
  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (!surfaceEsdec || !surface) {
    return <React.Fragment />;
  }

  /* auto choose CLICKFIT BASIC after deciding on client call */
  surfaceEsdec.updatePreferredProduct('CLICKFIT_BASIC');

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#F9FAFA', padding: 3, gap: 5 }}>
      {surface.placement === 'flat' ? (
        <React.Fragment>
          <EsdecMountingSystemTypeCard
            imageSrc={flatFixFusion}
            title={
              <FormattedMessage
                defaultMessage="The flexible click system for smaller flat roofs"
                id="vLug1G"
              />
            }
            bulletPoints={[
              <FormattedMessage
                defaultMessage="Max. panel size (l x w): 2190mm x 1150mm"
                id="AA7WWR"
                key="AA7WWR"
              />,
              <FormattedMessage defaultMessage="Pitch length adjustable" id="2Bq4t4" key="2Bq4t4" />,
              <FormattedMessage
                defaultMessage="Ideal for residential and small commercial roofs"
                id="1HYeXi"
                key="1HYeXi"
              />,
              <FormattedMessage
                defaultMessage="In East/West and South orientation"
                id="djtdFX"
                key="djtdFX"
              />,
              <FormattedMessage
                defaultMessage="Ballast calculation Peutz certified"
                id="1eVUZh"
                key="1eVUZh"
              />
            ]}
          >
            <ButtonPrimary
              sx={{ marginTop: 'auto' }}
              onClick={() => {
                surfaceEsdec.updatePreferredProduct('FLATFIX_FUSION');
              }}
            >
              <FormattedMessage defaultMessage="FLATFIX FUSION" id="LtfEUd" />
            </ButtonPrimary>
          </EsdecMountingSystemTypeCard>

          <EsdecMountingSystemTypeCard
            imageSrc={flatFixWavePlus}
            title={
              <FormattedMessage
                defaultMessage="The new standard for large-scale flat roof projects"
                id="hZJePC"
              />
            }
            bulletPoints={[
              <FormattedMessage
                defaultMessage="Max. panel size (l x w): 2400mm x 1150mm"
                id="L4n9nJ"
                key="L4n9nJ"
              />,
              <FormattedMessage
                defaultMessage="Longer panels up to 2500mm possible in consultation with customer support"
                id="jVrT1G"
                key="jVrT1G"
              />,
              <FormattedMessage defaultMessage="Pitch length max. 2460mm" id="i79unO" key="i79unO" />,
              <FormattedMessage
                defaultMessage="Ideal for large-scale, high-end commercial projects with large panels"
                id="vGHLvQ"
                key="vGHLvQ"
              />,
              <FormattedMessage defaultMessage="East/West orientation only" id="hiAHhD" key="hiAHhD" />,
              <FormattedMessage
                defaultMessage="Ballast calculation Peutz certified"
                id="1eVUZh"
                key="1eVUZh"
              />
            ]}
          >
            <ButtonPrimary
              sx={{ marginTop: 'auto' }}
              onClick={() => {
                surfaceEsdec.updatePreferredProduct('FLATFIX_WAVE_PLUS');
              }}
            >
              <FormattedMessage defaultMessage="FLATFIX WAVE PLUS" id="cYQuFu" />
            </ButtonPrimary>
          </EsdecMountingSystemTypeCard>
        </React.Fragment>
      ) : (
        <>
          <EsdecMountingSystemTypeCard
            imageSrc={clickFitEvo}
            title={<FormattedMessage defaultMessage="Universal mounting system" id="yCVPk7" />}
            bulletPoints={[
              <FormattedMessage
                defaultMessage="For tiled, steel, corrugated, standing seam, bitumen, EPMD and Arabic tiled roofs."
                id="zcjfwS"
                key="zcjfwS"
              />,
              <FormattedMessage defaultMessage="40% faster installation" id="NcqeFZ" key="NcqeFZ" />,
              <FormattedMessage defaultMessage="Only 4 components on the roof" id="G5WYmn" key="G5WYmn" />,
              <FormattedMessage defaultMessage="Panels up to 2.6 m2" id="Mwbnqf" key="Mwbnqf" />
            ]}
          >
            <ButtonPrimary
              sx={{ marginTop: 'auto' }}
              onClick={() => {
                surfaceEsdec.updatePreferredProduct('CLICKFIT_EVO');
              }}
            >
              <FormattedMessage defaultMessage="CLICKFIT EVO" id="cJbJCP" />
            </ButtonPrimary>
          </EsdecMountingSystemTypeCard>

          <EsdecMountingSystemTypeCard
            imageSrc={clickFitBasic}
            title={<FormattedMessage defaultMessage="Basic mounting system" id="UeZxg/" />}
            bulletPoints={[
              <FormattedMessage
                defaultMessage="For tile, steel, corrugated sheet, standing seam, EPDM and bitumen roofs"
                id="KeUImo"
                key="KeUImo"
              />,
              <FormattedMessage
                defaultMessage="Slate roofs in consultation with customer support"
                id="kDUlZB"
                key="kDUlZB"
              />,
              <FormattedMessage defaultMessage="Panels up to 2.25m2" id="isLJAO" key="isLJAO" />
            ]}
          >
            <ButtonPrimary
              sx={{ marginTop: 'auto' }}
              onClick={() => {
                surfaceEsdec.updatePreferredProduct('CLICKFIT_BASIC');
              }}
            >
              <FormattedMessage defaultMessage="CLICKFIT BASIC" id="F1OudO" />
            </ButtonPrimary>
          </EsdecMountingSystemTypeCard>
        </>
      )}
    </Box>
  );
});
