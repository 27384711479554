import { NumberField } from '@/components/number-field/number-field';
import { Typography } from '@/components/typography';
import React from 'react';
import { MOUNTING_SYSTEM_DEFAULT_SETBACK_METERS } from '@/features/editor/utils/mounting-system';
import { Icons } from '@/assets';
import { SolarUtils } from '@/utils/solar-utils';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';

interface ISurfaceSetbackInputProps {
  surfaceId: string;
}

export const SurfaceSetbackInput = observer(function SurfaceSetbackInput({
  surfaceId
}: ISurfaceSetbackInputProps): JSX.Element {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (!surface) {
    return <React.Fragment />;
  }

  function renderSetbackWarning(): JSX.Element | undefined {
    if (!surface) {
      return undefined;
    }
    return surface.setbackMeters < MOUNTING_SYSTEM_DEFAULT_SETBACK_METERS ? (
      <Typography sx={{ color: 'warning' }} variant="body2">
        Setback is below the {SolarUtils.meterToMillimeter(MOUNTING_SYSTEM_DEFAULT_SETBACK_METERS)}mm
        recommended by the mounting system
      </Typography>
    ) : (
      <React.Fragment />
    );
  }

  return (
    <NumberField
      minValue={0}
      description={renderSetbackWarning()}
      value={SolarUtils.meterToMillimeter(surface.setbackMeters)}
      onChange={(value) => {
        if (Number.isNaN(value)) {
          return;
        }
        store.surfaces.surfaceUpdate(surfaceId, (surface) => {
          surface.setbackMeters = SolarUtils.millimeterToMeter(value);
        });
      }}
      icon={<Icons.Setback />}
      label="Setback"
      formatOptions={{
        style: 'unit',
        unit: 'millimeter'
      }}
    />
  );
});
