import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import React from 'react';
import { IRadioGroupProps, RadioGroup } from '@/components/radio-group/radio-group';

interface IRhfRadioGroupProps<TData extends FieldValues> extends IRadioGroupProps {
  control: Control<TData>;
  name: FieldPath<TData>;
}

export function RhfRadioGroup<TData extends FieldValues>(props: IRhfRadioGroupProps<TData>): JSX.Element {
  const { control, name, children } = props;
  const controller = useController({
    control,
    name
  });

  return (
    <RadioGroup
      {...props}
      value={String(controller.field.value)}
      name={controller.field.name}
      onChange={controller.field.onChange}
    >
      {children}
    </RadioGroup>
  );
}
