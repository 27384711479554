/** @jsxImportSource theme-ui */
import { SelectField } from '@/components/select-field/select-field';
import React from 'react';
import { Flex } from 'theme-ui';
import { Typography } from '@/components/typography';
import { Box } from '@/components/box';
import { EditorApi } from '@/features/editor';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { useEditorContext } from '@/features/editor/stores/use-editor-context';
import { InverterDetails } from '@/features/editor/components/inverters/inverter-details';
import { FormattedMessage } from 'react-intl';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { R } from '@/lib/remeda';
import { useOptimizers } from '@/features/editor/utils/optimizers/use-optimizers';
import { useInverters } from '@/features/editor/utils/inverters/use-inverters';
import { Alert } from '@/components/alert/alert';
import { SolarUtils } from '@/utils/solar-utils';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface IOptimizerTypeSelectProps {
  inverterId: string;
}

export const OptimizerTypeSelect = observer(function OptimizerTypeSelect({
  inverterId
}: IOptimizerTypeSelectProps): JSX.Element {
  const formatCurrency = useFormatCurrency();

  const { calculationId } = useEditorContext();
  const allProducts = EditorApi.useGetAllProducts(calculationId);

  const surfaces = useSurfaces();
  const optimizers = useOptimizers();
  const inverters = useInverters();
  const store = useEditorStore();
  const inverter = inverters.find((inverter) => inverter.id === inverterId);

  if (!inverter || isQueryLoading(allProducts.isLoading, allProducts.data)) {
    return <React.Fragment />;
  }

  const inverterType = allProducts.data.inverterTypes.find(
    (inverterType) => inverterType.productId === inverter.inverterTypeId
  );

  if (R.isNil(inverterType)) {
    return <React.Fragment />;
  }

  const inverterSurfaces = surfaces.filter((surface) => inverter.surfaceIds.includes(surface.id));
  const optimizersCount = inverterSurfaces.reduce(
    (total, surface) => total + (optimizers[surface.id]?.length ?? 0),
    0
  );

  const hasOptimizers = optimizersCount > 0;
  const options = inverter.suitableOptimizers.map((optimizer) => ({
    value: optimizer.productId,
    label: `${optimizer.name} | ${formatCurrency(optimizer.price)}`
  }));

  const optimizerType = inverter.suitableOptimizers.find(
    (optimizer) => optimizer.productId === inverter.optimizerTypeId
  );

  const value = options.find((option) => option.value === inverter.optimizerTypeId);
  const showNoOptionsInfo = hasOptimizers && options.length === 0;

  function optimizerTypeHelperText() {
    if (showNoOptionsInfo) {
      return (
        <Alert>
          <Typography>
            <FormattedMessage
              defaultMessage="You have selected a combination of inverter type and panel type for which there are no optimizers."
              id="GMFoPF"
            />
          </Typography>
        </Alert>
      );
    }

    if (!hasOptimizers && inverterType?.isOptimizerRequired) {
      return (
        <Alert>
          <Typography as="span">
            <FormattedMessage
              defaultMessage="You have selected an inverter which requires an optimizer. Please add an optimizer on the map."
              id="nzoh/i"
            />
            <br />
            <br />
            <em>
              <FormattedMessage
                defaultMessage="Hint: you can add an optimizer by right-clicking on a panel"
                id="4TK7nQ"
              />
            </em>
          </Typography>
        </Alert>
      );
    }

    return undefined;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <SelectField
        label="Optimizer type"
        value={value ?? null}
        options={options}
        onChange={(newValue) => {
          store.inverters.inverterUpdate(inverterId, (inverter) => {
            inverter.optimizerTypeId = newValue?.value;
          });
        }}
        name="optimizerType"
        id="optimizerType"
        isClearable={!hasOptimizers}
        isDisabled={!hasOptimizers}
        helperText={
          !hasOptimizers ? (
            <React.Fragment>
              <FormattedMessage
                defaultMessage="Add an optimizer on the map to view the products list."
                id="nB99fj"
              />{' '}
              <em>
                <FormattedMessage
                  defaultMessage="Hint: you can add an optimizer by right-clicking on a panel"
                  id="4TK7nQ"
                />
              </em>
            </React.Fragment>
          ) : undefined
        }
      />

      {optimizerType && (
        <Flex
          sx={{
            flexDirection: 'column',
            gap: 2,
            backgroundColor: '#EEEFF4',
            p: 4,
            border: '1px solid #0000001F',
            borderRadius: 4
          }}
        >
          <Typography sx={{ fontWeight: 500, mb: 1 }}>{optimizerType.name}</Typography>
          <InverterDetails label="Price:" value={formatCurrency(optimizerType.price)} />
          <InverterDetails
            label="Peak Power:"
            value={`${SolarUtils.wattToKilowatt(optimizerType.production)} kWp`}
          />
          <InverterDetails
            label="Warranty:"
            value={
              <span>
                {optimizerType.warrantyTime}{' '}
                <FormattedMessage
                  defaultMessage="years"
                  id="Za9f1V"
                  description='Label for a number of years: "10 years"'
                />
              </span>
            }
          />
          <InverterDetails label="Quantity:" value={optimizersCount} />
        </Flex>
      )}

      {optimizerTypeHelperText()}
    </Box>
  );
});
