import { GroupBase } from 'react-select';
import React from 'react';
import { Field } from '@/components/field';
import {
  DropdownIndicator,
  ISelectFieldProps,
  selectStyles,
  selectTheme,
  MultiValue
} from './select-field-common';
import AsyncSelect, { AsyncProps } from 'react-select/async';

interface ISelectProps<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>
  extends Omit<AsyncProps<T, IsMulti, Group>, 'name'>,
    ISelectFieldProps {}

export function AsyncSelectField<
  Value,
  IsMulti extends boolean = false,
  Group extends GroupBase<Value> = GroupBase<Value>
>({
  label,
  name,
  errorMessage,
  helperText,
  isLabelLifted = false,
  value,
  required = false,
  onFocus,
  onBlur,
  ...props
}: ISelectProps<Value, IsMulti, Group>): JSX.Element {
  const [isActive, setIsActive] = React.useState(false);

  function onMenuClose(): void {
    setIsActive(false);
  }

  function onMenuOpen(): void {
    setIsActive(true);
  }

  return (
    <Field
      helperText={helperText}
      label={label}
      isLabelAlwaysLifted
      hasValue={Boolean(value)}
      errorMessage={errorMessage}
      name={name}
      required={required}
      isActive={isActive}
      isHovered={false}
      isolate={false}
    >
      <AsyncSelect
        {...props}
        name={name}
        value={value}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        blurInputOnSelect={true}
        menuIsOpen={props.menuIsOpen}
        closeMenuOnSelect={false}
        onFocus={(event) => {
          onFocus?.(event);
          setIsActive(true);
        }}
        onBlur={(event) => {
          onBlur?.(event);
          setIsActive(false);
        }}
        components={{
          DropdownIndicator,
          MultiValue,
          ...props.components
        }}
        theme={selectTheme}
        styles={selectStyles}
      />
    </Field>
  );
}
