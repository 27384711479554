import { IPoint } from '@/utils/gis/types';
import buffer from '@turf/buffer';
import * as turf from '@turf/helpers';
import { turfPolygonPath } from '@/utils/turf/turf-polygon-path';
import { isValidPolygonPath } from '@/utils/turf/is-valid-polygon-path';
import { Gis } from '@/features/editor/utils/gis';

interface IUseSurfaceWithSetbackHasSetback {
  path: IPoint[];
  hasSetback: true;
}

interface IUseSurfaceWithSetbackNoSetback {
  hasSetback: false;
}

// Get the surface path, adjusted for setback
export function surfaceWithSetback(
  path: IPoint[],
  shrinkMeters: number
): IUseSurfaceWithSetbackHasSetback | IUseSurfaceWithSetbackNoSetback {
  if (shrinkMeters === 0) {
    return { hasSetback: false };
  }

  const bufferPolygon = buffer(turf.polygon([Gis.geojsonPolygonPath(path)]), -shrinkMeters, {
    units: 'meters'
  });

  // Type lies
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (bufferPolygon === undefined) {
    return { hasSetback: false };
  }
  const bufferPolygonPath = turfPolygonPath(bufferPolygon);
  return isValidPolygonPath(bufferPolygonPath)
    ? {
        hasSetback: true,
        path: bufferPolygonPath
      }
    : { hasSetback: false };
}
