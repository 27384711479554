import * as turf from '@turf/helpers';
import turfCenter from '@turf/center';
import turfBooleanIntersects from '@turf/boolean-intersects';
import turfDistance from '@turf/distance';
import turfMidpoint from '@turf/midpoint';
import turfTruncate from '@turf/truncate';
import turfArea from '@turf/area';
import turfBooleanPointInPolygon from '@turf/boolean-point-in-polygon';
import turfRhumbBearing from '@turf/rhumb-bearing';
import turfRhumbDestination from '@turf/rhumb-destination';
import turfBooleanWithin from '@turf/boolean-within';
import turfBooleanOverlap from '@turf/boolean-overlap';
import transformTranslate from '@turf/transform-translate';
import turfLineIntersect from '@turf/line-intersect';
import turfTransformRotate from '@turf/transform-rotate';
import {
  bearingToAzimuth as turfBearingToAzimuth,
  Feature,
  LineString,
  Point,
  Polygon,
  Units
} from '@turf/helpers';
import { IPoint } from '@/utils/gis/types';
import { polygon } from '@turf/helpers';
import { isValidPolygonPath } from '@/utils/turf/is-valid-polygon-path';
import * as R from 'remeda';
import { isPointEqual } from '@/utils/gis/is-point-equal';
import { deepEqual } from '@/utils/deep-equal';

function isPoint(point: IPoint | IPoint[]): point is IPoint {
  return typeof point[0] === 'number' && typeof point[1] === 'number';
}

function getTurfShapeFromPath<Path extends IPoint | IPoint[]>(
  path: Path
): Path extends IPoint ? Point : Polygon | LineString {
  if (isPoint(path)) {
    // Types are OK here
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return turf.point(path) as any;
  }

  if (isValidPolygonPath(path)) {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return turf.polygon([path]) as any;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return turf.lineString(path) as any;
}

// Utilities for Gis math
export class Gis {
  public static midpoint(a: IPoint, b: IPoint): IPoint {
    return turfMidpoint(a, b).geometry.coordinates as IPoint;
  }

  public static bearingToAzimuth(bearing: number): number {
    return turfBearingToAzimuth(bearing);
  }

  public static rhumbBearing(
    start: IPoint,
    end: IPoint,
    options?: {
      final?: boolean;
    }
  ): number {
    return turfRhumbBearing(start, end, options);
  }

  public static booleanPointInPolygon(
    point: IPoint,
    path: IPoint[],
    options?: {
      ignoreBoundary?: boolean;
    }
  ): boolean {
    return turfBooleanPointInPolygon(point, polygon([path]), options);
  }

  public static rhumbDestination(
    origin: IPoint,
    distance: number,
    bearing: number,
    options?: {
      units?: Units;
    }
  ): IPoint {
    return turfRhumbDestination(origin, distance, bearing, options).geometry.coordinates as IPoint;
  }

  public static area(path: IPoint[]): number {
    return turfArea(polygon([path]));
  }

  // Convert a GeoJSON point to a point
  public static pointFeatureToPoint(point: Feature<Point>): IPoint {
    return point.geometry.coordinates as IPoint;
  }

  // Convert a GeoJSON polygon to a point array
  public static polygonFeatureToPath(polygon: Feature<Polygon>): IPoint[] {
    return polygon.geometry.coordinates[0] as IPoint[];
  }

  public static truncate(
    point: IPoint,
    options?: {
      precision?: number;
      coordinates?: number;
      mutate?: boolean;
    }
  ): IPoint {
    return Gis.pointFeatureToPoint(turfTruncate(turf.point(point), options));
  }

  public static booleanWithin(a: IPoint[], b: IPoint[]): boolean {
    return turfBooleanWithin(polygon([a]), polygon([b]));
  }

  public static booleanOverlap(a: IPoint[], b: IPoint[]): boolean {
    return turfBooleanOverlap(polygon([a]), polygon([b]));
  }

  public static flip(point: IPoint): IPoint {
    return [point[1], point[0]];
  }

  public static bbox({ topLeft, bottomRight }: { topLeft: IPoint; bottomRight: IPoint }): IPoint[] {
    const [minX, minY] = topLeft;
    const [maxX, maxY] = bottomRight;

    return [
      [minX, minY],
      [maxX, minY],
      [maxX, maxY],
      [minX, maxY],
      [minX, minY]
    ];
  }

  public static distance(a: IPoint, b: IPoint, options?: { units?: Units }): number {
    return turfDistance(a, b, options);
  }

  public static booleanIntersects(a: IPoint[], b: IPoint[]): boolean {
    return turfBooleanIntersects(getTurfShapeFromPath(a), getTurfShapeFromPath(b));
  }

  public static transformTranslate<T extends IPoint | IPoint[]>(
    path: T,
    distance: number,
    bearing: number,
    options?: {
      units?: Units;
      zTranslation?: number;
      mutate?: boolean;
    }
  ): T {
    if (isPoint(path)) {
      return transformTranslate(turf.point(path), distance, bearing, options).geometry.coordinates as T;
    }

    const isPolygon = isValidPolygonPath(path);
    if (isPolygon) {
      return transformTranslate(polygon([path]), distance, bearing, options).geometry.coordinates[0] as T;
    }

    return transformTranslate(turf.lineString(path), distance, bearing, options).geometry.coordinates as T;
  }

  // Takes a path and makes it GeoJSON compatible if it isn't already
  public static geojsonPolygonPath(path: IPoint[]): IPoint[] {
    const first = R.first(path);
    const last = R.last(path);

    if (first === undefined || last === undefined) {
      throw new Error('Invalid path');
    }

    if (isPointEqual(first, last)) {
      return path;
    }

    return [...path, R.first(path)!];
  }

  public static isValidGeoJsonPath(path: IPoint[]): boolean {
    return path.length >= 4 && isPointEqual(R.first(path), R.last(path));
  }

  /*
   * GeoJSON requires that the first and last coordinate in a polygon path be the same.
   *
   * This function will normalize the path by removing the last coordinate if it is the same as the first.
   */
  public static geojsonPolygonPathNormalize(path: IPoint[]): IPoint[] {
    const first = path[0];
    const last = path[path.length - 1];

    if (deepEqual(first, last) && path.length > 1) {
      return path.slice(0, path.length - 1);
    }

    return path;
  }

  public static lineIntersect<Path extends IPoint[]>(a: Path, b: Path): IPoint[] {
    const result = turfLineIntersect(getTurfShapeFromPath(a), getTurfShapeFromPath(b));
    return result.features.map((point) => point.geometry.coordinates as IPoint);
  }

  public static center(path: IPoint[]): IPoint {
    return turfCenter(getTurfShapeFromPath(path)).geometry.coordinates as IPoint;
  }

  public static findTopLeftMostPoint(points: IPoint[]): IPoint | undefined {
    return R.pipe(
      points,
      //we add 360 to a longitudes that are crossing the 180th meridian
      R.map((point) => [point[0] < 0 ? point[0] + 360 : point[0], point[1]] as IPoint),
      (x) => R.sortBy(x, [(point) => point[1], 'desc'], [(point) => point[0], 'asc']),
      R.first()
    );
  }

  public static transformRotate<Path extends IPoint | IPoint[]>(
    path: Path,
    angle: number,
    options?: {
      pivot?: IPoint;
      mutate?: boolean;
    }
  ): Path {
    if (isPoint(path)) {
      return turfTransformRotate(turf.point(path), angle, options).geometry.coordinates as Path;
    }

    const isPolygon = isValidPolygonPath(path);
    if (isPolygon) {
      return turfTransformRotate(polygon([path]), angle, options).geometry.coordinates[0] as Path;
    }

    return turfTransformRotate(turf.lineString(path), angle, options).geometry.coordinates as Path;
  }

  public static findTopLeftMost(points: IPoint[]): IPoint | undefined {
    return R.pipe(
      points,
      //we add 360 to a longitudes that are crossing the 180th meridian
      R.map((point) => [point[0] < 0 ? point[0] + 360 : point[0], point[1]] as IPoint),
      (x) => R.sortBy(x, [(point) => point[1], 'desc'], [(point) => point[0], 'asc']),
      R.first()
    );
  }

  public static longitude(point: IPoint): number {
    return point[0];
  }

  public static latitude(point: IPoint): number {
    return point[1];
  }
}
