import { Box } from '@/components/box';
import React, { PropsWithChildren } from 'react';

interface IEditorResultsCardProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

export function EditorResultsCard({ children, title }: IEditorResultsCardProps): JSX.Element {
  return (
    <EditorResultsCard.Wrapper>
      <EditorResultsCard.Title>{title}</EditorResultsCard.Title>
      <EditorResultsCard.Content>{children}</EditorResultsCard.Content>
    </EditorResultsCard.Wrapper>
  );
}

EditorResultsCard.Wrapper = function EditorResultsCardWrapper({
  children,
  className
}: PropsWithChildren<{ className?: string }>): JSX.Element {
  return (
    <Box
      className={className}
      sx={{
        isolation: 'isolate',
        borderRadius: 1,
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'inputBorder',
        backgroundColor: 'white'
      }}
    >
      {children}
    </Box>
  );
};

EditorResultsCard.Title = function EditorResultsCardTitle({
  children,
  className
}: PropsWithChildren<{ className?: string }>) {
  return (
    <Box
      className={className}
      sx={{ backgroundColor: 'menuHover', py: 6, px: 8, display: 'flex', alignItems: 'center', gap: 4 }}
    >
      {children}
    </Box>
  );
};

EditorResultsCard.Content = function EditorResultsCardContent({
  children,
  className
}: PropsWithChildren<{ className?: string }>) {
  return (
    <Box className={className} sx={{ px: 8 }}>
      {children}
    </Box>
  );
};
