import { EditorResultsCardPrintable } from '@/features/editor-results/components/editor-results-card/editor-results-card-printable';
import { Text, View, Image } from '@react-pdf/renderer';
import { IEditorResultsSurfaceCardProps } from '@/features/editor-results/components/editor-results-surface-card/editor-results-surface-card';
import { ComponentProps } from 'react';
import React from 'react';

type ViewProps = ComponentProps<typeof View>;

export function ImagesPrintSurface(
  props: IEditorResultsSurfaceCardProps & {
    style?: ViewProps['style'];
  }
): JSX.Element {
  return (
    <EditorResultsCardPrintable.Wrapper wrap={false} style={{ ...props.style }}>
      <EditorResultsCardPrintable.Title>
        <View
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column'
          }}
        >
          <Text style={{ fontSize: 9 }}>{props.description}</Text>
        </View>
      </EditorResultsCardPrintable.Title>
      <Image src={props.mapImageSrc} />
    </EditorResultsCardPrintable.Wrapper>
  );
}
