interface IHelpText {
  content: string;
  title: string;
}

export function parseNestedHelpTexts(texts: Record<string, string>) {
  const result = new Map<string, IHelpText[]>();

  for (const [key, content] of Object.entries(texts)) {
    const [, category, title] = key.split('#');
    if (category === undefined) {
      continue;
    }
    const existing = result.get(category) ?? [];
    if (title !== undefined) {
      existing.push({
        content,
        title
      });
    } else {
      existing.push({
        content,
        title: category
      });
    }
    result.set(category, existing);
  }

  return result;
}
