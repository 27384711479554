import { useModal, useModalContent, useModalOptions } from '@/features/modal/stores/use-modal-store';
import { ModalDialog } from '@/components/modal-dialog';
import { R } from '@/lib/remeda';

export function ModalContainer(): JSX.Element {
  const modal = useModal();
  const modalContent = useModalContent();
  const { maxWidth, maxHeight } = useModalOptions() ?? {};

  return (
    <ModalDialog
      modalSx={{
        overflowY: 'auto',
        ...(maxWidth ? { maxWidth } : {}),
        ...(maxHeight ? { maxHeight } : { maxHeight: '95%' })
      }}
      isOpen={R.isDefined(modalContent)}
      onClose={() => modal.closeModal()}
    >
      {modalContent}
    </ModalDialog>
  );
}
