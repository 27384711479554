import React from 'react';
import { R } from '@/lib/remeda';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { GoogleMapConversion } from '@/features/google-map/utils/google-map-conversion';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { runInAction } from 'mobx';

/**
 * Sync information about the map state with the editor machine.
 *
 * Includes things like the current zoom level, the center of the map screen, etc.
 * */
export function useSyncMapData() {
  const store = useEditorStore();
  const { mapData } = store;
  const { map } = useGoogleMapContext();

  React.useEffect(() => {
    const listeners: google.maps.MapsEventListener[] = [
      map.addListener('zoom_changed', () => {
        const zoom = map.getZoom();
        if (R.isNil(zoom)) {
          return;
        }

        // TODO: Why does MobX not recognize updateZoom as an action??
        runInAction(() => {
          mapData.updateZoom(zoom);
        });
      }),
      map.addListener('idle', () => {
        const center = map.getCenter();
        if (R.isNil(center)) {
          return;
        }
        mapData.updateMapCenter(GoogleMapConversion.latLngToPoint(center));
      })
    ];
    return () => {
      listeners.forEach((listener) => listener.remove());
    };
  }, [map, mapData]);
}
