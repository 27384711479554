import { IPoint } from '@/utils/gis/types';
import { degreesToRadians, Feature, LineString } from '@turf/helpers';
import * as R from 'remeda';
import pointToLineDistance from '@turf/point-to-line-distance';
import { IEnrichedSide, ISide } from '@/utils/drawing/types';
import { createLengthMarkerForLine } from '@/utils/gis/create-length-marker-for-line';
import { selectPolylineSides } from '@/features/google-map/utils/select-polyline-sides';
import { getSurfaceSideId } from '@/utils/drawing/get-surface-side-id';

function pythagoras(a: number, b: number): number {
  return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
}

// Computes the length of the sides of a path.
function getSideLengths(path: IPoint[]): IEnrichedSide[] {
  return R.map(selectPolylineSides(path), (side) => ({
    ...side,
    id: getSurfaceSideId(side),
    length: createLengthMarkerForLine(side).length
  }));
}

export function getSideLength(side: ISide, tiltAngle: number, baseline: Feature<LineString>): number {
  const dStart = pointToLineDistance(side.start, baseline, { units: 'meters' });
  const dEnd = pointToLineDistance(side.end, baseline, { units: 'meters' });

  const { length } = createLengthMarkerForLine(side);
  const hypotenuse3dLength = Math.tan(degreesToRadians(tiltAngle)) * (dStart - dEnd);
  return pythagoras(length, hypotenuse3dLength);
}

/**
 * Computes the length of the sides of the path.
 * Takes into account the tilt (if any) of the shape defined by the path.
 *
 * https://matchmaticians.com/questions/x73pme
 * */
function getSideLengthsWithTilt(
  path: IPoint[],
  baseline: Feature<LineString>,
  tiltAngle: number
): IEnrichedSide[] {
  return R.map(selectPolylineSides(path), (side) => ({
    ...side,
    id: getSurfaceSideId(side),
    length: getSideLength(side, tiltAngle, baseline)
  }));
}

export { getSideLengthsWithTilt, getSideLengths };
