import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { IEditorResultsCostsForConstructionProps } from '@/features/editor-results/components/editor-results-costs-for-construction/editor-results-costs-for-construction';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { EditorResultsCardPrintable } from '@/features/editor-results/components/editor-results-card/editor-results-card-printable';
import { IconsPdf } from '@/assets';
import { EmptyPropsWithChildren } from '@/utils/types';
import { ResultsPdfCostsDetail } from '@/features/editor-results/routes/editor-results-route';
import { IntlSpace } from '@/features/intl/components/intl-space';

export function DataPoint({
  value,
  label,
  hasBorder = true,
  description
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  description?: React.ReactNode;
  hasBorder?: boolean;
}): JSX.Element {
  return (
    <View style={{ paddingHorizontal: 10, width: 180 }}>
      <View
        style={{
          paddingHorizontal: 10,
          paddingVertical: 8,
          borderBottom: hasBorder ? '1px solid #D4D9E2' : 'none'
        }}
      >
        <Text style={{ color: '#667085', fontSize: 8, marginBottom: 5 }}>{label}</Text>
        <Text style={{ color: '#303540', fontSize: 9, fontWeight: 500 }}>{value}</Text>
        {description ? (
          <Text style={{ color: '#667085', fontSize: 7, fontWeight: 400, marginTop: 4 }}>{description}</Text>
        ) : null}
      </View>
    </View>
  );
}

function Title({ children }: EmptyPropsWithChildren): JSX.Element {
  return (
    <View style={{ backgroundColor: '#F5F5F5', paddingHorizontal: 20, paddingVertical: 7 }}>
      <Text style={{ color: '#13AB62', textTransform: 'capitalize', fontSize: 9, fontWeight: 500 }}>
        {children}
      </Text>
    </View>
  );
}

function SolarSystemTotal({ table }: IEditorResultsCostsForConstructionProps): JSX.Element {
  const formatCurrency = useFormatCurrency();

  return (
    <DataPoint
      hasBorder={false}
      label={<FormattedMessage defaultMessage="Total solar cost" id="4Gw7VU" />}
      value={formatCurrency(table.totalSolarCost.value)}
      description={
        <Text>
          <FormattedMessage defaultMessage="Possible deduction" id="7kPFKk" />
          <IntlSpace />
          {formatCurrency(table.totalSolarCost.possibleDeduction)}
        </Text>
      }
    />
  );
}

function SolarSystemLow({ table }: IEditorResultsCostsForConstructionProps): JSX.Element {
  const intl = useIntl();
  const formatCurrency = useFormatCurrency();

  const material =
    table.panelCost.value +
    table.inverterCost.value +
    table.optimizerCost.value +
    table.accessoriesCost.value +
    table.mountingMaterialCost.value +
    table.electricalMaterialCost.value;

  const installation = table.mountingCost.value + table.electricalInstallationCost.value;
  const other =
    table.additionsCost.value +
    table.shippingAndWasteCost.value +
    table.designCost.value +
    table.offerAdjustment.value;

  return (
    <React.Fragment>
      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Material',
            id: 'fMigBw'
          })}
          value={[formatCurrency(material)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Installation',
            id: '47svuN'
          })}
          value={[formatCurrency(installation)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Other',
            id: '/VnDMl'
          })}
          value={[formatCurrency(other)]}
        />
      </View>

      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          hasBorder={false}
          label={<FormattedMessage defaultMessage="Total solar cost" id="4Gw7VU" />}
          value={formatCurrency(table.totalSolarCost.value)}
          description={
            <Text>
              <FormattedMessage defaultMessage="Possible deduction" id="7kPFKk" />
              <IntlSpace />
              {formatCurrency(table.totalSolarCost.possibleDeduction)}
            </Text>
          }
        />
      </View>
    </React.Fragment>
  );
}

function SolarSystemMedium({ table }: IEditorResultsCostsForConstructionProps): JSX.Element {
  const intl = useIntl();
  const formatCurrency = useFormatCurrency();

  return (
    <React.Fragment>
      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Panels',
            id: 'lfGBbb'
          })}
          value={[formatCurrency(table.panelCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Inverter',
            id: 'UN6uei'
          })}
          value={[
            formatCurrency(table.inverterCost.value + table.optimizerCost.value + table.accessoriesCost.value)
          ]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Materials',
            id: '0dI1MW'
          })}
          value={[formatCurrency(table.mountingMaterialCost.value + table.electricalMaterialCost.value)]}
        />
      </View>

      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          hasBorder={false}
          label={intl.formatMessage({
            defaultMessage: 'Installation',
            id: '47svuN'
          })}
          value={[formatCurrency(table.mountingCost.value + table.electricalInstallationCost.value)]}
        />
        <DataPoint
          hasBorder={false}
          label={intl.formatMessage({
            defaultMessage: 'Other',
            id: '/VnDMl'
          })}
          value={[
            formatCurrency(
              table.additionsCost.value +
                table.shippingAndWasteCost.value +
                table.designCost.value +
                table.offerAdjustment.value
            )
          ]}
        />
        <DataPoint
          hasBorder={false}
          label={<FormattedMessage defaultMessage="Total solar cost" id="4Gw7VU" />}
          value={formatCurrency(table.totalSolarCost.value)}
          description={
            <Text>
              <FormattedMessage defaultMessage="Possible deduction" id="7kPFKk" />
              <IntlSpace />
              {formatCurrency(table.totalSolarCost.possibleDeduction)}
            </Text>
          }
        />
      </View>
    </React.Fragment>
  );
}

function SolarSystemHigh({ table }: IEditorResultsCostsForConstructionProps): JSX.Element {
  const intl = useIntl();
  const formatCurrency = useFormatCurrency();

  return (
    <React.Fragment>
      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Panels',
            id: 'lfGBbb'
          })}
          value={[formatCurrency(table.panelCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Inverter',
            id: 'UN6uei'
          })}
          value={[formatCurrency(table.inverterCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Optimizer',
            id: 'Fp6CRO'
          })}
          value={[formatCurrency(table.optimizerCost.value)]}
        />
      </View>

      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Installation accessories',
            id: 'S6qbD/'
          })}
          value={[formatCurrency(table.accessoriesCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Mounting material',
            id: 'CjIe+U'
          })}
          value={[formatCurrency(table.mountingMaterialCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Electrical materials',
            id: 'mWB+kv'
          })}
          value={[formatCurrency(table.electricalMaterialCost.value)]}
        />
      </View>

      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Installation cost',
            id: 'vf6o8w'
          })}
          value={[formatCurrency(table.mountingCost.value - table.travelCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Electrical installation cost',
            id: 's3OJd5'
          })}
          value={[formatCurrency(table.electricalInstallationCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Addition',
            id: 'uNJQ0V'
          })}
          value={[formatCurrency(table.additionsCost.value)]}
        />
      </View>

      {table.offerAdjustment.isActive && (
        <View style={{ flexDirection: 'row' }}>
          <DataPoint
            label={table.offerAdjustment.label}
            value={formatCurrency(table.offerAdjustment.value)}
          />
        </View>
      )}

      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          hasBorder={false}
          label={intl.formatMessage({
            defaultMessage: 'Shipping cost',
            id: '6nIgUX'
          })}
          value={[formatCurrency(table.shippingCost.value)]}
        />
        <DataPoint
          hasBorder={false}
          label={intl.formatMessage({
            defaultMessage: 'Waste cost',
            id: 'H/xAKy'
          })}
          value={[formatCurrency(table.wasteCost.value)]}
        />
        <DataPoint
          hasBorder={false}
          label={intl.formatMessage({
            defaultMessage: 'Travelling cost',
            id: 'mXWRBh'
          })}
          value={[formatCurrency(table.travelCost.value)]}
        />
      </View>
      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          hasBorder={false}
          label={intl.formatMessage({
            defaultMessage: 'Planning',
            id: '99OdS3'
          })}
          value={[formatCurrency(table.designCost.value)]}
        />
        <DataPoint
          hasBorder={false}
          label={<FormattedMessage defaultMessage="Total solar cost" id="4Gw7VU" />}
          value={formatCurrency(table.totalSolarCost.value)}
          description={
            <Text>
              <FormattedMessage defaultMessage="Possible deduction" id="7kPFKk" />
              <IntlSpace />
              {formatCurrency(table.totalSolarCost.possibleDeduction)}
            </Text>
          }
        />
      </View>
    </React.Fragment>
  );
}

function SolarSystemMaterialCost({ table }: IEditorResultsCostsForConstructionProps): JSX.Element {
  const intl = useIntl();
  const formatCurrency = useFormatCurrency();

  return (
    <React.Fragment>
      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Panels',
            id: 'lfGBbb'
          })}
          value={[formatCurrency(table.panelCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Inverter',
            id: 'UN6uei'
          })}
          value={[formatCurrency(table.inverterCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Optimizer',
            id: 'Fp6CRO'
          })}
          value={[formatCurrency(table.optimizerCost.value)]}
        />
      </View>

      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Installation accessories',
            id: 'S6qbD/'
          })}
          value={[formatCurrency(table.accessoriesCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Mounting material',
            id: 'CjIe+U'
          })}
          value={[formatCurrency(table.mountingMaterialCost.value)]}
        />
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Electrical materials',
            id: 'mWB+kv'
          })}
          value={[formatCurrency(table.electricalMaterialCost.value)]}
        />
      </View>

      <View style={{ flexDirection: 'row' }}>
        <DataPoint
          label={intl.formatMessage({
            defaultMessage: 'Addition',
            id: 'uNJQ0V'
          })}
          value={[formatCurrency(table.additionsCost.value)]}
        />
        <DataPoint
          hasBorder={false}
          label={<FormattedMessage defaultMessage="Total solar cost" id="4Gw7VU" />}
          value={formatCurrency(
            table.totalSolarCost.value -
              (table.mountingCost.value - table.travelCost.value) -
              table.electricalInstallationCost.value -
              table.wasteCost.value -
              table.designCost.value -
              table.shippingCost.value -
              table.travelCost.value
          )}
          description={
            <Text>
              <FormattedMessage defaultMessage="Possible deduction" id="7kPFKk" />
              <IntlSpace />
              {formatCurrency(table.totalSolarCost.possibleDeduction)}
            </Text>
          }
        />
      </View>

      {table.offerAdjustment.isActive && (
        <View style={{ flexDirection: 'row' }}>
          <DataPoint
            label={table.offerAdjustment.label}
            value={formatCurrency(table.offerAdjustment.value)}
          />
        </View>
      )}
    </React.Fragment>
  );
}

export function EditorResultsCostsForConstructionPrintable(
  props: IEditorResultsCostsForConstructionProps & {
    costsDetail: ResultsPdfCostsDetail;
  }
): JSX.Element {
  const { table } = props;
  const intl = useIntl();
  const formatCurrency = useFormatCurrency();

  return (
    <EditorResultsCardPrintable.Wrapper>
      <EditorResultsCardPrintable.Title>
        <IconsPdf.CostsForConstruction />
        <Text style={{ marginLeft: 8 }}>
          <FormattedMessage defaultMessage="Byggnadskostnad" id="pQi1B3" />
        </Text>
      </EditorResultsCardPrintable.Title>

      <View style={{ paddingBottom: 10 }}>
        <Title>
          <FormattedMessage defaultMessage="Solar system" id="0MqSJ1" />
        </Title>

        {/**
         * Different models of showing costs for construction
         * https://linear.app/metergram/issue/SOL-307
         */}
        <View style={{ justifyContent: 'center' }}>
          {(() => {
            switch (props.costsDetail) {
              case 'high':
                return <SolarSystemHigh {...props} />;
              case 'medium':
                return <SolarSystemMedium {...props} />;
              case 'low':
                return <SolarSystemLow {...props} />;
              case 'total':
                return <SolarSystemTotal {...props} />;
              case 'material':
                return <SolarSystemMaterialCost {...props} />;
              default:
                return <React.Fragment />;
            }
          })()}
        </View>

        <Title>
          <FormattedMessage defaultMessage="Energi" id="cVpmV7" />
        </Title>

        <View style={{ flexDirection: 'row' }}>
          <DataPoint
            hasBorder={false}
            label={intl.formatMessage({
              defaultMessage: 'Energy storage',
              id: 'qxnkFh'
            })}
            value={[formatCurrency(table.batteriesCost.value)]}
          />
          <DataPoint
            hasBorder={false}
            label={intl.formatMessage({
              defaultMessage: 'Laddbox',
              id: '/juEsH'
            })}
            value={[formatCurrency(table.wallboxCost.value)]}
          />
          <DataPoint
            hasBorder={false}
            label={<FormattedMessage defaultMessage="Totala anläggningsalternativ" id="Y5r6Cq" />}
            description={
              <Text>
                <FormattedMessage defaultMessage="Möjliga avdrag" id="7kPFKk" />
                <IntlSpace />
                {formatCurrency(table.totalAccessoriesCost.possibleDeduction)}
              </Text>
            }
            value={[formatCurrency(table.totalAccessoriesCost.value)]}
          />
        </View>

        <Title>
          <FormattedMessage
            defaultMessage="Total"
            id="BcWe8y"
            description="Title for a table of total installation cost, including deductions"
          />
        </Title>

        {props.costsDetail === 'material' ? (
          <View style={{ flexDirection: 'row' }}>
            <DataPoint
              hasBorder={false}
              label={intl.formatMessage({
                defaultMessage: 'Total',
                id: 'MJ2jZQ'
              })}
              value={[
                formatCurrency(
                  table.totalCalculationCost -
                    (table.mountingCost.value - table.travelCost.value) -
                    table.electricalInstallationCost.value -
                    table.wasteCost.value -
                    table.designCost.value -
                    table.shippingCost.value -
                    table.travelCost.value
                )
              ]}
            />
          </View>
        ) : (
          <View style={{ flexDirection: 'row' }}>
            <DataPoint
              hasBorder={false}
              label={intl.formatMessage({
                defaultMessage: 'Total',
                id: 'MJ2jZQ'
              })}
              value={[formatCurrency(table.totalCalculationCost)]}
            />
            <DataPoint
              hasBorder={false}
              label={intl.formatMessage({
                defaultMessage: 'Green deduction',
                id: 'YFG29r'
              })}
              value={[formatCurrency(table.deduction.value)]}
            />
            <DataPoint
              hasBorder={false}
              label={intl.formatMessage({
                defaultMessage: 'Total after green deduction',
                id: 'iNUn/c'
              })}
              value={[formatCurrency(table.totalCalculationCost + table.deduction.value)]}
            />
          </View>
        )}
      </View>
    </EditorResultsCardPrintable.Wrapper>
  );
}
