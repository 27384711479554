import { useRollbar } from '@rollbar/react';
import React from 'react';

interface ILoggingInfo {
  message: string;
}

interface ILoggingError {
  error: Error;
  data?: Record<string, unknown>;
}

function useLogging() {
  const rollbar = useRollbar();

  const info = React.useCallback(
    (message: ILoggingInfo) => {
      rollbar.info(message.message, {
        timestamp: Date.now()
      });
    },
    [rollbar]
  );

  const error = React.useCallback(
    (message: ILoggingError) => {
      rollbar.error(message.error, {
        timestamp: Date.now(),
        data: message.data
      });
    },
    [rollbar]
  );

  return React.useMemo(() => ({ info, error }), [info, error]);
}

export { useLogging };
