import { Box, Button, Text } from 'theme-ui';
import { Icons } from '@/assets';
import React, { PropsWithChildren } from 'react';

interface IMapControlsProps {
  onRecenter: () => void;
}

function MapControl({
  children,
  className,
  onClick
}: PropsWithChildren<{ className?: string; onClick?: React.MouseEventHandler }>): JSX.Element {
  const isInteractive = onClick !== undefined;

  return (
    <Button
      onClick={onClick}
      className={className}
      sx={{
        width: 54,
        height: 54,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'saturate(180%) blur(33px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: 2,
        borderRadius: 1,
        color: 'text',
        pointerEvents: isInteractive ? 'initial' : 'none',

        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.9)'
        }
      }}
    >
      {children}
    </Button>
  );
}

export function MapControls({ onRecenter }: IMapControlsProps): JSX.Element {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 96,
        left: 30,

        display: 'flex',
        gap: 1
      }}
    >
      <MapControl>
        <Text sx={{ display: 'block', fontWeight: 'bold', lineHeight: 0.8, fontSize: 'xs' }}>N</Text>
        <Icons.Navigation size={32} />
      </MapControl>

      <MapControl onClick={onRecenter}>
        <Icons.MapCenter color="text" />
      </MapControl>
    </Box>
  );
}
