import { useIntl } from 'react-intl';
import React from 'react';

export function useFormatYears(): (value: number) => string {
  const intl = useIntl();

  return React.useCallback(
    (value: number) => {
      return (
        value +
        ' ' +
        intl.formatMessage({
          defaultMessage: 'years',
          id: 'Q7cTgY',
          description: 'Label for number of years'
        })
      );
    },
    [intl]
  );
}
