import { IPoint } from '@/utils/gis/types';
import React from 'react';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { useDeepCompareEffect } from 'react-use';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';

interface IMapCircleOptions extends Pick<google.maps.MarkerOptions, 'clickable' | 'draggable' | 'opacity'> {
  point: IPoint;

  fillColor?: string;
  size?: number;
  zIndex?: number;

  onRightClick?: (event: google.maps.MapMouseEvent) => void;
  onMouseOut?: (event: google.maps.MapMouseEvent) => void;
  onMouseOver?: (event: google.maps.MapMouseEvent) => void;
  onClick?: (event: google.maps.MapMouseEvent) => void;
  onDragEnd?: (event: google.maps.MapMouseEvent) => void;
  onDragStart?: (event: google.maps.MapMouseEvent) => void;
  onDrag?: (event: google.maps.MapMouseEvent) => void;
}

export function MapCircle({
  fillColor = 'rgb(127,255,0)',
  point,
  size = 5,
  zIndex = 10,

  onClick,
  onDrag,
  onDragEnd,
  onDragStart,
  onMouseOut,
  onMouseOver,
  onRightClick,

  ...options
}: IMapCircleOptions) {
  const [circle, setCircle] = React.useState<google.maps.Marker>();
  const { map } = useGoogleMapContext();

  React.useEffect(() => {
    if (!circle) {
      const circle = new google.maps.Marker();
      setCircle(circle);
      circle.setMap(map);
    }

    return () => {
      if (circle) {
        circle.setMap(null);
      }
    };
  }, [circle, map]);

  useDeepCompareEffect(() => {
    if (circle) {
      circle.setOptions({
        ...options,

        position: pointToLatLng(point),
        zIndex,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor,
          fillOpacity: 1,
          scale: size,
          strokeWeight: 0
        }
      });
    }
  }, [options, circle, point, zIndex, fillColor, size]);

  React.useEffect(() => {
    const listeners = [
      onRightClick ? circle?.addListener('rightclick', onRightClick) : undefined,
      onMouseOut ? circle?.addListener('mouseout', onMouseOut) : undefined,
      onMouseOver ? circle?.addListener('mouseover', onMouseOver) : undefined,
      onClick ? circle?.addListener('click', onClick) : undefined,
      onDrag ? circle?.addListener('drag', onDrag) : undefined,
      onDragEnd ? circle?.addListener('dragend', onDragEnd) : undefined,
      onDragStart ? circle?.addListener('dragstart', onDragStart) : undefined
    ];

    return () => {
      listeners.forEach((listener) => listener?.remove());
    };
  }, [onClick, onDrag, onDragEnd, onDragStart, onMouseOut, onMouseOver, onRightClick, circle]);

  return null;
}
