/** @jsxImportSource theme-ui */
import { ButtonIcon } from '@/components/button/button';
import { IoMdHelp } from 'react-icons/io';
import React from 'react';
import { DropdownMenu, DropdownMenuItem, DropdownMenuSub } from '@/components/dropdown-menu/dropdown-menu';
import { EditorApi } from '@/features/editor';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { R } from '@/lib/remeda';
import { useModal } from '@/features/modal/stores/use-modal-store';
import { MdClose } from 'react-icons/md';
import { Typography } from '@/components/typography';
import { Tooltip } from '@/components/tooltip/tooltip';
import { FormattedMessage } from 'react-intl';
import { ModalDialogTitle } from '@/components/modal-dialog';
import { parseNestedHelpTexts } from '@/features/editor/features/help-texts/utils/parse-nested-help-texts';

interface IHelpButtonProps {
  bottom?: number;
  right?: number;
  position?: 'absolute' | 'fixed';
}

function HelpButtonContentDropdownItem({ content, title }: { title: string; content: string }) {
  const modal = useModal();

  return (
    <DropdownMenuItem
      sx={{
        textTransform: 'capitalize'
      }}
      onClick={() => {
        modal.openModal({
          children: (
            <div sx={{ variant: 'cards.modal', px: 8, pb: 8 }}>
              <ModalDialogTitle
                sx={{
                  display: 'flex',
                  ml: 'auto',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 4,
                  backgroundColor: 'white',
                  py: 2
                }}
              >
                <Typography variant="headline2" sx={{ textTransform: 'capitalize' }}>
                  {title}
                </Typography>

                <ButtonIcon onClick={modal.closeModal}>
                  <MdClose size={20} />
                </ButtonIcon>
              </ModalDialogTitle>

              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )
        });
      }}
      key={title}
    >
      {title}
    </DropdownMenuItem>
  );
}

export function HelpButton({ bottom = 96, right = 60, position = 'absolute' }: IHelpButtonProps) {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const getHelpTexts = EditorApi.useGetHelpTexts();

  if (isQueryLoading(getHelpTexts.isLoading, getHelpTexts.data)) {
    return <React.Fragment />;
  }

  if (R.keys(getHelpTexts.data).length === 0) {
    return <React.Fragment />;
  }

  const data = Array.from(parseNestedHelpTexts(getHelpTexts.data).entries());

  return (
    <DropdownMenu
      rootProps={{
        open: isDropdownOpen,
        onOpenChange(open: boolean) {
          setIsDropdownOpen(open);
        }
      }}
      contentProps={{
        side: 'top',
        sideOffset: 8
      }}
      trigger={
        <Tooltip content={<FormattedMessage defaultMessage="Help & resources" id="wabmUZ" />}>
          <ButtonIcon
            onClick={() => setIsDropdownOpen(true)}
            sx={{
              position,
              bottom,
              right,
              backgroundColor: 'primary',
              color: 'white',
              p: 3,
              boxShadow: '1dp',
              '&:hover': {
                color: 'text'
              }
            }}
          >
            <IoMdHelp size={32} />
          </ButtonIcon>
        </Tooltip>
      }
    >
      {data.map(([key, item]) => {
        const isNested = item.length > 1;

        if (isNested) {
          return (
            <DropdownMenuSub trigger={key}>
              {item.map(({ title, content }) => (
                <HelpButtonContentDropdownItem key={title} title={title} content={content} />
              ))}
            </DropdownMenuSub>
          );
        }

        const { content } = item[0]!;

        return <HelpButtonContentDropdownItem key={key} title={key} content={content} />;
      })}
    </DropdownMenu>
  );
}
