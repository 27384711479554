import { api } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { EditorApiKeys } from '@/features/editor/api/keys';
import { InferQueryOptions, UnwrapPromise } from '@/utils/types';
import { z } from 'zod';
import { R } from '@/lib/remeda';

export interface IGetMountingSystemsApiRequest {
  calculationId: number;
}

const schema = z.record(z.string());

export async function getMountingSystemsApi({ calculationId }: IGetMountingSystemsApiRequest) {
  const response = await api.get(`/getMountingSystems?calculationID=${calculationId}`);
  const data = schema.parse(response.data);
  return R.toPairs(data).map(([key, value]) => ({ id: key, name: value }));
}

export type IGetMountingSystemsApiResponse = UnwrapPromise<ReturnType<typeof getMountingSystemsApi>>;

export function useGetMountingSystems(
  { calculationId }: IGetMountingSystemsApiRequest,
  options?: InferQueryOptions<typeof getMountingSystemsApi>
) {
  return useQuery(
    EditorApiKeys.getMountingSystems({ calculationId }),
    () => getMountingSystemsApi({ calculationId }),
    {
      ...options,
      staleTime: Infinity
    }
  );
}
