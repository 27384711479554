import { Icons } from '@/assets';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { SurfaceEsdec } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { ISurface } from '@/features/editor/utils/surface/surface';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from 'theme-ui';
import { EsdecAttachmentTypeInput } from './esdec-attachment-type-input';
import { EsdecHangerBoltTypeInput } from './esdec-hanger-bolt-type-input';
import { EsdecPreferredFastenerInput } from './esdec-preferred-fastener-input';
import { EsdecPreferredProductInput } from './esdec-preferred-product-input';
import { EsdecRailSystemInput } from './esdec-rail-system-input';
import { EsdecRoofHookSizeInput } from './esdec-roof-hook-size-input';
import { EsdecRoofHookTypeInput } from './esdec-roof-hook-type-input';
import { EsdecSupportScrewTypeInput } from './esdec-support-screw-type-input';
import { EsdecSurfaceCardSection } from './esdec-surface-card-section';

interface IConfigurationProps {
  surfaceEsdec: SurfaceEsdec;
  surfaceId: string;
}

const CONFIGURATION_FLEX_CHILD_STYLE = '1 1 45%';
const CONFIGURATION_FLEX_CHILD_STYLE_FULL_WIDTH = '1 1 100% !important';

interface ISlopedConfigurationProps extends IConfigurationProps {
  surface: ISurface;
}

const SlopedConfiguration = observer(function SlopedConfiguration({
  surfaceId,
  surfaceEsdec,
  surface
}: ISlopedConfigurationProps) {
  if (surfaceEsdec.preferredProduct === 'CLICKFIT_EVO') {
    const showHangerBolt =
      surfaceEsdec.material === 'BITUMEN_INSULATED' ||
      surfaceEsdec.material === 'BITUMEN_UNINSULATED' ||
      surfaceEsdec.material === 'EPDM' ||
      surfaceEsdec.material === 'ARABIC_TILES' ||
      (surfaceEsdec.material === 'CORRUGATED' && surface.orientation === 'portrait') ||
      (surfaceEsdec.material === 'STEEL' && surfaceEsdec.chosenAttachmentType === 'HANGER_BOLTS') ||
      (surfaceEsdec.material === 'STEEL' &&
        surfaceEsdec.steelType === 'CORRUGATED' &&
        (surface.orientation === 'portrait' || surfaceEsdec.chosenAttachmentType === 'HANGER_BOLTS'));

    const isCorrugatedSteel = surfaceEsdec.material === 'STEEL' && surfaceEsdec.steelType === 'CORRUGATED';
    const isTrapezoidalSteel = surfaceEsdec.material === 'STEEL' && surfaceEsdec.steelType === 'TRAPEZOIDAL';
    const isCorrugatedVerticalLandscape =
      surfaceEsdec.material === 'CORRUGATED' &&
      surfaceEsdec.railSystem === 'VERTICAL' &&
      surface.orientation === 'landscape';

    const showRoofHookType = surfaceEsdec.material === 'TILES';
    const showAttachmentType =
      (isCorrugatedSteel && surface.orientation === 'landscape') ||
      isTrapezoidalSteel ||
      isCorrugatedVerticalLandscape;
    const showPreferredFastener =
      surfaceEsdec.material === 'STEEL' && surfaceEsdec.chosenAttachmentType === 'MOUNTING_PROFILES';

    return (
      <React.Fragment>
        <Box
          sx={{
            flex:
              !showRoofHookType && !showAttachmentType && !showPreferredFastener && !showHangerBolt
                ? CONFIGURATION_FLEX_CHILD_STYLE_FULL_WIDTH
                : CONFIGURATION_FLEX_CHILD_STYLE
          }}
        >
          <EsdecRailSystemInput surfaceId={surfaceId} />
        </Box>

        {showRoofHookType && <EsdecRoofHookTypeInput surfaceId={surfaceId} />}
        {showAttachmentType && <EsdecAttachmentTypeInput surfaceId={surfaceId} />}
        {showPreferredFastener && <EsdecPreferredFastenerInput surfaceId={surfaceId} />}
        {showHangerBolt && (
          <EsdecHangerBoltTypeInput
            preferredProduct={surfaceEsdec.preferredProduct}
            surfaceEsdec={surfaceEsdec}
          />
        )}
      </React.Fragment>
    );
  }
  const showSupportScrew =
    (surfaceEsdec.preferredProduct === 'CLICKFIT_BASIC' && surfaceEsdec.material === 'EPDM') ||
    surfaceEsdec.material === 'BITUMEN_UNINSULATED';

  const showAttachmentType =
    surfaceEsdec.preferredProduct === 'CLICKFIT_BASIC' &&
    surfaceEsdec.material === 'STEEL' &&
    surfaceEsdec.railSystem === 'HORIZONTAL';

  const showHangerBolt =
    surfaceEsdec.preferredProduct === 'CLICKFIT_BASIC' &&
    ((surfaceEsdec.material === 'STEEL' && surfaceEsdec.railSystem === 'VERTICAL') ||
      (showAttachmentType && surfaceEsdec.chosenAttachmentType === 'HANGER_BOLTS') ||
      surfaceEsdec.material === 'BITUMEN_INSULATED' ||
      surfaceEsdec.material === 'CORRUGATED');

  const showRoofHookSize = surfaceEsdec.material === 'TILES';
  const showRoofHookType = surfaceEsdec.material === 'TILES' && surfaceEsdec.railSystem === 'HORIZONTAL';
  return (
    <React.Fragment>
      <Box
        sx={{
          flex:
            !showRoofHookSize && !showRoofHookType && !showHangerBolt && !showSupportScrew
              ? CONFIGURATION_FLEX_CHILD_STYLE_FULL_WIDTH
              : CONFIGURATION_FLEX_CHILD_STYLE
        }}
      >
        <EsdecRailSystemInput surfaceId={surfaceId} />
      </Box>

      {showAttachmentType && <EsdecAttachmentTypeInput surfaceId={surfaceId} />}
      {showRoofHookSize && <EsdecRoofHookSizeInput surfaceId={surfaceId} />}
      {showRoofHookType && <EsdecRoofHookTypeInput surfaceId={surfaceId} />}
      {showHangerBolt && surfaceEsdec.preferredProduct && (
        <EsdecHangerBoltTypeInput
          preferredProduct={surfaceEsdec.preferredProduct}
          surfaceEsdec={surfaceEsdec}
        />
      )}
      {showSupportScrew && surfaceEsdec.preferredProduct && (
        <EsdecSupportScrewTypeInput
          preferredProduct={surfaceEsdec.preferredProduct}
          surfaceEsdec={surfaceEsdec}
        />
      )}
    </React.Fragment>
  );
});

export const EsdecConfigurationCard = observer(function EsdecConfigurationCard({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec || !surface || surface.placement === 'ground') {
    return <React.Fragment />;
  }

  return (
    <EsdecSurfaceCardSection
      title={<FormattedMessage defaultMessage="Your Configuration" id="JpHTki" />}
      icon={<Icons.Gear />}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          rowGap: 3,
          flexWrap: 'wrap',
          '& > *': {
            flex: CONFIGURATION_FLEX_CHILD_STYLE
          }
        }}
      >
        <EsdecPreferredProductInput surfaceId={surfaceId} />
        <>
          <SlopedConfiguration surfaceId={surfaceId} surfaceEsdec={surfaceEsdec} surface={surface} />
        </>
      </Box>
    </EsdecSurfaceCardSection>
  );
});
