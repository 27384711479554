import { pointToEsdecVector } from '@/features/esdec/utils/point-to-esdec-vector';
import { IEnrichedSurface } from '@/features/editor/utils/surface/surface';
import { GeneratedPanel } from '@/features/editor/utils/panel/panel';
import * as turf from '@turf/helpers';
import { Gis } from '@/features/editor/utils/gis';
import bboxPolygon from '@turf/bbox-polygon';
import bbox from '@turf/bbox';
import { nn } from '@/utils/invariant';
import {
  CalculationRequestDto,
  FieldDTOOrientationEnum,
  RoofDtoProductEnum,
  RoofDtoTypeEnum
} from '@solel/esdec';
import { SurfaceEsdec } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { ProjectInformationStore } from '@/features/editor/stores/mobx/project-information-store';
import { panelsMatrix } from '@/features/editor/utils/panel/panels-matrix';

/**
 * Takes a surface with panels and returns an ESDEC-compatible roof object.
 * */
export function esdecCalculationRequest({
  surface,
  panels,
  esdecInfo,
  projectInfo
}: {
  surface: IEnrichedSurface;
  panels: GeneratedPanel[];
  esdecInfo: SurfaceEsdec | undefined;
  projectInfo: ProjectInformationStore;
}): CalculationRequestDto {
  // Find the top-left most point of the top-left most panel
  const collection = turf.featureCollection(panels.map(({ panel }) => panel));
  const path = Gis.polygonFeatureToPath(bboxPolygon(bbox(collection)));
  const topLeftMostPoint = Gis.findTopLeftMost(path);

  nn(topLeftMostPoint, 'topLeftMostPoint is null');

  const side1 = surface.sides[0]?.length ? surface.sides[0]?.length : 1;
  const side2 = surface.sides[1]?.length ? surface.sides[1]?.length : 1;

  const panelL = surface.panelAttributes.width;

  let columnMatrix = Math.trunc(((side1 - 0.6) * 1000) / (panelL + 30)); //6

  const columnM = [];

  let panelsSum = panels.length;

  for (let i = 0; i < panelsSum; i++) {
    if (i !== 0) panelsSum = panelsSum - columnMatrix;
    if (panelsSum < columnMatrix) columnMatrix = panelsSum;

    const row = [];
    for (let j = 0; j < columnMatrix; j++) {
      row.push(true);
    }
    columnM.push(row);
  }

  interface arrayForPanels {
    id: number;
    type: string;
    indexY: number;
    indexX: number;
  }

  let arrayPanelsVector: arrayForPanels[] = panelsMatrix(panels).map((panel, index) => ({
    id: index + 1,
    indexX: panel.xIndex,
    indexY: panel.yIndex,
    type: 'STANDARD'
  }));

  arrayPanelsVector = arrayPanelsVector.sort((a, b) => a.indexX - b.indexX);
  arrayPanelsVector = arrayPanelsVector.sort((a, b) => a.indexY - b.indexY);

  const maxIndexX: number = Math.max(...arrayPanelsVector.map((element) => element.indexX));
  const maxIndexY: number = Math.max(...arrayPanelsVector.map((element) => element.indexY));

  // TODO Improve code and maybe extract in separate function
  let tmpArrayPanelsVector: arrayForPanels[] = [];
  let z: number = 0;
  for (let i: number = 0; i <= maxIndexX; i++) {
    tmpArrayPanelsVector.push({ id: z, type: 'STANDART', indexX: i, indexY: 0 });
    z++;
    for (let j: number = 1; j <= maxIndexY; j++) {
      tmpArrayPanelsVector.push({ id: z, type: 'STANDART', indexX: i, indexY: j });
      z++;
    }
  }

  tmpArrayPanelsVector = tmpArrayPanelsVector.sort((a, b) => a.indexX - b.indexX);
  tmpArrayPanelsVector = tmpArrayPanelsVector.sort((a, b) => a.indexY - b.indexY);

  const arrayMatrix: boolean[] = [];

  let i1: number = 0;
  let i2: number = 0;

  while (i1 < arrayPanelsVector.length && i2 < tmpArrayPanelsVector.length) {
    if (arrayPanelsVector[i1]?.indexX === tmpArrayPanelsVector[i2]?.indexX) {
      arrayMatrix.push(true);
      i1++;
      i2++;
    } else if (arrayPanelsVector[i1]?.indexX !== tmpArrayPanelsVector[i2]?.indexX) {
      arrayMatrix.push(false);
      i2++;
    }
  }

  function createArrayOfArrays(inputArray: boolean[], chunkSize: number) {
    const result = [];

    for (let i = 0; i < inputArray.length; i += chunkSize) {
      const chunk = inputArray.slice(i, i + chunkSize);
      result.push(chunk);
    }

    return result;
  }

  console.log(createArrayOfArrays(arrayMatrix, maxIndexX + 1));

  // Get the vector from which the panel array will start (the 0, 0 panel will be here)
  const fieldStartPoint = pointToEsdecVector({
    origin: surface.path[0]!,
    point: topLeftMostPoint
  });

  // function sideRoofPoint(path: [number, number], customPoint: IPoint, x: boolean) {
  //   const fieldStartPointCustom = pointToEsdecVector({
  //     origin: path,
  //     point: customPoint
  //   });
  //
  //   if (x) {
  //     return fieldStartPointCustom.x;
  //   } else {
  //     return fieldStartPointCustom.y;
  //   }
  // }

  let prefferedProduct: RoofDtoProductEnum = 'CLICKFIT_BASIC';
  switch (esdecInfo?.preferredProduct) {
    case 'CLICKFIT_BASIC':
      prefferedProduct = esdecInfo.preferredProduct;
      break;
    case 'CLICKFIT_EVO':
      prefferedProduct = esdecInfo.preferredProduct;
      break;
  }

  let roofType: RoofDtoTypeEnum = 'SLOPED';
  switch (surface.placement) {
    case 'flat':
      roofType = 'FLAT';
      break;
    case 'ground':
      roofType = 'FLAT';
      break;
    case 'angled':
      roofType = 'SLOPED';
      break;
  }

  let orientationField: FieldDTOOrientationEnum = 'PORTRAIT';
  switch (surface.orientation) {
    case 'portrait':
      orientationField = 'PORTRAIT';
      break;
    case 'landscape':
      orientationField = 'LANDSCAPE';
      break;
  }

  return {
    metaData: {
      client: {
        email: projectInfo.email,
        name: projectInfo.clientFullName || projectInfo.clientCompanyName,
        phoneNumber: projectInfo.clientPhone
      },
      project: {
        name: surface.description,
        contactPerson: 'Contact person at Esdec',
        installationDate: '',
        revision: ''
      }
    },
    calculationPreferences: {
      maximumRailLength: 7000
    },
    roofs: [
      {
        identifier: surface.esdecRoofId || 1,
        name: surface.id,
        product: prefferedProduct,
        country: 'SWEDEN',
        formattedAddress: projectInfo.address,
        solarPanel: {
          name: surface.panel.name,
          width: surface.panelAttributes.width,
          length: surface.panelAttributes.length,
          thickness: surface.panelAttributes.thickness,
          weight: surface.panelAttributes.weight * 1000,
          peakPower: surface.panel.power
        },
        material:
          esdecInfo?.material && esdecInfo.material !== 'NONE_SELECTED' ? esdecInfo.material : 'TILES',
        roofLines: [
          {
            start: {
              x: 0,
              y: side2 * 1000
            },
            end: {
              x: side1 * 1000,
              y: side2 * 1000
            }
          },
          {
            start: {
              x: side1 * 1000,
              y: side2 * 1000
            },
            end: {
              x: side1 * 1000,
              y: 0
            }
          },
          {
            start: {
              x: side1 * 1000,
              y: 0
            },
            end: {
              x: 0,
              y: 0
            }
          },
          {
            start: {
              x: 0,
              y: 0
            },
            end: {
              x: 0,
              y: side2 * 1000
            }
          }
        ],
        height: surface.heightToEavesMeters * 1000 ? surface.heightToEavesMeters * 1000 : 3000,
        type: roofType,
        angle: surface.tiltAngle && surface.panelTiltAngle ? surface.tiltAngle + surface.panelTiltAngle : 30,
        windzone: 'SV_ZONE_21',
        // change in discussion with ESDEC for compatibilities issues
        //esdecInfo?.windzone && esdecInfo.windzone !== 'NONE_SELECTED' ? esdecInfo.windzone : 'SV_ZONE_21',
        terrainCategory:
          esdecInfo?.terrainCategory && esdecInfo.terrainCategory !== 'NONE_SELECTED'
            ? esdecInfo.terrainCategory
            : 'EU_CATEGORY_0',
        snowzone: esdecInfo?.snowzone ? esdecInfo.snowzone : 'SWEDEN_1_0',
        fields: [
          {
            identifier: 1,
            topLeftPosition: {
              x: Math.abs(fieldStartPoint.x),
              y: Math.abs(fieldStartPoint.y)
            },
            railSystem: esdecInfo?.railSystem,
            orientation: orientationField,
            panels: createArrayOfArrays(arrayMatrix, maxIndexX + 1)
          }
        ],
        orography: 1,
        blackArticles: true,
        flangeClamp: esdecInfo?.flangeDistance?.toString(),
        hookType: esdecInfo?.hookType,
        hookSizeType: esdecInfo?.hookSize
      }
    ]
  };
}
