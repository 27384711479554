/** @jsxImportSource theme-ui */
import { Box, Button, Flex, IconButton, IconButtonProps } from 'theme-ui';
import React, { PropsWithChildren } from 'react';
import { GoogleMap } from '../../google-map/components/google-map';
import { useSelector } from '@xstate/react';
import { Icons } from '@/assets';
import { Tooltip } from '@/components/tooltip/tooltip';
import { ProjectInformationUpdateDialog } from '@/features/editor/components/project-information-dialog';
import { MdOutlineSettings } from 'react-icons/md';
import { HelpButton } from '@/features/editor/features/help-texts/components/help-button';
import { useModal } from '@/features/modal/stores/use-modal-store';
import { EditorResultsApi } from '@/features/editor-results';
import { useEditorCalculationId } from '@/features/editor/utils/use-editor-calculation-id';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { environment } from '@/utils/env';
import { R } from '@/lib/remeda';
import { Base64 } from '@/utils/base64';
import { isFeatureFlagActive } from '@/utils/feature-flags';
import { EditorServices } from '..';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { EditorRightMenu } from '@/features/editor/components/editor-right-menu';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { FormattedMessage } from 'react-intl';
import { useCalcEsdec } from '@/components/demo-page';

function ScreenshotTool() {
  const surfaces = useSurfaces();
  const { screenshotService } = EditorServices.Screenshot.useScreenshotService();
  const screenshotState = useSelector(screenshotService, (state) => state);

  if (!isFeatureFlagActive('screenshotTool')) {
    return <React.Fragment />;
  }
  return (
    <ToolMenuButton
      disabled={!screenshotState.matches('idle') || surfaces.length === 0}
      tooltipContent="Screenshot surface"
      onClick={() => {
        screenshotService.send({ type: 'SCREENSHOT' });
      }}
    >
      <Icons.SnippingTool />
    </ToolMenuButton>
  );
}

export const Editor = observer(function Editor(): JSX.Element {
  const { calculationId } = useEditorCalculationId();
  const editorResults = EditorResultsApi.useEditorResults({ calculationId });
  const store = useEditorStore();

  const isNewSurfaceActive = store.state.isCreatingSurface;
  const isNewCustomLengthSurface90Active = store.state.isCreatingCustomSurface90;
  const isAddingObstacleActive = store.state.isAddingObstacle;
  const isAddingPanelActive = store.state.isAddingPanel;
  const isDragging = store.state.isDragging;
  const isSelecting = store.state.isSelecting;
  const isMeasuring = store.state.isMeasuring;
  const isAddingOptimizer = store.state.isAddingOptimizer;
  const selectedSurfaceId = store.surfaces.selectedSurfaceId;
  const modal = useModal();
  store.surfaces.inputValue = 0;
  store.surfaces.inputSideValue = 0;

  useCalcEsdec();

  if (isQueryLoading(editorResults.isLoading, editorResults.data)) {
    return <React.Fragment />;
  }
  const customerID = editorResults.data.customerOrganizationInfo.customerInfo.customerID;
  const toolTipSelect = `To start selection box from inside the surface, hold alt-opt or alt key depending on if you are using Mac or Windows/Linux. To select individual panel,  hold cmd or ctrl key depending on if you are using Mac or Windows/Linux and using the mouse continue selecting wanted panels.`;
  const toolTipDrawCustom = `When drawing rectangle polygon, after the first point/side the polygon will be contained to the rules the will guide you to draw perfect rectangle. After the second side double click to close the rectangle.`;

  // function addCustomLengthValue(e: React.ChangeEvent<HTMLInputElement>) {
  //   store.surfaces.inputValue = +e.target.value;
  // }
  //
  // function addCustomAngleValue(e: React.ChangeEvent<HTMLInputElement>) {
  //   store.surfaces.inputAngle = +e.target.value;
  // }

  return (
    <React.Fragment>
      <Flex
        sx={{
          mx: 'auto',
          height: '100%'
        }}
      >
        <Flex
          sx={{
            minWidth: 64,
            maxWidth: 64,
            flexDirection: 'column'
          }}
        >
          <a href={`${environment.REACT_APP_SOLEL_HOME_PAGE_URL}/customers/${customerID}`}>
            <ToolMenuButton tooltipContent="Back" sx={{ width: '100%' }}>
              <Icons.EditorMinimizeToolbar size={20} />
            </ToolMenuButton>
          </a>

          <Box sx={{ flexGrow: 1, maxHeight: 50 }} />
          <ToolMenuButton
            tooltipContent={toolTipSelect}
            isActive={isSelecting}
            onClick={() => {
              store.state.setState('selecting');
            }}
          >
            <Icons.EditorSelectTool size={20} />
          </ToolMenuButton>
          <div
            sx={{
              display: 'grid',
              '&:hover': {
                '& .subMenu': {
                  display: 'block'
                }
              }
            }}
          >
            <ToolMenuButton
              tooltipContent="Draw Surface"
              isActive={isNewSurfaceActive}
              onClick={() => {
                store.state.setState('creatingSurface');
              }}
            >
              <Icons.EditorPenTool size={24} />
            </ToolMenuButton>

            <div
              className={'subMenu'}
              sx={{
                display: 'none',
                position: 'absolute',
                zIndex: '2',
                background: 'white',
                maxWidth: '240px',
                maxHeight: '128px',
                left: '64px'
              }}
            >
              <ToolMenuButton
                sx={{ width: '100%' }}
                tooltipContent={toolTipDrawCustom}
                isActive={isNewCustomLengthSurface90Active}
                onClick={() => {
                  store.state.setState('creatingCustomSurface90');
                }}
              >
                <div sx={{ display: 'inline-flex', float: 'left', paddingLeft: 5, gap: 5 }}>
                  <Icons.EditorPenToolCustomLength size={24} />
                  <FormattedMessage
                    defaultMessage="Custom polygon"
                    id="RhNyHs"
                    description="Label custom value for polygon"
                  />
                </div>
              </ToolMenuButton>
            </div>
          </div>

          {/*<ToolMenuButton*/}
          {/*  tooltipContent="Draw Surface Custom Input"*/}
          {/*  isActive={isNewCustomLengthSurfaceActive}*/}
          {/*  onClick={() => {*/}
          {/*    modal.openModal({*/}
          {/*      children: (*/}
          {/*        <Box sx={{ variant: 'cards.modal', p: 2, display: 'flex' }}>*/}
          {/*          <Box sx={{ px: 2, py: 2 }}>*/}
          {/*            <ButtonIcon onClick={modal.closeModal}>*/}
          {/*              <MdClose size={20} />*/}
          {/*            </ButtonIcon>*/}
          {/*          </Box>*/}

          {/*          <ModalDialogTitle*/}
          {/*            sx={{*/}
          {/*              display: 'flex',*/}
          {/*              alignItems: 'center',*/}
          {/*              justifyContent: 'space-between',*/}
          {/*              backgroundColor: 'white',*/}
          {/*              width: 'auto',*/}
          {/*              maxWidth: 'auto',*/}
          {/*              height: 'auto'*/}
          {/*            }}*/}
          {/*          >*/}
          {/*            <Typography sx={{ p: 4 }}>*/}
          {/*              <FormattedMessage*/}
          {/*                defaultMessage="Add custom values for polygon sides and angle"*/}
          {/*                id="p4TiN9"*/}
          {/*                description="Label custom value for sides and angle"*/}
          {/*              />*/}
          {/*            </Typography>*/}
          {/*          </ModalDialogTitle>*/}
          {/*          <input*/}
          {/*            sx={{ height: 30, alignSelf: 'center' }}*/}
          {/*            onChange={addCustomLengthValue}*/}
          {/*            placeholder={intl.formatMessage({*/}
          {/*              defaultMessage: 'Value is in meters',*/}
          {/*              id: 'QVr0qN'*/}
          {/*            })}*/}
          {/*          />*/}
          {/*          <input*/}
          {/*            sx={{ height: 30, alignSelf: 'center' }}*/}
          {/*            onChange={addCustomAngleValue}*/}
          {/*            placeholder={intl.formatMessage({*/}
          {/*              defaultMessage: 'Value is in degrees',*/}
          {/*              id: 'xrqlBN'*/}
          {/*            })}*/}
          {/*          />*/}
          {/*          <Box sx={{ px: 2, py: 2 }}>*/}
          {/*            <ButtonPrimary onClick={modal.closeModal}>*/}
          {/*              <FormattedMessage*/}
          {/*                defaultMessage="Enter value"*/}
          {/*                id="MkCjo3"*/}
          {/*                description="Label custom value for sides and angle"*/}
          {/*              />*/}
          {/*            </ButtonPrimary>*/}
          {/*          </Box>*/}
          {/*        </Box>*/}
          {/*      )*/}
          {/*    });*/}
          {/*    store.state.setState('creatingCustomSurface');*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Icons.EditorPenToolCustomLength size={24} />*/}
          {/*</ToolMenuButton>*/}

          <ToolMenuButton
            tooltipContent="Hand Tool"
            isActive={isDragging}
            onClick={() => {
              store.state.setState('dragging');
            }}
          >
            <Icons.EditorDrag size={24} />
          </ToolMenuButton>

          <ToolMenuButton
            tooltipContent="Ruler"
            isActive={isMeasuring}
            onClick={() => {
              store.state.setState('measuring');
            }}
          >
            <Icons.EditorRuler size={32} />
          </ToolMenuButton>

          <Box sx={{ flexGrow: 1, maxHeight: 78 }} />

          <ToolMenuButton
            tooltipContent="Add Optimizer"
            disabled={selectedSurfaceId === undefined}
            isActive={isAddingOptimizer}
            onClick={() => {
              store.state.setState('addingOptimizer');
            }}
          >
            <Icons.EditorAddOptimizer size={24} />
          </ToolMenuButton>

          <ToolMenuButton
            tooltipContent="Add Obstacle"
            isActive={isAddingObstacleActive}
            onClick={() => {
              store.state.setState('addingObstacle');
            }}
          >
            <Icons.EditorObstacle size={24} />
          </ToolMenuButton>

          <ToolMenuButton
            tooltipContent="Add Panel"
            disabled={selectedSurfaceId === undefined}
            isActive={isAddingPanelActive}
            onClick={() => {
              store.state.setState('addingPanel');
            }}
          >
            <Icons.EditorAddPanel size={24} />
          </ToolMenuButton>

          <ScreenshotTool />

          <ToolMenuButton
            sx={{
              position: 'relative',
              isolation: 'isolate'
            }}
            tooltipContent="Upload Image"
          >
            <Icons.FileUpload size={24} />
            <input
              onChange={async (event) => {
                const files = event.target.files;
                if (R.isNil(files)) {
                  return;
                }
                const file = files.item(0);
                if (R.isNil(file)) {
                  return;
                }

                event.target.value = '';

                const base64src = await Base64.readFileAsBase64(file);
                const src = Base64.strip(base64src);
                const img = new Image();
                img.src = base64src;
                await new Promise((resolve, reject) => {
                  img.onload = resolve;
                  img.onerror = reject;
                });

                store.overlayImage.initialize({
                  src,
                  naturalWidth: img.naturalWidth,
                  naturalHeight: img.naturalHeight
                });
              }}
              type="file"
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                opacity: 0
              }}
            />
          </ToolMenuButton>

          <Box sx={{ flexGrow: 1 }} />
          <Tooltip content="Edit Project">
            <IconButton
              sx={{
                height: 64,
                color: 'primary',
                '&:hover': {
                  backgroundColor: 'menuHover',
                  color: 'text'
                }
              }}
              onClick={() => {
                modal.openModal({
                  children: <ProjectInformationUpdateDialog onClose={modal.closeModal} />
                });
              }}
            >
              <MdOutlineSettings size={28} />
            </IconButton>
          </Tooltip>
        </Flex>

        <Box sx={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
          <GoogleMap />
          <HelpButton />
        </Box>

        <Box
          sx={{
            height: '100%',
            flexBasis: '20%',
            maxWidth: 400,
            minWidth: 400,
            display: 'flex'
          }}
        >
          <EditorRightMenu />
        </Box>
      </Flex>
    </React.Fragment>
  );
});

function ToolMenuButton({
  isActive,
  children,
  tooltipContent,
  ...props
}: IconButtonProps &
  PropsWithChildren<{
    tooltipContent: string;
    isActive?: boolean;
  }>): JSX.Element {
  return (
    <Tooltip content={tooltipContent}>
      <Button
        {...props}
        sx={{
          borderRadius: 0,
          height: 64,
          transition: 'background-color 0.1s ease-in-out, color 0.1s ease-in-out',
          backgroundColor: isActive ? '#667085' : 'transparent',
          color: isActive ? '#E6F7ED' : 'text',

          '&:disabled': {
            pointerEvents: 'none',
            color: 'disabled'
          },

          '&:hover:enabled': { backgroundColor: isActive ? 'menuText' : 'menuHover' }
        }}
      >
        {children}
      </Button>
    </Tooltip>
  );
}
