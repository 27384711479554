import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '@/utils/env';
import React from 'react';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { useLogging } from '@/utils/use-logging';
import { R } from '@/lib/remeda';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';
import { MapTypes } from '@/features/google-map/utils/map-types/map-types';
import { patchGoogleMapMaxZoom, useSuperZoomMap } from '@/features/google-map/utils/map-types/super-zoom';

const loader: Loader = new Loader({
  apiKey: environment.REACT_APP_GOOGLE_MAP_API_KEY,
  version: 'weekly',
  libraries: ['drawing', 'geometry']
});
type Status = 'loading' | 'error' | 'loaded';

export function useGoogleMap({
  mapDiv,
  onMapLoaded
}: {
  mapDiv: HTMLDivElement;
  onMapLoaded?: (map: google.maps.Map) => void;
}) {
  const [status, setStatus] = React.useState<Status>('loading');
  const [map, setMap] = React.useState<google.maps.Map>();

  const store = useEditorStore();
  const logging = useLogging();

  const { mapTypeId, zoom, mapCenter } = store.mapData;

  React.useEffect(() => {
    // If the map has not been loaded yet, load it
    const { coordinates } = store.projectInformation;

    if (R.isNil(map)) {
      loader
        .load()
        .then(async (google) => {
          if (R.isNil(coordinates)) {
            logging.info({
              message: 'Calculation loaded without coordinates.'
            });
            return;
          }
          const originalCenter = pointToLatLng(coordinates);

          const googleMap = new google.maps.Map(mapDiv, {
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            mapTypeId,
            center: mapCenter ? pointToLatLng(mapCenter) : originalCenter,
            zoom,
            tilt: 0,
            disableDoubleClickZoom: true,
            draggableCursor: 'initial',
            rotateControl: false
          });

          await MapTypes.attachCustomMapTypes(googleMap);
          patchGoogleMapMaxZoom(googleMap, store);

          setMap(googleMap);

          onMapLoaded?.(googleMap);
        })
        .catch((error) => {
          setStatus('error');
          console.error('Error initializing google maps', error);
        })
        .finally(() => {
          setStatus('loaded');
        });
    }
  }, [logging, mapCenter, mapTypeId, map, store, zoom, mapDiv, onMapLoaded]);

  useSuperZoomMap({ map });

  return {
    status,
    map
  };
}
