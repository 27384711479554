/** @jsxImportSource theme-ui */

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import React, { PropsWithChildren } from 'react';
import { RadioGroupState, useRadioGroupState } from '@react-stately/radio';
import { RadioGroupProps, RadioProps } from '@react-types/radio';
import { useRadio, useRadioGroup } from '@react-aria/radio';
import { createContext } from '@/utils/create-context';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useFocusRing } from '@react-aria/focus';
import { Box } from '@/components/box';
import { transparentize } from '@theme-ui/color';
import { keyframes } from '@emotion/react';
import { Label } from '@/components/label/label';
import { Grid, GridProps } from 'theme-ui';

export function RadioGroupContent(props: GridProps): JSX.Element {
  return (
    <Grid sx={{ gap: 3 }} {...props}>
      {props.children}
    </Grid>
  );
}

const [useRadioGroupContext, RadioGroupProvider] = createContext<RadioGroupState>();

export interface IRadioGroupProps extends PropsWithChildren<RadioGroupProps> {
  className?: string;
  'aria-label': string;
}

export function RadioGroup(props: IRadioGroupProps): JSX.Element {
  const { children, label, className } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);

  return (
    <div {...radioGroupProps} sx={{ fontFamily: 'body' }} className={className}>
      <Label {...labelProps} sx={{ mb: 3, position: 'relative' }}>
        {label}
      </Label>
      <RadioGroupProvider value={state}>{children}</RadioGroupProvider>
    </div>
  );
}

interface IRadioProps extends RadioProps {
  className?: string;
}

function Radio(props: IRadioProps): JSX.Element {
  const { children, value, className } = props;

  const state = useRadioGroupContext();
  const ref = React.useRef<HTMLInputElement>(null);
  const { inputProps } = useRadio(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = state.selectedValue === value;
  const isDisabled = state.isReadOnly || state.isDisabled;

  return (
    <Label
      className={className}
      sx={{
        isolation: 'isolate',
        display: 'inline-flex',
        width: 'fit-content',
        alignItems: 'center',
        color: isDisabled ? 'disabled' : 'text',
        position: 'relative',
        fontSize: `${14 / 16}rem`
      }}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>

      <RadioFocusableIcon isDisabled={isDisabled} isSelected={isSelected === true} isFocused={isFocusVisible}>
        <MdRadioButtonChecked sx={{ color: transparentize('primary', 0.3) }} size={24} />
      </RadioFocusableIcon>

      <RadioFocusableIcon
        isDisabled={isDisabled}
        isSelected={isSelected === false}
        isFocused={isFocusVisible}
      >
        <MdRadioButtonUnchecked sx={{ color: 'inherit' }} size={24} />
      </RadioFocusableIcon>

      <span
        sx={{
          ml: 8,
          fontWeight: 500
        }}
      >
        {children}
      </span>
    </Label>
  );
}

const DEFAULT_SCALE = 'scale(1.6)';
const RADIO_FOCUSABLE_HOVER = {
  '&:hover': {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: transparentize('primary', 0.95),
      borderRadius: 'rounded',
      transform: DEFAULT_SCALE,
      zIndex: -1
    }
  }
} as const;
const grow = keyframes({
  '0%': {
    transform: DEFAULT_SCALE
  },
  '50%': {
    transform: 'scale(1.7)'
  },
  '100%': {
    transform: DEFAULT_SCALE
  }
});

export function RadioFocusableIcon({
  isFocused,
  isSelected,
  isDisabled,
  children
}: {
  isFocused: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  children: React.ReactElement<HTMLElement>;
}): JSX.Element {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        position: 'absolute',
        borderRadius: 'initial',
        transition: 'all 0.2s ease-in-out',

        ...(isSelected ? { visibility: 'initial', transform: 'scale(1)' } : { opacity: '0' }),
        ...(isFocused || isDisabled ? {} : RADIO_FOCUSABLE_HOVER)
      }}
    >
      {isFocused && !isDisabled ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: transparentize('primary', 0.8),
            borderRadius: 'rounded',
            animation: `${grow} 5s infinite`,
            zIndex: -1
          }}
        />
      ) : null}
      {React.isValidElement(children)
        ? React.cloneElement(children, { style: { ...children.props.style, position: 'relative' } })
        : null}
    </Box>
  );
}

RadioGroup.Radio = Radio;
RadioGroup.Content = RadioGroupContent;
