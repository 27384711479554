import React from 'react';
import { ZoomScaleMapType } from '@/features/google-map/utils/map-types/zoom-scale-map';
import { GOOGLE_MAPS_OVERLAY_MAP_INDICES } from '@/features/google-map/components/google-map';
import { MapTypes } from '@/features/google-map/utils/map-types/map-types';
import { EditorStore, useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { R } from '@/lib/remeda';
import { ILatLng } from '@/utils/gis/types';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';

/*
 * Modifies Google Maps internals to allow for a max zoom level of 22 or 21 depending on the location
 */
function patchGoogleMapMaxZoom(map: google.maps.Map, store: EditorStore): void {
  // @ts-ignore
  const rangeModifier = map.__proto__.__proto__.__proto__;
  const originalSet = rangeModifier.set;

  rangeModifier.set = async function (name: string, value: number) {
    const { coordinates } = store.projectInformation;

    if (name === 'maxZoom' && !R.isNil(coordinates)) {
      const maxZoom = await checkSuperZoomAvailability(pointToLatLng(coordinates));
      value = maxZoom.zoom >= 20 ? 22 : 21;
    }
    originalSet.call(this, name, value);
  };
}

// Checks the maximum zoom allowed for particular coordinates
function checkSuperZoomAvailability(coordinates: ILatLng): Promise<google.maps.MaxZoomResult> {
  const superZoomService = new google.maps.MaxZoomService();
  const maxZoomResult = superZoomService.getMaxZoomAtLatLng(coordinates);
  return maxZoomResult;
}

// Handles attaching/reattaching the super zoom layer to the appropriate map type
function useSuperZoomMap({ map }: { map: google.maps.Map | undefined }) {
  const store = useEditorStore();
  const mapTypeId = store.mapData.mapTypeId;

  React.useEffect(() => {
    const listeners: google.maps.MapsEventListener[] = [];

    async function effect() {
      if (R.isNil(map)) {
        return;
      }

      const selectedMapType = MapTypes.getMapType(mapTypeId);

      // Custom map overlay type to manually zoom in images past the supported zoom level
      // Code is adapted from https://github.com/tilemapjp/ZoomTMSLayer/blob/master/lib/zoomTMSLayer.js
      class SuperZoomMapType extends ZoomScaleMapType {
        protected getTileData(point: google.maps.Point, tileZoom: number) {
          const zoomDiff = Math.max(tileZoom - this._tileMaxZoom, 0);
          const dScale = Math.pow(2, zoomDiff);
          const trueTile = {
            x: Math.floor(point.x / dScale),
            y: Math.floor(point.y / dScale)
          } as const;

          return {
            src: selectedMapType.getTileUrl(
              new google.maps.Point(trueTile.x, trueTile.y),
              tileZoom - zoomDiff,
              { map: map! }
            ),
            zoomDiff: zoomDiff
          };
        }
      }

      const maxZoom = await selectedMapType.maxZoom(map.getCenter());
      const superZoomMapType = new SuperZoomMapType(maxZoom, map);
      map.overlayMapTypes.setAt(GOOGLE_MAPS_OVERLAY_MAP_INDICES.SUPER_ZOOM_MAP, superZoomMapType);
      listeners.push(
        map.addListener('idle', () => {
          if ((map.getZoom() ?? 0) > maxZoom) {
            superZoomMapType.show();
          } else {
            superZoomMapType.hide();
          }
        })
      );
    }

    effect().catch(console.error);

    return () => {
      listeners.forEach((listener) => listener.remove());
    };
  }, [map, mapTypeId]);
}

export { useSuperZoomMap, patchGoogleMapMaxZoom };
