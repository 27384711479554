import {
  // RoofDTOPreferredProductEnum,
  // Roof,
  // RoofGroundingOptionEnum,
  // RoofRoofStructureEnum,
  // RoofSeamTypeEnum,
  // RoofWindzoneEnum,
  // RoofDtoTerrainCategoryEnum,
  // RoofDtoSnowzoneEnum,
  // RoofDtoMaterialEnum,
  // RoofDTOSteelTypeEnum,
  // FieldDtoRailSystemEnum,
  // RoofDTOHookSizeEnum,
  // RoofDTOPreferredFastenerEnum,
  // RoofDtoSideEnum,
  // RoofDtoTopographyEnum,
  RoofDTOPreferredProductEnum,
  RoofDTO,
  RoofDtoGroundingOptionEnum,
  RoofDtoRoofStructureEnum,
  RoofDtoSeamTypeEnum,
  RoofDtoWindzoneEnum,
  RoofDtoTerrainCategoryEnum,
  RoofDtoSnowzoneEnum,
  RoofDtoMaterialEnum,
  RoofDTOSteelTypeEnum,
  FieldDtoRailSystemEnum,
  RoofDTOHookSizeEnum,
  RoofDtoPreferredFastenerEnum,
  RoofDtoSideEnum,
  RoofDtoTopographyEnum,
  FieldDtoPanelConfigurationEnum
} from '@solel/esdec';
import { SurfacePlacement } from '@/features/editor/utils/surface/surface';

export type EsdecPartialRoof = Pick<
  RoofDTO,
  | 'preferredProduct'
  | 'windzone'
  | 'terrainCategory'
  | 'snowzone'
  | 'material'
  | 'roofStructure'
  | 'steelType'
  | 'seamType'
  | 'purlinDistance'
  | 'rafterDistance'
  | 'flangeDistance'
  | 'parapetHeight'
  | 'chosenHangerBoltId'
  | 'groundingOption'
  | 'distanceBetweenRails'
>;

export type EsdecPartialRoofWithSurfaceId = EsdecPartialRoof & {
  surfaceId: string;
};

export const ESDEC_ZONES_BY_COUNTRY = {
  sweden: {
    windzone: [
      RoofDtoWindzoneEnum.SvZone21,
      RoofDtoWindzoneEnum.SvZone22,
      RoofDtoWindzoneEnum.SvZone23,
      RoofDtoWindzoneEnum.SvZone24,
      RoofDtoWindzoneEnum.SvZone25,
      RoofDtoWindzoneEnum.SvZone26
    ],
    terrainCategory: [
      RoofDtoTerrainCategoryEnum.EuCategory0,
      RoofDtoTerrainCategoryEnum.EuCategory1,
      RoofDtoTerrainCategoryEnum.EuCategory2,
      RoofDtoTerrainCategoryEnum.EuCategory3,
      RoofDtoTerrainCategoryEnum.EuCategory4
    ],
    snowzone: [
      RoofDtoSnowzoneEnum.Sweden10,
      RoofDtoSnowzoneEnum.Sweden15,
      RoofDtoSnowzoneEnum.Sweden20,
      RoofDtoSnowzoneEnum.Sweden25,
      RoofDtoSnowzoneEnum.Sweden30,
      RoofDtoSnowzoneEnum.Sweden35,
      RoofDtoSnowzoneEnum.Sweden45,
      RoofDtoSnowzoneEnum.Sweden55
    ]
  }
};

// Esdec only supports angled and flat roofs.
type EsdecRoofType = Exclude<SurfacePlacement, 'ground'>;

export const ESDEC_ROOF_MATERIAL_BY_TYPE: Record<EsdecRoofType, RoofDtoMaterialEnum[]> = {
  angled: [
    RoofDtoMaterialEnum.Tiles,
    RoofDtoMaterialEnum.Steel,
    RoofDtoMaterialEnum.Corrugated,
    RoofDtoMaterialEnum.BitumenUninsulated,
    RoofDtoMaterialEnum.BitumenInsulated,
    RoofDtoMaterialEnum.Epdm,
    RoofDtoMaterialEnum.StandingSeam,
    RoofDtoMaterialEnum.ArabicTiles
  ],
  flat: [
    RoofDtoMaterialEnum.BitumenUninsulated,
    RoofDtoMaterialEnum.BitumenInsulated,
    RoofDtoMaterialEnum.Epdm,
    RoofDtoMaterialEnum.Pvc,
    RoofDtoMaterialEnum.Tpo,
    RoofDtoMaterialEnum.Other
  ]
};

export const ESDEC_ROOF_STEEL_TYPE = {
  steelType: [RoofDTOSteelTypeEnum.Trapezoidal, RoofDTOSteelTypeEnum.Corrugated]
};

export const ESDEC_ROOF_STRUCTURE_TYPE = {
  roofStructure: [
    RoofDtoRoofStructureEnum.Purlins,
    RoofDtoRoofStructureEnum.Rafters,
    RoofDtoRoofStructureEnum.Solid
  ]
};

export const ESDEC_ROOF_SEAM_TYPE = {
  seamType: [
    RoofDtoSeamTypeEnum.SingleFolded,
    RoofDtoSeamTypeEnum.DoubleFolded,
    RoofDtoSeamTypeEnum.Rounded,
    RoofDtoSeamTypeEnum.Snap
  ]
};

export const ESDEC_GROUNDING_TYPE = [
  RoofDtoGroundingOptionEnum.All,
  RoofDtoGroundingOptionEnum.ModulesOnly,
  RoofDtoGroundingOptionEnum.Off
];

export const ESDEC_PREFERRED_PRODUCT_TYPE = {
  flat: [RoofDTOPreferredProductEnum.FlatfixFusion, RoofDTOPreferredProductEnum.FlatfixWavePlus],
  angled: [RoofDTOPreferredProductEnum.ClickfitBasic, RoofDTOPreferredProductEnum.ClickfitEvo]
};

export const ESDEC_RAIL_SYSTEM_TYPE = [
  FieldDtoRailSystemEnum.Horizontal,
  FieldDtoRailSystemEnum.Vertical,
  FieldDtoRailSystemEnum.Cross
];

export const ESDEC_ROOF_HOOK_SIZE = [
  RoofDTOHookSizeEnum.Medium,
  RoofDTOHookSizeEnum.Multi,
  RoofDTOHookSizeEnum.Standard,
  RoofDTOHookSizeEnum.Wide
];

export const ESDEC_PREFERRED_FASTENER = [
  RoofDtoPreferredFastenerEnum.RivetMount,
  RoofDtoPreferredFastenerEnum.ScrewMount
];

export const ESDEC_ROOF_SIDE_TYPE = [RoofDtoSideEnum.Leeward, RoofDtoSideEnum.Windward];

export const ESDEC_TOPOGRAPHY_TYPE = [
  RoofDtoTopographyEnum.Normal,
  RoofDtoTopographyEnum.Sheltered,
  RoofDtoTopographyEnum.Windswept
];

export const ESDEC_PANEL_CONFIGURATION = [
  FieldDtoPanelConfigurationEnum.Single,
  FieldDtoPanelConfigurationEnum.Dual
];
