/** @jsxImportSource theme-ui */
import { MenuItem } from '@/components/menu-item';
import { useMenu } from '@react-aria/menu';
import { useModal, useOverlay, usePreventScroll } from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { useTreeState } from '@react-stately/tree';
import { MenuProps } from '@react-types/menu';
import { CollectionBase } from '@react-types/shared';
import React from 'react';
import { FocusScope } from '@react-aria/focus';

export interface IContextMenuPosition {
  x: number;
  y: number;
}
interface IContextMenuProps<T extends object> extends MenuProps<T> {
  position: IContextMenuPosition;
  onClose: () => void;
  'aria-label': string;
}

export function ContextMenu<T extends object>(props: IContextMenuProps<T>): JSX.Element {
  const { position, children } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  const { overlayProps } = useOverlay({ ...props, isDismissable: true, isOpen: true }, ref);
  usePreventScroll();
  const { modalProps } = useModal();
  const { dialogProps } = useDialog({}, ref);

  return (
    <FocusScope contain restoreFocus autoFocus>
      <div
        onContextMenu={(event) => event.preventDefault()}
        {...overlayProps}
        {...dialogProps}
        {...modalProps}
        ref={ref}
        style={{
          position: 'fixed',
          top: position.y,
          left: position.x
        }}
      >
        <Menu {...props}>{children}</Menu>
      </div>
    </FocusScope>
  );
}

interface IMenuProps<T extends object> extends CollectionBase<T>, Omit<IContextMenuProps<T>, 'position'> {}
function Menu<T extends object>(props: IMenuProps<T>): JSX.Element {
  const state = useTreeState({ ...props, selectionMode: 'none' });
  const ref = React.useRef<HTMLUListElement>(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <ul
      {...menuProps}
      ref={ref}
      sx={{
        variant: 'cards.menu'
      }}
    >
      {Array.from(state.collection).map((item) => (
        <MenuItem
          key={item.key}
          item={item}
          state={state}
          onAction={props.onAction}
          onClose={props.onClose}
        />
      ))}
    </ul>
  );
}
