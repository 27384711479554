import { Box, Heading, Paragraph, Link } from 'theme-ui';
import { FormattedMessage } from 'react-intl';
import { environment } from '@/utils/env';

export function PageNotFound(): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Heading sx={{ fontSize: '4xl', fontWeight: 400 }}>
        <FormattedMessage defaultMessage="404" id="MtqmUi" description="Not Found response status code" />
      </Heading>
      <Paragraph>
        <FormattedMessage
          defaultMessage="The page you requested could not be found."
          id="T7XG/F"
          description="Paragraph for error explanation"
        />
      </Paragraph>
      <Paragraph>
        <Link
          sx={{ color: 'primary', fontWeight: 700, textDecoration: 'none' }}
          href={environment.REACT_APP_SOLEL_HOME_PAGE_URL}
        >
          <FormattedMessage
            defaultMessage="Click here"
            id="jTehWc"
            description="Link to follow to return to the home page"
          />
        </Link>{' '}
        <FormattedMessage
          defaultMessage="to return to the home page."
          id="p6qQAc"
          description="Paragraph for the page not found component"
        />
      </Paragraph>
    </Box>
  );
}
