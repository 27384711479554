/** @jsxImportSource theme-ui */
import { R } from '@/lib/remeda';
import { ChipGroup } from '@/components/chip/chip-group';
import React from 'react';
import { PaperMap } from '@/features/google-map/utils/map-types/paper-map';
import { GoogleMapCoords } from '@/features/google-map/utils/google-map-coords';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

export type IMapTypeId = 'satellite' | 'hitta' | 'paper';

interface IMapType {
  // The id of the map type, used to select the currently active map type
  id: IMapTypeId;
  // The URL function for map tiles
  getTileUrl(
    coord: google.maps.Point,
    zoom: number,
    options: {
      map: google.maps.Map;
    }
  ): string;
  /**
   * The maximum zoom level for the map type
   * @param latLng - Optionally provide a point to get the maximum zoom level for
   * */
  maxZoom(latLng?: google.maps.LatLng): Promise<number>;
  // The name of the map, as displayed to the user
  displayName: string;
}

const mapTypes: Record<IMapTypeId, IMapType> = {
  satellite: {
    id: 'satellite',
    displayName: 'Google Maps',
    getTileUrl: (coord: google.maps.Point, zoom: number) => {
      return `https://khms1.googleapis.com/kh?v=932&hl=en-US&x=${coord.x}&y=${coord.y}&z=${zoom}`;
    },
    maxZoom: async (latLng) => {
      const DEFAULT_MAX_ZOOM = 20;
      if (R.isNil(latLng)) {
        return DEFAULT_MAX_ZOOM;
      }

      const result = await new google.maps.MaxZoomService().getMaxZoomAtLatLng(latLng);
      if (result.status === 'OK') {
        return result.zoom;
      }

      return DEFAULT_MAX_ZOOM;
    }
  },
  hitta: {
    id: 'hitta',
    displayName: 'Hitta',
    getTileUrl: (tileCoordinate: google.maps.Point, zoom: number, options) => {
      const projection = options.map.getProjection();
      if (R.isNil(projection)) {
        return '';
      }

      const tileCenter = GoogleMapCoords.tileCoordToLatLng(
        GoogleMapCoords.tileCenter(tileCoordinate),
        projection,
        zoom
      );

      if (R.isNil(tileCenter)) {
        return '';
      }

      return `https://api.hitta.se/image/v2/1/${zoom}/${tileCenter.lat()}:${tileCenter.lng()}`;
    },
    maxZoom: () => Promise.resolve(20)
  },
  paper: {
    id: 'paper',
    displayName: 'Paper',
    getTileUrl(): string {
      return '';
    },
    async maxZoom() {
      return Infinity;
    }
  }
};

class MapTypes {
  public static getMapType(id: IMapTypeId) {
    return mapTypes[id];
  }

  public static get defaultMapType() {
    return mapTypes.satellite;
  }

  // Attaches all the map types which aren't native (like satellite) to google maps
  public static async attachCustomMapTypes(map: google.maps.Map) {
    map.mapTypes.set(
      mapTypes.hitta.id,
      new google.maps.ImageMapType({
        name: mapTypes.hitta.id,
        getTileUrl: (coord, zoom) => mapTypes.hitta.getTileUrl(coord, zoom, { map }),
        maxZoom: await mapTypes.hitta.maxZoom()
      })
    );
    map.mapTypes.set(
      mapTypes.paper.id,
      new PaperMap({
        name: mapTypes.paper.id,
        maxZoom: await mapTypes.paper.maxZoom(),
        map
      })
    );
  }
}

const MapTypesInput = observer(function MapTypesInput() {
  const store = useEditorStore();
  const mapTypeId = store.mapData.mapTypeId;

  return (
    <React.Fragment>
      <ChipGroup
        onValueChange={(value) => {
          store.mapData.updateMapTypeId(value as IMapTypeId);
        }}
        value={mapTypeId}
      >
        {Object.values(mapTypes).map((mapType) => (
          <ChipGroup.ChipLarge
            checkedStyles={{
              backgroundColor: '#E7F6EF'
            }}
            uncheckedStyles={{
              backgroundColor: 'white'
            }}
            key={mapType.id}
            value={mapType.id}
          >
            {mapType.displayName}
          </ChipGroup.ChipLarge>
        ))}
      </ChipGroup>
    </React.Fragment>
  );
});

export { MapTypesInput, MapTypes };
