export async function canvasToBlob(
  canvas: HTMLCanvasElement,
  type?: string,
  quality?: number
): Promise<Blob | undefined> {
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob ?? undefined);
      },
      type,
      quality
    );
  });
}
