import { IPoint } from '@/utils/gis/types';
import { GoogleMapCoords } from '@/features/google-map/utils/google-map-coords';
import { useContextMenuStore } from '@/features/editor/stores/use-context-menu-store';
import React from 'react';
import { R } from '@/lib/remeda';
import { ZoomScaleMapType } from '@/features/google-map/utils/map-types/zoom-scale-map';
import { getPointFromMouseEvent } from '@/features/google-map/utils/get-point-from-mouse-event';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import * as turf from '@turf/helpers';
import { MdDelete } from 'react-icons/md';
import {
  GOOGLE_MAPS_OVERLAY_MAP_INDICES,
  useGoogleMapContext
} from '@/features/google-map/components/google-map';
import { degreesToRadians } from '@/utils/vec';
import { nn } from '@/utils/invariant';
import { canvasToBlob } from '@/utils/canvas-to-blob';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { autorun, toJS } from 'mobx';

/**
 * One slice of a full image.
 * */
interface IOverlayImage {
  src: string;
  topLeft: google.maps.Point;
  zoom: number;
}

interface IBlobImage extends Pick<IOverlayImage, 'topLeft' | 'zoom'> {
  blob: Blob;
}

/**
 * Since each overlay image is really just a slice of the full image,
 * we also keep track of the original (full) image's bounds. This is
 * mostly useful for the context menu hit-box test.
 * */
export interface IOverlayImageWithBounds extends IOverlayImage {
  originalImageBounds: {
    topLeft: IPoint;
    bottomRight: IPoint;
    topRight: IPoint;
    bottomLeft: IPoint;
  };
}

// Generates a unique name for each overlay image
export function overlayImageName(image: Pick<IOverlayImage, 'topLeft' | 'zoom'>): string {
  return `overlay-image-${image.topLeft.x}-${image.topLeft.y}-${image.zoom}`;
}

/**
 * The way to render an overlay image onto the Google Map is to provide tile coordinates
 * at which to render the image. Because our image is very likely to be larger than one tile
 * (256x256), we need to take that image and break it up into the tiles which it spans.
 *
 * https://developers.google.com/maps/documentation/javascript/examples/maptype-image-overlay
 * */
export async function splitImageInMapTiles(
  rect: {
    x: number;
    y: number;
    offsetWidth: number;
    offsetHeight: number;
    boundingWidth: number;
    boundingHeight: number;
    angleDegrees: number;
  },
  src: string,
  projection: google.maps.Projection,
  bounds: google.maps.LatLngBounds,
  zoom: number
): Promise<IBlobImage[]> {
  // We assume that the tile size is same as the default
  const TILE_SIZE = 256;

  // Find the image's top-left pixel coords
  const topLeftWorldPoint = GoogleMapCoords.vectorToWorldPoint(
    new google.maps.Point(rect.x, rect.y),
    projection,
    bounds,
    zoom
  );
  const topLeftPixelPoint = GoogleMapCoords.worldCoordToVector(topLeftWorldPoint, zoom);

  // Find the tile that the image's top-left pixel coords are in
  const topLeftTileCoord = new google.maps.Point(
    Math.floor(topLeftPixelPoint.x / TILE_SIZE),
    Math.floor(topLeftPixelPoint.y / TILE_SIZE)
  );

  // Find the tile's top-left pixel coords
  const topLeftTilePixelPoint = GoogleMapCoords.tileCoordToPixelCoord(topLeftTileCoord, zoom);

  // Calculate the image's offset from the tile's top-left pixel coords
  const offsetX = topLeftPixelPoint.x - topLeftTilePixelPoint.x;
  const offsetY = topLeftPixelPoint.y - topLeftTilePixelPoint.y;

  // Use the image's bounding box to find the bounding grid. This is because rotation will change
  // the image's width and height, but it will still be contained by its bounding box.
  const minBoundingGrid = {
    // How many tiles wide is the image?
    tilesX: Math.ceil((rect.boundingWidth + offsetX) / TILE_SIZE),
    // How many tiles tall is the image?
    tilesY: Math.ceil((rect.boundingHeight + offsetY) / TILE_SIZE)
  } as const;

  const img = new Image();
  img.src = src;
  await new Promise((resolve) => {
    img.onload = resolve;
  });

  // Slice the image into as many tiles as there are grid tiles
  const images: IBlobImage[] = [];

  for (let x = 0; x < minBoundingGrid.tilesX; x++) {
    for (let y = 0; y < minBoundingGrid.tilesY; y++) {
      const canvas = document.createElement('canvas');
      canvas.width = TILE_SIZE;
      canvas.height = TILE_SIZE;
      const offX = -(x * TILE_SIZE) + offsetX;
      const offY = -(y * TILE_SIZE) + offsetY;

      const centerX = offX + rect.offsetWidth / 2;
      const centerY = offY + rect.offsetHeight / 2;

      const ctx = canvas.getContext('2d')!;
      // To rotate the canvas, we first translate it to the origin around which we will rotate,
      ctx.translate(centerX, centerY);
      // then we rotate it around the origin,
      ctx.rotate(degreesToRadians(rect.angleDegrees));
      // and finally we translate it back to its original position.
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(img, offX, offY, rect.offsetWidth, rect.offsetHeight);

      const blob = await canvasToBlob(canvas);
      nn(blob);

      images.push({
        topLeft: new google.maps.Point(topLeftTileCoord.x + x, topLeftTileCoord.y + y),
        blob,
        zoom
      });
    }
  }

  return images;
}

/**
 * Handles the rendering (and re-rendering) of the map which contains overlay images.
 * An overlay image is a user placed image which renders on top of the map.
 * */
export function useOverlayImageMap() {
  const { map } = useGoogleMapContext();
  const { openContextMenu } = useContextMenuStore();
  const store = useEditorStore();

  React.useEffect(() => {
    if (R.isNil(map)) {
      return;
    }

    const dispose = autorun(() => {
      const overlayImages = toJS(store.overlayImages.overlayImages);

      class OverlayMapType extends ZoomScaleMapType {
        protected getTileData(point: google.maps.Point, tileZoom: number) {
          const overlayImage = overlayImages.find((overlayImage) => {
            const zoomDiff = tileZoom - overlayImage.zoom;
            // Skip zoom levels smaller than the original image's zoom level
            if (zoomDiff < 0) {
              return false;
            }

            // When zoomDiff is 0, this will be 1
            const scaleDiff = Math.pow(2, zoomDiff);

            /**
             * Check whether the image has coordinates either equal to
             * the tile (zoomDiff = 0), or a multiple of the tile (zoomDiff > 0)
             *
             * This is because each zoom level quadruples the number of tiles,
             * so for the tile that was (1, 1) at zoom level 1, the tiles
             * at zoom level 2 will be (2, 2), (2, 3), (3, 2), (3, 3).
             *
             * So if we had an image in the tile (1, 1) at zoom level 1, it would
             * now span the tiles mentioned above at zoom level 2, and for all those
             * tiles, the image would be the same (just scaled by the zoom difference).
             */
            return (
              overlayImage.topLeft.x === Math.floor(point.x / scaleDiff) &&
              overlayImage.topLeft.y === Math.floor(point.y / scaleDiff)
            );
          });
          if (R.isNil(overlayImage)) {
            return undefined;
          }
          return {
            src: overlayImage.src,
            zoomDiff: tileZoom - overlayImage.zoom
          };
        }
      }

      // We hardcode 20 here, but it really doesn't matter as the overlay map doesn't really use
      // the global max zoom of the map. It uses the zoom level at which individual images were added at.
      const overlayMap = new OverlayMapType(20, map);
      map.overlayMapTypes.removeAt(GOOGLE_MAPS_OVERLAY_MAP_INDICES.OVERLAY_IMAGE_MAP);
      map.overlayMapTypes.setAt(GOOGLE_MAPS_OVERLAY_MAP_INDICES.OVERLAY_IMAGE_MAP, overlayMap);
    });

    return () => {
      dispose();
    };
  }, [store, map]);

  React.useEffect(() => {
    if (R.isNil(map)) {
      return;
    }

    const listeners: google.maps.MapsEventListener[] = [];
    const dispose = autorun(() => {
      listeners.push(
        map.addListener('rightclick', (e: google.maps.MapMouseEvent) => {
          const point = getPointFromMouseEvent(e);
          if (R.isNil(point)) {
            return;
          }
          const hitImages = store.overlayImages.overlayImages.filter((image) => {
            const { topLeft, bottomRight, bottomLeft, topRight } = image.originalImageBounds;
            return booleanPointInPolygon(
              point,
              turf.polygon([[topLeft, topRight, bottomRight, bottomLeft, topLeft]])
            );
          });

          if (hitImages.length === 0) {
            return;
          }

          const domEvent = e.domEvent as MouseEvent;
          openContextMenu({
            position: { x: domEvent.x, y: domEvent.y },
            items: [
              {
                key: 'delete-image',
                children: [<MdDelete key="icon" />, <span key="text">Delete image</span>],
                onAction: () => {
                  store.overlayImages.overlayImagesRemove(hitImages);
                },
                textValue: 'Delete image'
              }
            ]
          });
        })
      );
    });

    return () => {
      listeners.forEach((listener) => listener.remove());
      dispose();
    };
  }, [map, openContextMenu, store]);
}
