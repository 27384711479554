import { z } from 'zod';
import { IPoint } from '@/utils/gis/types';

const clientTypeParser = z.enum(['privatePerson', 'company'] as const);
export type ProjectClientType = z.infer<typeof clientTypeParser>;

const projectElectricityConsumptionTemplateParser = z.enum(['villa', 'annual', 'perMonth'] as const);
type ProjectElectricityConsumptionTemplate = z.infer<typeof projectElectricityConsumptionTemplateParser>;

export type ProjectElectricityConsumption =
  | {
      template: Extract<ProjectElectricityConsumptionTemplate, 'villa'>;
    }
  | {
      template: Extract<ProjectElectricityConsumptionTemplate, 'annual'>;
      consumptionKwh: number;
    }
  | {
      template: Extract<ProjectElectricityConsumptionTemplate, 'perMonth'>;
      consumptionKwhMonths: [
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number
      ];
    };

export interface IProjectElectricityPrices {
  purchaseCostCents: number;
  sellingCostCents: number;
  priceDevelopmentPercentPerYear: number;
}

export interface IProjectFinancing {
  loanAmountCents: number;
  loanRepaymentPeriodYears: number;
  loanInterestRatePercent: number;
}

interface IEditorProjectInformation {
  clientFullName?: string;
  clientPhone?: string;
  clientCompanyName?: string;
  email?: string;
  address?: string;
  zipCode?: string;
  coordinates?: IPoint;
  clientType?: ProjectClientType;
  financialSupport?: string;
  electricityConsumption?: ProjectElectricityConsumption;
  electricityPrices?: IProjectElectricityPrices;
  financing?: IProjectFinancing;
  inputValue?: number;
  inputAngle?: number;
}

export { clientTypeParser, projectElectricityConsumptionTemplateParser };
export type { IEditorProjectInformation };
