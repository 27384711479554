import { Typography } from '@/components/typography';
import { Grid } from 'theme-ui';
import React from 'react';

interface IEsdecTableRow {
  label: React.ReactNode;
  value: string;
}

export function EsdecTableRow({ label, value }: IEsdecTableRow): JSX.Element {
  return (
    <Grid
      sx={{
        height: '50px',
        alignItems: 'center',
        px: 8,
        gridTemplateColumns: '4fr 3fr',
        '&:not(:last-child)': {
          borderBottom: '1px solid',
          borderColor: 'inputBorder'
        }
      }}
    >
      <Typography sx={{ color: 'menuText' }}>{label}</Typography>
      <Typography sx={{ fontWeight: 600, color: '#303540', justifySelf: 'end' }}>{value}</Typography>
    </Grid>
  );
}
