type GroupByFn<T> = (value: T) => string;

export function groupByMap<T>(items: T[], groupBy: GroupByFn<T>): Map<string, T> {
  const map = new Map<string, T>();
  items.forEach((item) => {
    const key = groupBy(item);
    map.set(key, item);
  });
  return map;
}
