import React from 'react';
import { Input, InputProps } from 'theme-ui';
import { Field } from '@/components/field';

interface ITextFieldProps extends Omit<InputProps, 'name'> {
  label: string;
  name: string;
  errorMessage?: string;
  helperText?: string;
  isLabelLifted?: boolean;
  isDense?: boolean;
}

function TextFieldInner(
  {
    name,
    required = false,
    label,
    errorMessage,
    helperText,
    isLabelLifted = true,
    value,
    placeholder,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    isDense = false,
    className,
    ...props
  }: ITextFieldProps,
  ref: React.Ref<HTMLInputElement>
): JSX.Element {
  const [isActive, setIsActive] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const isLifted = label && (isLabelLifted || isActive || value);
  const isPlaceholderVisible = !label || isLifted;

  return (
    <Field
      className={className}
      helperText={helperText}
      label={label}
      isLabelAlwaysLifted={isLabelLifted}
      hasValue={Boolean(value)}
      errorMessage={errorMessage}
      name={name}
      required={required}
      isActive={isActive}
      isHovered={isHovered}
    >
      <Input
        ref={ref}
        {...props}
        value={value}
        name={name}
        id={name}
        placeholder={isPlaceholderVisible ? placeholder : ''}
        onFocus={(event) => {
          if (onFocus) {
            onFocus(event);
          }
          setIsActive(true);
        }}
        onBlur={(event) => {
          if (onBlur) {
            onBlur(event);
          }
          setIsActive(false);
        }}
        onMouseEnter={(event) => {
          onMouseEnter?.(event);
          setIsHovered(true);
        }}
        onMouseLeave={(event) => {
          onMouseLeave?.(event);
          setIsHovered(false);
        }}
        sx={{
          fontSize: isDense ? 'sm' : 'sm',
          fontFamily: 'body',
          border: 'none',
          width: '100%',
          height: '1.1876em',
          padding: isDense ? '10px 14px' : '18.5px 14px',
          display: 'block',
          minWidth: 0,
          background: 'none',
          boxSizing: 'content-box',
          letterSpacing: 'inherit',
          outline: 'none',
          color: 'text'
        }}
      />
    </Field>
  );
}

export const TextField = React.forwardRef<HTMLInputElement, ITextFieldProps>(TextFieldInner);
