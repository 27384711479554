import * as ReactIntl from 'react-intl';
import React from 'react';
import { createContext } from '@/utils/create-context';
import { ResolvedIntlConfig } from 'react-intl';

export type IntlMessages = ResolvedIntlConfig['messages'];

export function loadLocaleData(locale: string): Promise<IntlMessages> {
  switch (locale) {
    case 'en':
      return import('../../assets/lang/compiled/en.json') as unknown as Promise<IntlMessages>;
    default:
      return import('../../assets/lang/compiled/en.json') as unknown as Promise<IntlMessages>;
  }
}

interface IIntlContext {
  setLocale: (locale: string) => void;
  locale: string;
}

const [useIntlContext, IntlContextProvider] = createContext<IIntlContext>();

interface IReactIntlProviderProps {
  defaultMessages: IntlMessages;
  children: React.ReactNode;
}

export function ReactIntlProvider({ children, defaultMessages }: IReactIntlProviderProps): JSX.Element {
  const [locale, setLocale] = React.useState('en');
  const [messages, setMessages] = React.useState<IntlMessages>(defaultMessages);

  const setLocaleInner: IIntlContext['setLocale'] = React.useCallback((locale: string) => {
    loadLocaleData(locale)
      .then(setMessages)
      .then(() => setLocale(locale))
      .catch(console.error);
  }, []);

  return (
    <ReactIntl.IntlProvider messages={messages} defaultLocale="en" locale={locale}>
      <IntlContextProvider value={{ setLocale: setLocaleInner, locale }}>{children}</IntlContextProvider>
    </ReactIntl.IntlProvider>
  );
}

export { useIntlContext };
