import { useEditorServices } from '@/features/editor/providers/editor-services-provider';
import { IVectorBounds } from '@/utils/vec';
import { assign, createMachine } from 'xstate';
import produce from 'immer';
import { isNil } from 'remeda';
import { R } from '@/lib/remeda';
import { canvasToBlob } from '@/utils/canvas-to-blob';

class ScreenshotUtils {
  public static getFullScreenStream() {
    return navigator.mediaDevices.getDisplayMedia({
      video: {
        height: screen.height,
        width: screen.width,
        frameRate: 1
      }
    });
  }

  // Captures a screenshot of a MediaStream object and returns it as a data URL
  public static async getScreenshotFromMediaStream(
    stream: MediaStream,
    bounds: IVectorBounds
  ): Promise<Blob | undefined> {
    const canvasWidth = bounds.bottomRight.x - bounds.topLeft.x;
    const canvasHeight = bounds.bottomRight.y - bounds.topLeft.y;
    const offsetUnusableBrowserArea = screen.height - window.innerHeight;

    const video = document.createElement('video');
    video.srcObject = stream;
    await video.play();

    const canvas = document.createElement('canvas');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    console.log(`canvas${canvas}`);

    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(
      video,
      bounds.topLeft.x,
      bounds.topLeft.y + offsetUnusableBrowserArea / 1.7,
      canvasWidth,
      canvasHeight,
      0,
      0,
      canvasWidth,
      canvasHeight
    );

    return canvasToBlob(canvas);
  }
}

interface IScreenshotMachineContext {
  // The media stream from which we will capture a screenshot
  stream: MediaStream | undefined;
  // The resulting base64 encoded image
  image: Blob | undefined;
  // The screen bounds of the screenshot
  bounds: IVectorBounds | undefined;
}

type IScreenshotMachineEvent =
  | {
      type: 'SCREENSHOT';
    }
  | {
      type: 'SCREEN_PERMISSION_GRANTED';
      payload: {
        stream: MediaStream;
      };
    }
  | {
      type: 'SNIPPING_DONE';
      payload: {
        bounds: IVectorBounds;
      };
    }
  | {
      type: 'SNIPPING_CANCELLED';
    }
  | {
      type: 'SCREENSHOT_TAKEN';
      payload: {
        image: Blob;
      };
    }
  | {
      type: 'RESET';
    };

type IScreenshotMachineTypestate =
  | {
      value: 'idle';
      context: {
        stream: undefined;
        image: Blob | undefined;
        bounds: IVectorBounds | undefined;
      };
    }
  | {
      value: 'snipping' | 'screenshotting';
      context: {
        stream: MediaStream;
        image: undefined;
        bounds: IVectorBounds;
      };
    }
  | {
      value: 'authorizing';
      context: {
        stream: undefined;
        image: undefined;
        bounds: undefined;
      };
    };

const screenshotMachine = createMachine<
  IScreenshotMachineContext,
  IScreenshotMachineEvent,
  IScreenshotMachineTypestate
>(
  {
    initial: 'idle',
    predictableActionArguments: true,
    context: { stream: undefined, image: undefined, bounds: undefined },
    states: {
      idle: {
        entry: ['clearMediaStream'],
        on: {
          SCREENSHOT: {
            actions: [
              assign({
                image: (_, __) => undefined,
                bounds: (_, __) => undefined
              })
            ],
            target: 'authorizing'
          },
          RESET: {
            actions: [
              assign({
                image: (_, __) => undefined,
                bounds: (_, __) => undefined
              })
            ]
          }
        }
      },

      snipping: {
        on: {
          SNIPPING_CANCELLED: {
            target: 'idle'
          },
          SNIPPING_DONE: {
            target: 'screenshotting',
            actions: [
              assign({
                bounds: (_, event) => event.payload.bounds
              })
            ]
          }
        }
      },

      screenshotting: {
        invoke: {
          src: 'captureScreenshot'
        },
        on: {
          SCREENSHOT_TAKEN: {
            target: 'idle',
            actions: [assign({ image: (_, event) => event.payload.image })]
          },
          SNIPPING_CANCELLED: {
            target: 'idle'
          }
        }
      },

      authorizing: {
        invoke: {
          src: 'getScreenStream'
        },
        on: {
          SCREEN_PERMISSION_GRANTED: {
            target: 'snipping',
            actions: [assign({ stream: (_, event) => event.payload.stream })]
          },
          SNIPPING_CANCELLED: {
            target: 'idle'
          }
        }
      }
    }
  },
  {
    actions: {
      clearMediaStream: (context) =>
        produce(context, (draft) => {
          if (isNil(draft.stream)) {
            return;
          }

          draft.stream.getTracks().forEach((track) => track.stop());
          draft.stream = undefined;
        })
    },
    services: {
      getScreenStream: () => async (callback) => {
        let track: MediaStreamTrack | undefined;

        try {
          const stream = await ScreenshotUtils.getFullScreenStream();
          track = R.first(stream.getVideoTracks());
          if (track) {
            track.addEventListener('onended', onTrackEnd);
          }
          callback({
            type: 'SCREEN_PERMISSION_GRANTED',
            payload: { stream }
          });
        } catch (e) {
          callback('SNIPPING_CANCELLED');
        }

        return () => {
          track?.removeEventListener('onended', onTrackEnd);
        };

        function onTrackEnd() {
          callback('SNIPPING_CANCELLED');
        }
      },

      captureScreenshot: (context) => async (callback) => {
        if (isNil(context.stream) || isNil(context.bounds)) {
          throw new Error('Invalid state');
        }

        const image = await ScreenshotUtils.getScreenshotFromMediaStream(context.stream, context.bounds);
        console.log(image);
        if (R.isNil(image)) {
          callback('SNIPPING_CANCELLED');
        } else {
          callback({
            type: 'SCREENSHOT_TAKEN',
            payload: {
              image
            }
          });
        }
      }
    }
  }
);

function useScreenshotService() {
  const { screenshotService } = useEditorServices();

  return { screenshotService };
}

export { screenshotMachine, useScreenshotService };
