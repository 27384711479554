import { Feature, Point, Polygon } from '@turf/helpers';
import * as turf from '@turf/helpers';
import { IPoint } from '@/utils/gis/types';
import { Gis } from '@/features/editor/utils/gis';
import clone from '@turf/clone';

export interface IRectanglePolygonOptions {
  rotationAngle?: number;
  rotationPoint?: IPoint;
}

/*
 * Given the top left corner where the rectangle should start, creates a polygon of
 * a specified width and height which represents the rectangle.
 */
export function rectanglePolygon(
  origin: Feature<Point>,
  widthMeters: number,
  heightMeters: number,
  { rotationAngle = 0 }: IRectanglePolygonOptions = {}
): Feature<Polygon> {
  const topLeft = Gis.pointFeatureToPoint(clone(origin));
  const topRight = Gis.rhumbDestination(topLeft, widthMeters, 90 + rotationAngle, {
    units: 'meters'
  });
  const bottomRight = Gis.rhumbDestination(topRight, heightMeters, 180 + rotationAngle, {
    units: 'meters'
  });
  const bottomLeft = Gis.rhumbDestination(topLeft, heightMeters, 180 + rotationAngle, {
    units: 'meters'
  });

  const [topLeftX, topLeftY] = topLeft;
  const [topRightX, topRightY] = topRight;
  const [bottomRightX, bottomRightY] = bottomRight;
  const [bottomLeftX, bottomLeftY] = bottomLeft;

  return turf.polygon([
    [
      [topLeftX, topLeftY],
      [topRightX, topRightY],
      [bottomRightX, bottomRightY],
      [bottomLeftX, bottomLeftY],
      [topLeftX, topLeftY]
    ]
  ]);
}

export const RectanglePoint = {
  topLeft(path: IPoint[]): IPoint {
    return [...path[0]!];
  },
  topRight(path: IPoint[]): IPoint {
    return [...path[1]!];
  },
  bottomRight(path: IPoint[]): IPoint {
    return [...path[2]!];
  },
  bottomLeft(path: IPoint[]): IPoint {
    return [...path[3]!];
  }
};
