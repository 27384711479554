/** @jsxImportSource theme-ui */
import { EditorResultsCard } from '@/features/editor-results/components/editor-results-card/editor-results-card';
import { EditorStore, useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { Box } from 'theme-ui';
import { EsdecOverviewContent } from './esdec-overview-content';
import { SingleCheckbox, CheckboxGroup } from '@/components/checkbox-group/checkbox-group';
import { FormattedMessage } from 'react-intl';
import { SelectField } from '@/components/select-field';

const downloadOptions = [
  { value: 'ORDERLIST AS PDF', label: 'ORDERLIST AS PDF' },
  { value: 'ORDERLIST AS CSV', label: 'ORDERLIST AS CSV' },
  { value: 'INSTALLATION SUMMARIES', label: 'INSTALLATION SUMMARIES' }
];

function finalizedRoofs(surfaceIds: string[], store: EditorStore) {
  const { surfacesEsdec } = store;
  const finalizedSurfaceIds = surfaceIds.filter((id) => {
    const surfaceEsdec = surfacesEsdec.surfaces[id];
    return surfaceEsdec?.isRoofFinalized === true;
  });

  return finalizedSurfaceIds;
}

export const EsdecOverviewCard = observer(function EsdecOverviewCard() {
  const store = useEditorStore();
  const { surfaces } = store;
  const { surfacesEsdec } = store;
  const surfacesIds = surfaces.surfaces.map((surface) => surface.id);

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    if (finalizedRoofs(surfacesIds, store).length > 0) {
      const allChecked =
        surfaces.selectedOverviewSurfaceIds.length === finalizedRoofs(surfacesIds, store).length;

      setSelectAllChecked(allChecked);
    }
  }, [store, surfaces, surfaces.selectedOverviewSurfaceIds, surfacesIds, surfacesIds.length]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', py: 20 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SingleCheckbox
          isDisabled={finalizedRoofs(surfacesIds, store).length === 0}
          isSelected={selectAllChecked}
          onChange={() => {
            const newChecked = !selectAllChecked;
            setSelectAllChecked(newChecked);
            if (newChecked) {
              surfaces.updateSelectedOverviewSurfaceIds(finalizedRoofs(surfacesIds, store));
            } else {
              surfaces.updateSelectedOverviewSurfaceIds([]);
            }
          }}
        >
          <FormattedMessage defaultMessage="Select All" id="kHdqem" />
        </SingleCheckbox>

        <SelectField
          isDisabled={!selectAllChecked && !surfaces.selectedOverviewSurfaceIds.length}
          placeholder={selectAllChecked ? 'DOWNLOAD ALL' : 'DONWLOAD SELECTED'}
          label=""
          name="download-summury"
          options={downloadOptions}
          sx={{ width: '250px' }}
        />
      </Box>
      <CheckboxGroup
        value={surfaces.selectedOverviewSurfaceIds}
        onChange={(value) => {
          surfaces.updateSelectedOverviewSurfaceIds(value);
          const newSelectAllChecked = value.length === surfaces.surfaces.length;
          setSelectAllChecked(newSelectAllChecked);
        }}
      >
        {surfaces.surfaces.map((surface) => {
          const surfaceEsdec = surfacesEsdec.surfaces[surface.id];
          if (!surfaceEsdec) {
            return <React.Fragment />;
          }

          return (
            <EditorResultsCard.Wrapper key={surface.id} sx={{ mb: 10 }}>
              <EditorResultsCard.Title>
                <CheckboxGroup.Checkbox
                  key={surface.id}
                  value={surface.id}
                  isDisabled={!surfaceEsdec.isRoofFinalized}
                >
                  {surface.description}
                </CheckboxGroup.Checkbox>
              </EditorResultsCard.Title>
              <EditorResultsCard.Content>
                <EsdecOverviewContent surface={surface} />
              </EditorResultsCard.Content>
            </EditorResultsCard.Wrapper>
          );
        })}
      </CheckboxGroup>
    </Box>
  );
});
