import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EditorContext } from '@/features/editor/utils/editor-context';
import { InferMutationOptions, UnwrapArray } from '@/utils/types';
import { api } from '@/utils/api';
import { R } from '@/lib/remeda';
import { EditorApiKeys } from '@/features/editor/api/keys';

type SurfaceWithComputed = UnwrapArray<EditorContext['surfaces']> & {
  computed: {
    areaMeters: number;
    ridgeLineLengthMeters: number;
    panelsCount: number;
  };
};

export interface IUpdateEditorContextApiRequest extends Omit<EditorContext, 'surfaces' | 'metadata'> {
  calculationId: number;
  surfaces: SurfaceWithComputed[];
}

async function updateEditorContext(request: IUpdateEditorContextApiRequest): Promise<EditorContext> {
  const response = await api.post('/editor/update', {
    ...R.omit(request, ['calculationId']),
    calculationID: request.calculationId
  });
  return response.data;
}

export function useUpdateEditorContext(options?: InferMutationOptions<typeof updateEditorContext>) {
  const queryClient = useQueryClient();
  return useMutation(updateEditorContext, {
    ...options,
    onMutate: async (variables) => {
      await Promise.all([
        queryClient.cancelQueries(EditorApiKeys.editorResults({ calculationId: variables.calculationId })),
        queryClient.cancelQueries(EditorApiKeys.accessories)
      ]);
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await Promise.all([
        queryClient.invalidateQueries(
          EditorApiKeys.editorResults({ calculationId: variables.calculationId })
        ),
        queryClient.invalidateQueries(EditorApiKeys.accessories)
      ]);
    }
  });
}
