/** @jsxImportSource theme-ui */

import { useButton } from '@react-aria/button';
import React from 'react';
import { ButtonProps } from '@react-types/button';
import { transparentize } from '@theme-ui/color';
import { RelativeLoadingIndicator } from '@/components/loading-indicator';
import { useObjectRef } from '@react-aria/utils';

interface IButtonProps
  extends ButtonProps,
    Omit<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      | 'onBlur'
      | 'onFocus'
      | 'onKeyUp'
      | 'onKeyDown'
      | 'aria-expanded'
      | 'aria-haspopup'
      | 'aria-pressed'
      | 'disabled'
    > {
  className?: string;
  isLoading?: boolean;
  size?: 'small' | 'medium';
}

function sizeProps(size: Required<IButtonProps>['size']) {
  if (size === 'medium') {
    return {
      padding: 3,
      fontSize: 'md'
    };
  }

  return {
    padding: '6px',
    fontSize: 'sm'
  };
}

const ButtonBase = React.forwardRef<HTMLButtonElement, IButtonProps>(function ButtonBase(
  { className, size = 'medium', ...props }: IButtonProps,
  forwardedRef: React.Ref<HTMLButtonElement>
): JSX.Element {
  const ref = useObjectRef(forwardedRef);
  const { buttonProps, isPressed } = useButton(
    { ...props, isDisabled: props.isDisabled || props.isLoading },
    ref
  );
  const { children } = props;

  return (
    <button
      className={className}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        borderRadius: 2,
        border: 'none',
        fontFamily: 'body',
        fontWeight: 500,
        transition: 'background-color 0.1s ease-in-out, opacity 0.1s ease-in-out',
        opacity: isPressed ? 0.85 : 1,
        ...sizeProps(size),

        '&:disabled': {
          '&:hover': {
            backgroundColor: 'disabled'
          },
          backgroundColor: 'disabled'
        }
      }}
      {...buttonProps}
      ref={ref}
    >
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          justifyContent: 'center',
          visibility: props.isLoading ? 'hidden' : 'visible'
        }}
      >
        {children}
      </div>

      {props.isLoading ? <RelativeLoadingIndicator color="white" /> : null}
    </button>
  );
});

export const ButtonPrimary = React.forwardRef<HTMLButtonElement, IButtonProps>(function ButtonPrimary(
  props: IButtonProps,
  forwardedRef: React.Ref<HTMLButtonElement>
): JSX.Element {
  return (
    <ButtonBase
      {...props}
      ref={forwardedRef}
      sx={{
        color: 'white',
        backgroundColor: 'primary',

        '&:hover': { backgroundColor: transparentize('primary', 0.18) }
      }}
    />
  );
});

export const ButtonSecondary = React.forwardRef<HTMLButtonElement, IButtonProps>(function ButtonSecondary(
  props: IButtonProps,
  forwardedRef: React.Ref<HTMLButtonElement>
): JSX.Element {
  return (
    <ButtonBase
      {...props}
      ref={forwardedRef}
      sx={{
        color: 'text',
        backgroundColor: 'white',
        border: '2px solid',
        borderColor: 'toolbarIconActive',

        '&:hover': { backgroundColor: 'menuBackground' }
      }}
    />
  );
});

export const ButtonText = React.forwardRef<HTMLButtonElement, IButtonProps>(function ButtonText(
  props: IButtonProps,
  forwardedRef: React.Ref<HTMLButtonElement>
): JSX.Element {
  return (
    <ButtonBase
      {...props}
      ref={forwardedRef}
      sx={{
        backgroundColor: 'transparent',
        '&:hover': { backgroundColor: 'menuBackground' },
        px: 2,
        py: 1,
        borderRadius: 1
      }}
    />
  );
});

export const ButtonIcon = React.forwardRef<HTMLButtonElement, IButtonProps>(function ButtonIcon(
  { className, ...props }: Omit<IButtonProps, 'isLoading'>,
  forwardedRef: React.Ref<HTMLButtonElement>
): JSX.Element {
  return (
    <button
      ref={forwardedRef}
      className={className}
      sx={{
        p: 2,
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 'rounded',
        transition: 'background-color 0.1s ease-in-out, color 0.1s ease-in-out',
        '&:hover': { backgroundColor: 'menuBackground' },
        minWidth: 32,
        minHeight: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      {...props}
    >
      {props.children}
    </button>
  );
});
