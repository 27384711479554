/** @jsxImportSource theme-ui */
import { EditorResultsCard } from '@/features/editor-results/components/editor-results-card/editor-results-card';
import { Image } from 'theme-ui';
import materialWarrantySrc from '@/assets/images/editor-results-material-guarantee.png';
import { Typography } from '@/components/typography';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { IGetEditorResultsApiResponse } from '@/features/editor/api';

function YearMessage(): JSX.Element {
  return <FormattedMessage defaultMessage="-års" id="9xcYs2" />;
}

export function ResultsMaterialWarrantyMessageTitle({
  isVatIncluded
}: Pick<IGetEditorResultsApiResponse['costs'], 'isVatIncluded'>): JSX.Element {
  if (isVatIncluded) {
    return (
      <FormattedMessage
        description="Rubrik för ett stycke som förklarar materialgarantin"
        defaultMessage="(Alla priser är inklusive moms)"
        id="/GuyyI"
      />
    );
  }

  return (
    <FormattedMessage
      defaultMessage="(Alla priser är exklusive moms)"
      id="wiRQ8K"
      description="Rubrik på ett stycke som förklarar materialgarantin"
    />
  );
}

export function ResultsMaterialWarrantyMessageBody({
  panelMaterialWarranty,
  panelWarranty,
  inverterWarranty,
  optimizerWarranty
}: IEditorResultsMaterialWarrantyProps): JSX.Element {
  return (
    <React.Fragment>
      <FormattedMessage
        description="I priset ingår 30 års elproduktionsgaranti och 12 års materialgaranti på panelerna, 25 års garanti på optimeraren och växelriktaren har 5 års garanti."
        defaultMessage="I priset ingår "
        id="nZoz82"
      />{' '}
      {panelWarranty}
      <YearMessage />{' '}
      <FormattedMessage
        description="I priset ingår 30 års elproduktionsgaranti och 12 års materialgaranti på panelerna, 25 års garanti på optimeraren och växelriktaren har 5 års garanti."
        defaultMessage="elproduktionsgaranti och en"
        id="Vg8Ty0"
      />{' '}
      {panelMaterialWarranty}
      <YearMessage />{' '}
      <FormattedMessage
        description="I priset ingår 30 års elproduktionsgaranti och 12 års materialgaranti på panelerna, 25 års garanti på optimeraren och växelriktaren har 5 års garanti."
        defaultMessage="materialgaranti på panelerna, en"
        id="HacUuV"
      />{' '}
      {optimizerWarranty}
      <YearMessage />{' '}
      <FormattedMessage
        description="I priset ingår 30 års elproduktionsgaranti och 12 års materialgaranti på panelerna, 25 års garanti på optimeraren och växelriktaren har 5 års garanti."
        defaultMessage="garanti på optimeraren och växelriktaren har en"
        id="3XByfs"
      />{' '}
      {inverterWarranty}
      <YearMessage />{' '}
      <FormattedMessage
        description="I priset ingår 30 års elproduktionsgaranti och 12 års materialgaranti på panelerna, 25 års garanti på optimeraren och växelriktaren har 5 års garanti."
        defaultMessage="garanti."
        id="z1n/0B"
      />
    </React.Fragment>
  );
}

export interface IEditorResultsMaterialWarrantyProps
  extends Pick<
    IGetEditorResultsApiResponse['costs'],
    'panelMaterialWarranty' | 'inverterWarranty' | 'optimizerWarranty' | 'panelWarranty' | 'isVatIncluded'
  > {}

export function EditorResultsMaterialWarranty(props: IEditorResultsMaterialWarrantyProps): JSX.Element {
  return (
    <EditorResultsCard.Wrapper
      sx={{ borderTopLeftRadius: 2, borderTopRightRadius: 2, alignSelf: 'flex-start' }}
    >
      <Image sx={{ width: '100%' }} src={materialWarrantySrc} />

      <EditorResultsCard.Content sx={{ py: 7 }}>
        <Typography variant="headline2">
          <ResultsMaterialWarrantyMessageTitle isVatIncluded={props.isVatIncluded} />
        </Typography>

        <br />

        <Typography sx={{ color: 'menuText' }}>
          <ResultsMaterialWarrantyMessageBody {...props} />
        </Typography>
      </EditorResultsCard.Content>
    </EditorResultsCard.Wrapper>
  );
}
