/** @jsxImportSource theme-ui */
import { FormattedMessage } from 'react-intl';
import { EsdecTableRow, EsdecWeatherZonesTableModal } from './esdec-weather-zones-table-modal';

interface IEsdecTopographyModal {
  onClose: () => void;
}

export function EsdecTopographyModal({ onClose }: IEsdecTopographyModal): JSX.Element {
  return (
    <EsdecWeatherZonesTableModal
      title={<FormattedMessage defaultMessage="Explanation of topography types" id="PeqQO2" />}
      subTitle={
        <FormattedMessage
          defaultMessage="Explanation of the different types of environment (topography)"
          id="BxCP2H"
        />
      }
      onClose={onClose}
    >
      <EsdecTableRow
        name={<FormattedMessage defaultMessage="windswept" id="n53Wmo" />}
        description={
          <FormattedMessage
            defaultMessage="Flat unobstructed areas exposed on all sides without, or little shelter afforded by terrain, higher construction works or trees."
            id="aoE2B5"
          />
        }
      />
      <EsdecTableRow
        name={<FormattedMessage defaultMessage="normal" id="7jMPLm" />}
        description={
          <FormattedMessage
            defaultMessage="Areas where there is no significant removal of snow by wind on construction work, because of terrain, other construction works or trees."
            id="fZx4V/"
          />
        }
      />
      <EsdecTableRow
        name={<FormattedMessage defaultMessage="sheltered" id="MPauAj" />}
        description={
          <FormattedMessage
            defaultMessage="Areas in which the construction work being considered is considerably lower than the surrounding terrain or surrounded by high trees and/or surrounded by higher construction works."
            id="K3xxyX"
          />
        }
      />
    </EsdecWeatherZonesTableModal>
  );
}
