import React, { PropsWithChildren } from 'react';
import { Typography } from '@/components/typography';

export function ResultsCardDataTitle({
  children,
  className
}: PropsWithChildren<{ className?: string }>): JSX.Element {
  return (
    <Typography as="label" variant="body1" sx={{ color: 'menuText' }} className={className}>
      {children}
    </Typography>
  );
}

export function ResultsCardDataValue({
  children,
  className
}: PropsWithChildren<{ className?: string }>): JSX.Element {
  return (
    <Typography
      sx={{ '&:not(:first-of-type)': { mt: 1 }, fontSize: 'sm' }}
      variant="headline3"
      className={className}
    >
      {children}
    </Typography>
  );
}
