import { IPoint } from '@/utils/gis/types';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import React from 'react';
import * as R from 'remeda';
import { MARKER_LABEL_CLASSNAME } from '@/features/google-map/utils/shapes/marker';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';
import { useDeepCompareEffect } from 'react-use';

export function MapLengthLabel(
  props: Omit<google.maps.MarkerOptions, 'label' | 'position'> & {
    label: string;
    point: IPoint;
    onRightClick?: (event: google.maps.MapMouseEvent) => void;
  }
) {
  const { map } = useGoogleMapContext();
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      const marker = new google.maps.Marker();
      setMarker(marker);
      marker.setMap(map);
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [map, marker]);

  useDeepCompareEffect(() => {
    if (marker) {
      marker.setOptions(
        R.merge(
          {
            clickable: false,
            label: {
              className: MARKER_LABEL_CLASSNAME,
              text: props.label,
              color: 'white',
              fontSize: '10px',
              fontFamily: 'Inter',
              fontWeight: '800'
            },
            icon: {
              url: `
            <rect x="0" y="0" width="60" height="30" />
          `
            },
            zIndex: GOOGLE_MAP_Z_INDICES.MARKER,
            anchorPoint: new google.maps.Point(0, 0),
            position: pointToLatLng(props.point)
          },
          R.omit(props, ['label'])
        )
      );
    }
  }, [map, marker, props]);

  const { onRightClick } = props;

  React.useEffect(() => {
    const listeners = [onRightClick ? marker?.addListener('rightclick', onRightClick) : undefined];

    return () => {
      listeners.forEach((listener) => listener?.remove());
    };
  }, [marker, onRightClick]);

  return null;
}
