import { IEditorResultsSurfaceCardProps } from '@/features/editor-results/components/editor-results-surface-card/editor-results-surface-card';
import { useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { groupByMap } from '@/utils/group-by-map';
import React from 'react';
import { R } from '@/lib/remeda';
import { IGetAllProductsApiResponse, IGetMountingSystemsApiResponse } from '@/features/editor/api';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { useOptimizers } from '@/features/editor/utils/optimizers/use-optimizers';
import { useIntl } from 'react-intl';
import { getSurfaceArea } from '@/features/editor/utils/surface/get-surface-area';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

/**
 * Maps all the required properties for displaying a surface card. This should be treated as
 * the "data source" part of the surface card, so it enables rendering different versions
 * of a surface card (PDF, DOM, etc.)
 * */
export function useEditorResultsSurfaceCardsProps({
  allProducts,
  mountingSystems
}: ISurfaceCardsListProps): IEditorResultsSurfaceCardProps[] {
  const store = useEditorStore();
  const surfaces = useSurfaces();
  const { surfacePanels } = useSurfacePanels();
  const { inverters } = store.inverters;
  const optimizers = useOptimizers();
  const intl = useIntl();

  const inverterProducts = groupByMap(allProducts.inverterTypes, (i) => i.productId);
  const optimizerProducts = groupByMap(allProducts.optimizerTypes, (o) => o.productId);
  const mountingSystemProducts = groupByMap(mountingSystems, (m) => m.id);

  return React.useMemo(
    () =>
      surfaces
        .map((surface) => {
          const panelsCount = surfacePanels[surface.id]?.length ?? 0;
          const panelType = allProducts.panelTypes.find((p) => p.productId === surface.panel.productId);
          const surfaceInverters = inverters.filter((i) => i.surfaceIds.includes(surface.id));
          const panelArea = intl.formatNumber(surface.panelAreaMeters, { maximumFractionDigits: 2 });
          const surfaceArea = intl.formatNumber(getSurfaceArea(surface), { maximumFractionDigits: 2 });

          const inverterTypes = surfaceInverters
            .map((inverter) => {
              if (R.isNil(inverter.inverterTypeId)) {
                return undefined;
              }
              const inverterProduct = inverterProducts.get(inverter.inverterTypeId);
              const quantity = inverter.quantity;

              if (R.isNil(inverterProduct)) {
                return undefined;
              }
              // Is the inverter shared between multiple surfaces?
              const isShared = inverter.surfaceIds.length > 1;
              return {
                ...inverterProduct,
                quantity,
                sharedSurfaceId: isShared ? R.first(inverter.surfaceIds) : undefined
              };
            })
            .filter(R.isDefined);
          const optimizerTypes = surfaceInverters
            .map((i) => (i.optimizerTypeId ? optimizerProducts.get(i.optimizerTypeId) : undefined))
            .filter(R.isDefined);
          const mountingSystemName = surface.mountingSystemId
            ? mountingSystemProducts.get(surface.mountingSystemId)?.name
            : undefined;

          if (R.isNil(panelType)) {
            return undefined;
          }

          return {
            panelArea,
            surfaceArea,
            panelsCount,
            inverterTypes: inverterTypes.map((inverterType) => ({
              productId: inverterType.productId,
              name: inverterType.name,
              power: inverterType.production,
              price: inverterType.price,
              isBatteryPrepared: inverterType.isBatteryPrepared,
              sharedSurfaceId: inverterType.sharedSurfaceId,
              warrantyTime: inverterType.warrantyTime,
              quantity: inverterType.quantity
            })),
            optimizerTypes: optimizerTypes.map((optimizerType) => ({
              price: optimizerType.price,
              name: optimizerType.name,
              power: optimizerType.production,
              warrantyTime: optimizerType.warrantyTime
            })),
            panelType: {
              name: panelType.name,
              power: panelType.power,
              price: panelType.price,
              warrantyTime: panelType.warrantyTime,
              warrantyEffect: panelType.warrantyEffect,
              materialWarranty: panelType.materialWarranty,
              imageSrc: panelType.imageSrc
            },
            mountingSystemName,
            ...surface,
            optimizerPoints: optimizers[surface.id]?.map((o) => o.optimizer) ?? []
          };
        })
        .filter(R.isDefined),
    [
      allProducts.panelTypes,
      intl,
      inverterProducts,
      inverters,
      mountingSystemProducts,
      optimizerProducts,
      optimizers,
      surfacePanels,
      surfaces
    ]
  );
}

export interface ISurfaceCardsListProps {
  allProducts: IGetAllProductsApiResponse;
  mountingSystems: IGetMountingSystemsApiResponse;
}
