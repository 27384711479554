import { pdf } from '@react-pdf/renderer';
import { useIntl } from 'react-intl';
import React from 'react';
import { ReactPdfProvider } from '@/lib/react-pdf/react-pdf-provider';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

function useRenderPdf({ calculationId }: { calculationId: number }): {
  renderPdf: typeof pdf;
} {
  const store = useEditorStore();
  const { messages } = useIntl();
  const context = store.contextExtended;

  return {
    renderPdf: React.useCallback(
      (initialValue) => {
        return pdf(
          <ReactPdfProvider calculationId={calculationId} context={context} messages={messages}>
            {initialValue}
          </ReactPdfProvider>
        );
      },
      [context, messages, calculationId]
    )
  };
}

export { useRenderPdf };
