import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { ESDEC_ROOF_HOOK_SIZE } from '../esdec-types';
import { FormattedMessage, useIntl } from 'react-intl';

export const EsdecRoofHookSizeInput = observer(function EsdecRoofHookSizeInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const intl = useIntl();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  const roofHookSizeOptions = ESDEC_ROOF_HOOK_SIZE.map((roofHookSize) => ({
    label: roofHookSize,
    value: roofHookSize
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Hook Size',
        id: 'ErgP7X'
      })}
      placeholder={<FormattedMessage defaultMessage="Select Roof Hook Size" id="jfdXrf" />}
      name="roofHookSize"
      options={roofHookSizeOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateHookSize(value.value);
        }
      }}
      value={roofHookSizeOptions.find(
        (roofHookSizeOption) => roofHookSizeOption.value === surfaceEsdec.hookSize
      )}
    />
  );
});
