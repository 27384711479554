import { api } from '@/utils/api';
import { InferMutationOptions } from '@/utils/types';
import { useMutation } from '@tanstack/react-query';

interface IDeleteSurfaceFromInverterGroupApiRequest {
  calculationId: number;
  surfaceId: string;
}

async function deleteSurfaceFromInverterGroup({
  calculationId,
  surfaceId
}: IDeleteSurfaceFromInverterGroupApiRequest) {
  return api.post('/deleteSurfaceFromInverterGroup', {
    calculationID: calculationId,
    guid: surfaceId
  });
}

export function useDeleteSurfaceFromInverterGroup(
  options?: InferMutationOptions<typeof deleteSurfaceFromInverterGroup>
) {
  return useMutation(deleteSurfaceFromInverterGroup, options);
}
