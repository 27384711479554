/** @jsxImportSource theme-ui */
import { Text } from 'theme-ui';
import esdecWindzoneSweden from '@/assets/images/esdec-windzone-sweden.png';
import { FormattedMessage } from 'react-intl';
import { EsdecWeatherZonesModal, EsdecWeatherZoneTypes } from './esdec-weather-zones-modal';
import { IntlSpace } from '@/features/intl/components/intl-space';

interface IEsdecWindzoneInformationModal {
  onClose: () => void;
}

export function EsdecWindzoneInformationModal({ onClose }: IEsdecWindzoneInformationModal): JSX.Element {
  return (
    <EsdecWeatherZonesModal
      onClose={onClose}
      title={<FormattedMessage defaultMessage="explanation of windzones" id="RDRwvH" />}
      subTitle={
        <>
          <FormattedMessage defaultMessage="Windzone (V" id="DK+qQJ" />
          <Text sx={{ verticalAlign: 'sub' }}>
            <FormattedMessage defaultMessage="b,0" id="bBGRxz" />
          </Text>
          <IntlSpace />
          <FormattedMessage defaultMessage="in m/s)" id="H6skLL" />
        </>
      }
      imageSrc={esdecWindzoneSweden}
    >
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Windzone 21" id="eKSZPr" />}
        zoneNumber={21}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Windzone 22" id="YnsqtR" />}
        zoneNumber={22}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Windzone 23" id="vHF3Zp" />}
        zoneNumber={23}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Windzone 24" id="K0LjYj" />}
        zoneNumber={24}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Windzone 25" id="O08Cok" />}
        zoneNumber={25}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Windzone 26" id="VStqEg" />}
        zoneNumber={26}
      />
    </EsdecWeatherZonesModal>
  );
}
