import React from 'react';
import { Box } from '@/components/box';
import { MdInfoOutline } from 'react-icons/md';

interface IAlertProps {
  children: React.ReactNode;
  kind?: 'info';
  className?: string;
}

export function Alert({ kind = 'info', children, className }: IAlertProps): JSX.Element {
  return (
    <Box
      className={className}
      sx={{
        backgroundColor: kind,
        color: 'white',
        p: 4,
        borderRadius: 1,
        fontSize: 'sm',
        display: 'flex',
        gap: 2
      }}
    >
      <Box sx={{ minWidth: 20, alignSelf: 'flex-start', display: 'inline-flex', mt: '-1px' }}>
        <MdInfoOutline size={20} />
      </Box>

      {children}
    </Box>
  );
}
