function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

export interface IVector {
  x: number;
  y: number;
}

export interface IVectorBounds {
  topLeft: IVector;
  bottomRight: IVector;
}

export interface IRect {
  top: number;
  left: number;
  width: number;
  height: number;
  bottom: number;
  right: number;
}

class Vec {
  public static rectCenter(topLeft: IVector, width: number, height: number): IVector {
    return {
      x: topLeft.x + width / 2,
      y: topLeft.y + height / 2
    };
  }

  /**
   * Returns the four corners of a rectangle given some information about the rectangle.
   * Importantly, takes into account the rotation of the rectangle (if any).
   * */
  public static rectBounds({
    rotationOrigin,
    rotationAngle,
    height,
    topLeft,
    width
  }: {
    topLeft: IVector;
    width: number;
    height: number;
    rotationOrigin: IVector;
    rotationAngle: number;
  }) {
    return {
      topLeft: Vec.rotate(topLeft, rotationOrigin, rotationAngle),
      bottomRight: Vec.rotate({ x: topLeft.x + width, y: topLeft.y + height }, rotationOrigin, rotationAngle),
      topRight: Vec.rotate({ x: topLeft.x + width, y: topLeft.y }, rotationOrigin, rotationAngle),
      bottomLeft: Vec.rotate(
        {
          x: topLeft.x,
          y: topLeft.y + height
        },
        rotationOrigin,
        rotationAngle
      )
    };
  }

  /**
   * Rotates a vector around a pivot point by a given angle (in degrees)
   * */
  public static rotate(vector: IVector, origin: IVector, angle: number): IVector {
    const rotateBy = degreesToRadians(angle);
    const cos = Math.cos(rotateBy);
    const sin = Math.sin(rotateBy);

    return {
      x: cos * (vector.x - origin.x) - sin * (vector.y - origin.y) + origin.x,
      y: sin * (vector.x - origin.x) + cos * (vector.y - origin.y) + origin.y
    };
  }

  public static add(a: IVector, b: IVector): IVector {
    return {
      x: a.x + b.x,
      y: a.y + b.y
    };
  }
}

export { Vec, degreesToRadians };
