import { GeneratedPanel } from '@/features/editor/utils/panel/panel';
import { Gis } from '@/features/editor/utils/gis';
import { RectanglePoint } from '@/utils/turf/rectangle-polygon';
import { IPoint } from '@/utils/gis/types';
import { R } from '@/lib/remeda';

export type IGeneratedPanelWithXy = GeneratedPanel & {
  xIndex: number;
  yIndex: number;
};

// Given an array of panels, computes the x and y index of each panel
export function panelsMatrix(panels: GeneratedPanel[]): IGeneratedPanelWithXy[] {
  if (panels.length === 0) {
    return [];
  }

  const leftMost = leftMostPanel(panels);
  const topMost = topMostPanel(panels);

  const singlePanelWidth = panelWidth(leftMost);
  const singlePanelHeight = panelHeight(topMost);

  return panels.map((panel) => {
    const panelPath = Gis.polygonFeatureToPath(panel.panel);
    const leftMostPath = Gis.polygonFeatureToPath(leftMost.panel);
    const topMostPath = Gis.polygonFeatureToPath(topMost.panel);

    const leftMostTopLeft = RectanglePoint.topLeft(leftMostPath);
    const topMostTopLeft = RectanglePoint.topLeft(topMostPath);
    const panelTopLeft = RectanglePoint.topLeft(panelPath);

    /**
     * Vertically fix the current panel to the top-left most panel,
     * so that we can calculate the horizontal distance between the two.
     *
     * (topLeft)
     * □ ------------------ □ (fixed panel)
     *                       ↑
     *                       ↑
     *                       ↑
     *                       □ (panel)
     * */
    const panelTopLeftFixedLat: IPoint = [Gis.longitude(panelTopLeft), Gis.latitude(leftMostTopLeft)];
    // Same goes for fixing the panel horizontally, to find the vertical distance.
    const panelTopLeftFixedLng: IPoint = [Gis.longitude(topMostTopLeft), Gis.latitude(panelTopLeft)];

    return {
      ...panel,
      // If a panel is 5th from the left, it will be 5 panel widths away from the left-most panel
      // So to get the index, we divide the distance between the left-most and current panel
      // by the width of a single panel, to get the number of panels between them.
      xIndex: Math.floor(Gis.distance(leftMostTopLeft, panelTopLeftFixedLat) / singlePanelWidth),
      yIndex: Math.floor(Gis.distance(topMostTopLeft, panelTopLeftFixedLng) / singlePanelHeight)
    };
  });

  function leftMostPanel(panels: GeneratedPanel[]): GeneratedPanel {
    return R.pipe(
      panels,
      R.sortBy(({ panel }) => Gis.longitude(RectanglePoint.topLeft(Gis.polygonFeatureToPath(panel)))),
      R.first()
    )!;
  }

  function topMostPanel(panels: GeneratedPanel[]): GeneratedPanel {
    return R.pipe(
      panels,
      R.sortBy(({ panel }) => Gis.latitude(RectanglePoint.topLeft(Gis.polygonFeatureToPath(panel)))),
      R.last()
    )!;
  }

  function panelWidth(panel: GeneratedPanel) {
    const asPath = Gis.polygonFeatureToPath(panel.panel);
    const topLeft = RectanglePoint.topLeft(asPath);
    const topRight = RectanglePoint.topRight(asPath);
    return Gis.distance(topLeft, topRight);
  }

  function panelHeight(panel: GeneratedPanel) {
    const asPath = Gis.polygonFeatureToPath(panel.panel);
    const topLeft = RectanglePoint.topLeft(asPath);
    const bottomLeft = RectanglePoint.bottomLeft(asPath);
    return Gis.distance(topLeft, bottomLeft);
  }
}
