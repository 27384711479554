import { Box, Grid } from 'theme-ui';
import { Typography } from '@/components/typography';
import React from 'react';

interface IEditorResultsTableRowProps {
  label: React.ReactNode;
  value: string[] | number[];
}

export function EditorResultsTableRow({ label, value }: IEditorResultsTableRowProps): JSX.Element {
  return (
    <Grid
      columns={[2]}
      sx={{
        py: 4,
        fontSize: 'sm',
        px: 8,
        '&:not(:last-child)': {
          borderBottom: '1px solid',
          borderColor: 'inputBorder'
        }
      }}
    >
      <Typography sx={{ color: 'menuText' }}>{label}</Typography>

      <Box>
        {value.map((item) => (
          <Typography sx={{ fontWeight: 600 }} key={item}>
            {item}
          </Typography>
        ))}
      </Box>
    </Grid>
  );
}
