import { ContextMenu } from '@/components/context-menu';
import { useContextMenuStore } from '@/features/editor/stores/use-context-menu-store';
import { OverlayContainer } from '@react-aria/overlays';
import { Item } from '@react-stately/collections';
import React from 'react';

export function GoogleMapContextMenu(): JSX.Element {
  const contextMenu = useContextMenuStore();

  if (contextMenu.position === undefined || contextMenu.items.length === 0) {
    return <React.Fragment />;
  }

  return (
    <OverlayContainer>
      <ContextMenu
        aria-label="Map context menu"
        onAction={(key) => {
          const item = contextMenu.items.find((item) => item.key === key);
          item?.onAction();
        }}
        onClose={contextMenu.close}
        position={contextMenu.position}
      >
        {contextMenu.items.map((item) => (
          <Item key={item.key} textValue={item.textValue}>
            {item.children}
          </Item>
        ))}
      </ContextMenu>
    </OverlayContainer>
  );
}
