import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { ESDEC_ROOF_SEAM_TYPE } from '../esdec-types';
import { FormattedMessage, useIntl } from 'react-intl';

export const EsdecSeamTypeInput = observer(function EsdecSeamTypeInput({ surfaceId }: { surfaceId: string }) {
  const store = useEditorStore();
  const intl = useIntl();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  const seamTypeOptions = ESDEC_ROOF_SEAM_TYPE.seamType.map((seamType) => ({
    label: seamType,
    value: seamType
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Seam Type',
        id: 'paPXDN'
      })}
      placeholder={<FormattedMessage defaultMessage="Select Seam Type" id="qB70Ly" />}
      name="seamType"
      options={seamTypeOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateSeamType(value.value);
        }
      }}
      value={seamTypeOptions.find((seamTypeOption) => seamTypeOption.value === surfaceEsdec.seamType)}
    />
  );
});
