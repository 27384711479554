import { z } from 'zod';
import { api } from '@/utils/api';

const addressParser = z.object({
  longitude: z.number(),
  latitude: z.number(),
  mapAddress: z.string()
});

const responseParser = z.union([z.array(addressParser), addressParser]);

export interface IAddressDataApiRequest {
  address: string;
  zipCode?: number;
}

export async function addressDataApi(request: IAddressDataApiRequest) {
  const searchQuery = new URLSearchParams();
  searchQuery.append('address', request.address);
  if (request.zipCode) {
    searchQuery.append('zipCode', String(request.zipCode));
  }

  const response = await api.get(`/addressData?address=${request.address}&zipCode=${searchQuery}`);
  const result = responseParser.parse(response.data);

  if (Array.isArray(result)) {
    return result;
  }

  return [result];
}
