/**
 * Given a max size, returns the adjusted width and height to fit within the max size.
 *
 * - If the height is greater than the width, it will be adjusted to fit within the max height.
 * - If the width is greater than the height, it will be adjusted to fit within the max width.
 * - Aspect ratio is maintained.
 * */
export function getRestrictedSizeBox({
  maxSize,
  height,
  width
}: {
  maxSize: number;
  width: number;
  height: number;
}) {
  if (width > height) {
    const aspectRatio = height / width;
    const newWidth = Math.min(maxSize, width);
    const newHeight = newWidth * aspectRatio;

    return {
      width: newWidth,
      height: newHeight
    };
  }

  const aspectRatio = width / height;
  const newHeight = Math.min(maxSize, height);
  const newWidth = newHeight * aspectRatio;

  return {
    width: newWidth,
    height: newHeight
  };
}
