import { api } from '@/utils/api';
import { z } from 'zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { InferMutationOptions } from '@/utils/types';
import { EditorApiKeys } from '@/features/editor/api/keys';
import { R } from '@/lib/remeda';
import { ICalculationAccessoriesApiResponse } from '@/features/editor/api/calculation-accessories';
import produce from 'immer';

export interface IProductRequestBase {
  calculationId: number;
  productType: 'accessories' | 'batteries' | 'wallbox';
  productId: number;
}

interface IProductAddRemoveAdd extends IProductRequestBase {
  action: 'add';
}

interface IProductAddRemoveRemove extends IProductRequestBase {
  action: 'remove';
  index: number;
}

type IProductAddRemove = IProductAddRemoveAdd | IProductAddRemoveRemove;

async function productAddRemoveApi(request: IProductAddRemove) {
  const parser = z.array(z.number());
  const response = await api.post('/attachProduct', {
    ...R.omit(request, ['productId', 'calculationId']),
    productID: request.productId,
    calculationID: request.calculationId
  });
  return parser.parse(response.data);
}

interface IProductUpdate extends IProductRequestBase {
  action: 'edit';
  index: number;
}

async function productUpdateApi(request: IProductUpdate) {
  const parser = z.array(z.number());
  const response = await api.post('/attachProduct', {
    action: request.action,
    calculationID: request.calculationId,
    productType: request.productType,
    productID: request.productId,
    index: request.index
  });
  return parser.parse(response.data);
}

export function useProductAddRemove(options?: InferMutationOptions<typeof productAddRemoveApi>) {
  const utils = useQueryClient();
  return useMutation(productAddRemoveApi, {
    ...options,
    onMutate: (variables) => {
      utils.setQueryData<ICalculationAccessoriesApiResponse>(
        EditorApiKeys.calculationAccessories({ calculationId: variables.calculationId }),
        (old) =>
          produce(old, (draft) => {
            if (!draft) {
              return;
            }

            if (variables.action === 'remove') {
              draft[variables.productType].splice(variables.index, 1);
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            } else if (variables.action === 'add') {
              draft[variables.productType].push(variables.productId);
            }
          })
      );
    },
    onSuccess: async (data, variables) => {
      await utils.invalidateQueries(EditorApiKeys.editorResults({ calculationId: variables.calculationId }));
    }
  });
}

export function useProductUpdate(options?: InferMutationOptions<typeof productUpdateApi>) {
  const utils = useQueryClient();
  return useMutation(['productUpdate'], productUpdateApi, {
    ...options,
    onMutate: (variables) => {
      utils.setQueryData<ICalculationAccessoriesApiResponse>(
        EditorApiKeys.calculationAccessories({ calculationId: variables.calculationId }),
        (old) =>
          produce(old, (draft) => {
            if (!draft) {
              return;
            }
            draft[variables.productType][variables.index] = variables.productId;
          })
      );
    },
    onSuccess: async (data, variables) => {
      await utils.invalidateQueries(EditorApiKeys.editorResults({ calculationId: variables.calculationId }));
    }
  });
}
