/** @jsxImportSource theme-ui */
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import React from 'react';
import { Box } from '@/components/box';
import { Typography } from '@/components/typography';
import { themeColors } from '@/lib/theme-ui/colors';
import { darken, lighten } from 'polished';
import { EditorResultsCard } from '@/features/editor-results/components/editor-results-card/editor-results-card';
import { IGetEditorResultsApiResponse } from '@/features/editor/api';
import { BaseAxisProps } from 'recharts/types/util/types';
import { Flex } from 'theme-ui';
import { FormattedMessage } from 'react-intl';
import { EditorResultsCardPrintable } from '@/features/editor-results/components/editor-results-card/editor-results-card-printable';
import { Text, View } from '@react-pdf/renderer';

// Calculates the point where a new color is introduced within the gradient
function gradientOffset(values: number[]): number {
  const dataMax = Math.max(...values);
  const dataMin = Math.min(...values);

  if (dataMax <= 0) {
    return 0;
  } else if (dataMin >= 0) {
    return 1;
  } else {
    return dataMax / (dataMax - dataMin);
  }
}

function CustomizedDot(props: { cx: number; cy: number; value: number }): JSX.Element {
  const { cx, cy, value } = props;
  if (value > 0) {
    return <circle cx={cx} cy={cy} r={5} fill={themeColors.primary} />;
  }
  return <circle cx={cx} cy={cy} r={5} fill="red" />;
}

const monthsMap: Record<number, string> = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
};

function CustomTooltip({ payload, label, active }: TooltipProps<string, string>): JSX.Element {
  if (!active) {
    return <React.Fragment />;
  }

  return (
    <Box sx={{ variant: 'cards.tooltip' }}>
      <Typography variant="body2">{label}</Typography>
      <div
        sx={{
          height: '1px',
          backgroundColor: lighten(0.1, '#667085'),
          my: 1
        }}
      />
      {payload?.map((item) => (
        <Typography key={item.name} variant="body2">
          {item.name}: {item.value}
        </Typography>
      ))}
    </Box>
  );
}

interface IYDataKeyBase<TData extends Record<string, unknown>> {
  key: Extract<keyof TData, string>;
  color?: string;
}

type IYDataKey<TData extends Record<string, unknown>> = IYDataKeyBase<TData> &
  (
    | {
        isRedUnderZero: true;
        selectValue: (data: TData) => number;
      }
    | {
        isRedUnderZero?: false;
      }
  );

interface ICustomLineChartProps<TData extends Record<string, unknown>> {
  data: TData[];
  yDataKeys: IYDataKey<TData>[];
  xDataKey: Extract<keyof TData, string>;

  height?: number;
  legend?: React.ReactNode;

  yTickFormatter?: BaseAxisProps['tickFormatter'];
}

declare module 'react' {
  function forwardRef<T, P = {}>(
    // eslint-disable-next-line @rushstack/no-new-null
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    // eslint-disable-next-line @rushstack/no-new-null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

function CustomLineChartInner<TData extends Record<string, unknown>>(
  { data, yDataKeys, xDataKey, height, legend, yTickFormatter }: ICustomLineChartProps<TData>,
  // Ref is typed as any in recharts
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: React.Ref<any>
): JSX.Element {
  const renderLegend = React.useCallback(
    () => (
      <Typography variant="body2" sx={{ textAlign: 'center', pb: 4, color: 'menuText' }}>
        {legend}
      </Typography>
    ),
    [legend]
  );

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart ref={ref} margin={{ right: 20 }} data={data}>
        <CartesianGrid strokeDasharray="5 5" stroke={themeColors.inputBorder} />
        {yDataKeys.map((item) => {
          const lineColor = item.color ?? themeColors.primary;

          if (item.isRedUnderZero) {
            const off = gradientOffset(data.map(item.selectValue));

            return (
              <React.Fragment key={item.key}>
                <defs>
                  <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
                    <stop offset="0%" stopColor={lineColor} />
                    <stop offset={off} stopColor={lineColor} />
                    <stop offset={off} stopColor={themeColors.error} />
                    <stop offset="100%" stopColor={themeColors.error} />
                  </linearGradient>
                </defs>

                <Line
                  isAnimationActive={false}
                  activeDot={CustomizedDot}
                  dot={false}
                  strokeWidth={2}
                  type="monotone"
                  dataKey={item.key}
                  stroke="url(#gradient)"
                />
              </React.Fragment>
            );
          }

          return (
            <Line
              isAnimationActive={false}
              key={item.key}
              activeDot={{ stroke: lineColor }}
              strokeWidth={2}
              type="monotone"
              dataKey={item.key}
              stroke={lineColor}
            />
          );
        })}
        <XAxis fontSize={11} stroke={darken(0.1, themeColors.inputBorder)} dataKey={xDataKey} />
        <YAxis tickFormatter={yTickFormatter} fontSize={11} stroke={darken(0.1, themeColors.inputBorder)} />
        <Tooltip isAnimationActive={false} content={CustomTooltip} />
        {legend ? <Legend content={renderLegend} /> : null}
      </LineChart>
    </ResponsiveContainer>
  );
}

const CustomLineChart = React.forwardRef(CustomLineChartInner);

interface IEditorResultsEnergyBalanceChartProps {
  data: IGetEditorResultsApiResponse['charts']['monthly'];
}

function ChartLegendColor({ color, children }: { color: string; children: React.ReactNode }): JSX.Element {
  return (
    <Flex sx={{ alignItems: 'center', gap: 1 }}>
      <Box
        sx={{ borderRadius: 1, width: 16, height: 16, backgroundColor: color, border: '1px solid black' }}
      />
      {children}
    </Flex>
  );
}

const energyBalanceColors = {
  production: themeColors.warning,
  consumption: themeColors.error,
  surplus: themeColors.primary
} as const;

function EditorResultsEnergyBalanceChartInner(
  { data }: IEditorResultsEnergyBalanceChartProps,
  // Ref is typed as any in recharts
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: React.Ref<any>
): JSX.Element {
  const chartData: {
    consumption: number;
    sales: number;
    production: number;
    month: string;
  }[] = [];

  for (let i = 0; i < data.monthlyConsumption.length; i++) {
    chartData.push({
      consumption: data.monthlyConsumption[i]!,
      sales: data.monthlySales[i]!,
      production: data.productionMonths[i]!,
      month: monthsMap[i]!
    });
  }

  return (
    <CustomLineChart
      ref={ref}
      legend={
        <Flex sx={{ justifyContent: 'center', gap: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <ChartLegendColor color={energyBalanceColors.surplus}>
              <FormattedMessage
                defaultMessage="Production"
                id="HlIFjK"
                description="Electricity production label"
              />
            </ChartLegendColor>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <ChartLegendColor color={energyBalanceColors.consumption}>
              <FormattedMessage
                defaultMessage="Consumption"
                id="uUIjbQ"
                description="Electricity consumption label"
              />
            </ChartLegendColor>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <ChartLegendColor color={energyBalanceColors.production}>
              <FormattedMessage
                defaultMessage="Surplus/Sales"
                id="4JM+20"
                description="Electricity surplus, the amount that can be sold back to the grid"
              />
            </ChartLegendColor>
          </Box>
        </Flex>
      }
      height={300}
      data={chartData}
      yDataKeys={[
        { key: 'sales', color: energyBalanceColors.production },
        { key: 'consumption', color: energyBalanceColors.consumption },
        { key: 'production', color: energyBalanceColors.surplus }
      ]}
      xDataKey="month"
    />
  );
}

export const EditorResultsEnergyBalanceChart = React.forwardRef(EditorResultsEnergyBalanceChartInner);

interface IEditorResultsBalanceOverTimeChartProps {
  data: IGetEditorResultsApiResponse['charts']['yearly'];
}

function EditorResultsBalanceOverTimeChartInner(
  { data }: IEditorResultsBalanceOverTimeChartProps,
  // Ref is typed as any in recharts
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: React.Ref<any>
): JSX.Element {
  return (
    <CustomLineChart
      ref={ref}
      legend="Year"
      height={300}
      data={data.totalPerYear.map((item, index) => ({
        tkr: Math.round(parseInt(item) / 1000),
        year: index + 1
      }))}
      yDataKeys={[{ key: 'tkr', isRedUnderZero: true, selectValue: (item) => item.tkr }]}
      xDataKey="year"
      yTickFormatter={(value) => `${value} tkr`}
    />
  );
}

export function EditorResultsChartCard({
  title,
  children
}: {
  title: React.ReactNode;
  children: JSX.Element;
}): JSX.Element {
  return (
    <EditorResultsCard.Wrapper>
      <EditorResultsCard.Title>
        <Typography variant="headline2">{title}</Typography>
      </EditorResultsCard.Title>
      <EditorResultsCard.Content
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 4,
          px: 2
        }}
      >
        {children}
      </EditorResultsCard.Content>
    </EditorResultsCard.Wrapper>
  );
}

export function EditorResultsChartCardPrintable({
  children,
  title
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <EditorResultsCardPrintable.Wrapper wrap={false}>
      <EditorResultsCardPrintable.Title>
        <Text>{title}</Text>
      </EditorResultsCardPrintable.Title>
      <View style={{ paddingTop: 16, maxWidth: 500 }}>{children}</View>
    </EditorResultsCardPrintable.Wrapper>
  );
}

export const EditorResultsBalanceOverTimeChart = React.forwardRef(EditorResultsBalanceOverTimeChartInner);
