/** @jsxImportSource theme-ui */
import { Box, Text, Image } from 'theme-ui';
import { Typography } from '@/components/typography';
import { EsdecModalHeader } from './esdec-modal-header';

interface IEsdecWeatherZoneTypes {
  zone: React.ReactNode;
  zoneNumber: number;
}

interface IEsdecWeatherZonesModal {
  onClose: () => void;
  title: React.ReactNode;
  subTitle: React.ReactNode;
  imageSrc: string;
  children: React.ReactNode;
}

export function EsdecWeatherZoneTypes({ zone, zoneNumber }: IEsdecWeatherZoneTypes): JSX.Element {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 5 }}>
      <Typography variant="headline3" sx={{ color: 'menuText' }}>
        {zone}
      </Typography>
      <Typography variant="headline1" sx={{ color: 'primary' }}>
        {zoneNumber}
      </Typography>
    </Box>
  );
}

export function EsdecWeatherZonesModal({
  onClose,
  title,
  subTitle,
  children,
  imageSrc
}: IEsdecWeatherZonesModal): JSX.Element {
  return (
    <Box
      sx={{
        backgroundColor: '#F9FAFA'
      }}
    >
      <EsdecModalHeader onClose={onClose}>{title}</EsdecModalHeader>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: '140px',
          py: 8,
          alignItems: 'center'
        }}
      >
        <Image
          sx={{
            height: '800px'
          }}
          src={imageSrc}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <Text sx={{ fontSize: '2xl', color: 'primary' }}>{subTitle}</Text>
          <Box>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
}
