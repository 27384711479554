import { Shape } from '@/features/google-map/utils/shapes/shape';
import { IPoint } from '@/utils/gis/types';
import * as R from 'remeda';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';

interface IPolylineOptions extends google.maps.PolylineOptions {}
interface IPolylineCloneOptions {
  path?: IPoint[];
  options?: IPolylineOptions;
}

interface IPolylineShape {
  id: string;
  path: IPoint[];
}

class Polyline extends Shape implements IPolylineShape {
  protected readonly _path: IPoint[];

  public constructor(id: string, path: IPoint[], options?: IPolylineOptions) {
    super(id, R.merge({ path: path.map(pointToLatLng), strokeWeight: 2 }, R.omit(options ?? {}, ['path'])));
    this._path = path;
  }

  public get path(): IPoint[] {
    return this._path;
  }

  public build(): google.maps.Polyline {
    return new google.maps.Polyline(this._options);
  }

  public update(shape: Polyline): Polyline {
    const result = new Polyline(this.id, shape.path, R.merge(this._options, shape.options));
    result.eventListenersToAttach = shape.eventListenersToAttach;
    return result;
  }

  private _clone({ path, options }: IPolylineCloneOptions): Polyline {
    const result = new Polyline(this.id, path ?? this._path, options ?? this._options);
    result.eventListenersToAttach = this.eventListenersToAttach;
    return result;
  }

  public setPath(path: IPoint[]): Polyline {
    return this._clone({ path });
  }
}

function isPolyline(shape: Shape | undefined): shape is Polyline {
  return shape instanceof Polyline;
}

export { Polyline, isPolyline };
export type { IPolylineOptions };
