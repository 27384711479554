/** @jsxImportSource theme-ui */
import { Box, Text } from 'theme-ui';
import { Icons } from '@/assets';
import { ButtonIcon } from '@/components/button/button';

interface IEsdecModalHeader {
  onClose: () => void;
  children: React.ReactNode;
}

export function EsdecModalHeader({ onClose, children }: IEsdecModalHeader): JSX.Element {
  return (
    <Box
      sx={{
        py: 8,
        px: '72px',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Text sx={{ fontSize: 'xl', fontWeight: 600, textTransform: 'uppercase' }}>{children}</Text>
      <ButtonIcon
        sx={{
          color: 'primary'
        }}
        onClick={() => {
          onClose();
        }}
      >
        <Icons.Close />
      </ButtonIcon>
    </Box>
  );
}
