import { api } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { ProjectInformationKeys } from '@/features/project-information/api/keys';
import { z } from 'zod';
import { InferQueryOptions, UnwrapPromise } from '@/utils/types';
import { R } from '@/lib/remeda';

const responseParser = z.object({
  loss: z.number(),
  consumption: z.object({
    monthly: z.tuple([
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number()
    ]),
    normalCurveTotal: z.number(),
    yearly: z.number()
  }),
  financing: z.object({
    financier: z.string(),
    financingAmount: z.number(),
    financingTime: z.number(),
    interest: z.number()
  }),
  sale: z.object({
    consumptionValue: z.number(),
    priceIncrease: z.number(),
    saleValue: z.number()
  }),
  deductions: z.array(
    z.object({
      id: z.number(),
      exVat: z.number(),
      incVat: z.number(),
      name: z.string(),
      organization_id: z.number()
    })
  )
});

export async function calculationDefaultsApi({ calculationId }: ICalculationDefaultsApiRequest) {
  const searchParams = new URLSearchParams();
  if (R.isDefined(calculationId)) {
    searchParams.append('calculationID', calculationId.toString());
  }

  const response = await api.get(
    calculationId ? `/calculationDefaults?${searchParams.toString()}` : '/calculationDefaults'
  );
  const data = responseParser.parse(response.data);

  return {
    pvgisLoss: data.loss,
    consumption: {
      monthlyKwh: data.consumption.monthly,
      normalCurveTotal: data.consumption.normalCurveTotal,
      yearlyKwh: data.consumption.yearly
    },
    financing: {
      loanAmount: data.financing.financingAmount,
      loanInterestRatePercent: data.financing.interest,
      loanRepaymentPeriodYears: data.financing.financingTime
    },
    sale: {
      // TODO: Resolve https://linear.app/metergram/issue/SOL-150
      purchaseCost: data.sale.consumptionValue,
      priceDevelopmentPercentPerYear: data.sale.priceIncrease,
      sellingCost: data.sale.saleValue
    },
    deductions: data.deductions.map((item) => ({
      id: String(item.id),
      name: item.name,
      excludeVat: item.exVat !== 0,
      includeVat: item.incVat !== 0
    }))
  };
}

export type ICalculationDefaultsApiResponse = UnwrapPromise<ReturnType<typeof calculationDefaultsApi>>;
export interface ICalculationDefaultsApiRequest {
  calculationId?: number;
}

export function useCalculationDefaults(
  request: ICalculationDefaultsApiRequest = {},
  options?: InferQueryOptions<typeof calculationDefaultsApi>
) {
  return useQuery(
    ProjectInformationKeys.calculationDefaults(request),
    () => calculationDefaultsApi(request),
    {
      staleTime: Infinity,
      ...options
    }
  );
}
