/** @jsxImportSource theme-ui */
import { Box, Heading, Paragraph, Link } from 'theme-ui';
import { ButtonPrimary } from '@/components/button/button';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useLogging } from '@/utils/use-logging';
import { HttpError } from '@/utils/api';
import { PageNotFound } from '@/components/page-not-found';
import { useRouteError } from 'react-router-dom';

export default function ErrorPage(): JSX.Element {
  const logging = useLogging();
  const error = useRouteError();

  React.useEffect(() => {
    logging.error({
      error: error as Error
    });

    // We only want to run this once, to report on the error that got the fallback to render in the first place.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error instanceof HttpError && error.statusCode === 404) {
    return <PageNotFound />;
  }

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', px: '450px' }}
    >
      <Box
        sx={{
          backgroundColor: '#FBFBFB',
          border: '1px dashed #CCCCCC',
          px: 40,
          py: 44,
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          maxWidth: 500
        }}
      >
        <Heading sx={{ color: 'black', fontSize: '2xl', fontWeight: 400 }}>
          <FormattedMessage
            defaultMessage="Something went wrong"
            id="ixznqc"
            description="Heading for the error component"
          />
        </Heading>
        <Paragraph sx={{ color: '#3F3F3F', fontSize: 'md', mb: 5, lineHeight: '24px' }}>
          <FormattedMessage
            defaultMessage="An unexpected error occurred. Click the button below to reload the page and try again.
            If the error persists, please contact us at"
            id="basY0k"
            description="Paragraph for the error component"
          />{' '}
          <Link sx={{ color: 'primary' }} href="mailto:support@solegrossisten.se">
            support@solegrossisten.se
          </Link>
          .
        </Paragraph>
        <ButtonPrimary onClick={() => window.location.reload()}>
          <FormattedMessage
            defaultMessage="Reload"
            id="jdeGXS"
            description="A button for refreshing the page"
          />
        </ButtonPrimary>
      </Box>
    </Box>
  );
}
