/** @jsxImportSource theme-ui */
import { Grid } from 'theme-ui';
import { Typography } from '@/components/typography';
import { Icons } from '@/assets';
import { EditorResultsCard } from '@/features/editor-results/components/editor-results-card/editor-results-card';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { EmptyPropsWithChildren } from '@/utils/types';
import { ICalculationAccessoriesApiResponse, IGetAllProductsApiResponse } from '@/features/editor/api';
import { useFilteredAdditionsData } from '@/features/editor-results/utils/use-filtered-additions-data';
import { ResultsCardDataTitle, ResultsCardDataValue } from '@/features/editor-results/components/common';

function TableTitle({ children }: EmptyPropsWithChildren): JSX.Element {
  return (
    <Typography sx={{ pl: 8, mt: 7, mb: 1 }} variant="headline3">
      {children}
    </Typography>
  );
}

function DataColumnWrapper({ children }: PropsWithChildren<{}>): JSX.Element {
  return <Grid sx={{ mx: 'auto' }}>{children}</Grid>;
}

export function EditorResultsAdditions({
  allProducts,
  calculationAccessories
}: {
  allProducts: IGetAllProductsApiResponse;
  calculationAccessories: ICalculationAccessoriesApiResponse;
}): JSX.Element {
  const intl = useIntl();
  const formatCurrency = useFormatCurrency();

  const { chargingBox, wallBoxGroupedBy, batteriesGroupedBy, energyStorage } = useFilteredAdditionsData({
    allProducts,
    calculationAccessories
  });

  if (energyStorage.length === 0 && chargingBox.length === 0) {
    return <React.Fragment />;
  }

  return (
    <EditorResultsCard.Wrapper>
      <EditorResultsCard.Title>
        <Icons.CostsForConstruction />
        <Typography variant="headline2">
          <FormattedMessage defaultMessage="Energy storage and charging box cost" id="yWMUI4" />
        </Typography>
      </EditorResultsCard.Title>

      <Grid>
        {energyStorage.length > 0 ? (
          <React.Fragment>
            <TableTitle>
              <FormattedMessage
                defaultMessage="Energy storage"
                id="rx2Z9F"
                description="Title for energy storage"
              />
            </TableTitle>

            <Grid
              columns={[4]}
              sx={{
                py: 4,
                fontSize: 'sm',
                px: 8,
                '&:not(:last-child)': {
                  borderBottom: '1px solid',
                  borderColor: 'inputBorder'
                }
              }}
            >
              <DataColumnWrapper>
                <ResultsCardDataTitle>
                  {intl.formatMessage({
                    defaultMessage: 'Name',
                    id: 'HAlOn1'
                  })}
                </ResultsCardDataTitle>
                {energyStorage.map((battery, index) => (
                  <ResultsCardDataValue key={index}>{battery.name_battery}</ResultsCardDataValue>
                ))}
              </DataColumnWrapper>
              <DataColumnWrapper>
                <ResultsCardDataTitle>
                  {intl.formatMessage({
                    defaultMessage: 'Sell price',
                    id: 'Fsk3WQ'
                  })}
                </ResultsCardDataTitle>
                {energyStorage.map((battery, index) => (
                  <ResultsCardDataValue key={index}>{formatCurrency(battery.sellprice)}</ResultsCardDataValue>
                ))}
              </DataColumnWrapper>
              <DataColumnWrapper>
                <ResultsCardDataTitle>
                  {intl.formatMessage({
                    defaultMessage: 'Quantity',
                    id: 'qVGRIE'
                  })}
                </ResultsCardDataTitle>
                {energyStorage.map((battery, index) => (
                  <ResultsCardDataValue key={index}>
                    {batteriesGroupedBy[battery.info_id]?.length ?? 0}
                  </ResultsCardDataValue>
                ))}
              </DataColumnWrapper>
              <DataColumnWrapper>
                <ResultsCardDataTitle>
                  {intl.formatMessage({
                    defaultMessage: 'Total price',
                    id: '4VivsY'
                  })}
                </ResultsCardDataTitle>
                {energyStorage.map((battery, index) => (
                  <ResultsCardDataValue key={index}>
                    {formatCurrency(battery.sellprice * (batteriesGroupedBy[battery.info_id]?.length ?? 0))}
                  </ResultsCardDataValue>
                ))}
              </DataColumnWrapper>
            </Grid>
          </React.Fragment>
        ) : null}

        {chargingBox.length > 0 ? (
          <React.Fragment>
            <TableTitle>
              <FormattedMessage
                defaultMessage="Charging box"
                id="xcA4aI"
                description="Title for charging box"
              />
            </TableTitle>
            <Grid
              columns={[4]}
              sx={{
                py: 4,
                fontSize: 'sm',
                px: 10,
                '&:not(:last-child)': {
                  borderBottom: '1px solid',
                  borderColor: 'inputBorder'
                }
              }}
            >
              <DataColumnWrapper>
                <ResultsCardDataTitle>
                  {intl.formatMessage({
                    defaultMessage: 'Name',
                    id: 'HAlOn1'
                  })}
                </ResultsCardDataTitle>
                {chargingBox.map((wallbox, index) => (
                  <ResultsCardDataValue key={index}>{wallbox.name_wallbox}</ResultsCardDataValue>
                ))}
              </DataColumnWrapper>
              <DataColumnWrapper>
                <ResultsCardDataTitle>
                  {intl.formatMessage({
                    defaultMessage: 'Sell price',
                    id: 'Fsk3WQ'
                  })}
                </ResultsCardDataTitle>
                {chargingBox.map((wallbox, index) => (
                  <ResultsCardDataValue key={index}>{formatCurrency(wallbox.sellprice)}</ResultsCardDataValue>
                ))}
              </DataColumnWrapper>
              <DataColumnWrapper>
                <ResultsCardDataTitle>
                  {intl.formatMessage({
                    defaultMessage: 'Quantity',
                    id: 'qVGRIE'
                  })}
                </ResultsCardDataTitle>
                {chargingBox.map((wallbox, index) => (
                  <ResultsCardDataValue key={index}>
                    {wallBoxGroupedBy[wallbox.info_id]?.length ?? 0}
                  </ResultsCardDataValue>
                ))}
              </DataColumnWrapper>
              <DataColumnWrapper>
                <ResultsCardDataTitle>
                  {intl.formatMessage({
                    defaultMessage: 'Total price',
                    id: '4VivsY'
                  })}
                </ResultsCardDataTitle>
                {chargingBox.map((wallbox, index) => (
                  <ResultsCardDataValue key={index}>
                    {formatCurrency(wallbox.sellprice * (wallBoxGroupedBy[wallbox.info_id]?.length ?? 0))}
                  </ResultsCardDataValue>
                ))}
              </DataColumnWrapper>
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>
    </EditorResultsCard.Wrapper>
  );
}
