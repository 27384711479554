import { ISide } from '@/utils/drawing/types';
import { Feature, LineString } from '@turf/helpers';
import rhumbBearing from '@turf/rhumb-bearing';
import * as turf from '@turf/helpers';
import rhumbDestination from '@turf/rhumb-destination';
import { Gis } from '@/features/editor/utils/gis';

// Given a line, extends it by 1000 degrees in both directions
export function extendLine(line: ISide): Feature<LineString> {
  const middle = Gis.midpoint(line.start, line.end);
  const bearing = rhumbBearing(line.start, line.end);
  return turf.lineString([
    rhumbDestination(middle, 1000, bearing).geometry.coordinates,
    middle,
    rhumbDestination(middle, 1000, bearing + 180).geometry.coordinates
  ]);
}
