import { Text, TextProps } from 'theme-ui';
import React from 'react';

type TypographyVariant =
  | 'headline1'
  | 'headline2'
  | 'headline3'
  | 'headline4'
  | 'headline5'
  | 'body1'
  | 'body2';
type TypographyProps = Omit<TextProps, 'variant'> & {
  variant?: TypographyVariant;
};

const VARIANT_ELEMENT: Record<TypographyVariant, keyof React.ReactHTML> = {
  body1: 'p',
  body2: 'p',
  headline1: 'h1',
  headline2: 'h2',
  headline3: 'h3',
  headline4: 'h4',
  headline5: 'h5'
};

/*
 * Component which should cover 80% of all typography uses.
 *
 * Uses the [Material type system](https://material.io/design/typography/the-type-system.html#type-scale).
 * Feel free to create your own type system, though keep in mind that it
 * should cover at least 80% of the typography uses in the project.
 */
function Typography({ variant = 'body1', sx, ...props }: TypographyProps): JSX.Element {
  const as = VARIANT_ELEMENT[variant];

  return <Text as={as} variant={variant} {...props} sx={sx} />;
}

export { Typography };
