import { useQuery } from '@tanstack/react-query';
import { EditorContext } from '@/features/editor/utils/editor-context';
import { api, IApiOptions } from '@/utils/api';
import { InferQueryOptions, UnwrapArray } from '@/utils/types';
import { EditorApiKeys } from '@/features/editor/api/keys';
import { z } from 'zod';
import { R } from '@/lib/remeda';
import { surfaceImageName, surfaceParser } from '@/features/editor/utils/surface/surface';
import { overlayImageName } from '@/features/google-map/utils/map-types/overlay-image-map';
import { FileUpload } from '@/features';
import { nn } from '@/utils/invariant';

export interface IGetEditorContextApiRequest {
  calculationId: number;
}

// Maps a surface from the old version (which has a panel object) to the new version (which has a panelTypeId)
function mapSurface(surface: z.infer<typeof surfaceParser>): UnwrapArray<EditorContext['surfaces']> {
  const result = R.omit(surface, ['panel']);

  if (surface.panel) {
    return {
      ...result,
      panelTypeId: surface.panel.productId
    };
  }

  if (surface.panelTypeId) {
    return {
      ...result,
      panelTypeId: surface.panelTypeId
    };
  }

  throw new Error(`Surface ${surface.id} has no panel`);
}

/**
 * Since images are stored in a private S3 bucket,
 * we need to get the signed URLs whenever we load the data.
 *
 * This function should be used to load all
 * S3 images that are stored in the EditorContext.
 * */
async function getImageUrls(
  context: EditorContext,
  options?: IApiOptions
): Promise<EditorContext | undefined> {
  const imageNames = [
    ...context.overlayImages.map((image) => ({ name: overlayImageName(image) })),
    ...context.surfaces
      .filter((surface) => R.isDefined(surface.mapImageSrc))
      .map((surface) => ({ name: surfaceImageName(surface) }))
  ];
  const imageUrls = await FileUpload.getFilesUrl(imageNames, options);

  return {
    ...context,
    surfaces: context.surfaces.map((surface) => {
      const mapImageSrc = imageUrls[surfaceImageName(surface)];
      return {
        ...surface,
        ...(mapImageSrc && { mapImageSrc })
      };
    }),
    overlayImages: context.overlayImages.map((image) => {
      const src = imageUrls[overlayImageName(image)];
      nn(src, `Could not find image URL for ${overlayImageName(image)}`);
      return {
        ...image,
        src
      };
    })
  };
}

const responseParser = z.object({
  surfaces: z.union([z.array(surfaceParser), z.record(surfaceParser)]).transform((value) => {
    if (Array.isArray(value)) {
      return value;
    }

    return Object.values(value);
  }),
  overlayImages: z.array(z.any()).default([])
});

export async function getEditorContextApi(
  request: IGetEditorContextApiRequest,
  options?: IApiOptions
): Promise<EditorContext | undefined> {
  const response = await api.get<EditorContext>(
    `/getEditorState?calculationID=${request.calculationId}`,
    options
  );
  const { surfaces, overlayImages } = responseParser.parse(response.data);

  const result = {
    ...response.data,
    overlayImages,
    surfaces: surfaces.map(mapSurface)
  };

  return getImageUrls(result, options);
}

export function useGetEditorContext(
  request: IGetEditorContextApiRequest,
  options?: InferQueryOptions<typeof getEditorContextApi>
) {
  return useQuery(EditorApiKeys.editorContext(request), () => getEditorContextApi(request), options);
}
