import { IAddressDataApiRequest } from '@/features/editor/api/address-data';
import { ICalculationAccessoriesApiRequest } from '@/features/editor/api/calculation-accessories';
import { IGetEditorResultsApiRequest } from '@/features/editor-results/api/get-editor-results';
import { IGetMountingSystemsApiRequest } from '@/features/editor/api/get-mounting-systems';
import { IGetEditorContextApiRequest } from '@/features/editor/api/get-editor-context';
import { IGetAllProductsApiRequest } from '@/features/editor/api/get-all-products';

export const EditorApiKeys = {
  backendData: ['backendData'],
  accessories: ['accessories'],
  accessoriesProducts: ({ calculationId }: IGetAllProductsApiRequest) => [
    ...EditorApiKeys.accessories,
    'products',
    calculationId
  ],
  calculationAccessories: ({ calculationId }: ICalculationAccessoriesApiRequest) => [
    ...EditorApiKeys.accessories,
    calculationId
  ],
  addressData: ({ address, zipCode }: IAddressDataApiRequest) => ['addressData', address, zipCode],
  getMountingSystems: ({ calculationId }: IGetMountingSystemsApiRequest) => [
    'getMountingSystems',
    calculationId
  ],
  editorResults: ({ calculationId }: IGetEditorResultsApiRequest) => ['editorResults', calculationId],
  editorContext: ({ calculationId }: IGetEditorContextApiRequest) => ['editorContext', calculationId],
  helpTexts: ['helpTexts']
};
