import { IEditorContext } from '@/features/editor/utils/editor-context';
import { R } from '@/lib/remeda';
import { IPanelsCacheUpdatePayload } from '@/features/editor/utils/actions/use-editor-actions';

function removeOutdatedCacheEntries(
  draft: Pick<IEditorContext, 'surfacesPanelsCache'>,
  oldKeys: string[],
  newKeys: string[]
) {
  R.difference(oldKeys, newKeys).forEach((key) => {
    delete draft.surfacesPanelsCache[key];
  });
}

export function panelsCacheUpdate(
  draft: Pick<IEditorContext, 'surfacesPanelsCache'>,
  event: IPanelsCacheUpdatePayload
) {
  removeOutdatedCacheEntries(draft, Object.keys(draft.surfacesPanelsCache), Object.keys(event.cache));

  for (const [surfaceId, { hash, panels }] of R.toPairs(event.cache)) {
    const existingCache = draft.surfacesPanelsCache[surfaceId];
    if (R.isNil(existingCache)) {
      draft.surfacesPanelsCache[surfaceId] = {
        hash,
        panels
      };
    } else if (existingCache.hash !== hash) {
      existingCache.hash = hash;
      existingCache.panels = panels;
    }
  }
}
