import * as R from 'remeda';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import React from 'react';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import { themeColors } from '@/lib/theme-ui/colors';
import { surfaceRidgeSide } from '@/features/google-map/utils/surface-ridge-side';
import { MapPolyline } from '@/features/google-map/components/shapes/map-polyline';

export function RenderSurfaceRidges() {
  const surfaces = useSurfaces();

  return (
    <React.Fragment>
      {R.pipe(
        surfaces,
        R.map(surfaceRidgeSide),
        R.filter(R.isDefined),
        R.flatten(),
        R.map((ridge) => (
          <MapPolyline
            key={ridge.ridgeId}
            path={[ridge.start, ridge.end]}
            strokeColor={themeColors.panelHover}
            zIndex={GOOGLE_MAP_Z_INDICES.RIDGE_LINE}
          />
        ))
      )}
    </React.Fragment>
  );
}
