import { IPoint } from '@/utils/gis/types';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { GoogleMapConversion } from '@/features/google-map/utils/google-map-conversion';

export function MapMarker(
  props: Omit<google.maps.MarkerOptions, 'position'> & {
    point: IPoint;
    onDragEnd?: (event: google.maps.MapMouseEvent) => void;
  }
) {
  const { map } = useGoogleMapContext();
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      const marker = new google.maps.Marker();
      setMarker(marker);
      marker.setMap(map);
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [map, marker]);

  useDeepCompareEffect(() => {
    if (marker) {
      marker.setOptions({
        ...props,
        position: GoogleMapConversion.pointToLatLng(props.point)
      });
    }
  }, [map, marker, props]);

  const { onDragEnd } = props;

  React.useEffect(() => {
    const listeners = [onDragEnd ? marker?.addListener('dragend', onDragEnd) : undefined];

    return () => {
      listeners.forEach((listener) => listener?.remove());
    };
  }, [marker, onDragEnd]);

  return null;
}
