import { EditorResultsCardPrintable } from '@/features/editor-results/components/editor-results-card/editor-results-card-printable';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import {
  IEditorResultsSurfaceCardProps,
  IInverterType,
  getUniqueInverters
} from '@/features/editor-results/components/editor-results-surface-card/editor-results-surface-card';
import { ISurfaceCardDataPointProps } from '@/features/editor-results/components/editor-results-surface-card/editor-results-surface-card';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { IconsPdf } from '@/assets';
import {
  formatKiloWatts,
  formatKiloWattsHours,
  formatWatts,
  formatKiloWattsPeak
} from '@/features/editor-results/components/editor-results-surface-card/editor-results-surface-card';
import React from 'react';
import { EmptyPropsWithChildren } from '@/utils/types';
import {
  SURFACE_PLACEMENT_CHIP_ICON_PRINTABLE,
  SURFACE_PLACEMENT_LABEL
} from '@/features/editor/utils/surface/surface';
import { themeColors } from '@/lib/theme-ui/colors';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { formatMetersSquared } from '@/features/intl/utils/format-meters-squared';
import { useFormatYears } from '@/features/intl/utils/use-format-years';

type ViewProps = ComponentProps<typeof View>;

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 20,
    marginBottom: 5
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
});

interface ISurfaceCardDataTitleProps {
  style?: ViewProps['style'];
  children: React.ReactNode;
}

interface ISurfaceCardChipProps {
  icon: React.ReactNode;
  label: string;
}

interface ISurfaceCardDataGridProps {
  children: React.ReactNode;
  isFirst?: boolean;
}

interface ISurfaceCardDataPointPrintableProps extends ISurfaceCardDataPointProps {
  isFirst?: boolean;
}

function SurfaceCardChip({ icon, label }: ISurfaceCardChipProps): JSX.Element {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
      {icon}
      <Text style={{ marginLeft: 3 }}>{label}</Text>
    </View>
  );
}

function SurfaceCardDataTitle({ children, style }: ISurfaceCardDataTitleProps): JSX.Element {
  return <Text style={{ ...style }}>{children}</Text>;
}

function SurfaceCardDataValue({ children }: EmptyPropsWithChildren): JSX.Element {
  return <Text>{children}</Text>;
}

function SurfaceCardDataGrid({ children, isFirst }: ISurfaceCardDataGridProps): JSX.Element {
  return (
    <View
      style={{
        padding: 10,
        flexDirection: 'row',
        backgroundColor: isFirst ? themeColors.surfaceCardBackground : ''
      }}
    >
      {children}
    </View>
  );
}

function SurfaceCardDataPointIcon({ value, label, icon }: ISurfaceCardDataPointProps): JSX.Element {
  const valueAsArray = Array.isArray(value) ? value : [value];
  return (
    <View
      style={{
        flexDirection: 'row',
        fontSize: 10,
        color: '#707070',
        paddingRight: 25,
        paddingBottom: 10
      }}
    >
      <View style={{ color: 'primary', marginRight: 8 }}>{icon}</View>
      <SurfaceCardDataTitle style={{ marginRight: 8 }}>{label}</SurfaceCardDataTitle>

      <View style={{ borderLeft: 1, borderColor: '#9CDCB6', paddingLeft: 8 }}>
        {valueAsArray.map((value, index) => (
          <SurfaceCardDataValue key={index}>{value}</SurfaceCardDataValue>
        ))}
      </View>
    </View>
  );
}

function SurfaceCardDataPoint({ value, label, isFirst }: ISurfaceCardDataPointPrintableProps): JSX.Element {
  const valueAsArray = Array.isArray(value) ? value : [value];

  return (
    <View style={{ fontSize: 8, color: '#667085', paddingRight: 30 }}>
      <SurfaceCardDataTitle style={{ marginRight: 8 }}>{label}</SurfaceCardDataTitle>

      <View
        style={{
          fontWeight: 600,
          maxWidth: isFirst === true ? 150 : 35,
          minWidth: isFirst === true ? 150 : 35,
          marginVertical: 5
        }}
      >
        {valueAsArray.map((value, index) => (
          <SurfaceCardDataValue key={index}>{value}</SurfaceCardDataValue>
        ))}
      </View>
    </View>
  );
}

export function EditorResultsSurfaceCardPrintable(
  props: IEditorResultsSurfaceCardProps & {
    style?: ViewProps['style'];
  }
): JSX.Element {
  const intl = useIntl();

  const formatYears = useFormatYears();
  const surfaces = useSurfaces();

  function getSharedPrimarySurface(inverterType: IInverterType): string | undefined {
    return inverterType.sharedSurfaceId !== props.id
      ? surfaces.find((s) => s.id === inverterType.sharedSurfaceId)?.description
      : undefined;
  }

  return (
    <EditorResultsCardPrintable.Wrapper wrap={false} style={{ marginBottom: 10, ...props.style }}>
      <EditorResultsCardPrintable.Title>
        <View
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row'
          }}
        >
          <Text style={{ fontSize: 9 }}>{props.description}</Text>

          {props.mountingSystemName ? (
            <View style={{ flex: 1, textAlign: 'right' }}>
              <Text style={{ fontSize: 7, fontWeight: 'normal' }}>
                {intl.formatMessage({
                  defaultMessage: 'Typ av monteringssystem',
                  id: 'up+owV',
                  description: 'Mounting System Type label'
                })}
              </Text>
              <Text style={{ marginTop: 2, fontWeight: 'medium' }}>{props.mountingSystemName}</Text>
            </View>
          ) : null}
        </View>
      </EditorResultsCardPrintable.Title>

      <EditorResultsCardPrintable.Content style={{ paddingHorizontal: 6 }}>
        <View style={{ paddingLeft: 10 }}>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={styles.container}>
              <SurfaceCardDataPointIcon
                size="large"
                label={intl.formatMessage({
                  defaultMessage: 'Area',
                  id: 'S0tyhI',
                  description: 'Area label'
                })}
                value={formatMetersSquared(props.surfaceArea)}
                icon={<IconsPdf.Area />}
              />
              <SurfaceCardDataPointIcon
                size="large"
                label={intl.formatMessage({
                  defaultMessage: 'Panel yta',
                  id: '/njA/z',
                  description: 'Panel yta label'
                })}
                value={formatMetersSquared(props.panelArea)}
                icon={<IconsPdf.Area />}
              />
              <SurfaceCardDataPointIcon
                size="large"
                label={intl.formatMessage({
                  defaultMessage: 'Lutning',
                  id: 'JhZtVS',
                  description: 'Lutning label'
                })}
                value={`${props.tiltAngle} °`}
                icon={<IconsPdf.Tilt />}
              />
            </View>

            <View style={styles.container}>
              <SurfaceCardDataPointIcon
                size="large"
                label={intl.formatMessage({
                  defaultMessage: 'Antal paneler',
                  id: '/L6Ruc',
                  description: 'Total produktion'
                })}
                value={formatKiloWatts((props.panel.power * props.panelsCount) / 1000)}
                icon={<IconsPdf.Panel />}
              />
              <SurfaceCardDataPointIcon
                size="large"
                label={intl.formatMessage({
                  defaultMessage: 'Höjd till takfot',
                  id: 'Joecyu',
                  description: 'Höjd till takfot label'
                })}
                value={intl.formatNumber(props.heightToEavesMeters, { style: 'unit', unit: 'meter' })}
                icon={<IconsPdf.HeightToEaves />}
              />
              <SurfaceCardDataPointIcon
                size="large"
                label={intl.formatMessage({
                  defaultMessage: 'Årlig production',
                  id: 'BO3ZOt',
                  description: 'Årlig production'
                })}
                value={formatKiloWattsHours(props.yearlyProduction ?? 0)}
                icon={<IconsPdf.Panel />}
              />
            </View>
            <View style={styles.container}>
              <SurfaceCardDataPointIcon
                size="large"
                label={intl.formatMessage({
                  defaultMessage: 'Azimut',
                  id: 'MRldZ5',
                  description: 'Tak azimut label'
                })}
                value={`${props.rotationAngle} °`}
                icon={<IconsPdf.Asimuth />}
              />
              <SurfaceCardDataPointIcon
                size="large"
                label={intl.formatMessage({
                  defaultMessage: 'Tak',
                  id: 'mL1smj',
                  description: 'Tak typ (angled, flat, etc.) label'
                })}
                value={SURFACE_PLACEMENT_LABEL[props.placement]!}
                icon={SURFACE_PLACEMENT_CHIP_ICON_PRINTABLE[props.placement]!}
              />

              <View style={{ flex: 1 }} />
            </View>
          </View>
        </View>

        <SurfaceCardDataGrid isFirst={true}>
          <View>
            <View style={styles.row}>
              <SurfaceCardDataPoint
                isFirst={true}
                label={intl.formatMessage({
                  defaultMessage: 'Panel Typ',
                  id: 'GbqP3M',
                  description: 'Panel typ label'
                })}
                value={props.panelType.name}
              />
              <SurfaceCardDataPoint
                isFirst={true}
                label={intl.formatMessage({
                  defaultMessage: 'Antal',
                  id: 'CHawra',
                  description: 'Panel antal label'
                })}
                value={intl.formatNumber(props.panelsCount)}
              />
              <SurfaceCardDataPoint
                isFirst={true}
                label={intl.formatMessage({
                  defaultMessage: 'Nominell Effekt',
                  id: 'RFJrLx',
                  description: 'Nominell kraft label'
                })}
                value={formatWatts(props.panelType.power.toString())}
              />
            </View>
            <View style={styles.row}>
              <SurfaceCardDataPoint
                isFirst={true}
                label={intl.formatMessage({
                  defaultMessage: 'Utgångsgaranti',
                  id: '1aUYO4',
                  description: 'Utgångsgaranti label'
                })}
                value={formatYears(props.panelType.warrantyTime)}
              />

              <SurfaceCardDataPoint
                isFirst={true}
                label={intl.formatMessage({
                  defaultMessage: 'Garanterad kraft',
                  id: 'sH9sS6',
                  description: 'Garanterad kraft label'
                })}
                value={props.panelType.warrantyEffect + '%'}
              />

              <SurfaceCardDataPoint
                isFirst={true}
                label={intl.formatMessage({
                  defaultMessage: 'Produktionsgaranti',
                  id: 'EW86qd',
                  description: 'Produktionsgaranti label'
                })}
                value={formatYears(props.panelType.materialWarranty)}
              />
            </View>
          </View>
        </SurfaceCardDataGrid>

        <View>
          {props.inverterTypes.length > 0
            ? getUniqueInverters(props.inverterTypes).map((inverterType, index) => (
                <SurfaceCardDataGrid key={index}>
                  <View>
                    <SurfaceCardDataPoint
                      isFirst={true}
                      label={intl.formatMessage({
                        defaultMessage: 'Växelriktartyp',
                        id: 'QqOgAA',
                        description: 'Växelriktartyp label'
                      })}
                      value={inverterType.name}
                    />

                    <View style={{ flexDirection: 'row' }}>
                      {inverterType.isBatteryPrepared ? (
                        <SurfaceCardChip icon={<IconsPdf.Battery />} label="Battery" />
                      ) : null}
                      {getSharedPrimarySurface(inverterType) ? (
                        <SurfaceCardChip
                          key={index}
                          icon={<IconsPdf.Shared />}
                          label={`Shared with ${getSharedPrimarySurface(inverterType)}`}
                        />
                      ) : null}
                    </View>
                  </View>
                  {getSharedPrimarySurface(inverterType) ? (
                    <React.Fragment>
                      <SurfaceCardDataPoint
                        label={intl.formatMessage({
                          defaultMessage: 'Antal',
                          id: 'V6lLLD',
                          description: 'Växelriktarantal label'
                        })}
                        value={inverterType.quantity}
                      />
                      <SurfaceCardDataPoint
                        label={intl.formatMessage({
                          defaultMessage: 'Nominell Effekt',
                          id: 'EW4KM6',
                          description: 'Växelriktare nominell kraft label'
                        })}
                        value={formatKiloWattsPeak(inverterType.power / 1000)}
                      />
                    </React.Fragment>
                  ) : null}
                  <React.Fragment key={index}>
                    {!getSharedPrimarySurface(inverterType) ? (
                      <React.Fragment>
                        <SurfaceCardDataPoint
                          label={intl.formatMessage({
                            defaultMessage: 'Quantity',
                            id: 'V6lLLD',
                            description: 'Växelriktare quantity label'
                          })}
                          value={inverterType.quantity}
                        />
                        <SurfaceCardDataPoint
                          label={intl.formatMessage({
                            defaultMessage: 'Nominell effekt',
                            id: 'EW4KM6',
                            description: 'Växelriktare nominell kraft label'
                          })}
                          value={formatKiloWattsPeak(inverterType.power / 1000)}
                        />
                        <SurfaceCardDataPoint
                          label={intl.formatMessage({
                            defaultMessage: 'Garantitid',
                            id: 'anAw/P',
                            description: 'Växelriktare garantitid label'
                          })}
                          value={formatYears(inverterType.warrantyTime)}
                        />
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                </SurfaceCardDataGrid>
              ))
            : null}
          {props.optimizerTypes.length > 0 && props.optimizerPoints.length > 0 ? (
            <SurfaceCardDataGrid isFirst={true}>
              <SurfaceCardDataPoint
                isFirst={true}
                label={intl.formatMessage({
                  defaultMessage: 'Optimizer Type',
                  id: '0N7Ffm',
                  description: 'Optimizer type label'
                })}
                value={props.optimizerTypes.map((i) => i.name)}
              />
              <SurfaceCardDataPoint
                label={intl.formatMessage({
                  defaultMessage: 'Quantity',
                  id: '/kVyXt',
                  description: 'Optimizer quantity label'
                })}
                value={props.optimizerPoints.length}
              />
              <SurfaceCardDataPoint
                label={intl.formatMessage({
                  defaultMessage: 'Nominell effekt',
                  id: 'I1gQCS',
                  description: 'Optimizer Nominell kraft label'
                })}
                value={props.optimizerTypes.map((i) => formatWatts(i.power.toString()))}
              />
              <SurfaceCardDataPoint
                label={intl.formatMessage({
                  defaultMessage: 'Warranty Time',
                  id: 'Jb64sV',
                  description: ' Optimizer warranty time label'
                })}
                value={props.optimizerTypes.map((i) => formatYears(i.warrantyTime))}
              />
            </SurfaceCardDataGrid>
          ) : null}
        </View>
      </EditorResultsCardPrintable.Content>
    </EditorResultsCardPrintable.Wrapper>
  );
}
