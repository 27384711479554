import * as R from 'remeda';
import React from 'react';
import { createContext } from '@/utils/create-context';
import { EmptyPropsWithChildren } from '@/utils/types';
import { IGetPanelsWorkerData } from '@/features/editor/utils/panel/get-optimal-panel-placement-on-surface-worker';
import { GeneratedPanel } from '@/features/editor/utils/panel/panel';
import { useWorker } from '@/utils/use-worker';
import { Feature, Polygon } from '@turf/helpers';
import { Gis } from '@/features/editor/utils/gis';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import { autorun, toJS } from 'mobx';
import { ISurface } from '@/features/editor/utils/surface/surface';
import { IObstacle } from '@/features/editor/utils/obstacle';
import { IEditorContext } from '@/features/editor/utils/editor-context';

const createWorker = (): Worker =>
  new Worker(new URL('get-optimal-panel-placement-on-surface-worker', import.meta.url), { type: 'module' });

export type SurfacePanelsMap = Record<string, GeneratedPanel[]>;
export type IGetSurfacePanelsWorkerReturn = Record<
  string,
  {
    // The panels after filtering (obstacle intersection, deleted panel locations, etc.)
    filteredPanels: GeneratedPanel[];
    // The panels before filtering, mostly used for caching purposes
    nonFilteredPanels: Feature<Polygon>[];
    // The hash of the panel generation run
    hash: string;
  }
>;

interface ISurfaceWithPanelDimensions extends Omit<ISurface, 'panel'> {
  panel: {
    widthMeters: number;
    heightMeters: number;
  };
}

// Generates optimal panel placement for every surface in the machine context
function useSurfacePanelsInner(): {
  panels: SurfacePanelsMap;
  isLoading: boolean;
} {
  const store = useEditorStore();

  const [surfacesWithPanelDimensions, setSurfacesWithPanelDimensions] = React.useState<
    ISurfaceWithPanelDimensions[]
  >([]);
  const [obstacles, setObstacles] = React.useState<IObstacle[]>(() => toJS(store.obstacles.obstacles));
  const [surfacesPanelsCache, setSurfacesPanelsCache] = React.useState<IEditorContext['surfacesPanelsCache']>(
    () => toJS(store.surfaces.surfacesPanelsCache)
  );
  const [panelTypes, setPanelTypes] = React.useState(() => toJS(store.metadata.panelTypes));

  React.useEffect(() => {
    const dispose = autorun(() => {
      setSurfacesWithPanelDimensions(
        toJS(store.surfaces.surfaces).map((surface) => {
          const panelType = panelTypes.find((panelType) => panelType.productId === surface.panelTypeId);
          return {
            ...surface,
            panel: panelType
              ? { heightMeters: panelType.heightMeters, widthMeters: panelType.widthMeters }
              : { widthMeters: 0, heightMeters: 0 }
          };
        })
      );
    });

    return () => {
      dispose();
    };
  }, [store, panelTypes]);

  React.useEffect(() => {
    const dispose = autorun(() => {
      setObstacles(toJS(store.obstacles.obstacles));
    });

    return () => {
      dispose();
    };
  }, [store]);

  React.useEffect(() => {
    const dispose = autorun(() => {
      setSurfacesPanelsCache(toJS(store.surfaces.surfacesPanelsCache));
    });

    return () => {
      dispose();
    };
  }, [store]);

  React.useEffect(() => {
    const dispose = autorun(() => {
      setPanelTypes(toJS(store.metadata.panelTypes));
    });

    return () => {
      dispose();
    };
  }, [store.metadata.panelTypes]);

  const valueOne: IGetPanelsWorkerData = React.useMemo(
    () => ({ surfaces: surfacesWithPanelDimensions, threadIndex: 'one', obstacles, surfacesPanelsCache }),
    [surfacesWithPanelDimensions, obstacles, surfacesPanelsCache]
  );
  const valueTwo: IGetPanelsWorkerData = React.useMemo(
    () => ({ ...valueOne, threadIndex: 'two' }),
    [valueOne]
  );
  const valueThree: IGetPanelsWorkerData = React.useMemo(
    () => ({ ...valueOne, threadIndex: 'three' }),
    [valueOne]
  );

  const threadOne = useWorker<IGetPanelsWorkerData, IGetSurfacePanelsWorkerReturn>(createWorker, valueOne);
  const threadTwo = useWorker<IGetPanelsWorkerData, IGetSurfacePanelsWorkerReturn>(createWorker, valueTwo);
  const threadThree = useWorker<IGetPanelsWorkerData, IGetSurfacePanelsWorkerReturn>(
    createWorker,
    valueThree
  );

  const threadOneResult = threadOne.state.result;
  const threadTwoResult = threadTwo.state.result;
  const threadThreeResult = threadThree.state.result;

  const isAnyLoading = [threadOne.status, threadTwo.status, threadThree.status].includes('loading');

  const workerResult = React.useMemo(
    function pickMostPanelsCandidate() {
      const result: IGetSurfacePanelsWorkerReturn = {};
      const candidates = [threadOneResult, threadTwoResult, threadThreeResult].filter(R.isDefined);

      for (const candidate of candidates) {
        for (const [surfaceId, panels] of Object.entries(candidate)) {
          if (R.isNil(result[surfaceId])) {
            result[surfaceId] = panels;
          } else {
            const panelsForSurface = result[surfaceId]!;
            if (panels.filteredPanels.length > panelsForSurface.filteredPanels.length) {
              result[surfaceId] = panels;
            }
          }
        }
      }

      return result;
    },
    [threadOneResult, threadTwoResult, threadThreeResult]
  );

  React.useEffect(() => {
    if (isAnyLoading) {
      return;
    }

    store.surfaces.updatePanelsCache({
      cache: R.pipe(
        Object.entries(workerResult),
        R.map(
          ([surfaceId, { hash, nonFilteredPanels }]) =>
            [surfaceId, { hash, panels: nonFilteredPanels.map(Gis.polygonFeatureToPath) }] as const
        ),
        (x) => Object.fromEntries(x)
      )
    });
  }, [workerResult, isAnyLoading, store]);

  return {
    panels: React.useMemo(() => {
      const result: SurfacePanelsMap = {};
      for (const [surfaceId, { filteredPanels }] of R.toPairs(workerResult)) {
        result[surfaceId] = filteredPanels;
      }
      return result;
    }, [workerResult]),
    isLoading: isAnyLoading
  };
}

const [useSurfacePanels, SurfacePanelsProviderInner] = createContext<{
  surfacePanels: SurfacePanelsMap;
  isLoadingPanels: boolean;
}>();

const SurfacePanelsProvider = observer(function SurfacePanelsProvider({
  children
}: EmptyPropsWithChildren): JSX.Element {
  const { panels, isLoading } = useSurfacePanelsInner();

  return (
    <SurfacePanelsProviderInner value={{ surfacePanels: panels, isLoadingPanels: isLoading }}>
      {children}
    </SurfacePanelsProviderInner>
  );
});

export { useSurfacePanels, SurfacePanelsProvider };
