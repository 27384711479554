import { IInverter } from '@/features/editor/utils/inverters/inverter';
import { action, makeAutoObservable } from 'mobx';
import { nanoid } from 'nanoid';
import { R } from '@/lib/remeda';
import { IEditorContext } from '@/features/editor/utils/editor-context';

export class InvertersStore {
  public inverters: IInverter[] = [];

  public selectedInverterId: string | undefined;

  public constructor(initial?: Pick<IEditorContext, 'inverters' | 'selectedInverterId'>) {
    makeAutoObservable(this, {
      inverterRemoveDeadSurfaces: action
    });
    if (initial) {
      this.inverters = initial.inverters;
      this.selectedInverterId = initial.selectedInverterId;
    }
  }

  public inverterCreate = () => {
    const id = nanoid();
    this.inverters.push({
      id,
      surfaceIds: [],
      quantity: 1
    });
    this.selectedInverterId = id;
  };

  public inverterDelete = (inverterId: string) => {
    const index = this.inverters.findIndex((inverter) => inverter.id === inverterId);
    this.inverters.splice(index, 1);
    if (this.selectedInverterId === inverterId) {
      this.selectedInverterId = R.last(this.inverters)?.id;
    }
  };

  public inverterGetOne = (inverterId: string) => {
    return this.inverters.find((inverter) => inverter.id === inverterId);
  };

  public inverterSelect = (inverterId: string) => {
    this.selectedInverterId = inverterId;
  };

  public inverterUpdate = (inverterId: string, fn: (inverter: IInverter) => void) => {
    const old = this.inverters.find((inverter) => inverter.id === inverterId);
    if (old) {
      fn(old);
    }
  };

  public inverterTypeChange = ({
    inverterId,
    inverterTypeId,
    optimizerTypeId
  }: {
    inverterId: string;
    inverterTypeId: string;
    optimizerTypeId?: string;
  }) => {
    const inverter = this.inverterGetOne(inverterId);
    if (!inverter) {
      return;
    }
    inverter.inverterTypeId = inverterTypeId;

    if (R.isNil(inverter.optimizerTypeId) && !R.isNil(optimizerTypeId)) {
      inverter.optimizerTypeId = optimizerTypeId;
    }
  };

  public inverterRemoveDeadSurfaces = ({ surfaceIds }: { surfaceIds: string[] }) => {
    const ids = new Set(surfaceIds);
    this.inverters.forEach((inverter) => {
      inverter.surfaceIds = inverter.surfaceIds.filter((surfaceId) => ids.has(surfaceId));
    });
  };
}
