import { IRect } from '@/utils/vec';

interface IAllowedDragDirectionsReturn {
  x: boolean;
  y: boolean;
}

interface IAllowedDragDirectionsParams {
  bounds: Pick<IRect, 'width' | 'height'>;
  rect: IRect;
  delta: [number, number];
}

/**
 * Given a rectangle, bounds, and delta, returns the directions
 * in which the rectangle can be dragged while remaining within the bounds.
 * */
export function allowedDragDirections({
  bounds,
  rect,
  delta: [x, y]
}: IAllowedDragDirectionsParams): IAllowedDragDirectionsReturn {
  let xAllowed = true;
  let yAllowed = true;

  if (rect!.left + x > 0) {
    xAllowed = false;
  }

  if (rect!.right + x < bounds.width) {
    xAllowed = false;
  }

  if (rect!.top + y > 0) {
    yAllowed = false;
  }

  if (rect!.bottom + y < bounds.height) {
    yAllowed = false;
  }

  return {
    x: xAllowed,
    y: yAllowed
  };
}
