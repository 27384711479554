/* eslint-disable @typescript-eslint/naming-convention */
import { api } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { EditorApiKeys } from '@/features/editor/api/keys';
import { z } from 'zod';
import { InferQueryOptions, UnwrapPromise } from '@/utils/types';
import { R } from '@/lib/remeda';

const wallBoxesParser = z.array(
  z.object({
    name_wallbox: z.string(),
    sellprice: z.number(),
    info_id: z.number()
  })
);

const batteriesParser = z.array(
  z.object({
    name_battery: z.string(),
    info_id: z.number(),
    sellprice: z.number()
  })
);

const accessoriesParser = z.array(
  z.object({
    info_id: z.number(),
    name_accessory: z.string(),
    sellprice: z.number()
  })
);

const panelsParser = z.array(
  z.object({
    name_panel: z.string(),
    height: z.number(),
    kWh: z.number(),
    info_id: z.number(),
    sellprice: z.number(),
    warrantyEffect: z.number(),
    warrantyTime: z.number(),
    materialWarranty: z.number(),
    width: z.number(),
    image: z.string()
  })
);

const additionsParser = z.array(
  z.object({
    addition_id: z.number(),
    addition_name: z.string(),
    price: z.number(),
    pricelist: z.string(),
    sku: z.string(),
    unit: z.string()
  })
);

const invertersParser = z.array(
  z.object({
    name_inverter: z.string(),
    optimizer: z.string(),
    battery: z.number(),
    kWh: z.number(),
    sellprice: z.number(),
    info_id: z.number(),
    warrantyTime: z.number(),
    optimizer_family_id: z.number()
  })
);

export const optimizerParser = z.object({
  info_id: z.number(),
  name_optimizer: z.string(),
  sellprice: z.number(),
  kWh: z.number(),
  warrantyTime: z.number(),
  family_id: z.number().nullable()
});

const allProductsParser = z.object({
  accessories: accessoriesParser,
  additions: additionsParser,
  batteries: batteriesParser,
  inverters: invertersParser,
  optimizers: z.array(optimizerParser),
  panels: panelsParser,
  wallboxes: wallBoxesParser
});

export interface IGetAllProductsApiRequest {
  calculationId: number;
}

export function mapOptimizerType(optimizer: z.infer<typeof optimizerParser>) {
  return {
    productId: String(optimizer.info_id),
    name: optimizer.name_optimizer,
    price: optimizer.sellprice,
    production: optimizer.kWh,
    warrantyTime: optimizer.warrantyTime,
    familyId: optimizer.family_id
  };
}

async function allProductsApi({ calculationId }: { calculationId: number }, signal?: AbortSignal) {
  const response = await api.get(`/allProducts?calculationID=${calculationId}`, { signal });
  const result = allProductsParser.parse(response.data);

  return {
    ...R.omit(result, ['optimizers', 'inverters']),
    optimizerTypes: result.optimizers.map(mapOptimizerType),
    panelTypes: result.panels.map((panel) => {
      return {
        productId: String(panel.info_id),
        heightMeters: panel.height / 1000,
        widthMeters: panel.width / 1000,
        rowMarginMeters: 0.1,
        columnMarginMeters: 0.3,
        name: panel.name_panel,
        power: panel.kWh,
        price: panel.sellprice,
        warrantyTime: panel.warrantyTime,
        warrantyEffect: panel.warrantyEffect,
        materialWarranty: panel.materialWarranty,
        imageSrc: panel.image ? panel.image : undefined
      };
    }),
    inverterTypes: result.inverters.map((inverter) => ({
      productId: String(inverter.info_id),
      name: inverter.name_inverter,
      price: inverter.sellprice,
      production: inverter.kWh,
      warrantyTime: inverter.warrantyTime,
      isBatteryPrepared: inverter.battery !== 0,
      isOptimizerRequired: inverter.optimizer === '1',
      optimizerFamilyId: inverter.optimizer_family_id,
      // TODO: Where do we get this from?
      openCircuitVoltage: 5
    }))
  };
}

export type IGetAllProductsApiResponse = UnwrapPromise<ReturnType<typeof allProductsApi>>;

export function useGetAllProducts(calculationId: number, options?: InferQueryOptions<typeof allProductsApi>) {
  return useQuery(
    EditorApiKeys.accessoriesProducts({ calculationId }),
    ({ signal }) => allProductsApi({ calculationId }, signal),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      ...options
    }
  );
}
