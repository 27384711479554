const MOTION = {
  exit: {
    productive: 'cubic-bezier(0.2, 0, 1, 0.9)',
    expressive: 'cubic-bezier(0.4, 0.14, 1, 1)'
  },
  enter: {
    productive: 'cubic-bezier(0, 0, 0.38, 0.9)',
    expressive: 'cubic-bezier(0, 0, 0.3, 1)'
  },
  standard: {
    productive: 'cubic-bezier(0.2, 0, 0.38, 0.9)',
    expressive: 'cubic-bezier(0.4, 0.14, 0.3, 1)'
  }
} as const;

type MotionType = keyof typeof MOTION;
type MotionSpeed = 'productive' | 'expressive';

function motion(type: MotionType, speed: MotionSpeed): string {
  return MOTION[type][speed];
}

export { motion };
