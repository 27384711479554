/** @jsxImportSource theme-ui */
import * as RadixHoverCard from '@radix-ui/react-hover-card';
import React from 'react';
import { keyframes } from '@emotion/react';

const ANIMATIONS = {
  scaleIn: keyframes({
    from: {
      opacity: 0,
      transform: 'scale(0)'
    },
    to: {
      opacity: 1,
      transform: 'scale(1)'
    }
  })
};

interface IHoverCardProps {
  children: React.ReactNode;
  content: React.ReactNode;
}

export function HoverCard({ children, content }: IHoverCardProps) {
  return (
    <RadixHoverCard.Root>
      <RadixHoverCard.Trigger asChild>{children}</RadixHoverCard.Trigger>

      <RadixHoverCard.Portal>
        <RadixHoverCard.Content
          sx={{
            animation: `${ANIMATIONS.scaleIn} 0.1s ease-out`,

            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(12px)',
            borderRadius: 2,
            boxShadow: 'overlay',
            maxWidth: 320,
            p: 4
          }}
        >
          <RadixHoverCard.Arrow
            sx={{
              fill: 'white'
            }}
          />

          {content}
        </RadixHoverCard.Content>
      </RadixHoverCard.Portal>
    </RadixHoverCard.Root>
  );
}
