import React from 'react';
import { IPoint } from '@/utils/gis/types';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { MapPolygon } from '@/features/google-map/components/shapes/map-polygon';
import { themeColors } from '@/lib/theme-ui/colors';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import { EditorStore, useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import { useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { flattenedHashedPanels, getSelectedPanelIds } from './render-panels-on-surfaces';

function selectionStart(
  event: google.maps.MapMouseEvent,
  $dragStart: React.MutableRefObject<IPoint | undefined>,
  store: EditorStore
): void {
  if (event.latLng === null) {
    return;
  }
  store.state.setState('selecting.selectionInProgress');
  $dragStart.current = [event.latLng.lng(), event.latLng.lat()];
}

export function useSelectionMapEvents() {
  const $dragStart = React.useRef<IPoint>();
  const { map } = useGoogleMapContext();
  const store = useEditorStore();
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const isSelecting = store.state.isSelecting;
  const selectedSurfaceId = store.surfaces.selectedSurfaceId;
  const { surfacePanels } = useSurfacePanels();
  const panels = React.useMemo(() => flattenedHashedPanels(surfacePanels), [surfacePanels]);

  React.useEffect(() => {
    if (!isSelecting || !selectedSurfaceId) {
      return;
    }
    const selectedPanelIds = getSelectedPanelIds(panels, selectedSurfaceId, store);

    const listenersToRemove: google.maps.MapsEventListener[] = [];
    listenersToRemove.push(
      map.addListener('click', () => {
        store.selection.deselectAll();
      }),
      map.addListener('mousedown', (event: google.maps.MapMouseEvent) => {
        if ((event.domEvent as MouseEvent).button !== 1) {
          selectionStart(event, $dragStart, store);
        }
      }),
      map.addListener('mousemove', (event: google.maps.MapMouseEvent) => {
        if (event.latLng === null || $dragStart.current === undefined) {
          return;
        }
        setSelectedIds(Array.from(selectedPanelIds));
        const dragStart = $dragStart.current;
        const dragEnd: IPoint = [event.latLng.lng(), event.latLng.lat()];

        store.selection.setRectangle({
          topLeft: dragStart,
          bottomRight: dragEnd
        });
        store.selection.setVisible(true);
      }),

      map.addListener('mouseup', (event: google.maps.MapMouseEvent) => {
        if (event.latLng === null) {
          return;
        }
        store.state.setState('selecting');
        store.selection.select(selectedIds);
        store.selection.path = undefined;
        setSelectedIds([]);
        $dragStart.current = undefined;
        store.selection.setVisible(false);
      })
    );

    return () => {
      listenersToRemove.forEach((listener) => listener.remove());
    };
  }, [isSelecting, map, panels, selectedIds, selectedSurfaceId, store]);

  React.useEffect(() => {
    if (!isSelecting) {
      store.selection.deselectAll();
    }
  }, [isSelecting, store.selection]);
}

export const RenderSelectionBox = observer(function RenderSelectionBox() {
  const store = useEditorStore();
  const selectionRectanglePath = store.selection.path;
  const selectionRectangleIsVisible = store.selection.isVisible;

  if (!selectionRectangleIsVisible || selectionRectanglePath === undefined) {
    return null;
  }

  return (
    <MapPolygon
      draggable={false}
      clickable={false}
      path={selectionRectanglePath}
      fillColor={themeColors.panelHover}
      strokeColor={themeColors.panelHover}
      zIndex={GOOGLE_MAP_Z_INDICES.SELECT_SHAPE}
    />
  );
});
