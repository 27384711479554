/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui';
import { RadioGroup } from '@/components/radio-group/radio-group';
import React from 'react';
import { SurfaceOrientation, surfaceOrientationParser } from '@/features/editor/utils/surface/surface';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface ISurfacePanelOrientationInputProps {
  surfaceId: string;
}

export const SurfacePanelOrientationInput = observer(function SurfacePanelOrientationInput({
  surfaceId
}: ISurfacePanelOrientationInputProps): JSX.Element {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (!surface) {
    return <React.Fragment />;
  }

  return (
    <RadioGroup
      aria-label="Surface orientation"
      value={surface.orientation}
      onChange={(value) => {
        store.surfaces.surfaceUpdate(surfaceId, (surface) => {
          surface.orientation = value as SurfaceOrientation;
        });
      }}
    >
      <Flex sx={{ gap: 8 }}>
        {surfaceOrientationParser.options.map((option) => (
          <RadioGroup.Radio key={option} value={option}>
            <span sx={{ textTransform: 'capitalize' }}>{option}</span>
          </RadioGroup.Radio>
        ))}
      </Flex>
    </RadioGroup>
  );
});
