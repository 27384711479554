/** @jsxImportSource theme-ui */
import { ActionMenu } from '@/components/action-menu/action-menu';
import { MdDelete, MdResetTv } from 'react-icons/md';
import React from 'react';
import { Icons } from '@/assets';

interface ISurfaceActionMenuProps {
  onDelete: () => void;
  onReset: () => void;
}

const ActionKey = {
  remove: 'remove',
  reset: 'reset'
};

export function SurfaceActionMenu({ onDelete, onReset }: ISurfaceActionMenuProps): JSX.Element {
  function onAction(key: React.Key): void {
    if (key === ActionKey.remove) {
      onDelete();
    } else if (key === ActionKey.reset) {
      onReset();
    }
  }

  return (
    <ActionMenu direction="left" label={<Icons.Settings />} onAction={onAction}>
      <ActionMenu.Item key={ActionKey.reset} textValue="Reset">
        <MdResetTv />
        Reset
      </ActionMenu.Item>

      <ActionMenu.Item key={ActionKey.remove} textValue="Delete">
        <MdDelete />
        Delete
      </ActionMenu.Item>
    </ActionMenu>
  );
}
