import { R } from '@/lib/remeda';
import { GoogleMapCoords } from '@/features/google-map/utils/google-map-coords';
import { GoogleMapConversion } from '@/features/google-map/utils/google-map-conversion';
import { distanceVincenty } from '@/utils/gis/distance-vincenty';
import { formatLengthMarker } from '@/features/editor/utils/format-length-marker';

class PaperMap {
  /**
   * 288 pixels in size ensures that the tiles are 1m wide at zoom level 25.
   * Since the distance increases by a power of two at each zoom level, the
   * size of the tiles from here is 2m, 4m, 8m, etc.
   * */
  public tileSize: google.maps.Size = new google.maps.Size(288, 288);
  public maxZoom: number = 19;
  public name: string = 'Tile #s';
  public alt: string = 'Paper map';

  private _map: google.maps.Map;

  private _zoomTileDistanceCache: Map<number, number> = new Map();

  public constructor({
    alt,
    name,
    maxZoom,
    map
  }: google.maps.ImageMapTypeOptions & {
    map: google.maps.Map;
  }) {
    this.alt = alt ?? 'Paper map';
    this.name = name ?? 'Paper map';
    this.maxZoom = maxZoom ?? 19;
    this._map = map;
  }

  // eslint-disable-next-line @rushstack/no-new-null
  public getTile(coord: google.maps.Point, zoom: number, ownerDocument: Document): HTMLElement | null {
    const div = ownerDocument.createElement('div');
    const distanceMeters = this._getTileDistanceMeters(coord, zoom);

    div.innerHTML = formatLengthMarker(distanceMeters, distanceMeters < 1 ? 2 : 0);
    div.style.padding = '4px';
    div.style.color = '#475825';
    div.style.fontFamily = 'Inter';
    div.style.width = this.tileSize.width + 'px';
    div.style.height = this.tileSize.height + 'px';
    div.style.fontSize = '10';
    div.style.borderStyle = 'solid';
    div.style.borderWidth = '1px';
    div.style.borderColor = '#475825';
    div.style.backgroundColor = '#d9d0ba';

    return div;
  }

  private _getTileDistanceMeters(coord: google.maps.Point, zoom: number): number {
    const cachedTileDistance = this._zoomTileDistanceCache.get(zoom);
    if (R.isDefined(cachedTileDistance)) {
      return cachedTileDistance;
    }

    const projection = this._map.getProjection();
    if (R.isNil(projection)) {
      return 0;
    }

    const topLeft = projection.fromPointToLatLng(
      GoogleMapCoords.tileCoordToWorldCoord(coord, zoom, this.tileSize)
    );
    if (R.isNil(topLeft)) {
      return 0;
    }
    const topRight = projection.fromPointToLatLng(
      GoogleMapCoords.tileCoordToWorldCoord(new google.maps.Point(coord.x + 1, coord.y), zoom, this.tileSize)
    );
    if (R.isNil(topRight)) {
      return 0;
    }

    const result = distanceVincenty(
      GoogleMapConversion.latLngToPoint(topLeft),
      GoogleMapConversion.latLngToPoint(topRight)
    );
    this._zoomTileDistanceCache.set(zoom, result);
    return result;
  }
}

export { PaperMap };
