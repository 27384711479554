import { environment } from '@/utils/env';
import Swal from 'sweetalert2';

interface IHttpResponse<Data> extends Response {
  data: Data;
}

class HttpError extends Error {
  public statusCode: number;

  public constructor(message: string, statusCode: number) {
    super(message);
    this.name = 'HttpError';
    this.statusCode = statusCode;
  }
}

async function mapResponse(response: Response) {
  return {
    ...response,
    data: await response.json()
  };
}

export interface IApiOptions extends RequestInit {}

async function innerFetch<ResponseData>(
  url: string,
  options?: IApiOptions
): Promise<IHttpResponse<ResponseData>> {
  return fetch([environment.REACT_APP_SOLEL_API_URL, url].join(''), {
    credentials: 'include',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options?.headers ?? {})
    }
  })
    .then(async (response) => {
      if (response.status === 200) {
        return mapResponse(response);
      }

      if (response.status === 401) {
        // TODO define conventional way of error responses to make this popup reusable
        await Swal.fire({
          title: 'Session expired',
          text: 'You session has expired please log in again',
          icon: 'error',
          confirmButtonColor: '#13AB62',
          confirmButtonText: 'Back to log in'
        }).then(() => {
          window.location.replace(`${environment.REACT_APP_SOLEL_HOME_PAGE_URL}/login`);
        });
      }

      const errorMessage = await response.text();
      return Promise.reject(new HttpError(errorMessage, response.status));
    })
    .catch(async (error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

const api = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async post<ResponseData = any>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    options?: IApiOptions
  ): Promise<IHttpResponse<ResponseData>> {
    return innerFetch<ResponseData>(url, {
      ...options,
      body: JSON.stringify(data),
      method: 'POST'
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get<Data = any>(url: string, options?: IApiOptions): Promise<IHttpResponse<Data>> {
    return innerFetch<Data>(url, options);
  }
};

export { api, HttpError };
