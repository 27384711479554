import React from 'react';
import { Text, Image, Link } from '@react-pdf/renderer';
import { EditorResultsCardPrintable } from '@/features/editor-results/components/editor-results-card/editor-results-card-printable';
import { IEditorResultsGuaranteeCardProps } from '@/features/editor-results/components/editor-results-guarantee-card/editor-results-guarantee-card';
import { FormattedMessage } from 'react-intl';
import { themeColors } from '@/lib/theme-ui/colors';
import manufacturerGuarantee from '@/assets/images/editor-results-manufacturer-guarantee.png';

export function EditorResultsManufacturerGuaranteePrintable({
  warrantyEffect,
  warrantyTime
}: IEditorResultsGuaranteeCardProps): JSX.Element {
  return (
    <EditorResultsCardPrintable.Wrapper style={{ flex: 1 }}>
      <EditorResultsCardPrintable.Title>
        <Image style={{ width: 41, height: 41, marginRight: 5 }} src={manufacturerGuarantee} />
        <Text style={{ flex: 1 }}>
          <FormattedMessage
            defaultMessage=" Tillverkaren av panelen garanterar att panelen ger minst"
            id="N97zze"
          />
          <Text style={{ color: themeColors.primary }}>
            {' '}
            {warrantyEffect} of the power after {warrantyTime} years.
          </Text>
        </Text>
      </EditorResultsCardPrintable.Title>

      <EditorResultsCardPrintable.Content>
        <Text>
          <FormattedMessage defaultMessage="Produktion under garantitiden är beräknad" id="ptAdUZ" />
        </Text>
        <Text style={{ marginBottom: 15 }}>
          <FormattedMessage
            defaultMessage="enligt den försämring som tillåts under garantiåtagandet."
            id="SJVgVO"
          />
        </Text>

        <Text>
          <FormattedMessage
            defaultMessage="I verkligheten borde effekten inte förändras så snabbt under"
            id="81sP0d"
          />
        </Text>
        <Text>
          <FormattedMessage
            defaultMessage="Svenska förhållanden kommer givetvis panelen att ta fram"
            id="RjXkkb"
          />
        </Text>
        <Text style={{ marginBottom: 20 }}>
          <FormattedMessage
            defaultMessage="el även efter garantitiden, men detta ingår inte i beräkningen."
            id="G2kz7w"
          />
        </Text>

        <Text style={{ paddingTop: 20, borderTop: 4, borderColor: themeColors.menuHover, lineHeight: 1.6 }}>
          <FormattedMessage
            defaultMessage="Beräkningen av produktionen görs enligt en modell (PVGIS) som utarbetats av EU-kommissionens
            gemensamma forskningscenter för energi och transporter. Den är baserad på isoleringsdata som samlats in under
            2010-2012."
            id="r5Y6fe"
          />
        </Text>

        <Text style={{ marginTop: 130, marginBottom: 10 }}>
          <FormattedMessage defaultMessage="Läs mer:" id="5JQUDt" />{' '}
          <Link src="http://re.jrc.ec.europa.eu/pvgis/">
            <Text style={{ color: themeColors.primary }}>http://re.jrc.ec.europa.eu/pvgis/</Text>
          </Link>
        </Text>
      </EditorResultsCardPrintable.Content>
    </EditorResultsCardPrintable.Wrapper>
  );
}
