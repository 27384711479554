/** @jsxImportSource theme-ui */
import { Box } from '@/components/box';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import { ButtonPrimary, ButtonSecondary } from '@/components/button/button';
import { EsdecWeatherZonesCard } from './esdec-weather-zones-card';
import { EsdecOrderlistCard } from './esdec-orderlist-card';
import { EsdecSummaryCard, IEsdecSummaryCard } from './esdec-summary-card';
import { EsdecRoofSettingsCard } from './esdec-roof-settings-card';
import { EsdecConfigurationCard } from './esdec-configuration-card';
import { FormattedMessage } from 'react-intl';
import { EsdecMountingSystemType } from './esdec-mounting-system-type';
import React, { useEffect, useState } from 'react';
import { SelectField } from '@/components/select-field';
import { Icons } from '@/assets';
import { SurfaceEsdec } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { esdecCalculationRequest } from '@/features/esdec/utils/esdec-calculation-request';
import { useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { IEnrichedSurface } from '@/features/editor/utils/surface/surface';
import { GeneratedPanel } from '@/features/editor/utils/panel/panel';
import { ProjectInformationStore } from '@/features/editor/stores/mobx/project-information-store';
import { useEditorCalculationId } from '@/features/editor/utils/use-editor-calculation-id';
import { environment } from '@/utils/env';
import { api } from '@/utils/api';
import Swal from 'sweetalert2';
import { SurfacesStore } from '@/features/editor/stores/mobx/surfaces-store';

async function manageAction(projectId: number, roofId: number, status: 'open' | 'finalize') {
  return await api.post(
    `/esdec/manage-project-actions?project_id=${projectId}&roof_id=${roofId}&action=${status}`,
    undefined
  );
}

async function initBuildEsdecProject(
  surface: IEnrichedSurface,
  panels: GeneratedPanel[],
  esdecInfo: SurfaceEsdec | undefined,
  projectInfo: ProjectInformationStore,
  calculationId: number
) {
  const field = esdecCalculationRequest({
    surface: surface,
    panels: panels,
    esdecInfo: esdecInfo,
    projectInfo: projectInfo
  });
  return await api.post(`/esdec/initial-build?calculationId=${calculationId}`, field);
}

async function updateEsdecProject(
  surface: IEnrichedSurface,
  panels: GeneratedPanel[],
  esdecInfo: SurfaceEsdec | undefined,
  projectInfo: ProjectInformationStore,
  esdecProjectId: number,
  esdecRoofId: number
) {
  const field = esdecCalculationRequest({
    surface: surface,
    panels: panels,
    esdecInfo: esdecInfo,
    projectInfo: projectInfo
  });
  return await api.post(`/esdec/update-project?project_id=${esdecProjectId}&roof_id=${esdecRoofId}`, field);
}

async function getOrderListSummary(projectId: number, roofId: number) {
  return await api.get(`/esdec/order-list-and-summary-results?projectId=${projectId}&roofId=${roofId}`);
}

export function findMyCurrentSurface(surface: IEnrichedSurface, surfaces: SurfacesStore) {
  return surface.id === surfaces.selectedEsdecSurfaceId;
}

const initialEsdecSummaryCard: IEsdecSummaryCard = {
  windPressure: 0,
  regularSnowLoad: 0,
  panelCount: 0,
  totalPower: 0,
  averageRoofLoad: 0
};

interface IOrderList {
  pricepermodule: number;
  priceperwattpower: number;
  totalprice: number;
}

const initialOrderList: IOrderList = {
  pricepermodule: 0,
  priceperwattpower: 0,
  totalprice: 0
};

export const EsdecSurfaceContent = observer(function EsdecSurfaceContent({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const { surfaces } = store;
  const { surfacePanels } = useSurfacePanels();
  const surfacesar = useSurfaces();
  const { surfacesEsdec, projectInformation } = store;
  const esdecTablId = surfaces.selectedEsdecSurfaceId;
  const { calculationId } = useEditorCalculationId();
  const [esdecSummaryCard, setEsdecSummmaryCard] = useState(initialEsdecSummaryCard);
  const [orderList, setOrderList] = useState(initialOrderList);
  const [projectId, setProjectId] = useState(0);
  const [roofId, setRoofId] = useState(0);
  const [disabled, setIsDisabled] = useState(true);

  const currentSurface = surfacesar.find((surface) => findMyCurrentSurface(surface, surfaces));

  useEffect(() => {
    if (currentSurface?.esdecProjectId && currentSurface.esdecRoofId) {
      setProjectId(currentSurface.esdecProjectId);
      setRoofId(currentSurface.esdecRoofId);

      if (esdecTablId) {
        const surfaceEsdec = surfacesEsdec.surfaces[esdecTablId];
        if (surfaceEsdec) surfaceEsdec.isRoofFinalized = true;
        setIsDisabled(false);
      }
    }
  }, [currentSurface?.esdecProjectId, currentSurface?.esdecRoofId, esdecTablId, surfacesEsdec.surfaces]);

  if (!esdecTablId) {
    return null;
  }

  const surfaceEsdec = surfacesEsdec.surfaces[esdecTablId];

  function documentOpen(url: string | undefined, label: string | undefined) {
    if (url && label) window.open(url, label);
  }

  function onUpdateFinalizedRoof(isEdit: boolean) {
    if (surfaceEsdec) {
      if (!esdecTablId || !currentSurface) {
        return null;
      }
      const thisSurfacePanels = surfacePanels[esdecTablId] ?? [];
      const thisEsdecInfo = surfacesEsdec.surfaces[esdecTablId];

      if (isEdit) {
        return (surfaceEsdec.isRoofFinalized = false);
      }

      if (!surfaceEsdec.isRoofFinalized && !currentSurface.esdecProjectId && !projectId) {
        initBuildEsdecProject(
          currentSurface,
          thisSurfacePanels,
          thisEsdecInfo,
          projectInformation,
          calculationId
        )
          .then((r) => {
            if (r.data.error_code) {
              surfaceEsdec.isRoofFinalized = false;
              return Swal.fire({
                title: r.data.title,
                text: r.data.msg,
                icon: r.data.type,
                confirmButtonColor: '#13AB62',
                confirmButtonText: 'continue'
              });
            }
            if (r.data.esdec_project_id && r.data.esdec_roof_id) {
              const projectId: number = r.data.esdec_project_id;
              const roofId: number = r.data.esdec_roof_id;
              setProjectId(projectId);
              setRoofId(roofId);
              getOrderListSummary(projectId, roofId)
                .then((r) => {
                  setOrderList(r.data.order_list);
                  setEsdecSummmaryCard(r.data.summary_results);
                  setIsDisabled(false);
                  return Swal.fire({
                    title: 'Created',
                    text: 'ESDEC project successfully created',
                    icon: 'success',
                    confirmButtonColor: 'green',
                    confirmButtonText: 'continue'
                  });
                })
                .then((r) => console.log(r))
                .catch((e) =>
                  Swal.fire({
                    title: e.data.title,
                    text: e.data.msg,
                    icon: e.data.type,
                    confirmButtonColor: '#13AB62',
                    confirmButtonText: 'continue'
                  })
                );
            }
          })
          .then((r) => console.log(r))
          .catch((e) =>
            Swal.fire({
              title: e.data.title,
              text: e.data.msg,
              icon: e.data.type,
              confirmButtonColor: '#13AB62',
              confirmButtonText: 'continue'
            })
          );
      } else {
        manageAction(projectId, roofId, 'open')
          .then(() => {
            updateEsdecProject(
              currentSurface,
              thisSurfacePanels,
              thisEsdecInfo,
              projectInformation,
              projectId,
              roofId
            )
              .then((r) => {
                if (r.data.error_code) {
                  return Swal.fire({
                    title: r.data.title,
                    text: r.data.msg,
                    icon: r.data.type,
                    confirmButtonColor: '#13AB62',
                    confirmButtonText: 'continue'
                  });
                }

                if (r.data.esdec_project_id && r.data.esdec_roof_id) {
                  setProjectId(r.data.esdec_project_id);
                  setRoofId(r.data.esdec_roof_id);
                  getOrderListSummary(r.data.esdec_project_id, r.data.esdec_roof_id)
                    .then((r) => {
                      setOrderList(r.data.order_list);
                      setEsdecSummmaryCard(r.data.summary_results);
                      setIsDisabled(false);
                      return Swal.fire({
                        title: 'Update',
                        text: 'Project successfully updated',
                        icon: 'success',
                        confirmButtonColor: 'green',
                        confirmButtonText: 'continue'
                      });
                    })
                    .then((r) => console.log(r))
                    .catch((e) =>
                      Swal.fire({
                        title: e.data.title,
                        text: e.data.msg,
                        icon: e.data.type,
                        confirmButtonColor: '#13AB62',
                        confirmButtonText: 'continue'
                      })
                    );
                }
              })
              .then((r) => console.log(r))
              .catch((e) =>
                Swal.fire({
                  title: e.data.title,
                  text: e.data.msg,
                  icon: e.data.type,
                  confirmButtonColor: '#13AB62',
                  confirmButtonText: 'continue'
                })
              );
          })
          .then((r) => console.log(r))
          .catch((e) =>
            Swal.fire({
              title: e.data.title,
              text: e.data.msg,
              icon: e.data.type,
              confirmButtonColor: '#13AB62',
              confirmButtonText: 'continue'
            })
          );
      }

      surfaceEsdec.updateRoofFinalized(!surfaceEsdec.isRoofFinalized);
      if (!surfaceEsdec.isRoofFinalized) {
        const finalizedSurfaceIds = surfaces.selectedOverviewSurfaceIds.filter(
          (selectedSurfaceId) => selectedSurfaceId !== surfaceId
        );
        surfaces.updateSelectedOverviewSurfaceIds(finalizedSurfaceIds);
      }
    }
  }

  const downloadOptionsList = [
    {
      value: `${environment.REACT_APP_SOLEL_API_URL}/esdec/download-orderlist?projectId=${
        projectId || currentSurface?.esdecProjectId
      }&roofId=${roofId || currentSurface?.esdecRoofId}&type=pdf`,
      label: 'ORDERLIST AS PDF'
    },
    {
      value: `${environment.REACT_APP_SOLEL_API_URL}/esdec/download-orderlist?projectId=${
        projectId || currentSurface?.esdecProjectId
      }&roofId=${roofId || currentSurface?.esdecRoofId}&type=csv`,
      label: 'ORDERLIST AS CSV'
    },
    {
      value: `${environment.REACT_APP_SOLEL_API_URL}/esdec/download-project-plan?projectId=${
        projectId || currentSurface?.esdecProjectId
      }&roofId=${roofId || currentSurface?.esdecRoofId}`,
      label: 'PROJECT PLAN'
    },
    {
      value: `${environment.REACT_APP_SOLEL_API_URL}/esdec/download-all-documents?projectId=${
        projectId || currentSurface?.esdecProjectId
      }&roofId=${roofId || currentSurface?.esdecRoofId}`,
      label: 'ALL DOCUMENTS IN A .ZIP'
    }
  ];

  return (
    <React.Fragment>
      {surfaceEsdec?.preferredProduct !== undefined ? (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            rowGap: 7,
            columnGap: 5
          }}
        >
          <EsdecWeatherZonesCard surfaceId={surfaceId} />

          <EsdecSummaryCard
            windPressure={
              currentSurface?.esdecAttributes?.summary_results?.windPressure ?? esdecSummaryCard.windPressure
            }
            regularSnowLoad={
              currentSurface?.esdecAttributes?.summary_results?.regularSnowLoad ??
              esdecSummaryCard.regularSnowLoad
            }
            panelCount={
              currentSurface?.esdecAttributes?.summary_results?.panelCount ?? esdecSummaryCard.panelCount
            }
            totalPower={
              currentSurface?.esdecAttributes?.summary_results?.totalPower ?? esdecSummaryCard.totalPower
            }
            averageRoofLoad={
              currentSurface?.esdecAttributes?.summary_results?.averageRoofLoad ??
              esdecSummaryCard.averageRoofLoad
            }
          />

          <EsdecRoofSettingsCard surfaceId={surfaceId} />
          <EsdecConfigurationCard surfaceId={surfaceId} />
          <Box
            sx={{
              gridColumn: '3 / 4',
              gridRow: '1 / 3'
            }}
          >
            <EsdecOrderlistCard
              subtotal={0}
              discount={0}
              totalPrice={currentSurface?.esdecAttributes?.order_list?.totalprice ?? orderList.totalprice}
              netCostPerModule={
                currentSurface?.esdecAttributes?.order_list?.pricepermodule ?? orderList.pricepermodule
              }
              netCostPerWp={
                currentSurface?.esdecAttributes?.order_list?.priceperwattpower ?? orderList.priceperwattpower
              }
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}
              >
                {surfaceEsdec.isRoofFinalized && (currentSurface?.esdecProjectId || projectId) ? (
                  <React.Fragment>
                    <ButtonSecondary onClick={() => onUpdateFinalizedRoof(true)} sx={{ color: '#9EA5B8' }}>
                      <Icons.EditInfo />
                      <FormattedMessage defaultMessage="Edit Project" id="gdCjPd" />
                    </ButtonSecondary>
                    <SelectField
                      isDisabled={disabled}
                      placeholder="Download Files"
                      label=""
                      name="download-summary"
                      options={downloadOptionsList}
                      onChange={(newValue) => {
                        documentOpen(newValue?.value, newValue?.label);
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <ButtonPrimary
                    isDisabled={!currentSurface?.panelAttributes.length}
                    onClick={() => onUpdateFinalizedRoof(false)}
                  >
                    <FormattedMessage defaultMessage="Finalize Project" id="UNbkDn" />
                  </ButtonPrimary>
                )}
              </Box>
            </EsdecOrderlistCard>
          </Box>
        </Box>
      ) : (
        <EsdecMountingSystemType surfaceId={surfaceId} />
      )}
    </React.Fragment>
  );
});
