import { makeAutoObservable } from 'mobx';

type IEditorState =
  | 'creatingSurface'
  | 'creatingCustomSurface90'
  | 'addingObstacle'
  | 'addingPanel'
  | 'dragging'
  | 'selecting'
  | 'selecting.selectionInProgress'
  | 'measuring'
  | 'addingOptimizer';

export class StateStore {
  public state: IEditorState = 'dragging';

  public constructor() {
    makeAutoObservable(this);
  }

  public setState(state: IEditorState) {
    this.state = state;
  }

  public get isSelecting() {
    return this.state === 'selecting' || this.state === 'selecting.selectionInProgress';
  }

  public get isCreatingSurface() {
    return this.state === 'creatingSurface';
  }

  public get isCreatingCustomSurface90() {
    return this.state === 'creatingCustomSurface90';
  }

  public get isAddingObstacle() {
    return this.state === 'addingObstacle';
  }

  public get isAddingPanel() {
    return this.state === 'addingPanel';
  }

  public get isDragging() {
    return this.state === 'dragging';
  }

  public get isMeasuring() {
    return this.state === 'measuring';
  }

  public get isAddingOptimizer() {
    return this.state === 'addingOptimizer';
  }

  public get isSelectionInProgress() {
    return this.state === 'selecting.selectionInProgress';
  }
}
