import { api } from '@/utils/api';

export interface IAutocompleteAddressDataApiRequest {
  address: string;
}

export async function autocompleteAddressDataApi(request: IAutocompleteAddressDataApiRequest) {
  const response = await api.get(`/addressAutocompleteData?input=${request.address}`);
  const result = response.data;

  if (Array.isArray(result)) {
    return result;
  }

  return [result];
}
