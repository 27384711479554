import { makeAutoObservable } from 'mobx';
import { IPoint } from '@/utils/gis/types';
import {
  IEditorProjectInformation,
  IProjectElectricityPrices,
  IProjectFinancing,
  ProjectClientType,
  ProjectElectricityConsumption
} from '@/features/project-information/utils/project-information';

export class ProjectInformationStore {
  public clientFullName?: string;
  public clientPhone?: string;
  public clientCompanyName?: string;
  public email?: string;
  public address?: string;
  public zipCode?: string;
  public coordinates?: IPoint;
  public clientType?: ProjectClientType;
  public financialSupport?: string;
  public electricityConsumption?: ProjectElectricityConsumption;
  public electricityPrices?: IProjectElectricityPrices;
  public financing?: IProjectFinancing;

  public constructor({
    initial
  }: {
    initial?: Pick<
      IEditorProjectInformation,
      | 'clientFullName'
      | 'clientPhone'
      | 'clientCompanyName'
      | 'email'
      | 'address'
      | 'zipCode'
      | 'coordinates'
      | 'clientType'
      | 'financialSupport'
      | 'electricityConsumption'
      | 'electricityPrices'
      | 'financing'
    >;
  }) {
    makeAutoObservable(this);

    if (initial) {
      this.clientFullName = initial.clientFullName;
      this.clientPhone = initial.clientPhone;
      this.clientCompanyName = initial.clientCompanyName;
      this.email = initial.email;
      this.address = initial.address;
      this.zipCode = initial.zipCode;
      this.coordinates = initial.coordinates;
      this.clientType = initial.clientType;
      this.financialSupport = initial.financialSupport;
      this.electricityConsumption = initial.electricityConsumption;
      this.electricityPrices = initial.electricityPrices;
      this.financing = initial.financing;
    }
  }

  public update = (fn: (projectInformation: IEditorProjectInformation) => void) => {
    fn(this);
  };
}
