import { api } from '@/utils/api';
import { R } from '@/lib/remeda';
import { InferMutationOptions } from '@/utils/types';
import { useMutation } from '@tanstack/react-query';

interface IInverterDeleteApiRequest {
  guid: string;
  calculationId: number;
}

async function inverterDeleteApi(request: IInverterDeleteApiRequest) {
  return api.post('/deleteInverter', {
    ...R.omit(request, ['calculationId']),
    calculationID: request.calculationId
  });
}

export function useInverterDelete(options?: InferMutationOptions<typeof inverterDeleteApi>) {
  return useMutation(inverterDeleteApi, options);
}
