import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { ESDEC_ROOF_MATERIAL_BY_TYPE } from '../esdec-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { RoofDTOMaterialEnum } from '@solel/esdec';

export const EsdecRoofMaterialInput = observer(function EsdecRoofMaterialInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);
  const intl = useIntl();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec || !surface || surface.placement === 'ground') {
    return <React.Fragment />;
  }

  const roofMaterialOptions = ESDEC_ROOF_MATERIAL_BY_TYPE[surface.placement].map((roofMaterial) => ({
    label: roofMaterial,
    value: roofMaterial
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Material',
        id: 'fMigBw'
      })}
      placeholder={<FormattedMessage defaultMessage="Select Material" id="YXhKDY" />}
      name="material"
      options={roofMaterialOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateMaterial(value.value as RoofDTOMaterialEnum);
        }
      }}
      value={roofMaterialOptions.find(
        (roofMaterialOption) => roofMaterialOption.value === surfaceEsdec.material
      )}
    />
  );
});
