import { IEnrichedObstacle } from '@/features/editor/utils/obstacle';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import * as R from 'remeda';
import booleanWithin from '@turf/boolean-within';
import * as turf from '@turf/helpers';
import { getSideLengths, getSideLengthsWithTilt } from '@/features/editor/utils/get-side-lengths';
import { surfaceRidgeSide } from '@/features/google-map/utils/surface-ridge-side';
import { extendLine } from '@/utils/turf/extend-line';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

export function useObstacles(): IEnrichedObstacle[] {
  const store = useEditorStore();
  const obstacles = store.obstacles.obstacles;
  const surfaces = useSurfaces();

  return R.map(obstacles, (obstacle) => {
    const containerSurface = surfaces.find((surface) =>
      booleanWithin(turf.polygon([obstacle.path]), turf.polygon([surface.path]))
    );

    if (containerSurface === undefined) {
      return {
        ...obstacle,
        sides: getSideLengths(obstacle.path)
      };
    }

    const ridge = surfaceRidgeSide({
      ridge: containerSurface.ridge,
      sides: containerSurface.sides,
      id: containerSurface.id
    });

    return {
      ...obstacle,
      sides: ridge
        ? getSideLengthsWithTilt(obstacle.path, extendLine(ridge), containerSurface.tiltAngle)
        : getSideLengths(obstacle.path)
    };
  });
}
