import { Icons } from '@/assets';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { theme } from '@/lib/theme-ui';
import { FormattedMessage } from 'react-intl';
import { Box, Text } from 'theme-ui';
import { EsdecSurfaceCardSection } from './esdec-surface-card-section';
import { EsdecTableRow } from './esdec-table-row';

export interface IEsdecOrderlistCard {
  subtotal: number;
  discount: number;
  totalPrice: number;
  netCostPerModule: number;
  netCostPerWp: number;
  children: React.ReactNode;
}

export function EsdecOrderlistCard({
  subtotal,
  discount,
  totalPrice,
  netCostPerModule,
  netCostPerWp,
  children
}: IEsdecOrderlistCard): JSX.Element {
  const formatCurrency = useFormatCurrency();
  return (
    <EsdecSurfaceCardSection
      title={<FormattedMessage defaultMessage="Your orderlist" id="k7hopF" />}
      icon={<Icons.ShoppingCart />}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Box sx={{ color: 'menuText', fontSize: 'sm', display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Text sx={{ fontWeight: 500 }}>
            <FormattedMessage defaultMessage="Could not calculate the materials because:" id="SnKgWw" />
          </Text>
          <Text sx={{ fontWeight: 400 }}>
            <FormattedMessage
              defaultMessage="The maximum load the system can withstand is exceeded because of the amount of snow on the ground.
            (5005)."
              id="vQccfo"
            />
          </Text>
        </Box>

        <Box sx={{ mx: -theme.space.cardPaddingX, mb: 7 }}>
          <EsdecTableRow
            label={<FormattedMessage defaultMessage="Subtotal" id="L8seEc" />}
            value={formatCurrency(subtotal)}
          />
          <EsdecTableRow
            label={<FormattedMessage defaultMessage="Discount" id="28C0Aj" />}
            value={`${discount}%`}
          />
          <EsdecTableRow
            label={<FormattedMessage defaultMessage="Total price" id="4VivsY" />}
            value={formatCurrency(totalPrice)}
          />
          <EsdecTableRow
            label={<FormattedMessage defaultMessage="NET Cost per module" id="GEpjcT" />}
            value={formatCurrency(netCostPerModule)}
          />
          <EsdecTableRow
            label={<FormattedMessage defaultMessage="NET Cost per wp" id="q80fDn" />}
            value={formatCurrency(netCostPerWp)}
          />
        </Box>
        {children}
      </Box>
    </EsdecSurfaceCardSection>
  );
}
