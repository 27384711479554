import { useMutation } from '@tanstack/react-query';
import { InferMutationOptions } from '@/utils/types';
import { EsdecPartialRoofWithSurfaceId } from '@/features/esdec/esdec-types';
import { useDebouncedCallback } from 'use-debounce';

async function esdecUpdateSurface(surface: EsdecPartialRoofWithSurfaceId) {
  const data = localStorage.getItem('esdecGetSurfaces');
  if (!data) {
    const surfaces: Record<string, EsdecPartialRoofWithSurfaceId> = {};
    surfaces[surface.surfaceId] = surface;
    localStorage.setItem('esdecGetSurfaces', JSON.stringify(surfaces));
  } else {
    const surfaces = JSON.parse(data) as Record<string, EsdecPartialRoofWithSurfaceId>;
    surfaces[surface.surfaceId] = surface;
    localStorage.setItem('esdecGetSurfaces', JSON.stringify(surfaces));
  }
}

export function useEsdecUpdateSurface(options?: InferMutationOptions<typeof esdecUpdateSurface>) {
  const result = useMutation(esdecUpdateSurface, options);
  const mutateDebounced = useDebouncedCallback(result.mutate, 1000);

  return {
    ...result,
    mutateDebounced
  };
}
