import { useGoogleMapContextMenu } from '@/features/google-map/utils/use-google-map-context-menu';
import React from 'react';
import { getPointFromMouseEvent } from '@/features/google-map/utils/get-point-from-mouse-event';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

// Setup events that are triggered by the map itself
export function useMapEvents(): void {
  const store = useEditorStore();
  const { map } = useGoogleMapContext();
  const { openMultiSelectionContextMenu } = useGoogleMapContextMenu();

  React.useEffect(() => {
    const listeners: google.maps.MapsEventListener[] = [
      map.addListener('click', (event: google.maps.MapMouseEvent) => {
        const point = getPointFromMouseEvent(event);
        if (point === undefined) {
          return;
        }

        if (store.state.isAddingPanel) {
          store.surfaces.surfacePanelManualCreate({
            point
          });
        }
      })
    ];

    return () => {
      listeners.forEach((listener) => listener.remove());
    };
  }, [store, map, openMultiSelectionContextMenu]);
}
