import React, { PropsWithChildren } from 'react';
import { Box } from '@/components/box';
import { Typography } from '@/components/typography';
import { Flex } from 'theme-ui';
import { EmptyPropsWithChildren } from '@/utils/types';

export interface IRightMenuSectionProps {
  title?: React.ReactNode;
  children: React.ReactNode;
}

export function RightMenuSection({ title, children }: IRightMenuSectionProps): JSX.Element {
  return (
    <Box>
      {title && <RightMenuSectionTitle>{title}</RightMenuSectionTitle>}
      <RightMenuSectionContent>{children}</RightMenuSectionContent>
    </Box>
  );
}

export function RightMenuSectionTitle({
  children,
  className
}: PropsWithChildren<{ className?: string }>): JSX.Element {
  return (
    <Typography
      className={className}
      sx={{ textTransform: 'uppercase', color: 'text', mb: 4 }}
      variant="headline5"
    >
      {children}
    </Typography>
  );
}

export function RightMenuSectionContent({ children }: EmptyPropsWithChildren): JSX.Element {
  return <Flex sx={{ gap: 5, flexDirection: 'column' }}>{children}</Flex>;
}
