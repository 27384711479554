import * as turf from '@turf/helpers';
import tin from '@turf/tin';
import { positionToPoint } from '@/utils/turf/position-to-point';
import { extendLine } from '@/utils/turf/extend-line';
import { surfaceRidgeSide } from '@/features/google-map/utils/surface-ridge-side';
import { selectPolylineSides } from '@/features/google-map/utils/select-polyline-sides';
import { getSideLengths, getSideLengthsWithTilt } from '@/features/editor/utils/get-side-lengths';
import { IEnrichedSurface } from '@/features/editor/utils/surface/surface';
import { Gis } from '@/features/editor/utils/gis';

/**
 * Computes the area of a surface.
 *
 * When the surface has a tilt angle and a ridge, the area calculation is no longer as simple as
 * calling `turf#area`. To compute the area of the surface, we triangulate the surface and then
 * compute the lengths of the sides of the triangles. We can then sum up the area of all triangles
 * to get the area of the entire surface.
 * */
export function getSurfaceArea(surface: IEnrichedSurface): number {
  const ridge = surfaceRidgeSide({
    ridge: surface.ridge,
    sides: selectPolylineSides(surface.path),
    id: surface.id
  });

  const pathPoints = turf.featureCollection(
    Gis.geojsonPolygonPathNormalize(surface.path).map((point) => turf.point(point))
  );
  const surfaceTriangles = tin(pathPoints);

  return surfaceTriangles.features.reduce((acc, triangle) => {
    const trianglePath = triangle.geometry.coordinates[0]!.map(positionToPoint);
    const [a, b, c] = ridge
      ? getSideLengthsWithTilt(trianglePath, extendLine(ridge), surface.tiltAngle)
      : getSideLengths(trianglePath);
    return acc + triangleArea(a!.length, b!.length, c!.length);
  }, 0);
}

function triangleArea(a: number, b: number, c: number): number {
  return 0.25 * Math.sqrt((a + b + c) * (-a + b + c) * (a - b + c) * (a + b - c));
}
