import { Card } from '@/components/card/card';
import { Typography } from '@/components/typography';
import { Box } from 'theme-ui';
import React from 'react';

interface IEsdecSurfaceCardSection {
  title: React.ReactNode;
  icon: React.ReactNode;
  children: React.ReactNode;
}

export function EsdecSurfaceCardSection({ title, icon, children }: IEsdecSurfaceCardSection): JSX.Element {
  return (
    <Card
      title={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 4
          }}
        >
          <Typography variant="headline3">{title}</Typography>
          {icon}
        </Box>
      }
    >
      {children}
    </Card>
  );
}
