import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { FormattedMessage, useIntl } from 'react-intl';
import { SurfaceEsdec } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { RoofDTOChosenAttachmentTypeEnum } from '@solel/esdec';
import { ISurface } from '@/features/editor/utils/surface/surface';

function getAttachmentTypeOptions(surfaceEsdec: SurfaceEsdec, surface: ISurface) {
  switch (surfaceEsdec.material) {
    case 'STEEL':
      switch (surfaceEsdec.steelType) {
        case 'CORRUGATED':
          if (surface.orientation === 'landscape') {
            return [
              RoofDTOChosenAttachmentTypeEnum.MountingProfiles,
              RoofDTOChosenAttachmentTypeEnum.HangerBolts
            ];
          }
          break;
        case 'TRAPEZOIDAL':
          return [
            RoofDTOChosenAttachmentTypeEnum.MountingProfiles,
            RoofDTOChosenAttachmentTypeEnum.HangerBolts
          ];
      }
      break;
    case 'CORRUGATED':
      if (surface.orientation === 'landscape' && surfaceEsdec.railSystem === 'VERTICAL') {
        return [
          RoofDTOChosenAttachmentTypeEnum.CorrugatedBrackets,
          RoofDTOChosenAttachmentTypeEnum.HangerBolts
        ];
      }
      break;
  }
  return undefined;
}

export const EsdecAttachmentTypeInput = observer(function EsdecAttachmentTypeInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const { surfacesEsdec } = store;
  const intl = useIntl();

  const surface = store.surfaces.surfaceGetOne(surfaceId);

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec || !surface) {
    return <React.Fragment />;
  }

  const attachmentTypes = getAttachmentTypeOptions(surfaceEsdec, surface);

  if (!attachmentTypes) {
    return <React.Fragment />;
  }
  const attachmentTypeOptions = attachmentTypes.map((attachmentType) => ({
    label: attachmentType,
    value: attachmentType
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Attachment Type',
        id: 'kj1ZDE'
      })}
      placeholder={<FormattedMessage defaultMessage="Attachment Type" id="kj1ZDE" />}
      name="attachmentType"
      options={attachmentTypeOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateAttachmentType(value.value as RoofDTOChosenAttachmentTypeEnum);
        }
      }}
      value={attachmentTypeOptions.find(
        (attachmentTypeOptions) => attachmentTypeOptions.value === surfaceEsdec.chosenAttachmentType
      )}
    />
  );
});
