import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Flex } from 'theme-ui';
import { RadioGroup } from '@/components/radio-group/radio-group';
// 19.06.2023 import { RoofHookTypeEnum } from '@solel/esdec';
import { RoofDTOHookTypeEnum } from '@solel/esdec';
import { FormattedMessage, useIntl } from 'react-intl';
import { SurfaceEsdec } from '@/features/editor/stores/mobx/surfaces-esdec-store';

function getHookTypeOptions(surfaceEsdec: SurfaceEsdec) {
  switch (surfaceEsdec.preferredProduct) {
    case 'CLICKFIT_BASIC':
      if (surfaceEsdec.material === 'TILES' && surfaceEsdec.railSystem === 'HORIZONTAL') {
        return [RoofDTOHookTypeEnum.Normal, RoofDTOHookTypeEnum.Hv];
      }
      break;
    case 'CLICKFIT_EVO':
      if (surfaceEsdec.material === 'TILES') {
        return [RoofDTOHookTypeEnum.Normal, RoofDTOHookTypeEnum.Truss];
      }
      break;
  }
  return undefined;
}

export const EsdecRoofHookTypeInput = observer(function EsdecRoofHookTypeInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const intl = useIntl();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  const hookTypeOptions = getHookTypeOptions(surfaceEsdec);

  if (!hookTypeOptions) {
    return <React.Fragment />;
  }

  return (
    <RadioGroup
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={<FormattedMessage defaultMessage="Hook Type" id="bAC44r" />}
      aria-label={intl.formatMessage({
        defaultMessage: 'Hook Type',
        id: 'bAC44r'
      })}
      value={surfaceEsdec.hookType}
      onChange={(value) => {
        surfaceEsdec.updateRoofHookType(value as RoofDTOHookTypeEnum);
      }}
    >
      <Flex sx={{ gap: 8 }}>
        {hookTypeOptions.map((option) => (
          <RadioGroup.Radio key={option} value={option}>
            <span sx={{ textTransform: 'capitalize' }}>{option}</span>
          </RadioGroup.Radio>
        ))}
      </Flex>
    </RadioGroup>
  );
});
