import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { IEditorResultsPlantSizeProps } from '@/features/editor-results/components/editor-results-plant-size/editor-results-plant-size';
import { themeColors } from '@/lib/theme-ui/colors';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { EditorResultsCardPrintable } from '@/features/editor-results/components/editor-results-card/editor-results-card-printable';
import { formatKiloWatts } from '../editor-results-surface-card/editor-results-surface-card';

function RowData({
  label,
  value,
  hasBorder
}: {
  label?: React.ReactElement;
  value: React.ReactNode;
  hasBorder: boolean;
}): JSX.Element {
  return (
    <View
      style={{
        paddingLeft: 12,
        marginBottom: 12,
        marginHorizontal: 5,
        borderBottom: hasBorder ? 1 : 0,
        borderColor: themeColors.menuBorder,
        lineHeight: 1.6
      }}
    >
      <Text style={{ fontSize: '8', color: '#667085' }}>{label}</Text>
      <Text style={{ fontSize: '9' }}>{value}</Text>
    </View>
  );
}

export function EditorResultsPlantSizePrintable({
  panelsCount,
  effectPerPanel,
  installedEffect,
  yearlyProduction,
  yearlyProductionValue,
  warrantyTimeProduction,
  warrantyTimeProductionValue,
  panelTypes,
  inverterTypes,
  pricePerKwp
}: IEditorResultsPlantSizeProps): JSX.Element {
  const formatCurrency = useFormatCurrency();

  return (
    <EditorResultsCardPrintable.Wrapper style={{ flex: 1 }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 12,
          paddingVertical: 12,
          marginBottom: 12,
          fontSize: 9,
          backgroundColor: '#E6F7ED'
        }}
      >
        <Text>Anläggningens storlek och produktion</Text>
      </View>
      <RowData
        label={<FormattedMessage defaultMessage="Paneltyper" id="z8ldiy" />}
        value={panelTypes}
        hasBorder={true}
      />
      <RowData
        label={<FormattedMessage defaultMessage="Växelriktartyper" id="H/mxrw" />}
        value={inverterTypes}
        hasBorder={true}
      />
      <RowData
        label={<FormattedMessage defaultMessage="Antal paneler" id="L9Xb8i" />}
        value={[panelsCount.value]}
        hasBorder={true}
      />
      <RowData
        label={<FormattedMessage defaultMessage="Genomsnittlig effekt per panel" id="Xax5dz" />}
        value={[effectPerPanel.value]}
        hasBorder={true}
      />
      <RowData
        label={<FormattedMessage defaultMessage="Intallerad effekt" id="58tgHu" />}
        value={[formatKiloWatts(installedEffect.value)]}
        hasBorder={true}
      />

      <RowData
        label={<FormattedMessage defaultMessage="Pris per kWp" id="fvnP5l" />}
        value={[formatCurrency(pricePerKwp)]}
        hasBorder={true}
      />

      <RowData
        label={<FormattedMessage defaultMessage="Produktion per år initialt" id="Qjb/1r" />}
        value={[yearlyProduction.value]}
        hasBorder={true}
      />
      <RowData
        label={<FormattedMessage defaultMessage="Värdet av producerad el per år initialt" id="khWduh" />}
        value={[formatCurrency(yearlyProductionValue.value)]}
        hasBorder={true}
      />
      <RowData
        label={<FormattedMessage defaultMessage="Tillverkning under garantitiden" id="1Y+UrF" />}
        value={[warrantyTimeProduction.value]}
        hasBorder={true}
      />
      <RowData
        label={<FormattedMessage defaultMessage="Värdet på el producerad under garantitiden" id="wbx6+y" />}
        value={[formatCurrency(warrantyTimeProductionValue.value)]}
        hasBorder={false}
      />
    </EditorResultsCardPrintable.Wrapper>
  );
}
