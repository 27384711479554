import { IPoint } from '@/utils/gis/types';
import * as R from 'remeda';
import { themeColors } from '@/lib/theme-ui/colors';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import { Shape } from '@/features/google-map/utils/shapes/shape';
import * as Turf from '@turf/helpers';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';
import * as turf from '@turf/helpers';
import { Gis } from '@/features/editor/utils/gis';

interface IPolygonOptions extends google.maps.PolygonOptions {}
interface IPolygonCloneOptions {
  path?: IPoint[];
  options?: IPolygonOptions;
}

interface IPolygonShape {
  id: string;
  path: IPoint[];
  options?: IPolygonOptions;
}

export class Polygon extends Shape implements IPolygonShape {
  protected readonly _path: IPoint[];
  private readonly _turfPolygon: Turf.Feature<Turf.Polygon>;

  public constructor(id: string, path: IPoint[], options?: IPolygonOptions) {
    super(
      id,
      R.merge(
        {
          strokeWeight: 2,
          paths: Gis.geojsonPolygonPathNormalize(path).map(pointToLatLng)
        },
        R.omit(options ?? {}, ['paths'])
      )
    );
    this._path = path;
    this._turfPolygon = turf.polygon([path]);
  }

  public get turfPolygon(): Turf.Feature<Turf.Polygon> {
    return this._turfPolygon;
  }

  public get path(): IPoint[] {
    return this._path;
  }

  public build(): google.maps.Polygon {
    return new google.maps.Polygon(this._options);
  }

  public update(shape: Polygon): Polygon {
    const result = new Polygon(this._id, shape._path, R.merge(this._options, shape.options));
    result.eventListenersToAttach = shape.eventListenersToAttach;
    return result;
  }

  public static get handleName(): string {
    return 'polygon-edit-handle';
  }
}

class PolygonObstacle extends Polygon {
  public constructor(id: string, path: IPoint[], options?: IPolygonOptions) {
    super(
      id,
      path,
      R.merge(
        {
          fillColor: themeColors.obstacle,
          strokeColor: themeColors.obstacle,
          zIndex: GOOGLE_MAP_Z_INDICES.OBSTACLE
        },
        options
      )
    );
  }

  public update(shape: PolygonObstacle): PolygonObstacle {
    const result = new PolygonObstacle(this._id, shape._path, R.merge(this._options, shape.options));
    result.eventListenersToAttach = shape.eventListenersToAttach;
    return result;
  }
}

interface IPolygonPanelOptions extends IPolygonOptions {}

interface IPolygonPanelData {
  id: string;
  path: IPoint[];
  surfaceId: string;
}

class PolygonPanel extends Polygon {
  private readonly _surfaceId: string;

  public constructor({ surfaceId, path, id }: IPolygonPanelData, options?: IPolygonPanelOptions) {
    super(id, path, R.merge({ zIndex: GOOGLE_MAP_Z_INDICES.SURFACE_PANEL }, options));
    this._surfaceId = surfaceId;
  }

  public update(shape: PolygonPanel): PolygonPanel {
    const result = new PolygonPanel(
      {
        path: shape._path,
        id: this._id,
        surfaceId: shape._surfaceId
      },
      R.merge(this._options, shape.options)
    );
    result.eventListenersToAttach = shape.eventListenersToAttach;
    return result;
  }

  private _clone({ path, options }: IPolygonCloneOptions): PolygonPanel {
    const result = new PolygonPanel(
      {
        id: this._id,
        path: path ?? this._path,
        surfaceId: this._surfaceId
      },
      options ?? this.options
    );
    result.eventListenersToAttach = this.eventListenersToAttach;
    return result;
  }

  public setOptions(options: IPolygonPanelOptions): PolygonPanel {
    return this._clone({ options });
  }

  public setPath(path: IPoint[]): PolygonPanel {
    return this._clone({ path });
  }

  public get options(): IPolygonPanelOptions | undefined {
    return this._options;
  }

  public get surfaceId(): string {
    return this._surfaceId;
  }
}

class PolygonSurface extends Polygon {
  public constructor(id: string, path: IPoint[], options?: IPolygonOptions) {
    super(id, path, options);
  }

  public update(shape: PolygonSurface): PolygonSurface {
    const result = new PolygonSurface(this._id, shape._path, R.merge(this._options, shape.options));
    result.eventListenersToAttach = shape.eventListenersToAttach;
    return result;
  }

  public static setbackId(surfaceId: string): string {
    return `${surfaceId}-setback`;
  }
}

export function isPolygonPanel(shape: Shape | undefined): shape is PolygonPanel {
  return shape instanceof PolygonPanel;
}

export function isPolygon(shape: Shape): shape is Polygon {
  return shape instanceof Polygon;
}

export { PolygonObstacle, PolygonPanel, PolygonSurface };
export type { IPolygonOptions };
