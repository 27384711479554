import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { getSurfaceArea } from '@/features/editor/utils/surface/get-surface-area';
import { IEnrichedSurface, ISurface } from '@/features/editor/utils/surface/surface';
import { formatMetersSquared } from '@/features/intl/utils/format-meters-squared';
import { R } from '@/lib/remeda';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Text } from 'theme-ui';

function EsdecOverviewContentItem({
  name,
  value
}: {
  name: string;
  value: string | undefined | number;
}): JSX.Element {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 4 }}>
      <Text sx={{ fontWeight: 600 }}>{name}</Text>
      <Text>{value}</Text>
    </Box>
  );
}
export const EsdecOverviewContent = observer(function EsdecOverviewContent({
  surface
}: {
  surface: ISurface;
}) {
  const store = useEditorStore();
  const { surfacesEsdec } = store;
  const { surfacePanels } = useSurfacePanels();
  const intl = useIntl();
  const surfaceEsdec = surfacesEsdec.surfaces[surface.id];

  if (R.isNil(surfaceEsdec)) {
    return <React.Fragment />;
  }
  const panelsCount = surfacePanels[surface.id]?.length ?? 0;
  const surfaceArea = intl.formatNumber(getSurfaceArea(surface as IEnrichedSurface), {
    maximumFractionDigits: 2
  });
  return (
    <Box sx={{ display: 'flex', gap: 120, padding: 6 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <EsdecOverviewContentItem name="Country" value="Sweden" />
        <EsdecOverviewContentItem
          name="Windzone:"
          value={surfaceEsdec.windzone ? surfaceEsdec.windzone : 'No windzone selected'}
        />
        <EsdecOverviewContentItem
          name="Terrain Category:"
          value={surfaceEsdec.terrainCategory ? surfaceEsdec.terrainCategory : 'No terrain category selected'}
        />
        <EsdecOverviewContentItem name="Roof height:" value={surface.heightToEavesMeters} />
        <EsdecOverviewContentItem name="Roof angle:" value={surface.tiltAngle} />
        <EsdecOverviewContentItem name="Roof area:" value={formatMetersSquared(surfaceArea)} />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <EsdecOverviewContentItem name="Panel model:" value={surface.panelTypeId} />
        <EsdecOverviewContentItem name="Panel count:" value={panelsCount} />
        <EsdecOverviewContentItem
          name="Product:"
          value={surfaceEsdec.preferredProduct ? surfaceEsdec.preferredProduct : 'No product selected'}
        />
        <EsdecOverviewContentItem name="Total cost:" value="22" />
        <EsdecOverviewContentItem name="Total power:" value="22" />
        <EsdecOverviewContentItem name="Total weight:" value="22" />
      </Box>
    </Box>
  );
});
