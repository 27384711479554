import { SelectField } from '@/components/select-field';
import { SurfaceEsdec } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { RoofDTOPreferredProductEnum } from '@solel/esdec';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEsdecArticles } from '../api/esdec-get-articles';

export const EsdecSupportScrewTypeInput = observer(function EsdecSupportScrewTypeInput({
  preferredProduct,
  surfaceEsdec
}: {
  preferredProduct: RoofDTOPreferredProductEnum;
  surfaceEsdec: SurfaceEsdec;
}) {
  const articles = useEsdecArticles(preferredProduct, 'ROOF_SUPPORT_SCREW');
  const intl = useIntl();

  if (isQueryLoading(articles.isLoading, articles.data)) {
    return <React.Fragment />;
  }

  const supportScrewOptions = articles.data.map((supportScrewType) => ({
    label: supportScrewType.description,
    value: supportScrewType.description
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Support Screw',
        id: 'LpVmdI'
      })}
      placeholder={<FormattedMessage defaultMessage="Select Support Screw" id="fdcHkj" />}
      name="supportScrew"
      options={supportScrewOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateSupportScrew(value.value);
        }
      }}
      value={supportScrewOptions.find(
        (supportScrewOption) => supportScrewOption.value === surfaceEsdec.chosenBitumenSupportScrew
      )}
    />
  );
});
