export class Base64 {
  // Encodes a string as a base64 image
  public static encode(input: string, kind: 'jpg' | 'png' = 'png'): string {
    return `data:image/${kind};base64,${input}`;
  }

  // Removes the base64 identifier from a base64 string, leaving only the data
  public static strip(input: string): string {
    return input.replace(/^data:.*;base64,/, '');
  }

  public static readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result !== 'string') {
          reject(new Error(`Expected file to be a string, but got ${typeof reader.result}`));
        } else {
          resolve(reader.result);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }
}
