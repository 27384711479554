import { api } from '@/utils/api';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { EditorApiKeys } from '@/features/editor/api/keys';
import { InferQueryOptions, UnwrapPromise } from '@/utils/types';

export interface ICalculationAccessoriesApiRequest {
  calculationId: number;
}

const responseParser = z.object({
  wallbox: z.array(z.number()),
  batteries: z.array(z.number()),
  accessories: z.array(z.number()),
  additions: z.array(
    z.object({
      price: z
        .number()
        .nullable()
        // We don't want undefined/null price values, rather 0
        .transform((val) => val ?? 0),
      margin: z.number(),
      amount: z.number(),
      name: z.string(),
      id: z.number(),
      product_id: z.number().nullable(),
      type: z.string()
    })
  )
});

export async function calculationAccessoriesApi(
  { calculationId }: ICalculationAccessoriesApiRequest,
  signal?: AbortSignal
) {
  const response = await api.get(`/getAllCalculationAccessories?calculationID=${calculationId}`, {
    signal
  });
  return responseParser.parse(response.data);
}

export type ICalculationAccessoriesApiResponse = UnwrapPromise<ReturnType<typeof calculationAccessoriesApi>>;

export function useCalculationAccessories(
  calculationId: number,
  options?: InferQueryOptions<typeof calculationAccessoriesApi>
) {
  return useQuery(
    EditorApiKeys.calculationAccessories({ calculationId }),
    ({ signal }) => calculationAccessoriesApi({ calculationId }, signal),
    {
      staleTime: Infinity,
      ...options
    }
  );
}
