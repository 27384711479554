import { Feature, Polygon } from '@turf/helpers';
import { IPoint } from '@/utils/gis/types';
import { positionToPoint } from '@/utils/turf/position-to-point';

// Takes a turf polygon and returns a point path
function turfPolygonPath(poly: Feature<Polygon>): IPoint[] {
  return poly.geometry.coordinates[0]!.map(positionToPoint);
}

export { turfPolygonPath };
