import { ThemeUiProvider } from '@/lib/theme-ui';
import { ReactQueryProvider } from '@/lib/react-query/react-query';
import { IntlMessages, ReactIntlProvider } from '@/lib/react-intl/react-intl';
import React from 'react';
import { RollbarProvider } from '@/lib/rollbar/rollbar';
import { ReactRouterProvider } from '@/lib/react-router/react-router';

export interface IGlobalProviderProps {
  defaultIntlMessages: IntlMessages;
}

export function GlobalProvider({ defaultIntlMessages }: IGlobalProviderProps): JSX.Element {
  return (
    <RollbarProvider>
      <ReactQueryProvider>
        <ThemeUiProvider>
          <ReactIntlProvider defaultMessages={defaultIntlMessages}>
            <ReactRouterProvider />
          </ReactIntlProvider>
        </ThemeUiProvider>
      </ReactQueryProvider>
    </RollbarProvider>
  );
}
