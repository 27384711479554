import React from 'react';
import { Box, Paragraph, ThemeUIStyleObject } from 'theme-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { useEditorContext } from '@/features/editor/stores/use-editor-context';
import { EditorApi } from '@/features/editor';
import { R } from '@/lib/remeda';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { pricePerKwp } from '@/features/editor-results/routes/editor-results-route';
import { SolarUtils } from '@/utils/solar-utils';

function BottomBarDataPoint({
  value,
  label,
  sx
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  sx?: ThemeUIStyleObject;
}): JSX.Element {
  return (
    <Box
      sx={{ display: 'flex', gap: 2, alignItems: 'baseline', px: 4, fontSize: 'sm', color: 'text', ...sx }}
    >
      <Paragraph>{label}</Paragraph>
      <Paragraph sx={{ fontWeight: 'bold' }}>{value}</Paragraph>
    </Box>
  );
}

export function MapBottomBar(): JSX.Element {
  const intl = useIntl();
  const formatCurrency = useFormatCurrency();

  const { surfacePanels } = useSurfacePanels();
  const surfaces = useSurfaces();

  const { calculationId } = useEditorContext();
  const editorResults = EditorApi.useEditorResults({ calculationId });

  const allSurfacesPanelsCount = R.pipe(
    R.values(surfacePanels),
    R.map((panels) => panels.length),
    R.reduce((acc, curr) => acc + curr, 0)
  );

  const allSurfacesKwp = SolarUtils.wattToKilowatt(
    R.reduce(surfaces, (acc, surface) => surface.totalPanelPower + acc, 0)
  );
  const productionData = R.first(R.values(editorResults.data?.sizeAndProduction ?? []));

  if (isQueryLoading(editorResults.isLoading, editorResults.data) || R.isNil(productionData)) {
    return <React.Fragment />;
  }
  const sizeAndProductionData = R.first(R.values(editorResults.data.sizeAndProduction));

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(238, 239, 244, 0.75)',
        backdropFilter: 'blur(8px)',
        width: '100%',
        py: 4,
        px: 4,
        display: 'flex',

        '& > *:not(:last-child)': {
          borderRight: '1px solid rgba(0, 0, 0, 0.5)'
        }
      }}
    >
      <BottomBarDataPoint
        sx={{
          '@media (max-width: 1493px)': {
            display: 'none'
          }
        }}
        label={<FormattedMessage defaultMessage="Number of panels:" id="jOBNib" />}
        value={intl.formatNumber(allSurfacesPanelsCount)}
      />

      <BottomBarDataPoint
        label={<FormattedMessage defaultMessage="Total power:" id="/5GoNN" />}
        value={`${intl.formatNumber(allSurfacesKwp)} kWp`}
      />

      <BottomBarDataPoint
        label={<FormattedMessage defaultMessage="kWh/year:" id="m6EuA2" />}
        value={productionData.yearlyProduction.value}
      />

      <BottomBarDataPoint
        label={<FormattedMessage defaultMessage="Total cost:" id="FZsRrm" />}
        value={formatCurrency(editorResults.data.costs.table.finalCost.value)}
      />

      {sizeAndProductionData && (
        <BottomBarDataPoint
          label={<FormattedMessage defaultMessage="Price/kWp:" id="ABzsdO" />}
          value={formatCurrency(
            pricePerKwp(editorResults.data.costs.table, sizeAndProductionData.installedEffect.value)
          )}
        />
      )}
    </Box>
  );
}
