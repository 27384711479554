/** @jsxImportSource theme-ui */
import { FormattedMessage } from 'react-intl';
import { EsdecTableRow, EsdecWeatherZonesTableModal } from './esdec-weather-zones-table-modal';

interface IEsdecTerrainModal {
  onClose: () => void;
}

export function EsdecTerrainModal({ onClose }: IEsdecTerrainModal): JSX.Element {
  return (
    <EsdecWeatherZonesTableModal
      title={<FormattedMessage defaultMessage="Explanation of terrain categories" id="lgHfcK" />}
      subTitle={<FormattedMessage defaultMessage="Terrain categories for the Sweden:" id="0DUH3d" />}
      onClose={onClose}
    >
      <EsdecTableRow
        name={<FormattedMessage defaultMessage="terrain category 0" id="hKR6fe" />}
        description={<FormattedMessage defaultMessage="Coastal area exposed to open sea." id="YVoTk+" />}
      />
      <EsdecTableRow
        name={<FormattedMessage defaultMessage="terrain category I" id="PlG4AE" />}
        description={
          <FormattedMessage defaultMessage="Lake shores and flat open areas with no obstacles." id="8UHu5P" />
        }
      />
      <EsdecTableRow
        name={<FormattedMessage defaultMessage="terrain category II" id="JsK3HN" />}
        description={
          <FormattedMessage
            defaultMessage="Areas with low vegetation and isolated obstacles where the average obstacle separation is more than 20 times the average obstacle height."
            id="Ui2iuS"
          />
        }
      />
      <EsdecTableRow
        name={<FormattedMessage defaultMessage="terrain category III" id="ENdwyQ" />}
        description={
          <FormattedMessage
            defaultMessage="Villages and suburbs, where the average separation is less than 20 times the average obstacle height."
            id="4eiBmI"
          />
        }
      />
      <EsdecTableRow
        name={<FormattedMessage defaultMessage="terrain category IV" id="+SlqkQ" />}
        description={
          <FormattedMessage
            defaultMessage="City centers and similar areas, where more than 15 % of the terrain is covered with structures taller than 15 m."
            id="J2LjgC"
          />
        }
      />
    </EsdecWeatherZonesTableModal>
  );
}
