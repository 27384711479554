import { IPoint } from '@/utils/gis/types';
import React from 'react';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';
import { useDeepCompareEffect } from 'react-use';
import { Gis } from '@/features/editor/utils/gis';

export interface IMapPolygonOptions
  extends Pick<
    google.maps.PolygonOptions,
    'clickable' | 'draggable' | 'zIndex' | 'strokeColor' | 'fillColor' | 'editable'
  > {
  path: IPoint[];

  onRightClick?: (event: google.maps.MapMouseEvent) => void;
  onMouseOut?: (event: google.maps.MapMouseEvent) => void;
  onMouseOver?: (event: google.maps.MapMouseEvent) => void;
  onClick?: (event: google.maps.MapMouseEvent) => void;
  onDragEnd?: (event: google.maps.MapMouseEvent) => void;
  onDragStart?: (event: google.maps.MapMouseEvent) => void;
  onDrag?: (event: google.maps.MapMouseEvent) => void;

  children?: React.ReactNode;

  hoveredStyles?: Pick<google.maps.PolygonOptions, 'strokeColor' | 'fillColor'>;
}

export function MapPolygon({
  onDrag,
  onDragEnd,
  onDragStart,
  onMouseOut,
  onMouseOver,
  onRightClick,
  onClick,

  children,
  hoveredStyles,

  ...options
}: IMapPolygonOptions) {
  const [polygon, setPolygon] = React.useState<google.maps.Polygon>();
  const [isHovered, setIsHovered] = React.useState(false);

  const { map } = useGoogleMapContext();

  React.useEffect(() => {
    if (!polygon) {
      const polygon = new google.maps.Polygon();
      setPolygon(polygon);
      polygon.setMap(map);
    }

    return () => {
      if (polygon) {
        polygon.setMap(null);
      }
    };
  }, [map, polygon]);

  useDeepCompareEffect(() => {
    if (polygon) {
      const { path, ...rest } = options;
      const hoveredOptions = hoveredStyles ?? {};
      const withHovered = isHovered ? { ...rest, ...hoveredOptions } : rest;

      polygon.setOptions({
        paths: Gis.geojsonPolygonPathNormalize(path).map(pointToLatLng),
        ...withHovered
      });
    }
  }, [isHovered, hoveredStyles, options, polygon]);

  React.useEffect(() => {
    const listeners = [
      polygon?.addListener('mouseout', (event: google.maps.MapMouseEvent) => {
        setIsHovered(false);
        onMouseOut?.(event);
      }),
      polygon?.addListener('mouseover', (event: google.maps.MapMouseEvent) => {
        setIsHovered(true);
        onMouseOver?.(event);
      }),
      onRightClick ? polygon?.addListener('rightclick', onRightClick) : undefined,
      onClick ? polygon?.addListener('click', onClick) : undefined,
      onDrag ? polygon?.addListener('drag', onDrag) : undefined,
      onDragEnd ? polygon?.addListener('dragend', onDragEnd) : undefined,
      onDragStart ? polygon?.addListener('dragstart', onDragStart) : undefined
    ];

    return () => {
      listeners.forEach((listener) => listener?.remove());
    };
  }, [onClick, onDrag, onDragEnd, onDragStart, onMouseOut, onMouseOver, onRightClick, polygon]);

  return <React.Fragment>{children}</React.Fragment>;
}
