import { Box, Grid, Text } from 'theme-ui';
import { EsdecModalHeader } from './esdec-modal-header';

interface IEsdecTableRow {
  name: React.ReactNode;
  description: React.ReactNode;
}

interface IEsdecWeatherZonesTableModal {
  onClose: () => void;
  title: React.ReactNode;
  subTitle: React.ReactNode;
  children: React.ReactNode;
}

export function EsdecTableRow({ name, description }: IEsdecTableRow): JSX.Element {
  return (
    <Grid
      sx={{
        gridTemplateColumns: '0.7fr 3fr',
        p: 5,
        '&:not(:last-child)': {
          borderBottom: '1px solid',
          borderColor: 'inputBorder'
        }
      }}
    >
      <Text sx={{ textTransform: 'uppercase', color: 'menuText', fontWeight: 600 }}>{name}</Text>
      <Text>{description}</Text>
    </Grid>
  );
}

export function EsdecWeatherZonesTableModal({
  onClose,
  title,
  subTitle,
  children
}: IEsdecWeatherZonesTableModal): JSX.Element {
  return (
    <Box
      sx={{
        backgroundColor: '#F9FAFA'
      }}
    >
      <EsdecModalHeader onClose={onClose}>{title}</EsdecModalHeader>
      <Box sx={{ p: 8 }}>
        <Box
          sx={{
            p: 5,
            backgroundColor: 'primary',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: 2,
            mb: 5
          }}
        >
          <Text sx={{ color: 'white', fontSize: 'lg', fontWeight: 600 }}>{subTitle}</Text>
        </Box>
        {children}
      </Box>
    </Box>
  );
}
