/** @jsxImportSource theme-ui */
import React from 'react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { MdOutlineChevronRight } from 'react-icons/md';

interface IDropdownMenuProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
  contentProps?: React.ComponentProps<typeof RadixDropdownMenu.Content>;
  rootProps?: React.ComponentProps<typeof RadixDropdownMenu.Root>;
}

function DropdownMenuItem(props: RadixDropdownMenu.DropdownMenuItemProps) {
  return (
    <RadixDropdownMenu.Item {...props} sx={{ variant: 'text.menuItem' }}>
      {props.children}
    </RadixDropdownMenu.Item>
  );
}

const DropdownMenuPortal = RadixDropdownMenu.Portal;

const DropdownMenuContent = React.forwardRef(function DropdownMenuContent(
  props: RadixDropdownMenu.DropdownMenuContentProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RadixDropdownMenu.Content
      ref={ref}
      sx={{
        variant: 'cards.menu'
      }}
      {...props}
    />
  );
});

const DropdownMenuSubContent = React.forwardRef(function DropdownMenuSubContent(
  props: RadixDropdownMenu.DropdownMenuSubContentProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RadixDropdownMenu.SubContent
      ref={ref}
      sx={{
        variant: 'cards.menu'
      }}
      {...props}
    />
  );
});

function DropdownMenuSubTrigger(props: RadixDropdownMenu.DropdownMenuSubTriggerProps) {
  return (
    <RadixDropdownMenu.SubTrigger
      sx={{
        variant: 'text.menuItem'
      }}
      {...props}
    >
      {props.children}

      <MdOutlineChevronRight sx={{ ml: 1 }} />
    </RadixDropdownMenu.SubTrigger>
  );
}

/**
 * Intended to replace the ActionMenu. RadixUI DropdownMenu seems to be easier to use
 * and more flexible than the ActionMenu from react-aria.
 * */
function DropdownMenu({ trigger, contentProps, children, rootProps }: IDropdownMenuProps) {
  return (
    <RadixDropdownMenu.Root modal={false} {...rootProps}>
      <RadixDropdownMenu.Trigger asChild>{trigger}</RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal>
        <DropdownMenuContent {...contentProps}>{children}</DropdownMenuContent>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
}

function DropdownMenuSub({ children, trigger }: { children: React.ReactNode; trigger: React.ReactNode }) {
  return (
    <RadixDropdownMenu.Sub>
      <DropdownMenuSubTrigger>{trigger}</DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent sideOffset={8}>{children}</DropdownMenuSubContent>
      </DropdownMenuPortal>
    </RadixDropdownMenu.Sub>
  );
}

export { DropdownMenu, DropdownMenuItem, DropdownMenuSub, DropdownMenuContent };
