import { EditorResultsEditCard } from '@/features/editor-results/components/editor-results-edit-card';
import { Icons } from '@/assets';
import { Typography } from '@/components/typography';
import { Box } from 'theme-ui';
import React from 'react';
import { IGetEditorResultsApiResponse } from '@/features/editor/api';
import { useIntl } from 'react-intl';
import { EditorContext } from '@/features/editor/utils/editor-context';
import { Link } from '@/components/link/link';
import { createHttpsUrl } from '@/features/editor-results/utils/http/create-https-url';

interface IBasicInformationData {
  icon?: React.ReactNode;
  label: string | React.ReactNode;
}

interface IBasicInformationTitle {
  title: string;
}

function BasicInformationTitle({ title }: IBasicInformationTitle): JSX.Element {
  return (
    <Typography variant="headline2" sx={{ color: 'primary', py: 5 }}>
      {title}
    </Typography>
  );
}

function BasicInformationData({ icon, label }: IBasicInformationData): JSX.Element {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 6 }}>
      <Box sx={{ color: 'primary', display: 'inline-flex' }}>{icon}</Box>

      <Typography variant="headline4" sx={{ py: 2 }}>
        {label}
      </Typography>
    </Box>
  );
}

export interface IBasicInformationProps
  extends Pick<IGetEditorResultsApiResponse, 'customerOrganizationInfo'>,
    Pick<EditorContext, 'projectInformation'> {
  onEdit: () => void;
}

export function BasicInformation({
  onEdit,
  customerOrganizationInfo: { organizationInfo, customerInfo },
  projectInformation
}: IBasicInformationProps): JSX.Element {
  const intl = useIntl();

  function referenceNumberLabel(referenceNumber: number): string {
    return [intl.formatMessage({ defaultMessage: 'Reference number', id: 'H7AdL7' }), referenceNumber].join(
      ' '
    );
  }

  return (
    <EditorResultsEditCard.Wrapper>
      <EditorResultsEditCard.Title icon={<Icons.BasicInformation />} onEdit={onEdit}>
        Grundläggande information
      </EditorResultsEditCard.Title>
      <EditorResultsEditCard.Content>
        <Box sx={{ borderBottom: '1px solid', borderColor: 'inputBorder', pb: 6 }}>
          <BasicInformationTitle title="Företags Information" />

          {organizationInfo.address && (
            <BasicInformationData icon={<Icons.Address />} label={organizationInfo.address} />
          )}

          {organizationInfo.website && (
            <BasicInformationData
              icon={<Icons.Language />}
              label={
                <Link href={createHttpsUrl(organizationInfo.website)} target="_blank">
                  {organizationInfo.website}
                </Link>
              }
            />
          )}

          {organizationInfo.sellerName && (
            <BasicInformationData icon={<Icons.User />} label={organizationInfo.sellerName} />
          )}

          {organizationInfo.sellerPhone && (
            <BasicInformationData icon={<Icons.Phone />} label={organizationInfo.sellerPhone} />
          )}

          {organizationInfo.sellerMail && (
            <BasicInformationData icon={<Icons.Mail />} label={organizationInfo.sellerMail} />
          )}
        </Box>

        <Box sx={{ pb: 6 }}>
          <BasicInformationTitle title="Kalkyl för" />

          <BasicInformationData
            icon={<Icons.Menu />}
            label={referenceNumberLabel(customerInfo.referenceNumber)}
          />

          {projectInformation.clientFullName && (
            <BasicInformationData icon={<Icons.User />} label={projectInformation.clientFullName} />
          )}

          {projectInformation.clientCompanyName && (
            <BasicInformationData icon={<Icons.Business />} label={projectInformation.clientCompanyName} />
          )}

          <BasicInformationData
            icon={<Icons.Mail />}
            label={<Link href={`mailto: ${customerInfo.email}`}>{customerInfo.email}</Link>}
          />
          {projectInformation.clientPhone && (
            <BasicInformationData icon={<Icons.Phone />} label={projectInformation.clientPhone} />
          )}

          <BasicInformationData icon={<Icons.Address />} label={customerInfo.address} />
        </Box>
      </EditorResultsEditCard.Content>
    </EditorResultsEditCard.Wrapper>
  );
}
