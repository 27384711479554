/** @jsxImportSource theme-ui */
import * as RadixToggle from '@radix-ui/react-toggle';
import { EmptyPropsWithChildren } from '@/utils/types';
import { Icons } from '@/assets';

interface IToggleProps extends RadixToggle.ToggleProps {}

export function Toggle(props: IToggleProps): JSX.Element {
  const iconProps = {
    size: 48,
    sx: {
      color: props.pressed ? 'primary' : '#B1B9C9'
    }
  };

  return (
    <RadixToggle.Root
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: 'sm',
        gap: 3,
        fontFamily: 'body',
        fontWeight: 500,
        width: 'fit-content'
      }}
      {...props}
    >
      {props.pressed ? (
        <IconWrapper>
          <Icons.ToggleOn {...iconProps} />
        </IconWrapper>
      ) : (
        <IconWrapper>
          <Icons.ToggleOff {...iconProps} />
        </IconWrapper>
      )}
      <span>{props.children}</span>
    </RadixToggle.Root>
  );
}

function IconWrapper({ children }: EmptyPropsWithChildren): JSX.Element {
  return <div sx={{ display: 'inline-flex' }}>{children}</div>;
}
