import { SurfacesStore } from '@/features/editor/stores/mobx/surfaces-store';
import { MetadataStore } from '@/features/editor/stores/mobx/metadata-store';
import { editorInitialContext, IEditorContext } from '@/features/editor/utils/editor-context';
import { createContext } from '@/utils/create-context';
import React, { PropsWithChildren } from 'react';
import { ObstaclesStore } from '@/features/editor/stores/mobx/obstacles-store';
import { HistoryStore, IHistoryStoreOptions } from '@/lib/mobx/mobx-undo-redo/history-store';
import { InvertersStore } from '@/features/editor/stores/mobx/inverters-store';
import { ProjectInformationStore } from '@/features/editor/stores/mobx/project-information-store';
import { MapDataStore } from '@/features/editor/stores/mobx/map-data-store';
import { OverlayImagesStore } from '@/features/editor/stores/mobx/overlay-images-store';
import { StateStore } from '@/features/editor/stores/mobx/state-store';
import { UndoRedoPatch } from '@/lib/mobx/mobx-undo-redo/get-undo-redo-patch';
import { OverlayImageStore } from '@/features/editor/stores/mobx/overlay-image-store';
import { SurfacesEsdecStore } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { useEsdecUpdateSurface } from '@/features/esdec/api/esdec-update-surface';
import { EsdecPartialRoofWithSurfaceId } from '@/features/esdec/esdec-types';
import { SelectionStore } from '@/features/editor/stores/mobx/selection-store';

interface IEditorStoreHistorySubset
  extends Pick<ObstaclesStore, 'obstacles'>,
    Pick<SurfacesStore, 'surfaces'>,
    Pick<InvertersStore, 'inverters'> {}

export interface IEditorStoreInitialContext extends IEditorContext {
  surfacesEsdec: Record<string, EsdecPartialRoofWithSurfaceId>;
}

export class EditorStore extends HistoryStore<IEditorStoreHistorySubset> {
  private readonly _surfacesStore: SurfacesStore;
  private readonly _metadataStore: MetadataStore;
  private readonly _obstaclesStore: ObstaclesStore;
  private readonly _invertersStore: InvertersStore;
  private readonly _projectInformationStore: ProjectInformationStore;
  private readonly _mapDataStore: MapDataStore;
  private readonly _overlayImagesStore: OverlayImagesStore;
  private readonly _stateStore: StateStore;
  private readonly _overlayImageStore: OverlayImageStore;
  private readonly _surfacesEsdecStore: SurfacesEsdecStore;

  private readonly _selectionStore: SelectionStore;

  public constructor(context?: IEditorStoreInitialContext, options?: IHistoryStoreOptions) {
    const selectionStore = new SelectionStore();
    const stateStore = new StateStore();
    const invertersStore = new InvertersStore(context);
    const metadataStore = new MetadataStore(context?.metadata);
    const surfacesEsdecStore = new SurfacesEsdecStore({ initial: context?.surfacesEsdec });
    const surfacesStore = new SurfacesStore({
      initial: context,
      metadataStore,
      invertersStore,
      stateStore,
      surfacesEsdecStore
    });
    const obstaclesStore = new ObstaclesStore({ initial: context });
    const projectInformationStore = new ProjectInformationStore({ initial: context?.projectInformation });
    const mapDataStore = new MapDataStore({ initial: context?.mapData });
    const overlayImagesStore = new OverlayImagesStore({ initial: context?.overlayImages });
    const overlayImageCurrentStore = new OverlayImageStore();

    // All observables added here will have their
    // change history tracked by the HistoryStore.
    // This is used for undo/redo
    super(
      {
        surfaces: surfacesStore.surfaces,

        obstacles: obstaclesStore.obstacles,

        inverters: invertersStore.inverters
      },
      options
    );

    this._surfacesStore = surfacesStore;
    this._metadataStore = metadataStore;
    this._obstaclesStore = obstaclesStore;
    this._invertersStore = invertersStore;
    this._projectInformationStore = projectInformationStore;
    this._mapDataStore = mapDataStore;
    this._overlayImagesStore = overlayImagesStore;
    this._stateStore = stateStore;
    this._overlayImageStore = overlayImageCurrentStore;
    this._surfacesEsdecStore = surfacesEsdecStore;
    this._selectionStore = selectionStore;
  }

  public get surfaces() {
    return this._surfacesStore;
  }

  public get metadata() {
    return this._metadataStore;
  }

  public get obstacles() {
    return this._obstaclesStore;
  }

  public get inverters() {
    return this._invertersStore;
  }

  public get projectInformation() {
    return this._projectInformationStore;
  }

  public get mapData() {
    return this._mapDataStore;
  }

  public get overlayImages() {
    return this._overlayImagesStore;
  }

  public get state() {
    return this._stateStore;
  }

  public get overlayImage() {
    return this._overlayImageStore;
  }

  public get surfacesEsdec() {
    return this._surfacesEsdecStore;
  }

  public get selection() {
    return this._selectionStore;
  }

  public get context(): typeof editorInitialContext {
    return {
      metadata: this.metadata,
      mapData: this.mapData,
      inverters: this.inverters.inverters,
      selectedInverterId: this.inverters.selectedInverterId,
      obstacles: this.obstacles.obstacles,
      overlayImages: this.overlayImages.overlayImages,
      projectInformation: this.projectInformation,
      selectedSurfaceId: this.surfaces.selectedSurfaceId,
      surfaces: this.surfaces.surfaces,
      surfacesPanelsCache: this.surfaces.surfacesPanelsCache
    };
  }

  public get contextExtended(): IEditorStoreInitialContext {
    return {
      ...this.context,
      surfacesEsdec: this.surfacesEsdec.surfaces
    };
  }
}

const [useEditorStore, EditorStoreContextValue] = createContext<EditorStore>();

class UndoRedoStorage {
  private readonly _key: string = 'SOLEL_EDITOR_HISTORY';

  public set(stack: UndoRedoPatch[]) {
    localStorage.setItem(this._key, JSON.stringify(stack));
  }

  public get(): UndoRedoPatch[] {
    try {
      const stack = localStorage.getItem(this._key);
      if (stack) {
        return JSON.parse(stack) as UndoRedoPatch[];
      }

      return [];
    } catch (error) {
      return [];
    }
  }
}

// TODO: Use when the time comes
// Beware of race conditions when implementing undo/redo history storage.
// If a surface for example is deleted, but the user refreshes the page
// before the "update" action is dispatched, the undo/redo storage will
// be populated, but the surface will not be deleted. Then, on the next
// undo, there will be two surfaces with the same id.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const undoRedoStorage = new UndoRedoStorage();

export function useEditorStoreSubscribers() {
  const store = useEditorStore();
  const esdecUpdateSurface = useEsdecUpdateSurface();

  React.useEffect(() => {
    const onSurfaceUpdate = (surface: EsdecPartialRoofWithSurfaceId) => {
      esdecUpdateSurface.mutateDebounced(surface);
    };

    store.surfacesEsdec.on('surface-update', onSurfaceUpdate);

    return () => {
      store.surfacesEsdec.off('surface-update', onSurfaceUpdate);
    };
  }, [esdecUpdateSurface, store]);
}

function EditorStoreProvider({
  context,
  children
}: PropsWithChildren<{
  context?: IEditorStoreInitialContext;
}>) {
  const [store] = React.useState(() => new EditorStore(context));

  return <EditorStoreContextValue value={store}>{children}</EditorStoreContextValue>;
}

export { EditorStoreProvider, useEditorStore };
