import { Box, Image, Flex, Heading, Paragraph } from 'theme-ui';
import resultBannerImage from '@/assets/images/result-banner-picture.png';
import { IGetEditorResultsApiResponse } from '@/features/editor/api';

export function EditorResultsBanner({
  totEarning,
  yearEarning,
  warrantyTimeEmission,
  warrantyTimeProduction,
  returnInvestment
}: IGetEditorResultsApiResponse['congratsTextVars']): JSX.Element {
  return (
    <Flex
      sx={{
        backgroundColor: '#039855'
      }}
    >
      <Box
        sx={{
          pl: 120,
          pt: 12,
          pb: 60,
          color: 'white',
          maxWidth: 760
        }}
      >
        <Box sx={{ fontSize: '2xl', mb: 8 }}>
          <Heading sx={{ fontWeight: 600 }}>Grattis!</Heading>
          <Heading sx={{ fontWeight: 'normal' }}>Ditt tak är perfekt för sol!</Heading>
        </Box>

        <Box
          sx={{
            fontSize: 'xl'
          }}
        >
          <Paragraph sx={{ mb: 5 }}>
            Enligt beräkningen tjänar du minst {totEarning} på att installera solceller på ditt tak, vilket
            motsvarar en avkastning på {returnInvestment}. Redan under år {yearEarning} får du mer pengar i
            plånboken.
          </Paragraph>

          <Paragraph>
            Under garantiperioden kommer din anläggning att producera cirka {warrantyTimeProduction}, vilket
            motsvarar en utsläppsminskning på cirka {warrantyTimeEmission} av koldioxid.
          </Paragraph>
        </Box>
      </Box>

      <Box
        sx={{
          ml: 'auto'
        }}
      >
        <Image
          sx={{ ml: 'auto', width: '100%', height: '100%', objectFit: 'cover' }}
          src={resultBannerImage}
        />
      </Box>
    </Flex>
  );
}
