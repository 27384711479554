import React from 'react';
import { NumberField } from '@/components/number-field/number-field';
import { Icons } from '@/assets';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface ISurfaceHeightToEavesInputProps {
  surfaceId: string;
}

export const SurfaceHeightToEavesInput = observer(function SurfaceHeightToEavesInput({
  surfaceId
}: ISurfaceHeightToEavesInputProps): JSX.Element {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (!surface) {
    return <React.Fragment />;
  }

  return (
    <NumberField
      minValue={0}
      value={surface.heightToEavesMeters}
      onChange={(value) => {
        if (Number.isNaN(value)) {
          return;
        }
        store.surfaces.surfaceUpdate(surfaceId, (surface) => {
          surface.heightToEavesMeters = value;
        });
      }}
      icon={<Icons.HeightToEaves size={15} />}
      label="Height to eaves"
      formatOptions={{
        style: 'unit',
        unit: 'meter'
      }}
    />
  );
});
