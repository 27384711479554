import React, { PropsWithChildren } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { Box } from '@/components/box';

export function MaxWidthWrapper({
  children,
  sx,
  className
}: PropsWithChildren<{
  sx?: ThemeUIStyleObject;
  className?: string;
}>): JSX.Element {
  return (
    <Box className={className} sx={{ maxWidth: 1440, width: '100%', mx: 'auto', ...sx }}>
      {children}
    </Box>
  );
}
