import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLogging } from '@/utils/use-logging';
import React, { PropsWithChildren } from 'react';
import { HttpError } from '@/utils/api';

export function useQueryClient() {
  const logging = useLogging();
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            useErrorBoundary: true,
            retry: false,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            onError: (error) => {
              if (error instanceof HttpError) {
                logging.error({ error });
              }
              if (process.env.NODE_ENV !== 'production') {
                console.error(error);
              }
            }
          },
          mutations: {
            onError: (error, variables) => {
              if (error instanceof HttpError) {
                logging.error({
                  error,
                  data: {
                    body: variables
                  }
                });
              }
              if (process.env.NODE_ENV !== 'production') {
                console.error(error);
              }
            }
          }
        }
      })
  );

  return queryClient;
}

export function ReactQueryProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const queryClient = useQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
