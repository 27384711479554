/** @jsxImportSource theme-ui */
import * as RadixTooltip from '@radix-ui/react-tooltip';
import React from 'react';

interface ITooltipProps
  extends RadixTooltip.TooltipProps,
    Pick<RadixTooltip.TooltipContentProps, 'side' | 'sideOffset'> {
  content: React.ReactNode;
  isArrowVisible?: boolean;
}

export const Tooltip = React.forwardRef<HTMLButtonElement, ITooltipProps>(function Tooltip(
  {
    open,
    onOpenChange,
    defaultOpen,
    children,
    delayDuration = 200,
    content,

    side = 'right',
    sideOffset = 10,

    isArrowVisible = false
  }: ITooltipProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return (
    <RadixTooltip.Root
      delayDuration={delayDuration}
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
    >
      <RadixTooltip.Trigger ref={ref} asChild>
        {children}
      </RadixTooltip.Trigger>
      <RadixTooltip.Content
        side={side}
        sideOffset={sideOffset}
        sx={{
          variant: 'cards.tooltip'
        }}
      >
        {content}
        {isArrowVisible ? <RadixTooltip.Arrow sx={{ fill: '#cccccc' }} /> : <React.Fragment />}
      </RadixTooltip.Content>
    </RadixTooltip.Root>
  );
});
