import { SelectField } from '@/components/select-field/select-field';
import React from 'react';
import { Flex } from 'theme-ui';
import { NumberField } from '@/components/number-field/number-field';
import { isSurfaceRackingDefault } from '@/features/editor/utils/surface/surface';
import { EditorApi } from '@/features/editor';
import { useEditorContext } from '@/features/editor/stores/use-editor-context';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { Icons } from '@/assets';
import { SolarUtils } from '@/utils/solar-utils';
import { FormattedMessage } from 'react-intl';
import { Box } from '@/components/box';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface IMountingSystemMarginInputsProps {
  surfaceId: string;
}

const MountingSystemMarginInputs = observer(function MountingSystemMarginInputs({
  surfaceId
}: IMountingSystemMarginInputsProps): JSX.Element {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  function updateMargin({ rowMargin, columnMargin }: { rowMargin?: number; columnMargin?: number }): void {
    store.surfaces.surfaceUpdate(surfaceId, (surface) => {
      if (rowMargin) {
        surface.rowMarginMeters = rowMargin;
      }
      if (columnMargin) {
        surface.columnMarginMeters = columnMargin;
      }
    });
  }

  if (!surface) {
    return <React.Fragment />;
  }

  return (
    <Flex sx={{ gap: 2 }}>
      <NumberField
        minValue={0}
        onChange={(value) => updateMargin({ rowMargin: SolarUtils.millimeterToMeter(value) })}
        formatOptions={{
          style: 'unit',
          unit: 'millimeter'
        }}
        value={SolarUtils.meterToMillimeter(surface.rowMarginMeters)}
        label="Row margin"
        icon={<Icons.Rows />}
      />
      <NumberField
        minValue={0}
        onChange={(value) => updateMargin({ columnMargin: SolarUtils.millimeterToMeter(value) })}
        formatOptions={{
          style: 'unit',
          unit: 'millimeter'
        }}
        value={SolarUtils.meterToMillimeter(surface.columnMarginMeters)}
        label="Column margin"
        icon={<Icons.Columns />}
      />
    </Flex>
  );
});

const MountingSystemRackingInputs = observer(function MountingSystemRackingInputs({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  function updateHorizontalCount(count: number) {
    store.surfaces.surfaceUpdate(surfaceId, (surface) => {
      surface.racking.horizontalCount = count;
    });
  }

  function updateVerticalCount(count: number) {
    store.surfaces.surfaceUpdate(surfaceId, (surface) => {
      surface.racking.verticalCount = count;
    });
  }

  function updateVerticalSpace(space: number) {
    store.surfaces.surfaceUpdate(surfaceId, (surface) => {
      surface.racking.verticalSpaceMeters = space;
    });
  }

  function updateHorizontalSpace(space: number) {
    store.surfaces.surfaceUpdate(surfaceId, (surface) => {
      surface.racking.horizontalSpaceMeters = space;
    });
  }

  if (!surface) {
    return <React.Fragment />;
  }

  return (
    <Box>
      <Flex sx={{ gap: 2 }}>
        <NumberField
          minValue={1}
          step={1}
          onChange={(value) => updateVerticalCount(value)}
          value={surface.racking.verticalCount}
          label={<FormattedMessage defaultMessage="Rack height" id="f24/v4" />}
          icon={<Icons.Height />}
          adornment={<FormattedMessage defaultMessage="panels" id="w1ZQTt" />}
        />
        <NumberField
          minValue={1}
          step={1}
          onChange={(value) => updateHorizontalCount(value)}
          value={surface.racking.horizontalCount}
          label={<FormattedMessage defaultMessage="Rack width" id="tU2aJ2" />}
          icon={<Icons.Height sx={{ transform: 'rotate(90deg)' }} />}
          adornment={<FormattedMessage defaultMessage="panels" id="w1ZQTt" />}
        />
      </Flex>

      {!isSurfaceRackingDefault(surface) && (
        <Flex sx={{ gap: 2, mt: 1 }}>
          <NumberField
            minValue={0}
            onChange={(value) => updateVerticalSpace(SolarUtils.millimeterToMeter(value))}
            value={SolarUtils.meterToMillimeter(surface.racking.verticalSpaceMeters)}
            label={<FormattedMessage defaultMessage="Rack row space" id="4NlDQM" />}
            icon={<Icons.Rows />}
            formatOptions={{
              style: 'unit',
              unit: 'millimeter'
            }}
          />
          <NumberField
            minValue={0}
            onChange={(value) => updateHorizontalSpace(SolarUtils.millimeterToMeter(value))}
            value={SolarUtils.meterToMillimeter(surface.racking.horizontalSpaceMeters)}
            label={<FormattedMessage defaultMessage="Rack column space" id="FOgFbx" />}
            icon={<Icons.Columns />}
            formatOptions={{
              style: 'unit',
              unit: 'millimeter'
            }}
          />
        </Flex>
      )}
    </Box>
  );
});

interface ISurfaceMountingSystemInputProps {
  surfaceId: string;
}

export const SurfaceMountingSystemInput = observer(function SurfaceMountingSystemInput({
  surfaceId
}: ISurfaceMountingSystemInputProps): JSX.Element {
  const { calculationId } = useEditorContext();
  const getMountingSystems = EditorApi.useGetMountingSystems({ calculationId });

  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (!surface || isQueryLoading(getMountingSystems.isLoading, getMountingSystems.data)) {
    return <React.Fragment />;
  }

  const options = getMountingSystems.data.map(({ id, name }) => ({
    label: name,
    value: id
  }));
  const value = options.find((option) => option.value === surface.mountingSystemId);

  return (
    <React.Fragment>
      <SelectField
        onChange={(value) => {
          if (value) {
            store.surfaces.surfaceUpdate(surfaceId, (surface) => {
              surface.mountingSystemId = value.value;
            });
          }
        }}
        label="System type"
        name="system-type"
        options={options}
        value={value}
      />
      <MountingSystemMarginInputs surfaceId={surface.id} />
      <MountingSystemRackingInputs surfaceId={surface.id} />
    </React.Fragment>
  );
});
