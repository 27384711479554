/** @jsxImportSource theme-ui */
import { MapTopControls } from '@/features/google-map/components/map-top-controls';
import { Icons } from '@/assets';
import { Button, ButtonProps } from 'theme-ui';
import React from 'react';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';

function UndoRedoButton({
  children,
  onClick,
  disabled
}: Pick<ButtonProps, 'onClick' | 'children' | 'disabled'>) {
  return (
    <Button
      sx={{
        width: 32,
        height: 32,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(4px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: 2,
        borderRadius: 1,
        color: 'text',
        boxShadow: '3dp',

        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 1)'
        },

        '&:disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          pointerEvents: 'none'
        }
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export const MapUndoRedoControls = observer(function MapUndoRedoControls() {
  const store = useEditorStore();

  React.useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      // Handles CTRL modifier for windows and mac
      const isControlModifier = event.ctrlKey || event.metaKey;
      if (event.key.toLowerCase() !== 'z' || !isControlModifier) {
        return;
      }

      if (event.shiftKey) {
        store.redo();
      } else {
        store.undo();
      }
    }

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [store]);

  return (
    <MapTopControls sx={{ left: 32, display: 'flex', gap: 2 }}>
      <UndoRedoButton
        disabled={!store.canUndo}
        onClick={() => {
          store.undo();
        }}
      >
        <Icons.Undo />
      </UndoRedoButton>

      <UndoRedoButton
        disabled={!store.canRedo}
        onClick={() => {
          store.redo();
        }}
      >
        <Icons.Redo />
      </UndoRedoButton>
    </MapTopControls>
  );
});
