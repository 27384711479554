import { SelectField } from '@/components/select-field/select-field';
import React from 'react';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';

interface ISurfacePanelSelectProps {
  surfaceId: string;
  panelId: string;
}

export const SurfacePanelSelect = observer(function SurfacePanelSelect({
  surfaceId,
  panelId
}: ISurfacePanelSelectProps): JSX.Element {
  const store = useEditorStore();
  const panelTypes = store.metadata.panelTypes;
  const formatCurrency = useFormatCurrency();

  const options = panelTypes.map((panel) => ({
    value: panel.productId,
    label: `${panel.name} | ${formatCurrency(panel.price)}`
  }));
  const value = options.find((option) => option.value === panelId);

  return (
    <SelectField
      label="Panel type"
      value={value}
      options={options}
      onChange={(newValue) => {
        if (newValue === null) {
          return;
        }

        store.surfaces.surfaceUpdate(surfaceId, (surface) => {
          surface.panelTypeId = newValue.value;
        });
      }}
      name="panelType"
      id="panelType"
    />
  );
});
