import { IPoint } from '@/utils/gis/types';
import { ISurface } from '@/features/editor/utils/surface/surface';
import { IObstacle } from '@/features/editor/utils/obstacle';
import { IInverter } from '@/features/editor/utils/inverters/inverter';
import { IEditorProjectInformation } from '@/features/project-information/utils/project-information';
import { IMapTypeId, MapTypes } from '@/features/google-map/utils/map-types/map-types';
import { IOverlayImageWithBounds } from '@/features/google-map/utils/map-types/overlay-image-map';
import { IPanelType } from '@/features/editor/utils/panel/panel';

export interface IEditorSurfacesPanelsCache {
  panels: IPoint[][];
  hash: string;
}

interface IEditorMapData {
  mapTypeId: IMapTypeId;
  zoom: number;
  mapCenter: IPoint | undefined;
}

export const editorInitialContext = {
  surfaces: [] as ISurface[],
  obstacles: [] as IObstacle[],
  metadata: {
    defaultPvgisLoss: 0,
    panelTypes: [] as IPanelType[]
  },
  inverters: [] as IInverter[],
  selectedSurfaceId: undefined as string | undefined,
  selectedInverterId: undefined as string | undefined,
  projectInformation: {} as IEditorProjectInformation,
  surfacesPanelsCache: {} as Record<string, IEditorSurfacesPanelsCache>,
  mapData: {
    mapTypeId: MapTypes.defaultMapType.id,
    zoom: 20,
    mapCenter: undefined as IPoint | undefined
  } as IEditorMapData,
  overlayImages: [] as IOverlayImageWithBounds[]
};

export type IEditorContext = typeof editorInitialContext;
export type EditorContext = typeof editorInitialContext;
