/** @jsxImportSource theme-ui */
import esdecSnowZoneSweden from '@/assets/images/esdec-snow-zone-sweden.png';
import { FormattedMessage } from 'react-intl';
import { EsdecWeatherZonesModal, EsdecWeatherZoneTypes } from './esdec-weather-zones-modal';
import { Text } from 'theme-ui';
import { IntlSpace } from '@/features/intl/components/intl-space';

interface IEsdecSnowZoneModal {
  onClose: () => void;
}

export default function EsdecSnowZoneModal({ onClose }: IEsdecSnowZoneModal): JSX.Element {
  return (
    <EsdecWeatherZonesModal
      onClose={onClose}
      title={<FormattedMessage defaultMessage="explanation of snow zones" id="Y89Arj" />}
      subTitle={
        <>
          <FormattedMessage defaultMessage="Snow zones (S" id="/8mM/k" />
          <Text sx={{ verticalAlign: 'sub' }}>
            <FormattedMessage defaultMessage="k" id="KvipEE" />
          </Text>
          <IntlSpace />
          <FormattedMessage defaultMessage="in N/m" id="bWYSS7" />
          <Text sx={{ verticalAlign: 'super' }}>
            <FormattedMessage defaultMessage="2" id="QLJEES" />
          </Text>
          <FormattedMessage defaultMessage=")" id="iZcM81" />
        </>
      }
      imageSrc={esdecSnowZoneSweden}
    >
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Snow Zone 1" id="GGyu5a" />}
        zoneNumber={1000}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Snow Zone 1.5" id="OyBec8" />}
        zoneNumber={1500}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Snow Zone 2" id="9zpX3f" />}
        zoneNumber={2000}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Snow Zone 2.5" id="8MzLGI" />}
        zoneNumber={2500}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Snow Zone 3" id="N1/4ax" />}
        zoneNumber={3000}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Snow Zone 3.5" id="/dZj/S" />}
        zoneNumber={3500}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Snow Zone 4.5" id="GyjLua" />}
        zoneNumber={4500}
      />
      <EsdecWeatherZoneTypes
        zone={<FormattedMessage defaultMessage="Snow Zone 5.5" id="WZpswu" />}
        zoneNumber={5500}
      />
    </EsdecWeatherZonesModal>
  );
}
