/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react';
import { Box } from 'theme-ui';
import { FormattedMessage } from 'react-intl';
import { Icons } from '@/assets';
import { Typography } from '@/components/typography';
import { ButtonSecondary } from '@/components/button/button';
import { EditorResultsCard } from '@/features/editor-results/components/editor-results-card/editor-results-card';

interface IEditorResultsEditCard {
  title: React.ReactNode;
  children: React.ReactNode;
  icon?: React.ReactNode;
}

export function EditorResultsEditCard({ children, title, icon }: IEditorResultsEditCard): JSX.Element {
  return (
    <EditorResultsEditCard.Wrapper>
      <EditorResultsEditCard.Title icon={icon}>{title}</EditorResultsEditCard.Title>
      <EditorResultsEditCard.Content>{children}</EditorResultsEditCard.Content>
    </EditorResultsEditCard.Wrapper>
  );
}

EditorResultsEditCard.Wrapper = function EditableCardWrapper({
  children,
  className
}: PropsWithChildren<{ className?: string }>): JSX.Element {
  return (
    <EditorResultsCard.Wrapper sx={{ px: 12 }} className={className}>
      {children}
    </EditorResultsCard.Wrapper>
  );
};

EditorResultsEditCard.Title = function EditableCardTitle({
  children,
  className,
  icon,
  onEdit
}: PropsWithChildren<{ className?: string; icon?: React.ReactNode; onEdit?: () => void }>): JSX.Element {
  return (
    <Box
      className={className}
      sx={{
        py: 5,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        borderBottom: '1px solid',
        borderColor: 'primary'
      }}
    >
      <Box
        sx={{ backgroundColor: 'menuHover', borderRadius: 5, p: 2, alignItems: 'center', display: 'flex' }}
      >
        {icon}
      </Box>

      <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }} variant="headline1">
        {children}
      </Typography>

      {onEdit ? (
        <ButtonSecondary onClick={onEdit} sx={{ ml: 'auto', color: '#9EA5B8' }}>
          <Icons.EditInfo />
          <FormattedMessage
            defaultMessage="Edit Info"
            id="hO5ADy"
            description="Primary call to action inside an edit card"
          />
        </ButtonSecondary>
      ) : null}
    </Box>
  );
};

EditorResultsEditCard.Content = function EditableCardContent({
  children,
  className
}: PropsWithChildren<{ className?: string }>) {
  return <Box className={className}>{children}</Box>;
};
