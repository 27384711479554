export class SolarUtils {
  public static wattToKilowatt(watt: number): number {
    return watt / 1000;
  }

  public static kilowattToWatt(kilowatt: number): number {
    return kilowatt * 1000;
  }

  public static meterToMillimeter(meter: number): number {
    return meter * 1000;
  }

  public static millimeterToMeter(millimeter: number): number {
    return millimeter / 1000;
  }
}
