import React from 'react';
import * as turf from '@turf/helpers';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import booleanWithin from '@turf/boolean-within';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { themeColors } from '@/lib/theme-ui/colors';
import { panelPolygon } from '@/features/editor/utils/panel/panel';
import { useEditorSelectors } from '@/features/editor/utils/use-editor-selectors';
import { IPoint } from '@/utils/gis/types';
import { getPointFromMouseEvent } from '@/features/google-map/utils/get-point-from-mouse-event';
import { isNil } from 'remeda';
import { MapCircle } from '@/features/google-map/components/shapes/map-circle';
import { MapPolygon } from '@/features/google-map/components/shapes/map-polygon';
import { Gis } from '@/features/editor/utils/gis';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

export const RenderShapeUnderPointer = observer(function RenderShapeUnderPointer() {
  const store = useEditorStore();
  const { map } = useGoogleMapContext();

  const isMeasuring = store.state.isMeasuring;
  const shouldShowCirclePointer =
    isMeasuring ||
    store.state.isCreatingSurface ||
    store.state.isCreatingCustomSurface90 ||
    store.state.isAddingPanel;
  const shouldShowObstaclePointer = store.state.isAddingObstacle;
  const shouldShowPanelPointer = store.state.isAddingPanel;

  const { selectedSurface, selectedPanel } = useEditorSelectors();
  const [pointerPoint, setPointerPoint] = React.useState<IPoint>();

  React.useEffect(() => {
    const listener = map.addListener('mousemove', (event: google.maps.MapMouseEvent) => {
      setPointerPoint(getPointFromMouseEvent(event));
    });

    return () => {
      listener.remove();
    };
  }, [map]);

  function renderCirclePointer(point: IPoint) {
    if (shouldShowCirclePointer) {
      return (
        <MapCircle
          clickable={false}
          point={point}
          fillColor={isMeasuring ? themeColors.ruler : themeColors.creating}
        />
      );
    }
  }

  function renderObstaclePointer(point: IPoint) {
    if (shouldShowObstaclePointer) {
      return <MapCircle clickable={false} point={point} fillColor={themeColors.obstacle} />;
    }
  }

  function renderPanelPointer(point: IPoint) {
    if (shouldShowPanelPointer && selectedPanel !== undefined && selectedSurface !== undefined) {
      const panelPoly = panelPolygon({
        heightMeters: selectedPanel.heightMeters,
        widthMeters: selectedPanel.widthMeters,
        tiltAngle: selectedSurface.tiltAngle,
        topLeft: point,
        rotationAngle: selectedSurface.rotationAngle,
        rotationPoint: point,
        orientation: selectedSurface.orientation
      });
      const surfacePoly = turf.polygon([selectedSurface.path]);
      if (!booleanWithin(panelPoly, surfacePoly)) {
        return null;
      }
      return (
        <MapPolygon
          clickable={false}
          zIndex={GOOGLE_MAP_Z_INDICES.POINTER_SHAPE}
          path={Gis.polygonFeatureToPath(panelPoly)}
          fillColor={themeColors.creating}
          strokeColor={themeColors.creating}
        />
      );
    }
  }

  if (isNil(pointerPoint)) {
    return null;
  }

  return (
    <React.Fragment>
      {renderCirclePointer(pointerPoint)}
      {renderObstaclePointer(pointerPoint)}
      {renderPanelPointer(pointerPoint)}
    </React.Fragment>
  );
});
