import { api } from '@/utils/api';
import {
  RoofDTOPreferredProductEnum,
  ArticleSetDetailsDTOArticleTypeEnum
  // ArticleCategoryArticleTypeEnum
  //RoofPreferredProductEnum
} from '@solel/esdec';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { InferQueryOptions, UnwrapPromise } from '@/utils/types';
import { EsdecApiKeys } from './keys';

const articlesParser = z.array(
  z.object({
    articleNumber: z.string(),
    description: z.string()
  })
);

export async function esdecArticlesApi(
  preferredProduct: RoofDTOPreferredProductEnum,
  articleType: ArticleSetDetailsDTOArticleTypeEnum,
  signal?: AbortSignal
) {
  const response = await api.get(`/esdec/article?product=${preferredProduct}&articleType=${articleType}`, {
    signal
  });
  return articlesParser.parse(response.data);
}
export type IEsdecArticlesApiResponse = UnwrapPromise<ReturnType<typeof esdecArticlesApi>>;

export function useEsdecArticles(
  preferredProduct: RoofDTOPreferredProductEnum,
  articleType: ArticleSetDetailsDTOArticleTypeEnum,
  options?: InferQueryOptions<typeof esdecArticlesApi>
) {
  return useQuery(
    EsdecApiKeys.esdecArticles(preferredProduct, articleType),
    ({ signal }) => esdecArticlesApi(preferredProduct, articleType, signal),
    {
      staleTime: Infinity,
      ...options
    }
  );
}
