import { EditorContext } from '@/features/editor/utils/editor-context';
import { api } from '@/utils/api';
import { R } from '@/lib/remeda';
import { InferMutationOptions } from '@/utils/types';
import { useMutation } from '@tanstack/react-query';

interface ICalculationUpdateApiRequest extends EditorContext {
  calculationId: number;
}

async function calculationUpdateApi(request: ICalculationUpdateApiRequest) {
  const response = await api.post<EditorContext>('/editor/update', {
    ...R.omit(request, ['calculationId']),
    calculationID: request.calculationId
  });

  return response.data;
}

export function useCalculationUpdate(options?: InferMutationOptions<typeof calculationUpdateApi>) {
  return useMutation(calculationUpdateApi, options);
}
