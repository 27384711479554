import * as R from 'remeda';
import { makeAutoObservable } from 'mobx';
import { Base64 } from '@/utils/base64';
import { IVector } from '@/utils/vec';
import { IPoint } from '@/utils/gis/types';
import {
  IMAGE_PIN_MARKER_OFFSET_X,
  IMAGE_PIN_MARKER_OFFSET_Y
} from '@/features/google-map/features/map-overlay-image/map-overlay-image-add-pins';

interface IOverlayImageCurrent {
  src: string;
  opacity: number;
  naturalWidth: number;
  naturalHeight: number;
  offsetX: number;
  offsetY: number;
}

interface IPins<Pin> {
  topLeft: Pin;
  bottomRight: Pin;
}

type OverlayImageStatus = 'idle' | 'add-pins' | 'adjust';

/**
 * An overlay image is an image placed over the map.
 *
 * This store managed the state of a single overlay image, in this case,
 * it is the image that is currently being added to the map.
 * */
export class OverlayImageStore {
  public image?: IOverlayImageCurrent;

  public mapPins?: IPins<IPoint>;

  private _imagePins?: IPins<IVector>;

  public status: OverlayImageStatus = 'idle';

  public constructor() {
    makeAutoObservable(this);
  }

  public initialize = (data: Pick<IOverlayImageCurrent, 'naturalHeight' | 'naturalWidth' | 'src'>) => {
    this.image = {
      ...data,
      offsetY: 0,
      offsetX: 0,
      opacity: 1
    };
    this.status = 'add-pins';
  };

  public finalize = () => {
    this._reset();
  };

  public cancel = () => {
    this._reset();
  };

  public updateImage = (fn: (data: IOverlayImageCurrent) => void) => {
    if (this.image) {
      fn(this.image);
    }
  };

  public updateMapPins = (fn: (old: IPins<IPoint> | undefined) => IPins<IPoint>) => {
    this.mapPins = fn(this.mapPins);
  };

  public updateImagePins = (fn: (old: IPins<IVector> | undefined) => IPins<IVector> | undefined) => {
    this._imagePins = fn(this._imagePins);
  };

  public addPinsDone = () => {
    this.status = 'adjust';
  };

  public addPinsSkip = () => {
    this.status = 'adjust';
  };

  public get isActive() {
    return R.isDefined(this.image);
  }

  public get base64src() {
    if (!this.image) {
      return;
    }

    return Base64.encode(this.image.src, 'png');
  }

  // Get the image pins without any offsets
  public get imagePinsBase() {
    return this._imagePins;
  }

  /**
   * The user drags the pin and expects that they are dragging relative
   * to the bottom center of the image (the pin). By default, however, the
   * pin is relative to the top left corner of the image. This will offset
   * the location of the pin such that it is relative to the bottom center.
   * */
  public get imagePins(): IPins<IVector> | undefined {
    if (!this._imagePins) {
      return undefined;
    }

    return {
      topLeft: {
        x: this._imagePins.topLeft.x + IMAGE_PIN_MARKER_OFFSET_X,
        y: this._imagePins.topLeft.y + IMAGE_PIN_MARKER_OFFSET_Y
      },
      bottomRight: {
        x: this._imagePins.bottomRight.x + IMAGE_PIN_MARKER_OFFSET_X,
        y: this._imagePins.bottomRight.y + IMAGE_PIN_MARKER_OFFSET_Y
      }
    };
  }

  private _reset() {
    this.image = undefined;
    this._imagePins = undefined;
    this.mapPins = undefined;
    this.status = 'idle';
  }
}
