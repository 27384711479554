/** @jsxImportSource theme-ui */
import React from 'react';
import { useGesture, UserHandlers } from '@use-gesture/react';
import { R } from '@/lib/remeda';
import { radiansToDegrees } from '@turf/helpers';
import { IRect, IVector } from '@/utils/vec';
import { allowedDragDirections } from '@/features/google-map/features/map-overlay-image/allowed-drag-directions';

function snap(num: number, to: number): number {
  return Math.round(num / to) * to;
}

/**
 * Given a control (ex. a button) and a target (ex. a draggable element), this
 * hook will calculate the angle continuously as the user click + drags the control.
 * */
function useRotationControl(controlElement: HTMLElement, targetElement: HTMLImageElement): { angle: number } {
  const [angleBase, setAngleBase] = React.useState(0);
  const [angleInProgress, setAngleInProgress] = React.useState(0);

  const $rotationPivot = React.useRef<IVector>();
  const $rotationStart = React.useRef<IVector>();

  useGesture(
    {
      onDrag: ({ xy: [x, y], event }) => {
        const rotationPivot = $rotationPivot.current;
        const rotationStart = $rotationStart.current;
        if (R.isNil(rotationPivot) || R.isNil(rotationStart)) {
          return;
        }
        event.stopPropagation();

        const newAngle = radiansToDegrees(Math.atan2(y - rotationPivot.y, x - rotationPivot.x));
        // Normalize the angle such that the starting position is 0 degrees
        const startOffsetAngle = radiansToDegrees(
          Math.atan2(rotationStart.y - rotationPivot.y, rotationStart.x - rotationPivot.x)
        );

        const result = newAngle - startOffsetAngle;
        setAngleInProgress(event.shiftKey ? snap(result, 15) : result);
      },
      onDragStart: ({ xy: [x, y] }) => {
        $rotationPivot.current = {
          x: targetElement.x + targetElement.offsetWidth / 2,
          y: targetElement.y + targetElement.offsetHeight / 2
        };
        $rotationStart.current = { x, y };
      },
      onDragEnd: () => {
        $rotationPivot.current = undefined;
        $rotationStart.current = undefined;
        setAngleBase((angleBase + angleInProgress) % 360);
        setAngleInProgress(0);
      }
    },
    {
      target: controlElement
    }
  );

  return { angle: angleBase + angleInProgress };
}

interface IRotationButtonProps {
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

const RotationButton = React.forwardRef(function RotationButton(
  props: IRotationButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  function getPositionStyle(): React.CSSProperties {
    const cornerOffset = -12;
    switch (props.position) {
      case 'top-left':
        return {
          top: `${cornerOffset}px`,
          left: `${cornerOffset}px`
        };
      case 'top-right':
        return {
          top: `${cornerOffset}px`,
          right: `${cornerOffset}px`
        };
      case 'bottom-left':
        return {
          bottom: `${cornerOffset}px`,
          left: `${cornerOffset}px`
        };
      case 'bottom-right':
        return {
          bottom: `${cornerOffset}px`,
          right: `${cornerOffset}px`
        };
    }
  }

  return (
    <button
      ref={ref}
      style={{
        opacity: 0,
        touchAction: 'none',
        position: 'absolute',
        cursor: 'grab',
        width: 12,
        height: 10,
        border: 'none',
        ...getPositionStyle()
      }}
    />
  );
});

const CornerResizeButton = React.forwardRef(function CornerResizeButton(
  props: IRotationButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  function getPositionStyle(): React.CSSProperties {
    const cornerOffset = -4;
    switch (props.position) {
      case 'top-left':
        return {
          top: `${cornerOffset}px`,
          left: `${cornerOffset}px`,
          cursor: 'nwse-resize'
        };
      case 'top-right':
        return {
          top: `${cornerOffset}px`,
          right: `${cornerOffset}px`,
          cursor: 'nesw-resize'
        };
      case 'bottom-left':
        return {
          bottom: `${cornerOffset}px`,
          left: `${cornerOffset}px`,
          cursor: 'nesw-resize'
        };
      case 'bottom-right':
        return {
          bottom: `${cornerOffset}px`,
          right: `${cornerOffset}px`,
          cursor: 'nwse-resize'
        };
    }
  }

  return (
    <button
      ref={ref}
      style={{
        border: 'none',
        touchAction: 'none',
        position: 'absolute',
        width: 8,
        height: 8,
        ...getPositionStyle()
      }}
    />
  );
});

interface ISideResizeButtonProps {
  position: 'top' | 'bottom' | 'left' | 'right';
}

const SideResizeButton = React.forwardRef(function SideResizeButton(
  props: ISideResizeButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  function getPositionStyle(): React.CSSProperties {
    const size = 6;

    switch (props.position) {
      case 'top':
        return {
          top: -size,
          height: size,
          width: '100%',
          cursor: 'ns-resize'
        };
      case 'bottom':
        return {
          bottom: -size,
          height: size,
          width: '100%',
          cursor: 'ns-resize'
        };
      case 'left':
        return {
          left: -size,
          height: '100%',
          width: size,
          cursor: 'ew-resize'
        };
      case 'right':
        return {
          right: -size,
          height: '100%',
          width: size,
          cursor: 'ew-resize'
        };
    }
  }

  return (
    <button
      ref={ref}
      style={{
        position: 'absolute',
        touchAction: 'none',
        opacity: 0,
        ...getPositionStyle()
      }}
    />
  );
});

function zoomOutIn(
  old: IRect,
  xDelta: number,
  yDelta: number,
  xD: IVector,
  scaled: number | undefined
): IRect {
  if (scaled && scaled > 1) {
    return {
      top: old!.top + xD.y,
      left: old!.left + xD.x,
      bottom: old!.bottom + xD.y,
      right: old!.right + xD.x,
      width: old!.width,
      height: old!.height
    };
  } else {
    return {
      top: old!.top + yDelta,
      left: old!.left + xDelta,
      bottom: old!.bottom + yDelta,
      right: old!.right + xDelta,
      width: old!.width,
      height: old!.height
    };
  }
}

function getModifiedRect(
  originalRect: IRect,
  d: IVector,
  control: IRotationButtonProps['position'] | ISideResizeButtonProps['position'] | 'center',
  {
    isAspectRatioLocked
  }: {
    isAspectRatioLocked: boolean;
  } = {
    isAspectRatioLocked: false
  }
): IRect {
  if (control === 'center') {
    return {
      ...originalRect,
      top: originalRect.top + d.y,
      left: originalRect.left + d.x,
      bottom: originalRect.bottom + d.y,
      right: originalRect.right + d.x,
      width: originalRect.right - originalRect.left,
      height: originalRect.bottom - originalRect.top
    };
  }

  const r = R.clone(originalRect);

  switch (control) {
    case 'top':
    case 'top-left':
    case 'top-right':
      r.top += d.y;
      break;
    case 'bottom':
    case 'bottom-left':
    case 'bottom-right':
      r.bottom += d.y;
      break;
  }

  switch (control) {
    case 'left':
    case 'top-left':
    case 'bottom-left':
      r.left += d.x;
      break;
    case 'right':
    case 'top-right':
    case 'bottom-right':
      r.right += d.x;
      break;
  }

  const aw = originalRect.right - originalRect.left;
  const ah = originalRect.bottom - originalRect.top;

  const scaleX = (r.right - r.left) / aw;
  const scaleY = (r.top - r.bottom) / ah;

  const bw = Math.abs(r.right - r.left);
  const bh = Math.abs(r.top - r.bottom);

  // Adjust the rect to keep the aspect ratio (width / height) constant
  if (isAspectRatioLocked) {
    const aspectRatio = aw / ah;
    const isTall = aspectRatio < r.width / r.height;
    const tw = bw * (scaleY < 0 ? 1 : -1) * (1 / aspectRatio);
    const th = bh * (scaleX < 0 ? 1 : -1) * aspectRatio;

    switch (control) {
      case 'top-left': {
        if (isTall) r.bottom = r.top + tw;
        else r.left = r.right + th;
        break;
      }
      case 'top-right': {
        if (isTall) r.bottom = r.top + tw;
        else r.right = r.left - th;
        break;
      }
      case 'bottom-right': {
        if (isTall) r.top = r.bottom - tw;
        else r.right = r.left - th;
        break;
      }
      case 'bottom-left': {
        if (isTall) r.top = r.bottom - tw;
        else r.left = r.right + th;
        break;
      }
      case 'bottom':
      case 'top': {
        const m = (r.left + r.right) / 2;
        const w = bh * aspectRatio;
        r.left = m - w / 2;
        r.right = m + w / 2;
        break;
      }
      case 'left':
      case 'right': {
        const m = (r.top + r.bottom) / 2;
        const h = bw / aspectRatio;
        r.top = m - h / 2;
        r.bottom = m + h / 2;
        break;
      }
    }
  }

  return {
    right: r.right,
    bottom: r.bottom,
    left: r.left,
    top: r.top,
    width: r.right - r.left,
    height: r.bottom - r.top
  };
}

interface IImagePositioning {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

interface IEditableImageResult {
  // The x coordinate of the element's top left corner
  x: number;
  // The y coordinate of the element's top left corner
  y: number;
  // The width of the element
  offsetWidth: number;
  // The height of the element
  offsetHeight: number;
  // The width of the element's _bounding box_
  boundingWidth: number;
  // The height of the element's _bounding box_
  boundingHeight: number;
  // The rotation angle of the image in degrees
  angleDegrees: number;
  // The source of the image
  src: string;
}

interface IEditableImageProps {
  // If you want the image to be disallowed from being dragged
  // outside a certain area, you can specify the bounds here
  bounds?: Pick<IRect, 'width' | 'height'>;

  // Is the image's size editable?
  isEditable?: boolean;
  defaultPositioning?: IImagePositioning;
  defaultRotationAngle?: number;
  opacity?: number;
  isLoading?: boolean;
  className?: string;
  src: string;
  children?: (result: IEditableImageResult) => React.ReactNode;
  onDrag?: UserHandlers['onDrag'];
  scaled?: number;
}

function EditableImage({
  children,
  src,
  isLoading = false,
  opacity = 0.5,
  defaultRotationAngle = 0,
  defaultPositioning,
  className,
  isEditable = true,
  bounds,
  onDrag,
  scaled
}: IEditableImageProps): JSX.Element {
  const $imageWrapper = React.useRef<HTMLDivElement | null>(null);
  const $image = React.useRef<HTMLImageElement | null>(null);

  const $topLeftRotateControl = React.useRef<HTMLButtonElement | null>(null);
  const topLeftRotate = useRotationControl($topLeftRotateControl.current!, $image.current!);
  const $bottomRightRotateControl = React.useRef<HTMLButtonElement | null>(null);
  const bottomRightRotate = useRotationControl($bottomRightRotateControl.current!, $image.current!);
  const $topRightRotateControl = React.useRef<HTMLButtonElement | null>(null);
  const topRightRotate = useRotationControl($topRightRotateControl.current!, $image.current!);
  const $bottomLeftRotateControl = React.useRef<HTMLButtonElement | null>(null);
  const bottomLeftRotate = useRotationControl($bottomLeftRotateControl.current!, $image.current!);

  const $topLeft = React.useRef<HTMLButtonElement>(null);
  const $topRight = React.useRef<HTMLButtonElement>(null);
  const $bottomRight = React.useRef<HTMLButtonElement>(null);
  const $bottomLeft = React.useRef<HTMLButtonElement>(null);
  const $bottom = React.useRef<HTMLButtonElement>(null);
  const $top = React.useRef<HTMLButtonElement>(null);
  const $left = React.useRef<HTMLButtonElement>(null);
  const $right = React.useRef<HTMLButtonElement>(null);

  const [positioning, setPositioning] = React.useState<IImagePositioning | undefined>(defaultPositioning);

  useGesture(
    {
      onDrag: ({ delta: [x, y], ...rest }) => {
        if (isLoading) {
          return;
        }

        if (R.isNil(bounds)) {
          onDrag?.({ delta: [x, y], ...rest });
          return setPositioning((old) => ({
            top: old!.top + y,
            left: old!.left + x,
            bottom: old!.bottom + y,
            right: old!.right + x,
            width: old!.width,
            height: old!.height
          }));
        }

        const allowed = allowedDragDirections({
          bounds,
          rect: positioning!,
          delta: [x, y]
        });

        const xDelta = allowed.x ? x : 0;
        const yDelta = allowed.y ? y : 0;

        onDrag?.({ delta: [xDelta, yDelta], ...rest });
        // setPositioning((old) => ({
        //   top: old!.top + yDelta,
        //   left: old!.left + xDelta,
        //   bottom: old!.bottom + yDelta,
        //   right: old!.right + xDelta,
        //   width: old!.width,
        //   height: old!.height
        // }));

        setPositioning((old) => (old ? zoomOutIn(old, xDelta, yDelta, { x, y }, scaled) : undefined));

        console.log(positioning);
      }
    },
    {
      target: $imageWrapper
    }
  );

  const finalAngle =
    (topLeftRotate.angle +
      bottomRightRotate.angle +
      topRightRotate.angle +
      bottomLeftRotate.angle +
      defaultRotationAngle) %
    360;

  useGesture(
    {
      onDrag: ({ delta: [x, y], event }) => {
        event.stopPropagation();
        setPositioning((old) =>
          old
            ? getModifiedRect(old, { x, y }, 'top-left', {
                isAspectRatioLocked: event.shiftKey
              })
            : undefined
        );
      }
    },
    {
      target: $topLeft
    }
  );

  useGesture(
    {
      onDrag: ({ delta: [x, y], event }) => {
        event.stopPropagation();
        setPositioning((old) =>
          old
            ? getModifiedRect(old, { x, y }, 'top-right', {
                isAspectRatioLocked: event.shiftKey
              })
            : undefined
        );
      }
    },
    {
      target: $topRight
    }
  );

  useGesture(
    {
      onDrag: ({ delta: [x, y], event }) => {
        event.stopPropagation();
        setPositioning((old) =>
          old
            ? getModifiedRect(old, { x, y }, 'bottom-left', {
                isAspectRatioLocked: event.shiftKey
              })
            : undefined
        );
      }
    },
    {
      target: $bottomLeft
    }
  );

  useGesture(
    {
      onDrag: ({ delta: [x, y], event }) => {
        event.stopPropagation();
        setPositioning((old) =>
          old
            ? getModifiedRect(old, { x, y }, 'bottom-right', {
                isAspectRatioLocked: event.shiftKey
              })
            : undefined
        );
      }
    },
    {
      target: $bottomRight
    }
  );

  useGesture(
    {
      onDrag: ({ delta: [x, y], event }) => {
        event.stopPropagation();
        setPositioning((old) =>
          old
            ? getModifiedRect(old, { x, y }, 'left', {
                isAspectRatioLocked: event.shiftKey
              })
            : undefined
        );
      }
    },
    {
      target: $left
    }
  );

  useGesture(
    {
      onDrag: ({ delta: [x, y], event }) => {
        event.stopPropagation();
        setPositioning((old) =>
          old
            ? getModifiedRect(old, { x, y }, 'right', {
                isAspectRatioLocked: event.shiftKey
              })
            : undefined
        );
      }
    },
    {
      target: $right
    }
  );

  useGesture(
    {
      onDrag: ({ delta: [x, y], event }) => {
        event.stopPropagation();
        setPositioning((old) =>
          old
            ? getModifiedRect(old, { x, y }, 'bottom', {
                isAspectRatioLocked: event.shiftKey
              })
            : undefined
        );
      }
    },
    {
      target: $bottom
    }
  );

  useGesture(
    {
      onDrag: ({ delta: [x, y], event }) => {
        event.stopPropagation();
        setPositioning((old) =>
          old
            ? getModifiedRect(old, { x, y }, 'top', {
                isAspectRatioLocked: event.shiftKey
              })
            : undefined
        );
      }
    },
    {
      target: $top
    }
  );

  const getResult = React.useCallback((): IEditableImageResult | undefined => {
    if (R.isNil($image.current) || R.isNil(positioning)) {
      return undefined;
    }

    const boundingRect = $image.current.getBoundingClientRect();

    return {
      x: positioning.left,
      y: positioning.top,
      offsetWidth: positioning.width,
      offsetHeight: positioning.height,
      boundingHeight: boundingRect.height,
      boundingWidth: boundingRect.width,
      angleDegrees: finalAngle,
      src
    };
  }, [finalAngle, positioning, src]);

  function renderChildren() {
    const result = getResult();
    if (!result || !children) {
      return null;
    }
    return children(result);
  }

  return (
    <React.Fragment>
      <div
        ref={$imageWrapper}
        className={className}
        style={{
          position: 'absolute',
          touchAction: 'none',
          isolation: 'isolate',

          top: 0,
          left: 0,

          ...(R.isDefined(positioning)
            ? {
                width: positioning.width,
                height: positioning.height,
                right: positioning.right,
                bottom: positioning.bottom,
                left: positioning.left,
                top: positioning.top
              }
            : {}),

          transform: `rotate(${finalAngle}deg)`
        }}
      >
        <img
          ref={$image}
          onLoad={(event) => {
            const target = event.target as HTMLImageElement | undefined;
            if (R.isNil(target)) {
              return;
            }
            setPositioning((old) => {
              if (old) {
                return old;
              }

              return {
                top: 0,
                left: 0,
                bottom: target.naturalHeight,
                right: target.naturalWidth,
                height: target.naturalHeight,
                width: target.naturalWidth
              };
            });
          }}
          draggable="false"
          style={{
            opacity,
            position: 'absolute',
            top: 0,
            left: 0,
            width: !isEditable ? 'auto' : '100%',
            height: !isEditable ? '600px' : '100%'
          }}
          src={src}
          alt=""
        />

        {!isLoading && isEditable && (
          <React.Fragment>
            <SideResizeButton position="top" ref={$top} />
            <SideResizeButton position="left" ref={$left} />
            <SideResizeButton position="bottom" ref={$bottom} />
            <SideResizeButton position="right" ref={$right} />

            <CornerResizeButton position="bottom-right" ref={$bottomRight} />
            <CornerResizeButton position="bottom-left" ref={$bottomLeft} />
            <CornerResizeButton position="top-left" ref={$topLeft} />
            <CornerResizeButton position="top-right" ref={$topRight} />

            <RotationButton position="top-left" ref={$topLeftRotateControl} />
            <RotationButton position="bottom-right" ref={$bottomRightRotateControl} />
            <RotationButton position="top-right" ref={$topRightRotateControl} />
            <RotationButton position="bottom-left" ref={$bottomLeftRotateControl} />
          </React.Fragment>
        )}

        {renderChildren()}
      </div>
    </React.Fragment>
  );
}

export { EditableImage };
