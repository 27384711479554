/** @jsxImportSource theme-ui */

import React, { PropsWithChildren } from 'react';
import { ButtonProps, Text } from 'theme-ui';
import { alpha, transparentize } from '@theme-ui/color';
import { Box } from '@/components/box';
import { motion } from '@/utils/motion';

const CHIP_STYLES = {
  base: {
    fontFamily: 'body',
    fontSize: `${14 / 16}rem`,
    border: '1px solid',
    borderColor: 'inputBorder',
    color: transparentize('inputText', 0.3),
    backgroundColor: 'transparent',
    borderRadius: 'chip',
    transition: `border-color ${motion('standard', 'productive')} 150ms, background-color ${motion(
      'standard',
      'productive'
    )} 100ms`,

    '&:hover': {
      backgroundColor: alpha('#E0E0E0', 0.2)
    }
  },
  active: {
    borderColor: 'primary',
    color: 'primary',
    backgroundColor: alpha('primary', 0.1),
    fontFamily: 'body',
    '&:hover': {}
  },
  muted: {
    borderColor: alpha('primary', 0.1)
  }
};

export interface IChipBaseProps extends ButtonProps {
  icon?: React.ReactNode;
  iconAppend?: React.ReactNode;
  isActive?: boolean;
  isMuted?: boolean;
}

const ChipBase = React.forwardRef<HTMLButtonElement, PropsWithChildren<IChipBaseProps>>(function ChipBase(
  { icon, iconAppend, children, isActive, isMuted, ...props }: PropsWithChildren<IChipBaseProps>,
  ref: React.ForwardedRef<HTMLButtonElement>
): JSX.Element {
  return (
    <button
      ref={ref}
      sx={{
        ...CHIP_STYLES.base,
        ...(isActive ? CHIP_STYLES.active : {}),
        ...(isMuted ? CHIP_STYLES.muted : {})
      }}
      {...props}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {icon}
        <Text>{children}</Text>
        {iconAppend}
      </Box>
    </button>
  );
});

const ChipSmall = React.forwardRef<HTMLButtonElement, PropsWithChildren<IChipBaseProps>>(function ChipSmall(
  props: PropsWithChildren<IChipBaseProps>,
  ref: React.ForwardedRef<HTMLButtonElement>
): JSX.Element {
  return <ChipBase ref={ref} {...props} sx={{ py: '1px', px: 3 }} />;
});

const ChipLarge = React.forwardRef<HTMLButtonElement, PropsWithChildren<IChipBaseProps>>(function ChipLarge(
  props: PropsWithChildren<IChipBaseProps>,
  ref: React.ForwardedRef<HTMLButtonElement>
): JSX.Element {
  return <ChipBase ref={ref} {...props} sx={{ py: 2, px: 3 }} />;
});

export { ChipSmall, ChipLarge };
