import { createContext } from '@/utils/create-context';
import React, { PropsWithChildren } from 'react';

interface IEditorContext {
  calculationId: number;
}

const [useEditorContext, EditorContextProviderInner] = createContext<IEditorContext>();

function EditorContextProvider({
  children,
  calculationId
}: PropsWithChildren<{ calculationId: number }>): JSX.Element {
  return <EditorContextProviderInner value={{ calculationId }}>{children}</EditorContextProviderInner>;
}

export { useEditorContext, EditorContextProvider };
