import { nn } from '@/utils/invariant';
import { IVector } from '@/utils/vec';

// TODO: Rename all "pixel coord" references to "vector", and change the types to "IVector" instead of "google.maps.Point"

/**
 * Utils for working with Google Map projections/coordinate system conversions.
 * https://developers.google.com/maps/documentation/javascript/coordinates
 * */
class GoogleMapCoords {
  public static worldCoordToVector(coord: google.maps.Point, zoom: number): google.maps.Point {
    const scale = Math.pow(2, zoom);
    return new google.maps.Point(Math.floor(coord.x * scale), Math.floor(coord.y * scale));
  }

  public static tileCoordToWorldCoord(
    coord: google.maps.Point,
    zoom: number,
    size: google.maps.Size = new google.maps.Size(256, 256)
  ): google.maps.Point {
    const scale = Math.pow(2, zoom);
    return new google.maps.Point((coord.x * size.width) / scale, (coord.y * size.height) / scale);
  }

  public static tileCoordToPixelCoord(coord: google.maps.Point, zoom: number): google.maps.Point {
    return GoogleMapCoords.worldCoordToVector(GoogleMapCoords.tileCoordToWorldCoord(coord, zoom), zoom);
  }

  // Takes a pixel coordinate on the screen and converts it to a world coordinate on the map
  public static vectorToWorldPoint(
    vector: IVector,
    projection: google.maps.Projection,
    bounds: google.maps.LatLngBounds,
    zoom: number
  ): google.maps.Point {
    const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
    const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
    const scale = Math.pow(2, zoom);
    return new google.maps.Point(
      vector.x / scale + (bottomLeft?.x ?? 0),
      vector.y / scale + (topRight?.y ?? 0)
    );
  }

  public static worldPointToScreenPixel(worldPoint: google.maps.Point, map: google.maps.Map) {
    const projection = map.getProjection();
    const bounds = map.getBounds();
    const zoom = map.getZoom();

    nn(projection);
    nn(bounds);
    nn(zoom);

    const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
    const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
    const scale = Math.pow(2, zoom);

    return new google.maps.Point(
      (worldPoint.x - (bottomLeft?.x ?? 0)) * scale,
      (worldPoint.y - (topRight?.y ?? 0)) * scale
    );
  }

  public static latLngToVector(latLng: google.maps.LatLng, map: google.maps.Map): IVector {
    const projection = map.getProjection();
    const bounds = map.getBounds();
    const zoom = map.getZoom();

    nn(projection);
    nn(bounds);
    nn(zoom);

    const pixel = GoogleMapCoords.worldPointToScreenPixel(projection.fromLatLngToPoint(latLng)!, map);
    return {
      x: pixel.x,
      y: pixel.y
    };
  }

  public static vectorToLatLng(vector: IVector, map: google.maps.Map): google.maps.LatLng {
    const projection = map.getProjection();
    const bounds = map.getBounds();
    const zoom = map.getZoom();

    nn(projection);
    nn(bounds);
    nn(zoom);

    const worldPoint = GoogleMapCoords.vectorToWorldPoint(
      new google.maps.Point(vector.x, vector.y),
      projection,
      bounds,
      zoom
    );
    const result = projection.fromPointToLatLng(worldPoint);
    nn(result);
    return result;
  }

  // Converts a tile coordinate (top left corner of a tile) to a lat, lng
  public static tileCoordToLatLng(
    coord: google.maps.Point,
    projection: google.maps.Projection,
    zoom: number
  ): google.maps.LatLng | undefined {
    return projection.fromPointToLatLng(GoogleMapCoords.tileCoordToWorldCoord(coord, zoom)) ?? undefined;
  }

  // Takes a tile coordinate (top left corner of a tile) and returns the center point of that tile
  public static tileCenter(tileCoord: google.maps.Point): google.maps.Point {
    return new google.maps.Point(tileCoord.x + 0.5, tileCoord.y + 0.5);
  }
}

export { GoogleMapCoords };
