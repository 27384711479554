import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { EditorResultsCardPrintable } from '@/features/editor-results/components/editor-results-card/editor-results-card-printable';
import { IconsPdf } from '@/assets';
import { EmptyPropsWithChildren } from '@/utils/types';
import { ICalculationAccessoriesApiResponse, IGetAllProductsApiResponse } from '@/features/editor/api';
import { useFilteredAdditionsData } from '@/features/editor-results/utils/use-filtered-additions-data';

function Title({ children }: EmptyPropsWithChildren): JSX.Element {
  return (
    <View style={{ backgroundColor: '#F5F5F5', paddingHorizontal: 20, paddingVertical: 7 }}>
      <Text style={{ color: '#13AB62', textTransform: 'capitalize', fontSize: 9, fontWeight: 500 }}>
        {children}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  label: { color: '#667085', fontSize: 8, marginBottom: 5 },
  value: { color: '#303540', fontSize: 9, fontWeight: 500, marginBottom: 5 },
  container: { paddingHorizontal: 10, paddingVertical: 8 }
});

function EditorResultsAdditionsPrintable({
  allProducts,
  calculationAccessories
}: {
  allProducts: IGetAllProductsApiResponse;
  calculationAccessories: ICalculationAccessoriesApiResponse;
}): JSX.Element {
  const formatCurrency = useFormatCurrency();

  const { batteriesGroupedBy, wallBoxGroupedBy, energyStorage, chargingBox } = useFilteredAdditionsData({
    allProducts,
    calculationAccessories
  });

  if (energyStorage.length === 0 && chargingBox.length === 0) {
    return <React.Fragment />;
  }

  return (
    <EditorResultsCardPrintable.Wrapper>
      <EditorResultsCardPrintable.Title>
        <IconsPdf.CostsForConstruction />
        <Text style={{ marginLeft: 8 }}>
          <FormattedMessage defaultMessage="Energy storage and charging box cost" id="yWMUI4" />
        </Text>
      </EditorResultsCardPrintable.Title>

      <View style={{ paddingBottom: 10 }}>
        {energyStorage.length > 0 ? (
          <React.Fragment>
            <Title>
              <FormattedMessage defaultMessage="Energy storage" id="qxnkFh" />
            </Title>
            <View style={{ flexDirection: 'row', marginBottom: 5 }}>
              <View style={styles.container}>
                <Text style={styles.label}>
                  <FormattedMessage defaultMessage="Name" id="HAlOn1" />
                </Text>
                {energyStorage.map((battery, index) => (
                  <Text style={styles.value} key={index}>
                    {battery.name_battery}
                  </Text>
                ))}
              </View>
              <View style={styles.container}>
                <Text style={styles.label}>
                  <FormattedMessage defaultMessage="Sell price" id="Fsk3WQ" />
                </Text>
                {energyStorage.map((battery, index) => (
                  <Text style={styles.value} key={index}>
                    {formatCurrency(battery.sellprice)}
                  </Text>
                ))}
              </View>
              <View style={styles.container}>
                <Text style={styles.label}>
                  <FormattedMessage defaultMessage="Quantity" id="qVGRIE" />
                </Text>
                {energyStorage.map((battery, index) => (
                  <Text style={styles.value} key={index}>
                    {batteriesGroupedBy[battery.info_id]?.length ?? 0}
                  </Text>
                ))}
              </View>
              <View style={styles.container}>
                <Text style={styles.label}>
                  <FormattedMessage defaultMessage="Total price" id="4VivsY" />
                </Text>
                {energyStorage.map((battery, index) => (
                  <Text style={styles.value} key={index}>
                    {formatCurrency(battery.sellprice * (batteriesGroupedBy[battery.info_id]?.length ?? 0))}
                  </Text>
                ))}
              </View>
            </View>
          </React.Fragment>
        ) : null}

        {chargingBox.length > 0 ? (
          <React.Fragment>
            <Title>
              <FormattedMessage defaultMessage="Charging box" id="/juEsH" />
            </Title>
            <View style={{ flexDirection: 'row', marginBottom: 5 }}>
              <View style={styles.container}>
                <Text style={styles.label}>
                  <FormattedMessage defaultMessage="Name" id="HAlOn1" />
                </Text>
                {chargingBox.map((wallbox, index) => (
                  <Text style={styles.value} key={index}>
                    {wallbox.name_wallbox}
                  </Text>
                ))}
              </View>
              <View style={styles.container}>
                <Text style={styles.label}>
                  <FormattedMessage defaultMessage="Sell price" id="Fsk3WQ" />
                </Text>
                {chargingBox.map((wallbox, index) => (
                  <Text style={styles.value} key={index}>
                    {formatCurrency(wallbox.sellprice)}
                  </Text>
                ))}
              </View>
              <View style={styles.container}>
                <Text style={styles.label}>
                  <FormattedMessage defaultMessage="Quantity" id="qVGRIE" />
                </Text>
                {chargingBox.map((wallbox, index) => (
                  <Text style={styles.value} key={index}>
                    {wallBoxGroupedBy[wallbox.info_id]?.length ?? 0}
                  </Text>
                ))}
              </View>
              <View style={styles.container}>
                <Text style={styles.label}>
                  <FormattedMessage defaultMessage="Total price" id="4VivsY" />
                </Text>
                {chargingBox.map((wallbox, index) => (
                  <Text style={styles.value} key={index}>
                    {formatCurrency(wallbox.sellprice * (wallBoxGroupedBy[wallbox.info_id]?.length ?? 0))}
                  </Text>
                ))}
              </View>
            </View>
          </React.Fragment>
        ) : null}
      </View>
    </EditorResultsCardPrintable.Wrapper>
  );
}

export { EditorResultsAdditionsPrintable };
