import { EditorContext } from '@/features/editor/utils/editor-context';
import { IUpdateEditorContextApiRequest } from '@/features/editor/api/update-editor-context';
import { R } from '@/lib/remeda';
import { distanceVincenty } from '@/utils/gis/distance-vincenty';
import { EditorApi } from '@/features/editor';
import { useDebouncedCallback } from 'use-debounce';
import React from 'react';
import { SurfacePanelsMap, useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { deepEqual } from '@/utils/deep-equal';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { getSurfaceArea } from '@/features/editor/utils/surface/get-surface-area';
import { useOptimizers } from '@/features/editor/utils/optimizers/use-optimizers';
import { autorun, toJS } from 'mobx';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

function computePayload(
  context: EditorContext,
  {
    surfacePanels,
    surfaces,
    calculationId,
    optimizers
  }: {
    surfacePanels: SurfacePanelsMap;
    surfaces: ReturnType<typeof useSurfaces>;
    optimizers: ReturnType<typeof useOptimizers>;
    calculationId: number;
  }
): IUpdateEditorContextApiRequest {
  return R.omit(
    {
      ...context,
      calculationId,
      surfaces: surfaces.map((surface) => ({
        ...R.omit(surface, ['ridgeLine', 'sides', 'panel']),
        optimizerPoints: optimizers[surface.id]?.map((o) => o.optimizer) ?? [],
        computed: {
          areaMeters: getSurfaceArea(surface),
          ridgeLineLengthMeters: R.isNil(surface.ridgeLine)
            ? 0
            : distanceVincenty(surface.ridgeLine.start, surface.ridgeLine.end),
          panelsCount: surfacePanels[surface.id]?.length ?? 0
        }
      }))
    } as const,
    ['metadata']
  );
}

// Does a delayed update to the server whenever the editor context changes
export function useSyncEditorContext({ calculationId }: { calculationId: number }): void {
  const store = useEditorStore();
  const electricityPrices = store.projectInformation.electricityPrices;
  const saveEditorContext = EditorApi.useUpdateEditorContext({
    onSuccess: (data) => {
      /**
       * The electricity prices change (VAT applied or not) whenever
       * the project is for a private person or a company.
       *
       * The server calculates the prices on every update, so when
       * the user changes the project type, the prices will change.
       * */
      if (
        R.isDefined(data.projectInformation) &&
        !deepEqual(data.projectInformation.electricityPrices, electricityPrices)
      ) {
        store.projectInformation.update((old) => {
          old.electricityPrices = data.projectInformation.electricityPrices;
        });
      }
      store.surfaces.surfaces = data.surfaces;
    }
  });
  const saveEditorContextDebounced = useDebouncedCallback(saveEditorContext.mutate, 1000);

  const $prevPayload = React.useRef<Omit<EditorContext, 'metadata'>>();

  /**
   * Since the calculation of the panels runs in web workers (threads), a race condition
   * can arise where the call to sync the editor context is made with an old value of the panels.
   *
   * To avoid this, we store the latest context in this ref and use a separate effect to
   * sync the context whenever the panels finish computing.
   * */
  const $latestContext = React.useRef<EditorContext>();

  const { surfacePanels, isLoadingPanels } = useSurfacePanels();
  const surfaces = useSurfaces();
  const optimizers = useOptimizers();

  React.useEffect(() => {
    const disposer = autorun(() => {
      const context = {
        surfaces: toJS(store.surfaces.surfaces),
        mapData: toJS(store.mapData),
        metadata: toJS(store.metadata),
        inverters: toJS(store.inverters.inverters),
        obstacles: toJS(store.obstacles.obstacles),
        projectInformation: toJS(store.projectInformation),
        overlayImages: toJS(store.overlayImages.overlayImages),
        selectedInverterId: toJS(store.inverters.selectedInverterId),
        selectedSurfaceId: toJS(store.surfaces.selectedSurfaceId),
        surfacesPanelsCache: toJS(store.surfaces.surfacesPanelsCache)
      };

      // Save the latest context
      $latestContext.current = context;

      if (isLoadingPanels) {
        return;
      }

      const payload = computePayload(context, {
        surfacePanels,
        surfaces: toJS(surfaces),
        calculationId,
        optimizers
      });

      if (!deepEqual(payload, $prevPayload.current)) {
        $prevPayload.current = payload;
        saveEditorContextDebounced(payload);
      }
    });

    return () => {
      disposer();
    };
  }, [
    calculationId,
    isLoadingPanels,
    optimizers,
    saveEditorContextDebounced,
    store,
    surfacePanels,
    surfaces
  ]);

  // Sync the context whenever the panels finish computing
  React.useEffect(() => {
    if (!isLoadingPanels && $latestContext.current) {
      const payload = computePayload($latestContext.current, {
        surfacePanels,
        surfaces: toJS(surfaces),
        calculationId,
        optimizers
      });

      if (!deepEqual(payload, $prevPayload.current)) {
        $prevPayload.current = payload;
        saveEditorContextDebounced(payload);
      }
    }
  }, [calculationId, isLoadingPanels, optimizers, saveEditorContextDebounced, surfacePanels, surfaces]);
}
