/** @jsxImportSource theme-ui */
import { EditorResultsEditCard } from '@/features/editor-results/components/editor-results-edit-card';
import { Icons } from '@/assets';
import { Typography } from '@/components/typography';
import { Box } from 'theme-ui';
import { EditorContext } from '@/features/editor/utils/editor-context';
import { R } from '@/lib/remeda';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { ICalculationDefaultsApiResponse } from '@/features/project-information/api';

interface ICalculationAssumptionsData {
  value: React.ReactNode;
  label: string;
  description: string;
}

function CalculationAssumptionsData({ value, label, description }: ICalculationAssumptionsData): JSX.Element {
  return (
    <Box>
      <Typography variant="headline1" sx={{ textTransform: 'uppercase', color: 'menuText' }}>
        <span sx={{ color: 'primary' }}>{value}</span> <span sx={{ fontSize: '0.8em' }}>{label}</span>
      </Typography>

      <Typography sx={{ mt: 2, color: 'inputText' }} variant="headline4">
        {description}
      </Typography>
    </Box>
  );
}

export type ICalculationAssumptionsProps = EditorContext['projectInformation'] & {
  annualElectricityConsumption: number;
  onEdit: () => void;
};

export function CalculationAssumptions({
  electricityPrices,
  clientType,
  electricityConsumption,
  annualElectricityConsumption,
  financialSupport,
  onEdit
}: ICalculationAssumptionsProps): JSX.Element {
  const intl = useIntl();

  if (R.isNil(electricityPrices) || R.isNil(clientType) || R.isNil(electricityConsumption)) {
    return <React.Fragment />;
  }

  return (
    <EditorResultsEditCard.Wrapper
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <EditorResultsEditCard.Title onEdit={onEdit} icon={<Icons.CalculationAssumptions />}>
        Beräkningsantaganden
      </EditorResultsEditCard.Title>

      <EditorResultsEditCard.Content
        sx={{
          display: 'flex',
          gap: 115,
          alignItems: 'center',
          flexGrow: 1,
          px: 2,

          '& > *': {
            display: 'flex',
            flexDirection: 'column',
            gap: 12
          }
        }}
      >
        <Box>
          <CalculationAssumptionsData
            value={annualElectricityConsumption.toString()}
            label="kwh"
            description=" Årlig konsumtion"
          />
          <CalculationAssumptionsData
            value={intl.formatNumber(electricityPrices.sellingCostCents / 100, {
              style: 'currency',
              currency: 'SEK'
            })}
            label="per kwh"
            description="Pris på såld el"
          />
          <CalculationAssumptionsData
            value={
              clientType === 'privatePerson' ? (
                <FormattedMessage defaultMessage="Ja" id="a5msuh" />
              ) : (
                <FormattedMessage defaultMessage="Nej" id="oUWADl" />
              )
            }
            label=""
            description="Inkludera moms"
          />
        </Box>

        <Box>
          <CalculationAssumptionsData
            value={intl.formatNumber(electricityPrices.purchaseCostCents / 100, {
              style: 'currency',
              currency: 'SEK'
            })}
            label="per kwh"
            description="Kostnad köpt el"
          />
          <CalculationAssumptionsData
            value={`${electricityPrices.priceDevelopmentPercentPerYear * 100}%`}
            label="per year"
            description="Pris utveckling"
          />
          <CalculationAssumptionsData value={financialSupport} label="Avdrag" description="" />
        </Box>
      </EditorResultsEditCard.Content>
    </EditorResultsEditCard.Wrapper>
  );
}

export function calculationAssumptionsData(
  projectInformation: EditorContext['projectInformation'],
  calculationDefaults: ICalculationDefaultsApiResponse
): EditorContext['projectInformation'] & {
  annualElectricityConsumption: number;
} {
  return {
    ...projectInformation,
    annualElectricityConsumption: electricityConsumption(),
    financialSupport: calculationDefaults.deductions.find(
      (deduction) => deduction.id === projectInformation.financialSupport
    )?.name
  };

  function electricityConsumption(): number {
    if (R.isNil(projectInformation.electricityConsumption)) {
      return 0;
    }

    if (projectInformation.electricityConsumption.template === 'villa') {
      return calculationDefaults.consumption.yearlyKwh;
    } else if (projectInformation.electricityConsumption.template === 'perMonth') {
      return projectInformation.electricityConsumption.consumptionKwhMonths.reduce(
        (acc, val) => acc + val,
        0
      );
    }

    return projectInformation.electricityConsumption.consumptionKwh;
  }
}
