import React from 'react';
import * as R from 'remeda';
import { createLengthMarkerForLine } from '@/utils/gis/create-length-marker-for-line';
import { selectPolylineSides } from '@/features/google-map/utils/select-polyline-sides';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { themeColors } from '@/lib/theme-ui/colors';
import { IPolylineOptions } from '@/features/google-map/utils/shapes/polyline';
import { getPointFromMouseEvent } from '@/features/google-map/utils/get-point-from-mouse-event';
import { MapPolyline } from '@/features/google-map/components/shapes/map-polyline';
import { isNil } from 'remeda';
import { MapLengthLabel } from '@/features/google-map/components/shapes/map-length-label';
import { pointSerialize } from '@/utils/gis/point';
import { ISide } from '@/utils/drawing/types';
import { EditorServices } from '@/features/editor';
import { Gis } from '@/features/editor/utils/gis';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';

function RenderLengthLabelOnSide({ side }: { side: ISide }) {
  const { middle, lengthLabel, length } = createLengthMarkerForLine({
    start: side.start,
    end: side.end
  });
  if (length === 0) {
    return null;
  }
  return <MapLengthLabel label={lengthLabel} point={middle} />;
}

type PolygonKind = 'surface' | 'obstacle';

function useDrawingPolygonDone(kind: PolygonKind) {
  const { drawPolygonService } = EditorServices.DrawPolygon.useDrawPolygonService();
  const store = useEditorStore();

  React.useEffect(() => {
    const subscriber = drawPolygonService.subscribe((state) => {
      if (state.matches('done')) {
        if (kind === 'surface') {
          store.surfaces.surfaceCreate(Gis.geojsonPolygonPath(state.context.path));
        } else {
          store.obstacles.obstacleCreate(Gis.geojsonPolygonPath(state.context.path));
        }

        drawPolygonService.send({
          type: 'RESET'
        });
      }
    });

    return () => {
      subscriber.unsubscribe();
    };
  }, [drawPolygonService, kind, store]);
}

const RenderInProgressPolygonInner = observer(function RenderInProgressPolygonInner({
  options,
  kind
}: {
  options?: IPolylineOptions;
  kind: PolygonKind;
}) {
  const { map } = useGoogleMapContext();
  const store = useEditorStore();

  const { drawPolygonService } = EditorServices.DrawPolygon.useDrawPolygonService();
  const path = EditorServices.DrawPolygon.useSelectPath();
  const cursorPoint = EditorServices.DrawPolygon.useSelectCursorPoint();

  const isDrawPolygonActive = store.state.isCreatingSurface || store.state.isAddingObstacle;

  const isDrawPolygonActiveCustom90 = store.state.isCreatingCustomSurface90;

  React.useEffect(() => {
    if (!isDrawPolygonActive && !isDrawPolygonActiveCustom90) {
      return;
    }

    function cancelDrawing(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        drawPolygonService.send('CANCEL');
      }
    }

    window.addEventListener('keydown', cancelDrawing);

    const listeners: google.maps.MapsEventListener[] = [
      map.addListener('click', (event: google.maps.MapMouseEvent) => {
        const point = getPointFromMouseEvent(event);
        if (point) {
          drawPolygonService.send({
            type: 'CLICK',
            point,
            isSnappingAngle: (event.domEvent as MouseEvent).shiftKey,
            isDrawBox: isDrawPolygonActiveCustom90 ? isDrawPolygonActiveCustom90 : false
          });
        }
      }),
      map.addListener('mousemove', (event: google.maps.MapMouseEvent) => {
        const point = getPointFromMouseEvent(event);
        if (point) {
          drawPolygonService.send({
            type: 'MOUSE_MOVE',
            point,
            isSnappingAngle: (event.domEvent as MouseEvent).shiftKey,
            isDrawBox: isDrawPolygonActiveCustom90 ? isDrawPolygonActiveCustom90 : false
          });
        }
      }),
      map.addListener('dblclick', () => {
        drawPolygonService.send('DOUBLE_CLICK');
      })
    ];

    return () => {
      listeners.forEach((listener) => listener.remove());
      window.removeEventListener('keydown', cancelDrawing);
    };
  }, [
    isDrawPolygonActive,
    isDrawPolygonActiveCustom90,
    drawPolygonService,
    map,
    path,
    store.surfaces.inputValue,
    store.surfaces.inputSideValue
  ]);

  useDrawingPolygonDone(kind);

  if (!isDrawPolygonActive && !isDrawPolygonActiveCustom90) {
    return null;
  }

  const normalizedPath = path ? Gis.geojsonPolygonPathNormalize(path) : undefined;
  if (isNil(normalizedPath) || isNil(cursorPoint)) {
    return null;
  }

  const first = R.first(normalizedPath)!;
  const last = R.last(normalizedPath)!;

  return (
    <MapPolyline {...options} path={normalizedPath}>
      <MapPolyline {...options} path={[first, cursorPoint]} />
      <MapPolyline {...options} path={[last, cursorPoint]} />

      <RenderLengthLabelOnSide side={{ start: first, end: cursorPoint }} />
      <RenderLengthLabelOnSide side={{ start: last, end: cursorPoint }} />

      {selectPolylineSides(normalizedPath).map((side) => (
        <RenderLengthLabelOnSide
          key={[pointSerialize(side.start), pointSerialize(side.end)].join('')}
          side={side}
        />
      ))}
    </MapPolyline>
  );
});

type LineType = 'surface' | 'obstacle';
function lineStrokeColor(lineType: LineType): string {
  if (lineType === 'surface') {
    return themeColors.surfaceDefault;
  }
  return themeColors.obstacle;
}

export const RenderInProgressPolygon = observer(function RenderInProgressPolygon() {
  const store = useEditorStore();
  const isAddingObstacle = store.state.isAddingObstacle;

  const options = React.useMemo(
    () =>
      isAddingObstacle
        ? {
            strokeColor: lineStrokeColor('obstacle'),
            clickable: false
          }
        : {
            strokeColor: lineStrokeColor('surface'),
            clickable: false
          },
    [isAddingObstacle]
  );

  if (isAddingObstacle) {
    return <RenderInProgressPolygonInner options={options} kind="obstacle" />;
  }

  return <RenderInProgressPolygonInner options={options} kind="surface" />;
});
