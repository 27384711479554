import { IGetAllProductsApiResponse } from '@/features/editor/api';
import { UnwrapArray } from '@/utils/types';

type OptimizerType = UnwrapArray<IGetAllProductsApiResponse['optimizerTypes']>;
type InverterType = UnwrapArray<IGetAllProductsApiResponse['inverterTypes']>;

export function isOptimizerInInverterFamily(
  inverter: Pick<InverterType, 'optimizerFamilyId'>,
  optimizer: Pick<OptimizerType, 'familyId'>
): boolean {
  // If the optimizer has no family, it can be used with any inverter
  if (optimizer.familyId === null) {
    return true;
  }
  return inverter.optimizerFamilyId === optimizer.familyId;
}

export function filterOptimizersByInverterFamily<T extends Pick<OptimizerType, 'familyId'>>(
  inverter: Pick<InverterType, 'optimizerFamilyId'>,
  optimizers: T[]
) {
  return optimizers.filter((optimizer) => isOptimizerInInverterFamily(inverter, optimizer));
}
