import { ISide } from '@/utils/drawing/types';
import * as R from 'remeda';
import pointToLineDistance from '@turf/point-to-line-distance';
import * as turf from '@turf/helpers';
import { IEnrichedSurface } from '@/features/editor/utils/surface/surface';

export interface IRidgeLine extends ISide {
  ridgeId: string;
}

function ridgeId(surfaceId: string): string {
  return `${surfaceId}-ridge`;
}

interface ISurfaceRidgeParams extends Pick<IEnrichedSurface, 'ridge' | 'id'> {
  sides: ISide[];
}

// Given a ridge point and sides belonging to a surface, returns the side which is closest to the ridge point.
export function surfaceRidgeSide({ ridge, sides, id }: ISurfaceRidgeParams): IRidgeLine | undefined {
  if (ridge === undefined) {
    return undefined;
  }

  return R.pipe(
    sides,
    R.map((side) => ({
      start: side.start,
      end: side.end,
      distance: pointToLineDistance(ridge, turf.lineString([side.start, side.end])),
      ridgeId: ridgeId(id)
    })),
    R.sortBy(({ distance }) => distance),
    R.first()
  );
}
