/** @jsxImportSource theme-ui */
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import React from 'react';
import { useGoogleMap } from '@/features/google-map/hooks/use-google-map';
import { ModalDialog } from '@/components/modal-dialog';
import { Card } from '@/components/card/card';
import { GoogleMapProvider } from '@/features/google-map/components/google-map';
import { MapMarker } from '@/features/google-map/components/shapes/map-marker';
import { Gis } from '@/features/editor/utils/gis';
import { R } from '@/lib/remeda';
import { GoogleMapConversion } from '@/features/google-map/utils/google-map-conversion';
import { Box } from '@/components/box';
import { useGesture, UserHandlers } from '@use-gesture/react';
import { ButtonPrimary, ButtonSecondary } from '@/components/button/button';
import { FormattedMessage } from 'react-intl';
import { GoogleMapCoords } from '@/features/google-map/utils/google-map-coords';
import { nn } from '@/utils/invariant';
import { OverlayImageStore } from '@/features/editor/stores/mobx/overlay-image-store';
import { bearingToAzimuth } from '@turf/helpers';
import yellowMarkerA from '@/assets/images/maps/yellow-marker-a.png';
import yellowMarkerB from '@/assets/images/maps/yellow-marker-b.png';
import redMarkerA from '@/assets/images/maps/red-marker-a.png';
import redMarkerB from '@/assets/images/maps/red-marker-b.png';
import { RequiredType } from '@/utils/types';
import { Vec } from '@/utils/vec';
import { EditableImage } from '@/components/editable-image';
import { Typography } from '@/components/typography';
import { Alert } from '@/components/alert/alert';
import { Icons } from '@/assets';
import { IntlSpace } from '@/features/intl/components/intl-space';

const MapPins = observer(function MapPins() {
  const store = useEditorStore();
  const { mapData, overlayImage } = store;

  if (R.isNil(mapData.mapCenter)) {
    return null;
  }

  const topLeftDefault = mapData.mapCenter;
  const bottomRightDefault = Gis.transformTranslate(topLeftDefault, 4, 90, { units: 'meters' });

  return (
    <React.Fragment>
      <MapMarker
        icon={yellowMarkerA}
        onDragEnd={(event) => {
          overlayImage.updateMapPins((old) => ({
            topLeft: GoogleMapConversion.latLngToPoint(event.latLng!),
            bottomRight: old?.bottomRight ?? bottomRightDefault
          }));
        }}
        draggable
        point={overlayImage.mapPins?.topLeft ?? topLeftDefault}
      />
      <MapMarker
        icon={yellowMarkerB}
        onDragEnd={(event) => {
          overlayImage.updateMapPins((old) => ({
            topLeft: old?.topLeft ?? topLeftDefault,
            bottomRight: GoogleMapConversion.latLngToPoint(event.latLng!)
          }));
        }}
        draggable
        point={overlayImage.mapPins?.bottomRight ?? bottomRightDefault}
      />
    </React.Fragment>
  );
});

function DraggableImagePin({
  children,
  x,
  y,

  onDrag
}: { children: React.ReactNode; x: number; y: number } & Pick<UserHandlers, 'onDrag'>) {
  const $ref = React.useRef<HTMLDivElement>(null);

  useGesture(
    {
      onDrag
    },
    {
      target: $ref
    }
  );

  return (
    <div
      ref={$ref}
      style={{
        cursor: 'pointer',
        position: 'absolute',
        top: y,
        left: x,
        display: 'inline-flex',
        touchAction: 'none'
      }}
    >
      {children}
    </div>
  );
}

/**
 * IMPORTANT:
 *
 * If you change the image type for the pins, you need to update these values.
 * If you don't, the location of the pins will likely be off.
 * */
export const IMAGE_PIN_MARKER_OFFSET_X = 10;
export const IMAGE_PIN_MARKER_OFFSET_Y = 34;

const ImagePins = observer(function ImagePins() {
  const store = useEditorStore();
  const { overlayImage } = store;

  const defaultTopLeft = { x: 200, y: 200 };
  const defaultBottomRight = { x: 250, y: 250 };
  const markerImageStyle = {
    pointerEvents: 'none',
    touchAction: 'none',
    userSelect: 'none'
  } as const;

  const markerA = Vec.add(overlayImage.imagePinsBase?.topLeft ?? defaultTopLeft, {
    x: overlayImage.image?.offsetX ?? 0,
    y: overlayImage.image?.offsetY ?? 0
  });

  const markerB = Vec.add(overlayImage.imagePinsBase?.bottomRight ?? defaultBottomRight, {
    x: overlayImage.image?.offsetX ?? 0,
    y: overlayImage.image?.offsetY ?? 0
  });

  return (
    <React.Fragment>
      <DraggableImagePin
        onDrag={({ delta: [x, y], event }) => {
          event.stopPropagation();

          const deltaVec = { x, y };

          overlayImage.updateImagePins((old) => ({
            topLeft: old?.topLeft ? Vec.add(old.topLeft, deltaVec) : Vec.add({ x, y }, defaultTopLeft),
            bottomRight: old?.bottomRight ?? defaultBottomRight
          }));
        }}
        {...markerA}
      >
        <img style={markerImageStyle} src={redMarkerA} alt={''} />
      </DraggableImagePin>

      <DraggableImagePin
        onDrag={({ delta: [x, y], event }) => {
          event.stopPropagation();

          const deltaVec = { x, y };

          overlayImage.updateImagePins((old) => ({
            topLeft: old?.topLeft ?? defaultTopLeft,
            bottomRight: old?.bottomRight
              ? Vec.add(old.bottomRight, deltaVec)
              : Vec.add(deltaVec, defaultBottomRight)
          }));
        }}
        {...markerB}
      >
        <img style={markerImageStyle} src={redMarkerB} alt={''} />
      </DraggableImagePin>
    </React.Fragment>
  );
});

const IMAGE_BOX_SIZE = 600;

export function getImagePositionFromPins({
  imagePins,
  mapPins,
  parentMap
}: Pick<RequiredType<OverlayImageStore>, 'mapPins' | 'imagePins'> & {
  parentMap: google.maps.Map;
}) {
  const originPin = mapPins.topLeft;
  const originPinPixel = GoogleMapCoords.latLngToVector(
    GoogleMapConversion.pointToLatLng(originPin),
    parentMap
  );

  // Convert pins to locations on the real map (parent map),
  // as opposed to the small map visible in the dialog
  const topLeftPin = GoogleMapConversion.latLngToPoint(
    GoogleMapCoords.vectorToLatLng(
      new google.maps.Point(originPinPixel.x + imagePins.topLeft.x, originPinPixel.y + imagePins.topLeft.y),
      parentMap
    )
  );
  const bottomRightPin = GoogleMapConversion.latLngToPoint(
    GoogleMapCoords.vectorToLatLng(
      new google.maps.Point(
        originPinPixel.x + imagePins.bottomRight.x,
        originPinPixel.y + imagePins.bottomRight.y
      ),
      parentMap
    )
  );

  // Get the difference in angle between the image pins and the map pins
  // This is the angle that the image needs to be rotated by
  const imagePinsAngle = bearingToAzimuth(Gis.rhumbBearing(topLeftPin, bottomRightPin));
  const mapPinsAngle = bearingToAzimuth(Gis.rhumbBearing(mapPins.topLeft, mapPins.bottomRight));
  const rotationAngle = mapPinsAngle - imagePinsAngle;

  // Get the rotated image line. This will be used to calculate how far the
  // image's top left corner needs to be translated such that the image pins
  // line is in the same position as the map pins line
  const imageLine = [topLeftPin, bottomRightPin];
  const imageLineRotated = Gis.transformRotate(imageLine, rotationAngle);
  const mapLine = [mapPins.topLeft, mapPins.bottomRight];

  return {
    // Adjust the image such that the pins are in the same position as the map pins
    topLeft: Gis.transformTranslate(
      originPin,
      Gis.distance(Gis.center(imageLineRotated), Gis.center(mapLine)),
      Gis.rhumbBearing(Gis.center(imageLineRotated), Gis.center(mapLine))
    ),
    rotationAngle
  };
}

const OverlayImagePinsBackground = observer(function OverlayImagePinsBackground() {
  const [scaled, handleZoom] = React.useState(1);
  const [bounds, setBounds] = React.useState<{
    width: number;
    height: number;
  }>();

  const store = useEditorStore();
  const $imageWrapper = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setBounds($imageWrapper.current?.getBoundingClientRect());
  }, []);

  const { overlayImage } = store;

  return (
    <div
      id={'wrapper'}
      sx={{
        width: '50%',
        height: IMAGE_BOX_SIZE,
        position: 'relative',
        display: 'flex',
        borderRadius: 1,
        overflow: 'hidden'
      }}
    >
      <div
        sx={{
          position: 'absolute',
          zIndex: 1,
          p: 2,
          m: 2,
          backgroundColor: 'white',
          display: 'grid',
          gap: 2,
          bottom: 0,
          right: 0
        }}
      >
        <Icons.ZoomIN
          sx={{ display: 'relative', cursor: 'pointer' }}
          onClick={() => handleZoom(scaled < 2 ? scaled + 0.5 : 2)}
        />
        <div sx={{ backgroundColor: 'grey', height: '1px' }} />
        <Icons.ZoomOUT
          sx={{ display: 'relative', cursor: 'pointer' }}
          onClick={() => handleZoom(scaled > 1 ? scaled - 0.5 : 1)}
        />
      </div>
      <Box
        sx={{
          width: '100%',
          height: IMAGE_BOX_SIZE,
          position: 'relative',
          display: 'flex',
          borderRadius: 1,
          overflow: 'hidden',
          transform: `scale(${scaled})`,
          transformOrigin: '0 0'
        }}
      >
        {overlayImage.base64src ? (
          <div sx={{ height: '600px', width: `${100 * scaled}%` }}>
            <EditableImage
              onDrag={({ delta: [x, y] }) => {
                overlayImage.updateImage((old) => {
                  old.offsetX += x;
                  old.offsetY += y;
                });
              }}
              bounds={bounds}
              isEditable={false}
              opacity={1}
              src={overlayImage.base64src}
              scaled={scaled}
            />
          </div>
        ) : null}
        <ImagePins />
      </Box>
    </div>
  );
});

export const OverlayImageAddPins = observer(function OverlayImageAddPins({
  parentMap,
  onDone
}: {
  parentMap: google.maps.Map;
  onDone: (data: ReturnType<typeof getImagePositionFromPins> | undefined) => void;
}) {
  const store = useEditorStore();
  const $mapDiv = React.useRef<HTMLDivElement>(null);

  const { overlayImage, mapData } = store;

  const { map } = useGoogleMap({
    mapDiv: $mapDiv.current!
  });

  React.useEffect(() => {
    if (!map) {
      return;
    }

    const listener = map.addListener('zoom_changed', () => {
      mapData.updateZoom(map.getZoom()!);
    });

    return () => {
      listener.remove();
    };
  }, [map, mapData]);

  function onProceed() {
    nn(overlayImage.mapPins);
    nn(overlayImage.imagePins);

    onDone(
      getImagePositionFromPins({
        imagePins: overlayImage.imagePins,
        mapPins: overlayImage.mapPins,
        parentMap
      })
    );

    overlayImage.addPinsDone();
  }

  function onSkip() {
    onDone(undefined);
    overlayImage.addPinsSkip();
  }

  const canProceed = R.isDefined(overlayImage.mapPins) && R.isDefined(overlayImage.imagePins);

  return (
    <ModalDialog
      modalSx={{
        maxWidth: 'fit-content'
      }}
      isOpen={overlayImage.status !== 'idle'}
      onClose={overlayImage.cancel}
    >
      <Card
        sx={{
          backgroundColor: 'white',
          padding: 6,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography
          variant="headline2"
          sx={{
            marginBottom: 4
          }}
        >
          <FormattedMessage defaultMessage="Adjust image" id="aEmLhz" />
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 4,
            width: '90vW'
          }}
        >
          <Box sx={{ display: 'contents' }} id={'wrapper'}>
            <div
              ref={$mapDiv}
              sx={{
                width: '50%',
                height: IMAGE_BOX_SIZE,
                borderRadius: 1,
                overflow: 'hidden'
              }}
            />

            {map && (
              <GoogleMapProvider value={{ map }}>
                <MapPins />
              </GoogleMapProvider>
            )}

            <OverlayImagePinsBackground />
          </Box>
        </Box>

        <Alert
          kind="info"
          sx={{
            my: 6
          }}
        >
          <Typography variant="body1">
            <FormattedMessage
              defaultMessage="Drag the pins and place them in equal positions on the map to align the image with the relevant structure on the map."
              id="tz24aM"
            />
            <IntlSpace />
            <FormattedMessage
              defaultMessage="Skip this step if you want to manually align the image."
              id="lqrXs4"
            />
          </Typography>
        </Alert>

        <Box
          sx={{
            alignSelf: 'flex-end',
            display: 'flex',
            gap: 2
          }}
        >
          <ButtonSecondary onPress={overlayImage.cancel}>
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </ButtonSecondary>

          {canProceed ? (
            <ButtonPrimary onPress={onProceed}>
              <FormattedMessage defaultMessage="Proceed" id="VNX4fn" />
            </ButtonPrimary>
          ) : (
            <ButtonPrimary onPress={onSkip}>
              <FormattedMessage defaultMessage="Skip" id="/4tOwT" />
            </ButtonPrimary>
          )}
        </Box>
      </Card>
    </ModalDialog>
  );
});
