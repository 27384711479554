import React from 'react';
import { NumberField } from '@/components/number-field/number-field';
import { FormattedMessage } from 'react-intl';
import { Icons } from '@/assets';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface ISurfacePvgisLossInputProps {
  surfaceId: string;
}

export const SurfacePvgisLossInput = observer(function SurfacePvgisLossInput({
  surfaceId
}: ISurfacePvgisLossInputProps): JSX.Element {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (!surface) {
    return <React.Fragment />;
  }

  return (
    <NumberField
      minValue={0}
      value={surface.pvgisLoss / 100}
      onChange={(value) => {
        if (Number.isNaN(value)) {
          return;
        }
        store.surfaces.surfaceUpdate(surfaceId, (surface) => {
          surface.pvgisLoss = value * 100;
        });
      }}
      label={<FormattedMessage defaultMessage="Loss" id="wil7Q2" description="Pvgis loss label" />}
      formatOptions={{
        style: 'percent'
      }}
      icon={<Icons.Loss />}
    />
  );
});
