/** @jsxImportSource theme-ui */
import { Box } from 'theme-ui';
import { Typography } from '@/components/typography';
import { Icons } from '@/assets';
import { EditorResultsCard } from '@/features/editor-results/components/editor-results-card/editor-results-card';
import React from 'react';
import { EditorResultsTableRow } from '@/features/editor-results/components/editor-results-table-row';
import { IGetEditorResultsApiResponse } from '@/features/editor/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { EmptyPropsWithChildren } from '@/utils/types';
import { IntlSpace } from '@/features/intl/components/intl-space';

function TableTitle({ children }: EmptyPropsWithChildren): JSX.Element {
  return (
    <Typography sx={{ pl: 8, mt: 7, mb: 1 }} variant="headline3">
      {children}
    </Typography>
  );
}

export type IEditorResultsCostsForConstructionProps = IGetEditorResultsApiResponse['costs'];

export function EditorResultsCostsForConstruction({
  table
}: IEditorResultsCostsForConstructionProps): JSX.Element {
  const intl = useIntl();
  const formatCurrency = useFormatCurrency();

  return (
    <EditorResultsCard.Wrapper>
      <EditorResultsCard.Title>
        <Icons.CostsForConstruction />
        <Typography variant="headline2">
          <FormattedMessage defaultMessage="Byggnationskostnad" id="pQi1B3" />
        </Typography>
      </EditorResultsCard.Title>

      <Box>
        <TableTitle>
          <FormattedMessage
            defaultMessage="Solanläggning"
            id="NcQ5hn"
            description="Title for a cost table for solar installation"
          />
        </TableTitle>

        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Paneler',
            id: 'lfGBbb'
          })}
          value={[formatCurrency(table.panelCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Växelriktare',
            id: 'UN6uei'
          })}
          value={[formatCurrency(table.inverterCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Optimerare',
            id: 'Fp6CRO'
          })}
          value={[formatCurrency(table.optimizerCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Installations accessoarer',
            id: 'S6qbD/'
          })}
          value={[formatCurrency(table.accessoriesCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Monteringsmaterial',
            id: 'CjIe+U'
          })}
          value={[formatCurrency(table.mountingMaterialCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Elektrisktmaterial',
            id: 'mWB+kv'
          })}
          value={[formatCurrency(table.electricalMaterialCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Installationskostnad',
            id: 'vf6o8w'
          })}
          value={[formatCurrency(table.mountingCost.value - table.travelCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Elinstallationskostnad',
            id: 's3OJd5'
          })}
          value={[formatCurrency(table.electricalInstallationCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Addition',
            id: 'uNJQ0V'
          })}
          value={[formatCurrency(table.additionsCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Frakt och avfallskostnader',
            id: 'USBLOj'
          })}
          value={[formatCurrency(table.shippingAndWasteCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Resekostnad',
            id: 'gxoBCE'
          })}
          value={[formatCurrency(table.travelCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Planering',
            id: '99OdS3'
          })}
          value={[formatCurrency(table.designCost.value)]}
        />
        {table.offerAdjustment.isActive ? (
          <EditorResultsTableRow
            label={table.offerAdjustment.label}
            value={[formatCurrency(table.offerAdjustment.value)]}
          />
        ) : null}
        <EditorResultsTableRow
          label={
            <React.Fragment>
              <FormattedMessage defaultMessage="Total kostnad" id="4Gw7VU" />
              <br />
              <span sx={{ fontSize: '0.9em' }}>
                <FormattedMessage defaultMessage="Möjliga avdrag" id="7kPFKk" />
                <IntlSpace />
                {formatCurrency(table.totalSolarCost.possibleDeduction)}
              </span>
            </React.Fragment>
          }
          value={[formatCurrency(table.totalSolarCost.value)]}
        />

        <TableTitle>
          <FormattedMessage
            defaultMessage="Energi"
            id="j4fyFA"
            description="Title for a table for batteries cost"
          />
        </TableTitle>

        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Energiförvaring',
            id: 'qxnkFh'
          })}
          value={[formatCurrency(table.batteriesCost.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Laddbox',
            id: '/juEsH'
          })}
          value={[formatCurrency(table.wallboxCost.value)]}
        />
        <EditorResultsTableRow
          label={
            <React.Fragment>
              <FormattedMessage defaultMessage="Totala anläggningsalternativ" id="Y5r6Cq" />
              <br />
              <span sx={{ fontSize: '0.9em' }}>
                <FormattedMessage defaultMessage="Möjliga avdrag" id="7kPFKk" />
                <IntlSpace />
                {formatCurrency(table.totalAccessoriesCost.possibleDeduction)}
              </span>
            </React.Fragment>
          }
          value={[formatCurrency(table.totalAccessoriesCost.value)]}
        />

        <TableTitle>
          <FormattedMessage
            defaultMessage="Totalt"
            id="BcWe8y"
            description="Title for a table of total installation cost, including deductions"
          />
        </TableTitle>

        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Totalt',
            id: 'MJ2jZQ'
          })}
          value={[formatCurrency(table.totalCalculationCost)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Gröntavdrag',
            id: 'YFG29r'
          })}
          value={[formatCurrency(table.deduction.value)]}
        />
        <EditorResultsTableRow
          label={intl.formatMessage({
            defaultMessage: 'Totalt efter gröntavdrag',
            id: 'iNUn/c'
          })}
          value={[formatCurrency(table.totalCalculationCost + table.deduction.value)]}
        />
      </Box>
    </EditorResultsCard.Wrapper>
  );
}
