import { Box } from '@/components/box';
import { PropsWithChildren } from 'react';

export function MapTopControls({ className, children }: PropsWithChildren<{ className?: string }>) {
  return (
    <Box
      className={className}
      sx={{
        top: 32,
        position: 'absolute'
      }}
    >
      {children}
    </Box>
  );
}
