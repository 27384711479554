/** @jsxImportSource theme-ui */
import { Box } from '@/components/box';
import { Typography } from '@/components/typography';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@/components/text-field/text-field';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { RadioGroup } from '@/components/radio-group/radio-group';
import {
  clientTypeParser,
  projectElectricityConsumptionTemplateParser
} from '@/features/project-information/utils/project-information';
import { Divider, Flex, Grid, Heading, ThemeUIStyleObject, Link } from 'theme-ui';
import { ButtonPrimary, ButtonSecondary } from '@/components/button/button';
import { R } from '@/lib/remeda';
import React, { PropsWithChildren } from 'react';
import { useMachine } from '@xstate/react';
import { pointParseLngLat, pointParser } from '@/utils/gis/point';
import { Icons } from '@/assets';
import { RhfNumberField } from '@/lib/react-hook-form/rhf-number-field';
import { RhfRadioGroup } from '@/lib/react-hook-form/rhf-radio-group';
import { EmptyPropsWithChildren } from '@/utils/types';
import { fromCents, toCents } from '@/utils/to-cents';
import { Tabs } from '@/components/tabs/tabs';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { matchDeductionToClient } from '@/features/project-information/utils/match-deduction-to-client';
import { ProjectInformationApi } from '@/features/project-information';
import { createModel } from 'xstate/lib/model';
import { EditorContext, editorInitialContext } from '@/features/editor/utils/editor-context';
import { assign } from 'xstate';
import { Gis } from '@/features/editor/utils/gis';
import { useEditorCalculationId } from '@/features/editor/utils/use-editor-calculation-id';
import { AsyncSelectField } from '@/components/select-field';
import { debounce } from 'ts-debounce';
import { EditorApi } from '@/features/editor';
import { addressDataApi } from '@/features/editor/api';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { useFinancialSupportRule } from '@/features/editor/utils/use-editor-rules-subscriber';
import { useNavigate } from '@/lib/react-router/use-navigate';
import { IntlSpace } from '@/features/intl/components/intl-space';
import { autocompleteAddressDataApi } from '@/features/editor/api/autocomplete-address-data';

function DialogActionButtonsWrapper({ children }: EmptyPropsWithChildren): JSX.Element {
  return (
    <Flex
      sx={{
        gap: 7,
        mt: 15,
        '& > *': {
          flexGrow: 1
        }
      }}
    >
      {children}
    </Flex>
  );
}

function DialogDivider(): JSX.Element {
  return <Divider sx={{ my: 5, color: 'inputBorder' }} />;
}

function SectionLabel({ children }: EmptyPropsWithChildren): JSX.Element {
  return <Typography sx={{ color: 'primary', mb: 2 }}>{children}</Typography>;
}

function FormFields({ className, children }: PropsWithChildren<{ className?: string }>) {
  return (
    <Box
      className={className}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: 7,
        rowGap: 3,
        mb: 6,
        mt: 8
      }}
    >
      {children}
    </Box>
  );
}

function trimmedRequiredString(requiredMessage: string) {
  return z.preprocess((val) => {
    if (typeof val === 'string') {
      return val.trim();
    }
    return val;
  }, z.string().min(1, requiredMessage));
}

const debouncedGetAddress = debounce(addressDataApi, 1000);
const debouncedGetAutocompleteAddress = debounce(autocompleteAddressDataApi, 1000);

class PrivateOrBusiness {
  public static schema(intl: IntlShape) {
    return z.object({
      clientFullName: z.string().optional(),
      clientPhone: z.string().optional(),
      clientCompanyName: z.string().optional(),
      email: z.string().email(
        intl.formatMessage({
          defaultMessage: 'Invalid email',
          id: 'ByuOj8'
        })
      ),
      address: trimmedRequiredString(
        intl.formatMessage({
          defaultMessage: 'Address is required',
          id: 'PWe6/f'
        })
      ),
      zipCode: trimmedRequiredString(
        intl.formatMessage({
          defaultMessage: 'Zip code is required',
          id: 'z7+EXa'
        })
      ).refine(
        (val) => !val.startsWith('0'),
        intl.formatMessage({
          defaultMessage: "Zip code can't start with zero",
          id: 'xJKBEL'
        })
      ),
      coordinates: z
        .union([
          z.string().min(
            1,
            intl.formatMessage({
              defaultMessage: 'Coordinates are required',
              id: '9zMIDJ'
            })
          ),
          pointParser
        ])
        .transform((arg, ctx) => {
          if (Array.isArray(arg)) {
            return Gis.flip(arg);
          }

          const coordinates = pointParseLngLat(arg);
          if (R.isNil(coordinates)) {
            ctx.addIssue({
              message: intl.formatMessage({
                defaultMessage: 'Coordinates format: 18.0686, 59.3293',
                id: 'V7ErX1',
                description: 'Error message for invalid coordinates format'
              }),
              code: 'custom'
            });
          }
          return Gis.flip(coordinates!);
        })
        .refine(R.identity),
      clientType: clientTypeParser
    });
  }
}

const PrivateOrBusinessStep = observer(function PrivateOrBusinessStep({
  onSubmit,
  submitText,
  isSubmitting
}: {
  onSubmit: (data: z.output<ReturnType<typeof PrivateOrBusiness.schema>>) => void;
  submitText?: string;
  isSubmitting?: boolean;
}): JSX.Element {
  const intl = useIntl();
  const store = useEditorStore();

  const defaultValues = (function () {
    const result = R.pick(store.projectInformation, [
      'email',
      'zipCode',
      'coordinates',
      'clientType',
      'address',
      'clientCompanyName',
      'clientFullName',
      'clientPhone'
    ]);

    return {
      ...result,
      clientType: R.isNil(result.clientType) ? 'privatePerson' : result.clientType,
      coordinates: result.coordinates ? Gis.flip(result.coordinates) : undefined
    };
  })();

  const [schema] = React.useState(() => PrivateOrBusiness.schema(intl));

  interface IAddressAutocompleteOption {
    label: string;
    value: string;
  }

  const [addressSelectOptions, setAddressSelectOptions] = React.useState<IAddressAutocompleteOption[]>([]);
  const [inputValue, setInputValue] = React.useState('');

  const formMethods = useForm<z.output<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues
  });

  function getSubmitText(): string | JSX.Element {
    if (submitText) {
      return submitText;
    }
    return <FormattedMessage defaultMessage="Next" id="wtu6NM" description="Primary action in a form" />;
  }

  return (
    <form
      onSubmit={formMethods.handleSubmit((data) => {
        const {
          address,
          coordinates,
          clientType,
          clientPhone,
          clientFullName,
          clientCompanyName,
          zipCode,
          email
        } = data;

        store.projectInformation.update((data) => {
          data.address = address;
          data.coordinates = coordinates;
          data.clientType = clientType;
          data.clientPhone = clientPhone;
          data.clientFullName = clientFullName;
          data.clientCompanyName = clientCompanyName;
          data.zipCode = zipCode;
          data.email = email;
        });

        onSubmit(data);
      })}
    >
      <Typography variant="body2">
        <FormattedMessage
          defaultMessage="All the information is saved on the server and is available from another computer."
          id="5Yb/CR"
        />
      </Typography>
      <Typography sx={{ mt: 1 }} variant="body2">
        <FormattedMessage defaultMessage="Read how we handle your personal information" id="zrDDlz" />
        <IntlSpace />
        <Link sx={{ color: 'menuText' }} href="https://www.solelgrossisten.se/page/policy-och-cookies">
          <FormattedMessage defaultMessage="here." id="W3hRqN" />
        </Link>
      </Typography>

      <FormFields>
        <TextField
          label={intl.formatMessage({
            defaultMessage: 'Email',
            id: 'DuPnGd',
            description: 'Email label'
          })}
          {...formMethods.register('email')}
          errorMessage={formMethods.formState.errors.email?.message}
        />

        <Controller
          name="address"
          control={formMethods.control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <AsyncSelectField<IAddressAutocompleteOption>
                errorMessage={formMethods.formState.errors.address?.message}
                inputValue={inputValue ? inputValue : value}
                onInputChange={(newValue, { action }) => {
                  setInputValue(newValue);
                  if (action === 'input-change' && newValue === '') {
                    onChange('');
                  }
                }}
                value={addressSelectOptions.find((item) => item.value === value) ?? null}
                loadOptions={async (inputValue) => {
                  const response = await debouncedGetAutocompleteAddress({
                    address: inputValue
                  });
                  const result: IAddressAutocompleteOption[] = response.map((item) => ({
                    label: item,
                    value: item
                  }));
                  setAddressSelectOptions(result);

                  return result;
                }}
                onChange={async (value) => {
                  if (R.isDefined(value)) {
                    onChange(value.value);
                    setInputValue(value.value);
                    const response = await debouncedGetAddress({
                      address: value.value
                    });
                    response.map((item) => {
                      formMethods.setValue('coordinates', [item.latitude, item.longitude]);
                    });
                  }
                }}
                name={name}
                label={intl.formatMessage({
                  defaultMessage: 'Address',
                  description: 'Address label',
                  id: 'Oj0iQH'
                })}
              />
            );
          }}
        />

        <TextField
          label={intl.formatMessage({
            defaultMessage: 'ZIP Code',
            id: 'hFyjyV',
            description: 'ZIP code label'
          })}
          {...formMethods.register('zipCode')}
          errorMessage={formMethods.formState.errors.zipCode?.message}
        />

        <TextField
          label={intl.formatMessage({
            defaultMessage: 'Coordinates',
            id: '0dCPzu',
            description: 'Latitude/longitude coordinates label'
          })}
          {...formMethods.register('coordinates')}
          errorMessage={formMethods.formState.errors.coordinates?.message}
          helperText={intl.formatMessage({
            defaultMessage: 'Enter latitude and longitude separated by a comma',
            id: 'vR2w4C'
          })}
          placeholder="59.3293, 18.0686"
        />
      </FormFields>

      <FormFields>
        <TextField
          label={intl.formatMessage({
            defaultMessage: 'Full name',
            id: 'KQANlm',
            description: 'Client name'
          })}
          {...formMethods.register('clientFullName')}
          errorMessage={formMethods.formState.errors.clientFullName?.message}
        />

        <TextField
          label={intl.formatMessage({
            defaultMessage: 'Phone number',
            id: '9A9/Da',
            description: 'Client phone number'
          })}
          {...formMethods.register('clientPhone')}
          errorMessage={formMethods.formState.errors.clientPhone?.message}
        />

        <TextField
          sx={{ gridColumn: '1 / span 2' }}
          label={intl.formatMessage({
            defaultMessage: 'Company name',
            id: 'Requk8',
            description: 'Client company name'
          })}
          {...formMethods.register('clientCompanyName')}
          errorMessage={formMethods.formState.errors.clientCompanyName?.message}
        />
      </FormFields>

      <RhfRadioGroup
        aria-label={intl.formatMessage({
          defaultMessage: 'Private person or company',
          id: 'xbS7N8'
        })}
        control={formMethods.control}
        name="clientType"
        label="Private person / Company"
      >
        <RadioGroup.Radio value={clientTypeParser.Enum.privatePerson}>
          <FormattedMessage
            description='Label for the "private person" radio field'
            defaultMessage="Private person / Including VAT"
            id="zqiZVO"
          />
        </RadioGroup.Radio>

        <RadioGroup.Radio sx={{ ml: 12 }} value={clientTypeParser.Enum.company}>
          <FormattedMessage
            description='Label for the "company" radio field'
            defaultMessage="Company / Excluding VAT"
            id="pDv4+V"
          />
        </RadioGroup.Radio>
      </RhfRadioGroup>

      <DialogActionButtonsWrapper>
        <ButtonPrimary isLoading={isSubmitting} type="submit" sx={{ gridColumn: 'span 2' }}>
          {getSubmitText()}
        </ButtonPrimary>
      </DialogActionButtonsWrapper>
    </form>
  );
});

class ElectricityConsumption {
  public static get schema() {
    return z.object({
      financialSupport: z.string(),
      electricityConsumption: z.union([
        z.object({
          template: z.literal('villa')
        }),
        z.object({
          template: z.literal('annual'),
          consumptionKwh: z.number().min(0).default(0)
        }),
        z.object({
          template: z.literal('perMonth'),
          consumptionKwhMonths: z.tuple([
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0),
            z.number().min(0).default(0)
          ])
        })
      ]),
      electricityPrices: z.object({
        purchaseCost: z.number().min(0),
        sellingCost: z.number().min(0),
        priceDevelopmentPercentPerYear: z.number()
      }),
      financing: z.object({
        loanAmount: z.number().default(0),
        loanRepaymentPeriodYears: z.number(),
        loanInterestRatePercent: z.number()
      })
    });
  }

  public static parse(data: z.output<typeof ElectricityConsumption.schema>) {
    return {
      financing: {
        loanAmountCents: toCents(data.financing.loanAmount),
        loanRepaymentPeriodYears: data.financing.loanRepaymentPeriodYears,
        loanInterestRatePercent: data.financing.loanInterestRatePercent
      },
      electricityConsumption: data.electricityConsumption,
      electricityPrices: {
        purchaseCostCents: toCents(data.electricityPrices.purchaseCost),
        sellingCostCents: toCents(data.electricityPrices.sellingCost),
        priceDevelopmentPercentPerYear: data.electricityPrices.priceDevelopmentPercentPerYear
      },
      financialSupport: data.financialSupport
    } as const;
  }
}

type ElectricityConsumptionChildren = (data: {
  getValues: () => ReturnType<typeof ElectricityConsumption.parse>;
}) => React.ReactNode;

const ElectricityConsumptionStep = observer(function ElectricityConsumptionStep({
  onSubmit,
  children,
  submitText,
  isSubmitDisabledWhenClean = false,
  deductions,
  defaults,
  isSubmitting
}: {
  isSubmitting?: boolean;
  onSubmit: (data: ReturnType<typeof ElectricityConsumption.parse>) => void;
  children?: ElectricityConsumptionChildren;
  submitText?: string;
  isSubmitDisabledWhenClean?: boolean;
  deductions: ProjectInformationApi.ICalculationDefaultsApiResponse['deductions'];
  defaults: ProjectInformationApi.ICalculationDefaultsApiResponse;
}): JSX.Element {
  const store = useEditorStore();

  const clientType = store.projectInformation.clientType;
  const defaultValues = (function () {
    const { electricityConsumption, electricityPrices, financing, financialSupport } =
      store.projectInformation;

    const electricityConsumptionDefaults = {
      consumptionKwh: defaults.consumption.yearlyKwh,
      consumptionKwhMonths: defaults.consumption.monthlyKwh,
      template: 'villa'
    } as const;

    return {
      financialSupport,
      electricityConsumption: electricityConsumption
        ? { ...electricityConsumptionDefaults, ...electricityConsumption }
        : // Since the form uses a discriminated union, it doesn't accept the 'villa' template
          // with the 'consumptionKwh' and 'consumptionKwhMonths' fields. We still need to add defaults though
          // so this error needs to be silenced
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (electricityConsumptionDefaults as any),
      electricityPrices: electricityPrices
        ? {
            ...electricityPrices,
            purchaseCost: fromCents(electricityPrices.purchaseCostCents),
            sellingCost: fromCents(electricityPrices.sellingCostCents)
          }
        : {
            ...defaults.sale,
            priceDevelopmentPercentPerYear: defaults.sale.priceDevelopmentPercentPerYear / 100
          },
      financing: financing
        ? {
            ...financing,
            loanAmount: fromCents(financing.loanAmountCents)
          }
        : {
            loanAmount: defaults.financing.loanAmount,
            loanRepaymentPeriodYears: defaults.financing.loanRepaymentPeriodYears,
            loanInterestRatePercent: defaults.financing.loanInterestRatePercent / 100
          }
    };
  })();

  const intl = useIntl();

  const formMethods = useForm<z.infer<typeof ElectricityConsumption.schema>>({
    resolver: zodResolver(ElectricityConsumption.schema),
    defaultValues
  });
  const { isDirty } = formMethods.formState;
  const template = formMethods.watch('electricityConsumption.template');
  const loanRepaymentPeriodYears = formMethods.watch('financing.loanRepaymentPeriodYears');
  const isDisabled = isSubmitDisabledWhenClean ? !isDirty : false;

  const kwhLabel = <FormattedMessage defaultMessage="kWh" id="oPw1GI" description="Kilowatt hours label" />;

  function getSubmitText(): string {
    if (submitText) {
      return submitText;
    }
    return intl.formatMessage({
      defaultMessage: 'Start designing',
      id: 'mT7izs',
      description: 'Label for a primary action'
    });
  }

  if (R.isNil(clientType)) {
    return <React.Fragment />;
  }

  return (
    <form
      onSubmit={formMethods.handleSubmit((data) => {
        const payload = ElectricityConsumption.parse(data);
        const { electricityConsumption, electricityPrices, financing, financialSupport } = payload;

        store.projectInformation.update((data) => {
          data.electricityConsumption = electricityConsumption;
          data.electricityPrices = electricityPrices;
          data.financing = financing;
          data.financialSupport = financialSupport;
        });

        onSubmit(payload);
      })}
    >
      <RhfRadioGroup
        aria-label={intl.formatMessage({
          defaultMessage: 'Financial support type',
          id: '6dCrT8'
        })}
        label={
          <SectionLabel>
            <FormattedMessage
              defaultMessage="Select support / deduction for calculation"
              id="2RD+ZG"
              description="Label for a radio button group"
            />
          </SectionLabel>
        }
        control={formMethods.control}
        name="financialSupport"
      >
        <RadioGroup.Content
          sx={{
            display: 'flex',
            gap: 3,
            flexWrap: 'wrap',
            '& > *': {
              flexGrow: 1
            }
          }}
        >
          {R.pipe(
            deductions,
            R.filter((deduction) => matchDeductionToClient(deduction, clientType)),
            R.map((item) => (
              <RadioGroup.Radio key={item.id} value={String(item.id)}>
                {item.name}
              </RadioGroup.Radio>
            ))
          )}
        </RadioGroup.Content>
      </RhfRadioGroup>

      <DialogDivider />

      <RhfRadioGroup
        aria-label={intl.formatMessage({
          defaultMessage: 'Electricity consumption template',
          id: 'Rc5m6g'
        })}
        label={
          <SectionLabel>
            <FormattedMessage
              defaultMessage="How much electricity do you use today?"
              id="qhmScd"
              description="Label for a radio button group"
            />
          </SectionLabel>
        }
        control={formMethods.control}
        name="electricityConsumption.template"
      >
        <RadioGroup.Content
          sx={{
            gridTemplateColumns: 'repeat(3, 1fr)'
          }}
        >
          <RadioGroup.Radio value={projectElectricityConsumptionTemplateParser.Enum.villa}>
            <FormattedMessage
              defaultMessage="Template for villa"
              id="dDkduZ"
              description="Label for a radio button"
            />
          </RadioGroup.Radio>

          <RadioGroup.Radio value={projectElectricityConsumptionTemplateParser.Enum.annual}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
              {template === 'annual' ? (
                <Box sx={{ maxWidth: 184 }}>
                  <RhfNumberField
                    label={intl.formatMessage({
                      defaultMessage: 'Annual consumption',
                      id: 'Dn8cYs',
                      description: 'Label for a radio button'
                    })}
                    adornment={kwhLabel}
                    control={formMethods.control}
                    name="electricityConsumption.consumptionKwh"
                  />
                </Box>
              ) : (
                <FormattedMessage
                  defaultMessage="Annual consumption"
                  id="Dn8cYs"
                  description="Label for a radio button"
                />
              )}
            </Box>
          </RadioGroup.Radio>

          <RadioGroup.Radio value={projectElectricityConsumptionTemplateParser.Enum.perMonth}>
            <FormattedMessage
              defaultMessage="Consumption per month"
              id="w0Yp3d"
              description="Label for a radio button"
            />
          </RadioGroup.Radio>
        </RadioGroup.Content>
      </RhfRadioGroup>

      {template === 'perMonth' && (
        <Grid
          sx={{
            mt: 6,
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: 1
          }}
        >
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'January',
              id: 't8QlFE'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.0"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'February',
              id: '0TR0PD'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.1"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'March',
              id: 'T5W0CV'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.2"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'April',
              id: 'h7xti1'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.3"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'May',
              id: 'zUpHdf'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.4"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'June',
              id: 'xBaHKe'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.5"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'July',
              id: 'NGWumB'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.6"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'August',
              id: 'lINtN6'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.7"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'September',
              id: 'TFf5u+'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.8"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'October',
              id: 'EuK7yR'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.9"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'November',
              id: 'pn+jQD'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.10"
          />
          <RhfNumberField
            adornment={kwhLabel}
            placeholder={intl.formatMessage({
              defaultMessage: 'December',
              id: 'SsiZK7'
            })}
            control={formMethods.control}
            name="electricityConsumption.consumptionKwhMonths.11"
          />
        </Grid>
      )}

      <DialogDivider />

      <Grid
        sx={{
          gridTemplateColumns: 'repeat(3, 1fr)'
        }}
      >
        <RhfNumberField
          label={intl.formatMessage({
            defaultMessage: 'Cost of purchased electricity',
            id: 'FVDs/t',
            description: 'Label for a number field'
          })}
          formatOptions={{
            style: 'currency',
            currency: 'SEK'
          }}
          control={formMethods.control}
          name="electricityPrices.purchaseCost"
          adornment="/ kWh"
        />

        <RhfNumberField
          label={intl.formatMessage({
            defaultMessage: 'Revenue sold electricity',
            id: 'D7mB1A',
            description: 'Label for a number field'
          })}
          formatOptions={{
            style: 'currency',
            currency: 'SEK'
          }}
          control={formMethods.control}
          name="electricityPrices.sellingCost"
          adornment="/ kWh"
        />

        <RhfNumberField
          label={intl.formatMessage({
            defaultMessage: 'Price development',
            id: 'wLxaaX',
            description: 'Label for a number field'
          })}
          formatOptions={{
            style: 'percent'
          }}
          control={formMethods.control}
          name="electricityPrices.priceDevelopmentPercentPerYear"
          adornment={intl.formatMessage({
            defaultMessage: 'per year',
            id: '9ltv9F',
            description: 'Description for the meaning of a number (ex. 0% per year)'
          })}
        />
      </Grid>

      <Box sx={{ mt: 6 }}>
        <SectionLabel>
          <FormattedMessage defaultMessage="Financing" id="VkWjO8" description="Sub-section title" />
        </SectionLabel>
      </Box>

      <Grid
        sx={{
          gridTemplateColumns: 'repeat(3, 1fr)'
        }}
      >
        <RhfNumberField
          label={intl.formatMessage({
            defaultMessage: 'Loan amount',
            id: 'Yqycwa',
            description: 'Label for a number field'
          })}
          formatOptions={{
            style: 'currency',
            currency: 'SEK'
          }}
          control={formMethods.control}
          name="financing.loanAmount"
        />

        <RhfNumberField
          label={intl.formatMessage({
            defaultMessage: 'Repayment period',
            id: 'i0KQL9',
            description: 'Label for a number field'
          })}
          control={formMethods.control}
          name="financing.loanRepaymentPeriodYears"
          adornment={intl.formatMessage(
            {
              defaultMessage: '{itemCount, plural, =0 {years} one {year} other {years}}',
              id: 'aSi3I6'
            },
            {
              itemCount: loanRepaymentPeriodYears
            }
          )}
        />

        <RhfNumberField
          label={intl.formatMessage({
            defaultMessage: 'Interest',
            id: 'gVGSEn',
            description: 'Label for a number field'
          })}
          formatOptions={{
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          control={formMethods.control}
          name="financing.loanInterestRatePercent"
        />
      </Grid>

      <DialogActionButtonsWrapper>
        {children?.({
          getValues: () => ElectricityConsumption.parse(formMethods.getValues())
        })}

        <ButtonPrimary isLoading={isSubmitting} isDisabled={isDisabled} type="submit">
          {getSubmitText()}
        </ButtonPrimary>
      </DialogActionButtonsWrapper>
    </form>
  );
});

interface IProjectInformationDialogProps {
  onClose?: () => void;
  initialStep?: ProjectInformationStep;
}

const dialogContentSx: ThemeUIStyleObject = {
  backgroundColor: 'menuBackground',
  color: 'menuText',
  px: 11,
  pt: 6,
  pb: 9,
  borderTop: '1px solid',
  borderColor: 'menuBorder'
};

export function ProjectInformationUpdateDialog({
  onClose,
  initialStep = 'privateOrBusiness'
}: IProjectInformationDialogProps): JSX.Element {
  const intl = useIntl();
  const [step, setStep] = React.useState<React.Key>(initialStep);

  const { calculationId } = useEditorCalculationId();
  const calculationDefaults = ProjectInformationApi.useCalculationDefaults({ calculationId });

  if (isQueryLoading(calculationDefaults.isLoading, calculationDefaults.data)) {
    return <React.Fragment />;
  }

  return (
    <Tabs
      onSelectionChange={(key) => setStep(key)}
      selectedKey={step}
      sx={{ backgroundColor: 'menuBackground' }}
      tabContentSx={dialogContentSx}
    >
      <Tabs.Item key="privateOrBusiness" title="Private / Business">
        <PrivateOrBusinessStep
          submitText={intl.formatMessage({
            defaultMessage: 'Save',
            id: 'RKx9ML',
            description: 'Label for a primary action'
          })}
          onSubmit={() => {
            onClose?.();
          }}
        />
      </Tabs.Item>

      <Tabs.Item key="consumption" title="Electricity consumption">
        <ElectricityConsumptionStep
          defaults={calculationDefaults.data}
          deductions={calculationDefaults.data.deductions}
          isSubmitDisabledWhenClean
          submitText={intl.formatMessage({
            defaultMessage: 'Save',
            id: 'RKx9ML',
            description: 'Label for a primary action'
          })}
          onSubmit={() => {
            onClose?.();
          }}
        />
      </Tabs.Item>
    </Tabs>
  );
}

export type ProjectInformationStep = 'privateOrBusiness' | 'consumption';

const createProjectModel = createModel(
  {
    calculationId: undefined as number | undefined
  },
  {
    events: {
      gotoPrivate: (data: EditorContext) => ({ data }),
      createCalculation: (data: EditorContext) => ({ data }),
      updateCalculation: (data: EditorContext) => ({ data }),
      done: () => ({})
    }
  }
);

const createProjectMachine = createProjectModel.createMachine({
  predictableActionArguments: true,
  initial: 'privateOrBusiness',
  states: {
    privateOrBusiness: {
      id: 'privateOrBusiness',
      initial: 'waiting',
      states: {
        waiting: {
          on: {
            createCalculation: [
              {
                target: 'creating',
                cond: (context) => R.isNil(context.calculationId)
              },
              {
                target: 'changingStep',
                cond: (context) => R.isDefined(context.calculationId)
              }
            ]
          }
        },
        creating: {
          invoke: {
            src: 'createCalculation',
            onDone: {
              target: '#consumption',
              actions: assign({
                calculationId: (context, event) => {
                  const { calculationID } = EditorApi.calculationCreateParser.parse(event.data);
                  return calculationID;
                }
              })
            },
            onError: {
              target: 'waiting'
            }
          }
        },
        changingStep: {
          invoke: {
            src: 'updateCalculation',
            onDone: {
              target: '#consumption'
            },
            onError: {
              target: 'waiting'
            }
          }
        }
      }
    },
    consumption: {
      id: 'consumption',
      initial: 'waiting',
      states: {
        waiting: {
          on: {
            gotoPrivate: {
              target: 'changingStep'
            },
            updateCalculation: {
              target: 'updating'
            }
          }
        },
        changingStep: {
          invoke: {
            src: 'updateCalculation',
            onDone: {
              target: '#privateOrBusiness'
            },
            onError: {
              target: 'waiting'
            }
          }
        },
        updating: {
          invoke: {
            src: 'updateCalculation',
            onDone: {
              target: '#done'
            },
            onError: {
              target: 'waiting'
            }
          }
        }
      }
    },
    done: {
      id: 'done',
      type: 'final',
      entry: ['done']
    }
  }
});

// Connects the machine with the appropriate side effects.
function useCreateProjectMachine() {
  const navigate = useNavigate();
  const calculationUpdate = EditorApi.useCalculationUpdate();
  const calculationCreate = EditorApi.useCalculationCreate();

  const [state, dispatch] = useMachine(createProjectMachine, {
    actions: {
      done: async (context) => {
        await navigate({
          search: `?calculationId=${context.calculationId}`
        });
      }
    },
    services: {
      createCalculation: (context, event) => {
        if (event.type !== 'createCalculation' || context.calculationId) {
          return Promise.resolve();
        }

        return calculationCreate.mutateAsync(event.data);
      },
      updateCalculation: (context, event) => {
        if (
          (event.type !== 'updateCalculation' &&
            event.type !== 'createCalculation' &&
            event.type !== 'gotoPrivate') ||
          !context.calculationId
        ) {
          return Promise.resolve();
        }

        return calculationUpdate.mutateAsync({
          ...event.data,
          calculationId: context.calculationId
        });
      }
    }
  });

  return [state, dispatch] as const;
}

export const ProjectInformationCreateDialog = observer(
  function ProjectInformationCreateDialog(): JSX.Element {
    const calculationDefaults = ProjectInformationApi.useCalculationDefaults();
    const store = useEditorStore();

    const [state, dispatch] = useCreateProjectMachine();

    useFinancialSupportRule(
      state.context.calculationId
        ? {
            calculationId: state.context.calculationId
          }
        : undefined
    );

    if (isQueryLoading(calculationDefaults.isLoading, calculationDefaults.data)) {
      return <React.Fragment />;
    }

    return (
      <React.Fragment>
        <Box
          sx={{
            variant: 'cards.modal',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ ml: -4 }}>
            {state.matches('privateOrBusiness') ? <Icons.PrivateOrBusiness /> : null}
            {state.matches('consumption') ? <Icons.ElectricityConsumption /> : null}
          </Box>
          <Heading
            sx={{ color: 'primary', fontWeight: 500, fontSize: '2xl', textTransform: 'capitalize' }}
            variant="headline1"
          >
            {state.matches('privateOrBusiness') ? (
              <FormattedMessage
                defaultMessage="Private / Business"
                id="OIYCgR"
                description="Title of a form including information about the client (company or individual)"
              />
            ) : null}

            {state.matches('consumption') ? (
              <FormattedMessage
                defaultMessage="Electricity Consumption"
                id="l8CYtF"
                description="Title of a form including information about the client's electricity consumption"
              />
            ) : null}
          </Heading>
        </Box>

        <Box sx={dialogContentSx}>
          {state.matches('privateOrBusiness') && (
            <PrivateOrBusinessStep
              isSubmitting={
                // @ts-expect-error XState infers second state argument as `never` for some reason
                state.matches('privateOrBusiness.changingStep') || state.matches('privateOrBusiness.creating')
              }
              onSubmit={(data) => {
                dispatch({
                  type: 'createCalculation',
                  data: {
                    ...editorInitialContext,
                    projectInformation: data
                  }
                });
              }}
            />
          )}

          {state.matches('consumption') && (
            <ElectricityConsumptionStep
              isSubmitting={state.matches('consumption.updating')}
              defaults={calculationDefaults.data}
              deductions={calculationDefaults.data.deductions}
              onSubmit={(data) => {
                dispatch({
                  type: 'updateCalculation',
                  data: {
                    ...store.context,
                    projectInformation: {
                      ...store.projectInformation,
                      ...data
                    }
                  }
                });
              }}
            >
              {({ getValues }) => (
                <ButtonSecondary
                  isLoading={state.matches('consumption.changingStep')}
                  onPress={() => {
                    dispatch({
                      type: 'gotoPrivate',
                      data: {
                        ...store.context,
                        projectInformation: {
                          ...store.projectInformation,
                          ...getValues()
                        }
                      }
                    });
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Go back"
                    id="otT3Zj"
                    description="Label for a secondary action"
                  />
                </ButtonSecondary>
              )}
            </ElectricityConsumptionStep>
          )}
        </Box>
      </React.Fragment>
    );
  }
);
