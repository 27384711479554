import {
  SURFACE_PLACEMENT_CHIP_ICON,
  SURFACE_PLACEMENT_LABEL,
  SurfacePlacement,
  surfacePlacementParser
} from '@/features/editor/utils/surface/surface';
import { ChipGroup } from '@/components/chip/chip-group';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface ISurfacePlacementInputProps {
  placement: SurfacePlacement;
  surfaceId: string;
}

export const SurfacePlacementInput = observer(function SurfacePlacementInput({
  placement,
  surfaceId
}: ISurfacePlacementInputProps): JSX.Element {
  const store = useEditorStore();

  function changeSurfacePlacement(id: string, placement: SurfacePlacement): void {
    store.surfaces.surfaceUpdate(id, (old) => {
      old.placement = placement;
    });
  }

  return (
    <ChipGroup gap={3} value={placement}>
      {surfacePlacementParser.options.map((placement) => (
        <ChipGroup.ChipLarge
          key={placement}
          value={placement}
          icon={SURFACE_PLACEMENT_CHIP_ICON[placement]}
          onClick={() => changeSurfacePlacement(surfaceId, placement)}
        >
          {SURFACE_PLACEMENT_LABEL[placement]}
        </ChipGroup.ChipLarge>
      ))}
    </ChipGroup>
  );
});
