/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react';
import { Box, ThemeUICSSObject } from 'theme-ui';
import * as RadixDialog from '@radix-ui/react-dialog';

interface IModalDialogProps {
  children: React.ReactNode;
  onClose?: () => void;
  isOpen?: boolean;
  modalSx?: ThemeUICSSObject;
  overlaySx?: ThemeUICSSObject;
}

export function ModalDialog(props: IModalDialogProps): JSX.Element {
  const { isOpen, modalSx, children, overlaySx } = props;

  return (
    <RadixDialog.Root
      modal
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          props.onClose?.();
        }
      }}
    >
      <RadixDialog.Portal>
        <RadixDialog.Overlay
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            backdropFilter: 'blur(5px)',
            ...overlaySx
          }}
        />
        <RadixDialog.Content
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 2,
            overflow: 'hidden',
            boxShadow: '4dp',
            maxWidth: 765,
            width: '100%',
            mx: 4,
            ...modalSx
          }}
        >
          <RadixDialog.Description>{children}</RadixDialog.Description>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}

export function ModalDialogTitle({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0
      }}
      className={className}
    >
      {children}
    </Box>
  );
}
