import { Box, Grid } from 'theme-ui';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@/components/typography';
import { IGetEditorResultsApiResponse } from '@/features/editor-results/api';
import { EditorResultsCard } from '../editor-results-card/editor-results-card';

function GridHeader(): JSX.Element {
  return (
    <Grid gap={0} columns={[7]} sx={{ '& > *': { py: 4, px: 8 }, backgroundColor: '#F9FAFB' }}>
      <Typography variant="body2">
        <FormattedMessage defaultMessage="Year" id="IFo1oo" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage defaultMessage="Produced kWh" id="R6dk+y" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage defaultMessage="Value electricity" id="UK9MgF" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage defaultMessage="Interest after deductions" id="0Nsi3v" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage defaultMessage="Amortization" id="QwUFnM" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage defaultMessage="In the wallet" id="IRyVbh" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage defaultMessage="Total in the wallet" id="hDIHfu" />
      </Typography>
    </Grid>
  );
}

export interface IGridData {
  year: string;
  producedKwh: string;
  electricityValue: string;
  interestAfterDeductionPercent: string;
  amortization: string;
  walletCents: string;
  totalWalletCents: string;
}

function GridData({
  year,
  producedKwh,
  electricityValue,
  interestAfterDeductionPercent,
  amortization,
  walletCents,
  totalWalletCents
}: IGridData): JSX.Element {
  return (
    <Grid
      gap={0}
      columns={[7]}
      sx={{ fontWeight: 500, borderTop: '1px solid', borderColor: 'inputBorder', '& > *': { py: 4, px: 8 } }}
    >
      <Typography variant="body1">{year}</Typography>
      <Typography variant="body1">{producedKwh}</Typography>
      <Typography variant="body1">{electricityValue}</Typography>
      <Typography variant="body1">{interestAfterDeductionPercent}</Typography>
      <Typography variant="body1">{amortization}</Typography>
      <Typography variant="body1">{walletCents}</Typography>
      <Typography variant="body1">{totalWalletCents}</Typography>
    </Grid>
  );
}

export interface IEditorResultsReturnOverTimeProps {
  data: IGetEditorResultsApiResponse['returnOverTime'];
}

export function EditorResultsReturnOverTime({ data }: IEditorResultsReturnOverTimeProps): JSX.Element {
  return (
    <EditorResultsCard.Wrapper>
      <EditorResultsCard.Title>
        <Typography variant="headline2">Return over time</Typography>
      </EditorResultsCard.Title>

      <Box>
        <GridHeader />

        {data.map((item, index) => (
          <GridData
            key={index}
            year={`${index + 1}`}
            amortization={item.amort}
            interestAfterDeductionPercent={item.interest}
            producedKwh={item.production}
            electricityValue={item.valuePerYear}
            walletCents={item.liquidity}
            totalWalletCents={item.liquidityAcc}
          />
        ))}
      </Box>
    </EditorResultsCard.Wrapper>
  );
}
