import { R } from '@/lib/remeda';
import { ICalculationAccessoriesApiResponse, IGetAllProductsApiResponse } from '@/features/editor/api';

export function useFilteredAdditionsData({ allProducts, calculationAccessories }: IFilteredDataProps) {
  const batteriesGroupedBy = R.groupBy(calculationAccessories.batteries, (x) => x);
  const wallBoxGroupedBy = R.groupBy(calculationAccessories.wallbox, (x) => x);

  const energyStorage = allProducts.batteries.filter((battery) =>
    calculationAccessories.batteries.includes(battery.info_id)
  );

  const chargingBox = allProducts.wallboxes.filter((wallbox) =>
    calculationAccessories.wallbox.includes(wallbox.info_id)
  );

  return {
    batteriesGroupedBy,
    wallBoxGroupedBy,
    energyStorage,
    chargingBox,
    hasEnergyStorageOrChargingBox: energyStorage.length > 0 || chargingBox.length > 0
  };
}

export interface IFilteredDataProps {
  allProducts: IGetAllProductsApiResponse;
  calculationAccessories: ICalculationAccessoriesApiResponse;
}
