/** @jsxImportSource theme-ui */

import React from 'react';
import { Field } from '@/components/field';
import Select, { GroupBase, Props } from 'react-select';
import {
  DropdownIndicator,
  ISelectFieldProps,
  MultiValue,
  selectStyles,
  selectTheme
} from '@/components/select-field/select-field-common';

interface ISelectProps<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>
  extends Omit<Props<T, IsMulti, Group>, 'name'>,
    ISelectFieldProps {}

export interface ISelectDefaultValueShape {
  label: string;
  value: string;
}

export interface ISelectOptionShape<T> {
  label: string;
  value: T;
}

export function SelectField<
  T extends ISelectDefaultValueShape,
  IsMulti extends boolean = false,
  Group extends GroupBase<T> = GroupBase<T>
>({
  label,
  name,
  errorMessage,
  helperText,
  isLabelLifted = false,
  value,
  required = false,
  onFocus,
  onBlur,
  ...props
}: ISelectProps<T, IsMulti, Group>): JSX.Element {
  const [isActive, setIsActive] = React.useState(false);

  function onMenuClose(): void {
    setIsActive(false);
  }

  function onMenuOpen(): void {
    setIsActive(true);
  }

  return (
    <Field
      helperText={helperText}
      label={label}
      isLabelAlwaysLifted
      hasValue={Boolean(value)}
      errorMessage={errorMessage}
      name={name}
      required={required}
      isActive={isActive}
      isHovered={false}
      isolate={false}
    >
      <Select
        {...props}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        name={name}
        value={value}
        isClearable={props.isClearable ?? false}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        menuIsOpen={props.menuIsOpen}
        onFocus={(event) => {
          onFocus?.(event);
          setIsActive(true);
        }}
        onBlur={(event) => {
          onBlur?.(event);
          setIsActive(false);
        }}
        components={{
          DropdownIndicator,
          MultiValue,
          ...props.components
        }}
        theme={selectTheme}
        styles={selectStyles}
      />
    </Field>
  );
}

export function filterSelectOptionAndValue(option: { label: string; value: string }, input: string): boolean {
  if (input === '') {
    return true;
  }

  if (option.label.toLowerCase().includes(input.toLowerCase())) {
    return true;
  }

  return option.value.toLowerCase().includes(input.toLowerCase());
}
