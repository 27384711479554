/** @jsxImportSource theme-ui */
import React from 'react';
import { Box } from '@/components/box';
import { BodyPortal } from '@/components/body-portal';
import { useGesture } from '@use-gesture/react';
import { IVectorBounds } from '@/utils/vec';
import { isNil } from 'remeda';
import { Typography } from '@/components/typography';
//import { Card } from '@/components/card/card';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@/features/modal/stores/use-modal-store';
import { ButtonIcon } from '@/components/button/button';
import { MdClose } from 'react-icons/md';
import { ModalDialogTitle } from '@/components/modal-dialog';

// Defines an area (whole screen) where the user can drag their mouse to create a selection box
export function ScreenshotArea({ onBoundsChange }: { onBoundsChange: (bounds: IVectorBounds) => void }) {
  const [isSnipping, setIsSnipping] = React.useState(false);
  const [showSnippingMsg, setShowSnippingMsg] = React.useState(true);
  const $ref = React.useRef<HTMLDivElement>(null);
  const $snippingArea = React.useRef<HTMLDivElement>(null);
  const modal = useModal();

  function actions() {
    setShowSnippingMsg(false);
    modal.closeModal();
  }

  useGesture(
    {
      onDragEnd: ({ initial, values: current }) => {
        if (!showSnippingMsg) {
          const snippingArea = $snippingArea.current;
          if (snippingArea) {
            snippingArea.style.width = '0px';
            snippingArea.style.height = '0px';
          }

          setIsSnipping(false);

          onBoundsChange({
            topLeft: { x: initial[0], y: initial[1] },
            bottomRight: { x: current[0], y: current[1] }
          });
        }
      },
      onDrag: ({ initial, values: current }) => {
        if (!showSnippingMsg) {
          const snippingArea = $snippingArea.current;
          if (isNil(snippingArea)) {
            return;
          }

          const width = current[0] - initial[0];
          const height = current[1] - initial[1];

          snippingArea.style.width = `${width}px`;
          snippingArea.style.height = `${height}px`;
          snippingArea.style.left = `${initial[0]}px`;
          snippingArea.style.top = `${initial[1]}px`;
        }
      },
      onDragStart: () => {
        if (!showSnippingMsg) {
          setIsSnipping(true);
        }
      }
    },
    {
      target: $ref
    }
  );

  return (
    <BodyPortal>
      <Box
        ref={$ref}
        sx={{
          touchAction: 'none',
          width: '100%',
          height: '100%',
          position: 'fixed',
          left: 0,
          top: 0,
          background: isSnipping ? 'transparent' : 'rgba(0, 0, 0, 0.7)',
          backdropFilter: isSnipping ? 'none' : 'blur(3px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {showSnippingMsg && (
          <>
            {modal.openModal({
              options: {
                maxHeight: '100%',
                maxWidth: '100%'
              },
              children: (
                <div id={'modal_wrapper'} sx={{ height: '100vH', width: '100vW', padding: '20%' }}>
                  <Box
                    sx={{
                      variant: 'cards.modal',
                      p: 2,
                      display: 'flex',
                      borderRadius: '8px',
                      width: '97%',
                      height: '100px',
                      justifyContent: 'space-between'
                    }}
                  >
                    <ModalDialogTitle
                      sx={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        width: 'auto',
                        maxWidth: 'auto',
                        height: 'auto'
                      }}
                    >
                      <Typography sx={{ p: 4, fontWeight: 'bold' }}>
                        <FormattedMessage
                          defaultMessage="Screenshot mode"
                          id="li3LNc"
                          description="Title for an editor mode where the user is prompted to take a screenshot of the screen"
                        />
                      </Typography>
                      <Typography sx={{ px: 4 }}>
                        <FormattedMessage
                          defaultMessage="You are in screenshot mode. After closing this modal, click and drag your mouse on the screen to create a screenshot of a surface/surfaces."
                          id="buuK3l"
                        />
                      </Typography>
                    </ModalDialogTitle>
                    <Box sx={{ px: 2, py: 2 }}>
                      <ButtonIcon onClick={() => actions()}>
                        <MdClose size={20} />
                      </ButtonIcon>
                    </Box>
                  </Box>
                </div>
              )
            })}
          </>
        )}
      </Box>

      <Box
        ref={$snippingArea}
        style={{
          display: isSnipping ? 'revert' : 'none',
          position: 'fixed',
          left: 0,
          top: 0,
          background: 'transparent',
          border: '2px solid white'
        }}
      />
    </BodyPortal>
  );
}
