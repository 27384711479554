import { applyPatch } from 'fast-json-patch';
import { observable, makeObservable, action, toJS } from 'mobx';
import { EventEmitter } from 'events';
import { Patch, UndoRedoPatch } from '@/lib/mobx/mobx-undo-redo/get-undo-redo-patch';
import { deepObserveWithUndoRedoPatches } from '@/lib/mobx/mobx-undo-redo/deep-observe-with-patches';

export interface IHistoryStoreOptions {
  initialStack?: UndoRedoPatch[];
}

export class HistoryStore<T = {}> extends EventEmitter {
  private debugChangeCount: number = 0;

  /** The (observable) document. */
  private document: T = {} as T;

  /** Whether the history is paused. */
  private isPaused: boolean = false;

  /** Whether the history manager is disposed. */
  private isDisposed: boolean = false;

  /** The current history frame. */
  private pointer: number = -1;

  /** An array of JSON patches that represent the history of the document. */
  private _stack: UndoRedoPatch[] = [];

  /** Whether we're applying a patch. When true, we'll skip the next frame from being committed. */
  private isPatching: boolean = false;

  /** Whether the document changed while the history was paused. */
  private didChangeWhilePaused: boolean = false;

  /** A disposable for the history reaction. */
  private readonly _disposable?: () => void;

  public constructor(initialDocument: T, options?: IHistoryStoreOptions) {
    super();
    this.document = initialDocument;
    this._stack = options?.initialStack ?? [];

    makeObservable<HistoryStore<unknown>, 'document' | 'patchDocument'>(this, {
      document: observable,
      patchDocument: action
    });
    this._disposable = deepObserveWithUndoRedoPatches(this.document, this.handleDocumentChange);
  }

  /**
   * Called by deepObserve when the document changes.
   * @param undo - The undo patch
   * @param redo - The redo patch
   * @private
   */
  private handleDocumentChange = (undo: Patch, redo: Patch) => {
    const { didChangeWhilePaused, isPatching, isPaused, _stack } = this;

    this.debugChangeCount++;

    const undoredo = { undo, redo };

    // Emit a change event with the patches
    this.emit('change', undoredo);

    // If the change was causd by patching the document...
    if (isPatching) {
      // Turn off the isPatching flag and don't create a new patch
      this.isPatching = false;
      return;
    }

    const len = _stack.length;

    if (isPaused) {
      // If paused...
      // If this is the first change since we paused...
      if (!didChangeWhilePaused) {
        // Mark that the document changed while history was paused
        this.didChangeWhilePaused = true;

        // Increment pointer
        this.pointer++;

        // Remove any pending redos
        if (len > this.pointer) {
          _stack.splice(this.pointer, len - this.pointer);
        }

        // Add the new undo / redo patch
        _stack.push(undoredo);
      } else {
        // If we've already changed while paused, then push the
        // new "redo" operations to the current patch's "redo".
        // No need to trim here, as we will have already trimmed.
        _stack[this.pointer]!.redo.push(...redo);
      }
    } else {
      // If not paused...

      // Increment pointer
      this.pointer++;

      // Remove any pending redos
      if (len > this.pointer) {
        _stack.splice(this.pointer, len - this.pointer);
      }

      // Add the new undo / redo patch
      _stack.push(undoredo);
      this.emit('commit', null);
    }
  };

  /**
   * An action that applies a patch to the document.
   * @param patch - The patch to apply.
   * @public
   */
  public patchDocument = (patch: Patch) => {
    const { document } = this;

    if (this.isPatching) {
      throw Error('Tried to patch while patching');
    }

    this.isPatching = true;
    try {
      applyPatch(document, patch);
    } catch (e) {
      console.error(e, patch);
    }

    return this;
  };

  /**
   * Dispose the history. A disposed history will no longer respond to changes in the document.
   * @public
   */
  private dispose = () => {
    this._disposable?.();
  };

  /**
   * Undo to the previous frame.
   * @public
   */
  public undo = () => {
    const { isPaused, pointer, _stack, didChangeWhilePaused } = this;

    if (this.isPatching) {
      throw Error('Tried to undo while patching');
    }

    if (isPaused) {
      this.resume(); // Resume if paused
    }

    // If we can undo...
    if (pointer >= 0 || didChangeWhilePaused) {
      // Patch in the pointed-to undoredo's redo patch
      this.patchDocument(_stack[pointer]!.undo);

      // Decrement the pointer
      this.pointer--;

      this.emit('commit', null);
      this.emit('undo', null);
    }

    return this;
  };

  /**
   * Redo to the next frame.
   * @public
   */
  public redo = () => {
    const { isPaused, pointer, _stack } = this;

    if (this.isPatching) {
      throw Error('Tried to redo while patching');
    }

    if (isPaused) {
      this.resume(); // Resume if paused
    }

    // If we can redo...
    if (pointer < _stack.length - 1) {
      // Increment the pointer
      this.pointer++;

      // Patch in the pointed-to undoredo's redo patch
      this.patchDocument(_stack[this.pointer]!.redo);

      this.emit('commit', null);
      this.emit('redo', null);
    }

    return this;
  };

  /**
   * Pause the history.
   * @public
   */
  public pause = () => {
    const { isPaused } = this;

    if (this.isPatching) {
      throw Error('Tried to pause while patching');
    }

    // If we can pause...
    if (!isPaused) {
      this.isPaused = true;
      this.didChangeWhilePaused = false;

      this.emit('paused-history');
    }

    return this;
  };

  /**
   * Resume (unpause) the history.
   * @public
   */
  public resume = () => {
    const { isPaused, didChangeWhilePaused } = this;

    if (this.isPatching) {
      throw Error('Tried to resume while patching');
    }

    if (isPaused) {
      this.isPaused = false;
      this.didChangeWhilePaused = false;

      if (didChangeWhilePaused) {
        this.emit('commit', null);
      }

      this.emit('resumed-history');
    }
    return this;
  };

  public get stack() {
    return this._stack;
  }

  public get canUndo() {
    /**
     * This is a hack to force mobx to recompute the canUndo value.
     * We can't make `pointer` and `didChangeWhilePaused` observable because
     * they are updated in the `handleDocumentChange` method, which is called
     * whenever the document changes. If we made them observable, then we would
     * get an infinite loop.
     * */
    toJS(this.document);

    return this.pointer >= 0 || this.didChangeWhilePaused;
  }

  public get canRedo() {
    // Same as canUndo
    toJS(this.document);

    return this.pointer < this._stack.length - 1;
  }
}
