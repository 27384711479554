import { createContext } from '@/utils/create-context';
import { InterpreterFrom } from 'xstate';
import { PropsWithChildren } from 'react';
import { useInterpret } from '@xstate/react';
import { EditorServices } from '@/features/editor';

const [useEditorServices, EditorServicesContextProvider] = createContext<{
  screenshotService: InterpreterFrom<typeof EditorServices.Screenshot.screenshotMachine>;
  drawPolygonService: InterpreterFrom<typeof EditorServices.DrawPolygon.drawPolygonMachine>;
}>();

function EditorServicesProvider({ children }: PropsWithChildren<{}>) {
  const screenshotService = useInterpret(EditorServices.Screenshot.screenshotMachine);
  const drawPolygonService = useInterpret(EditorServices.DrawPolygon.drawPolygonMachine);

  return (
    <EditorServicesContextProvider value={{ screenshotService, drawPolygonService }}>
      {children}
    </EditorServicesContextProvider>
  );
}

export { useEditorServices, EditorServicesProvider };
