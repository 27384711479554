import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { ESDEC_ROOF_SIDE_TYPE } from '../esdec-types';
import { FormattedMessage, useIntl } from 'react-intl';

export const EsdecRoofSideTypeInput = observer(function EsdecRoofSideTypeInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const intl = useIntl();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  const roofSideTypeOptions = ESDEC_ROOF_SIDE_TYPE.map((roofSideType) => ({
    label: roofSideType,
    value: roofSideType
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Roof Side',
        id: 'WKF/Ot'
      })}
      placeholder={<FormattedMessage defaultMessage="Select Roof Side" id="vE4blL" />}
      name="roofSideType"
      options={roofSideTypeOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateRoofSideType(value.value);
        }
      }}
      value={roofSideTypeOptions.find((roofSideTypeOption) => roofSideTypeOption.value === surfaceEsdec.side)}
    />
  );
});
