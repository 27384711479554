/** @jsxImportSource theme-ui */
import { GoogleMapContextMenu } from '@/features/google-map/components/google-map-context-menu';
import { RenderSurfaces } from '@/features/google-map/utils/render-surfaces';
import { RenderShapeUnderPointer } from '@/features/google-map/utils/render-shape-under-pointer';
import { RenderInProgressPolygon } from '@/features/google-map/utils/render-in-progress-polygon';
import { RenderPanelsOnSurfaces } from '@/features/google-map/utils/render-panels-on-surfaces';
import { RenderObstacles } from '@/features/google-map/utils/render-obstacles';
import React from 'react';
import { Box } from 'theme-ui';
import { RenderSelectionBox, useSelectionMapEvents } from '@/features/google-map/utils/selection';
import { createContext } from '@/utils/create-context';
import { ShapeManager } from '@/features/google-map/utils/shapes/shape-manager';
import { RenderRuler } from '@/features/google-map/utils/render-ruler';
import { MapControls } from '@/features/google-map/components/map-controls';
import { RenderSurfaceRidges } from '@/features/google-map/utils/render-surface-ridges';
import {
  RenderLengthLabelsOnObstacles,
  RenderLengthLabelsOnSurfaces
} from '@/features/google-map/utils/render-length-labels';
import { R } from '@/lib/remeda';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';
import { MapBottomBar } from '@/features/google-map/components/map-bottom-bar';
import { MARKER_LABEL_CLASSNAME } from '@/features/google-map/utils/shapes/marker';
import { useOverlayImageMap } from '@/features/google-map/utils/map-types/overlay-image-map';
import { useMapEvents } from '@/features/google-map/utils/use-map-events';
import { useSyncMapData } from '@/features/google-map/utils/use-sync-map-data';
import { IPoint } from '@/utils/gis/types';
import { MapOverlayImageAdjust } from '@/features/google-map/features/map-overlay-image/map-overlay-image-adjust';
import { MapScreenshot } from '@/features/google-map/components/map-screenshot';
import { MapUndoRedoControls } from '@/features/google-map/components/map-undo-redo-controls';
import { RenderOptimizers } from '@/features/google-map/utils/render-optimizers';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { autorun } from 'mobx';
import { MapTopRightControls } from '@/features/google-map/components/map-top-right-controls';
import { useGoogleMap } from '@/features/google-map/hooks/use-google-map';

const [useGoogleMapContext, GoogleMapProvider] = createContext<{
  map: google.maps.Map;
}>();

const SetupMapRendering = observer(function SetupMapRendering(): JSX.Element {
  useMapEvents();
  useSyncMapData();
  useOverlayImageMap();
  useSelectionMapEvents();

  return (
    <React.Fragment>
      <MapOverlayImageAdjust />
      <MapScreenshot />

      <RenderSurfaceRidges />
      <RenderLengthLabelsOnObstacles />
      <RenderLengthLabelsOnSurfaces />
      <RenderPanelsOnSurfaces />
      <RenderObstacles />
      <RenderSelectionBox />
      <RenderSurfaces />
      <RenderShapeUnderPointer />
      <RenderOptimizers />
      <RenderRuler />
      <RenderInProgressPolygon />
    </React.Fragment>
  );
});

export let globalShapeManager: ShapeManager | undefined;

export const GOOGLE_MAPS_OVERLAY_MAP_INDICES = {
  OVERLAY_IMAGE_MAP: 1,
  SUPER_ZOOM_MAP: 0
} as const;

export const GoogleMap = observer(function GoogleMap(): JSX.Element {
  const store = useEditorStore();
  const [shapeManager, setShapeManager] = React.useState<ShapeManager>();

  const $mapElement = React.useRef<HTMLDivElement>();

  const isMapDraggable = !store.state.isSelectionInProgress;
  const { mapTypeId, mapCenter } = store.mapData;

  const onMapLoaded = React.useCallback((googleMap: google.maps.Map) => {
    const sm = new ShapeManager(googleMap);
    setShapeManager(sm);
    globalShapeManager = sm;
  }, []);

  const { map, status } = useGoogleMap({
    mapDiv: $mapElement.current!,
    onMapLoaded
  });

  const recenterMap = React.useCallback(
    function recenterMap(coordinates: IPoint): void {
      if (R.isNil(coordinates) || R.isNil(map)) {
        return;
      }
      map.setCenter(pointToLatLng(coordinates));
      map.setZoom(20);
    },
    [map]
  );

  React.useEffect(() => {
    if (R.isNil(map) || status !== 'loaded') {
      return;
    }
    map.setMapTypeId(mapTypeId);
  }, [map, mapTypeId, status]);

  React.useEffect(() => {
    const dispose = autorun(() => {
      const { coordinates } = store.projectInformation;

      if (R.isNil(coordinates) || R.isDefined(mapCenter)) {
        return;
      }

      recenterMap(coordinates);
    });

    return () => {
      dispose();
    };
  }, [mapCenter, store, recenterMap]);

  React.useEffect(() => {
    if (!map) {
      return;
    }

    const dispose = autorun(() => {
      map.setZoom(store.mapData.zoom);
    });

    return () => {
      dispose();
    };
  }, [map, store.mapData.zoom]);

  React.useEffect(() => {
    map?.setOptions({
      gestureHandling: isMapDraggable ? 'auto' : 'none'
    });
  }, [isMapDraggable, map]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          flex: 1
        }}
      >
        <Box
          sx={{
            height: '100%',
            '& div': { outline: 'none' },
            [`.${MARKER_LABEL_CLASSNAME}`]: {
              background: 'rgba(10,10,10,0.7)',
              p: '3px',
              borderRadius: 1,
              boxShadow: 'inset 0 0 0 1px rgba(5,5,5,0.8)'
            }
          }}
          ref={$mapElement}
        />
      </Box>

      {status === 'loaded' && map && shapeManager && (
        <GoogleMapProvider value={{ map }}>
          <SetupMapRendering />
          <MapControls
            onRecenter={() => {
              const { coordinates } = store.projectInformation;
              if (R.isNil(coordinates)) {
                return;
              }
              recenterMap(coordinates);
            }}
          />
          <GoogleMapContextMenu />
          <MapBottomBar />
          <MapUndoRedoControls />
          <MapTopRightControls />
        </GoogleMapProvider>
      )}
    </Box>
  );
});

export { useGoogleMapContext, GoogleMapProvider };
