import { useContextMenuStore } from '@/features/editor/stores/use-context-menu-store';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { getPointFromMouseEvent } from '@/features/google-map/utils/get-point-from-mouse-event';
import { getPositionFromMouseEvent } from '@/features/google-map/utils/get-position-from-mouse-event';
import React from 'react';
import { MdArrowUpward } from 'react-icons/md';
import * as R from 'remeda';
import { useObstacles } from '@/features/editor/utils/use-obstacles';
import { IEnrichedSide, IEnrichedSideWithParentId } from '@/utils/drawing/types';
import { MapLengthLabel } from '@/features/google-map/components/shapes/map-length-label';
import { formatLengthMarker } from '@/features/editor/utils/format-length-marker';
import { createLengthMarkerForLine } from '@/utils/gis/create-length-marker-for-line';
import { getSurfaceSideId } from '@/utils/drawing/get-surface-side-id';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

function getSides<T extends { sides: IEnrichedSide[]; id: string }>(
  values: T[]
): IEnrichedSideWithParentId[] {
  return R.pipe(
    values,
    R.map((parent) => parent.sides.map((side) => ({ ...side, parentId: parent.id }))),
    R.flatten()
  );
}

export const RenderLengthLabelsOnSurfaces = observer(function RenderLengthLabelsOnSurfaces() {
  const { openContextMenu } = useContextMenuStore();
  const store = useEditorStore();

  const surfaces = useSurfaces();
  let selectedSurfaceId = store.surfaces.selectedSurfaceId;

  if (!selectedSurfaceId) {
    selectedSurfaceId = surfaces[0]?.id;
  }

  const sides = React.useMemo(
    () => getSides(surfaces.filter((surface) => surface.id === selectedSurfaceId)),
    [surfaces, selectedSurfaceId]
  );

  return (
    <React.Fragment>
      {sides.map((side) => (
        <MapLengthLabel
          onRightClick={(event) => {
            const surface = surfaces.find((surface) => surface.id === side.parentId);
            if (surface === undefined) {
              return;
            }

            const point = getPointFromMouseEvent(event);
            if (point === undefined) {
              return;
            }
            openContextMenu({
              position: getPositionFromMouseEvent(event),
              items: [
                {
                  key: 'add-ridge',
                  children: [
                    <MdArrowUpward key="icon" />,
                    <span key="text">
                      <FormattedMessage defaultMessage="Set ridge" id="WO8Lon" />
                    </span>
                  ],
                  onAction: () => {
                    store.surfaces.updateSurfaceRidge({
                      surfaceId: surface.id,
                      ridge: point
                    });
                  }
                },
                {
                  key: 'align-with-side',
                  children: [
                    <MdArrowUpward key="icon" />,
                    <span key="text">
                      <FormattedMessage defaultMessage="Align with side" id="SoUOu0" />
                    </span>
                  ],
                  onAction: () => {
                    store.surfaces.setSurfaceDirectionOppositeOfPoint({
                      surfaceId: surface.id,
                      point
                    });
                  }
                }
              ]
            });
          }}
          clickable
          key={getSurfaceSideId(side)}
          label={formatLengthMarker(side.length)}
          point={createLengthMarkerForLine(side).middle}
        />
      ))}
    </React.Fragment>
  );
});

export const RenderLengthLabelsOnObstacles = observer(function RenderLengthLabelsOnObstacles() {
  const obstacles = useObstacles();
  const store = useEditorStore();
  const sides = getSides(obstacles).filter((side) => {
    return (
      store.obstacles.hoveredObstacleIds.has(side.parentId) &&
      !store.obstacles.draggingObstacleIds.has(side.parentId)
    );
  });

  return (
    <React.Fragment>
      {sides.map((side) => (
        <MapLengthLabel
          label={formatLengthMarker(side.length)}
          point={createLengthMarkerForLine(side).middle}
          key={getSurfaceSideId(side)}
        />
      ))}
    </React.Fragment>
  );
});
