import { useContextMenuStore } from '@/features/editor/stores/use-context-menu-store';
import { getPositionFromMouseEvent } from '@/features/google-map/utils/get-position-from-mouse-event';
import React from 'react';
import { MdContentCopy, MdDelete } from 'react-icons/md';
import { themeColors } from '@/lib/theme-ui/colors';
import { MapEditablePolygon } from '@/features/google-map/components/shapes/map-editable-polygon';
import { useMapDrag } from '@/features/google-map/utils/render-panels-on-surfaces';
import { getPointFromMouseEvent } from '@/features/google-map/utils/get-point-from-mouse-event';
import { Gis } from '@/features/editor/utils/gis';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

export const RenderObstacles = observer(function RenderObstacles() {
  const { openContextMenu } = useContextMenuStore();
  const { onStart, onEnd } = useMapDrag();
  const store = useEditorStore();

  const obstacles = store.obstacles.obstacles;
  const isDraggable = store.state.isDragging;
  const isSelectable = store.state.isSelecting;

  return (
    <React.Fragment>
      {obstacles.map((obstacle) => {
        const isDragged = store.obstacles.draggingObstacleIds.has(obstacle.id);

        return (
          <MapEditablePolygon
            onMouseOver={() => {
              store.obstacles.obstaclesHover(obstacle.id);
            }}
            onMouseOut={() => {
              store.obstacles.obstaclesUnhover(obstacle.id);
            }}
            onPointUpdate={store.obstacles.obstaclePointUpdate}
            onPointDelete={({ polygonId, indexAt }) => {
              store.obstacles.obstaclePointUpdate({
                mode: 'remove',
                polygonId,
                indexAt
              });
            }}
            zIndex={GOOGLE_MAP_Z_INDICES.OBSTACLE}
            isEditable={(isDraggable || isSelectable) && !isDragged}
            clickable={isDraggable || isSelectable}
            draggable={isDraggable || isSelectable}
            id={obstacle.id}
            key={obstacle.id}
            fillColor={themeColors.obstacle}
            strokeColor={themeColors.obstacle}
            path={obstacle.path}
            onDragStart={(event) => {
              store.obstacles.obstaclesDragStart(obstacle.id);
              const point = getPointFromMouseEvent(event);
              if (point) {
                onStart(point);
              }
            }}
            onDragEnd={(event) => {
              store.obstacles.obstaclesDragEnd(obstacle.id);
              const point = getPointFromMouseEvent(event);
              if (!point) {
                return;
              }
              const { distanceDelta, bearingDelta } = onEnd(point);
              store.obstacles.obstacleUpdate(obstacle.id, (obstacle) => {
                obstacle.path = Gis.transformTranslate(obstacle.path, distanceDelta, bearingDelta);
              });
            }}
            onRightClick={(event) => {
              openContextMenu({
                position: getPositionFromMouseEvent(event),
                items: [
                  {
                    key: 'delete-obstacle',
                    children: [
                      <MdDelete key="icon" />,
                      <span key="text">
                        <FormattedMessage defaultMessage="Delete Obstacle" id="HADRCH" />
                      </span>
                    ],
                    onAction: () => {
                      store.obstacles.obstacleDelete(obstacle.id);
                    }
                  },
                  {
                    key: 'copy-obstacle',
                    children: [
                      <MdContentCopy key="icon" />,
                      <span key="text">
                        <FormattedMessage defaultMessage="Copy Obstacle" id="VaZ0Mj" />
                      </span>
                    ],
                    onAction: () => {
                      store.obstacles.obstacleCopy(obstacle);
                    }
                  }
                ]
              });
            }}
          />
        );
      })}
    </React.Fragment>
  );
});
