import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { ESDEC_ROOF_STRUCTURE_TYPE } from '../esdec-types';
import { FormattedMessage, useIntl } from 'react-intl';

export const EsdecRoofStructureInput = observer(function EsdecRoofStructureInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const intl = useIntl();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  const roofStructureOptions = ESDEC_ROOF_STRUCTURE_TYPE.roofStructure.map((roofStructure) => ({
    label: roofStructure,
    value: roofStructure
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Roof Structure',
        id: '2saSbe'
      })}
      placeholder={<FormattedMessage defaultMessage="Select Roof Structure" id="UGo/9T" />}
      name="roofStructure"
      options={roofStructureOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateRoofStructure(value.value);
        }
      }}
      value={roofStructureOptions.find(
        (roofStructureOption) => roofStructureOption.value === surfaceEsdec.roofStructure
      )}
    />
  );
});
