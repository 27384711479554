import { IGetEditorResultsApiResponse } from '@/features/editor/api';
import { Text, View } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';

export function EditorResultsBannerPrintable({
  totEarning,
  yearEarning,
  warrantyTimeEmission,
  warrantyTimeProduction,
  returnInvestment
}: IGetEditorResultsApiResponse['congratsTextVars']): JSX.Element {
  return (
    <View
      style={{
        color: 'white',
        backgroundColor: '#039855',
        paddingLeft: 26,
        paddingTop: 40,
        paddingBottom: 36,
        borderRadius: 11
      }}
    >
      <View style={{ maxWidth: 425 }}>
        <Text style={{ fontSize: 14, marginBottom: 6, fontWeight: 600 }}>
          <FormattedMessage defaultMessage="Grattis!" id="gPmll5" description="Heading for the banner" />
        </Text>
        <Text style={{ fontSize: 12, marginBottom: 11 }}>
          <FormattedMessage
            defaultMessage="Ditt tak är perfekt för solen!"
            id="yeI08C"
            description="Label for the banner"
          />
        </Text>
        <View style={{ fontSize: 9 }}>
          <Text style={{ marginBottom: 11 }}>
            <FormattedMessage
              defaultMessage="Enligt beräkningen tjänar du minst "
              id="DZfO2O"
              description="Paragraph for the banner"
            />{' '}
            {totEarning}{' '}
            <FormattedMessage
              defaultMessage="genom att installera solceller på ditt tak, vilket motsvarar en avkastning på"
              id="nHRtSm"
              description="Paragraph for the banner"
            />{' '}
            {returnInvestment}
            <FormattedMessage
              defaultMessage=". Redan i år"
              id="aaelos"
              description="Paragraph for the banner"
            />{' '}
            {yearEarning}
            <FormattedMessage
              defaultMessage=", får du mer pengar i plånboken."
              id="UH3aey"
              description="Paragraph for the banner"
            />
          </Text>
          <Text>
            <FormattedMessage
              defaultMessage="Under garantiperioden kommer din anläggning att producera ungefär"
              id="gMr8RE"
              description="Paragraph for the banner"
            />{' '}
            {warrantyTimeProduction}
            <FormattedMessage
              defaultMessage=" , vilket motsvarar en utsläppsminskning på ca"
              id="46HtBt"
              description="Paragraph for the banner"
            />{' '}
            {warrantyTimeEmission}{' '}
            <FormattedMessage
              defaultMessage="av koldioxid."
              id="3No9WQ"
              description="Paragraph for the banner"
            />
          </Text>
        </View>
      </View>
    </View>
  );
}
