import { InferMutationOptions } from '@/utils/types';
import { getFilesUrl, uploadFiles } from './functions';
import { useMutation } from '@tanstack/react-query';

export function useUploadFiles(options?: InferMutationOptions<typeof uploadFiles>) {
  return useMutation(uploadFiles, options);
}

export function useGetFilesUrl(options?: InferMutationOptions<typeof getFilesUrl>) {
  return useMutation(getFilesUrl, options);
}
