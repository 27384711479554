import { IPoint } from '@/utils/gis/types';

interface IModeBase {
  indexAt: number;
  mutate?: boolean;
  path: IPoint[];
}
type ModeUpdate = IModeBase & {
  mode: 'update';
  point: IPoint;
};
type ModeRemove = IModeBase & {
  mode: 'remove';
};
type ModeInsert = IModeBase & {
  mode: 'insert';
  point: IPoint;
};

type Options = ModeUpdate | ModeRemove | ModeInsert;
export type GeoJsonPolygonUpdateMode = Options['mode'];

// Implements update operations while keeping first/last equality invariant true.
export function geojsonPolygonUpdate({ path, indexAt, mutate = true, ...options }: Options): IPoint[] {
  if (indexAt >= path.length || indexAt < 0) {
    return path;
  }

  const newPath = mutate ? path : [...path];

  if (options.mode === 'update') {
    const { point } = options;
    if (indexAt === 0) {
      newPath[0] = point;
      newPath[newPath.length - 1] = point;
    } else if (indexAt === newPath.length - 1) {
      newPath.splice(indexAt, 1, point);
      newPath.push(newPath[0]!);
    } else {
      newPath[indexAt] = point;
    }
  }

  if (options.mode === 'insert') {
    const { point } = options;
    if (newPath.length === 1) {
      newPath.push(point);
      newPath.push(newPath[0]!);
    } else if (indexAt === 0) {
      newPath.splice(indexAt, 0, point);
      newPath[newPath.length - 1] = point;
    } else {
      newPath.splice(indexAt, 0, point);
    }
  }

  if (options.mode === 'remove') {
    if (indexAt === 0) {
      newPath.splice(0, 1);
      newPath[newPath.length - 1] = newPath[0]!;
    } else if (indexAt === newPath.length - 1) {
      newPath.splice(indexAt - 1, 1);
    } else {
      newPath.splice(indexAt, 1);
    }
  }

  return newPath;
}
