import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { EmptyPropsWithChildren } from '@/utils/types';
import { IconsPdf } from '@/assets';
import { IBasicInformationProps } from '@/features/editor-results/components/editor-results-basic-information/editor-results-basic-information';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICalculationAssumptionsProps } from '@/features/editor-results/components/editor-results-calculation-assumptions';
import { R } from '@/lib/remeda';
import { LinkPdf } from '@/components/link/link';
import { createHttpsUrl } from '@/features/editor-results/utils/http/create-https-url';
import { IntlSpace } from '@/features/intl/components/intl-space';

function Row({ children, icon }: { children: React.ReactNode; icon: React.ReactNode }): JSX.Element {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 2, maxWidth: '200px' }}>
      <View style={{ width: 16, height: 16, alignItems: 'center', justifyContent: 'center', marginRight: 6 }}>
        {icon}
      </View>
      <Text style={{ fontSize: 9, fontWeight: 500, color: '#303540' }}>{children}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  cardWrapper: {
    padding: 16,
    borderRadius: 4,
    border: '1px solid #D4D9E2',
    flexGrow: 1,
    backgroundColor: 'white'
  },
  separator: {
    height: 1,
    backgroundColor: '#1DBA6C',
    marginVertical: 12
  },
  subtitle: {
    color: '#13AB62',
    fontWeight: 500,
    fontSize: 10,
    marginBottom: 10,
    textTransform: 'capitalize'
  }
});

function Header({ children }: EmptyPropsWithChildren): JSX.Element {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <View style={{ padding: 4, borderRadius: 4, backgroundColor: '#E6F7ED', marginRight: 12 }}>
        <IconsPdf.Business />
      </View>
      <Text style={{ fontSize: 10, textTransform: 'uppercase', fontWeight: 500 }}>{children}</Text>
    </View>
  );
}

function EditorResultsBasicInformationPrintable({
  customerOrganizationInfo,
  projectInformation
}: Omit<IBasicInformationProps, 'onEdit'>): JSX.Element {
  return (
    <View style={styles.cardWrapper}>
      <Header>
        <FormattedMessage defaultMessage="Kontakt information" id="cXUE4o" />
      </Header>

      <View style={styles.separator} />

      <Text style={styles.subtitle}>
        <FormattedMessage defaultMessage="Företags information" id="0ItI1G" />
      </Text>

      {customerOrganizationInfo.organizationInfo.address && (
        <Row icon={<IconsPdf.LocationPin />}>
          <Text>{customerOrganizationInfo.organizationInfo.address}</Text>
        </Row>
      )}

      {customerOrganizationInfo.organizationInfo.website && (
        <Row icon={<IconsPdf.Language />}>
          <LinkPdf src={createHttpsUrl(customerOrganizationInfo.organizationInfo.website)}>
            <Text>{customerOrganizationInfo.organizationInfo.website}</Text>
          </LinkPdf>
        </Row>
      )}

      {customerOrganizationInfo.organizationInfo.sellerName && (
        <Row icon={<IconsPdf.User />}>
          <Text>{customerOrganizationInfo.organizationInfo.sellerName}</Text>
        </Row>
      )}

      {customerOrganizationInfo.organizationInfo.sellerPhone && (
        <Row icon={<IconsPdf.Phone />}>
          <Text>{customerOrganizationInfo.organizationInfo.sellerPhone}</Text>
        </Row>
      )}

      {customerOrganizationInfo.organizationInfo.sellerMail && (
        <Row icon={<IconsPdf.Email />}>
          <Text>{customerOrganizationInfo.organizationInfo.sellerMail}</Text>
        </Row>
      )}

      <Text
        style={{
          ...styles.subtitle,
          marginTop: 20
        }}
      >
        <FormattedMessage defaultMessage="Kalkyl för" id="RDi86B" />
      </Text>

      <Row icon={<IconsPdf.UnorderedList />}>
        <Text>
          <FormattedMessage defaultMessage="Referens nummer" id="H7AdL7" />
          <IntlSpace />
          {customerOrganizationInfo.customerInfo.referenceNumber}
        </Text>
      </Row>

      {projectInformation.clientFullName && (
        <Row icon={<IconsPdf.User />}>
          <Text>{projectInformation.clientFullName}</Text>
        </Row>
      )}

      {projectInformation.clientCompanyName && (
        <Row icon={<IconsPdf.Business />}>
          <Text>{projectInformation.clientCompanyName}</Text>
        </Row>
      )}

      <Row icon={<IconsPdf.Email />}>
        <LinkPdf src={`mailto: ${customerOrganizationInfo.customerInfo.email}`}>
          <Text>{customerOrganizationInfo.customerInfo.email}</Text>
        </LinkPdf>
      </Row>

      {projectInformation.clientPhone && (
        <Row icon={<IconsPdf.Phone />}>
          <Text>{projectInformation.clientPhone}</Text>
        </Row>
      )}

      <Row icon={<IconsPdf.LocationPin />}>
        <Text>{customerOrganizationInfo.customerInfo.address}</Text>
      </Row>
    </View>
  );
}

function CalculationAssumptionsDataPoint({
  value,
  label,
  description
}: {
  description: React.ReactNode;
  label?: React.ReactNode;
  value: React.ReactNode;
}): JSX.Element {
  return (
    <View style={{ marginBottom: 12 }}>
      <Text style={{ fontSize: 11, fontWeight: 500, textTransform: 'uppercase' }}>
        <Text style={{ color: '#13AB62' }}>{value}</Text>
        <IntlSpace />
        {label ? <Text style={{ color: '#667085' }}>{label}</Text> : null}
      </Text>
      <Text style={{ fontSize: 9, color: 'rgba(102,112,133,0.80)', marginTop: 2 }}>{description}</Text>
    </View>
  );
}

function EditorResultsCalculationAssumptionsPrintable({
  annualElectricityConsumption,
  electricityPrices,
  financialSupport,
  clientType
}: Omit<ICalculationAssumptionsProps, 'onEdit'>): JSX.Element {
  const intl = useIntl();

  if (R.isNil(electricityPrices)) {
    return <React.Fragment />;
  }
  return (
    <View style={styles.cardWrapper}>
      <Header>
        <FormattedMessage defaultMessage="Calculation assumptions" id="q32JFs" />
      </Header>

      <View style={styles.separator} />

      <CalculationAssumptionsDataPoint
        description={<FormattedMessage defaultMessage="Annual electricity consumption" id="IwaO/0" />}
        value={annualElectricityConsumption}
        label="kwh"
      />

      <CalculationAssumptionsDataPoint
        description={<FormattedMessage defaultMessage="Revenue sold electricity" id="0djgNH" />}
        value={intl.formatNumber(electricityPrices.sellingCostCents / 100, {
          style: 'currency',
          currency: 'SEK'
        })}
        label={<FormattedMessage defaultMessage="per kwh" id="U/frwm" />}
      />

      <CalculationAssumptionsDataPoint
        description={<FormattedMessage defaultMessage="Cost purchased electricity" id="bH31Y5" />}
        value={intl.formatNumber(electricityPrices.purchaseCostCents / 100, {
          style: 'currency',
          currency: 'SEK'
        })}
        label={<FormattedMessage defaultMessage="per kwh" id="U/frwm" />}
      />

      <CalculationAssumptionsDataPoint
        description={<FormattedMessage defaultMessage="Price development" id="mU8pNg" />}
        value={intl.formatNumber(electricityPrices.priceDevelopmentPercentPerYear, {
          style: 'percent'
        })}
        label={<FormattedMessage defaultMessage="per year" id="qxHg3H" />}
      />

      <CalculationAssumptionsDataPoint
        description={<FormattedMessage defaultMessage="Investment support" id="lmMKGn" />}
        value={financialSupport}
        label={<FormattedMessage defaultMessage="deduction" id="hhTMkd" />}
      />

      <CalculationAssumptionsDataPoint
        description={<FormattedMessage defaultMessage="Private person including VAT" id="pfON5q" />}
        value={
          clientType === 'privatePerson' ? (
            <FormattedMessage defaultMessage="Yes" id="a5msuh" />
          ) : (
            <FormattedMessage defaultMessage="No" id="oUWADl" />
          )
        }
      />
    </View>
  );
}

export { EditorResultsCalculationAssumptionsPrintable, EditorResultsBasicInformationPrintable };
