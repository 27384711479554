/** @jsxImportSource theme-ui */
import { GroupBase, MultiValueProps, StylesConfig, ThemeConfig } from 'react-select';
import { themeBoxShadows } from '@/lib/theme-ui/shadows';
import { themeColors } from '@/lib/theme-ui/colors';
import { transparentize } from 'polished';
import React from 'react';
import { ChipSmall } from '@/components/chip/chip';
import { MdExpandMore, MdOutlineClear } from 'react-icons/md';
import { IconButton } from 'theme-ui';
import { z } from 'zod';

// Select complains about incorrect generics, but we don't use
// them anyway, so we can safely disable the rule
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectStyles: StylesConfig<any, any, any> = {
  menu: (base) => {
    return {
      ...base,
      left: 0,
      boxShadow: themeBoxShadows['1dp'],
      fontFamily: 'inherit',
      fontSize: '14px'
    };
  },
  control: (base) => {
    return {
      ...base,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: 'none',
      boxShadow: 'none',
      fontFamily: 'inherit',
      backgroundColor: 'transparent'
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      paddingLeft: '14px',
      gap: 4,
      fontFamily: 'inherit'
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none'
    };
  },
  indicatorsContainer: (base) => ({
    ...base,
    '& > div:first-of-type': {
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  container: (base) => {
    return {
      ...base,
      border: 'none',
      width: '100%',
      padding: '8px 0px',

      // Fixes Firefox issue described here: https://github.com/JedWatson/react-select/issues/5170
      display: 'grid',
      gridTemplateColumns: 'minmax(0, 1fr)',

      minWidth: 0,
      background: 'none',
      boxSizing: 'content-box',
      letterSpacing: 'inherit',
      outline: 'none',
      color: 'inherit',
      position: 'relative',
      fontFamily: 'inherit',
      fontSize: '14px'
    };
  }
};

const selectTheme: ThemeConfig = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: themeColors.primary,
      primary25: transparentize(0.75, themeColors.primary),
      primary50: transparentize(0.5, themeColors.primary),
      primary75: transparentize(0.25, themeColors.primary),

      neutral90: transparentize(0.1, themeColors.text),
      neutral80: transparentize(0.2, themeColors.text),
      neutral70: transparentize(0.3, themeColors.text),
      neutral60: transparentize(0.4, themeColors.text),
      neutral50: transparentize(0.5, themeColors.text),
      neutral40: transparentize(0.6, themeColors.text),
      neutral30: transparentize(0.7, themeColors.text),
      neutral20: transparentize(0.8, themeColors.text),
      neutral10: transparentize(0.9, themeColors.text),
      neutral5: transparentize(0.95, themeColors.text),
      neutral0: 'white'
    }
  };
};

interface ISelectFieldProps {
  label: string;
  name: string;
  errorMessage?: string;
  helperText?: React.ReactNode;
  isLabelLifted?: boolean;
  required?: boolean;
}

function DropdownIndicator(): JSX.Element {
  return (
    <IconButton
      tabIndex={-1}
      sx={{
        mx: 2,
        right: '14px',
        alignSelf: 'center',
        pointerEvents: 'none',
        fill: 'none',
        color: 'primary'
      }}
    >
      <MdExpandMore size={24} />
    </IconButton>
  );
}

const MultiValueLabelParser = z.object({
  label: z.string()
});

function MultiValue<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>(
  props: MultiValueProps<T, IsMulti, Group>
): JSX.Element {
  const value = MultiValueLabelParser.safeParse(props.data);

  if (!value.success) {
    return <React.Fragment />;
  }

  return (
    <ChipSmall
      iconAppend={
        <div
          sx={{
            variant: 'buttons.icon',
            borderRadius: 'rounded',
            mr: -1,
            ml: 1
          }}
          {...props.removeProps}
        >
          <MdOutlineClear />
        </div>
      }
    >
      {value.data.label}
    </ChipSmall>
  );
}

export { selectStyles, selectTheme, MultiValue, DropdownIndicator };
export type { ISelectFieldProps };
