/* eslint-disable @rushstack/no-new-null */
import { R } from '@/lib/remeda';

/**
 * Map type capable of manually scaling the image for tiles which don't have a
 * corresponding tile at the current zoom level.
 * */
export class ZoomScaleMapType implements google.maps.MapType {
  private _visible: boolean = true;
  private _tiles: HTMLElement[] = [];

  public alt: string | null = null;
  public maxZoom: number = 23;
  public minZoom: number = 0;
  public name: string | null = null;
  public projection: google.maps.Projection | null = null;
  public radius: number = 6378137;
  public tileSize: google.maps.Size = new google.maps.Size(256, 256);

  protected _tileMaxZoom: number;

  private _map: google.maps.Map;

  public constructor(tileMaxZoom: number, map: google.maps.Map) {
    this._tileMaxZoom = tileMaxZoom;
    this._map = map;
  }

  /**
   * When the map's zoom level increases by 1, the number of tiles on the map increases by 4.
   * So a tile that looked like this at zoom level 20:
   * |_|
   * Will look like this at zoom level 21:
   * |_|_|
   * |_|_|
   *
   * To get a scaled image at zoom level 21 (because Google Maps has only images up to zoom level 20),
   * we need to get the image at zoom level 20 and scale it up by 2x horizontally and vertically.
   * We then need to position the image such that each of the resulting four tiles
   * only contains the appropriate portion of the image (top left, top right, bottom left, bottom right).
   * */
  public getTile(
    tile: google.maps.Point | null,
    zoom: number,
    ownerDocument: Document | null
  ): Element | null {
    if (tile === null || ownerDocument === null) {
      return null;
    }

    const tileData = this.getTileData(tile, zoom);
    if (R.isNil(tileData)) {
      return null;
    }

    // If tile already exists then use it
    const t_key = 't_' + tile.x + '_' + tile.y + '_' + zoom;
    for (const tile of this._tiles) {
      if (tile.id === t_key) {
        return tile;
      }
    }

    // If tile doesn't exist then create it
    let div = ownerDocument.createElement('div');
    div.id = t_key;
    div.style.width = this.tileSize.width + 'px';
    div.style.height = this.tileSize.height + 'px';

    const normTile = { x: tile.x, y: tile.y };

    let img = ownerDocument.createElement('img');
    img.style.width = this.tileSize.width + 'px';
    img.style.height = this.tileSize.height + 'px';
    img.style.position = 'absolute';
    img.style.filter = 'inherit';

    let imgBase: HTMLDivElement;

    if (tileData.zoomDiff > 0) {
      const dScale = Math.pow(2, tileData.zoomDiff);
      const dTranslate = (256 * (dScale - 1)) / (dScale * 2);
      const dSize = 256 / dScale;
      const aX = normTile.x % dScale;
      const dX = dTranslate - aX * dSize;
      const aY = normTile.y % dScale;
      const dY = -aY * dSize;

      normTile.x = Math.floor(normTile.x / dScale);
      normTile.y = Math.floor(normTile.y / dScale);

      imgBase = ownerDocument.createElement('div');
      imgBase.style.transform = `scale(${dScale},${dScale}) translate(${dX}px,${dY}px)`;
      imgBase.style.border = '0px';

      img.style.clip = `rect(${aY * dSize}px,${(aX + 1) * dSize}px,${(aY + 1) * dSize}px,${aX * dSize}px)`;

      div.appendChild(imgBase);
    } else {
      imgBase = div;
    }

    img.onload = function () {
      imgBase.appendChild(img);
      // @ts-ignore
      img = null;
      // @ts-ignore
      div = null;
      // @ts-ignore
      imgBase = null;
    };
    img.onerror = function () {
      // @ts-ignore
      img = null;
      // @ts-ignore
      div = null;
      // @ts-ignore
      imgBase = null;
    };
    img.src = tileData.src;

    if (!this._visible) {
      div.style.display = 'none';
    }

    this._tiles.push(div);

    return div;
  }

  protected getTileData(
    point: google.maps.Point,
    tileZoom: number
  ):
    | {
        src: string;
        zoomDiff: number;
      }
    | undefined {
    throw new Error('Not implemented');
  }

  public show(): void {
    this._visible = true;
    for (const tile of this._tiles) {
      tile.style.display = '';
    }
  }

  public hide(): void {
    this._visible = false;
    for (const tile of this._tiles) {
      tile.style.display = 'none';
    }
  }

  public releaseTile(tile: Element | null): void {
    tile = null;
  }
}
