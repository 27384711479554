/** @jsxImportSource theme-ui */
import { CheckboxGroupState, useCheckboxGroupState } from '@react-stately/checkbox';
import { useToggleState } from '@react-stately/toggle';
import {
  useCheckboxGroup,
  useCheckboxGroupItem,
  AriaCheckboxGroupProps,
  AriaCheckboxGroupItemProps,
  AriaCheckboxProps,
  useCheckbox
} from '@react-aria/checkbox';
import { createContext } from '@/utils/create-context';
import { Box, Label } from 'theme-ui';
import React, { DOMAttributes } from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { RadioFocusableIcon } from '../radio-group/radio-group';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { useFocusRing } from '@react-aria/focus';
import { FocusableElement } from '@react-types/shared';

export interface ICheckboxGroupProps extends AriaCheckboxGroupProps {
  children: React.ReactNode;
}
const [useCheckboxGroupContext, CheckboxGroupProvider] = createContext<CheckboxGroupState>();

interface ICheckboxCustomStyle {
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  focusProps: DOMAttributes<FocusableElement>;
  ref: React.Ref<HTMLInputElement>;
  isSelected: boolean;
  isDisabled: boolean;
  isFocusVisible: boolean;
  children: React.ReactNode;
}

function CheckboxCustomStyle({
  inputProps,
  focusProps,
  ref,
  isSelected,
  isDisabled,
  isFocusVisible,
  children
}: ICheckboxCustomStyle): JSX.Element {
  return (
    <Label sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>

      <RadioFocusableIcon isDisabled={isDisabled} isSelected={isSelected === true} isFocused={isFocusVisible}>
        <MdCheckBox size={24} sx={{ color: 'primary' }} />
      </RadioFocusableIcon>

      <RadioFocusableIcon
        isDisabled={isDisabled}
        isSelected={isSelected === false}
        isFocused={isFocusVisible}
      >
        <MdCheckBoxOutlineBlank size={24} sx={{ color: 'primary' }} />
      </RadioFocusableIcon>

      <Box
        sx={{
          ml: 8
        }}
      >
        {children}
      </Box>
    </Label>
  );
}

export function CheckboxGroup(props: ICheckboxGroupProps): JSX.Element {
  const { children, label } = props;
  const state = useCheckboxGroupState(props);
  const { groupProps, labelProps } = useCheckboxGroup(props, state);

  return (
    <div {...groupProps} sx={{ fontFamily: 'body' }}>
      <Label {...labelProps} sx={{ mb: 3, position: 'relative' }}>
        {label}
      </Label>
      <CheckboxGroupProvider value={state}>{children}</CheckboxGroupProvider>
    </div>
  );
}

export function SingleCheckbox(props: AriaCheckboxProps) {
  const { children } = props;
  const state = useToggleState(props);
  const ref = React.useRef(null);
  const { inputProps } = useCheckbox(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const isSelected = state.isSelected && !props.isIndeterminate;

  return (
    <CheckboxCustomStyle
      isDisabled={props.isDisabled ? props.isDisabled : false}
      inputProps={inputProps}
      ref={ref}
      focusProps={focusProps}
      isSelected={isSelected}
      isFocusVisible={isFocusVisible}
    >
      {children}
    </CheckboxCustomStyle>
  );
}

function Checkbox(props: AriaCheckboxGroupItemProps) {
  const { children } = props;
  const state = useCheckboxGroupContext();
  const ref = React.useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckboxGroupItem(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const isSelected = state.isSelected(props.value);
  const isDisabled = state.isDisabled || props.isDisabled;
  return (
    <CheckboxCustomStyle
      inputProps={inputProps}
      ref={ref}
      focusProps={focusProps}
      isSelected={isSelected}
      isFocusVisible={isFocusVisible}
      isDisabled={isDisabled ? isDisabled : false}
    >
      {children}
    </CheckboxCustomStyle>
  );
}

CheckboxGroup.Checkbox = Checkbox;
