/** @jsxImportSource theme-ui */
import React from 'react';
import { Box } from '@/components/box';
import {
  CalculationAssumptions,
  calculationAssumptionsData
} from '@/features/editor-results/components/editor-results-calculation-assumptions';
import { BasicInformation } from '@/features/editor-results/components/editor-results-basic-information/editor-results-basic-information';
import { EditorResultsHeader } from '@/features/editor-results/components/editor-results-header';
import { Grid } from 'theme-ui';
import {
  EditorResultsBalanceOverTimeChart,
  EditorResultsChartCard,
  EditorResultsChartCardPrintable,
  EditorResultsEnergyBalanceChart
} from '@/features/editor-results/components/editor-results-charts';
import { EditorResultsSurfaceCard } from '@/features/editor-results/components/editor-results-surface-card/editor-results-surface-card';
import { EditorResultsBanner } from '../components/editor-results-banner/editor-results-banner';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditorResultsGuaranteeCard } from '@/features/editor-results/components/editor-results-guarantee-card/editor-results-guarantee-card';
import { EditorResultsPlantSize } from '@/features/editor-results/components/editor-results-plant-size/editor-results-plant-size';
import { EditorResultsCostsForConstruction } from '@/features/editor-results/components/editor-results-costs-for-construction/editor-results-costs-for-construction';
import { MaxWidthWrapper } from '@/components/max-width-wrapper';
import { Spacer } from '@/components/spacer';
import { EditorApi } from '@/features/editor';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { useEditorCalculationId } from '@/features/editor/utils/use-editor-calculation-id';
import { R } from '@/lib/remeda';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { groupByMap } from '@/utils/group-by-map';
import { ProjectInformationApi } from '@/features/project-information';
import { ProjectInformationUpdateDialog } from '@/features/editor/components/project-information-dialog';
import { useCurrentPng } from 'recharts-to-png';
import { useRenderPdf } from '@/lib/react-pdf/use-render-pdf';
import { EditorResultsMaterialWarranty } from '@/features/editor-results/components/editor-results-material-warranty/editor-results-material-warranty';
import { EditorResultsApi } from '@/features/editor-results';
import { useEditorResultsSurfaceCardsProps } from '@/features/editor-results/utils/use-editor-results-surface-cards-props';
import { EditorResultsReturnOverTime } from '@/features/editor-results/components/editor-results-return-over-time/editor-results-return-over-time';
import { Document, Image, Page, View } from '@react-pdf/renderer';
import {
  EditorResultsBasicInformationPrintable,
  EditorResultsCalculationAssumptionsPrintable
} from '@/features/editor-results/components/editor-results-assumptions-information-printable';
import { EditorResultsPlantSizePrintable } from '@/features/editor-results/components/editor-results-plant-size/editor-results-plant-size-printable';
import { EditorResultsManufacturerGuaranteePrintable } from '@/features/editor-results/components/editor-results-guarantee-card/editor-results-guarantee-card-printable';
import { EditorResultsCostsForConstructionPrintable } from '@/features/editor-results/components/editor-results-costs-for-construction/editor-results-costs-for-construction-printable';
import { EditorResultsMaterialWarrantyPrintable } from '@/features/editor-results/components/editor-results-material-warranty/editor-results-material-warranty-printable';
import { EditorResultsBannerPrintable } from '@/features/editor-results/components/editor-results-banner/editor-results-banner-printable';
import { EditorResultsReturnOverTimePrintable } from '@/features/editor-results/components/editor-results-return-over-time/editor-results-return-over-time-printable';
import { EditorResultsSurfaceCardPrintable } from '@/features/editor-results/components/editor-results-surface-card/editor-results-surface-card-printable';
import { EmptyPropsWithChildren } from '@/utils/types';
import { IGetEditorResultsApiResponse } from '../api/get-editor-results';
import {
  ICalculationAccessoriesApiResponse,
  IGetAllProductsApiResponse,
  IGetMountingSystemsApiResponse
} from '@/features/editor/api';
import { ICalculationDefaultsApiResponse } from '@/features/project-information/api';
import { HelpButton } from '@/features/editor/features/help-texts/components/help-button';
import { ISelectOptionShape, SelectField } from '@/components/select-field/select-field';
import { useModal } from '@/features/modal/stores/use-modal-store';
import { EditorResultsAdditions } from '@/features/editor-results/components/editor-results-additions/editor-results-additions-card';
import { EditorResultsAdditionsPrintable } from '@/features/editor-results/components/editor-results-additions/editor-results-additions-printable';
import { useFilteredAdditionsData } from '@/features/editor-results/utils/use-filtered-additions-data';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { ImagesPrintSurface } from '@/features/editor-results/components/editor-results-surface-card/images-print-surface';

export function pricePerKwp(
  table: IGetEditorResultsApiResponse['costs']['table'],
  installedEffect: number
): number {
  const totalAfterGreenDeduction = table.finalCost.value;
  if (installedEffect === 0) {
    return 0;
  }
  return totalAfterGreenDeduction / installedEffect;
}

function ResultsPdfPage({ children }: EmptyPropsWithChildren): JSX.Element {
  return (
    <Page size="A4" style={{ padding: 24, backgroundColor: 'white', fontFamily: 'Inter' }}>
      {children}
    </Page>
  );
}

/**
 * Describes the detail level which the total cost of the project should be shown in.
 * It can be used by sales people to show the total cost in a more or less detailed way.
 * */
export type ResultsPdfCostsDetail = 'high' | 'medium' | 'low' | 'total' | 'material';

const RouteContent = observer(function RouteContent({
  editorResults,
  allProducts,
  calculationDefaults,
  mountingSystems,
  calculationAccessories
}: {
  editorResults: IGetEditorResultsApiResponse;
  allProducts: IGetAllProductsApiResponse;
  calculationDefaults: ICalculationDefaultsApiResponse;
  mountingSystems: IGetMountingSystemsApiResponse;
  calculationAccessories: ICalculationAccessoriesApiResponse;
}): JSX.Element {
  const modal = useModal();
  const surfaces = useSurfaces();
  const intl = useIntl();
  const store = useEditorStore();

  const { inverters } = store.inverters;
  const projectInformation = store.projectInformation;

  const [pdfCostsDetail, setPdfCostsDetail] = React.useState<ResultsPdfCostsDetail>('high');
  const [getBalanceOverTimePng, { ref: balanceOverTimeRef }] = useCurrentPng({
    scale: 2
  });
  const [getEnergyBalancePng, { ref: energyBalanceRef }] = useCurrentPng({
    scale: 2
  });

  const { calculationId } = useEditorCalculationId();
  const { renderPdf } = useRenderPdf({ calculationId });

  const sizeAndProductionData = R.first(R.values(editorResults.sizeAndProduction));
  const sizeAndProductionPerSurfaceData = editorResults.sizeAndProductionPerSurface;
  const inverterProducts = groupByMap(allProducts.inverterTypes, (i) => i.productId);
  const panelProducts = groupByMap(allProducts.panelTypes, (p) => p.productId);
  const surfaceCards = useEditorResultsSurfaceCardsProps({ allProducts, mountingSystems });
  const { hasEnergyStorageOrChargingBox } = useFilteredAdditionsData({ allProducts, calculationAccessories });

  async function downloadPdf(): Promise<void> {
    const pngs = await Promise.all([getBalanceOverTimePng(), getEnergyBalancePng()]);
    const [balanceOverTimePng, energyBalancePng] = pngs;

    if (R.isNil(balanceOverTimePng) || R.isNil(energyBalancePng)) {
      return;
    }

    const blob = await renderPdf(
      <Document>
        <ResultsPdfPage>
          <View style={{ flexDirection: 'row', marginBottom: 30 }}>
            <EditorResultsBasicInformationPrintable
              projectInformation={projectInformation}
              customerOrganizationInfo={editorResults.customerOrganizationInfo}
            />

            <View style={{ width: 12 }} />

            <EditorResultsCalculationAssumptionsPrintable
              {...calculationAssumptionsData(projectInformation, calculationDefaults)}
            />
          </View>

          <View>
            {surfaceCards.map((cardProps, index) => (
              <EditorResultsSurfaceCardPrintable
                key={cardProps.id}
                {...cardProps}
                yearlyProduction={sizeAndProductionPerSurfaceData[cardProps.id]?.yearlyProduction.value}
                style={{
                  ...(index === surfaceCards.length - 1 && { marginBottom: 0 })
                }}
              />
            ))}
          </View>
        </ResultsPdfPage>

        {hasEnergyStorageOrChargingBox && (
          <ResultsPdfPage>
            <View>
              <EditorResultsAdditionsPrintable
                allProducts={allProducts}
                calculationAccessories={calculationAccessories}
              />
            </View>
          </ResultsPdfPage>
        )}

        <ResultsPdfPage>
          {sizeAndProductionData && (
            <View style={{ flexDirection: 'row', marginBottom: 30 }}>
              <EditorResultsPlantSizePrintable
                {...sizeAndProductionData}
                inverterTypes={R.uniq(
                  inverters
                    .map((inverter) =>
                      inverter.inverterTypeId
                        ? inverterProducts.get(inverter.inverterTypeId)?.name
                        : undefined
                    )
                    .filter(R.isDefined)
                )}
                panelTypes={R.uniq(
                  surfaces
                    .map((surface) => panelProducts.get(surface.panel.productId)?.name)
                    .filter(R.isDefined)
                )}
                pricePerKwp={pricePerKwp(
                  editorResults.costs.table,
                  sizeAndProductionData.installedEffect.value
                )}
              />

              <View style={{ width: 12 }} />

              <EditorResultsManufacturerGuaranteePrintable
                warrantyEffect={sizeAndProductionData.warrantyEffect}
                warrantyTime={sizeAndProductionData.warrantyTime}
              />
            </View>
          )}

          <EditorResultsChartCardPrintable
            title={<FormattedMessage defaultMessage="Investering över tid" id="7pLtpg" />}
          >
            <Image src={balanceOverTimePng} style={{ width: '100%' }} />
          </EditorResultsChartCardPrintable>
        </ResultsPdfPage>

        <ResultsPdfPage>
          <EditorResultsChartCardPrintable
            title={<FormattedMessage defaultMessage="Produktion & energibalans per månad" id="LasfbB" />}
          >
            <Image src={energyBalancePng} style={{ width: '100%' }} />
          </EditorResultsChartCardPrintable>

          <View style={{ height: 20 }} />

          <EditorResultsCostsForConstructionPrintable costsDetail={pdfCostsDetail} {...editorResults.costs} />
        </ResultsPdfPage>

        {pdfCostsDetail !== 'material' && (
          <>
            <ResultsPdfPage>
              <EditorResultsReturnOverTimePrintable data={editorResults.returnOverTime} />
            </ResultsPdfPage>
            <ResultsPdfPage>
              <EditorResultsMaterialWarrantyPrintable {...editorResults.costs} />
              <View style={{ height: 24 }} />
              <EditorResultsBannerPrintable {...editorResults.congratsTextVars} />
            </ResultsPdfPage>
          </>
        )}

        {surfaceCards.map(
          (cardProps, index) =>
            cardProps.mapImageSrc && (
              <ResultsPdfPage>
                <View>
                  <ImagesPrintSurface
                    key={cardProps.id}
                    {...cardProps}
                    style={{
                      ...(index === surfaceCards.length - 1 && { marginBottom: 0 })
                    }}
                  />
                </View>
              </ResultsPdfPage>
            )
        )}
      </Document>
    ).toBlob();
    window.open(URL.createObjectURL(blob), '_blank');
  }

  const pdfDetailOptions: ISelectOptionShape<ResultsPdfCostsDetail>[] = [
    {
      value: 'high',
      label: intl.formatMessage({ defaultMessage: 'High detail', id: '/8EBgA' })
    },
    {
      value: 'medium',
      label: intl.formatMessage({ defaultMessage: 'Medium detail', id: 'OVeRhs' })
    },
    {
      value: 'low',
      label: intl.formatMessage({ defaultMessage: 'Low detail', id: 'KZjRSY' })
    },
    {
      value: 'total',
      label: intl.formatMessage({ defaultMessage: 'Total cost only', id: 'xJdL2G' })
    },
    {
      value: 'material',
      label: intl.formatMessage({ defaultMessage: 'Material cost only', id: 'fvoRCo' })
    }
  ];

  return (
    <Box sx={{ backgroundColor: '#F7FAF9', minHeight: '100%', isolation: 'isolate', position: 'relative' }}>
      <EditorResultsHeader onPrint={downloadPdf}>
        <SelectField
          onChange={(value) => {
            if (R.isNil(value)) {
              return;
            }
            setPdfCostsDetail(value.value);
          }}
          value={pdfDetailOptions.find((o) => o.value === pdfCostsDetail)}
          options={pdfDetailOptions}
          label={intl.formatMessage({
            defaultMessage: 'Solar system details',
            id: 'yrNPNp'
          })}
          helperText={intl.formatMessage({
            defaultMessage: 'Changes the breakdown of costs when printing',
            id: '1E+mt6'
          })}
          name="costs-detail"
        />
      </EditorResultsHeader>

      <MaxWidthWrapper sx={{ px: 64, mt: 100, display: 'flex', flexDirection: 'column', gap: 74 }}>
        <Grid columns={[2]} gap={9}>
          <BasicInformation
            projectInformation={projectInformation}
            onEdit={() => {
              modal.openModal({
                children: (
                  <ProjectInformationUpdateDialog
                    initialStep="privateOrBusiness"
                    onClose={modal.closeModal}
                  />
                )
              });
            }}
            customerOrganizationInfo={editorResults.customerOrganizationInfo}
          />

          <CalculationAssumptions
            onEdit={() => {
              modal.openModal({
                children: (
                  <ProjectInformationUpdateDialog initialStep="consumption" onClose={modal.closeModal} />
                )
              });
            }}
            {...calculationAssumptionsData(projectInformation, calculationDefaults)}
          />
        </Grid>

        {surfaceCards.map((cardProps) => (
          <EditorResultsSurfaceCard
            key={cardProps.id}
            {...cardProps}
            yearlyProduction={sizeAndProductionPerSurfaceData[cardProps.id]?.yearlyProduction.value}
          />
        ))}

        <EditorResultsAdditions allProducts={allProducts} calculationAccessories={calculationAccessories} />

        {sizeAndProductionData && (
          <Grid columns={[2]} gap={9}>
            <EditorResultsPlantSize
              {...sizeAndProductionData}
              inverterTypes={R.uniq(
                inverters
                  .map((inverter) =>
                    inverter.inverterTypeId ? inverterProducts.get(inverter.inverterTypeId)?.name : undefined
                  )
                  .filter(R.isDefined)
              )}
              panelTypes={R.uniq(
                surfaces
                  .map((surface) => panelProducts.get(surface.panel.productId)?.name)
                  .filter(R.isDefined)
              )}
              pricePerKwp={pricePerKwp(
                editorResults.costs.table,
                sizeAndProductionData.installedEffect.value
              )}
            />
            <EditorResultsGuaranteeCard
              warrantyEffect={sizeAndProductionData.warrantyEffect}
              warrantyTime={sizeAndProductionData.warrantyTime}
            />
          </Grid>
        )}

        <Grid columns={[2]} gap={9}>
          <EditorResultsChartCard
            title={
              <FormattedMessage
                defaultMessage="Investeringsbalans över tid"
                id="YDnuIO"
                description="Title for a card"
              />
            }
          >
            <EditorResultsBalanceOverTimeChart ref={balanceOverTimeRef} data={editorResults.charts.yearly} />
          </EditorResultsChartCard>

          <EditorResultsChartCard
            title={
              <FormattedMessage
                defaultMessage="Produktion och effekt per månad"
                id="txod9E"
                description="Title for a card"
              />
            }
          >
            <EditorResultsEnergyBalanceChart ref={energyBalanceRef} data={editorResults.charts.monthly} />
          </EditorResultsChartCard>
        </Grid>

        <Grid columns={[2]} gap={9}>
          <EditorResultsCostsForConstruction {...editorResults.costs} />

          <EditorResultsMaterialWarranty {...editorResults.costs} />
        </Grid>
      </MaxWidthWrapper>

      <Spacer height={74} />

      <EditorResultsBanner {...editorResults.congratsTextVars} />

      <Spacer height={74} />

      <MaxWidthWrapper sx={{ px: 64 }}>
        <EditorResultsReturnOverTime data={editorResults.returnOverTime} />
      </MaxWidthWrapper>

      <Spacer height={100} />

      <HelpButton position="fixed" bottom={32} right={64} />
    </Box>
  );
});

export function EditorResultsRoute(): JSX.Element {
  const { calculationId } = useEditorCalculationId();
  const editorResults = EditorResultsApi.useEditorResults({ calculationId });
  const allProducts = EditorApi.useGetAllProducts(calculationId);
  const calculationDefaults = ProjectInformationApi.useCalculationDefaults({ calculationId });
  const mountingSystems = EditorApi.useGetMountingSystems({ calculationId });
  const calculationAccessories = EditorApi.useCalculationAccessories(calculationId);

  if (
    isQueryLoading(editorResults.isLoading, editorResults.data) ||
    isQueryLoading(allProducts.isLoading, allProducts.data) ||
    isQueryLoading(calculationDefaults.isLoading, calculationDefaults.data) ||
    isQueryLoading(mountingSystems.isLoading, mountingSystems.data) ||
    isQueryLoading(calculationAccessories.isLoading, calculationAccessories.data)
  ) {
    return <React.Fragment />;
  }

  return (
    <RouteContent
      editorResults={editorResults.data}
      allProducts={allProducts.data}
      calculationDefaults={calculationDefaults.data}
      mountingSystems={mountingSystems.data}
      calculationAccessories={calculationAccessories.data}
    />
  );
}
