/** @jsxImportSource theme-ui */
import React, { PropsWithChildren, ReactNode } from 'react';
import { ChipSmall } from '@/components/chip/chip';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditorResultsCard } from '@/features/editor-results/components/editor-results-card/editor-results-card';
import { Typography } from '@/components/typography';
import { Box } from '@/components/box';
import { Icons } from '@/assets';
import { MdClose, MdShare } from 'react-icons/md';
import { EmptyPropsWithChildren } from '@/utils/types';
import {
  IEnrichedSurface,
  SURFACE_PLACEMENT_CHIP_ICON,
  SURFACE_PLACEMENT_LABEL
} from '@/features/editor/utils/surface/surface';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { formatMetersSquared } from '@/features/intl/utils/format-meters-squared';
import { useFormatYears } from '@/features/intl/utils/use-format-years';
import { ResultsCardDataTitle, ResultsCardDataValue } from '@/features/editor-results/components/common';
import { Link } from 'theme-ui';
import { IntlSpace } from '@/features/intl/components/intl-space';
import { ModalDialogTitle } from '@/components/modal-dialog';
import { ButtonIcon } from '@/components/button/button';
import { useModal } from '@/features/modal/stores/use-modal-store';

function SurfaceCardChip({ children, icon }: PropsWithChildren<{ icon: React.ReactNode }>): JSX.Element {
  return (
    <ChipSmall sx={{ width: 'fit-content' }} isMuted isActive disabled icon={icon}>
      {children}
    </ChipSmall>
  );
}

export interface ISurfaceCardDataPointProps {
  label: React.ReactNode;
  value: React.ReactNode | React.ReactNode[];
  icon?: React.ReactNode;
  className?: string;
  size?: 'small' | 'large';
}

function SurfaceCardDataPoint({
  value,
  label,
  icon,
  className,
  size = 'small'
}: ISurfaceCardDataPointProps): JSX.Element {
  const valueAsArray = Array.isArray(value) ? value : [value];

  return (
    <Box sx={{ flexGrow: 1 }} className={className}>
      <ResultsCardDataTitle>{label}</ResultsCardDataTitle>

      <Box
        sx={{
          mt: 2,
          display: 'flex',
          gap: 2
        }}
      >
        {icon ? <span sx={{ color: 'primary' }}>{icon}</span> : null}
        <Box>
          {valueAsArray.map((value, index) => (
            <ResultsCardDataValue sx={size === 'large' ? { fontSize: 'md' } : {}} key={index}>
              {value}
            </ResultsCardDataValue>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function SurfaceCardDataGrid({ children }: EmptyPropsWithChildren): JSX.Element {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr',
        rowGap: 1,
        columnGap: 2
      }}
    >
      {children}
    </Box>
  );
}

function SurfaceCardDataGridSharedInverter({ children }: EmptyPropsWithChildren): JSX.Element {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr 3fr',
        rowGap: 1
      }}
    >
      {children}
    </Box>
  );
}

interface IProductType {
  name: string;
  power: number;
  price: number;
  warrantyTime: number;
}

interface IPanelType extends IProductType {
  warrantyEffect: number;
  materialWarranty: number;
  imageSrc: string | undefined;
}

export interface IInverterType extends IProductType {
  isBatteryPrepared: boolean;
  productId: string;
  sharedSurfaceId: string | undefined;
  quantity: number;
}

export interface IEditorResultsSurfaceCardProps extends IEnrichedSurface {
  panelsCount: number;
  panelType: IPanelType;
  inverterTypes: IInverterType[];
  optimizerTypes: IProductType[];
  mountingSystemName?: string;
  yearlyProduction?: number;
  surfaceArea: string;
  panelArea: string;
}

export function formatWatts(value: number | string): ReactNode {
  return value + ' W';
}

export function formatKiloWatts(value: number | string): string {
  return value + ' kW';
}

export function formatKiloWattsHours(value: number | string): string {
  return value + ' kWh';
}

export function formatKiloWattsPeak(value: number | string): string {
  return value + ' kWp';
}

export function getUniqueInverters(inverterTypes: IInverterType[]): IInverterType[] {
  const uniqueInverterTypes: IInverterType[] = [];
  inverterTypes.forEach((inverterType) => {
    const existingInverter = uniqueInverterTypes.find((uniqueType) => uniqueType.name === inverterType.name);
    if (existingInverter) {
      existingInverter.quantity += 1;
    } else {
      uniqueInverterTypes.push({ ...inverterType });
    }
  });
  return uniqueInverterTypes;
}

export function EditorResultsSurfaceCard(props: IEditorResultsSurfaceCardProps): JSX.Element {
  const intl = useIntl();
  const surfaces = useSurfaces();
  const formatYears = useFormatYears();
  const modal = useModal();
  function getSharedPrimarySurface(inverterType: IInverterType): string | undefined {
    return inverterType.sharedSurfaceId !== props.id
      ? surfaces.find((s) => s.id === inverterType.sharedSurfaceId)?.description
      : undefined;
  }

  return (
    <EditorResultsCard.Wrapper>
      <EditorResultsCard.Title
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 70
        }}
      >
        <Typography variant="headline2">{props.description}</Typography>

        {props.mountingSystemName ? (
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant="body1">
              <FormattedMessage defaultMessage="Mounting System Type" id="wGfnvJ" />
            </Typography>
            <Typography variant="headline2" sx={{ mt: 2 }}>
              {props.mountingSystemName}
            </Typography>
          </Box>
        ) : null}
      </EditorResultsCard.Title>

      <EditorResultsCard.Content
        sx={{
          px: 0,
          '& > *:nth-child(even)': {
            backgroundColor: 'surfaceCardBackground'
          },
          '& > *': {
            py: 4,
            px: 70
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 6,
            '& > *:not(:last-child)': {
              borderRight: '1px solid',
              borderColor: 'inputBorder'
            }
          }}
        >
          <SurfaceCardDataPoint
            size="large"
            label={intl.formatMessage({
              defaultMessage: 'Ytarea',
              id: 'S0tyhI',
              description: 'Surface area label'
            })}
            value={formatMetersSquared(props.surfaceArea)}
            icon={<Icons.Area size={16} />}
          />
          <SurfaceCardDataPoint
            size="large"
            label={intl.formatMessage({
              defaultMessage: 'Panel Area',
              id: '/njA/z',
              description: 'Panel area label'
            })}
            value={formatMetersSquared(props.panelArea)}
            icon={<Icons.Area size={16} />}
          />
          <SurfaceCardDataPoint
            size="large"
            label={intl.formatMessage({
              defaultMessage: 'Paneler',
              id: '/L6Ruc',
              description: 'Total panel power'
            })}
            value={formatKiloWatts((props.panel.power * props.panelsCount) / 1000)}
            icon={<Icons.PanelCount size={16} />}
          />
          <SurfaceCardDataPoint
            size="large"
            label={intl.formatMessage({
              defaultMessage: 'Årlig Produktion',
              id: 'BO3ZOt',
              description: 'Yearly production'
            })}
            value={formatKiloWattsHours(props.yearlyProduction ?? 0)}
            icon={<Icons.PanelCount size={16} />}
          />
          <SurfaceCardDataPoint
            size="large"
            label={intl.formatMessage({
              defaultMessage: 'Lutning',
              id: 'JhZtVS',
              description: 'Tilt of the roof label'
            })}
            value={`${props.tiltAngle} °`}
            icon={<Icons.PanelTilt size={16} />}
          />
          <SurfaceCardDataPoint
            size="large"
            label={intl.formatMessage({
              defaultMessage: 'Azimuth',
              id: 'MRldZ5',
              description: 'Roof azimuth label'
            })}
            value={`${props.rotationAngle} °`}
            icon={<Icons.Orientation size={16} />}
          />
          <SurfaceCardDataPoint
            size="large"
            label={intl.formatMessage({
              defaultMessage: 'Tak',
              id: 'mL1smj',
              description: 'Roof type (angled, flat, etc.) label'
            })}
            value={SURFACE_PLACEMENT_LABEL[props.placement]}
            icon={SURFACE_PLACEMENT_CHIP_ICON[props.placement]}
          />
          <SurfaceCardDataPoint
            size="large"
            label={intl.formatMessage({
              defaultMessage: 'Höjd till takfot',
              id: 'Joecyu',
              description: 'Height to eaves label'
            })}
            value={intl.formatNumber(props.heightToEavesMeters, { style: 'unit', unit: 'meter' })}
            icon={<Icons.HeightToEaves size={16} />}
          />
          {/*only if map is available show image view of the graphical representation of roof*/}
          {props.mapImageSrc ? (
            <SurfaceCardDataPoint
              sx={{ cursor: 'pointer' }}
              size="large"
              label={intl.formatMessage({
                defaultMessage: 'VIEW IMAGE',
                id: 'n0xYWk',
                description: 'image roof label'
              })}
              value={''}
              icon={
                <Icons.ImageRoof
                  onClick={() => {
                    modal.openModal({
                      children: (
                        <div sx={{ variant: 'cards.modal', px: 2, py: 2 }}>
                          <div sx={{ px: 2, py: 2 }}>
                            <ButtonIcon onClick={modal.closeModal}>
                              <MdClose size={20} />
                            </ButtonIcon>
                          </div>

                          <ModalDialogTitle
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              backgroundColor: 'white',
                              width: 'auto',
                              maxWidth: 'auto',
                              height: 'auto'
                            }}
                          >
                            <img
                              sx={{ width: '100%', height: '100%' }}
                              alt={intl.formatMessage({
                                defaultMessage: 'graphical roof representation',
                                id: 'uVwNoy',
                                description: 'alt text'
                              })}
                              src={props.mapImageSrc}
                            />
                          </ModalDialogTitle>
                        </div>
                      )
                    });
                  }}
                  size={16}
                />
              }
            />
          ) : null}
        </Box>

        <SurfaceCardDataGrid>
          <SurfaceCardDataPoint
            label={intl.formatMessage({
              defaultMessage: 'Panel Typ',
              id: 'GbqP3M',
              description: 'Panel type label'
            })}
            value={
              <Link sx={{ color: 'text' }} target="_blank" href={props.panelType.imageSrc}>
                {props.panelType.name}
              </Link>
            }
          />
          <SurfaceCardDataPoint
            label={intl.formatMessage({
              defaultMessage: 'Antal',
              id: 'CHawra',
              description: 'Panel quantity label'
            })}
            value={intl.formatNumber(props.panelsCount)}
          />

          <SurfaceCardDataPoint
            label={intl.formatMessage({
              defaultMessage: 'Nominell Effekt',
              id: 'RFJrLx',
              description: 'Panel nominal power label'
            })}
            value={formatWatts(props.panelType.power.toString())}
          />

          <SurfaceCardDataPoint
            label={intl.formatMessage({
              defaultMessage: 'Outgående Garanti',
              id: '1aUYO4',
              description: 'Panel output time label'
            })}
            value={formatYears(props.panelType.warrantyTime)}
          />

          <SurfaceCardDataPoint
            label={intl.formatMessage({
              defaultMessage: 'Garanterad Effekt',
              id: 'sH9sS6',
              description: 'Panel guaranteed power label'
            })}
            value={props.panelType.warrantyEffect + '%'}
          />

          <SurfaceCardDataPoint
            label={intl.formatMessage({
              defaultMessage: 'Produktgaranti',
              id: 'EW86qd',
              description: 'Panel product guarantee label'
            })}
            value={formatYears(props.panelType.materialWarranty)}
          />
        </SurfaceCardDataGrid>

        {props.inverterTypes.length > 0
          ? getUniqueInverters(props.inverterTypes).map((inverterType, index) => (
              <React.Fragment key={index}>
                {getSharedPrimarySurface(inverterType) ? (
                  <SurfaceCardDataGridSharedInverter>
                    <SurfaceCardDataPoint
                      label={intl.formatMessage({
                        defaultMessage: 'Inverter Type',
                        id: 'QqOgAA',
                        description: 'Inverter type label'
                      })}
                      value={inverterType.name}
                    />
                    <SurfaceCardDataPoint
                      label={intl.formatMessage({
                        defaultMessage: 'Quantity',
                        id: 'V6lLLD',
                        description: 'Inverter quantity label'
                      })}
                      value={inverterType.quantity}
                    />
                    <SurfaceCardDataPoint
                      label={intl.formatMessage({
                        defaultMessage: 'Nominal Power',
                        id: 'EW4KM6',
                        description: 'Inverter nominal power label'
                      })}
                      value={formatKiloWattsPeak(inverterType.power / 1000)}
                    />
                    <SurfaceCardChip icon={<MdShare />}>
                      <FormattedMessage
                        description="Label for when the inverter is shared across multiple surfaces"
                        defaultMessage="Shared with "
                        id="fmS1/7"
                      />
                      <IntlSpace />
                      {getSharedPrimarySurface(inverterType)}
                    </SurfaceCardChip>
                  </SurfaceCardDataGridSharedInverter>
                ) : (
                  <SurfaceCardDataGrid>
                    <SurfaceCardDataPoint
                      label={intl.formatMessage({
                        defaultMessage: 'Inverter Type',
                        id: 'QqOgAA',
                        description: 'Inverter type label'
                      })}
                      value={inverterType.name}
                    />
                    <SurfaceCardDataPoint
                      label={intl.formatMessage({
                        defaultMessage: 'Quantity',
                        id: 'V6lLLD',
                        description: 'Inverter quantity label'
                      })}
                      value={inverterType.quantity}
                    />
                    <SurfaceCardDataPoint
                      label={intl.formatMessage({
                        defaultMessage: 'Nominal Power',
                        id: 'EW4KM6',
                        description: 'Inverter nominal power label'
                      })}
                      value={formatKiloWattsPeak(inverterType.power / 1000)}
                    />
                    <SurfaceCardDataPoint
                      label={intl.formatMessage({
                        defaultMessage: 'Warranty Time',
                        id: 'anAw/P',
                        description: 'Inverter warranty time label'
                      })}
                      value={formatYears(inverterType.warrantyTime)}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 6,
                        position: 'relative',
                        top: '2px'
                      }}
                    >
                      {inverterType.isBatteryPrepared ? (
                        <SurfaceCardChip icon={<Icons.Circle />}>
                          <FormattedMessage
                            description="Label for when the inverter is battery prepared"
                            defaultMessage="Battery"
                            id="msVDFo"
                          />
                        </SurfaceCardChip>
                      ) : null}
                    </Box>
                  </SurfaceCardDataGrid>
                )}
              </React.Fragment>
            ))
          : null}

        {props.optimizerTypes.length > 0 && props.optimizerPoints.length > 0 ? (
          <SurfaceCardDataGrid>
            <SurfaceCardDataPoint
              label={intl.formatMessage({
                defaultMessage: 'Optimerare Typ',
                id: '0N7Ffm',
                description: 'Optimizer type label'
              })}
              value={props.optimizerTypes.map((i) => i.name)}
            />
            <SurfaceCardDataPoint
              label={intl.formatMessage({
                defaultMessage: 'Antal',
                id: '3dbx70',
                description: 'Optimizer quantity'
              })}
              value={props.optimizerPoints.length}
            />
            <SurfaceCardDataPoint
              label={intl.formatMessage({
                defaultMessage: 'Nominell Effekt',
                id: 'I1gQCS',
                description: 'Optimizer nominal power label'
              })}
              value={props.optimizerTypes.map((i) => formatWatts(i.power.toString()))}
            />

            <SurfaceCardDataPoint
              label={intl.formatMessage({
                defaultMessage: 'Garantitid',
                id: 'Jb64sV',
                description: ' Optimizer warranty time label'
              })}
              value={props.optimizerTypes.map((i) => formatYears(i.warrantyTime))}
            />
          </SurfaceCardDataGrid>
        ) : null}
      </EditorResultsCard.Content>
    </EditorResultsCard.Wrapper>
  );
}
