/** @jsxImportSource theme-ui */
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import React from 'react';
import { Collapsible } from '@/components/collapsible/collapsible';
import { ISelectDefaultValueShape, SelectField } from '@/components/select-field/select-field';
import { InverterTypeSelect } from '@/features/editor/components/inverters/inverter-type-select';
import { OptimizerTypeSelect } from '@/features/editor/components/optimizer-type-select';
import { darken } from '@theme-ui/color';
import { Typography } from '@/components/typography';
import { RightMenuSection } from '@/features/editor/components/right-menu/components/right-menu-section';
import { Flex } from 'theme-ui';
import { ActionMenu } from '@/components/action-menu/action-menu';
import { MdDelete } from 'react-icons/md';
import { Icons } from '@/assets';
import { EditorApi } from '@/features/editor';
import { useEditorContext } from '@/features/editor/stores/use-editor-context';
import { differenceBy } from '@/utils/difference-by';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { components, GroupBase, OptionProps } from 'react-select';
import { IntlSpace } from '@/features/intl/components/intl-space';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { FixedLoadingIndicator } from '@/components/loading-indicator';

const SurfaceSelectOption = observer(function SurfaceSelectOption(
  props: OptionProps<ISelectDefaultValueShape, true, GroupBase<ISelectDefaultValueShape>>
): JSX.Element {
  const store = useEditorStore();

  function hoverSurface(): void {
    store.surfaces.surfaceHover({
      surfaceId: props.data.value,
      mode: 'mouseenter'
    });
  }

  function blurSurface(): void {
    store.surfaces.surfaceHover({
      surfaceId: props.data.value,
      mode: 'mouseleave'
    });
  }

  return (
    <span onMouseLeave={blurSurface} onClick={blurSurface} onMouseEnter={hoverSurface}>
      <components.Option {...props}>{props.children}</components.Option>
    </span>
  );
});

interface IInvertersContentProps {
  id: string;
  index: number;
}

export const InverterContent = observer(function InverterContent({
  id,
  index
}: IInvertersContentProps): JSX.Element {
  const intl = useIntl();
  const { calculationId } = useEditorContext();
  const store = useEditorStore();

  const deleteSurfaceFromInverterGroup = EditorApi.useDeleteSurfaceFromInverterGroup();
  const inverterDelete = EditorApi.useInverterDelete({
    onSuccess: (data, variables) => {
      store.inverters.inverterDelete(variables.guid);
    }
  });

  const inverter = store.inverters.inverterGetOne(id);

  const surfaces = useSurfaces();
  const selectedInverterId = store.inverters.selectedInverterId;
  const isSelected = selectedInverterId === inverter?.id;

  if (isQueryLoading(inverterDelete.isLoading, inverterDelete.data)) {
    return <FixedLoadingIndicator />;
  }

  const surfaceOptions = surfaces.map((surface) => ({
    value: surface.id,
    label: surface.description
  }));

  if (!inverter) {
    return <React.Fragment />;
  }

  const inverterSurfaceOptions = inverter.surfaceIds.map((surfaceId) => ({
    value: surfaces.find((surface) => surface.id === surfaceId)?.id ?? '',
    label: surfaces.find((surface) => surface.id === surfaceId)?.description ?? ''
  }));

  const isConnectedToSurface = inverter.surfaceIds.length > 0;
  const inverterTotalPower = surfaces
    .filter((surface) => inverter.surfaceIds.includes(surface.id))
    .reduce((totalPower, surface) => surface.totalPanelPower + totalPower, 0);

  function selectInverter(id: string): void {
    store.inverters.inverterSelect(id);
  }

  function removeInverter(id: string): void {
    inverterDelete.mutate({
      calculationId,
      guid: id
    });
  }

  return (
    <Collapsible key={inverter.id} open={isSelected}>
      <Collapsible.Trigger
        iconRight={
          <ActionMenu
            direction="left"
            label={<Icons.Settings />}
            onAction={(key) => {
              if (key === 'delete') {
                removeInverter(inverter.id);
              }
            }}
          >
            <ActionMenu.Item key="delete" textValue="Delete">
              <MdDelete />
              <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
            </ActionMenu.Item>
          </ActionMenu>
        }
        sx={{
          backgroundColor: isSelected ? darken('collapsibleBackground', 0.04) : undefined
        }}
        onClick={() => selectInverter(inverter.id)}
        title={
          <span>
            <FormattedMessage defaultMessage="Inverter" id="UN6uei" />
            <IntlSpace />
            {index + 1}
            {inverterTotalPower > 0 && (
              <span sx={{ fontSize: 'xs', color: 'inputText', ml: 1 }}>
                {intl.formatNumber(inverterTotalPower)}
                <IntlSpace />
                <FormattedMessage defaultMessage="kWp" id="yA0JgT" />
              </span>
            )}
          </span>
        }
      >
        <Flex sx={{ gap: 1, flexWrap: 'wrap' }}>
          {inverterSurfaceOptions.map((surface) => {
            return (
              <Typography
                key={id}
                sx={{
                  fontSize: 'sm',
                  color: 'inputText',
                  '&:not(:last-child)': {
                    '&::after': {
                      content: '","'
                    }
                  }
                }}
              >
                {surface.label}
              </Typography>
            );
          })}
        </Flex>
      </Collapsible.Trigger>

      <Collapsible.Content
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5'
        }}
      >
        <RightMenuSection title={undefined}>
          <SelectField
            components={{
              Option: SurfaceSelectOption
            }}
            isMulti
            label="Surfaces"
            value={inverterSurfaceOptions}
            options={surfaceOptions}
            name="surface"
            id="surfaces"
            onChange={(newValue) => {
              const removed = differenceBy(inverterSurfaceOptions, newValue, (x) => x.value);
              removed.forEach((value) => {
                deleteSurfaceFromInverterGroup.mutate({
                  calculationId,
                  surfaceId: value.value
                });
              });
              store.inverters.inverterUpdate(id, (inverter) => {
                inverter.surfaceIds = newValue.map((v) => v.value);
              });
            }}
          />
        </RightMenuSection>

        {isConnectedToSurface && (
          <RightMenuSection title={undefined}>
            <InverterTypeSelect id={id} />
            <OptimizerTypeSelect inverterId={id} />
          </RightMenuSection>
        )}
      </Collapsible.Content>
    </Collapsible>
  );
});
