/** @jsxImportSource theme-ui */
import { Box, Image, Paragraph, Link, Text, Divider } from 'theme-ui';
import badgeGuarantee from '@/assets/images/badge-guarantee.svg';
import { Typography } from '@/components/typography';
import { EditorResultsCard } from '@/features/editor-results/components/editor-results-card/editor-results-card';
import { FormattedMessage } from 'react-intl';
import { IGetEditorResultsApiResponse } from '@/features/editor/api';

export interface IEditorResultsGuaranteeCardProps
  extends Pick<
    IGetEditorResultsApiResponse['sizeAndProduction'][string],
    'warrantyEffect' | 'warrantyTime'
  > {}

export function EditorResultsGuaranteeCard({
  warrantyEffect,
  warrantyTime
}: IEditorResultsGuaranteeCardProps): JSX.Element {
  return (
    <EditorResultsCard.Wrapper>
      <EditorResultsCard.Title>
        <Image sx={{ minWidth: 84 }} src={badgeGuarantee} />

        <Typography variant="headline1">
          <FormattedMessage
            defaultMessage="Tillverkaren av panelen garanterar att panelen ger minst"
            id="vKnDen"
            description="Tillverkaren av panelen garanterar att panelen kommer att ge minst 84 % av effekten efter 25 år."
          />{' '}
          <Text sx={{ color: 'primary' }}>
            {warrantyEffect}{' '}
            <FormattedMessage
              defaultMessage="av kraften efter"
              id="i0j3Wf"
              description="Tillverkaren av panelen garanterar att panelen kommer att ge minst 84 % av effekten efter 25 år."
            />{' '}
            {warrantyTime}{' '}
            <FormattedMessage
              defaultMessage="år."
              id="RmSNUh"
              description="Tillverkaren av panelen garanterar att panelen kommer att ge minst 84 % av effekten efter 25 år."
            />
          </Text>
        </Typography>
      </EditorResultsCard.Title>

      <EditorResultsCard.Content
        sx={{
          p: 13,
          borderTop: '1px solid',
          borderColor: 'inherit'
        }}
      >
        <Paragraph>
          <FormattedMessage
            defaultMessage="Produktion under garantiperioden beräknas enligt den försämring som tillåts under garantiåtagandet."
            id="+06nNo"
          />
        </Paragraph>

        <br />

        <Paragraph>
          <FormattedMessage
            defaultMessage="I verkligheten borde effekten inte förändras så snabbt under svenska förhållanden, självklart kommer panelen att producera el även efter garantitiden, men detta ingår inte i beräkningen."
            id="556Qyl"
          />
        </Paragraph>

        <Divider
          sx={{
            mt: 11,
            mb: 8,
            backgroundColor: 'menuHover',
            color: 'menuHover',
            height: 4
          }}
        />

        <Box sx={{ fontSize: 'sm', fontWeight: 500 }}>
          <Paragraph>
            <FormattedMessage
              defaultMessage="Beräkningen av produktionen görs enligt en modell (PVGIS) som utarbetats av EU-kommissionens gemensamma forskningscenter för energi och transporter. Den är baserad på isoleringsdata som samlats in under 2010-2012."
              id="32ejfo"
            />
          </Paragraph>

          <Paragraph sx={{ mt: 6, textAlign: 'right' }}>
            <FormattedMessage defaultMessage="Läs mer här:" id="5JQUDt" />{' '}
            <Link sx={{ color: 'primary' }} href="http://re.jrc.ec.europa.eu/pvgis/">
              http://re.jrc.ec.europa.eu/pvgis/
            </Link>
          </Paragraph>
        </Box>
      </EditorResultsCard.Content>
    </EditorResultsCard.Wrapper>
  );
}
