/** @jsxImportSource theme-ui */
import { EditorServices, EditorApi } from '@/features/editor';
import React from 'react';
import { Collapsible } from '@/components/collapsible/collapsible';
import { darken } from '@theme-ui/color';
import { Typography } from '@/components/typography';
import { Flex, Box } from 'theme-ui';
import { ButtonPrimary, ButtonSecondary } from '@/components/button/button';
import { TabBottomButtonsHolder } from '@/features/editor/components/right-menu/components/tab-bottom-buttons-holder';
import { SurfacePanelSelect } from '@/features/editor/components/surface-panel-select';
import { SurfaceActionMenu } from '@/features/editor/components/surface-action-menu';
import { SurfacePlacementInput } from '@/features/editor/components/surface-placement-input';
import { useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { SurfaceOrientationInput } from '@/features/editor/components/surface-orientation-input';
import { SurfacePanelTiltInput, SurfaceTiltInput } from '@/features/editor/components/surface-tilt-input';
import { SurfaceHeightToEavesInput } from '@/features/editor/components/surface-height-to-eaves-input';
import { SurfacePanelOrientationInput } from '@/features/editor/components/surface-panel-orientation-input';
import { SurfaceSetbackInput } from '@/features/editor/components/surface-setback-input';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { SurfaceRidgeWarningMessage } from '@/features/editor/components/surface-ridge-warning-message';
import { Alert } from '@/components/alert/alert';
import { getSurfaceArea } from '@/features/editor/utils/surface/get-surface-area';
import { SurfaceMountingSystemInput } from '@/features/editor/components/surface-mounting-system-input';
import { Tabs } from '@/components/tabs/tabs';
import { InverterContent } from '@/features/editor/components/inverters/inverter-content';
import { FormattedMessage, useIntl } from 'react-intl';
import { MdAdd } from 'react-icons/md';
import { RightMenuAccessories } from '@/features/editor/components/right-menu/components/accessories';
import { RightMenuSection } from '@/features/editor/components/right-menu/components/right-menu-section';
import { RightMenuAdditions } from '@/features/editor/components/right-menu/components/additions';
import { useEditorContext } from '@/features/editor/stores/use-editor-context';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { useDebouncedCallback } from 'use-debounce';
import { IEnrichedSurface, ISurface, SurfaceHoverMode } from '@/features/editor/utils/surface/surface';
import { SurfacePvgisLossInput } from '../surface-pvgis-loss-input';
import { formatMetersSquared } from '@/features/intl/utils/format-meters-squared';
import { SolarUtils } from '@/utils/solar-utils';
import { isDefined } from 'remeda';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from '@/lib/react-router/use-navigate';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { SurfaceStartingCost } from '@/features/editor/components/surface-starting-cost';
import { SelectField } from '@/components/select-field';
import { FixedLoadingIndicator } from '@/components/loading-indicator';
import { IntlSpace } from '@/features/intl/components/intl-space';
import { isFeatureFlagActive } from '@/utils/feature-flags';
import { transparentize } from '@theme-ui/color';
import { Tooltip } from '@/components/tooltip/tooltip';

const SurfaceDescriptionInput = observer(function SurfaceDescriptionInput({
  description,
  surfaceId
}: {
  description: ISurface['description'];
  surfaceId: string;
}): JSX.Element {
  const [value, setValue] = React.useState(description);
  const store = useEditorStore();

  /**
   * Need to debounce because otherwise every letter typed would trigger a change in the surface which would
   * recompute the surface's panels. Since this is expensive, we debounce to greatly reduce this effect.
   * */
  const updateSurfaceDescription = useDebouncedCallback((surfaceId: string, value: string) => {
    store.surfaces.surfaceUpdate(surfaceId, (old) => {
      old.description = value;
    });
  }, 500);

  return (
    <input
      onClick={(e) => e.stopPropagation()}
      sx={{
        variant: 'text.headline2',
        color: 'inherit',
        backgroundColor: 'transparent',
        border: 'none',
        width: '100%'
      }}
      type="text"
      value={value}
      onBlur={(event) => {
        if (event.target.value.trim() === '') {
          setValue(description);
        }
      }}
      onChange={(event) => {
        setValue(event.target.value);
        if (event.target.value.trim() === '') {
          updateSurfaceDescription(surfaceId, description);
        } else {
          updateSurfaceDescription(surfaceId, event.target.value);
        }
      }}
    />
  );
});

interface ISurfaceTitleProps {
  surface: Pick<ISurface, 'id' | 'description'>;
  children?: React.ReactNode;
  isCreateSurfaceInProgress: boolean;
}

const SurfaceTitle = observer(function SurfaceTitle({
  surface,
  children,
  isCreateSurfaceInProgress
}: ISurfaceTitleProps): JSX.Element {
  const { calculationId } = useEditorContext();
  const store = useEditorStore();
  const { surfaces } = store;
  const selectedSurfaceId = store.surfaces.selectedSurfaceId;
  const hoveredSurfaceIds = store.surfaces.hoveredSurfaceIds;

  const isSelected = (selectedSurfaceId ? selectedSurfaceId : surface.id) === surface.id;
  const isHovered = hoveredSurfaceIds.includes(surface.id);
  const isCollapsibleOpen = isCreateSurfaceInProgress || isSelected;

  const surfaceDelete = EditorApi.useSurfaceDelete({
    onSuccess: (data, variables) => {
      store.surfaces.surfaceDelete({ surfaceId: variables.guid });
    }
  });

  if (isQueryLoading(surfaceDelete.isLoading, surfaceDelete.data)) {
    return <FixedLoadingIndicator />;
  }

  function selectSurface(id: string): void {
    store.surfaces.surfaceSelect(id);
  }

  function hoverSurface(id: string, mode: SurfaceHoverMode): void {
    store.surfaces.surfaceHover({ surfaceId: id, mode });
  }

  function removeSurface(surfaceId: string): void {
    if (surfaces.selectedOverviewSurfaceIds.includes(surfaceId)) {
      surfaces.selectedOverviewSurfaceIds.splice(surfaces.selectedOverviewSurfaceIds.indexOf(surfaceId), 1);
    }
    surfaceDelete.mutate({
      calculationId,
      guid: surfaceId
    });
  }

  function resetSurface(surfaceId: string): void {
    store.surfaces.surfaceResetAutomation({ surfaceId });
  }

  return (
    <Collapsible
      onMouseEnter={() => hoverSurface(surface.id, 'mouseenter')}
      onMouseLeave={() => hoverSurface(surface.id, 'mouseleave')}
      key={surface.id}
      open={isCollapsibleOpen}
    >
      <Collapsible.Trigger
        isSurface={true}
        iconRight={
          <SurfaceActionMenu
            onReset={() => resetSurface(surface.id)}
            onDelete={() => removeSurface(surface.id)}
          />
        }
        sx={{
          backgroundColor: isHovered || isSelected ? darken('collapsibleBackground', 0.04) : undefined
        }}
        onClick={() => selectSurface(surface.id)}
        title={<SurfaceDescriptionInput description={surface.description} surfaceId={surface.id} />}
      />

      <Collapsible.Content
        sx={{
          gap: 10,
          display: 'flex',
          flexDirection: 'column',
          color: 'textLight'
        }}
      >
        {isCreateSurfaceInProgress ? (
          <Alert>
            <Typography>
              <FormattedMessage
                defaultMessage="Click on the map to add points to your surface. When you are done, double click to finish the
          surface."
                id="sW7YqT"
              />
            </Typography>
          </Alert>
        ) : (
          children
        )}
      </Collapsible.Content>
    </Collapsible>
  );
});

const tabs = {
  accessories: 'accessories',
  inverters: 'inverters',
  surfaces: 'surfaces'
} as const;

export const RightMenu = observer(() => {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState(false);

  const store = useEditorStore();
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const inProgressPolygonPath = EditorServices.DrawPolygon.useSelectPath();
  const surfaces = useSurfaces();
  const isCreatingSurface = store.state.isCreatingSurface;
  const isCreatingCustomSurface90 = store.state.isCreatingCustomSurface90;
  const isCreateSurfaceInProgress =
    isCreatingSurface && isCreatingCustomSurface90 && isDefined(inProgressPolygonPath);

  const inverters = store.inverters.inverters;

  const showEmptyState = !isCreateSurfaceInProgress && surfaces.length === 0;

  const surfaceOptions = surfaces.map((surface) => ({
    label: surface.description,
    value: surface.id
  }));

  let selectedSurfaceId = store.surfaces.selectedSurfaceId;
  let selectedSurface = surfaces.find((surface) => surface.id === selectedSurfaceId);

  if (!selectedSurfaceId) {
    selectedSurfaceId = surfaces[0]?.id;
    selectedSurface = surfaces[0];
  }

  function enterCreateSurfaceState(): void {
    store.state.setState('creatingSurface');
  }

  function inverterCreate(): void {
    store.inverters.inverterCreate();
  }

  const renderEmptyState = (
    <div
      sx={{
        alignSelf: 'center',
        px: 8
      }}
    >
      <Flex sx={{ textAlign: 'center', gap: 2, flexDirection: 'column' }}>
        <Typography variant="headline1">
          <FormattedMessage
            defaultMessage="You have no surfaces yet."
            id="0hwYLM"
            description="Main empty state message for when there are no surfaces"
          />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Click on" id="QW/+bI" />
          <IntlSpace />
          <em>
            <FormattedMessage defaultMessage="Create Surface" id="FHaL3k" />
          </em>
          <IntlSpace />
          <FormattedMessage
            defaultMessage=" below and start drawing on the map to create your first surface."
            id="g53RRH"
          />
        </Typography>
        <ButtonPrimary
          isDisabled={isCreatingSurface || isCreatingCustomSurface90}
          onPress={enterCreateSurfaceState}
          sx={{ mt: 3 }}
        >
          {isCreatingSurface || isCreatingCustomSurface90 ? (
            <FormattedMessage
              description="Text inside a disabled button used as extra information to the user"
              defaultMessage="You can draw on the map!"
              id="29gmDr"
            />
          ) : (
            <FormattedMessage
              description="Text inside an enabled primary action button"
              defaultMessage="Create Surface"
              id="MfnPem"
            />
          )}
        </ButtonPrimary>
      </Flex>
    </div>
  );

  const renderEmptyStateInverters = (
    <div
      sx={{
        px: 8
      }}
    >
      <Flex sx={{ textAlign: 'center', gap: 2, flexDirection: 'column' }}>
        <Typography variant="headline1">
          <FormattedMessage defaultMessage="You have no inverters yet." id="0MD0Vg" />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Click on" id="QW/+bI" />
          <IntlSpace />
          <em>
            <FormattedMessage defaultMessage="Add Inverter" id="SkNVv8" />
          </em>
          <IntlSpace />
          <FormattedMessage
            defaultMessage="below and start the process of adding and configuring inverter."
            id="zUxiKQ"
          />
        </Typography>
        <ButtonPrimary onPress={inverterCreate} sx={{ mt: 3 }}>
          <FormattedMessage defaultMessage="Add Inverter" id="SkNVv8" />
        </ButtonPrimary>
      </Flex>
    </div>
  );

  function renderContent(): JSX.Element {
    if (isBottomSheetOpen) {
      return <RightMenuAdditions onClose={() => setIsBottomSheetOpen(false)} />;
    }

    return (
      <Tabs
        selectedKey={searchParams.get('tab') ?? tabs.surfaces}
        onSelectionChange={async (key) => {
          setSearchParams((prev) => {
            prev.set('tab', key as string);
            return prev;
          });
        }}
        sx={{ flexGrow: 1 }}
        disabledKeys={surfaces.length === 0 ? [tabs.inverters] : undefined}
        tabContentSx={{ flexGrow: 1, alignItems: 'center', display: 'flex', overflowY: 'auto' }}
      >
        <Tabs.Item key={tabs.surfaces} title="Surfaces">
          <Flex
            sx={{
              flexDirection: 'column',
              flexGrow: 1,
              alignSelf: 'stretch'
            }}
          >
            <div sx={{ flexGrow: 1 }}>
              {surfaces.length > 1 ? (
                <div sx={{ p: 2, mt: 4 }}>
                  <SelectField
                    sx={{ py: 4 }}
                    label={intl.formatMessage({
                      defaultMessage: 'Select Surface',
                      id: '3eBnnR'
                    })}
                    placeholder={<FormattedMessage defaultMessage="Select" id="kQAf2d" />}
                    name="surfaces"
                    options={surfaceOptions}
                    onChange={(value) => {
                      store.surfaces.selectedSurfaceId = value?.value;
                    }}
                    value={surfaceOptions.find(
                      (surfaceOption) =>
                        surfaceOption.value ===
                        (store.surfaces.selectedSurfaceId
                          ? store.surfaces.selectedSurfaceId
                          : selectedSurfaceId)
                    )}
                  />
                </div>
              ) : null}
              {selectedSurface ? (
                <SurfaceTitle
                  isCreateSurfaceInProgress={false}
                  key={selectedSurfaceId}
                  surface={selectedSurface}
                >
                  <SurfaceContent surface={selectedSurface} />
                </SurfaceTitle>
              ) : null}
              {isCreateSurfaceInProgress ? (
                <SurfaceTitle
                  isCreateSurfaceInProgress={isCreateSurfaceInProgress}
                  surface={{
                    id: 'in-progress',
                    description: 'New surface'
                  }}
                />
              ) : null}
            </div>
            <TabBottomButtonsHolder>
              <Box sx={{ display: 'flex', gap: 2 }}>
                {isFeatureFlagActive('esdec') &&
                  (selectedSurface?.placement === 'angled' ? (
                    <ButtonPrimary
                      onPress={() => navigate('/esdec')}
                      sx={{
                        flex: 1,
                        backgroundColor: 'darkorange',
                        '&:hover': { backgroundColor: transparentize('darkorange', 0.18) }
                      }}
                    >
                      <FormattedMessage defaultMessage="Esdec" id="dey0Mq" />
                    </ButtonPrimary>
                  ) : (
                    <Tooltip content={<FormattedMessage defaultMessage="Esdec not supported" id="/mghsK" />}>
                      <ButtonPrimary
                        isDisabled={true}
                        onPress={() => navigate('/esdec')}
                        sx={{
                          flex: 1,
                          backgroundColor: 'darkorange',
                          '&:hover': { backgroundColor: transparentize('darkorange', 0.18) }
                        }}
                      >
                        <FormattedMessage defaultMessage="Esdec" id="dey0Mq" />
                      </ButtonPrimary>
                    </Tooltip>
                  ))}
                <ButtonPrimary onPress={() => navigate('/results')} sx={{ flex: 1 }}>
                  <FormattedMessage defaultMessage="Calculations" id="eE9icP" />
                </ButtonPrimary>
              </Box>
            </TabBottomButtonsHolder>
          </Flex>
        </Tabs.Item>

        <Tabs.Item key={tabs.inverters} title="Inverters">
          {inverters.length > 0 ? (
            <Flex
              sx={{
                flexDirection: 'column',
                flexGrow: 1,
                alignSelf: 'stretch'
              }}
            >
              <div>
                {inverters.map((inverter, index) => (
                  <InverterContent key={inverter.id} id={inverter.id} index={index} />
                ))}
              </div>
              <TabBottomButtonsHolder>
                <ButtonSecondary onPress={inverterCreate}>
                  <MdAdd size={20} />
                  <FormattedMessage defaultMessage="Add New Inverter" id="SPUoec" />
                </ButtonSecondary>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {isFeatureFlagActive('esdec') && (
                    <ButtonPrimary
                      onPress={() => navigate('/esdec')}
                      sx={{
                        flex: 1,
                        backgroundColor: 'darkorange',
                        '&:hover': { backgroundColor: transparentize('darkorange', 0.18) }
                      }}
                    >
                      <FormattedMessage defaultMessage="Esdec" id="dey0Mq" />
                    </ButtonPrimary>
                  )}
                  <ButtonPrimary onPress={() => navigate('/results')} sx={{ flex: 1 }}>
                    <FormattedMessage defaultMessage="Calculations" id="eE9icP" />
                  </ButtonPrimary>
                </Box>
              </TabBottomButtonsHolder>
            </Flex>
          ) : (
            renderEmptyStateInverters
          )}
        </Tabs.Item>

        <Tabs.Item key={tabs.accessories} title="Accessories">
          <RightMenuAccessories onAdditionsClick={() => setIsBottomSheetOpen(true)} />
        </Tabs.Item>
      </Tabs>
    );
  }

  return <React.Fragment>{showEmptyState ? renderEmptyState : renderContent()}</React.Fragment>;
});

interface ISurfaceContentProps {
  surface: IEnrichedSurface;
}

function SurfaceContent({ surface }: ISurfaceContentProps): JSX.Element {
  const intl = useIntl();
  const { surfacePanels } = useSurfacePanels();

  const surfaceArea = intl.formatNumber(getSurfaceArea(surface), { maximumFractionDigits: 2 });
  const panelArea = intl.formatNumber(surface.panelAreaMeters, { maximumFractionDigits: 2 });

  const panelsCount = surfacePanels[surface.id]?.length ?? 0;
  const showRidgeAlert = surface.placement === 'angled' && surface.ridge === undefined;
  const showTiltAlert = surface.placement === 'angled' && surface.tiltAngle === 0;

  const { calculationId } = useEditorContext();
  const allProducts = EditorApi.useGetAllProducts(calculationId);

  if (isQueryLoading(allProducts.isLoading, allProducts.data)) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Box>
          <Typography>
            <FormattedMessage defaultMessage="Modules:" id="F/h54d" /> {panelsCount}
            <IntlSpace />
            {surface.panel.power}
            <IntlSpace />
            <FormattedMessage defaultMessage="Wp" id="x2uLD6" />
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <FormattedMessage defaultMessage="Power:" id="0WvBZc" />
            <IntlSpace />
            {intl.formatNumber(SolarUtils.wattToKilowatt(panelsCount * surface.panel.power))}
            <IntlSpace />
            <FormattedMessage defaultMessage="kWp" id="yA0JgT" />
          </Typography>
        </Box>

        <Box sx={{ ml: 2 }}>
          <Typography>
            <FormattedMessage defaultMessage="Area:" id="fWlrDN" /> {formatMetersSquared(surfaceArea)}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <FormattedMessage defaultMessage="Panel yta:" id="S5K0CX" /> {formatMetersSquared(panelArea)}
          </Typography>
        </Box>
      </Flex>

      {showRidgeAlert ? (
        <React.Fragment>
          <SurfacePlacementInput placement={surface.placement} surfaceId={surface.id} />
          <SurfaceRidgeWarningMessage />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <RightMenuSection title="Roof settings">
            <SurfacePlacementInput placement={surface.placement} surfaceId={surface.id} />

            <Flex sx={{ flexDirection: 'column', gap: 1 }}>
              <Flex sx={{ gap: 2 }}>
                <SurfaceTiltInput surfaceId={surface.id} />
                <SurfacePanelTiltInput surfaceId={surface.id} />
              </Flex>

              {showTiltAlert ? null : (
                <React.Fragment>
                  <Flex sx={{ gap: 2 }}>
                    <Box sx={{ flex: 1 }}>
                      <SurfaceOrientationInput surfaceId={surface.id} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <SurfaceHeightToEavesInput surfaceId={surface.id} />
                    </Box>
                  </Flex>
                  <SurfacePvgisLossInput surfaceId={surface.id} />
                </React.Fragment>
              )}
            </Flex>
          </RightMenuSection>

          <SurfaceStartingCost surfaceId={surface.id} />

          {showTiltAlert ? (
            <Alert>
              <Typography>
                <FormattedMessage
                  defaultMessage="Your surface is missing a tilt. This will make the calculation of the surface&rsquo;s sides
                incorrect."
                  id="YTBbMz"
                />
                <br />
                <br />
                <FormattedMessage defaultMessage=" Add a surface tilt to proceed." id="jrQFr9" />
              </Typography>
            </Alert>
          ) : (
            <React.Fragment>
              <RightMenuSection title="Panel settings">
                <SurfacePanelSelect panelId={surface.panel.productId} surfaceId={surface.id} />
                <SurfacePanelOrientationInput surfaceId={surface.id} />
              </RightMenuSection>

              <RightMenuSection title="Mounting system">
                <SurfaceMountingSystemInput surfaceId={surface.id} />
                <SurfaceSetbackInput surfaceId={surface.id} />
              </RightMenuSection>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
