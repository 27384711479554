import { NumberField } from '@/components/number-field/number-field';
import React from 'react';
import { Icons } from '@/assets';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface ISurfaceTiltInputProps {
  surfaceId: string;
}

export const SurfaceTiltInput = observer(function SurfaceTiltInput({
  surfaceId
}: ISurfaceTiltInputProps): JSX.Element {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (!surface) {
    return <React.Fragment />;
  }

  return (
    <NumberField
      maxValue={89}
      minValue={0}
      icon={<Icons.RoofTilt />}
      label="Surface tilt"
      formatOptions={{
        style: 'unit',
        unit: 'degree'
      }}
      value={surface.tiltAngle}
      onChange={(value) => {
        if (Number.isNaN(value)) {
          return;
        }
        store.surfaces.surfaceUpdate(surfaceId, (old) => {
          old.tiltAngle = value;
        });
      }}
    />
  );
});

export const SurfacePanelTiltInput = observer(function SurfacePanelTiltInput({
  surfaceId
}: ISurfaceTiltInputProps): JSX.Element {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (!surface) {
    return <React.Fragment />;
  }

  return (
    <NumberField
      maxValue={89}
      minValue={0}
      icon={<Icons.RoofTilt />}
      label="Panel tilt"
      formatOptions={{
        style: 'unit',
        unit: 'degree'
      }}
      value={surface.panelTiltAngle}
      onChange={(value) => {
        if (Number.isNaN(value)) {
          return;
        }
        store.surfaces.surfaceUpdate(surfaceId, (old) => {
          old.panelTiltAngle = value;
        });
      }}
    />
  );
});
