import * as Rrd from 'react-router-dom';
import React from 'react';

// Returns a navigate function which doesn't overwrite the query string
export function useNavigate(): Rrd.NavigateFunction {
  const navigate = Rrd.useNavigate();
  const location = Rrd.useLocation();

  return React.useCallback(
    (to, options?: Rrd.NavigateOptions) => {
      if (typeof to === 'number') {
        navigate(to);
      } else if (typeof to === 'string') {
        navigate(to + location.search, options);
      } else if (typeof to === 'object') {
        navigate(
          {
            search: location.search + to.search,
            hash: to.hash,
            pathname: to.pathname
          },
          options
        );
      }
    },
    [location.search, navigate]
  );
}
