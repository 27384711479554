import { Icons } from '@/assets';
import { Box } from '@/components/box';
import { FormattedMessage } from 'react-intl';
import { EsdecSurfaceCardSection } from './esdec-surface-card-section';
import { theme } from '@/lib/theme-ui';
import { EsdecTableRow } from './esdec-table-row';

export interface IEsdecSummaryCard {
  windPressure: number;
  regularSnowLoad: number;
  panelCount: number;
  totalPower: number;
  averageRoofLoad: number;
}

export function EsdecSummaryCard({
  windPressure,
  regularSnowLoad,
  panelCount,
  totalPower,
  averageRoofLoad
}: IEsdecSummaryCard): JSX.Element {
  return (
    <EsdecSurfaceCardSection
      title={<FormattedMessage defaultMessage="Summary" id="RrCui3" />}
      icon={<Icons.Expand />}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mx: -theme.space.cardPaddingX
        }}
      >
        <EsdecTableRow
          label={<FormattedMessage defaultMessage="Wind pressure" id="0wYUeL" />}
          value={`${windPressure} N/m2`}
        />

        <EsdecTableRow
          label={<FormattedMessage defaultMessage="Regular snow load" id="THWgDJ" />}
          value={`${regularSnowLoad} N/m2`}
        />

        <EsdecTableRow
          label={<FormattedMessage defaultMessage="Panel count" id="E3fYDG" />}
          value={`${panelCount}`}
        />

        <EsdecTableRow
          label={<FormattedMessage defaultMessage="Total power" id="hqqvux" />}
          value={`${totalPower} kWp`}
        />

        <EsdecTableRow
          label={<FormattedMessage defaultMessage="Average roof load over system area" id="f1H64K" />}
          value={`${averageRoofLoad} kg`}
        />
      </Box>
    </EsdecSurfaceCardSection>
  );
}
