import { useContextMenuStore } from '@/features/editor/stores/use-context-menu-store';
import React from 'react';
import { getPositionFromMouseEvent } from '@/features/google-map/utils/get-position-from-mouse-event';
import { MdDelete, MdSwapHoriz, MdContentCopy } from 'react-icons/md';
import { RectanglePoint } from '@/utils/turf/rectangle-polygon';
import { useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { R } from '@/lib/remeda';
import { Gis } from '@/features/editor/utils/gis';
import { getPointFromMouseEvent } from './get-point-from-mouse-event';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { FormattedMessage } from 'react-intl';

interface IUseGoogleMapContextMenuReturn {
  openMultiSelectionContextMenu: (event: google.maps.MapMouseEvent, selectedIds: string[]) => void;
  openPastePanelsMenu: (event: google.maps.MapMouseEvent, surfaceId: string) => void;
  openPasteObstaclesMenu: (event: google.maps.MapMouseEvent) => void;
}

export function useGoogleMapContextMenu(): IUseGoogleMapContextMenuReturn {
  const { openContextMenu } = useContextMenuStore();
  const { surfacePanels } = useSurfacePanels();

  const store = useEditorStore();
  const selectedSurfaceId = store.surfaces.selectedSurfaceId;

  return {
    openMultiSelectionContextMenu: React.useCallback(
      (event: google.maps.MapMouseEvent, selectedIds: string[]) => {
        if (R.isNil(selectedIds)) {
          return;
        }
        const allPanels = Object.entries(surfacePanels).flatMap(([surfaceId, panels]) =>
          panels.map((panel) => ({ surfaceId, panel }))
        );

        const selectedPanels = R.filter(allPanels, ({ panel }) => selectedIds.includes(panel.id));

        openContextMenu({
          position: getPositionFromMouseEvent(event),
          items: [
            {
              key: 'delete',
              children: [
                <MdDelete key="icon" />,
                <span key="text">
                  <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
                </span>
              ],
              onAction: () => {
                if (!selectedSurfaceId) {
                  return;
                }

                store.surfaces.removePanelsFromSurface({
                  surfaceId: selectedSurfaceId,
                  panelTopLefts: selectedPanels.map(({ panel }) =>
                    RectanglePoint.topLeft(Gis.polygonFeatureToPath(panel.panel))
                  )
                });
              }
            },
            {
              key: 'add-optimizer',
              textValue: 'Add optimizer',
              onAction: () => {
                store.surfaces.optimizersAdd(
                  selectedPanels.map(({ panel, surfaceId }) => ({
                    path: Gis.polygonFeatureToPath(panel.panel),
                    surfaceId
                  }))
                );
              },
              children: [
                <MdSwapHoriz key="icon" />,
                <span key="text">
                  <FormattedMessage defaultMessage="Add optimizer" id="ueGaYZ" />
                </span>
              ]
            },
            {
              key: 'remove-optimizer',
              textValue: 'Remove optimizer',
              onAction: () => {
                store.surfaces.optimizersRemove(
                  selectedPanels.map(({ panel, surfaceId }) => ({
                    path: Gis.polygonFeatureToPath(panel.panel),
                    surfaceId
                  }))
                );
              },
              children: [
                <MdSwapHoriz key="icon" />,
                <span key="text">
                  <FormattedMessage defaultMessage="Remove optimizer" id="mHTBAe" />
                </span>
              ]
            },
            {
              key: 'copy',
              children: [
                <MdContentCopy key="icon" />,
                <span key="text">
                  <FormattedMessage defaultMessage="Copy Panels" id="bEkePM" />
                </span>
              ],
              onAction: () => {
                store.surfaces.surfacePanelsCopy({
                  panels: selectedPanels.map(({ panel }) => panel)
                });
              }
            }
          ]
        });
      },
      [openContextMenu, selectedSurfaceId, store.surfaces, surfacePanels]
    ),
    openPastePanelsMenu: React.useCallback(
      (event: google.maps.MapMouseEvent, surfaceId: string) => {
        const panelTopLeftNew = getPointFromMouseEvent(event);
        if (
          panelTopLeftNew === undefined ||
          store.surfaces.copiedPanelsTopLeftPoints.length === 0 ||
          selectedSurfaceId !== surfaceId
        ) {
          return;
        }

        openContextMenu({
          position: getPositionFromMouseEvent(event),
          items: [
            {
              key: 'paste',
              children: [
                <MdContentCopy key="icon" />,
                <span key="text">
                  <FormattedMessage defaultMessage="Paste Panels" id="ANv19L" />
                </span>
              ],
              onAction: () => {
                if (!selectedSurfaceId) {
                  return;
                }

                store.surfaces.surfacePanelsPaste({
                  pasteOrigin: panelTopLeftNew,
                  topLefts: store.surfaces.copiedPanelsTopLeftPoints,
                  surfaceId: selectedSurfaceId,
                  onPasteDone: (pastedIds) => {
                    store.selection.deselectAll();
                    store.selection.select(pastedIds);
                  }
                });
              }
            }
          ]
        });
      },
      [openContextMenu, selectedSurfaceId, store.selection, store.surfaces]
    ),
    openPasteObstaclesMenu: React.useCallback(
      (event: google.maps.MapMouseEvent) => {
        const pasteOrigin = getPointFromMouseEvent(event);
        if (pasteOrigin === undefined || store.obstacles.copiedObstacle.length === 0) {
          return;
        }

        openContextMenu({
          position: getPositionFromMouseEvent(event),
          items: [
            {
              key: 'paste',
              children: [
                <MdContentCopy key="icon" />,
                <span key="text">
                  <FormattedMessage defaultMessage="Paste Obstacle" id="SkGAVE" />
                </span>
              ],
              onAction: () => {
                store.obstacles.obstaclePaste({
                  pasteOrigin: pasteOrigin,
                  copiedPoints: store.obstacles.copiedObstacle
                });
              }
            }
          ]
        });
      },
      [openContextMenu, store.obstacles]
    )
  };
}
