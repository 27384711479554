import { Flex } from 'theme-ui';
import React from 'react';

interface ITabBottomButtons {
  children?: React.ReactNode;
}

export function TabBottomButtonsHolder({ children }: ITabBottomButtons): JSX.Element {
  return (
    <Flex
      sx={{
        position: 'sticky',
        bottom: 0,
        flexDirection: 'column',
        mt: 'auto',
        boxShadow: '4dp',
        px: 5,
        py: 4,
        gap: 3,
        backgroundColor: 'white',
        zIndex: 1
      }}
    >
      {children}
    </Flex>
  );
}
