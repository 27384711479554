import * as ThemeUI from 'theme-ui';
import React, { PropsWithChildren } from 'react';

export interface IFieldProps {
  label: string;
  errorMessage: string | undefined;
  required: boolean;
  name: string;
  isLabelAlwaysLifted: boolean;
  children: React.ReactNode | React.ReactNodeArray;
  isActive: boolean;
  isHovered: boolean;
  hasValue: boolean;
  backgroundColor?: string;
  helperText?: React.ReactNode;
  isThemeUiSelect?: boolean;
  isolate?: boolean;
  className?: string;
}

export function Field({
  errorMessage,
  helperText,
  isHovered,
  isolate = true,
  className,
  ...props
}: IFieldProps): JSX.Element {
  const isLabelLifted = props.label && (props.isLabelAlwaysLifted || props.isActive || props.hasValue);
  const hasError = Boolean(errorMessage);

  return (
    <ThemeUI.Box className={className}>
      <ThemeUI.Box
        sx={{
          width: '100%',
          position: 'relative',
          display: 'inline-flex',
          flexDirection: 'column',
          isolation: isolate ? 'isolate' : 'auto',
          backgroundColor: props.backgroundColor,
          color: 'inputText',
          fontFamily: 'body'
        }}
      >
        <ThemeUI.Label
          htmlFor={props.name}
          sx={{
            fontFamily: 'body',
            fontSize: '1rem',
            lineHeight: 1,
            letterSpacing: '0.00938em',
            padding: '0px',
            position: 'absolute',
            transformOrigin: 'top left',
            transform: 'translate(14px, 20px) scale(1)',
            ...(isLabelLifted && {
              transform: 'translate(14px, -6px) scale(0.75)'
            }),
            pointerEvents: 'none',
            top: 0,
            left: 0,
            zIndex: 1,
            transition:
              // eslint-disable-next-line max-len
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,opacity 200ms cubic-bezier(0.0, 0, 0.2, 1)',
            opacity: isLabelLifted || hasError ? 1 : 0.4,
            color: getLabelColor({
              isError: hasError,
              isActive: props.isActive
            }),

            ...(props.required && {
              '&::after': {
                content: '"*"',
                pl: 1
              }
            })
          }}
        >
          {props.label}
        </ThemeUI.Label>

        <ThemeUI.Box
          sx={{
            position: 'relative',
            borderRadius: '4px',
            display: 'inline-flex',
            fontSize: '1rem',
            boxSizing: 'border-box',
            lineHeight: '1.1876em',

            ...(props.isThemeUiSelect && {
              '& > div': {
                width: '100%'
              }
            })
          }}
        >
          {props.children}

          <ThemeUI.Box
            sx={{
              position: 'absolute',
              top: '-5px',
              left: 0,
              right: 0,
              bottom: 0,
              padding: '0 8px',
              paddingRight: '0px',
              pointerEvents: 'none',
              borderStyle: 'solid',
              borderWidth: props.isActive || isHovered ? '1px' : '1px',
              borderRadius: 'inherit',
              borderColor: getBorderColor({
                isError: hasError,
                isActive: props.isActive
              }),
              transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
            }}
            as="fieldset"
          >
            <ThemeUI.Box
              sx={{
                width: 'auto',
                height: '11px',
                display: 'block',
                padding: 0,
                textAlign: 'left',
                visibility: 'hidden',
                fontSize: '0.75em',
                transition: 'max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                ...(isLabelLifted && {
                  pr: props.required ? '18px' : '10px'
                }),
                ...(!isLabelLifted && {
                  maxWidth: '0.01px'
                })
              }}
              as="legend"
            >
              {props.label}
            </ThemeUI.Box>
          </ThemeUI.Box>
        </ThemeUI.Box>
      </ThemeUI.Box>

      {errorMessage ? (
        <HelperText isError>{errorMessage}</HelperText>
      ) : helperText ? (
        <HelperText>{helperText}</HelperText>
      ) : null}
    </ThemeUI.Box>
  );
}

function HelperText({ isError, children }: PropsWithChildren<{ isError?: boolean }>): JSX.Element {
  return (
    <ThemeUI.Text
      as="p"
      sx={{
        color: isError ? 'error' : 'inherit',
        fontFamily: 'body',
        mt: 1,
        px: 1,
        fontSize: '12px',

        '& > *': {
          fontSize: '12px'
        }
      }}
    >
      {children}
    </ThemeUI.Text>
  );
}

interface ILabelColorOptions {
  isError?: boolean;
  isActive?: boolean;
}

function getLabelColor({ isError = false, isActive = false }: ILabelColorOptions) {
  if (isError) {
    return 'error';
  }

  if (isActive) {
    return 'primary';
  }

  return 'inherit';
}

function getBorderColor({ isError = false, isActive = false }: ILabelColorOptions) {
  if (isError) {
    return 'error';
  }

  if (isActive) {
    return 'primary';
  }

  return 'inputBorder';
}
