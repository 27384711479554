import React, { PropsWithChildren } from 'react';
import { EditorContext } from '@/features/editor/utils/editor-context';
import { useOptionalEditorCalculationId } from '@/features/editor/utils/use-editor-calculation-id';
import { EditorApi } from '@/features/editor';
import { ProjectInformationApi } from '@/features/project-information';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { RelativeLoadingIndicator } from '@/components/loading-indicator';
import { EditorProvider } from '@/features/editor/providers/editor-provider';
import { ProjectInformationCreateDialog } from '@/features/editor/components/project-information-dialog';
import { ModalDialog } from '@/components/modal-dialog';
import { R } from '@/lib/remeda';
import { useSyncEditorContext } from '@/features/editor/utils/use-sync-editor-context';
import { ModalContainer } from '@/features/modal/components/modal-container';
import { EditorResultsApi } from '@/features/editor-results';
import { IGetAllProductsApiResponse } from '@/features/editor/api';
import { ICalculationDefaultsApiResponse } from '@/features/project-information/api';
import { useEditorRulesSubscriber } from '@/features/editor/utils/use-editor-rules-subscriber';
import { EmptyPropsWithChildren } from '@/utils/types';
import { nn } from '@/utils/invariant';
import {
  EditorStoreProvider,
  IEditorStoreInitialContext,
  useEditorStore,
  useEditorStoreSubscribers
} from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { IEsdecGetSurfacesResponse, useEsdecGetSurfaces } from '@/features/esdec/api/esdec-get-surfaces';

const EditorSyncContext = observer(function EditorSyncContext({
  calculationId
}: {
  calculationId: number;
}): JSX.Element {
  useSyncEditorContext({ calculationId });

  return <React.Fragment />;
});

const EditorSyncServerData = observer(function EditorSyncServerData({
  calculationId
}: {
  calculationId: number;
}): JSX.Element {
  const store = useEditorStore();

  EditorApi.useGetAllProducts(calculationId, {
    onSuccess: (data) => {
      store.metadata.update((metadata) => {
        metadata.panelTypes = data.panelTypes;
      });
    }
  });

  ProjectInformationApi.useCalculationDefaults(
    { calculationId },
    {
      onSuccess: (data) => {
        store.metadata.update((metadata) => {
          metadata.defaultPvgisLoss = data.pvgisLoss;
        });
      }
    }
  );

  return <React.Fragment />;
});

export const EditorRulesSubscriber = observer(function EditorRulesSubscriber({
  calculationId
}: {
  calculationId: number;
}) {
  useEditorRulesSubscriber({ calculationId });

  return <React.Fragment />;
});

function EditorStoreSubscribers() {
  useEditorStoreSubscribers();

  return <React.Fragment />;
}

function EditorRouteExistingInner({
  calculationId,
  editorData,
  allProducts,
  calculationDefaults,
  children,
  surfacesEsdec
}: PropsWithChildren<{
  calculationId: number;
  editorData: EditorContext;
  allProducts: IGetAllProductsApiResponse;
  calculationDefaults: ICalculationDefaultsApiResponse;
  surfacesEsdec: IEsdecGetSurfacesResponse;
}>) {
  const initialContext: IEditorStoreInitialContext = React.useMemo(() => {
    const result = editorData;
    result.metadata = {
      ...result.metadata,
      panelTypes: allProducts.panelTypes,
      defaultPvgisLoss: calculationDefaults.pvgisLoss
    };
    return {
      ...result,
      surfacesEsdec
    };
  }, [allProducts.panelTypes, calculationDefaults.pvgisLoss, editorData, surfacesEsdec]);

  return (
    <EditorProvider calculationId={calculationId} initialContext={initialContext}>
      {children}
      <EditorSyncContext calculationId={calculationId} />
      <EditorSyncServerData calculationId={calculationId} />
      <EditorRulesSubscriber calculationId={calculationId} />
      <EditorStoreSubscribers />
      <ModalContainer />
    </EditorProvider>
  );
}

export function EditorRouteExisting({
  calculationId,
  children
}: PropsWithChildren<{ calculationId: number }>): JSX.Element {
  const editorContext = EditorApi.useGetEditorContext({ calculationId });
  const editorResults = EditorResultsApi.useEditorResults({ calculationId });
  const allProducts = EditorApi.useGetAllProducts(calculationId);
  const calculationDefaults = ProjectInformationApi.useCalculationDefaults({ calculationId });
  const calculationAccessories = EditorApi.useCalculationAccessories(calculationId);
  const getMountingSystems = EditorApi.useGetMountingSystems({ calculationId });
  const getHelpTexts = EditorApi.useGetHelpTexts();
  const getEsdecSurfaces = useEsdecGetSurfaces({ calculationId });

  if (
    isQueryLoading(editorContext.isLoading, editorContext.data) ||
    isQueryLoading(allProducts.isLoading, allProducts.data) ||
    isQueryLoading(calculationDefaults.isLoading, calculationDefaults.data) ||
    isQueryLoading(calculationAccessories.isLoading, calculationAccessories.data) ||
    isQueryLoading(getMountingSystems.isLoading, getMountingSystems.data) ||
    isQueryLoading(getHelpTexts.isLoading, getHelpTexts.data) ||
    isQueryLoading(editorResults.isLoading, editorResults.data) ||
    isQueryLoading(getEsdecSurfaces.isLoading, getEsdecSurfaces.data)
  ) {
    return <RelativeLoadingIndicator />;
  }

  nn(editorContext.data, 'Expected editor data but got undefined');

  return (
    <EditorRouteExistingInner
      allProducts={allProducts.data}
      calculationDefaults={calculationDefaults.data}
      calculationId={calculationId}
      editorData={editorContext.data}
      surfacesEsdec={getEsdecSurfaces.data}
    >
      {children}
    </EditorRouteExistingInner>
  );
}

export function EditorRouteNew(): JSX.Element {
  return (
    <EditorStoreProvider>
      <ModalDialog isOpen>
        <ProjectInformationCreateDialog />
      </ModalDialog>
    </EditorStoreProvider>
  );
}

export function EditorRoute({ children }: EmptyPropsWithChildren): JSX.Element {
  const { calculationId } = useOptionalEditorCalculationId();

  if (R.isNil(calculationId)) {
    return <EditorRouteNew />;
  }

  return (
    <EditorRouteExisting calculationId={calculationId}>
      <Outlet />
    </EditorRouteExisting>
  );
}
