import { action, makeAutoObservable, makeObservable, observable, reaction, runInAction } from 'mobx';
import type { FieldDTO, FieldDto, RoofDTO } from '@solel/esdec';
import { EsdecPartialRoofWithSurfaceId } from '@/features/esdec/esdec-types';
import { TypedEmitter } from 'tiny-typed-emitter';

interface ISurfaceEsdecOptions {
  onChange?: (surface: EsdecPartialRoofWithSurfaceId) => void;
}

type SurfaceEsdecInitial = Omit<Partial<SurfaceEsdec>, 'surfaceId'> & { surfaceId: string };

export class SurfaceEsdec implements EsdecPartialRoofWithSurfaceId {
  public surfaceId: string;

  /**
   * The product that ESDEC sells as a mounting system
   * This can be ClickFit Basic, FlatFix Fusion, etc.
   * */
  public preferredProduct: RoofDTO['preferredProduct'];

  /**
   * Relevant when the product is ClickFit.
   * */
  public chosenHangerBolt: RoofDTO['chosenHangerBoltId'];

  /**
   * Relevant when the product is FlatFix Fusion.
   * */
  public groundingOption: RoofDTO['groundingOption'];

  /**
   * Relevant when the product is FlatFix Wave Plus.
   * */
  public distanceBetweenRails: RoofDTO['distanceBetweenRails'];

  /**
   * A wind zone is a classification system used to identify
   * the level of risk posed by high winds in a particular area.
   *
   * The wind zones vary by country, and are pre-defined enums.
   * */
  public windzone: RoofDTO['windzone'];

  /**
   * Specifies whether the terrain has a lot
   * of trees/structures or is relatively flat.
   * */
  public terrainCategory: RoofDTO['terrainCategory'];

  /**
   * Similar to a wind zone, a snow zone is a classification system
   * used to identify the level of risk posed by snow in a particular area.
   * */
  public snowzone: RoofDTO['snowzone'];

  /**
   * The material that the roof structure is made of.
   * Some materials are only available in flat roofs,
   * some only in sloped roofs, and some in both.
   * */
  public material: RoofDTO['material'];

  /**
   * The roof structure is relevant when the roof is made of bitumen.
   * */
  public roofStructure: RoofDTO['roofStructure'];

  /**
   * The steel type is relevant when the roof is made of steel.
   * */
  public steelType: RoofDTO['steelType'];

  /**
   * The seam type is relevant when the roof material is Standing Seam.
   * */
  public seamType: RoofDTO['seamType'];

  /**
   * The distance between the purlins of the roof structure, in millimeters.
   * */
  public purlinDistance: RoofDTO['purlinDistance'];

  /**
   * The distance between the rafters of the roof structure, in millimeters.
   * */
  public rafterDistance: RoofDTO['rafterDistance'];

  /**
   * The distance between the flanges of the roof structure, in millimeters.
   * */
  public flangeDistance: RoofDTO['flangeDistance'];

  /**
   * The height of the parapet, in millimeters.
   * */
  public parapetHeight: RoofDTO['parapetHeight'];

  public hookType: RoofDTO['hookType'];

  /**
   * Rail System. Only required for fields on \'sloped\' roofs.
   * */
  public railSystem: FieldDTO['railSystem'];

  /**
   * RoofDTO Hook Size
   * */
  public hookSize: RoofDTO['hookSize'];

  public preferredFastener: RoofDTO['preferredFastener'];

  /**
   * Attachment Type
   * */
  public chosenAttachmentType: RoofDTO['chosenAttachmentType'];

  /**
   * The presence of snow fences that prevent snow from sliding off the roof.
   * */
  public snowFencesPresent: RoofDTO['snowFencesPresent'];

  /**
   * The roof side. Required for sloped roofs in Sweden.
   * */
  public side: RoofDTO['side'];

  public topography: RoofDTO['topography'];

  /**
   * Relevant when the product is ClickFit Basic.
   * */
  public chosenBitumenSupportScrew: RoofDTO['chosenBitumenSupportScrewId'];
  /**
   * Only required for fields on \'flat\' roofs. Cannot co-exists with railSystem
   * */
  public panelConfiguration: FieldDto['panelConfiguration'];

  public isRoofFinalized: boolean = false;

  private readonly _onChangeDisposer: () => void;

  public constructor(initial: SurfaceEsdecInitial, options: ISurfaceEsdecOptions = {}) {
    makeAutoObservable(this);

    this.surfaceId = initial.surfaceId;
    Object.assign(this, initial);

    this._onChangeDisposer = reaction<EsdecPartialRoofWithSurfaceId>(
      () => ({
        surfaceId: this.surfaceId,
        preferredProduct: this.preferredProduct,
        chosenHangerBolt: this.chosenHangerBolt,
        groundingOption: this.groundingOption,
        distanceBetweenRails: this.distanceBetweenRails,
        windzone: this.windzone,
        snowzone: this.snowzone,
        terrainCategory: this.terrainCategory,
        material: this.material,
        roofStructure: this.roofStructure,
        steelType: this.steelType,
        seamType: this.seamType,
        purlinDistance: this.purlinDistance,
        rafterDistance: this.rafterDistance,
        flangeDistance: this.flangeDistance,
        parapetHeight: this.parapetHeight,
        hookType: this.hookType,
        railSystem: this.railSystem,
        preferredFastener: this.preferredFastener,
        hookSize: this.hookSize,
        side: this.side,
        topography: this.topography,
        snowFencesPresent: this.snowFencesPresent,
        chosenBitumenSupportScrew: this.chosenBitumenSupportScrew,
        panelConfiguration: this.panelConfiguration,
        isRoofFinalized: this.isRoofFinalized
      }),
      (data) => {
        options.onChange?.(data);
      }
    );
  }

  public dispose() {
    this._onChangeDisposer();
  }

  public updatePreferredProduct(preferredProduct: RoofDTO['preferredProduct']) {
    this.preferredProduct = preferredProduct;
  }

  public updateWindzone(windzone: RoofDTO['windzone']) {
    this.windzone = windzone;
  }

  public updateTerrainCategory(terrainCategory: RoofDTO['terrainCategory']) {
    this.terrainCategory = terrainCategory;
  }

  public updateSnowzone(snowzone: RoofDTO['snowzone']) {
    this.snowzone = snowzone;
  }

  public updateMaterial(material: RoofDTO['material']) {
    this.material = material;
  }

  public updateParapetHeight(parapetHeight: RoofDTO['parapetHeight']) {
    this.parapetHeight = parapetHeight;
  }

  public updateSteelType(steelType: RoofDTO['steelType']) {
    this.steelType = steelType;
  }

  public updatePurlinDistance(purlinDistance: RoofDTO['purlinDistance']) {
    this.purlinDistance = purlinDistance;
  }

  public updateRoofStructure(roofStructure: RoofDTO['roofStructure']) {
    this.roofStructure = roofStructure;
  }

  public updateRafterDistance(rafterDistance: RoofDTO['rafterDistance']) {
    this.rafterDistance = rafterDistance;
  }

  public updateSeamType(seamType: RoofDTO['seamType']) {
    this.seamType = seamType;
  }

  public updateFlangeDistance(flangeDistance: RoofDTO['flangeDistance']) {
    this.flangeDistance = flangeDistance;
  }

  public updateGrounding(groundingOption: RoofDTO['groundingOption']) {
    this.groundingOption = groundingOption;
  }

  public updateRoofHookType(hookType: RoofDTO['hookType']) {
    this.hookType = hookType;
  }

  public updateDistanceBetweenRails(distanceBetweenRails: RoofDTO['distanceBetweenRails']) {
    this.distanceBetweenRails = distanceBetweenRails;
  }

  public updateRailSystem(railSystem: FieldDTO['railSystem']) {
    this.railSystem = railSystem;
  }

  public updateHookSize(hookSize: RoofDTO['hookSize']) {
    this.hookSize = hookSize;
  }

  public updatePreferredFastener(preferredFastener: RoofDTO['preferredFastener']) {
    this.preferredFastener = preferredFastener;
  }

  public updateAttachmentType(chosenAttachmentType: RoofDTO['chosenAttachmentType']) {
    this.chosenAttachmentType = chosenAttachmentType;
  }

  public updateSnowFences(snowFencesPresent: RoofDTO['snowFencesPresent']) {
    this.snowFencesPresent = snowFencesPresent;
  }

  public updateRoofSideType(side: RoofDTO['side']) {
    this.side = side;
  }

  public updateTopographyType(topography: RoofDTO['topography']) {
    this.topography = topography;
  }

  public updateSupportScrew(chosenBitumenSupportScrew: RoofDTO['chosenBitumenSupportScrewId']) {
    this.chosenBitumenSupportScrew = chosenBitumenSupportScrew;
  }

  public updateChosenHangerBolt(chosenHangerBolt: RoofDTO['chosenHangerBoltId']) {
    this.chosenHangerBolt = chosenHangerBolt;
  }

  public updatePanelConfiguration(panelConfiguration: FieldDto['panelConfiguration']) {
    this.panelConfiguration = panelConfiguration;
  }

  public updateRoofFinalized(isRoofFinalized: boolean) {
    this.isRoofFinalized = isRoofFinalized;
  }
}

type SurfaceId = string;

interface ISurfacesEsdecStoreEvents {
  'surface-update': (surface: EsdecPartialRoofWithSurfaceId) => void;
}

export class SurfacesEsdecStore extends TypedEmitter<ISurfacesEsdecStoreEvents> {
  public surfaces: Record<SurfaceId, SurfaceEsdec> = {};

  public constructor({ initial }: { initial?: Record<string, EsdecPartialRoofWithSurfaceId> }) {
    super();

    makeObservable(this, {
      surfaces: observable,
      create: action
    });

    if (initial) {
      runInAction(() => {
        Object.entries(initial).forEach(([surfaceId, surface]) => {
          this.create({
            ...surface,
            surfaceId
          });
        });
      });
    }
  }

  public create(surface: SurfaceEsdecInitial) {
    if (this.surfaces[surface.surfaceId]) {
      return;
    }

    this.surfaces[surface.surfaceId] = new SurfaceEsdec(surface, {
      onChange: (surface) => this.emit('surface-update', surface)
    });
  }
}
