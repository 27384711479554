/** @jsxImportSource theme-ui */
import { MenuItem } from '@/components/menu-item';
import { useButton } from '@react-aria/button';
import { FocusScope } from '@react-aria/focus';
import { useMenu, useMenuTrigger } from '@react-aria/menu';
import { AriaPositionProps, DismissButton, useOverlay, useOverlayPosition } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { Item } from '@react-stately/collections';
import { useMenuTriggerState } from '@react-stately/menu';
import { useTreeState } from '@react-stately/tree';
import { MenuTriggerProps } from '@react-types/menu';
import { CollectionBase, FocusStrategy } from '@react-types/shared';
import React from 'react';

interface IActionMenuProps<T extends object> extends MenuTriggerProps, CollectionBase<T> {
  label?: React.ReactNode;
  onAction?: (key: React.Key) => void;

  triggerElement?: React.ReactNode;
}

export function ActionMenu<T extends object>(props: IActionMenuProps<T>): JSX.Element {
  // Create state based on the incoming props
  const state = useMenuTriggerState(props);

  // Get props for the menu trigger and menu elements
  const ref = React.useRef<HTMLDivElement>(null);
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref);

  // Get props for the button based on the trigger props from useMenuTrigger
  const { buttonProps } = useButton(menuTriggerProps, ref);

  return (
    <div sx={{ position: 'relative' }}>
      <div
        {...buttonProps}
        tabIndex={0}
        ref={ref}
        sx={{
          backgroundColor: 'transparent',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 32,
          height: 32,
          borderRadius: 1
        }}
      >
        {props.label}
      </div>

      {state.isOpen && (
        <Menu
          {...props}
          {...menuProps}
          autoFocus={state.focusStrategy}
          onClose={() => state.close()}
          triggerRef={ref}
        >
          {/*@ts-expect-error Can't bother right now*/}
          {props.children}
        </Menu>
      )}
    </div>
  );
}

interface IMenuProps<T extends object> extends IActionMenuProps<T> {
  autoFocus?: FocusStrategy;
  onClose?: () => void;
  triggerRef: React.RefObject<HTMLDivElement>;
}
function Menu<T extends object>(props: IMenuProps<T>): JSX.Element {
  // Create menu state based on the incoming props
  const state = useTreeState({ ...props, selectionMode: 'none' });

  // Get props for the menu element
  const menuRef = React.useRef<HTMLUListElement>(null);
  const { menuProps } = useMenu(props, state, menuRef);

  // Handle events that should cause the menu to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  const overlayRef = React.useRef<HTMLDivElement>(null);
  const { overlayProps } = useOverlay(
    {
      onClose: props.onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true
    },
    overlayRef
  );

  let initialPlacement: AriaPositionProps['placement'];
  switch (props.direction) {
    case 'left':
    case 'right':
    case 'start':
    case 'end':
      initialPlacement = `${props.direction} ${
        props.align === 'end' ? 'bottom' : 'top'
      }` as AriaPositionProps['placement'];
      break;
    case 'bottom':
    case 'top':
    default:
      initialPlacement = `${props.direction} ${props.align}` as AriaPositionProps['placement'];
  }
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: props.triggerRef,
    overlayRef,
    scrollRef: menuRef,
    offset: 4,
    isOpen: props.isOpen,
    onClose: props.onClose,
    placement: initialPlacement
  });

  // Wrap in <FocusScope> so that focus is restored back to the
  // trigger when the menu is closed. In addition, add hidden
  // <DismissButton> components at the start and end of the list
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus>
      <div {...mergeProps(overlayProps, positionProps)} ref={overlayRef}>
        <DismissButton onDismiss={props.onClose} />
        <ul
          {...menuProps}
          ref={menuRef}
          sx={{
            variant: 'cards.menu'
          }}
        >
          {Array.from(state.collection).map((item) => (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              onAction={props.onAction}
              onClose={props.onClose}
            />
          ))}
        </ul>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
}

ActionMenu.Item = Item;
