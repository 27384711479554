import { IPoint } from '@/utils/gis/types';
import { Gis } from '@/features/editor/utils/gis';

/**
 * ESDEC expects vectors in millimeters. This function takes a GeoJSON point
 * and origin (from which the point is offset), and returns the vector as
 * expected by ESDEC.
 * */
export function pointToEsdecVector({ point, origin }: { point: IPoint; origin: IPoint }) {
  const pointFixedLng: IPoint = [origin[0], point[1]];
  const pointFixedLat: IPoint = [point[0], origin[1]];

  const directionVertical = Gis.rhumbBearing(origin, pointFixedLng) === 0 ? 'top' : 'bottom';
  const directionHorizontal = Gis.rhumbBearing(origin, pointFixedLat) === 90 ? 'right' : 'left';

  const x = Gis.distance(pointFixedLat, origin, { units: 'millimeters' });
  const y = Gis.distance(pointFixedLng, origin, { units: 'millimeters' });

  return { x: directionHorizontal === 'left' ? -x : x, y: directionVertical === 'top' ? -y : y };
}
