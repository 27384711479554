import { IPoint } from '@/utils/gis/types';
import { positionToPoint } from '@/utils/turf/position-to-point';
import rhumbDestination from '@turf/rhumb-destination';
import { distanceVincenty } from '@/utils/gis/distance-vincenty';
import rhumbBearing from '@turf/rhumb-bearing';
import { bearingToAzimuth } from '@turf/helpers';

/**
 * Given a start, end, and a middle point, returns a new point which would
 * cause the triangle formed by the three points to be snapped to a 30-degree angle.
 * */
export function snapAngle(start: IPoint, middle: IPoint, end: IPoint): IPoint {
  return positionToPoint(
    rhumbDestination(
      middle,
      distanceVincenty(end, middle),
      rhumbBearing(middle, start) + snap(getGeometricAngle(start, middle, end), 15),
      {
        units: 'meters'
      }
    ).geometry.coordinates
  );

  /**
   * Given the start, end, and middle point of a geographic triangle,
   * returns the geometric angle at the middle point.
   *
   * This is necessary because we need the actual angle of the triangle,
   * rather than the bearing between its sides.
   *
   * https://www.engineeringenotes.com/surveying/compass-surveying/calculation-of-angles-from-bearings-compass-surveying-surveying/13816
   * */
  function getGeometricAngle(start: IPoint, middle: IPoint, end: IPoint): number {
    const midStart = bearingToAzimuth(rhumbBearing(middle, start));
    const midEnd = bearingToAzimuth(rhumbBearing(middle, end));

    return -(midStart - midEnd);
  }

  // Snap a value to the nearest multiple of a given number
  // Ex. snap(35, 30) = 30
  function snap(num: number, to: number): number {
    return Math.round(num / to) * to;
  }
}
