import { transparentize } from 'polished';

export const themeColors = {
  surfaceDefault: transparentize(0.42, '#FFFFFF'),
  surfaceHovered: transparentize(0.31, '#FABF2D'),
  surfaceCardBackground: transparentize(0.36, '#EEEFF4'),
  panel: '#00356B',
  panelHover: '#1acdff',
  creating: 'lightgreen',
  obstacle: '#B00020',

  primary: '#13AB62',
  primary10: transparentize(0.9, '#13AB62'),

  text: '#344054',
  textLight: '#6B7489',
  error: '#B00020',
  warning: '#ED6C02',
  info: '#0288d1',

  collapsibleBackground: '#F3F5F4',

  inputBorder: '#DADDDC',
  inputText: '#636464',
  disabled: 'rgba(0, 0, 0, 0.38)',

  toolbarIconActive: '#D0D5DD',

  menuBackground: '#F5F5F5',
  menuBorder: '#D1D2D3',
  menuText: '#667085',
  menuHover: '#E6F7ED',

  ruler: 'yellow',

  cardBorder: '#D4D9E2',
  cardTitleBackground: '#E6F7ED'
} as const;
