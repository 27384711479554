/** @jsxImportSource theme-ui */
import { Box, Button } from 'theme-ui';
import { MdArrowBack } from 'react-icons/md';
import { themeColors } from '@/lib/theme-ui/colors';
import { MaxWidthWrapper } from '@/components/max-width-wrapper';
import { Icons } from '@/assets';
import { ButtonPrimary } from '@/components/button/button';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useNavigate } from '@/lib/react-router/use-navigate';

interface IEditorResultsHeaderProps {
  onPrint: () => void;
  children?: React.ReactNode;
}

export function EditorResultsHeader({ onPrint, children }: IEditorResultsHeaderProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <Box
      as="header"
      sx={{
        backgroundColor: 'white',
        position: 'sticky',
        top: 0,
        width: '100%',
        boxShadow: '2dp',
        zIndex: 1
      }}
    >
      <MaxWidthWrapper
        sx={{
          py: 28,
          px: 12,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          onClick={() => navigate('..')}
          sx={{
            color: '#303540',
            backgroundColor: 'white',
            fontSize: 'md',
            fontWeight: 500,
            fontFamily: 'Inter'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, '&:hover': { cursor: 'pointer' } }}>
            <MdArrowBack style={{ color: themeColors.primary }} size={25} />
            <FormattedMessage defaultMessage="Edit project" id="qwCflo" />
          </Box>
        </Button>

        <div
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'flex-start'
          }}
        >
          {children}

          <ButtonPrimary onClick={onPrint}>
            <Icons.Print />
            <FormattedMessage
              defaultMessage="Print Calculation"
              id="Zsp3C+"
              description="Primary call to action inside a page header"
            />
          </ButtonPrimary>
        </div>
      </MaxWidthWrapper>
    </Box>
  );
}
