import React from 'react';
import { IPoint } from '@/utils/gis/types';
import center from '@turf/center';
import { pointSerialize } from '@/utils/gis/point';
import { themeColors } from '@/lib/theme-ui/colors';
import { R } from '@/lib/remeda';
import { useOptimizers } from '@/features/editor/utils/optimizers/use-optimizers';
import { MapCircle } from '@/features/google-map/components/shapes/map-circle';

export function RenderOptimizers() {
  const optimizers = useOptimizers();

  return (
    <React.Fragment>
      {R.flatten(R.values(optimizers)).map(({ optimizer, panel }) => (
        <MapCircle
          clickable={false}
          key={pointSerialize(optimizer)}
          point={center(panel).geometry.coordinates as IPoint}
          fillColor={themeColors.creating}
        />
      ))}
    </React.Fragment>
  );
}
