import { z } from 'zod';
import { api, IApiOptions } from '@/utils/api';

interface IFileUploadItem {
  object: Blob;
  name: string;
}

export async function uploadFiles(request: IFileUploadItem[]) {
  const parser = z.record(z.string());

  try {
    const response = parser.parse(
      (
        await api.post(
          '/S3CreatePresignedUrl',
          request.map((item) => item.name)
        )
      ).data
    );

    const promises = Object.entries(response).map(async ([name, url]) => {
      const item = request.find((item) => item.name === name);

      if (!item) {
        return Promise.resolve();
      }

      const response = await fetch(url, {
        body: item.object,
        method: 'PUT'
      });

      if (!response.ok) {
        return Promise.reject();
      }

      return Promise.resolve();
    });

    await Promise.all(promises);
    return getFilesUrl(request);
  } catch (e) {
    console.log(e);
  }
}

interface IFileGetItem {
  name: string;
}

export async function getFilesUrl(request: IFileGetItem[], options?: IApiOptions) {
  if (request.length === 0) {
    return {};
  }

  const parser = z.record(z.string());

  return parser.parse(
    (
      await api.post(
        '/S3GetPresignedUrl',
        request.map((item) => item.name),
        options
      )
    ).data
  );
}
