import { z } from 'zod';
import { IPanelType, panelManualParser, panelTypeParser } from '@/features/editor/utils/panel/panel';
import { IEnrichedSide } from '@/utils/drawing/types';
import { IRidgeLine } from '@/features/google-map/utils/surface-ridge-side';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'theme-ui';
import { IconsPdf } from '@/assets';
import { MdOutlineCropSquare, MdOutlineWbTwilight } from 'react-icons/md';
import { pointParser } from '@/utils/gis/point';

export type SurfaceHoverMode = 'mouseenter' | 'mouseleave';

export const surfacePlacementParser = z.enum(['angled', 'flat', 'ground'] as const);
export type SurfacePlacement = z.infer<typeof surfacePlacementParser>;

export const surfaceOrientationParser = z.enum(['portrait', 'landscape'] as const);
export type SurfaceOrientation = z.infer<typeof surfaceOrientationParser>;

const surfaceRackingParser = z.object({
  verticalCount: z.number(),
  horizontalCount: z.number(),
  verticalSpaceMeters: z.number(),
  horizontalSpaceMeters: z.number()
});
export type SurfaceRacking = z.infer<typeof surfaceRackingParser>;

const panelAttributesParser = z.object({
  width: z.number(),
  length: z.number(),
  thickness: z.number(),
  weight: z.number()
});
export type SurfacePanelAttributes = z.infer<typeof panelAttributesParser>;

const summaryResults = z
  .object({
    windPressure: z.number().optional().nullable(),
    regularSnowLoad: z.number().optional().nullable(),
    panelCount: z.number().optional().nullable(),
    totalPower: z.number().optional().nullable(),
    averageRoofLoad: z.number().optional().nullable(),
    errors: z.string().array().optional().nullable()
  })
  .nullable();
export type SummaryResults = z.infer<typeof summaryResults>;

const order_list = z
  .object({
    totalprice: z.number().optional().nullable(),
    pricepermodule: z.number().optional().nullable(),
    priceperwattpower: z.number().optional().nullable()
  })
  .nullable();
export type OrderList = z.infer<typeof order_list>;

const roof = z
  .object({
    windzone: z.string().nullable(),
    snowzone: z.string().nullable(),
    topography: z.string().nullable(),
    terrainCategory: z.string().nullable(),
    material: z.string().nullable(),
    side: z.string().nullable(),
    snowFencesPresent: z.boolean().nullable(),
    steelType: z.string().nullable(),
    purlinDistance: z.string().nullable(),
    roofStructure: z.string().nullable(),
    rafterDistance: z.string().nullable(),
    seamType: z.string().nullable(),
    flangeDistance: z.string().nullable(),
    hangerBolt: z.string().nullable(),
    product: z.string().nullable(),
    railSystem: z.string().nullable()
  })
  .nullable();
export type Roof = z.infer<typeof roof>;

const esdecAttributes = z
  .object({
    summary_results: summaryResults
      .optional()
      .default({
        windPressure: 0,
        regularSnowLoad: 0,
        panelCount: 0,
        totalPower: 0,
        averageRoofLoad: 0,
        errors: null
      })
      .nullable(),
    order_list: order_list
      .optional()
      .default({
        totalprice: 0,
        pricepermodule: 0,
        priceperwattpower: 0
      })
      .nullable(),
    roof: roof
      .optional()
      .default({
        windzone: null,
        snowzone: null,
        topography: null,
        terrainCategory: null,
        material: null,
        side: null,
        snowFencesPresent: null,
        steelType: null,
        purlinDistance: null,
        roofStructure: null,
        rafterDistance: null,
        seamType: null,
        flangeDistance: null,
        hangerBolt: null,
        product: null,
        railSystem: null
      })
      .nullable()
  })
  .nullable();
export type EsdecAttributes = z.infer<typeof esdecAttributes>;

export const surfaceParser = z.object({
  columnMarginMeters: z.number(),

  // An array of points representing the top left corner of panels that have been deleted
  deletedPanelTopLefts: z.array(pointParser),

  description: z.string(),

  // The height from the floor to the eaves of the roof, in meters
  heightToEavesMeters: z.number(),

  id: z.string(),

  // If Esdec project is already created
  esdecRoofId: z.number().optional().nullable(),
  esdecProjectId: z.number().optional().nullable(),

  //Object esdec data stored in our DB
  esdecAttributes: esdecAttributes,

  // An array of points at which there is an optimizer
  optimizerPoints: z.array(pointParser),

  orientation: surfaceOrientationParser,

  // The unique identifier for the panel type used on this surface
  panelTypeId: z.string().optional(),

  // Racking information for a surface
  panelAttributes: panelAttributesParser.optional().default({
    width: 0,
    length: 0,
    thickness: 0,
    weight: 0
  }),

  // Old way of defining panels, only used for backwards compatibility
  panel: panelTypeParser.optional(),

  // An array of panels defined manually, as opposed to automatically placed
  panelsManual: z.array(panelManualParser),

  // An array of points defining the shape of the surface, uses GeoJSON format
  path: z.array(pointParser),

  // The placement of the surface (angled, flat, or ground)
  placement: surfacePlacementParser,

  /**
   * The loss calculated by PVGIS for the surface.
   *
   * New surfaces will get a default defined by the backend, but old
   * surfaces will not have this value set. So we default to 14 here.
   * */
  pvgisLoss: z.number().optional().default(14),

  // The angle at which the surface is rotated
  rotationAngle: z.number(),

  // The margin between rows of panels, in meters
  rowMarginMeters: z.number(),

  // The angle at which the surface is tilted
  tiltAngle: z.number(),

  /**
   * The angle at which the panel array is tilted.
   *
   * This is different from the surface tilt angle, which is the slope
   * of the actual surface. The panel tilt angle is the angle at which
   * the panels are tilted with respect to the surface that they're on.
   * */
  panelTiltAngle: z.number().optional().default(0),

  // The setback of the surface from the edge of the roof, in meters
  setbackMeters: z.number(),

  // The position of the ridge of the roof, if applicable (optional)
  ridge: pointParser.optional(),

  // The unique identifier for the mounting system used on the surface (optional)
  mountingSystemId: z.string().optional(),

  // Racking information for a surface
  racking: surfaceRackingParser.optional().default({
    horizontalCount: 1,
    verticalCount: 1,
    horizontalSpaceMeters: 1,
    verticalSpaceMeters: 1
  }),

  isStartingCostEnabled: z.boolean().default(true),

  // An image of the surface on the map
  mapImageSrc: z
    .string()
    .nullable()
    .transform((val) => (val === null ? undefined : val))
});

export function surfaceImageName(surface: ISurface) {
  return `surface-${surface.id}`;
}

export type ISurface = z.infer<typeof surfaceParser>;

export interface IEnrichedSurface extends ISurface {
  sides: IEnrichedSide[];
  ridgeLine?: IRidgeLine;
  panel: IPanelType;
  totalPanelPower: number;
  panelAreaMeters: number;
}

export const SURFACE_PLACEMENT_CHIP_ICON: Record<SurfacePlacement, React.ReactNode> = {
  angled: (
    <Text sx={{ fontSize: 16 }}>
      <FormattedMessage defaultMessage="/" id="hPwhJC" description="/" />
    </Text>
  ),
  flat: <MdOutlineWbTwilight size={16} />,
  ground: <MdOutlineCropSquare size={16} />
};

export const SURFACE_PLACEMENT_LABEL: Record<IEnrichedSurface['placement'], React.ReactNode> = {
  angled: <FormattedMessage defaultMessage="Angled" id="WwRXKX" description="Label for an angled roof" />,
  flat: <FormattedMessage defaultMessage="Flat" id="bK8DVI" description="Label for a flat roof" />,
  ground: <FormattedMessage defaultMessage="Ground" id="DEhpIW" description="Label for a ground surface" />
};

export const SURFACE_PLACEMENT_CHIP_ICON_PRINTABLE: Record<SurfacePlacement, React.ReactNode> = {
  angled: <IconsPdf.RoofAngled />,
  flat: <IconsPdf.RoofFlat />,
  ground: <IconsPdf.Area />
};

export function isSurfaceRackingDefault(surface: Pick<ISurface, 'racking'>) {
  return surface.racking.horizontalCount === 1 && surface.racking.verticalCount === 1;
}
