/* eslint-disable @typescript-eslint/naming-convention */
import { ThemeProvider } from 'theme-ui';
import { EmptyPropsWithChildren } from '@/utils/types';
import { themeColors } from '@/lib/theme-ui/colors';
import { themeBoxShadows } from '@/lib/theme-ui/shadows';
import { darken, transparentize } from '@theme-ui/color';

export const theme = {
  colors: themeColors,
  radii: {
    0: 0,
    1: 4,
    2: 8,
    3: 12,
    chip: 32,
    rounded: '50%'
  },
  buttons: {
    icon: {
      width: 'auto',
      height: 'auto',
      padding: '2px',
      borderRadius: 1,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.1s ease-in-out',

      '&:hover': {
        backgroundColor: 'inputBorder'
      }
    }
  },
  fontSizes: {
    xs: `${12 / 16}rem`,
    sm: `${14 / 16}rem`,
    md: `${16 / 16}rem`,
    lg: `${18 / 16}rem`,
    xl: `${20 / 16}rem`,
    '2xl': `${24 / 16}rem`,
    '3xl': `${28 / 16}rem`,
    '4xl': `${32 / 16}rem`
  },
  space: {
    0: 0,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 20,
    6: 24,
    7: 28,
    8: 32,
    9: 36,
    10: 40,
    11: 44,
    12: 48,
    13: 52,
    14: 56,
    15: 60,

    cardPaddingX: 8,
    cardPaddingY: 5
  },
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'Inter, system-ui, sans-serif',
    monospace: 'Menlo, monospace'
  },
  shadows: themeBoxShadows,
  cards: {
    menu: {
      margin: 0,
      minWidth: 'max-content',
      listStyle: 'none',
      border: '1px solid',
      borderColor: 'menuBorder',
      p: 2,
      borderRadius: 1,
      fontFamily: 'body',
      backgroundColor: transparentize('menuBackground', 0.08),
      backdropFilter: 'blur(16px)',
      color: 'menuText'
    },
    tooltip: {
      fontFamily: 'body',
      fontSize: 'xs',
      backgroundColor: '#667085',
      boxShadow: '1dp',
      borderRadius: 1,
      px: 2,
      py: 1,
      color: '#FFFFFFDE',
      maxWidth: 200,
      textAlign: 'center'
    },
    modal: {
      backgroundColor: 'white',
      px: 11,
      py: 5,
      gap: 3
    }
  },
  text: {
    body1: {
      fontFamily: 'body',
      fontSize: `${14 / 16}rem`
    },
    body2: {
      fontFamily: 'body',
      fontSize: `${12 / 16}rem`
    },
    headline1: {
      fontFamily: 'heading',
      fontSize: `${20 / 16}rem`,
      fontWeight: 'bold'
    },
    headline2: {
      fontFamily: 'heading',
      fontSize: `${18 / 16}rem`,
      fontWeight: 500
    },
    headline3: {
      fontFamily: 'heading',
      fontSize: `${16 / 16}rem`,
      fontWeight: 500
    },
    headline4: {
      fontFamily: 'heading',
      fontSize: `${14 / 16}rem`,
      fontWeight: 500
    },
    headline5: {
      fontFamily: 'heading',
      fontSize: `${12 / 16}rem`,
      fontWeight: 500
    },

    menuItem: {
      textTransform: 'capitalize',
      transition: 'background-color 0.1s ease-in-out, color 0.1s ease-in-out',
      background: 'transparent',
      color: 'inherit',
      borderRadius: 1,
      pl: 2,
      pr: 4,
      py: 2,
      outline: 'none',
      cursor: 'pointer',
      fontSize: '12px',
      display: 'flex',
      alignItems: 'center',
      '&:focus': {
        backgroundColor: darken('menuBackground', 0.3),
        color: 'white'
      }
    }
  }
} as const;

export function ThemeUiProvider({ children }: EmptyPropsWithChildren): JSX.Element {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
