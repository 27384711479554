/** @jsxImportSource theme-ui */
import { TabListProps } from '@react-types/tabs';
import { TabListState, useTabListState } from '@react-stately/tabs';
import React from 'react';
import { useTab, useTabList, useTabPanel } from '@react-aria/tabs';
import { Node } from '@react-types/shared';
import { Item } from '@react-stately/collections';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { SxProp } from 'theme-ui';

interface ITabsProps<T extends object> extends TabListProps<T> {
  className?: string;
  tabContentSx?: SxProp['sx'];
  tabListSx?: SxProp['sx'];
}

export function Tabs<T extends object>({
  className,
  tabContentSx,
  tabListSx,
  ...props
}: ITabsProps<T>): JSX.Element {
  const state = useTabListState(props);
  const $tabList = React.useRef<HTMLDivElement>(null);
  const { tabListProps } = useTabList(props, state, $tabList);

  return (
    <div
      sx={{
        fontFamily: 'body',
        '--horizontal-padding': '8px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      className={className}
    >
      <div
        {...tabListProps}
        ref={$tabList}
        sx={{
          overflow: 'auto',
          display: 'flex',
          ...tabListSx
        }}
      >
        {Array.from(state.collection).map((item) => (
          <Tab key={item.key} item={item} state={state} />
        ))}
      </div>

      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {state.selectedItem ? <TabPanel sx={tabContentSx} key={state.selectedItem.key} state={state} /> : null}
    </div>
  );
}

interface ITabProps<T extends object> {
  state: TabListState<T>;
  item: Node<T>;
}
function Tab<T extends object>({ item, state }: ITabProps<T>): JSX.Element {
  const { key, rendered } = item;
  const $tab = React.useRef<HTMLDivElement>(null);
  const { tabProps } = useTab({ key }, state, $tab);

  const isSelected = state.selectedKey === key;
  const isDisabled = state.disabledKeys.has(key);

  const { hoverProps, isHovered } = useHover({});
  const showHoverState = !isDisabled && isHovered;

  return (
    <div
      {...mergeProps(tabProps, hoverProps)}
      ref={$tab}
      sx={{
        flexGrow: 1,
        textAlign: 'center',
        color: isDisabled ? 'disabled' : isSelected ? 'primary' : 'menuText',
        cursor: isDisabled ? 'default' : 'pointer',
        py: 3,
        px: 'var(--horizontal-padding)',
        fontSize: '14px',
        textTransform: 'uppercase',
        position: 'relative',
        backgroundColor: 'white',

        outlineOffset: '1px',
        transition: 'background-color 0.1s ease-in-out, color 0.1s ease-in-out',
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,

        fontWeight: 500,

        ...(isSelected && {
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: 4,
            backgroundColor: 'primary'
          }
        }),

        ...(showHoverState && {
          backgroundColor: '#E6F7ED',
          color: 'primary'
        })
      }}
    >
      {rendered}
    </div>
  );
}

interface ITabPanelProps<T extends object> {
  state: TabListState<T>;
  className?: string;
}
function TabPanel<T extends object>({ state, className }: ITabPanelProps<T>): JSX.Element {
  const $tabPanel = React.useRef<HTMLDivElement>(null);
  const { tabPanelProps } = useTabPanel({}, state, $tabPanel);

  return (
    <div {...tabPanelProps} ref={$tabPanel} className={className}>
      {state.selectedItem.props.children}
    </div>
  );
}

Tabs.Item = Item;
