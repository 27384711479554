import { Shape } from '@/features/google-map/utils/shapes/shape';
import { IPoint } from '@/utils/gis/types';
import * as R from 'remeda';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';

interface IMarkerOptions extends google.maps.MarkerOptions {}

export interface IMarkerShape {
  id: string;
  point: IPoint;
  label: string;
  options?: IMarkerOptions;
}

export const MARKER_LABEL_CLASSNAME = 'marker-label';

export class Marker extends Shape implements IMarkerShape {
  private readonly _point: IPoint;
  private readonly _label: string;

  // TODO: Make separate marker for length labels which doesn't have the below options
  public constructor(id: string, point: IPoint, label: string, options?: IMarkerOptions) {
    super(
      id,
      R.merge(
        {
          clickable: false,
          label: {
            className: MARKER_LABEL_CLASSNAME,
            text: label,
            color: 'white',
            fontSize: '10px',
            fontFamily: 'Inter',
            fontWeight: '800'
          },
          icon: {
            url: `
            <rect x="0" y="0" width="60" height="30" />
          `
          },
          zIndex: GOOGLE_MAP_Z_INDICES.MARKER,
          anchorPoint: new google.maps.Point(0, 0),
          position: pointToLatLng(point)
        },
        options
      )
    );
    this._point = point;
    this._label = label;
  }

  public get point(): IPoint {
    return this._point;
  }

  public get label(): string {
    return this._label;
  }

  public get options(): IMarkerOptions | undefined {
    return this._options;
  }

  public build(): google.maps.Marker {
    return new google.maps.Marker(this._options);
  }

  public update(shape: Marker): Marker {
    const result = new Marker(this._id, shape._point, shape._label, R.merge(this._options, shape.options));
    result.eventListenersToAttach = shape.eventListenersToAttach;
    return result;
  }
}

export type { IMarkerOptions };
