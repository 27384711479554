/** @jsxImportSource theme-ui */
import { Box } from '@/components/box';
import { Flex, Grid, IconButton, Paragraph } from 'theme-ui';
import { MdClear } from 'react-icons/md';
import { Typography } from '@/components/typography';
import { NumberField } from '@/components/number-field/number-field';
import { FormattedMessage } from 'react-intl';
import { RightMenuSection } from '@/features/editor/components/right-menu/components/right-menu-section';
import { RightMenuListSectionTitle } from '@/features/editor/components/right-menu/components/right-menu-list-section-title';
import { EditorApi } from '@/features/editor';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import React from 'react';
import { ICalculationAccessoriesApiResponse, IGetAllProductsApiResponse } from '@/features/editor/api';
import { UnwrapArray } from '@/utils/types';
import { R } from '@/lib/remeda';
import { useEditorContext } from '@/features/editor/stores/use-editor-context';
import CreatableSelect from 'react-select/creatable';
import { TextField } from '@/components/text-field/text-field';
import { useDebouncedCallback } from 'use-debounce';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { filterSelectOptionAndValue } from '@/components/select-field/select-field';
/**
 * The current system works such that the margin value input by the user
 * is summed with 1. In effect, writing "50" in the margin box will yield
 * 1.5, writing "35" will yield 1.35, etc.
 *
 * I am unsure as to why this is, but to preserve the current behavior,
 * I have implemented the same logic here.
 * */
function calculateAdditionMargin(marginPercent: number): number {
  return 1 + marginPercent;
}

interface IAdditionProps extends UnwrapArray<ICalculationAccessoriesApiResponse['additions']> {
  index: number;
  onUpdateQuantity: (quantity: number) => void;
  onUpdateMargin: (margin: number) => void;
  onUpdatePrice: (price: number) => void;
  onRemoveAddition: () => void;
  children?: React.ReactNode;
}

function Addition({
  margin,
  amount,
  price,
  type,
  index,
  onUpdateMargin,
  onUpdateQuantity,
  onUpdatePrice,
  onRemoveAddition,
  children
}: IAdditionProps): JSX.Element {
  const formatCurrency = useFormatCurrency();
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'inputBorder',
        overflow: 'hidden',
        borderRadius: 1,
        backgroundColor: 'white'
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          backgroundColor: '#EEEFF4',
          pl: 4,
          pr: 2,
          py: 1,
          color: '#8992A6'
        }}
      >
        <Typography sx={{ color: 'primary' }}>
          <FormattedMessage
            defaultMessage="Addition"
            id="vlaAmM"
            description='Title for an addition, the index of the addition will be a part of this sentence: "Addition 1", "Addition 5", etc.'
          />{' '}
          {index + 1}
        </Typography>
        <IconButton sx={{ ml: 'auto' }} onClick={onRemoveAddition}>
          <MdClear size={20} />
        </IconButton>
      </Flex>

      <Box sx={{ p: 4 }}>
        {children}

        <Grid
          sx={{
            gridTemplateColumns: '1fr 1fr 2fr',
            mt: 3
          }}
        >
          <NumberField
            minValue={0}
            onChange={(value) => {
              if (Number.isNaN(value)) {
                return;
              }
              onUpdateQuantity(value);
            }}
            value={amount}
            label={
              <FormattedMessage
                defaultMessage="Quantity"
                id="2RYMbP"
                description="Label for a numbered input"
              />
            }
          />
          <NumberField
            onChange={(value) => {
              onUpdateMargin(value);
            }}
            value={margin / 100}
            label={
              <FormattedMessage
                defaultMessage="Margin"
                id="UiVN8h"
                description="Label for a numbered input"
              />
            }
            formatOptions={{
              style: 'percent',
              maximumFractionDigits: 2
            }}
          />
          <NumberField
            minValue={0}
            onChange={(value) => {
              if (Number.isNaN(value)) {
                return;
              }
              onUpdatePrice(value);
            }}
            isDisabled={type === 'product'}
            value={price}
            label={
              <FormattedMessage defaultMessage="Price" id="tedWSP" description="Label for a numbered input" />
            }
            formatOptions={{
              style: 'currency',
              currency: 'SEK'
            }}
          />
        </Grid>
        <Box sx={{ fontSize: 'xs', color: '#667085', display: 'flex', gap: 2, mt: 3 }}>
          <Paragraph>
            <FormattedMessage
              defaultMessage="Total Price"
              id="PNg/DG"
              description="Label for a total price"
            />
          </Paragraph>
          <Paragraph sx={{ fontWeight: 500 }}>{formatCurrency(amount * price)}</Paragraph>
        </Box>
      </Box>
    </Box>
  );
}

function AdditionsList({
  additions,
  allProducts,
  onClose,
  calculationId
}: {
  allProducts: IGetAllProductsApiResponse;
  additions: ICalculationAccessoriesApiResponse['additions'];
  onClose: () => void;
  calculationId: number;
}): JSX.Element {
  const additionUpdate = EditorApi.useAdditionUpdate();
  const additionUpdateDebounced = useDebouncedCallback(additionUpdate.mutate, 500);
  const additionAdd = EditorApi.useAdditionAdd();
  const additionRemove = EditorApi.useAdditionRemove();

  type PendingAddition = UnwrapArray<ICalculationAccessoriesApiResponse['additions']>;
  // Pending additions are locally added additions which have not yet been saved to the server
  const [pendingAdditions, setPendingAdditions] = React.useState<PendingAddition[]>(
    additions.length === 0 ? [createPendingAddition()] : []
  );
  const [loadingAdditionIds, setLoadingAdditionIds] = React.useState<number[]>([]);

  const additionsOptions = React.useMemo(
    () =>
      allProducts.additions.map((addition) => ({
        label: addition.addition_name,
        value: String(addition.addition_id)
      })),
    [allProducts.additions]
  );

  function createPendingAddition(): PendingAddition {
    return {
      type: '',
      product_id: Math.random(),
      id: Math.random(),
      price: 0,
      name: '',
      amount: 1,
      margin: 0
    };
  }

  return (
    <Box sx={{ backgroundColor: '#F8F8F8', overflow: 'auto', pb: 4, isolation: 'isolate', flexGrow: 1 }}>
      <Flex
        sx={{
          justifyContent: 'flex-end',
          backgroundColor: 'white',
          px: 4,
          py: 2,
          position: 'sticky',
          top: 0,
          zIndex: 1,
          boxShadow: '1dp'
        }}
      >
        <IconButton onClick={onClose}>
          <MdClear size={24} />
        </IconButton>
      </Flex>

      <Box sx={{ flexGrow: 1, px: 4, mt: 7 }}>
        <RightMenuSection
          title={
            <RightMenuListSectionTitle
              onItemAdd={() => {
                setPendingAdditions((old) => [...old, createPendingAddition()]);
              }}
              title={
                <FormattedMessage
                  defaultMessage="Additions"
                  id="C/yMma"
                  description="Title for a sub-section containing e-commerce items"
                />
              }
            />
          }
        >
          {pendingAdditions.map((addition, index) => {
            const isLoading = loadingAdditionIds.includes(addition.id);
            const selectedOption = additionsOptions.find((o) => o.value === String(addition.product_id));

            return (
              <Addition
                onRemoveAddition={() => {
                  setPendingAdditions((old) => old.filter((item) => item.id !== addition.id));
                }}
                onUpdateQuantity={(quantity) => {
                  setPendingAdditions((old) =>
                    old.map((item) => (item.id === addition.id ? { ...item, amount: quantity } : item))
                  );
                }}
                onUpdateMargin={(margin) => {
                  setPendingAdditions((old) =>
                    old.map((item) =>
                      item.id === addition.id ? { ...item, margin: calculateAdditionMargin(margin) } : item
                    )
                  );
                }}
                onUpdatePrice={(price) => {
                  setPendingAdditions((old) =>
                    old.map((item) => (item.id === addition.id ? { ...item, price } : item))
                  );
                }}
                index={index}
                key={addition.id}
                {...addition}
              >
                <CreatableSelect
                  filterOption={filterSelectOptionAndValue}
                  menuPosition="fixed"
                  sx={{ fontSize: 'sm', fontFamily: 'body' }}
                  isDisabled={isLoading}
                  options={additionsOptions}
                  value={selectedOption}
                  onChange={(value) => {
                    if (R.isNil(value)) {
                      return;
                    }
                    setLoadingAdditionIds((old) => [...old, addition.id]);
                    additionAdd.mutate(
                      {
                        type: 'existing',
                        amount: addition.amount,
                        productId: value.value,
                        calculationId,
                        name: addition.name
                      },
                      {
                        onSuccess() {
                          setPendingAdditions((old) => old.filter((a) => a.id !== addition.id));
                        },
                        onSettled() {
                          setLoadingAdditionIds((old) => old.filter((id) => id !== addition.id));
                        }
                      }
                    );
                  }}
                  onCreateOption={(inputValue) => {
                    if (R.isNil(inputValue)) {
                      return;
                    }
                    additionAdd.mutate(
                      {
                        type: 'custom',
                        name: inputValue,
                        amount: 1,
                        calculationId,
                        marginPercent: 0,
                        priceCents: 0
                      },
                      {
                        onSuccess() {
                          setPendingAdditions((old) => old.filter((a) => a.id !== addition.id));
                        }
                      }
                    );
                  }}
                />
              </Addition>
            );
          })}

          {additions.map((addition, index) => {
            /*
             * A custom addition is one which does not have a product in the database.
             * As such, it doesn't have a product ID but its name is just a string
             * defined by the user through the text-field which appears for custom additions.
             * **/
            const isCustomAddition = R.isNil(addition.product_id);
            const selectedOption = additionsOptions.find((o) => o.value === String(addition.product_id));

            return (
              <Addition
                onRemoveAddition={() => {
                  additionRemove.mutate({
                    calculationId,
                    id: addition.id,
                    type: isCustomAddition ? 'custom' : 'existing'
                  });
                }}
                onUpdateQuantity={(quantity) => {
                  if (isCustomAddition) {
                    additionUpdate.mutate({
                      type: 'custom',
                      id: String(addition.id),
                      calculationId,
                      name: addition.name,
                      priceCents: addition.price,
                      marginPercent: addition.margin,
                      amount: quantity
                    });
                  } else {
                    additionUpdate.mutate({
                      type: 'existing',
                      amount: quantity,
                      id: String(addition.id),
                      product: true,
                      marginPercent: addition.margin,
                      calculationId
                    });
                  }
                }}
                onUpdateMargin={(margin) => {
                  const marginPercent = calculateAdditionMargin(margin);

                  if (isCustomAddition) {
                    additionUpdate.mutate({
                      type: 'custom',
                      id: String(addition.id),
                      calculationId,
                      name: addition.name,
                      priceCents: addition.price,
                      marginPercent,
                      amount: addition.amount
                    });
                  } else {
                    additionUpdate.mutate({
                      type: 'existing',
                      amount: addition.amount,
                      id: String(addition.id),
                      product: true,
                      marginPercent,
                      calculationId
                    });
                  }
                }}
                onUpdatePrice={(price) => {
                  if (isCustomAddition) {
                    additionUpdate.mutate({
                      type: 'custom',
                      id: String(addition.id),
                      calculationId,
                      name: addition.name,
                      priceCents: price,
                      marginPercent: addition.margin,
                      amount: addition.amount
                    });
                  }
                }}
                index={index + pendingAdditions.length}
                key={addition.id}
                {...addition}
              >
                {isCustomAddition ? (
                  <TextField
                    isDense
                    label=""
                    name=""
                    defaultValue={addition.name}
                    onChange={(event) => {
                      additionUpdateDebounced({
                        type: 'custom',
                        id: String(addition.id),
                        calculationId,
                        name: event.target.value,
                        priceCents: addition.price,
                        marginPercent: addition.margin,
                        amount: addition.amount
                      });
                    }}
                  />
                ) : (
                  <CreatableSelect
                    filterOption={filterSelectOptionAndValue}
                    menuPosition="fixed"
                    sx={{ fontSize: 'sm', fontFamily: 'body' }}
                    isDisabled
                    options={additionsOptions}
                    value={selectedOption}
                    onChange={(value) => {
                      if (R.isNil(value)) {
                        return;
                      }
                      additionUpdate.mutate({
                        type: 'existing',
                        amount: addition.amount,
                        id: value.value,
                        product: true,
                        marginPercent: addition.margin,
                        calculationId
                      });
                    }}
                  />
                )}
              </Addition>
            );
          })}
        </RightMenuSection>
      </Box>
    </Box>
  );
}

export function RightMenuAdditions({ onClose }: { onClose: () => void }): JSX.Element {
  const { calculationId } = useEditorContext();
  const calculationAccessories = EditorApi.useCalculationAccessories(calculationId);
  const allProducts = EditorApi.useGetAllProducts(calculationId);

  if (
    isQueryLoading(calculationAccessories.isLoading, calculationAccessories.data) ||
    isQueryLoading(allProducts.isLoading, allProducts.data)
  ) {
    return <React.Fragment />;
  }

  return (
    <AdditionsList
      calculationId={calculationId}
      allProducts={allProducts.data}
      additions={calculationAccessories.data.additions}
      onClose={onClose}
    />
  );
}
