import { RightMenu } from '@/features/editor/components/right-menu/right-menu';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { OverlayImageInProgressControls } from '@/features/google-map/features/map-overlay-image/overlay-image-in-progress-controls';

export const EditorRightMenu = observer(function EditorRightMenu() {
  const store = useEditorStore();

  if (store.overlayImage.status !== 'idle') {
    return <OverlayImageInProgressControls />;
  }

  return <RightMenu />;
});
