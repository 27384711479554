/** @jsxImportSource theme-ui */
import { Flex, IconButton } from 'theme-ui';
import {
  IRightMenuSectionProps,
  RightMenuSectionContent,
  RightMenuSectionTitle
} from '@/features/editor/components/right-menu/components/right-menu-section';
import { filterSelectOptionAndValue, SelectField } from '@/components/select-field/select-field';
import React from 'react';
import { ButtonPrimary, ButtonSecondary } from '@/components/button/button';
import { TabBottomButtonsHolder } from '@/features/editor/components/right-menu/components/tab-bottom-buttons-holder';
import { FormattedMessage } from 'react-intl';
import { Box } from '@/components/box';
import { RightMenuListSectionTitle } from '@/features/editor/components/right-menu/components/right-menu-list-section-title';
import { EditorApi } from '@/features/editor';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { IProductRequestBase } from '@/features/editor/api';
import { R } from '@/lib/remeda';
import { UnwrapArray } from '@/utils/types';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { useEditorContext } from '@/features/editor/stores/use-editor-context';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { useNavigate } from '@/lib/react-router/use-navigate';
import { isFeatureFlagActive } from '@/utils/feature-flags';
import { transparentize } from '@theme-ui/color';

function AccessoriesSection({ title, children }: IRightMenuSectionProps): JSX.Element {
  return (
    <Box>
      <RightMenuSectionTitle
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1
        }}
      >
        {title}
      </RightMenuSectionTitle>
      <RightMenuSectionContent>{children}</RightMenuSectionContent>
    </Box>
  );
}

function RightMenuAccessoriesProduct({
  options,
  existingIds,
  productType,
  title,
  calculationId
}: {
  existingIds: number[];
  options: { label: string; value: string }[];
  productType: IProductRequestBase['productType'];
  title: React.ReactNode;
  calculationId: number;
}): JSX.Element {
  const productAddRemove = EditorApi.useProductAddRemove();
  const productUpdate = EditorApi.useProductUpdate();

  function matchValue(id: number): UnwrapArray<typeof options> | undefined {
    return options.find((option) => option.value === String(id));
  }

  return (
    <AccessoriesSection
      title={
        <RightMenuListSectionTitle
          onItemAdd={() => {
            productAddRemove.mutate({
              action: 'add',
              calculationId,
              productId: 0,
              productType
            });
          }}
          title={title}
        />
      }
    >
      {existingIds.every((item) => matchValue(item) === undefined) && (
        <SelectField
          filterOption={filterSelectOptionAndValue}
          onChange={(newValue) => {
            if (R.isNil(newValue)) {
              return;
            }
            productAddRemove.mutate({
              action: 'add',
              calculationId,
              productId: parseInt(newValue.value),
              productType
            });
          }}
          label=""
          name=""
          value={matchValue(0)}
          options={options}
        />
      )}
      {existingIds.map((id, index) => (
        <Flex
          key={`${id}-${index}`}
          sx={{
            alignItems: 'center',
            gap: 2,
            '& > *:first-child': {
              flexGrow: 1
            }
          }}
        >
          <SelectField
            filterOption={filterSelectOptionAndValue}
            onChange={(newValue) => {
              if (R.isNil(newValue)) {
                return;
              }
              productUpdate.mutate({
                action: 'edit',
                calculationId,
                productId: parseInt(newValue.value),
                productType,
                index
              });
            }}
            label=""
            name=""
            value={matchValue(id)}
            options={options}
          />

          <IconButton
            sx={{ color: '#9EA5B8', minWidth: 32 }}
            onClick={() => {
              productAddRemove.mutate({
                action: 'remove',
                productId: id,
                index,
                productType,
                calculationId
              });
            }}
          >
            <MdRemoveCircleOutline size={20} />
          </IconButton>
        </Flex>
      ))}
    </AccessoriesSection>
  );
}

export function RightMenuAccessories({ onAdditionsClick }: { onAdditionsClick: () => void }): JSX.Element {
  const { calculationId } = useEditorContext();
  const allProducts = EditorApi.useGetAllProducts(calculationId);
  const calculationAccessories = EditorApi.useCalculationAccessories(calculationId);

  const formatCurrency = useFormatCurrency();
  const navigate = useNavigate();

  if (
    isQueryLoading(allProducts.isLoading, allProducts.data) ||
    isQueryLoading(calculationAccessories.isLoading, calculationAccessories.data)
  ) {
    return <React.Fragment />;
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
        alignSelf: 'stretch',
        position: 'relative',
        isolation: 'isolate'
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          gap: 10,
          px: 5,
          pt: 10
        }}
      >
        <RightMenuAccessoriesProduct
          calculationId={calculationId}
          existingIds={calculationAccessories.data.accessories}
          options={allProducts.data.accessories.map((product) => ({
            label: `${product.name_accessory} | ${formatCurrency(product.sellprice)}`,
            value: String(product.info_id)
          }))}
          productType="accessories"
          title={<FormattedMessage defaultMessage="Installation accessories" id="S6qbD/" />}
        />

        <RightMenuAccessoriesProduct
          calculationId={calculationId}
          existingIds={calculationAccessories.data.batteries}
          options={allProducts.data.batteries.map((product) => ({
            label: `${product.name_battery} | ${formatCurrency(product.sellprice)}`,
            value: String(product.info_id)
          }))}
          productType="batteries"
          title={<FormattedMessage defaultMessage="Energy storage" id="qxnkFh" />}
        />

        <RightMenuAccessoriesProduct
          calculationId={calculationId}
          existingIds={calculationAccessories.data.wallbox}
          options={allProducts.data.wallboxes.map((product) => ({
            label: `${product.name_wallbox} | ${formatCurrency(product.sellprice)}`,
            value: String(product.info_id)
          }))}
          productType="wallbox"
          title={<FormattedMessage defaultMessage="Charging box" id="/juEsH" />}
        />
      </Flex>

      <TabBottomButtonsHolder>
        <ButtonSecondary onClick={onAdditionsClick}>
          <FormattedMessage defaultMessage="Additions" id="FdA2Cu" />
        </ButtonSecondary>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {isFeatureFlagActive('esdec') && (
            <ButtonPrimary
              onPress={() => navigate('/esdec')}
              sx={{
                flex: 1,
                backgroundColor: 'darkorange',
                '&:hover': { backgroundColor: transparentize('darkorange', 0.18) }
              }}
            >
              <FormattedMessage defaultMessage="Esdec" id="dey0Mq" />
            </ButtonPrimary>
          )}
          <ButtonPrimary onPress={() => navigate('/results')} sx={{ flex: 1 }}>
            <FormattedMessage defaultMessage="Calculations" id="eE9icP" />
          </ButtonPrimary>
        </Box>
      </TabBottomButtonsHolder>
    </Flex>
  );
}
