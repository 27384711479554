import { IPoint } from '@/utils/gis/types';
import * as R from 'remeda';

export function isPointEqual(first: IPoint | undefined, last: IPoint | undefined): boolean {
  if (R.isNil(first) || R.isNil(last)) {
    return false;
  }

  return first[0] === last[0] && first[1] === last[1];
}
