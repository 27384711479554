import { IPoint } from '@/utils/gis/types';

/**
 * Utilities for converting from Google Maps to our own types.
 * */
export class GoogleMapConversion {
  // Get the path of a polygon
  public static polygonPath(polygon: google.maps.Polygon): IPoint[] {
    return polygon
      .getPath()
      .getArray()
      .map((p) => [p.lng(), p.lat()]);
  }

  public static latLngToPoint(point: google.maps.LatLng): IPoint {
    return [point.lng(), point.lat()];
  }

  public static pointToLatLng(point: IPoint): google.maps.LatLng {
    return new google.maps.LatLng(point[1], point[0]);
  }
}
