import * as R from 'remeda';
import React from 'react';
import { positionToPoint } from '@/utils/turf/position-to-point';
import { hashPolygonPath } from '@/utils/drawing/hash-polygon-path';
import { SurfacePanelsMap, useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { themeColors } from '@/lib/theme-ui/colors';
import { IPoint } from '@/utils/gis/types';
import * as turf from '@turf/helpers';
import { getPointFromMouseEvent } from '@/features/google-map/utils/get-point-from-mouse-event';
import { RectanglePoint } from '@/utils/turf/rectangle-polygon';
import { useContextMenuStore } from '@/features/editor/stores/use-context-menu-store';
import { getPositionFromMouseEvent } from '@/features/google-map/utils/get-position-from-mouse-event';
import { MdContentCopy, MdDelete, MdSwapHoriz } from 'react-icons/md';
import { useGoogleMapContextMenu } from '@/features/google-map/utils/use-google-map-context-menu';
import { IGeneratedPanelAutomatic, IGeneratedPanelManual } from '@/features/editor/utils/panel/panel';
import { MapPolygon } from '@/features/google-map/components/shapes/map-polygon';
import { Gis } from '@/features/editor/utils/gis';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import { Feature, Polygon } from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';
import { EditorStore, useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

interface IManualHashed extends IGeneratedPanelManual {
  hash: string;
  surfaceId: string;
}

interface IAutomaticHashed extends IGeneratedPanelAutomatic {
  hash: string;
  surfaceId: string;
}

type PanelHashed = IManualHashed | IAutomaticHashed;

export function flattenedHashedPanels(surfacePanels: SurfacePanelsMap): PanelHashed[] {
  const panels: PanelHashed[] = [];
  for (const [surfaceId, p] of R.toPairs(surfacePanels)) {
    for (const panel of p) {
      const hash = hashPolygonPath(panel.panel.geometry.coordinates[0]!.map(positionToPoint));
      if (panel.type === 'automatic') {
        panels.push({
          type: 'automatic',
          id: panel.id,
          hash,
          panel: panel.panel,
          surfaceId
        });
      } else {
        panels.push({
          originalPoint: panel.originalPoint,
          id: panel.id,
          hash,
          surfaceId,
          panel: panel.panel,
          type: 'manual'
        });
      }
    }
  }
  return panels;
}

export function useMapDrag() {
  const [startPoint, setStartPoint] = React.useState<IPoint>();
  const [endPoint, setEndPoint] = React.useState<IPoint>();

  const distanceDelta = startPoint && endPoint ? Gis.distance(startPoint, endPoint) : 0;
  const bearingDelta = startPoint && endPoint ? Gis.rhumbBearing(startPoint, endPoint) : 0;

  return {
    distanceDelta,
    bearingDelta,

    onStart: React.useCallback((point: IPoint) => {
      setStartPoint(point);
    }, []),
    onDrag: React.useCallback((point: IPoint) => {
      setEndPoint(point);
    }, []),
    onEnd: React.useCallback(
      (endPoint: IPoint) => {
        const result = {
          distanceDelta: Gis.distance(startPoint!, endPoint),
          bearingDelta: Gis.rhumbBearing(startPoint!, endPoint)
        };

        setStartPoint(undefined);
        setEndPoint(undefined);

        return result;
      },
      [startPoint]
    )
  };
}

export function getSelectedPanelIds(
  panels: PanelHashed[],
  selectedSurfaceId: string | undefined,
  store: EditorStore
) {
  const selectedPanelIds = new Set<string>();
  for (const panel of panels) {
    if (
      panel.surfaceId === selectedSurfaceId &&
      store.selection.isSelected({
        path: Gis.polygonFeatureToPath(panel.panel),
        id: panel.id
      })
    ) {
      selectedPanelIds.add(panel.id);
    }
  }
  return selectedPanelIds;
}

export const RenderPanelsOnSurfaces = observer(function RenderPanelsOnSurfaces() {
  const { surfacePanels } = useSurfacePanels();
  const panels = React.useMemo(() => flattenedHashedPanels(surfacePanels), [surfacePanels]);
  const [lastClickPoint, setLastClickPoint] = React.useState<IPoint>();
  const [isAltKeyPressed, setIsAltKeyPressed] = React.useState(false);

  const { openContextMenu } = useContextMenuStore();
  const { openMultiSelectionContextMenu } = useGoogleMapContextMenu();
  const store = useEditorStore();

  const selectedSurfaceId = store.surfaces.selectedSurfaceId;
  const isDraggable = store.state.isDragging;
  const isSelecting = store.state.isSelecting;
  const isAddingOptimizer = store.state.isAddingOptimizer;
  const isSelectionInProgress = store.state.isSelectionInProgress;

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Alt') {
        setIsAltKeyPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Alt') {
        setIsAltKeyPressed(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [setIsAltKeyPressed]);

  const selectedPanelIds = getSelectedPanelIds(panels, selectedSurfaceId, store);

  function isPanelClickable(panelHashed: PanelHashed): boolean {
    return (
      !isSelectionInProgress &&
      !isAltKeyPressed &&
      selectedSurfaceId === panelHashed.surfaceId &&
      (isDraggable || isSelecting || isAddingOptimizer)
    );
  }

  function isPanelDraggable(panelHashed: PanelHashed): boolean {
    return selectedSurfaceId === panelHashed.surfaceId && (isDraggable || !isAltKeyPressed);
  }

  const selectPanelsBetweenPoints = React.useCallback(
    (pointA: IPoint, pointB: IPoint) => {
      const line = turf.lineString([pointA, pointB]);
      store.selection.select(
        panels.filter((panel) => booleanIntersects(line, panel.panel)).map((panel) => panel.id)
      );
    },
    [panels, store.selection]
  );

  const { distanceDelta, bearingDelta, onStart, onEnd, onDrag } = useMapDrag();

  return (
    <React.Fragment>
      {panels.map((panel) => {
        const originalPath = Gis.polygonFeatureToPath(panel.panel);
        const transformedPath = Gis.transformTranslate(originalPath, distanceDelta, bearingDelta);
        const isSelected = selectedPanelIds.has(panel.id);

        const path = isSelected ? transformedPath : originalPath;

        return (
          <MapPolygon
            onDragStart={(event) => {
              const point = getPointFromMouseEvent(event);
              if (!point) {
                return;
              }
              onStart(point);
            }}
            onDrag={(event) => {
              const point = getPointFromMouseEvent(event);
              if (!point) {
                return;
              }
              onDrag(point);
            }}
            onDragEnd={(event) => {
              const point = getPointFromMouseEvent(event);
              if (!point) {
                return;
              }

              const affectedPanels = panels.filter((panel) => selectedPanelIds.has(panel.id));
              const { distanceDelta, bearingDelta } = onEnd(point);

              if (panel.type === 'automatic') {
                store.surfaces.panelsAutomaticToManual({
                  surfaceId: panel.surfaceId,
                  panels: affectedPanels.map((panel) => {
                    const { topLeft, originalPoint } = getPanelPoints(panel.panel);
                    return {
                      originalPoint,
                      topLeft
                    };
                  })
                });
              } else {
                store.surfaces.panelsManualUpdate({
                  surfaceId: panel.surfaceId,
                  panels: affectedPanels.map((panel) => {
                    const { topLeft } = getPanelPoints(panel.panel);
                    return {
                      panelId: panel.id,
                      topLeft
                    };
                  })
                });
              }

              function getPanelPoints(panel: Feature<Polygon>) {
                const path = Gis.polygonFeatureToPath(panel);
                const originalPoint = RectanglePoint.topLeft(path);
                const topLeft = Gis.transformTranslate(originalPoint, distanceDelta, bearingDelta);
                return {
                  originalPoint,
                  topLeft
                };
              }
            }}
            onRightClick={(event) => {
              if (selectedPanelIds.size > 0) {
                return openMultiSelectionContextMenu(event, Array.from(selectedPanelIds));
              }

              const pasteOrigin = getPointFromMouseEvent(event);
              if (pasteOrigin === undefined) {
                return;
              }
              openContextMenu({
                position: getPositionFromMouseEvent(event),
                items: [
                  {
                    key: 'delete',
                    textValue: 'Delete',
                    onAction: () => {
                      if (!selectedSurfaceId) {
                        return;
                      }

                      store.surfaces.removePanelsFromSurface({
                        surfaceId: selectedSurfaceId,
                        panelTopLefts: [RectanglePoint.topLeft(Gis.polygonFeatureToPath(panel.panel))]
                      });
                    },
                    children: [
                      <MdDelete key="icon" />,
                      <span key="text">
                        <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
                      </span>
                    ]
                  },
                  {
                    key: 'toggle-optimizer',
                    textValue: 'Toggle optimizer',
                    onAction: () => {
                      store.surfaces.optimizersToggle([
                        {
                          path: Gis.polygonFeatureToPath(panel.panel),
                          surfaceId: panel.surfaceId
                        }
                      ]);
                    },
                    children: [
                      <MdSwapHoriz key="icon" />,
                      <span key="text">
                        <FormattedMessage defaultMessage="Toggle optimizer" id="MZDw8U" />
                      </span>
                    ]
                  },
                  ...(store.obstacles.copiedObstacle.length !== 0
                    ? [
                        {
                          key: 'paste',
                          children: [
                            <MdContentCopy key="icon" />,
                            <span key="text">
                              <FormattedMessage defaultMessage="Paste Obstacle" id="SkGAVE" />
                            </span>
                          ],
                          onAction: () => {
                            store.obstacles.obstaclePaste({
                              pasteOrigin: pasteOrigin,
                              copiedPoints: store.obstacles.copiedObstacle
                            });
                          }
                        }
                      ]
                    : [])
                ]
              });
            }}
            onClick={(event: google.maps.MapMouseEvent) => {
              const domEvent = event.domEvent as MouseEvent;
              if (isSelecting) {
                // if (!domEvent.ctrlKey && !domEvent.metaKey) {
                //   store.selection.deselectAll();
                // }
                store.selection.toggleSelect(panel.id);
                const point = getPointFromMouseEvent(event);
                if (point && lastClickPoint && domEvent.shiftKey) {
                  selectPanelsBetweenPoints(point, lastClickPoint);
                }
                if (point) {
                  setLastClickPoint(point);
                }
              }

              if (isAddingOptimizer) {
                store.surfaces.optimizersToggle([
                  {
                    path: Gis.polygonFeatureToPath(panel.panel),
                    surfaceId: panel.surfaceId
                  }
                ]);
              }
            }}
            hoveredStyles={{
              fillColor: themeColors.panelHover
            }}
            strokeColor={isSelected ? themeColors.panelHover : themeColors.panel}
            fillColor={themeColors.panel}
            zIndex={
              isSelected ? GOOGLE_MAP_Z_INDICES.SURFACE_PANEL_SELECTED : GOOGLE_MAP_Z_INDICES.SURFACE_PANEL
            }
            draggable={isPanelDraggable(panel)}
            clickable={isPanelClickable(panel)}
            path={path}
            key={panel.id}
          />
        );
      })}
    </React.Fragment>
  );
});
