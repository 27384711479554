import React from 'react';
import { SurfacesProvider } from '@/features/editor/utils/surface/use-surfaces';
import { SurfacePanelsProvider } from '@/features/editor/utils/panel/use-surface-panels';
import { EditorContextProvider } from '@/features/editor/stores/use-editor-context';
import { OptimizersProvider } from '@/features/editor/utils/optimizers/use-optimizers';
import { EditorServicesProvider } from '@/features/editor/providers/editor-services-provider';
import { EditorStoreProvider, IEditorStoreInitialContext } from '@/features/editor/stores/mobx/editor-store';

interface IEditorProviderProps {
  calculationId: number;
  initialContext: IEditorStoreInitialContext;
  children: React.ReactNode;
}

export function EditorProvider({
  children,
  initialContext,
  calculationId
}: IEditorProviderProps): JSX.Element {
  return (
    <EditorStoreProvider context={initialContext}>
      <EditorContextProvider calculationId={calculationId}>
        <EditorServicesProvider>
          <SurfacePanelsProvider>
            <SurfacesProvider>
              <OptimizersProvider>{children}</OptimizersProvider>
            </SurfacesProvider>
          </SurfacePanelsProvider>
        </EditorServicesProvider>
      </EditorContextProvider>
    </EditorStoreProvider>
  );
}
