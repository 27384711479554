import { ISide } from '@/utils/drawing/types';
import transformRotate from '@turf/transform-rotate';
import * as turf from '@turf/helpers';
import { ISurface } from '@/features/editor/utils/surface/surface';
import { Gis } from '@/features/editor/utils/gis';
import { IPoint } from '@/utils/gis/types';

// Given a line, calculates the angle of the direction opposite to the line.
export function oppositeAzimuth(side: ISide, surface: ISurface): number {
  const perpendicular = transformRotate(turf.lineString([side.start, side.end]), 90);
  const perpendicularStart = perpendicular.geometry.coordinates[0]! as IPoint;
  const perpendicularEnd = perpendicular.geometry.coordinates[1]! as IPoint;
  const perpendicularMiddle = Gis.midpoint(perpendicularStart, perpendicularEnd);

  const leftAzimuth = Gis.bearingToAzimuth(Gis.rhumbBearing(perpendicularMiddle, perpendicularStart));
  const rightAzimuth = Gis.bearingToAzimuth(Gis.rhumbBearing(perpendicularMiddle, perpendicularEnd));

  const isSurfaceLeft = Gis.booleanPointInPolygon(
    Gis.rhumbDestination(perpendicularMiddle, 0.1, leftAzimuth, {
      units: 'meters'
    }),
    surface.path
  );

  return isSurfaceLeft ? leftAzimuth : rightAzimuth;
}
