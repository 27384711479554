import * as R from 'remeda';
import { deepEqual } from '@/utils/deep-equal';
import { Shape } from '@/features/google-map/utils/shapes/shape';
import { IMarkerShape, Marker } from '@/features/google-map/utils/shapes/marker';
import { IRectangleShape, Rectangle } from '@/features/google-map/utils/shapes/rectangle';
import { isPolygon, isPolygonPanel, Polygon, PolygonPanel } from '@/features/google-map/utils/shapes/polygon';
import { isPolyline, Polyline } from '@/features/google-map/utils/shapes/polyline';

type RenderedShape = google.maps.Marker | google.maps.Rectangle | google.maps.Polygon | google.maps.Polyline;

type SubscriberFnAction = 'remove' | 'update' | 'add';
type SubscriberFn = (affectedIds: string[], action: SubscriberFnAction) => void;
type UnsubscribeFn = () => void;

interface IManagedShape {
  id: string;
  rendered: RenderedShape;
  shape: Shape;
}

type RenderedShapeMap<T extends Shape> = T extends Polygon
  ? google.maps.Polygon
  : T extends Polyline
  ? google.maps.Polyline
  : T extends Marker
  ? google.maps.Marker
  : T extends Rectangle
  ? google.maps.Rectangle
  : never;

/*
 * Manages the rendering of shapes on the map.
 *
 * Given a shape object with an ID, it will add/update/remove the object from the internal
 * store, and it will render/destroy the object and all of its listeners if needed.
 */
export class ShapeManager {
  private readonly _map: google.maps.Map;
  private _shapes: Record<string, IManagedShape> = {};
  private _subscribers: SubscriberFn[] = [];

  public constructor(map: google.maps.Map) {
    this._map = map;
  }

  public subscribe(subscriber: SubscriberFn): UnsubscribeFn {
    this._subscribers.push(subscriber);
    return () => {
      this._subscribers = R.reject(this._subscribers, (fn) => fn === subscriber);
    };
  }

  public get shapes(): Record<string, IManagedShape> {
    return this._shapes;
  }

  public addShape<T extends Shape>(shape: T): RenderedShapeMap<T> {
    const [rendered] = this._addShapes([shape]);
    return rendered as RenderedShapeMap<T>;
  }

  public addShapes(shapes: Shape[]): RenderedShape[] {
    return this._addShapes(shapes);
  }

  public getShape(id: string): IManagedShape | undefined {
    return this._shapes[id];
  }

  public addMarker(marker: IMarkerShape): void {
    this._addShape(new Marker(marker.id, marker.point, marker.label, marker.options));
  }

  public addRectangle(rectangle: IRectangleShape): void {
    this._addShape(new Rectangle(rectangle.id, rectangle.bounds, rectangle.options));
  }

  public removeShape(id: string): void {
    this.removeShapes([id]);
  }

  public getPolylines(ids?: string[]): Polyline[] {
    if (ids === undefined) {
      return R.values(this._shapes)
        .map(({ shape }) => shape)
        .filter(isPolyline);
    }
    return R.map(ids, (id) => this._shapes[id]?.shape).filter(isPolyline);
  }

  public getPolyline(id: string): Polyline | undefined {
    const shape = this._shapes[id];
    if (shape && shape.shape instanceof Polyline) {
      return shape.shape;
    }
    return undefined;
  }

  public removeShapes(shapesOrIds: string[] | Shape[]): void {
    let hasRemoved = false;
    const ids: string[] = [];

    for (const shapeOrId of shapesOrIds) {
      const id = typeof shapeOrId === 'string' ? shapeOrId : shapeOrId.id;
      const shape = this._shapes[id];
      ids.push(id);

      if (shape) {
        const isRemoved = this._onRemoveShape(shape);
        if (isRemoved) {
          hasRemoved = true;
        }
      }
    }
    if (hasRemoved) {
      this._notifySubscribers(ids, 'remove');
    }
  }

  public getAllShapes(predicate: (shape: Shape) => boolean): Shape[] {
    return R.pipe(
      this._shapes,
      R.values,
      R.map(({ shape }) => shape),
      R.filter(predicate)
    );
  }

  public getPolygonPanels(ids?: string[]): PolygonPanel[] {
    if (ids === undefined) {
      return this.getPolygons().filter(isPolygonPanel);
    }
    return R.map(ids, (id) => this._shapes[id]?.shape).filter(isPolygonPanel);
  }

  public getPolygons(): Polygon[] {
    return R.values(this._shapes)
      .map(({ shape }) => shape)
      .filter(isPolygon);
  }

  public getRenderedShape(id: string): RenderedShape | undefined {
    return this._shapes[id]?.rendered;
  }

  public updateShapes<T extends Shape>(ids: string[], getNewShape: (existingShape: T) => T): void {
    R.pipe(
      ids,
      R.map((id) => this._shapes[id]),
      R.filter(R.isDefined),
      R.forEach((oldShape) => {
        const newShape = R.merge(oldShape, { shape: getNewShape(oldShape.shape as T) });
        this._onUpdateShape(oldShape, newShape);
      })
    );
    this._notifySubscribers(ids, 'update');
  }

  public updateShape<T extends Shape>(id: string, getNewShape: (existingShape: T) => T): void {
    this.updateShapes([id], getNewShape);
  }

  private static _getUpdatedShape(oldShape: Shape, newShape: Shape): Shape {
    return oldShape.update(newShape);
  }

  private _addShape(shape: Shape): RenderedShape | undefined {
    const [rendered] = this._addShapes([shape]);
    return rendered;
  }

  private _addShapes(shapes: Shape[]): RenderedShape[] {
    const result: RenderedShape[] = [];
    for (const shape of shapes) {
      const oldShape = this._shapes[shape.id];
      const newShape =
        oldShape === undefined
          ? {
              id: shape.id,
              shape: shape,
              rendered: shape.build()
            }
          : {
              ...oldShape,
              shape: ShapeManager._getUpdatedShape(oldShape.shape, shape)
            };

      if (oldShape) {
        this._onUpdateShape(oldShape, newShape);
      } else {
        this._onAddShape(newShape);
      }

      result.push(newShape.rendered);
    }

    this._notifySubscribers(
      shapes.map((shape) => shape.id),
      'add'
    );
    return result;
  }

  private _notifySubscribers(ids: string[], action: SubscriberFnAction): void {
    this._subscribers.forEach((subscriber) => subscriber(ids, action));
  }

  private _onRemoveShape(shape: IManagedShape): boolean {
    if (this.getShape(shape.id) === undefined) {
      return false;
    }

    shape.shape.removeEventListeners();
    shape.rendered.setMap(null);
    shape.shape.removeEffects();
    delete this._shapes[shape.id];

    return true;
  }

  private _onUpdateShape(oldShape: IManagedShape, newShape: IManagedShape): void {
    const options = newShape.shape.options ?? {};
    const areOptionsEqual = deepEqual(oldShape.shape.options, options);
    oldShape.shape.removeEventListeners();
    if (!areOptionsEqual) {
      newShape.rendered.setOptions(options);
    }
    newShape.shape.attachEventListeners(newShape.rendered);
    this._shapes[newShape.id] = newShape;
  }

  private _onAddShape(shape: IManagedShape): void {
    shape.shape.attachEventListeners(shape.rendered);
    shape.rendered.setMap(this._map);
    shape.shape.runEffects(shape.rendered);
    this._shapes[shape.id] = shape;
  }
}

export type { RenderedShape };
