import { IPoint } from './types';
import { distanceVincenty } from '@/utils/gis/distance-vincenty';
import { Gis } from '@/features/editor/utils/gis';

interface ILine {
  start: IPoint;
  end: IPoint;
}

interface ICreateLengthMarkerOptions {
  formatLabel?: (length: number) => string;
}

interface ICreateLengthMarkerReturn {
  middle: IPoint;
  lengthLabel: string;
  length: number;
}

// Creates an object with the required properties for constructing a length label
export function createLengthMarkerForLine(
  side: ILine,
  { formatLabel = (length) => `${length.toFixed(2)}m` }: ICreateLengthMarkerOptions = {}
): ICreateLengthMarkerReturn {
  const [lng, lat] = Gis.midpoint(side.start, side.end);
  const length = distanceVincenty(side.start, side.end);

  return {
    middle: [lng, lat] as IPoint,
    lengthLabel: formatLabel(length),
    length
  };
}
