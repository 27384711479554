import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { themeColors } from '@/lib/theme-ui/colors';
import {
  IEditorResultsReturnOverTimeProps,
  IGridData
} from '@/features/editor-results/components/editor-results-return-over-time/editor-results-return-over-time';

interface IFormattedCell {
  cellData: React.ReactNode;
}

function FormattedCell({ cellData }: IFormattedCell): JSX.Element {
  return (
    <Text
      style={{
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto'
      }}
    >
      {cellData}
    </Text>
  );
}

function GridHeader(): JSX.Element {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingVertical: 10,
        paddingHorizontal: 12,
        color: themeColors.textLight
      }}
    >
      <FormattedCell cellData={<FormattedMessage defaultMessage="År" id="IFo1oo" />} />
      <FormattedCell cellData={<FormattedMessage defaultMessage="Producerad kWh" id="R6dk+y" />} />
      <FormattedCell cellData={<FormattedMessage defaultMessage="Värde el" id="UK9MgF" />} />
      <FormattedCell cellData={<FormattedMessage defaultMessage="Ränta efter avdrag" id="0Nsi3v" />} />
      <FormattedCell cellData={<FormattedMessage defaultMessage="Amortering" id="QwUFnM" />} />
      <FormattedCell cellData={<FormattedMessage defaultMessage="I plånboken" id="IRyVbh" />} />
      <FormattedCell cellData={<FormattedMessage defaultMessage="Totalt i plånboken" id="hDIHfu" />} />
    </View>
  );
}

function GridData({
  year,
  producedKwh,
  electricityValue,
  interestAfterDeductionPercent,
  amortization,
  walletCents,
  totalWalletCents,
  isCompact = false
}: IGridData & {
  isCompact?: boolean;
}): JSX.Element {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingVertical: isCompact ? 6 : 8,
        paddingHorizontal: 12,
        borderTop: 1,
        borderColor: themeColors.inputBorder
      }}
    >
      <FormattedCell cellData={year} />
      <FormattedCell cellData={producedKwh} />
      <FormattedCell cellData={electricityValue} />
      <FormattedCell cellData={interestAfterDeductionPercent} />
      <FormattedCell cellData={amortization} />
      <FormattedCell cellData={walletCents} />
      <FormattedCell cellData={totalWalletCents} />
    </View>
  );
}

export function EditorResultsReturnOverTimePrintable({
  data
}: IEditorResultsReturnOverTimeProps): JSX.Element {
  return (
    <View
      style={{
        borderRadius: 5,
        overflow: 'hidden',
        border: '1',
        borderColor: themeColors.inputBorder,
        backgroundColor: 'white',
        fontFamily: 'Inter',
        fontSize: 8
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 12,
          paddingVertical: 12,
          marginBottom: 12,
          fontSize: 9,
          backgroundColor: '#E6F7ED'
        }}
      >
        <Text>
          <FormattedMessage defaultMessage="Return över tid" id="BOBy3W" />
        </Text>
      </View>

      <View
        style={{
          fontSize: 8,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start'
        }}
      >
        <GridHeader />
        {data.map((item, index) => (
          <GridData
            isCompact={data.length > 25}
            key={index}
            year={`${index + 1}`}
            amortization={item.amort}
            interestAfterDeductionPercent={item.interest}
            producedKwh={item.production}
            electricityValue={item.valuePerYear}
            walletCents={item.liquidity}
            totalWalletCents={item.liquidityAcc}
          />
        ))}
      </View>
    </View>
  );
}
