"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsdecApiFactory = void 0;
const src_1 = require("./src");
function EsdecApiFactory(configuration) {
    return {
        Project: (0, src_1.ProjectResourceApiFactory)(configuration),
        // Account: AccountResourceApiFactory(configuration),
        //Account: AuditResourceApiFp(configuration),
        Article: (0, src_1.ArticleResourceApiFactory)(configuration),
        //Audit: AuditResourceApiFactory(configuration),
        Calculation: (0, src_1.CalculationResourceApiFactory)(configuration),
        //Configuration: ConfigurationResourceApiFactory(configuration),
        Roof: (0, src_1.RoofResourceApiFactory)(configuration),
        Shortcut: (0, src_1.ShortcutResourceApiFactory)(configuration),
        SolarPanel: (0, src_1.SolarPanelResourceApiFactory)(configuration),
        //UserJwtController: UserJwtControllerApiFactory(configuration),
        UserJwtController: (0, src_1.UserAccountControllerApiFp)(configuration),
        //User: UserResourceApiFactory(configuration),
        User: src_1.VendorResourceApi,
        Vendor: (0, src_1.VendorResourceApiFactory)(configuration),
        Webshop: (0, src_1.WebshopResourceApiFactory)(configuration),
        WindData: (0, src_1.WindDataResourceApiFactory)(configuration)
    };
}
exports.EsdecApiFactory = EsdecApiFactory;
__exportStar(require("./src"), exports);
