import { Icons } from '@/assets';
import { FormattedMessage } from 'react-intl';
import { EsdecSurfaceCardSection } from './esdec-surface-card-section';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import React from 'react';
import { EsdecParapetHeightInput } from './esdec-parapet-height-input';
import { Box } from 'theme-ui';
import { EsdecPurlinDistanceInput } from './esdec-purlin-distance-input';
import { EsdecSteelTypeInput } from './esdec-steel-type-input';
import { EsdecRoofStructureInput } from './esdec-roof-structure-input';
import { EsdecSeamTypeInput } from './esdec-seam-type-input';
import { EsdecFlangeDistanceInput } from './esdec-flange-distance-input';
import { EsdecRafterDistanceInput } from './esdec-rafter-distance-input';
import { SurfaceEsdec } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { EsdecRoofMaterialInput } from './esdec-roof-material-input';
import { EsdecRoofSideTypeInput } from './esdec-roof-side-type-input';
import { EsdecSnowFencesInput } from './esdec-snow-fences-input';

interface ISettingsProps {
  surfaceId: string;
}

const FlatRoofSettings = observer(function FlatRoofSettings({ surfaceId }: ISettingsProps) {
  return (
    <Box sx={{ flexBasis: '100%' }}>
      <EsdecParapetHeightInput surfaceId={surfaceId} />
    </Box>
  );
});

interface ISlopedSettingsProps extends ISettingsProps {
  surfaceEsdec: SurfaceEsdec;
}

const CONFIGURATION_FLEX_CHILD_STYLE = '1 1 45%';
const CONFIGURATION_FLEX_CHILD_STYLE_20 = '1 1 20%';

const SlopedRoofSettings = observer(function SlopedRoofSettings({
  surfaceId,
  surfaceEsdec
}: ISlopedSettingsProps) {
  const showSteelType = surfaceEsdec.material === 'STEEL';
  const showPurlinDistance =
    surfaceEsdec.material === 'CORRUGATED' ||
    (surfaceEsdec.material === 'BITUMEN_INSULATED' && surfaceEsdec.roofStructure === 'PURLINS');
  const showRoofStructure = surfaceEsdec.material === 'BITUMEN_INSULATED';
  const showRafterDistance =
    surfaceEsdec.material === 'BITUMEN_INSULATED' && surfaceEsdec.roofStructure === 'RAFTERS';
  const showSeamType = surfaceEsdec.material === 'STANDING_SEAM';
  const showFlangeDistance = surfaceEsdec.material === 'STANDING_SEAM';

  return (
    <React.Fragment>
      <Box
        sx={{
          flex:
            (showSteelType || showPurlinDistance || showRoofStructure || showSeamType) &&
            CONFIGURATION_FLEX_CHILD_STYLE
        }}
      >
        <EsdecRoofSideTypeInput surfaceId={surfaceId} />
      </Box>

      {showSteelType && <EsdecSteelTypeInput surfaceId={surfaceId} />}
      {showRoofStructure && <EsdecRoofStructureInput surfaceId={surfaceId} />}
      {showPurlinDistance && (
        <Box sx={{ flex: CONFIGURATION_FLEX_CHILD_STYLE_20 }}>
          <EsdecPurlinDistanceInput surfaceId={surfaceId} />
        </Box>
      )}

      {showRafterDistance && (
        <Box sx={{ flex: CONFIGURATION_FLEX_CHILD_STYLE_20 }}>
          <EsdecRafterDistanceInput surfaceId={surfaceId} />
        </Box>
      )}

      {showSeamType && <EsdecSeamTypeInput surfaceId={surfaceId} />}
      {showFlangeDistance && (
        <Box sx={{ flex: CONFIGURATION_FLEX_CHILD_STYLE_20 }}>
          <EsdecFlangeDistanceInput surfaceId={surfaceId} />
        </Box>
      )}

      <Box
        sx={{
          flex:
            (showPurlinDistance || showRafterDistance || showFlangeDistance) &&
            CONFIGURATION_FLEX_CHILD_STYLE_20
        }}
      >
        <EsdecSnowFencesInput surfaceId={surfaceId} isEsdecToggle />
      </Box>
    </React.Fragment>
  );
});

export const EsdecRoofSettingsCard = observer(function EsdecWeatherZonesCard({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec || !surface || surface.placement === 'ground') {
    return <React.Fragment />;
  }

  return (
    <EsdecSurfaceCardSection
      title={<FormattedMessage defaultMessage="Roof settings" id="GjsM+f" />}
      icon={<Icons.Sliders />}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          rowGap: 3,
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          '& > *': {
            flex: CONFIGURATION_FLEX_CHILD_STYLE
          }
        }}
      >
        <EsdecRoofMaterialInput surfaceId={surfaceId} />

        {surface.placement === 'flat' ? (
          <FlatRoofSettings surfaceId={surfaceId} />
        ) : (
          <SlopedRoofSettings surfaceId={surfaceId} surfaceEsdec={surfaceEsdec} />
        )}
      </Box>
    </EsdecSurfaceCardSection>
  );
});
