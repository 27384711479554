/** @jsxImportSource theme-ui */
import { LabelProps, Label as ThemeUiLabel } from 'theme-ui';
import React from 'react';

interface ILabelProps extends LabelProps {}

function LabelInner(props: ILabelProps, ref: React.Ref<HTMLLabelElement>): JSX.Element {
  return (
    <ThemeUiLabel
      ref={ref}
      sx={{
        fontFamily: 'body',
        fontSize: '12px',
        display: 'inline-block'
      }}
      {...props}
    />
  );
}

export const Label = React.forwardRef(LabelInner);
