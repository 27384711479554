import { R } from '@/lib/remeda';

function differenceBy<T>(array: readonly T[], other: readonly T[], by: (value: T) => unknown): T[] {
  const arrayBy = array.map(by);
  const otherBy = other.map(by);
  const difference = R.difference(arrayBy, otherBy);

  return array.filter((value) => difference.includes(by(value)));
}

export { differenceBy };
