import { EditorContext } from '@/features/editor/utils/editor-context';
import { UnwrapArray } from '@/utils/types';
import { IGetAllProductsApiResponse } from '@/features/editor/api';
import { R } from '@/lib/remeda';
import { IInverter } from '@/features/editor/utils/inverters/inverter';
import { isOptimizerInInverterFamily } from '@/features/editor/utils/optimizers/filter-optimizers-by-inverter-family';
import { IPanelType } from '@/features/editor/utils/panel/panel';

type OptimizerProduct = Pick<
  UnwrapArray<IGetAllProductsApiResponse['optimizerTypes']>,
  'productId' | 'production' | 'familyId'
>;
type InverterProduct = Pick<
  UnwrapArray<IGetAllProductsApiResponse['inverterTypes']>,
  'optimizerFamilyId' | 'productId'
>;
type ContextSubset = Pick<EditorContext, 'inverters' | 'surfaces'> & {
  panelTypes: IPanelType[];
};

function getInverterSurfacesWithOptimizers(context: ContextSubset, inverter: IInverter) {
  return context.surfaces.filter(
    (surface) => inverter.surfaceIds.includes(surface.id) && surface.optimizerPoints.length > 0
  );
}

function getPanelPowers(panelTypes: IPanelType[]) {
  return panelTypes.reduce((acc, panelType) => {
    acc[panelType.productId] = panelType.power;
    return acc;
  }, {} as Record<string, number>);
}

// Returns a map of inverter id to suitable optimizers for that inverter
export function getSuitableOptimizersForInverters<Optimizer extends OptimizerProduct>(
  context: ContextSubset,
  {
    optimizerProducts,
    inverterProducts
  }: {
    optimizerProducts: Optimizer[];
    inverterProducts: InverterProduct[];
  }
): Record<string, Optimizer[]> {
  const result: Record<string, Optimizer[]> = {};

  for (const inverter of context.inverters) {
    const inverterProduct = R.find(
      inverterProducts,
      (product) => product.productId === inverter.inverterTypeId
    );

    if (!inverterProduct) {
      result[inverter.id] = [];
      continue;
    }

    const inverterSurfaces = R.filter(context.surfaces, (surface) =>
      inverter.surfaceIds.includes(surface.id)
    );
    const panelPowers = getPanelPowers(context.panelTypes);
    const inverterSurfacesMaxPower = Math.max(
      ...inverterSurfaces.map((surface) => (surface.panelTypeId ? panelPowers[surface.panelTypeId] ?? 0 : 0))
    );

    result[inverter.id] = R.pipe(
      optimizerProducts,
      R.filter((optimizer) => isOptimizerInInverterFamily(inverterProduct, optimizer)),
      R.filter((optimizer) => optimizer.production >= inverterSurfacesMaxPower),
      (optimizers) => R.sortBy(optimizers, [(optimizer) => optimizer.production, 'asc'])
    );
  }

  return result;
}

// Walks the list of inverters and sets/removes the optimizer type as needed
export function setSuitableOptimizersForInverters(
  context: ContextSubset,
  {
    optimizerProducts,
    inverterProducts
  }: {
    optimizerProducts: OptimizerProduct[];
    inverterProducts: InverterProduct[];
  }
) {
  const inverterToOptimizers = getSuitableOptimizersForInverters(context, {
    optimizerProducts,
    inverterProducts
  });

  for (const inverter of context.inverters) {
    const suitableOptimizers = inverterToOptimizers[inverter.id] ?? [];
    const surfacesWithOptimizers = getInverterSurfacesWithOptimizers(context, inverter);

    // If no optimizers on the map, remove the optimizer type
    if (surfacesWithOptimizers.length === 0) {
      inverter.optimizerTypeId = undefined;
      continue;
    }

    // If it has an optimizer type, but it's not suitable, remove it
    if (inverter.optimizerTypeId) {
      const isCurrentOptimizerSuitable = suitableOptimizers.some(
        (optimizer) => optimizer.productId === inverter.optimizerTypeId
      );
      if (!isCurrentOptimizerSuitable) {
        inverter.optimizerTypeId = undefined;
      }
      continue;
    }

    // If it doesn't have an optimizer type, but there are suitable ones, set the first one
    inverter.optimizerTypeId = R.first(suitableOptimizers)?.productId;
  }
}
