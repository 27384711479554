import { Typography } from '@/components/typography';
import { EditorResultsCard } from '@/features/editor-results/components/editor-results-card/editor-results-card';
import { Box } from '@/components/box';
import { EditorResultsTableRow } from '@/features/editor-results/components/editor-results-table-row';
import { IGetEditorResultsApiResponse } from '@/features/editor-results/api/get-editor-results';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { formatKiloWatts } from '@/features/editor-results/components/editor-results-surface-card/editor-results-surface-card';

export type IEditorResultsPlantSizeProps = IGetEditorResultsApiResponse['sizeAndProduction'][string] & {
  panelTypes: string[];
  inverterTypes: string[];
  pricePerKwp: number;
};

export function EditorResultsPlantSize({
  panelsCount,
  effectPerPanel,
  installedEffect,
  yearlyProduction,
  yearlyProductionValue,
  warrantyTimeProduction,
  warrantyTimeProductionValue,
  panelTypes,
  inverterTypes,
  pricePerKwp
}: IEditorResultsPlantSizeProps): JSX.Element {
  const formatCurrency = useFormatCurrency();

  return (
    <EditorResultsCard.Wrapper>
      <EditorResultsCard.Title>
        <Typography variant="headline2">Anläggningens storlek och produktion</Typography>
      </EditorResultsCard.Title>

      <Box>
        <EditorResultsTableRow label="Typ av paneler" value={panelTypes} />
        <EditorResultsTableRow label="Typ av växelriktare" value={inverterTypes} />
        <EditorResultsTableRow label="Antal paneler" value={[panelsCount.value]} />
        <EditorResultsTableRow label="Genomsnittlig effekt per panel" value={[effectPerPanel.value]} />
        <EditorResultsTableRow label="Installerad effekt" value={[formatKiloWatts(installedEffect.value)]} />
        <EditorResultsTableRow label="Pris per kWp" value={[formatCurrency(pricePerKwp)]} />
        <EditorResultsTableRow label="Produktion per år initialt" value={[yearlyProduction.value]} />
        <EditorResultsTableRow
          label="Värdet av producerad el per år initialt"
          value={[formatCurrency(yearlyProductionValue.value)]}
        />
        <EditorResultsTableRow
          label="Tillverkning under garantitiden"
          value={[warrantyTimeProduction.value]}
        />
        <EditorResultsTableRow
          label="Värdet på el producerad under garantitiden"
          value={[formatCurrency(warrantyTimeProductionValue.value)]}
        />
      </Box>
    </EditorResultsCard.Wrapper>
  );
}
