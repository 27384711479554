const GOOGLE_MAP_Z_INDICES = {
  SURFACE: 1,
  SURFACE_PANEL: 2,
  SURFACE_PANEL_SELECTED: 3,
  OBSTACLE: 4,
  POINTER_SHAPE: 5,
  SELECT_SHAPE: 6,
  RIDGE_LINE: 7,
  RULER: 8,
  MARKER: 9,
  EDIT_HANDLE: 10
};

export { GOOGLE_MAP_Z_INDICES };
