import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
import { useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import React from 'react';
import { R } from '@/lib/remeda';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { IPoint } from '@/utils/gis/types';
import { Feature, Polygon } from '@turf/helpers';
import { createContext } from '@/utils/create-context';
import { EmptyPropsWithChildren } from '@/utils/types';

interface IUseOptimizersPayload {
  optimizer: IPoint;
  panel: Feature<Polygon>;
}

type IUseOptimizersReturn = Record<string, IUseOptimizersPayload[]>;

/**
 * Calculates which optimizers need to be shown on the map and returns them.
 * Only those which belong to panels that are currently visible on the map should be returned.
 * */
function useOptimizersInner(): IUseOptimizersReturn {
  const surfaces = useSurfaces();
  const { surfacePanels } = useSurfacePanels();

  return React.useMemo(() => {
    const result: IUseOptimizersReturn = {};
    for (const surface of surfaces) {
      const panels = surfacePanels[surface.id];
      if (R.isNil(panels)) {
        continue;
      }

      // We only allow one optimizer per panel, so keep track of which panels already have an optimizer.
      const usedPanels = new Set<string>();
      result[surface.id] = surface.optimizerPoints
        .map((optimizer) => {
          const panel = panels.find((panel) => booleanPointInPolygon(optimizer, panel.panel));
          if (R.isNil(panel) || usedPanels.has(panel.id)) {
            return undefined;
          }
          usedPanels.add(panel.id);
          return { optimizer, panel: panel.panel };
        })
        .filter(R.isDefined);
    }
    return result;
  }, [surfacePanels, surfaces]);
}

const [useOptimizers, OptimizersContextProvider] = createContext<IUseOptimizersReturn>({});

function OptimizersProvider({ children }: EmptyPropsWithChildren): JSX.Element {
  const value = useOptimizersInner();

  return <OptimizersContextProvider value={value}>{children}</OptimizersContextProvider>;
}

export { useOptimizers, OptimizersProvider };
export type { IUseOptimizersPayload };
