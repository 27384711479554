import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import { INumberFieldProps, NumberField } from '@/components/number-field/number-field';
import { R } from '@/lib/remeda';

type IRhfNumberFieldProps<TData extends FieldValues> = INumberFieldProps & {
  control: Control<TData>;
  name: FieldPath<TData>;
};

export function RhfNumberField<TData extends FieldValues>(props: IRhfNumberFieldProps<TData>): JSX.Element {
  const { name, control } = props;
  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control
  });

  return (
    <NumberField
      aria-label={R.isNil(props.label) ? props.placeholder : undefined}
      {...props}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value ?? NaN}
      errorMessage={error?.message}
    />
  );
}
