import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import * as turf from '@turf/helpers';
import center from '@turf/center';
import { IOptimizerPayload } from '@/features/editor/utils/optimizers/optimizer';
import { positionToPoint } from '@/utils/turf/position-to-point';
import { ISurface } from '@/features/editor/utils/surface/surface';

interface IData {
  surfaces: ISurface[];
}

// Removes all optimizers inside the path specified in the payload
export function removeOptimizerFromPath({ surfaces }: IData, payload: IOptimizerPayload[]): void {
  for (const p of payload) {
    const surface = surfaces.find((s) => s.id === p.surfaceId);
    if (!surface) {
      continue;
    }
    surface.optimizerPoints = surface.optimizerPoints.filter(
      (o) => !booleanPointInPolygon(o, turf.polygon([p.path]))
    );
  }
}

// Adds an optimizer to the path specified in the payload
export function addOptimizerToPath({ surfaces }: IData, payload: IOptimizerPayload[]): void {
  for (const p of payload) {
    const surface = surfaces.find((s) => s.id === p.surfaceId);
    if (!surface) {
      continue;
    }
    const centerPoint = center(turf.polygon([p.path])).geometry.coordinates;
    surface.optimizerPoints.push(positionToPoint(centerPoint));
  }
}

export function toggleOptimizersOnPath(
  {
    surfaces
  }: {
    surfaces: ISurface[];
  },
  payload: IOptimizerPayload[]
) {
  const toRemove: typeof payload = [];
  const toAdd: typeof payload = [];

  for (const p of payload) {
    const surface = surfaces.find((s) => s.id === p.surfaceId);
    if (!surface) {
      continue;
    }
    const pathHasOptimizer = surface.optimizerPoints.some((point) =>
      booleanPointInPolygon(point, turf.polygon([p.path]))
    );

    if (pathHasOptimizer) {
      toRemove.push(p);
    } else {
      toAdd.push(p);
    }
  }

  removeOptimizerFromPath({ surfaces }, toRemove);
  addOptimizerToPath({ surfaces }, toAdd);
}
