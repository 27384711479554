import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NumberField } from '@/components/number-field/number-field';
import { FormattedMessage } from 'react-intl';

export const EsdecFlangeDistanceInput = observer(function EsdecFlangeDistanceInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  return (
    <NumberField
      isDisabled={surfaceEsdec.isRoofFinalized}
      minValue={300}
      label={<FormattedMessage defaultMessage="Flange Distance" id="9I6IjJ" />}
      formatOptions={{
        style: 'unit',
        unit: 'millimeter'
      }}
      showStepper={true}
      value={surfaceEsdec.flangeDistance}
      onChange={(value) => {
        if (Number.isNaN(value)) {
          return;
        }
        surfaceEsdec.updateFlangeDistance(value);
      }}
    />
  );
});
