import { useIntl } from 'react-intl';
import { useIntlContext } from '@/lib/react-intl/react-intl';
import React from 'react';

export function useFormatCurrency(): (value: number) => string {
  const intl = useIntl();
  const { locale } = useIntlContext();

  return React.useCallback(
    (value: number) => {
      const currency: Record<string, string> = {
        en: 'SEK'
      };

      return intl.formatNumber(value, {
        style: 'currency',
        currency: currency[locale],
        maximumFractionDigits: 0
      });
    },
    [intl, locale]
  );
}
