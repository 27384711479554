import { api } from '@/utils/api';
import { InferMutationOptions } from '@/utils/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { EditorContext } from '@/features/editor/utils/editor-context';
import { EditorApiKeys } from '@/features/editor/api/keys';

export const calculationCreateParser = z.object({
  calculationID: z.number()
});

async function calculationCreateApi(request: EditorContext) {
  const response = await api.post<EditorContext & { calculationID: number }>('/editor/create', request);
  return response.data;
}

export function useCalculationCreate(options?: InferMutationOptions<typeof calculationCreateApi>) {
  const queryClient = useQueryClient();
  return useMutation(calculationCreateApi, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(EditorApiKeys.editorContext({ calculationId: data.calculationID }), variables);
      options?.onSuccess?.(data, variables, context);
    }
  });
}
