/** @jsxImportSource theme-ui */

import { EditableImage } from '@/components/editable-image';
import { Base64 } from '@/utils/base64';
import { R } from '@/lib/remeda';
import {
  IOverlayImageWithBounds,
  overlayImageName,
  splitImageInMapTiles
} from '@/features/google-map/utils/map-types/overlay-image-map';
import { IVector, Vec } from '@/utils/vec';
import { GoogleMapConversion } from '@/features/google-map/utils/google-map-conversion';
import { GoogleMapCoords } from '@/features/google-map/utils/google-map-coords';
import React from 'react';
import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import { FileUpload } from '@/features';
import { nn } from '@/utils/invariant';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import {
  getImagePositionFromPins,
  OverlayImageAddPins
} from '@/features/google-map/features/map-overlay-image/map-overlay-image-add-pins';
import { getRestrictedSizeBox } from '@/features/google-map/features/map-overlay-image/get-restricted-size-box';
import { ButtonSecondary } from '@/components/button/button';
import { FormattedMessage } from 'react-intl';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { FixedLoadingIndicator } from '@/components/loading-indicator';

export const MapOverlayImageAdjust = observer(function MapOverlayImageAdjust() {
  const store = useEditorStore();
  const { map } = useGoogleMapContext();

  const { overlayImage } = store;
  const imageInProgress = overlayImage.image?.src;

  const uploadFiles = FileUpload.useUploadFiles();
  const [imagePosition, setImagePosition] = React.useState<
    ReturnType<typeof getImagePositionFromPins> | undefined
  >();

  if (isQueryLoading(uploadFiles.isLoading, uploadFiles.data)) {
    return <FixedLoadingIndicator />;
  }

  if (overlayImage.status === 'idle') {
    return null;
  }

  if (overlayImage.status === 'add-pins') {
    return <OverlayImageAddPins onDone={(data) => setImagePosition(data)} parentMap={map} />;
  }

  const defaultTopLeft = imagePosition?.topLeft
    ? GoogleMapCoords.latLngToVector(GoogleMapConversion.pointToLatLng(imagePosition.topLeft), map)
    : undefined;

  function defaultPositioning() {
    if (!overlayImage.image) {
      return undefined;
    }

    const { width: mapWidth } = map.getDiv().getBoundingClientRect();
    const { width, height } = getRestrictedSizeBox({
      height: overlayImage.image.naturalHeight,
      width: overlayImage.image.naturalWidth,
      // Restrict the image to 80% of the map width,
      // to allow huge images to be displayed still
      maxSize: mapWidth * 0.8
    });

    const top = defaultTopLeft?.y ?? 0;
    const left = defaultTopLeft?.x ?? 0;
    const right = left + width;
    const bottom = top + height;

    return {
      top,
      left,
      width,
      height,
      right,
      bottom
    };
  }

  return (
    <React.Fragment>
      {imageInProgress && (
        <EditableImage
          sx={{ border: '2px solid red' }}
          defaultPositioning={defaultPositioning()}
          defaultRotationAngle={imagePosition?.rotationAngle}
          opacity={store.overlayImage.image?.opacity}
          src={Base64.encode(imageInProgress, 'png')}
        >
          {(rect) => (
            <ButtonSecondary
              isLoading={uploadFiles.isLoading}
              onClick={async () => {
                const [projection, bounds, zoom] = [map.getProjection(), map.getBounds(), map.getZoom()];
                if (R.isNil(projection) || R.isNil(bounds) || R.isNil(zoom)) {
                  return;
                }

                const images = await splitImageInMapTiles(rect, rect.src, projection, bounds, zoom);
                const imageBounds = Vec.rectBounds({
                  topLeft: { x: rect.x, y: rect.y },
                  rotationAngle: rect.angleDegrees,
                  rotationOrigin: Vec.rectCenter(
                    {
                      x: rect.x,
                      y: rect.y
                    },
                    rect.offsetWidth,
                    rect.offsetHeight
                  ),
                  height: rect.offsetHeight,
                  width: rect.offsetWidth
                });

                uploadFiles.mutate(
                  images.map((image) => ({
                    name: overlayImageName(image),
                    object: image.blob
                  })),
                  {
                    onSuccess: (data) => {
                      if (data) {
                        const imagesWithBounds: IOverlayImageWithBounds[] = images.map(
                          ({ topLeft, zoom }) => {
                            const name = overlayImageName({ topLeft, zoom });
                            const src = data[name];

                            nn(src);

                            return {
                              zoom,
                              topLeft,
                              src,
                              originalImageBounds: {
                                topLeft: GoogleMapConversion.latLngToPoint(
                                  projectScreenPointToLatLng(imageBounds.topLeft)
                                ),
                                bottomRight: GoogleMapConversion.latLngToPoint(
                                  projectScreenPointToLatLng(imageBounds.bottomRight)
                                ),
                                topRight: GoogleMapConversion.latLngToPoint(
                                  projectScreenPointToLatLng(imageBounds.topRight)
                                ),
                                bottomLeft: GoogleMapConversion.latLngToPoint(
                                  projectScreenPointToLatLng(imageBounds.bottomLeft)
                                )
                              }
                            };
                          }
                        );

                        store.overlayImages.overlayImagesAdd(imagesWithBounds);
                        store.overlayImage.finalize();
                      } else {
                        return;
                      }
                    }
                  }
                );

                function projectScreenPointToLatLng(point: IVector) {
                  return projection!.fromPointToLatLng(
                    GoogleMapCoords.vectorToWorldPoint(
                      new google.maps.Point(point.x, point.y),
                      projection!,
                      bounds!,
                      zoom!
                    )
                  )!;
                }
              }}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: `translate(-50%, -50%) rotate(${-rect.angleDegrees}deg)`
              }}
            >
              <FormattedMessage defaultMessage="Upload" id="p4N05H" />
            </ButtonSecondary>
          )}
        </EditableImage>
      )}
    </React.Fragment>
  );
});
