/** @jsxImportSource theme-ui */
import * as RadixCollapsible from '@radix-ui/react-collapsible';
import { Box } from 'theme-ui';
import { MdExpandMore } from 'react-icons/md';
import React from 'react';
import { createContext } from '@/utils/create-context';
import { darken } from '@theme-ui/color';
import { motion } from '@/utils/motion';
import { Typography } from '@/components/typography';

const COMMON = {
  PADDING_X: 5
};

const [useCollapsible, CollapsibleProvider] = createContext<{
  isOpen: boolean;
}>();

interface ICollapsibleProps extends Omit<RadixCollapsible.CollapsibleProps, 'open'> {
  open: boolean;
}

export function Collapsible({ open, ...props }: ICollapsibleProps): JSX.Element {
  const value = React.useMemo(() => ({ isOpen: open }), [open]);

  return (
    <RadixCollapsible.Root
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
      open={open}
      {...props}
    >
      <CollapsibleProvider value={value}>{props.children}</CollapsibleProvider>
    </RadixCollapsible.Root>
  );
}

interface ICollapsibleTriggerProps extends Omit<RadixCollapsible.CollapsibleTriggerProps, 'title'> {
  iconRight?: React.ReactNode;
  title: React.ReactNode;
  isSurface?: boolean;
}

function CollapsibleTrigger({
  children,
  iconRight,
  isSurface,
  title,
  ...props
}: ICollapsibleTriggerProps): JSX.Element {
  const { isOpen } = useCollapsible();

  return (
    <RadixCollapsible.Trigger
      sx={{
        px: COMMON.PADDING_X,
        py: 5,
        backgroundColor: 'collapsibleBackground',
        border: 'none',
        textAlign: 'left',
        boxShadow: '2dp',
        transition: `background-color 0.1s ${motion('standard', 'productive')}`,

        '&:hover': {
          backgroundColor: darken('collapsibleBackground', 0.04)
        }
      }}
      {...props}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box>
          <Typography variant="headline2" sx={{ fontFamily: 'body', color: 'text' }}>
            {title}
          </Typography>
          {children ? <Box sx={{ mt: 2 }}>{children}</Box> : null}
        </Box>
        <Box
          sx={{
            ml: 'auto',
            color: 'primary'
          }}
        >
          {!isSurface ? (
            <MdExpandMore
              sx={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: `transform 0.1s ${motion('standard', 'productive')}`,
                ...(iconRight === undefined && {
                  mr: -1
                })
              }}
              size={24}
            />
          ) : null}
        </Box>
        {iconRight}
      </Box>
    </RadixCollapsible.Trigger>
  );
}

function CollapsibleContent(props: RadixCollapsible.CollapsibleContentProps): JSX.Element {
  return (
    <RadixCollapsible.Content
      sx={{
        overflow: 'hidden'
      }}
    >
      <Box
        {...props}
        sx={{
          px: COMMON.PADDING_X,
          py: 5
        }}
      >
        {props.children}
      </Box>
    </RadixCollapsible.Content>
  );
}

Collapsible.Trigger = CollapsibleTrigger;
Collapsible.Content = CollapsibleContent;
