import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { ESDEC_PREFERRED_FASTENER } from '../esdec-types';
import { FormattedMessage, useIntl } from 'react-intl';

export const EsdecPreferredFastenerInput = observer(function EsdecPreferredFastenerInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const intl = useIntl();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  const preferredFastenerOptions = ESDEC_PREFERRED_FASTENER.map((preferredFastener) => ({
    label: preferredFastener,
    value: preferredFastener
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Preferred Fastener',
        id: 'EvEtGW'
      })}
      placeholder={<FormattedMessage defaultMessage="Fastener" id="ynDm7e" />}
      name="preferredFastener"
      options={preferredFastenerOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updatePreferredFastener(value.value);
        }
      }}
      value={preferredFastenerOptions.find(
        (preferredFastenerOption) => preferredFastenerOption.value === surfaceEsdec.preferredFastener
      )}
    />
  );
});
