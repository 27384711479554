import { useQuery } from '@tanstack/react-query';
import { EsdecApiKeys } from '@/features/esdec/api/keys';
import { InferQueryOptions, UnwrapPromise } from '@/utils/types';
import { EsdecPartialRoofWithSurfaceId } from '@/features/esdec/esdec-types';

async function esdecGetSurfacesApi() {
  const data = localStorage.getItem('esdecGetSurfaces');
  if (data) {
    return JSON.parse(data) as Record<string, EsdecPartialRoofWithSurfaceId>;
  }
  return {};
}

export type IEsdecGetSurfacesResponse = UnwrapPromise<ReturnType<typeof esdecGetSurfacesApi>>;

export function useEsdecGetSurfaces(
  { calculationId }: { calculationId: number },
  options?: InferQueryOptions<typeof esdecGetSurfacesApi>
) {
  return useQuery(EsdecApiKeys.getSurfaces, esdecGetSurfacesApi, options);
}
