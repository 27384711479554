/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { api } from '@/utils/api';
import { R } from '@/lib/remeda';
import { InferMutationOptions } from '@/utils/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EditorApiKeys } from '@/features/editor/api/keys';
import produce from 'immer';
import { ICalculationAccessoriesApiResponse } from '@/features/editor/api/calculation-accessories';
import { z } from 'zod';

interface IRequestAddExisting {
  type: 'existing';
  productId: string;
  amount: number;
  name: string;
}

interface IRequestAddCustom {
  type: 'custom';
  amount: number;
  marginPercent: number;
  priceCents: number;
  name: string;
}

type IRequestAdd = IRequestAddExisting | IRequestAddCustom;

interface IRequestUpdateExisting {
  type: 'existing';
  id: string;
  product: true;
  amount: number;
  marginPercent: number;
}

interface IRequestUpdateCustom {
  type: 'custom';
  id: string;
  name: string;
  marginPercent: number;
  priceCents: number;
  amount: number;
}

type IRequestUpdate = IRequestUpdateExisting | IRequestUpdateCustom;

interface IRequestRemoveExisting {
  id: number;
  type: 'existing';
}

interface IRequestRemoveCustom {
  id: number;
  type: 'custom';
}

type IRequestRemove = IRequestRemoveExisting | IRequestRemoveCustom;
interface IRequestBase {
  calculationId: number;
}

async function additionRemove(request: IRequestRemove & IRequestBase) {
  if (request.type === 'custom') {
    return api.post('/attachAddition', {
      calculationID: request.calculationId,
      data: {
        id: request.id,
        delete: true
      }
    });
  } else if (request.type === 'existing') {
    return api.post('/attachAddition', {
      calculationID: request.calculationId,
      data: {
        id: request.id,
        product: true,
        delete: true
      }
    });
  }

  throw new Error('Invalid request type');
}

async function additionUpdate(request: IRequestUpdate & IRequestBase) {
  if (request.type === 'custom') {
    return api.post('/attachAddition', {
      calculationID: request.calculationId,
      data: {
        id: request.id,
        update: true,
        name: request.name,
        margin: request.marginPercent,
        price: request.priceCents,
        amount: request.amount
      }
    });
  } else if (request.type === 'existing') {
    return api.post('/attachAddition', {
      calculationID: request.calculationId,
      data: {
        id: request.id,
        product: true,
        update: true,
        amount: request.amount,
        margin: request.marginPercent
      }
    });
  }

  throw new Error('Invalid request type');
}

async function additionAdd(request: IRequestAdd & IRequestBase) {
  const responseParser = z.array(
    z.object({
      price: z.number(),
      margin: z.number(),
      name: z.string(),
      amount: z.number(),
      product_id: z.number().nullable(),
      type: z.union([z.literal('product'), z.literal('text')]),
      id: z.number()
    })
  );

  if (request.type === 'existing') {
    const response = await api.post('/attachAddition', {
      calculationID: request.calculationId,
      data: {
        new: true,
        product_id: request.productId,
        amount: request.amount,
        name: request.name
      }
    });
    return responseParser.parse(response.data);
  } else if (request.type === 'custom') {
    const response = await api.post('/attachAddition', {
      calculationID: request.calculationId,
      data: {
        new: true,
        amount: request.amount,
        margin: request.marginPercent,
        price: request.priceCents,
        name: request.name
      }
    });
    return responseParser.parse(response.data);
  }

  throw new Error('Invalid request type');
}

export function useAdditionRemove(options?: InferMutationOptions<typeof additionRemove>) {
  const utils = useQueryClient();
  return useMutation(additionRemove, {
    ...options,
    onMutate: (variables) => {
      utils.setQueryData<ICalculationAccessoriesApiResponse>(
        EditorApiKeys.calculationAccessories({ calculationId: variables.calculationId }),
        (data) =>
          produce(data, (draft) => {
            if (!draft) {
              return;
            }
            draft.additions = draft.additions.filter((addition) => addition.id !== variables.id);
          })
      );
    },
    onSuccess: async (data, variables, context) => {
      await Promise.all([
        utils.invalidateQueries(
          EditorApiKeys.calculationAccessories({ calculationId: variables.calculationId })
        ),
        utils.invalidateQueries(EditorApiKeys.editorResults({ calculationId: variables.calculationId }))
      ]);
      return options?.onSuccess?.(data, variables, context);
    }
  });
}

export function useAdditionUpdate(options?: InferMutationOptions<typeof additionUpdate>) {
  const utils = useQueryClient();
  return useMutation(additionUpdate, {
    ...options,
    onMutate: (variables) => {
      utils.setQueryData<ICalculationAccessoriesApiResponse>(
        EditorApiKeys.calculationAccessories({ calculationId: variables.calculationId }),
        (data) =>
          produce(data, (draft) => {
            if (!draft) {
              return;
            }
            if (variables.type === 'existing') {
              draft.additions = draft.additions.map((addition) => {
                if (String(addition.id) !== variables.id) {
                  return addition;
                }
                return {
                  ...addition,
                  amount: variables.amount,
                  margin: variables.marginPercent
                };
              });
            } else if (variables.type === 'custom') {
              draft.additions = draft.additions.map((addition) => {
                if (String(addition.id) !== variables.id) {
                  return addition;
                }
                return {
                  ...addition,
                  amount: variables.amount,
                  margin: variables.marginPercent,
                  price: variables.priceCents,
                  name: variables.name
                };
              });
            }
          })
      );
    },
    onSuccess: async (data, variables, context) => {
      await Promise.all([
        utils.invalidateQueries(
          EditorApiKeys.calculationAccessories({ calculationId: variables.calculationId })
        ),
        utils.invalidateQueries(EditorApiKeys.editorResults({ calculationId: variables.calculationId }))
      ]);
      return options?.onSuccess?.(data, variables, context);
    }
  });
}

export function useAdditionAdd(options?: InferMutationOptions<typeof additionAdd>) {
  const utils = useQueryClient();
  return useMutation(additionAdd, {
    ...options,
    onSuccess: async (data, variables, context) => {
      utils.setQueryData<ICalculationAccessoriesApiResponse>(
        EditorApiKeys.calculationAccessories({ calculationId: variables.calculationId }),
        (old) =>
          produce(old, (draft) => {
            if (R.isNil(draft)) {
              return;
            }
            draft.additions = data;
          })
      );
      options?.onSuccess?.(data, variables, context);

      Promise.all([
        utils.invalidateQueries(EditorApiKeys.editorResults({ calculationId: variables.calculationId })),
        utils.invalidateQueries(
          EditorApiKeys.calculationAccessories({ calculationId: variables.calculationId })
        )
      ]).catch(console.error);
    }
  });
}
