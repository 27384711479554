import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import React from 'react';
import { IPoint } from '@/utils/gis/types';
import { pointToLatLng } from '@/utils/gis/point-to-lat-lng';
import { useDeepCompareEffect } from 'react-use';

interface IMapPolylineOptions
  extends Pick<google.maps.PolylineOptions, 'strokeColor' | 'zIndex' | 'strokeWeight' | 'clickable'> {
  path: IPoint[];

  children?: React.ReactNode;
}

export function MapPolyline({ path, strokeWeight = 2, children, ...options }: IMapPolylineOptions) {
  const { map } = useGoogleMapContext();
  const [polyline, setPolyline] = React.useState<google.maps.Polyline>();

  React.useEffect(() => {
    if (!polyline) {
      const polyline = new google.maps.Polyline();
      setPolyline(polyline);
      polyline.setMap(map);
    }

    return () => {
      if (polyline) {
        polyline.setMap(null);
      }
    };
  }, [map, polyline]);

  useDeepCompareEffect(() => {
    if (polyline) {
      polyline.setOptions({
        ...options,
        strokeWeight,
        path: path.map(pointToLatLng)
      });
    }
  }, [polyline, path, strokeWeight, options]);

  return <React.Fragment>{children}</React.Fragment>;
}
