import { R } from '@/lib/remeda';
import { useSearchParams } from 'react-router-dom';

export function useEditorCalculationId(): { calculationId: number } {
  const { calculationId } = useOptionalEditorCalculationId();

  if (R.isNil(calculationId)) {
    throw new Error('calculationId is required');
  }

  return { calculationId };
}

export function useOptionalEditorCalculationId(): { calculationId?: number } {
  const [params] = useSearchParams();
  const calculationId = params.get('calculationId') ? parseInt(params.get('calculationId')!) : undefined;

  return { calculationId };
}
