/** @jsxImportSource theme-ui */
import { Icons } from '@/assets';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { FormattedMessage } from 'react-intl';
import { Box } from 'theme-ui';
import { EsdecSurfaceCardSection } from './esdec-surface-card-section';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useModal } from '@/features/modal/stores/use-modal-store';
import { EsdecWindzoneInformationModal } from './esdec-windzone-information-modal';
import { ButtonIcon } from '@/components/button/button';
import EsdecSnowZoneModal from './esdec-snow-zone-modal';
import { EsdecWindzoneTypeInput } from './esdec-windzone-type-input';
import { EsdecSnowzoneTypeInput } from './esdec-snowzone-type-input';
import { EsdecTopographyTypeInput } from './esdec-topography-type-input';
import { EsdecTopographyModal } from './esdec-topography-modal';
import { EsdecTerrainTypeInput } from './esdec-terrain-type-input';
import { EsdecTerrainModal } from './esdec-terrain-modal';

interface IEsdecWeatherZonesPlaceholder {
  icon: React.ReactNode;
  text: React.ReactNode;
}

export function EsdecWeatherZonesPlaceholder({ icon, text }: IEsdecWeatherZonesPlaceholder): JSX.Element {
  return (
    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', color: 'menuText', fontSize: 'md' }}>
      {icon}
      {text}
    </Box>
  );
}

export const EsdecWeatherZonesCard = observer(function EsdecWeatherZonesCard({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const modal = useModal();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  return (
    <EsdecSurfaceCardSection
      title={<FormattedMessage defaultMessage="Weather zones" id="saHLte" />}
      icon={<Icons.Globe />}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 7, height: '100%', justifyContent: 'center' }}
      >
        <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <EsdecWindzoneTypeInput surfaceId={surfaceId} />
          </Box>
          <ButtonIcon
            sx={{ color: 'primary' }}
            onClick={() => {
              modal.openModal({
                children: <EsdecWindzoneInformationModal onClose={modal.closeModal} />,
                options: { maxWidth: 1200 }
              });
            }}
          >
            <Icons.QuestionMarkCircled />
          </ButtonIcon>
        </Box>
        <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <EsdecSnowzoneTypeInput surfaceId={surfaceId} />
          </Box>
          <ButtonIcon
            sx={{ color: 'primary' }}
            onClick={() => {
              modal.openModal({
                children: <EsdecSnowZoneModal onClose={modal.closeModal} />,
                options: { maxWidth: 1200 }
              });
            }}
          >
            <Icons.QuestionMarkCircled />
          </ButtonIcon>
        </Box>
        <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <EsdecTopographyTypeInput surfaceId={surfaceId} />
          </Box>
          <ButtonIcon
            sx={{ color: 'primary' }}
            onClick={() => {
              modal.openModal({
                children: <EsdecTopographyModal onClose={modal.closeModal} />,
                options: { maxWidth: 1200 }
              });
            }}
          >
            <Icons.QuestionMarkCircled />
          </ButtonIcon>
        </Box>
        <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <EsdecTerrainTypeInput surfaceId={surfaceId} />
          </Box>
          <ButtonIcon
            sx={{ color: 'primary' }}
            onClick={() => {
              modal.openModal({
                children: <EsdecTerrainModal onClose={modal.closeModal} />,
                options: { maxWidth: 1200 }
              });
            }}
          >
            <Icons.QuestionMarkCircled />
          </ButtonIcon>
        </Box>
      </Box>
    </EsdecSurfaceCardSection>
  );
});
