import * as Rollbar from '@rollbar/react';
import { EmptyPropsWithChildren } from '@/utils/types';
import { ComponentProps } from 'react';
import { environment } from '@/utils/env';

type RollbarConfig = ComponentProps<typeof Rollbar.Provider>['config'];
const config: RollbarConfig = {
  accessToken: environment.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: {
    // Logs all network requests
    network: true,
    // Logs the headers of all network requests
    networkResponseHeaders: true,

    // Think about user data collection before enabling options here.
    // Some of these will log user input which may be sensitive.
    networkRequestBody: false,
    networkResponseBody: false,
    dom: false,
    log: false,
    connectivity: false,
    navigation: false,
    contentSecurityPolicy: false,
    errorOnContentSecurityPolicy: false
  },
  environment: environment.REACT_APP_ENVIRONMENT ?? process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production',
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    }
  }
};

function RollbarProvider({ children }: EmptyPropsWithChildren) {
  return <Rollbar.Provider config={config}>{children}</Rollbar.Provider>;
}

export { RollbarProvider };
