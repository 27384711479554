import {
  IEditorResultsMaterialWarrantyProps,
  ResultsMaterialWarrantyMessageBody,
  ResultsMaterialWarrantyMessageTitle
} from '@/features/editor-results/components/editor-results-material-warranty/editor-results-material-warranty';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';

export function EditorResultsMaterialWarrantyPrintable(
  props: IEditorResultsMaterialWarrantyProps
): JSX.Element {
  return (
    <View style={{ maxWidth: 412 }}>
      <Text style={{ color: '#303540', fontSize: 10, fontWeight: 500, marginBottom: 8 }}>
        <ResultsMaterialWarrantyMessageTitle isVatIncluded={props.isVatIncluded} />
      </Text>

      <Text style={{ color: '#667085', fontSize: 8 }}>
        <ResultsMaterialWarrantyMessageBody {...props} />
      </Text>
    </View>
  );
}
