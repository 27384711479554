import * as R from 'remeda';
import { RenderedShape } from '@/features/google-map/utils/shapes/shape-manager';
import { IMarkerOptions } from '@/features/google-map/utils/shapes/marker';
import { IRectangleOptions } from '@/features/google-map/utils/shapes/rectangle';
import { IPolygonOptions } from '@/features/google-map/utils/shapes/polygon';
import { IPolylineOptions } from '@/features/google-map/utils/shapes/polyline';

type BuildEventListenerFn = (rendered: RenderedShape) => google.maps.MapsEventListener[];
type ShapeEffectRemoveFn = () => void;
type Effect = (rendered: RenderedShape) => ShapeEffectRemoveFn | undefined;

interface IShape {
  id: string;
  options?: ShapeOptions;
  build: () => RenderedShape;
  eventListenersToAttach: BuildEventListenerFn[];
  removeEventListeners: () => void;
  update: (shape: Shape) => Shape;
}

export class Shape implements IShape {
  protected readonly _id: string;
  protected _options?: ShapeOptions;

  private _eventListenersToAttach: BuildEventListenerFn[];
  private _eventListenersToRemove: google.maps.MapsEventListener[];

  private _effectRemoveFns: ShapeEffectRemoveFn[] = [];
  private _effects: Effect[] = [];

  public constructor(id: string, options?: ShapeOptions) {
    this._id = id;
    this._options = options;
    this._eventListenersToAttach = [];
    this._eventListenersToRemove = [];
  }

  public get id(): string {
    return this._id;
  }

  public get options(): ShapeOptions | undefined {
    return this._options;
  }

  public get eventListenersToRemove(): google.maps.MapsEventListener[] {
    return this._eventListenersToRemove;
  }

  public get eventListenersToAttach(): BuildEventListenerFn[] {
    return this._eventListenersToAttach;
  }

  protected set eventListenersToAttach(value: BuildEventListenerFn[]) {
    this._eventListenersToAttach = value;
  }

  public attachEventListeners(rendered: RenderedShape): void {
    this._eventListenersToAttach.forEach((fn) => {
      this._eventListenersToRemove.push(...fn(rendered));
    });
  }

  public removeEventListeners(): void {
    this._eventListenersToRemove.forEach((listener) => listener.remove());
  }

  public build(): RenderedShape {
    throw new Error('subclass responsibility');
  }

  public addEventListener(fn: BuildEventListenerFn): Shape {
    this._eventListenersToAttach.push(fn);
    return this;
  }

  public update(shape: Shape): Shape {
    throw new Error('subclass responsibility');
  }

  public setOptions(options: ShapeOptions): Shape {
    this._options = R.merge(this._options, options);
    return this;
  }

  public addEffect(fn: Effect): Shape {
    this._effects.push(fn);
    return this;
  }

  public runEffects(rendered: RenderedShape) {
    this._effects.forEach((fn) => {
      const removeFn = fn(rendered);
      if (removeFn) {
        this._effectRemoveFns.push(removeFn);
      }
    });
  }

  public removeEffects() {
    this._effectRemoveFns.forEach((fn) => fn());
  }
}

export type ShapeOptions = Omit<
  IMarkerOptions | IRectangleOptions | IPolygonOptions | IPolylineOptions,
  'map'
>;
