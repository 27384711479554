// import { useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { useSurfaces } from '@/features/editor/utils/surface/use-surfaces';
// import { esdecRoofFromSurface } from '@/features/esdec/utils/esdec-roof-from-surface';

export function useCalcEsdec() {
  // const { surfacePanels } = useSurfacePanels();
  const surfaces = useSurfaces();

  const firstSurface = surfaces[0];

  if (!firstSurface) {
    return null;
  }

  // const thisSurfacePanels = surfacePanels[firstSurface.id] ?? [];
  //
  // const field = esdecRoofFromSurface({
  //   surface: firstSurface,
  //   panels: thisSurfacePanels
  // });
}

export function DemoPage() {
  return <div />;
}
