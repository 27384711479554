import React from 'react';
import { themeColors } from '@/lib/theme-ui/colors';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import { surfaceWithSetback } from '@/features/editor/utils/surface/surface-with-setback';
import { MapEditablePolygon } from '@/features/google-map/components/shapes/map-editable-polygon';
import { MapPolygon } from '@/features/google-map/components/shapes/map-polygon';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { toJS } from 'mobx';
import { ISurface } from '@/features/editor/utils/surface/surface';
import { useGoogleMapContextMenu } from './use-google-map-context-menu';
import { getSideLength } from '@/features/editor/utils/get-side-lengths';
import { surfaceRidgeSide } from '@/features/google-map/utils/surface-ridge-side';
import { selectPolylineSides } from '@/features/google-map/utils/select-polyline-sides';
import { extendLine } from '@/utils/turf/extend-line';

function getSurfaceColor(isHovered: boolean, isSelected: boolean): string {
  if (isHovered && !isSelected) {
    return themeColors.surfaceHovered;
  }
  return themeColors.surfaceDefault;
}

const RenderSurface = observer(function RenderSurface({
  surface,

  isSurfaceHovered,
  isSurfaceSelected,
  isSurfaceEditable,
  isSurfaceClickable
}: {
  surface: ISurface;
  isSurfaceClickable: boolean;
  isSurfaceEditable: boolean;
  isSurfaceHovered: boolean;
  isSurfaceSelected: boolean;
}) {
  const store = useEditorStore();
  const { openPastePanelsMenu, openPasteObstaclesMenu } = useGoogleMapContextMenu();

  const polygonColor = getSurfaceColor(isSurfaceHovered, isSurfaceSelected);
  const withSetback = surfaceWithSetback(surface.path, surface.setbackMeters);

  const ridgeline = surfaceRidgeSide({
    ridge: surface.ridge,
    sides: selectPolylineSides(surface.path),
    id: surface.id
  });

  return (
    <MapEditablePolygon
      onPointUpdate={store.surfaces.surfacePointUpdate}
      onPointDelete={({ indexAt, polygonId }) => {
        store.surfaces.surfacePointUpdate({
          mode: 'remove',
          indexAt,
          polygonId
        });
      }}
      isEditable={isSurfaceEditable}
      zIndex={GOOGLE_MAP_Z_INDICES.SURFACE}
      fillColor={polygonColor}
      strokeColor={polygonColor}
      clickable={isSurfaceClickable}
      path={toJS(surface.path)}
      id={surface.id}
      key={surface.id}
      onMouseOver={() => {
        store.surfaces.surfaceHover({
          surfaceId: surface.id,
          mode: 'mouseenter'
        });
      }}
      onMouseOut={() => {
        store.surfaces.surfaceHover({
          surfaceId: surface.id,
          mode: 'mouseleave'
        });
      }}
      onClick={() => {
        store.surfaces.surfaceSelect(surface.id);
      }}
      onRightClick={(event) => {
        openPastePanelsMenu(event, surface.id);
        openPasteObstaclesMenu(event);
      }}
      calculateSideLength={
        ridgeline && surface.tiltAngle
          ? (side) => {
              return getSideLength(side, surface.tiltAngle, extendLine(ridgeline));
            }
          : undefined
      }
    >
      {withSetback.hasSetback ? (
        <MapPolygon
          fillColor={themeColors.surfaceDefault}
          strokeColor="orange"
          zIndex={0}
          clickable={false}
          path={withSetback.path}
        />
      ) : undefined}
    </MapEditablePolygon>
  );
});

export const RenderSurfaces = observer(function RenderSurfaces() {
  const store = useEditorStore();
  const [isAltKeyPressed, setIsAltKeyPressed] = React.useState(false);
  const { surfaces, hoveredSurfaceIds } = store.surfaces;

  let selectedSurfaceId = store.surfaces.selectedSurfaceId;

  if (!selectedSurfaceId) {
    selectedSurfaceId = surfaces[0]?.id;
  }

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Alt') {
        setIsAltKeyPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Alt') {
        setIsAltKeyPressed(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [setIsAltKeyPressed]);

  const isSurfaceClickable = (function () {
    if (store.state.isDragging) {
      return true;
    }
    return store.state.isSelecting && !isAltKeyPressed && !store.state.isSelectionInProgress;
  })();

  const isSurfaceEditable = store.state.isDragging;

  return (
    <React.Fragment>
      {surfaces.map((surface) => (
        <RenderSurface
          key={surface.id}
          surface={surface}
          isSurfaceClickable={isSurfaceClickable}
          isSurfaceEditable={isSurfaceEditable}
          isSurfaceHovered={hoveredSurfaceIds.includes(surface.id)}
          isSurfaceSelected={selectedSurfaceId === surface.id}
        />
      ))}
    </React.Fragment>
  );
});
