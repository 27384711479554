import { Box } from '@/components/box';
import React from 'react';

export function Spacer({ height, width }: { height?: number; width?: number }): JSX.Element {
  return (
    <Box
      sx={{
        minHeight: height ?? 0,
        minWidth: width ?? 0
      }}
    />
  );
}
