/** @jsxImportSource theme-ui */
import { Box, Spinner } from 'theme-ui';
import React from 'react';

interface ILoadingIndicatorProps {
  className?: string;
}
function LoadingIndicator(props: ILoadingIndicatorProps): JSX.Element {
  return <Spinner size={24} {...props} />;
}

// Renders a loading indicator centered relative to the closest relatively positioned parent
export function RelativeLoadingIndicator({ color }: { color?: string }): JSX.Element {
  return (
    <LoadingIndicator
      sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color }}
    />
  );
}

export function FixedLoadingIndicator({ color }: { color?: string }): JSX.Element {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LoadingIndicator sx={{ color }} />
    </Box>
  );
}
