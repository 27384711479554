import { useEditorCalculationId } from '@/features/editor/utils/use-editor-calculation-id';
import { EditorApi, EditorMachineActions } from '@/features/editor';
import React from 'react';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

export function useInverters() {
  const store = useEditorStore();
  const { calculationId } = useEditorCalculationId();

  const allProducts = EditorApi.useGetAllProducts(calculationId);
  const inverters = store.inverters.inverters;
  const suitableOptimizers = EditorMachineActions.getSuitableOptimizersForInverters(
    {
      inverters,
      panelTypes: store.metadata.panelTypes,
      surfaces: store.surfaces.surfaces
    },
    {
      inverterProducts: allProducts.data?.inverterTypes ?? [],
      optimizerProducts: allProducts.data?.optimizerTypes ?? []
    }
  );

  return React.useMemo(
    () =>
      inverters.map((inverter) => {
        return {
          ...inverter,
          suitableOptimizers: suitableOptimizers[inverter.id] ?? []
        };
      }),
    [inverters, suitableOptimizers]
  );
}
