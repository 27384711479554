/** @jsxImportSource theme-ui */
import React from 'react';
import { Flex } from 'theme-ui';
import { ButtonText } from '@/components/button/button';
import { FormattedMessage } from 'react-intl';
import { MdAdd } from 'react-icons/md';

export function RightMenuListSectionTitle({
  title,
  onItemAdd
}: {
  title: React.ReactNode;
  onItemAdd: () => void;
}): JSX.Element {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      {title}

      <ButtonText
        onClick={onItemAdd}
        sx={{
          textTransform: 'uppercase',
          fontSize: 'inherit',
          mr: -2,
          color: 'menuText'
        }}
      >
        <FormattedMessage
          defaultMessage="Add"
          id="V53ewJ"
          description="Button for adding an item to a list"
        />{' '}
        <MdAdd />
      </ButtonText>
    </Flex>
  );
}
