import { SelectField } from '@/components/select-field';
import { SurfaceEsdec } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { RoofDTOPreferredProductEnum } from '@solel/esdec';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEsdecArticles } from '../api/esdec-get-articles';

export const EsdecHangerBoltTypeInput = observer(function EsdecHangerBoltTypeInput({
  preferredProduct,
  surfaceEsdec
}: {
  preferredProduct: RoofDTOPreferredProductEnum;
  surfaceEsdec: SurfaceEsdec;
}) {
  const articles = useEsdecArticles(preferredProduct, 'HANGER_BOLT');
  const intl = useIntl();

  if (isQueryLoading(articles.isLoading, articles.data)) {
    return <React.Fragment />;
  }

  const hangerBoltOptions = articles.data.map((hangerBoltType) => ({
    label: hangerBoltType.description,
    value: hangerBoltType.description
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Hanger Bolt',
        id: 'v4UhRZ'
      })}
      placeholder={<FormattedMessage defaultMessage="Select Hanger Bolt" id="ceq1Ki" />}
      name="hangerBolt"
      options={hangerBoltOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateChosenHangerBolt(value.value as string);
        }
      }}
      value={hangerBoltOptions.find(
        (hangerBoltOption) => hangerBoltOption.value === surfaceEsdec.chosenHangerBolt
      )}
    />
  );
});
