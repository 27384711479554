import { IPoint } from '@/utils/gis/types';
import { Gis } from '@/features/editor/utils/gis';
import { makeAutoObservable } from 'mobx';

export class SelectionStore {
  public path: IPoint[] | undefined;
  public isVisible: boolean = false;
  public selectedIds: Record<string, boolean> = {};

  public constructor() {
    makeAutoObservable(this);
  }

  public setRectangle = (bounds: { topLeft: IPoint; bottomRight: IPoint } | undefined) => {
    this.path = bounds ? Gis.bbox(bounds) : undefined;
  };

  public setVisible = (isVisible: boolean) => {
    this.isVisible = isVisible;
  };

  public select = (id: string | string[]) => {
    const ids = Array.isArray(id) ? id : [id];
    ids.forEach((id) => (this.selectedIds[id] = true));
  };

  public deselect = (id: string | string[]) => {
    const ids = Array.isArray(id) ? id : [id];
    ids.forEach((id) => delete this.selectedIds[id]);
  };

  public toggleSelect = (id: string | string[]) => {
    const ids = Array.isArray(id) ? id : [id];
    ids.forEach((id) => {
      const isSelected = Boolean(this.selectedIds[id]);
      this.selectedIds[id] = !isSelected;
    });
  };

  public deselectAll = () => {
    this.path = undefined;
    this.selectedIds = {};
  };

  public isSelected({
    id,
    path,
    rectPath = this.path
  }: {
    id?: string;
    path?: IPoint[];
    rectPath?: IPoint[];
  }) {
    if (id && path && rectPath) {
      return this.selectedIds[id] || Gis.booleanIntersects(path, rectPath);
    }

    if (id) {
      return Boolean(this.selectedIds[id]);
    }

    if (path && rectPath) {
      return Gis.booleanIntersects(path, rectPath);
    }

    return false;
  }
}
