import { z } from 'zod';

const schema = z.object({
  REACT_APP_SOLEL_API_URL: z.string().min(1),

  REACT_APP_GOOGLE_MAP_API_KEY: z.string().min(1),

  REACT_APP_SOLEL_HOME_PAGE_URL: z.string().min(1),

  REACT_APP_ROLLBAR_ACCESS_TOKEN: z.string().min(1),

  // The app environment, like staging, production, etc.
  REACT_APP_ENVIRONMENT: z.string().optional()
});

export const environment = schema.parse(process.env);
