/** @jsxImportSource theme-ui */
import { SelectField } from '@/components/select-field/select-field';
import React from 'react';
import { Flex } from 'theme-ui';
import { Typography } from '@/components/typography';
import { Box } from '@/components/box';
import { EditorApi } from '@/features/editor';
import { isQueryLoading } from '@/lib/react-query/is-query-loading';
import { useEditorContext } from '@/features/editor/stores/use-editor-context';
import { useFormatCurrency } from '@/features/intl/utils/use-format-currency';
import { FormattedMessage } from 'react-intl';
import { InverterDetails } from '@/features/editor/components/inverters/inverter-details';
import { MdBatteryChargingFull } from 'react-icons/md';
import { Toggle } from '@/components/toggle/toggle';
import { R } from '@/lib/remeda';
import { useInverterOptimizers } from '@/features/editor/utils/inverters/use-inverter-optimizers';
import { SolarUtils } from '@/utils/solar-utils';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface IInverterTypeSelectProps {
  id: string;
}

export const InverterTypeSelect = observer(function InverterTypeSelect({
  id
}: IInverterTypeSelectProps): JSX.Element {
  const [isBatteryPreparedFilterOn, setIsBatteryPreparedFilterOn] = React.useState(false);
  const formatCurrency = useFormatCurrency();
  const store = useEditorStore();

  const { inverterOptimizers } = useInverterOptimizers();
  const inverter = store.inverters.inverterGetOne(id);

  const { calculationId } = useEditorContext();
  const allProducts = EditorApi.useGetAllProducts(calculationId);

  if (!inverter || isQueryLoading(allProducts.isLoading, allProducts.data)) {
    return <React.Fragment />;
  }

  const { inverterTypes } = allProducts.data;
  const inverterType = inverterTypes.find(
    (inverterType) => inverterType.productId === inverter.inverterTypeId
  );

  const options = inverterTypes
    .map((inverter) => ({
      value: inverter.productId,
      label: `${inverter.name} | ${formatCurrency(inverter.price)}`,
      isBatteryPrepared: inverter.isBatteryPrepared
    }))
    .filter((inverter) => {
      if (!isBatteryPreparedFilterOn) {
        return true;
      }
      return inverter.isBatteryPrepared;
    });
  const value = options.find((option) => option.value === inverterType?.productId);

  return (
    <React.Fragment>
      <Toggle
        pressed={isBatteryPreparedFilterOn}
        onPressedChange={(pressed) => {
          setIsBatteryPreparedFilterOn(pressed);
        }}
      >
        <FormattedMessage
          defaultMessage="Show battery prepared"
          id="Bk21W0"
          description="A toggle for whether to show only the battery prepared inverters"
        />
      </Toggle>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <SelectField
          formatOptionLabel={(data) => (
            <Flex
              sx={{
                alignItems: 'flex-start'
              }}
            >
              {data.label}{' '}
              <Box sx={{ color: 'inherit', marginLeft: 'auto', minWidth: 17 }}>
                {data.isBatteryPrepared ? <MdBatteryChargingFull /> : null}
              </Box>
            </Flex>
          )}
          onChange={(newValue) => {
            if (R.isNil(newValue)) {
              return;
            }
            const inverter = allProducts.data?.inverterTypes.find((i) => i.productId === newValue.value);
            if (R.isNil(inverter)) {
              return;
            }

            if (inverter.isOptimizerRequired) {
              const optimizer = R.first(inverterOptimizers[inverter.productId] ?? []);
              store.inverters.inverterTypeChange({
                inverterId: id,
                inverterTypeId: newValue.value,
                optimizerTypeId: optimizer?.productId
              });
            } else {
              store.inverters.inverterTypeChange({
                inverterId: id,
                inverterTypeId: newValue.value,
                optimizerTypeId: undefined
              });
            }
          }}
          label="Inverter type"
          value={value}
          options={options}
          name="inverterType"
          id="inverterType"
        />

        {inverterType && (
          <React.Fragment>
            <Flex
              sx={{
                flexDirection: 'column',
                gap: 2,
                backgroundColor: '#EEEFF4',
                p: 4,
                border: '1px solid #0000001F',
                borderRadius: 4
              }}
            >
              <Typography sx={{ fontWeight: 500, mb: 1 }}>{inverterType.name}</Typography>
              <InverterDetails label="Price:" value={formatCurrency(inverterType.price)} />
              <InverterDetails
                label="Peak Power:"
                value={`${SolarUtils.wattToKilowatt(inverterType.production)} kWp`}
              />
              <InverterDetails
                label="Warranty:"
                value={
                  <span>
                    {inverterType.warrantyTime}{' '}
                    <FormattedMessage
                      defaultMessage="years"
                      id="Za9f1V"
                      description='Label for a number of years: "10 years"'
                    />
                  </span>
                }
              />
            </Flex>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
});
