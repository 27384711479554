import { IntlShape } from 'react-intl';
import React from 'react';
import { registerPdfFonts } from '@/lib/react-pdf/register-pdf-fonts';
import { EditorProvider } from '@/features/editor/providers/editor-provider';
import { ReactIntlProvider } from '@/lib/react-intl/react-intl';
import { IEditorStoreInitialContext } from '@/features/editor/stores/mobx/editor-store';

registerPdfFonts();

interface IReactPdfProviderProps extends Pick<IntlShape, 'messages'> {
  children: React.ReactNode;
  context: IEditorStoreInitialContext;
  calculationId: number;
}

export function ReactPdfProvider({
  children,
  messages,
  context,
  calculationId
}: IReactPdfProviderProps): JSX.Element {
  return (
    <ReactIntlProvider defaultMessages={messages}>
      <EditorProvider calculationId={calculationId} initialContext={context}>
        {children}
      </EditorProvider>
    </ReactIntlProvider>
  );
}
