import { Typography } from '@/components/typography';
import { Box, Image, Text } from 'theme-ui';
import { Icons } from '@/assets';

interface IEsdecMountingSystemTypeCard {
  imageSrc: string;
  title: React.ReactNode;
  bulletPoints: React.ReactNode[];
  children: React.ReactNode;
}

export function EsdecMountingSystemTypeCard({
  imageSrc,
  title,
  bulletPoints,
  children
}: IEsdecMountingSystemTypeCard): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: 3,
        flexDirection: 'column',
        gap: 6,
        px: '64px',
        py: 9,
        boxShadow: '2dp'
      }}
    >
      <Image src={imageSrc} />
      <Text sx={{ fontSize: '2xl', color: 'primary' }}>{title}</Text>

      <Box>
        {bulletPoints.map((bulletPoint, index) => (
          <Box sx={{ display: 'flex', gap: 3, my: 2 }} key={index}>
            <Box sx={{ flexShrink: 0 }}>
              <Icons.CheckMark />
            </Box>

            <Typography variant="headline3" sx={{ color: 'menuText', letterSpacing: '0.15px' }}>
              {bulletPoint}
            </Typography>
          </Box>
        ))}
      </Box>
      {children}
    </Box>
  );
}
