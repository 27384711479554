/** @jsxImportSource theme-ui */

import { createContext } from '@/utils/create-context';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { RadioGroupItemProps } from '@radix-ui/react-radio-group';
import React from 'react';
import { ChipLarge, ChipSmall, IChipBaseProps } from '@/components/chip/chip';
import { ThemeUIStyleObject } from 'theme-ui';

const [useChipGroup, ChipGroupProvider] = createContext<{
  groupValue: string | undefined;
}>();

interface IChipGroupProps extends RadioGroup.RadioGroupProps {
  gap?: number;
}

export function ChipGroup({ gap = 2, ...props }: IChipGroupProps): JSX.Element {
  const value = React.useMemo(() => ({ groupValue: props.value }), [props.value]);

  return (
    <ChipGroupProvider value={value}>
      <RadioGroup.Root sx={{ display: 'flex', gap }} {...props}>
        {props.children}
      </RadioGroup.Root>
    </ChipGroupProvider>
  );
}

interface IChipGroupChipProps extends RadioGroupItemProps, Omit<IChipBaseProps, 'value'> {
  checkedStyles?: ThemeUIStyleObject;
  uncheckedStyles?: ThemeUIStyleObject;
}

ChipGroup.ChipLarge = function ChipGroupChipLarge({
  checkedStyles,
  uncheckedStyles,
  ...props
}: IChipGroupChipProps): JSX.Element {
  const { groupValue } = useChipGroup();

  return (
    <RadioGroup.Item asChild {...props}>
      <ChipLarge
        {...props}
        isActive={groupValue === props.value}
        sx={{
          '&[data-state="checked"]': checkedStyles,
          '&[data-state="unchecked"]': uncheckedStyles
        }}
      />
    </RadioGroup.Item>
  );
};

ChipGroup.ChipSmall = function ChipGroupChipSmall({
  checkedStyles,
  uncheckedStyles,
  ...props
}: IChipGroupChipProps): JSX.Element {
  const { groupValue } = useChipGroup();

  return (
    <RadioGroup.Item asChild {...props}>
      <ChipSmall
        {...props}
        isActive={groupValue === props.value}
        sx={{
          '&[data-state="checked"]': checkedStyles,
          '&[data-state="unchecked"]': uncheckedStyles
        }}
      />
    </RadioGroup.Item>
  );
};
