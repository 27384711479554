import { IEnrichedSurface, ISurface } from '@/features/editor/utils/surface/surface';
import * as R from 'remeda';
import React from 'react';
import { createContext } from '@/utils/create-context';
import { EmptyPropsWithChildren } from '@/utils/types';
import { IRidgeLine, surfaceRidgeSide } from '@/features/google-map/utils/surface-ridge-side';
import { selectPolylineSides } from '@/features/google-map/utils/select-polyline-sides';
import { getSideLengths, getSideLengthsWithTilt } from '@/features/editor/utils/get-side-lengths';
import { extendLine } from '@/utils/turf/extend-line';
import { SurfacePanelsMap, useSurfacePanels } from '@/features/editor/utils/panel/use-surface-panels';
import { IPanelType } from '@/features/editor/utils/panel/panel';
import { IEnrichedSide } from '@/utils/drawing/types';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

function surfaceTotalPower(surface: ISurface, panelTypes: IPanelType[], panels: SurfacePanelsMap): number {
  const panelType = panelTypes.find((panelType) => panelType.productId === surface.panelTypeId);

  const panelPower = panelType?.power ?? 0;
  const panelCount = panels[surface.id]?.length ?? 0;

  return panelCount * panelPower;
}

function surfaceSidesLengths(surface: ISurface, ridgeLine?: IRidgeLine): IEnrichedSide[] {
  if (R.isNil(ridgeLine)) {
    return getSideLengths(surface.path);
  }

  return getSideLengthsWithTilt(
    surface.path,
    extendLine({ start: ridgeLine.start, end: ridgeLine.end }),
    surface.tiltAngle
  );
}

// Returns all surfaces with all relevant measurements.
function useSurfacesValue(): IEnrichedSurface[] {
  const { surfacePanels } = useSurfacePanels();
  const store = useEditorStore();
  const { surfaces } = store.surfaces;

  return R.pipe(
    surfaces,
    R.filter((surface) => surface.path.length >= 2),
    R.map(function (surface: ISurface): IEnrichedSurface {
      const { panelTypes } = store.metadata;
      const surfacePanel = panelTypes.find((panelType) => panelType.productId === surface.panelTypeId)!;
      const panelsCount = surfacePanels[surface.id]?.length ?? 0;
      const panelAreaMeters = surfacePanel.widthMeters * surfacePanel.heightMeters * panelsCount;

      const ridgeLine = surfaceRidgeSide({
        ridge: surface.ridge,
        sides: selectPolylineSides(surface.path),
        id: surface.id
      });

      return {
        ...surface,
        ridgeLine,
        sides: surfaceSidesLengths(surface, ridgeLine),
        panel: surfacePanel,
        totalPanelPower: surfaceTotalPower(surface, panelTypes, surfacePanels),
        panelAreaMeters
      };
    })
  );
}

const [useSurfaces, SurfacesContextProvider] = createContext<IEnrichedSurface[]>([]);
const SurfacesProvider = observer(function SurfacesProvider({
  children
}: EmptyPropsWithChildren): JSX.Element {
  const value = useSurfacesValue();

  return <SurfacesContextProvider value={value}>{children}</SurfacesContextProvider>;
});

export { useSurfaces, SurfacesProvider };
