/** @jsxImportSource theme-ui */
import { MapTopControls } from '@/features/google-map/components/map-top-controls';
import { MapTypesInput } from '@/features/google-map/utils/map-types/map-types';
import { observer } from 'mobx-react-lite';

export const MapTopRightControls = observer(function MapTopRightControls() {
  return (
    <MapTopControls
      sx={{
        right: 32
      }}
    >
      <MapTypesInput />
    </MapTopControls>
  );
});
