import React from 'react';
import create from 'zustand';
import type { CSSProperties } from 'react';

interface IOpenModalOptions {
  children: React.ReactNode;
  options?: {
    maxWidth?: CSSProperties['maxWidth'];
    maxHeight?: CSSProperties['maxHeight'];
  };
}

interface IModalStore {
  children?: React.ReactNode;
  options?: IOpenModalOptions['options'];

  openModal(options: IOpenModalOptions): void;
  closeModal(): void;
}

const useModalStore = create<IModalStore>((set) => ({
  openModal({ children, options }: IOpenModalOptions) {
    set((state) => ({ ...state, children, options }));
  },
  closeModal() {
    set((state) => ({ ...state, children: undefined, options: undefined }));
  }
}));

export function useModalOptions() {
  return useModalStore((state) => state.options);
}

export function useModalContent() {
  return useModalStore((state) => state.children);
}

function useModal() {
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);

  return {
    openModal,
    closeModal
  };
}

export { useModal };
