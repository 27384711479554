/** @jsxImportSource theme-ui */
import { Box } from '@/components/box';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { Tabs } from '@/components/tabs/tabs';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { EsdecSurfaceContent } from '@/features/esdec/components/esdec-surface-content';
import { isFeatureFlagActive } from '@/utils/feature-flags';
import { useResizeObserver } from '@react-aria/utils';
import { EsdecOverviewCard } from './components/esdec-overview-card';
import { MdArrowBack } from 'react-icons/md';
import { themeColors } from '@/lib/theme-ui/colors';
import { Button } from 'theme-ui';
import { useNavigate } from '@/lib/react-router/use-navigate';
import { SurfaceEsdec } from '@/features/editor/stores/mobx/surfaces-esdec-store';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@/components/tooltip/tooltip';

/**
 * Calculate the amount of padding needed between the
 * full width parent and tabs element to ensure that
 * the content / tabs are 1300 pixels wide.
 * */
function useTabsContentPadding() {
  // The width of the content and tabs
  const CONTENT_WIDTH = 1300;

  const [width, setWidth] = React.useState(0);
  const $parent = React.useRef<HTMLDivElement>(null);

  useResizeObserver({
    ref: $parent,
    onResize: () => {
      setWidth($parent.current?.parentElement?.getBoundingClientRect().width || 0);
    }
  });

  function getPx() {
    if (width) {
      // Divide by 2 to get the padding on each side
      return (width - CONTENT_WIDTH) / 3;
    }

    return 0;
  }

  return {
    $parent,
    px: getPx()
  };
}

export const EsdecRoute = observer(function EsdecRoute(): JSX.Element {
  const store = useEditorStore();
  const navigate = useNavigate();
  const { surfaces } = store;
  const [activeTab, setActiveTab] = React.useState(store.surfaces.selectedSurfaceId);
  const { surfacesEsdec } = store;

  const { px, $parent } = useTabsContentPadding();

  if (!isFeatureFlagActive('esdec')) {
    return <React.Fragment />;
  }

  if (activeTab) store.surfaces.selectedEsdecSurfaceId = activeTab;

  function createSurfaceEsdec(surfaceEsdec: SurfaceEsdec | undefined, surfaceId: string) {
    if (!surfaceEsdec) {
      return surfacesEsdec.create({ surfaceId });
    }
  }

  const tabItems = surfaces.surfaces.map((surface) => (
    <Tabs.Item key={surface.id} title={surface.description}>
      <EsdecSurfaceContent surfaceId={surface.id} />
    </Tabs.Item>
  ));

  tabItems.push(
    <Tabs.Item key="overview" title="Overview">
      <EsdecOverviewCard />
    </Tabs.Item>
  );
  const height = activeTab === 'overview' ? 'auto' : '100%';

  surfaces.surfaces.map((surface) => createSurfaceEsdec(surfacesEsdec.surfaces[surface.id], surface.id));

  return (
    <Box
      ref={$parent}
      sx={{
        height: height,
        backgroundColor: '#F8F8F8',
        position: 'relative'
      }}
    >
      {surfaces.surfaces.length > 0 && (
        <React.Fragment>
          <Tooltip content={<FormattedMessage defaultMessage="Back to editor" id="feFLY0" />}>
            <Button
              onClick={() => {
                navigate('..');
              }}
              sx={{
                backgroundColor: 'white',
                position: 'absolute',
                padding: 0,
                marginLeft: 5,
                pt: 1,
                '&:hover': { cursor: 'pointer' }
              }}
            >
              <MdArrowBack
                style={{
                  color: themeColors.primary
                }}
                size={34}
              />
            </Button>
          </Tooltip>

          <Tabs
            selectedKey={activeTab}
            tabListSx={{
              backgroundColor: 'white',
              px
            }}
            tabContentSx={{
              px,
              display: 'flex',
              alignItems: 'center',
              height: height
            }}
            onSelectionChange={(key) => setActiveTab(key.toString())}
          >
            {tabItems}
          </Tabs>
        </React.Fragment>
      )}
    </Box>
  );
});
