/** @jsxImportSource theme-ui */
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@/components/toggle/toggle';

export const EsdecSnowFencesInput = observer(function EsdecSnowFencesInput({
  surfaceId,
  isEsdecToggle
}: {
  surfaceId: string;
  isEsdecToggle?: boolean;
}) {
  const store = useEditorStore();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  return (
    <Toggle
      sx={{ flexDirection: isEsdecToggle ? 'column-reverse' : null }}
      disabled={surfaceEsdec.isRoofFinalized}
      pressed={surfaceEsdec.snowFencesPresent}
      onPressedChange={(pressed) => {
        surfaceEsdec.updateSnowFences(pressed);
      }}
    >
      <FormattedMessage defaultMessage="Snow Fences" id="3IsB8c" />
    </Toggle>
  );
});
