import React from 'react';
import { Alert } from '@/components/alert/alert';
import { Text } from 'theme-ui';

export function SurfaceRidgeWarningMessage(): JSX.Element {
  return (
    <Alert kind="info">
      <Text>
        You have an angled surface without a ridge. Right click on one of the length labels of the surface to
        make it a ridge.
      </Text>
    </Alert>
  );
}
