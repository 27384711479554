import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { ESDEC_TOPOGRAPHY_TYPE } from '../esdec-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icons } from '@/assets';
import { EsdecWeatherZonesPlaceholder } from './esdec-weather-zones-card';

export const EsdecTopographyTypeInput = observer(function EsdecTopographyTypeInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const intl = useIntl();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  const topographyTypeOptions = ESDEC_TOPOGRAPHY_TYPE.map((topographyType) => ({
    label: topographyType,
    value: topographyType
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Environment Type',
        id: '4pkikl'
      })}
      placeholder={
        <EsdecWeatherZonesPlaceholder
          icon={<Icons.Terrain />}
          text={<FormattedMessage defaultMessage="Select Environment" id="wXPbBg" />}
        />
      }
      name="environmentType"
      options={topographyTypeOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateTopographyType(value.value);
        }
      }}
      value={topographyTypeOptions.find(
        (topographyTypeOption) => topographyTypeOption.value === surfaceEsdec.topography
      )}
    />
  );
});
