import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

export function useEditorSelectors() {
  const store = useEditorStore();

  const { surfaces, selectedSurfaceId } = store.surfaces;
  const { panelTypes } = store.metadata;

  const selectedSurface = surfaces.find((surface) => surface.id === selectedSurfaceId);
  const selectedPanel = panelTypes.find((panelType) => selectedSurface?.panelTypeId === panelType.productId);

  return {
    selectedPanel,
    selectedSurface
  };
}
