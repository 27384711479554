import { FormattedMessage } from 'react-intl';
import { Toggle } from '@/components/toggle/toggle';
import React from 'react';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';

export const SurfaceStartingCost = observer(function SurfaceStartingCost({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const surface = store.surfaces.surfaceGetOne(surfaceId);

  if (!surface) {
    return <React.Fragment />;
  }

  return (
    <Toggle
      pressed={surface.isStartingCostEnabled}
      onPressedChange={(pressed) => {
        store.surfaces.surfaceUpdate(surfaceId, (surface) => {
          surface.isStartingCostEnabled = pressed;
        });
      }}
    >
      <FormattedMessage
        defaultMessage="Starting cost"
        id="yc7lnn"
        description="A toggle that determines for each surface whether there should or shouldn't be a starting cost."
      />
    </Toggle>
  );
});
