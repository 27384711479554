import { Font } from '@react-pdf/renderer';

// @ts-ignore
import InterRegular from '@/assets/fonts/Inter-Regular.ttf';
// @ts-ignore
import InterMedium from '@/assets/fonts/Inter-Medium.ttf';
// @ts-ignore
import InterBold from '@/assets/fonts/Inter-Bold.ttf';

export function registerPdfFonts(): void {
  Font.register({
    family: 'Inter',
    fonts: [{ src: InterRegular }, { src: InterMedium, fontWeight: 500 }, { src: InterBold, fontWeight: 700 }]
  });
}
