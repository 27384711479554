import { useGoogleMapContext } from '@/features/google-map/components/google-map';
import React from 'react';
import { IPoint } from '@/utils/gis/types';
import { createLengthMarkerForLine } from '@/utils/gis/create-length-marker-for-line';
import { useMachine } from '@xstate/react';
import { getPointFromMouseEvent } from '@/features/google-map/utils/get-point-from-mouse-event';
import { GOOGLE_MAP_Z_INDICES } from '@/features/google-map/utils/z-indices';
import { themeColors } from '@/lib/theme-ui/colors';
import { MapPolyline } from '@/features/google-map/components/shapes/map-polyline';
import { MapLengthLabel } from '@/features/google-map/components/shapes/map-length-label';
import { assign, createMachine } from 'xstate';
import { ISide } from '@/utils/drawing/types';
import { observer } from 'mobx-react-lite';
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';

interface IRulerMachineContext {
  start: IPoint | undefined;
  end: IPoint | undefined;
}

type IRulerMachineEvent =
  | {
      type: 'MOUSE_MOVE';
      point: IPoint;
    }
  | {
      type: 'CLICK';
      point: IPoint;
    }
  | {
      type: 'CLEAR';
    };

type IRulerMachineTypestate =
  | {
      value: 'idle';
      context: {
        start: IPoint | undefined;
        end: IPoint | undefined;
      };
    }
  | {
      value: 'drawing';
      context: {
        start: IPoint;
        end: IPoint;
      };
    };

const rulerMachine = createMachine<IRulerMachineContext, IRulerMachineEvent, IRulerMachineTypestate>({
  predictableActionArguments: true,

  initial: 'idle',
  states: {
    idle: {
      on: {
        CLICK: {
          target: 'drawing',
          actions: [
            assign({
              start: (_, event) => event.point,
              end: (_, event) => event.point
            })
          ]
        },
        CLEAR: {
          actions: [
            assign({
              start: (_, __) => undefined,
              end: (_, __) => undefined
            })
          ]
        }
      }
    },

    drawing: {
      on: {
        MOUSE_MOVE: {
          actions: [
            assign({
              end: (_, event) => event.point
            })
          ]
        },
        CLEAR: {
          target: 'idle',
          actions: [assign({ start: (_, __) => undefined, end: (_, __) => undefined })]
        },
        CLICK: {
          target: 'idle',
          actions: [
            assign({
              end: (_, event) => event.point
            })
          ]
        }
      }
    }
  }
});

function RulerPolyline({ start, end }: ISide) {
  const { middle, lengthLabel } = createLengthMarkerForLine({
    start,
    end
  });

  return (
    <MapPolyline
      clickable={false}
      strokeColor={themeColors.ruler}
      zIndex={GOOGLE_MAP_Z_INDICES.RULER}
      path={[start, end]}
    >
      <MapLengthLabel label={lengthLabel} point={middle} />
    </MapPolyline>
  );
}

export const RenderRuler = observer(function RenderRuler() {
  const { map } = useGoogleMapContext();
  const store = useEditorStore();

  const isMeasuring = store.state.isMeasuring;
  const [state, send] = useMachine(rulerMachine);

  React.useEffect(() => {
    if (!isMeasuring) {
      return;
    }

    const listeners = [
      map.addListener('mousemove', (event: google.maps.MapMouseEvent) => {
        const point = getPointFromMouseEvent(event);
        if (point === undefined) {
          return;
        }

        send({ type: 'MOUSE_MOVE', point });
      }),
      map.addListener('click', (event: google.maps.MapMouseEvent) => {
        const point = getPointFromMouseEvent(event);
        if (point === undefined) {
          return;
        }
        send({ type: 'CLICK', point });
      }),
      map.addListener('rightclick', () => {
        send({ type: 'CLEAR' });
      })
    ];

    return () => {
      listeners.forEach((listener) => listener.remove());
    };
  }, [isMeasuring, map, send]);

  if (state.matches('drawing')) {
    return <RulerPolyline start={state.context.start} end={state.context.end} />;
  }

  if (state.matches('idle') && state.context.start && state.context.end) {
    return <RulerPolyline start={state.context.start} end={state.context.end} />;
  }

  return null;
});
