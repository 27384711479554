/** @jsxImportSource theme-ui */
import { useEditorStore } from '@/features/editor/stores/mobx/editor-store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField } from '@/components/select-field';
import { ESDEC_ZONES_BY_COUNTRY } from '../esdec-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icons } from '@/assets';
import { EsdecWeatherZonesPlaceholder } from './esdec-weather-zones-card';

export const EsdecWindzoneTypeInput = observer(function EsdecWindzoneTypeInput({
  surfaceId
}: {
  surfaceId: string;
}) {
  const store = useEditorStore();
  const intl = useIntl();
  const { surfacesEsdec } = store;

  const surfaceEsdec = surfacesEsdec.surfaces[surfaceId];

  if (!surfaceEsdec) {
    return <React.Fragment />;
  }

  const windzoneOptions = ESDEC_ZONES_BY_COUNTRY.sweden.windzone.map((windzone) => ({
    label: windzone,
    value: windzone
  }));

  return (
    <SelectField
      isDisabled={surfaceEsdec.isRoofFinalized}
      label={intl.formatMessage({
        defaultMessage: 'Windzone Type',
        id: 'Z/pMfj'
      })}
      placeholder={
        <EsdecWeatherZonesPlaceholder
          icon={<Icons.Air />}
          text={<FormattedMessage defaultMessage="Select Windzone" id="Dayv0D" />}
        />
      }
      name="windzone"
      options={windzoneOptions}
      onChange={(value) => {
        if (value) {
          surfaceEsdec.updateWindzone(value.value);
        }
      }}
      value={windzoneOptions.find((windzoneOption) => windzoneOption.value === surfaceEsdec.windzone)}
    />
  );
});
