import { IMapTypeId, MapTypes } from '@/features/google-map/utils/map-types/map-types';
import { IPoint } from '@/utils/gis/types';
import { makeAutoObservable } from 'mobx';
import { IEditorContext } from '@/features/editor/utils/editor-context';

export class MapDataStore {
  public mapTypeId: IMapTypeId = MapTypes.defaultMapType.id;

  public zoom: number = 20;

  public mapCenter: IPoint | undefined;

  public constructor({ initial }: { initial?: IEditorContext['mapData'] }) {
    makeAutoObservable(this);

    if (initial) {
      this.mapTypeId = initial.mapTypeId;
      this.zoom = initial.zoom;
      this.mapCenter = initial.mapCenter;
    }
  }

  public updateMapTypeId = (mapTypeId: IMapTypeId) => {
    this.mapTypeId = mapTypeId;
  };

  public updateZoom = (zoom: number) => {
    this.zoom = zoom;
  };

  public updateMapCenter = (mapCenter: IPoint) => {
    this.mapCenter = mapCenter;
  };
}
