import { Box } from '@/components/box';
import React from 'react';
import { theme } from '@/lib/theme-ui';

interface ICardProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
}

export function Card({ children, title, className }: ICardProps) {
  if (!title) {
    return (
      <Box
        className={className}
        sx={{
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'cardBorder',
          py: 5,
          px: theme.space.cardPaddingX,
          backgroundColor: 'white'
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'cardBorder',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%'
      }}
    >
      <Box
        sx={{
          backgroundColor: 'cardTitleBackground',
          px: theme.space.cardPaddingX
        }}
      >
        {title}
      </Box>

      <Box
        sx={{
          px: theme.space.cardPaddingX,
          py: 5,
          backgroundColor: 'white',
          flex: 1
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
