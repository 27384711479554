// @ts-nocheck Types broke for children here, so we're ignoring it for now
import { G, Path, Svg, View, Rect, Circle } from '@react-pdf/renderer';

export function Business(): JSX.Element {
  return (
    <Svg width="14.281" height="14.281" viewBox="0 0 14.281 14.281">
      <G>
        <Path d="M0,0H14.281V14.281H0Z" fill="none" />
        <Path
          d="M7.95,5.38V3H2V13.711H13.9V5.38Zm-3.57,7.14H3.19v-1.19H4.38Zm0-2.38H3.19V8.95H4.38Zm0-2.38H3.19V6.57H4.38Zm0-2.38H3.19V4.19H4.38Zm2.38,7.14H5.57v-1.19H6.76Zm0-2.38H5.57V8.95H6.76Zm0-2.38H5.57V6.57H6.76Zm0-2.38H5.57V4.19H6.76Zm5.95,7.14H7.95v-1.19H9.14V10.14H7.95V8.95H9.14V7.76H7.95V6.57h4.76Zm-1.19-4.76h-1.19V8.95h1.19Zm0,2.38h-1.19v1.19h1.19Z"
          transform="translate(-0.81 -1.215)"
          fill="#1dba6c"
        />
      </G>
    </Svg>
  );
}

export function User(): JSX.Element {
  return (
    <Svg height="24" viewBox="0 0 24 24" width="24">
      <Path d="M0 0h24v24H0V0z" fill="none" />
      <Path
        fill="#4dc582"
        d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
      />
    </Svg>
  );
}

export function LocationPin(): JSX.Element {
  return (
    <Svg height="24px" viewBox="0 0 24 24" width="24px">
      <Path d="M0 0h24v24H0z" fill="none" />
      <Path
        fill="#4dc582"
        d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"
      />
    </Svg>
  );
}

export function Email(): JSX.Element {
  return (
    <Svg height="24" viewBox="0 0 24 24" width="24">
      <Path d="M0 0h24v24H0V0z" fill="none" />
      <Path
        fill="#4dc582"
        d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"
      />
    </Svg>
  );
}

export function UnorderedList(): JSX.Element {
  return (
    <Svg height="24" viewBox="0 0 24 24" width="24">
      <Path d="M0 0h24v24H0V0z" fill="none" />
      <Path
        fill="#4dc582"
        d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z"
      />
    </Svg>
  );
}

export function Area(): JSX.Element {
  return (
    <Svg width="11.797" height="11.797" viewBox="0 0 11.797 11.797">
      <Path
        d="M13.486,3H4.311A1.315,1.315,0,0,0,3,4.311v9.175A1.315,1.315,0,0,0,4.311,14.8h9.175A1.315,1.315,0,0,0,14.8,13.486V4.311A1.315,1.315,0,0,0,13.486,3Zm0,10.486H4.311V4.311h9.175Z"
        transform="translate(-3 -3)"
        fill="#13ab62"
      />
    </Svg>
  );
}
export function CostsForConstruction(): JSX.Element {
  return (
    <Svg width="14.284" height="15.204" viewBox="0 0 14.284 15.204">
      <Path
        data-name="Path 13318"
        d="M10.518,3.5c0-.826-1.683-1.5-3.759-1.5S3,2.67,3,3.5m0,0c0,.826,1.683,1.5,3.76,1.5s3.76-.669,3.76-1.5V9.3M3,3.5v8.97c0,.826,1.684,1.5,3.76,1.5a7.51,7.51,0,0,0,2.59-.416M3,6.485c0,.826,1.683,1.5,3.76,1.5s3.76-.669,3.76-1.5M9.395,10.54a7.479,7.479,0,0,1-2.635.43C4.684,10.97,3,10.3,3,9.475m11.791.238a3.35,3.35,0,0,1,0,4.757,3.4,3.4,0,0,1-4.785,0,3.35,3.35,0,0,1,0-4.757A3.4,3.4,0,0,1,14.791,9.713Z"
        transform="translate(-2.249 -1.25)"
        fill="none"
        stroke="#039855"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </Svg>
  );
}

export function Panel(): JSX.Element {
  return (
    <Svg id="dataset_black_24dp" width="11.797" height="11.797" viewBox="0 0 11.797 11.797">
      <G data-name="Group 11982" transform="translate(0 0)">
        <G data-name="Group 11981">
          <G data-name="Group 11976" transform="translate(2.621 6.554)">
            <Rect x="" y="" width="2.621" height="2.621" fill="#13ab62" />
          </G>
          <G data-name="Group 11977" transform="translate(6.554 6.554)">
            <Rect x="" y="" width="2.621" height="2.621" fill="#13ab62" />
          </G>
          <G data-name="Group 11978">
            <Path
              data-name="Path 12630"
              d="M13.486,3H4.311A1.315,1.315,0,0,0,3,4.311v9.175A1.315,1.315,0,0,0,4.311,14.8h9.175A1.315,1.315,0,0,0,14.8,13.486V4.311A1.315,1.315,0,0,0,13.486,3Zm0,10.486H4.311V4.311h9.175Z"
              transform="translate(-3 -3)"
              fill="#13ab62"
            />
          </G>
          <G data-name="Group 11979" transform="translate(2.621 2.621)">
            <Rect x="" y="" width="2.621" height="2.621" fill="#13ab62" />
          </G>
          <G data-name="Group 11980" transform="translate(6.554 2.621)">
            <Rect x="" y="" width="2.621" height="2.621" fill="#13ab62" />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export function Tilt(): JSX.Element {
  return (
    <Svg width="11.115" height="11.114" viewBox="0 0 11.115 11.114">
      <G data-name="Group 12221" transform="translate(1.061 1.061)">
        <Path
          data-name="Path 12027"
          d="M-12487.416,13112.414l8.994-8.993"
          transform="translate(12487.416 -13103.421)"
          fill="none"
          stroke="#13ab62"
          stroke-linecap="round"
          stroke-width="1.5"
        />
        <G data-name="Polygon 2" transform="translate(2.238 6.278)" fill="#13ab62">
          <Path
            d="M 4.743019104003906 2.526839733123779 L 1.810659289360046 2.526839733123779 L 3.276839256286621 1.060659646987915 L 4.743019104003906 2.526839733123779 Z"
            stroke="none"
          />
          <Path
            d="M 3.276839256286621 -4.76837158203125e-07 L 6.553679466247559 3.276839733123779 L -9.5367431640625e-07 3.276839733123779 L 3.276839256286621 -4.76837158203125e-07 Z"
            stroke="none"
            fill="#13ab62"
          />
        </G>
      </G>
    </Svg>
  );
}

export function Asimuth(): JSX.Element {
  return (
    <Svg width="12.641" height="12.641" viewBox="0 0 12.641 12.641">
      <G data-name="Group 12222" transform="translate(11.891 0.75) rotate(90)">
        <Path
          data-name="Path 11763"
          d="M.259,1.562A8.214,8.214,0,0,1,0,0,8.211,8.211,0,0,1,1.562.259a4.058,4.058,0,0,1,1.8.941A4.06,4.06,0,0,1,4.3,3a8.214,8.214,0,0,1,.259,1.562A8.215,8.215,0,0,1,3,4.3a4.06,4.06,0,0,1-1.8-.941A4.06,4.06,0,0,1,.259,1.562Z"
          transform="translate(3.292 3.292)"
          fill="none"
          stroke="#13ab62"
          stroke-width="1.5"
        />
        <Circle
          data-name="Ellipse 21"
          cx="5.571"
          cy="5.571"
          r="5.571"
          fill="none"
          stroke="#13ab62"
          stroke-width="1.5"
        />
      </G>
    </Svg>
  );
}

export function RoofAngled(): JSX.Element {
  return (
    <Svg width="11.115" height="11.114" viewBox="0 0 11.115 11.114">
      <G data-name="Group 12223" transform="translate(1.06 1.061)">
        <Path
          data-name="Path 12027"
          d="M-12487.416,13112.414l8.994-8.993"
          transform="translate(12487.416 -13103.421)"
          fill="none"
          stroke="#13ab62"
          stroke-linecap="round"
          stroke-width="1.5"
        />
      </G>
    </Svg>
  );
}

export function HeightToEaves(): JSX.Element {
  return (
    <Svg width="11.296" height="10.986" viewBox="0 0 11.296 10.986">
      <G data-name="Group 12224" transform="translate(1.061 1.061)">
        <Path
          data-name="Path 12610"
          d="M0,4.588H9.175m0,0L4.588,0M9.175,4.588,4.588,9.175"
          transform="translate(0 9.175) rotate(-90)"
          fill="none"
          stroke="#13ab62"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <Path
          data-name="Path 12611"
          d="M-1812.964,1552h9.175"
          transform="translate(1812.964 -1542.825)"
          fill="none"
          stroke="#13ab62"
          stroke-linecap="round"
          stroke-width="1.5"
        />
      </G>
    </Svg>
  );
}

export function Battery(): JSX.Element {
  return (
    <Svg width="4" height="4" viewBox="0 0 4 4">
      <Circle data-name="Ellipse 554" cx="2" cy="2" r="2" fill="#12b76a" />
    </Svg>
  );
}

export function Shared(): JSX.Element {
  return (
    <Svg data-name="share_black_24dp (1)" width="10" height="10" viewBox="0 0 10 10">
      <Path data-name="Path 12968" d="M0,0H10V10H0Z" fill="none" />
      <Path
        data-name="Path 12969"
        d="M9.25,7.867a1.213,1.213,0,0,0-.817.321L5.463,6.458a1.153,1.153,0,0,0,0-.583L8.4,4.163A1.247,1.247,0,1,0,8,3.25a1.364,1.364,0,0,0,.038.292L5.1,5.254a1.25,1.25,0,1,0,0,1.825L8.067,8.813a1.175,1.175,0,0,0-.033.271A1.217,1.217,0,1,0,9.25,7.867Z"
        transform="translate(-1.75 -1.167)"
        fill="#12b76a"
      />
    </Svg>
  );
}

export function RoofFlat(): JSX.Element {
  return (
    <View>
      <Svg stroke-width="0" viewBox="0 0 24 24" height="16" width="16">
        <Path fill="none" d="M0 0h24v24H0z" />
        <Path
          fill="#12b76a"
          d="M16.955 8.662l2.12-2.122 1.416 1.414-2.121 2.122zM2 18h20v2H2zM11 4h2v3h-2zM3.543 7.925L4.957 6.51l2.121 2.12-1.414 1.415zM5 16h14c0-3.87-3.13-7-7-7s-7 3.13-7 7z"
        />
      </Svg>
    </View>
  );
}

export function Language() {
  return (
    <Svg height="24" viewBox="0 0 24 24" width="24">
      <Path d="M0 0h24v24H0V0z" fill="none" />
      <Path
        fill="#4dc582"
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
      />
    </Svg>
  );
}

export function Phone() {
  return (
    <Svg height="24" viewBox="0 0 24 24" width="24">
      <Path d="M0 0h24v24H0V0z" fill="none" />
      <Path
        fill="#4dc582"
        d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"
      />
    </Svg>
  );
}
